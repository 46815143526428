import { useMutation } from 'wagmi'
import { useQueryClient } from '@tanstack/react-query'

import { axios } from '../../../lib/axios'
import DataService from '../../../lib/DataService'

const { postformdataRequest, getRequest, postRequest } = DataService()

function makeOffer(data) {
	return postRequest('asset/make-offer', { ...data })
}

export function useMakeOffer() {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: makeOffer,
		onSuccess: () => {
			queryClient.invalidateQueries(['makeOffer'])
		}
	})
}
