import React, { useCallback, useEffect, useRef, useState } from 'react'
import { format } from 'date-fns'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useAccount, useNetwork, useQueryClient, useSwitchNetwork } from 'wagmi'
import { Signature } from 'ethers'
import { NumericFormat } from 'react-number-format'
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Modal,
	ModalBody,
	ModalHeader,
	UncontrolledDropdown
} from 'reactstrap'
import Web3 from 'web3'
import useSWR from 'swr'
import { formatUnits } from 'viem'

import {
	useMakeCollectionOfferModalActions,
	useMakeCollectionOfferModalState
} from './store'
import DefaultImage from '../../../assets/images/homeExplorer/placeholder.png'
import { chainId } from '../../../config/constants/chains'
import contracts from '../../../config/constants/contracts'
import { CloseIcon } from '../makeOffer/CloseIcon'
import { useCollectionOfferSuccessModalActions } from '../collection-offer-success/store'
import { useCollectionMakeOffer } from './makeCollectionOffer'
import { usePurchaseLoadingModalActions } from '../purchase-loading/store'
import { useSomethingWentWrongModalActions } from '../error/store'
import { useFetchCollectionTraits } from '../../../features/collection/apis/fetchCollectionTraits'
import { Spinner } from '../../../components/loader/spinner/Spinner'
import { useOnClickOutside } from '../../../hooks/useOnClickOutside'
import { useToast } from '../../../utils/toast/customSuccess'
import {
	useMarketplaceContract,
	useTokenContract
} from '../../../hooks/useContract'
import { formatAmount } from '../../../utils/formatAmount'
import { useApproveToken } from '../../../hooks/useApproveToken'
import { handleImageError } from '../../../hooks/useHandleImg'
import { getProvider } from '../../../utils/getProvider'

import yellowtick20 from '../../../assets/images/collection/yellow-tick_20px.svg'
import decreaseIcon from '../../../assets/images/decreaseIcon.svg'
import increaseIcon from '../../../assets/images/increaseIcon.svg'
import searchIcon from '../../../assets/images/search.svg'
import closeIcon from '../../../assets/images/closeToast.svg'
import { chainIdMap, chainMap, nativeCoinMap } from '../../../utils/getFilterData'
import { useOkxSwitchNetwork } from '../../../hooks/useOkxSwitchNetwork'



const intervals = [
	{ label: '12 hours', duration: 720, format: '12H' },
	{ label: '1 day', duration: 1440, format: '1D' },
	{ label: '3 days', duration: 4320, format: '3D' },
	{ label: '7 days', duration: 10080, format: '7D' },
	{ label: '1 month', duration: 43200, format: '1M' }
]

const CheckTick = () => {
	return (
		<>
			<svg
				width='12'
				height='9'
				viewBox='0 0 12 9'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M10.1953 0.46875C10.3125 0.351562 10.5 0.351562 10.5938 0.46875L11.2734 1.125C11.3672 1.24219 11.3672 1.42969 11.2734 1.52344L4.24219 8.55469C4.125 8.67188 3.96094 8.67188 3.84375 8.55469L0.703125 5.4375C0.609375 5.32031 0.609375 5.13281 0.703125 5.03906L1.38281 4.35938C1.47656 4.26562 1.66406 4.26562 1.78125 4.35938L4.03125 6.63281L10.1953 0.46875Z'
					fill='#FFA900'
				/>
			</svg>
		</>
	)
}

export function MakeCollectionOfferModal() {
	const { t } = useTranslation()

	const queryClient = useQueryClient()

	const [bidAmount, setBidAmount] = useState()
	const [hasFunds, setHasFunds] = useState(false)
	const [selectedInterval, setSelectedInterval] = useState(intervals[4])
	const [dropdownOpen, setDropdownOpen] = useState(false)
	const [date, setDate] = useState('')
	const [selectedDate, setSelectedDate] = useState('')
	const [time, setTime] = useState('')
	const [numberOfCopies, setNumberOfCopies] = useState(1)
	const [show, setShow] = useState(false)
	const [showDropdown, setShowDropdown] = useState(false)
	const [traits, setTraits] = useState()
	const [selectedTrait, setSelectedTrait] = useState()
	const [searchText, setSearchText] = useState('')
	const [isBidHighest, setIsBidHighest] = useState(false)
	const [selectedCurrency, setSelectedCurrency] = useState()
	const [refreshBalance, setRefreshBalance] = useState(false)

	const { address: account } = useAccount()
	const { chain } = useNetwork()
	const { switchNetworkAsync } = useSwitchNetwork()
	const { switchNetwork } = useOkxSwitchNetwork()

	const { toggleMakeCollectionOfferModal } =
		useMakeCollectionOfferModalActions()
	const { setCollection } = useCollectionOfferSuccessModalActions()
	const { toggleCollectionOfferSuccessModal } =
		useCollectionOfferSuccessModalActions()
	const { togglePurchaseLoadingModal, setHeading } =
		usePurchaseLoadingModalActions()
	const { toggleSomethingWentWrongModal } =
		useSomethingWentWrongModalActions()
	const { isOpen, collection } = useMakeCollectionOfferModalState()

	useEffect(() => {
		if (!collection) return

		setSelectedCurrency(
			collection.collectionOffer
				? collection.collectionOffer.currency
				: collection?.currencies[0]
		)
		setBidAmount(
			collection.collectionOffer ? collection.collectionOffer.price : null
		)
		if (
			collection.collectionOffer &&
			collection.collectionOffer.traitName !== 'No Trait'
		) {
			setSelectedTrait(
				collection.collectionOffer
					? {
						key: collection.collectionOffer.traitName,
						value: collection.collectionOffer.traitValue
					}
					: undefined
			)
			setShow(true)
		}
	}, [collection])

	const inputRef = useRef(null)
	const toast = useToast()

	const { mutateAsync: makeCollectionOffer } = useCollectionMakeOffer()
	const { data, isLoading, status } = useFetchCollectionTraits(
		collection?.contractAddress
	)

	const traitRef = useRef(null)
	useOnClickOutside(traitRef, () => {
		setShowDropdown(false)
	})

	const chainid = chainIdMap[collection?.analytics.chain]
	const tokenAddress = contracts[selectedCurrency?.toLowerCase()]
	const tokenContract = useTokenContract(tokenAddress, chainid)
	const { approve } = useApproveToken(tokenAddress, chainid)

	const marketplaceContractAddress = collection
		? contracts.marketplace[chainid]
		: null
	const marketplaceContract = useMarketplaceContract(
		marketplaceContractAddress,
		chainid
	)

	const { data: tokenBalance } = useSWR(
		['tokenBalance', tokenContract, refreshBalance],
		async () => {
			const decimals = await tokenContract.read.decimals()
			const rawBalance = await tokenContract.read.balanceOf([account])
			return parseFloat(formatUnits(rawBalance, decimals))
		}
	)

	const debounceValidateBidAmount = useCallback(
		debounce((bidAmount) => validateBidAmount(bidAmount), 500),
		[account, validateBidAmount]
	)

	const walletType = localStorage.getItem('walletType')

	useEffect(() => {
		debounceValidateBidAmount(bidAmount)
	}, [selectedCurrency, bidAmount, tokenBalance, numberOfCopies])

	useEffect(() => {
		if (status === 'success') {
			if (data.status) {
				setTraits(data.data)
			}
		}
	}, [status, data])

	useEffect(() => {
		if (account && bidAmount) {
			validateBidAmount(bidAmount)
		}
	}, [account, bidAmount, tokenBalance, selectedCurrency])

	useEffect(() => {
		const nextDate =
			new Date().getTime() + selectedInterval.duration * 60000

		const formatDate = format(new Date(nextDate), 'dd-MM-yyyy hh:mm a')
		const dateTimeArray = formatDate.split(' ')
		setDate(dateTimeArray[0])
		setSelectedDate(nextDate)
		setTime(dateTimeArray[1] + ' ' + dateTimeArray[2])
	}, [selectedInterval, isOpen])

	if (!collection) {
		return null
	}

	const price = numberOfCopies * bidAmount

	function validateBidAmount(floatValue) {
		setHasFunds(false)
		if (numberOfCopies * floatValue >= tokenBalance) {
			setHasFunds(false)
		} else {
			setHasFunds(true)
		}
		let isBidHighest = false;
		if (
			floatValue > 0 &&
			(
				(selectedCurrency === 'USDT' && floatValue >= collection.usdtMinOfferPrice) ||
				(selectedCurrency === 'WOR' && floatValue >= collection.worMinOfferPrice) ||
				(selectedCurrency === 'BUSD' && floatValue >= collection.busdMinOfferPrice) ||
				(['WBNB', 'WMATIC', 'WETH'].includes(selectedCurrency) && floatValue >= collection.minOfferPrice)
			)
		) {
			isBidHighest = true;
		}
		setIsBidHighest(isBidHighest);
	}

	const handleBidAmountChange = (values) => {
		const { floatValue } = values
		setBidAmount(floatValue)
		debounceValidateBidAmount(floatValue)
	}

	const handleIntervalChange = (interval) => {
		setSelectedInterval(interval)
	}

	const handleNumberOfCopiesChange = (values) => {
		const { floatValue } = values
		setNumberOfCopies(floatValue)
	}

	const handleNumberOfCopiesDecrement = () => {
		setNumberOfCopies(numberOfCopies - 1)
	}

	const handleNumberOfCopiesIncrement = () => {
		setNumberOfCopies((currentNumberOfCopies) => {
			if (currentNumberOfCopies === undefined) return 2
			return numberOfCopies + 1
		})
	}

	const intervalItems = intervals.map((interval) => (
		<DropdownItem
			key={interval.label}
			onClick={() => handleIntervalChange(interval)}
		>
			<div>{interval.label} </div>
			{selectedInterval.label === interval.label && (
				<div>
					<CheckTick />
				</div>
			)}
		</DropdownItem>
	))



	const handleMakeOffer = async () => {
		setHeading('Make Offer')
		togglePurchaseLoadingModal()

		const provider = getProvider()
		const web3 = new Web3(provider)

		try {
			if (walletType === 'okx') {
				if (
					collection.analytics.chain !== chainMap[parseInt(window.okxwallet.chainId)]
				) {
					await switchNetwork(chainIdMap[collection.analytics.chain])
				}
			} else {
				if (collection.analytics.chain === 'binance' && chain.id !== chainId.BINANCE) {
					await switchNetworkAsync(chainId.BINANCE)
				} else if (
					collection.analytics.chain === 'ethereum' &&
					chain.id !== chainId.ETHEREUM
				) {
					await switchNetworkAsync(chainId.ETHEREUM)
				} else if (
					collection.analytics.chain === 'polygon' &&
					chain.id !== chainId.AMOY
				) {
					await switchNetworkAsync(chainId.AMOY)
				}
			}

			const expiry = Math.floor(selectedDate / 1000)
			const nftAddress = collection.contractAddress
			const hash = await marketplaceContract.read.getHash([
				numberOfCopies,
				expiry,
				nftAddress,
				account
			])

			const sign = await web3.eth.personal.sign(hash, account)

			const { v, r, s } = Signature.from(sign)
			const signTuple = [v, r, s]

			await approve(
				(bidAmount * numberOfCopies).toString(),
				marketplaceContractAddress
			)

			setCollection(collection)

			let collectionOffer = {
				signTuple,
				expiry,
				price: bidAmount,
				quantity: numberOfCopies,
				contractAddress: collection.contractAddress,
				currency: selectedCurrency,
				duration: selectedInterval.format,
				address: account
			}

			if (selectedTrait) {
				collectionOffer = {
					...collectionOffer,
					trait: {
						traitName: selectedTrait.key,
						traitValue: selectedTrait.value
					}
				}
			}
			const offer = await makeCollectionOffer(collectionOffer)

			if (offer.status) {
				setNumberOfCopies(1)
				queryClient.refetchQueries(['collection_offer'])
				setBidAmount()
				setShowDropdown(false)
				setShow(false)
				setSelectedTrait()
				setRefreshBalance(!refreshBalance)
				toggleMakeCollectionOfferModal()
				toggleCollectionOfferSuccessModal()
				setSearchText('')
				return
			} else {
				toast.error(offer.message)
			}
		} catch (error) {
			toggleSomethingWentWrongModal()
		} finally {
			togglePurchaseLoadingModal()
		}
	}

	const handleOnChange = (e) => {
		if (e.target.value === '') {
			setSearchText(e.target.value)
			setTraits(data.data)
			return
		}

		const traits = data.data
			.filter(
				(t) =>
					t.values.findIndex((v) =>
						v.traitName
							.toLowerCase()
							.includes(e.target.value.toLowerCase())
					) !== -1
			)
			.map((t) => {
				return {
					...t,
					values: t.values.filter((v) => {
						return v.traitName
							.toLowerCase()
							.includes(e.target.value.toLowerCase())
					})
				}
			})

		setSearchText(e.target.value)
		setTraits(traits)
	}

	const handleOnKeyDown = (e) => {
		if (e.key === 'Enter' && traits.length > 0) {
			const [trait] = traits
			const [value] = trait.values
			setSelectedTrait({ key: trait.key, value: value.traitName })
			setShowDropdown(false)
		}
	}

	return (
		<>
			<Modal
				isOpen={isOpen}
				centered='true'
				className='mymodal'
				backdropClassName='selCurBp'
				keyboard={false}
			>
				<ModalHeader
					toggle={toggleMakeCollectionOfferModal}
					close={
						<CloseIcon
							onClose={() => {
								toggleMakeCollectionOfferModal()
								setNumberOfCopies(1)
								setBidAmount()
								setShowDropdown(false)
								setShow(false)
								setSelectedTrait()
								setSearchText('')
							}}
						/>
					}
				>
					<h5 className='modal-title'>{t('Make Collection Offer')}</h5>
				</ModalHeader>
				<ModalBody>
					<div>
						<div className='cartItem justify-content-center'>
							<div className='itemImg'>
								{typeof collection?.logo == 'string' &&
									collection?.logo !== undefined &&
									!collection?.logo?.startsWith(
										process.env.S3_URL
									) &&
									!collection?.logo?.startsWith('ipfs') &&
									collection?.logo !== null ? (
									<img
										alt='asset'
										src={
											collection.logo
												? collection.logo
												: DefaultImage
										}
										onError={handleImageError}
									/>
								) : (
									<img alt='asset' src={DefaultImage} />
								)}
							</div>
							<div className='flex-grow-1 p-3 d-flex align-items-center'>
								<div className='d-flex justify-content-between align-items-center flex-grow-1'>
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center'
										}}
									>
										<div className='itemName'>
											{collection.collectionName}{' '}
											{collection.isVerified && (
												<img
													src={yellowtick20}
													alt='yellowtick20'
													className='ml-1'
												/>
											)}
										</div>
										{!!selectedTrait && (
											<p
												style={{ marginBottom: '0' }}
											>{`${selectedTrait.key}: ${selectedTrait.value}`}</p>
										)}
									</div>
									<span className='fs-14 fw-400 dark-text-secondary'>
										{price
											? `${formatAmount(
												price
											)} ${selectedCurrency}`
											: `${selectedCurrency}--`}
									</span>
								</div>
							</div>
						</div>
					</div>
					<hr className='hrCls' />

					<div className='borderRoundedCard'>
						<div class='d-flex justify-content-between'>
							<div className='fs-14 fw-400 dark-text-secondary'>
								{t('Your Balance')}
							</div>
							<div className='fs-16 fw-600 dark-text'>
								{tokenBalance === undefined
									? 'Loading...'
									: `${parseFloat(
										tokenBalance.toFixed(6)
									)} ${selectedCurrency}`}
							</div>
						</div>
					</div>

					<hr className='hrCls mt-3' />
					<div className='position-relative zindex1'>
						<div className='d-flex justify-content-between mb-1'>
							<span className='fs-14 fw-600 dark-text'>
								{t('Price')}
							</span>
							{!!bidAmount && !hasFunds ? (
								<span className='fs-14 fw-400 dark-red'>
									{t('Not enough funds')}
								</span>
							) : !!bidAmount && !isBidHighest ? (
								<span className='fs-14 fw-400 dark-red'>
									{t(`Minimum offer price is ${parseFloat(
										selectedCurrency === 'USDT' ? collection.usdtMinOfferPrice :
											selectedCurrency === 'WOR' ? collection.worMinOfferPrice :
												selectedCurrency === 'BUSD' ? collection.busdMinOfferPrice :
													collection.minOfferPrice
									).toFixed(6)} ${selectedCurrency}`)}
								</span>
							) : null}
						</div>
						<div className='form-group formInputs position-relative zindex1'>
							<InputGroup
								className={`${!!bidAmount && !hasFunds || !!bidAmount && !isBidHighest ? 'err' : ''
									}`}
							>
								<NumericFormat
									className='form-control mx-auto'
									value={bidAmount}
									allowNegative={false}
									customInput={Input}
									onValueChange={handleBidAmountChange}
								/>
								<InputGroupAddon addonType='append'>
									{collection.currencies.length === 1 ? (
										<InputGroupText
											className='pr-0'
											style={{ color: 'white' }}
										>
											{collection.currencies[0]}
										</InputGroupText>
									) : (
										<InputGroupText className='pr-0'>
											<UncontrolledDropdown>
												<DropdownToggle className='input-Dropdown'>
													{selectedCurrency}
													<i className='fas fa-angle-down ml-2'></i>
												</DropdownToggle>
												<DropdownMenu
													right
													className='ig-dmenu'
													style={{ zIndex: '999' }}
												>
													{collection.currencies.map(
														(currency) => (
															<DropdownItem
																key={currency}
																onClick={() => {
																	setSelectedCurrency(
																		currency
																	)
																}}
															>
																{currency}
															</DropdownItem>
														)
													)}
												</DropdownMenu>
											</UncontrolledDropdown>
										</InputGroupText>
									)}
								</InputGroupAddon>
							</InputGroup>
						</div>{' '}
						<div className='d-flex justify-content-center align-items-center mb-3'>
							<div className='d-flex flex-column justify-content-between mb-1 flex-grow-1'>
								<p className='fs-14 fw-600 dark-text'>
									{t("Quantity")}
								</p>
								<p className='' style={{ fontSize: '13px' }}>
									{t("Offers can be accepted separately")}
								</p>
							</div>
							<div
								className='btn copies ml-auto d-flex'
								style={{ cursor: 'default' }}
							>
								<button
									disabled={
										numberOfCopies <= 1 ||
										numberOfCopies === undefined
									}
									onClick={handleNumberOfCopiesDecrement}
								>
									<img src={decreaseIcon} />
								</button>
								<div className='copies-input'>
									<NumericFormat
										className='form-control mx-auto'
										placeholder='1'
										decimalScale={0}
										value={numberOfCopies}
										allowNegative={false}
										customInput={Input}
										onValueChange={
											handleNumberOfCopiesChange
										}
										isAllowed={(values) => {
											const { floatValue } = values
											return (
												floatValue >= 1 ||
												floatValue === undefined
											)
										}}
									/>
								</div>
								<button
									disabled={numberOfCopies >= 100}
									onClick={handleNumberOfCopiesIncrement}
								>
									<img src={increaseIcon} />
								</button>
							</div>
						</div>
						{data?.data?.length !== 0 && (
							<div className='d-flex justify-content-between mb-3'>
								<span className='fs-14 fw-600 dark-text'>
									{t('Traits')}
								</span>
								{!show && (
									<button
										class='bg-transparent p-0 border-0'
										onClick={() => setShow(true)}
									>
										<img
											src={increaseIcon}
											alt='increaseIcon'
										/>
									</button>
								)}
								{show && (
									<button
										class='bg-transparent p-0 border-0'
										onClick={() => setShow(false)}
									>
										<img
											src={decreaseIcon}
											alt='decreaseIcon'
										/>
									</button>
								)}
							</div>
						)}
						{show && (
							<div className='traitFilterDropdown' ref={traitRef}>
								<div className='form-group formInputs'>
									<InputGroup>
										<InputGroupAddon addonType='prepend'>
											<InputGroupText className='px-0'>
												<img
													src={searchIcon}
													alt='searchIcon'
												/>
											</InputGroupText>
										</InputGroupAddon>
										<Input
											placeholder={t('Search traits')}
											innerRef={inputRef}
											readOnly={!!selectedTrait}
											onFocus={(e) => {
												if (e.currentTarget.readOnly) {
													setTraits(data.data)
												}
												setShowDropdown(true)
											}}
											value={
												!!selectedTrait
													? `${selectedTrait.key}: ${selectedTrait.value}`
													: searchText
											}
											onChange={handleOnChange}
											onKeyDown={handleOnKeyDown}
										/>
										{!!selectedTrait && (
											<InputGroupAddon
												addonType='append hover'
												onClick={() => {
													setShowDropdown(true)
													setSearchText('')
													setSelectedTrait()
													inputRef.current.focus()
												}}
											>
												<InputGroupText className='px-0'>
													<img
														src={closeIcon}
														alt='closeIcon'
													/>
												</InputGroupText>
											</InputGroupAddon>
										)}
									</InputGroup>
								</div>
								{showDropdown && (
									<div
										className='filterSubmenuModal scroller'
										style={{ maxHeight: '200px' }}
									>
										<div className='filterhead'>
											<span className='heading'>
												{t("Traits")}
											</span>
											<span className='heading'>
												{t("Floor")}
											</span>
										</div>

										<hr />
										{isLoading && (
											<div className='trait-loader'>
												<Spinner />
											</div>
										)}
										{!isLoading && traits.length !== 0 ? (
											traits?.map((trait, i) => {
												return (
													<React.Fragment key={i}>
														{i !== 0 && <hr />}
														<Trait
															trait={trait}
															setSelectedTrait={
																setSelectedTrait
															}
															onTraitValueClick={() => {
																setShowDropdown(
																	false
																)
															}}
														/>
													</React.Fragment>
												)
											})
										) : (
											<div
												style={{
													width: '100%',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center'
												}}
											>
												<p
													style={{
														fontSize: '12px'
													}}
												>
													{t("No traits matching your search")}
												</p>
											</div>
										)}
									</div>
								)}
							</div>
						)}
						<div className='d-flex justify-content-between mb-1'>
							<span className='fs-14 fw-600 dark-text'>
								{t("Duration")}
							</span>
						</div>
						<div className='row form-row position-relative'>
							<div className='col-lg-8'>
								<div className='form-group formInputs'>
									<InputGroup>
										<Input
											value={date}
											placeholder={date}
											disabled
										/>
										<InputGroupAddon addonType='append'>
											<InputGroupText className='pr-0'>
												<span className='fs-16 fw-400'>
													{time}
												</span>
											</InputGroupText>
										</InputGroupAddon>
									</InputGroup>
								</div>
							</div>
							<div className='col-lg-4'>
								<Dropdown
									className='customdropdown w-100'
									isOpen={dropdownOpen}
									toggle={() =>
										setDropdownOpen(!dropdownOpen)
									}
								>
									<DropdownToggle className='d-flex align-items-center justify-content-between w-100'>
										{selectedInterval.label}
										<i
											className={`ml-auto fas ${dropdownOpen
												? 'fa-angle-up'
												: 'fa-angle-down'
												}`}
										></i>
									</DropdownToggle>
									<DropdownMenu right>
										{intervalItems}
									</DropdownMenu>
								</Dropdown>
							</div>
						</div>
					</div>

					<div className='mt-4'>
						<button
							className='btn btn-block checkoutBtn'
							disabled={!bidAmount || !hasFunds || !isBidHighest}
							onClick={handleMakeOffer}
						>
							{t('Make Offer')}{' '}
						</button>
					</div>
				</ModalBody>
			</Modal>
		</>
	)
}

function Trait({ trait, onTraitValueClick, setSelectedTrait }) {
	return (
		<>
			<div className='filterSubHead mb-2'>
				<h3>{trait.key}</h3>
			</div>
			<hr />
			{trait.values.map((value, i) => (
				<TraitValue
					key={i}
					value={value}
					onTraitValueClick={() => {
						setSelectedTrait({
							key: trait.key,
							value: value.traitName
						})
						onTraitValueClick()
					}}
				/>
			))}
		</>
	)
}

function TraitValue({ value, onTraitValueClick }) {
	return (
		<div className='filterRow hover' onClick={onTraitValueClick} onKeyDown={(e) => {
			if (e.key === 'Enter' || e.key === ' ') {
				onTraitValueClick();
			}
		}}
			role="button"
			tabIndex={0}>
			<div>
				<div className='filterTxt'>{value.traitName}</div>
				<p>{value.percentage}%</p>
			</div>
		</div>
	)
}
