import { useQuery } from '@tanstack/react-query'

import { ThemeContext } from '../../../views/context/context'
import { useContext } from 'react'
import DataService from '../../../lib/DataService'

const { getRequest } = DataService()
function collectionSaleChart(collectionAddress,Analyticsfilter) {
	
	return getRequest(`collection/sales-chart?contractAddress=${collectionAddress}&filter=${Analyticsfilter}`)

}

export function useCollectionSaleChart(collectionAddress) {
	const { Analyticsfilter, setAnalyticsFilter } = useContext(ThemeContext)

	return useQuery({
        queryKey: ['sale_chart', collectionAddress,Analyticsfilter],
		queryFn: () => collectionSaleChart(collectionAddress,Analyticsfilter),
		enabled: !!(collectionAddress)
	})
}
