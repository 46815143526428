import { useQuery } from '@tanstack/react-query'

import { ThemeContext } from '../../../views/context/context'
import { useContext } from 'react'
import DataService from '../../../lib/DataService'

const { getRequest } = DataService()
function collectionVolumeChart(collectionAddress,Analyticsfilter) {
	
	return getRequest(`collection/volume-chart?contractAddress=${collectionAddress}&filter=${Analyticsfilter}`)

}

export function useCollectionVolumeChart(collectionAddress) {
	const { Analyticsfilter, setAnalyticsFilter } = useContext(ThemeContext)
	return useQuery({
		queryKey: ['volume_chart', collectionAddress,Analyticsfilter],
		queryFn: () => collectionVolumeChart(collectionAddress,Analyticsfilter),
		enabled: !!collectionAddress
	})
}
