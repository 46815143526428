import React, { useEffect, useState, useContext } from 'react';
import { Modal } from 'reactstrap';

import IconModal from '../../assets/images/IconModal.png';
import noItemfoundImage from '../../assets/images/collection/noItemfoundImage.svg';
import cnddsLight from '../../assets/images/cnddsLight.svg'
import defaultImage from '../../assets/images/homeExplorer/placeholder.png'
import './Settings.scss';
import loader from '../../assets/images/Loader.png'
import { useNavigate, useParams } from 'react-router-dom';
import {
  useAccount,
} from 'wagmi'
import { ThemeContext } from '../context/context';
import { useToast } from '../../utils/toast/customSuccess'
import { handleImageError } from '../../hooks/useHandleImg';
import { nativeCoinMap, chainIconMap, nativecoin } from '../../utils/getFilterData';
import { useTranslation } from 'react-i18next';
import DataService from '../../lib/DataService';
export const SettingsOffers = () => {

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [loading, setLoading] = useState(false);
  const { postRequest } = DataService()


  const { address } = useAccount()
  const toast = useToast()
  const navigate = useNavigate()

  const { id } = useParams()
  const { t } = useTranslation()

  useEffect(() => {
    fetchoffer()
  }, [address])

  const [offer, setOffer] = useState([])
  const fetchoffer = async () => {
    setLoading(true)
    const offerreceived = await postRequest("user/getCollectionSettingOffers", { "address": id ? id : address })
    if (offerreceived.status == true) {
      setOffer(offerreceived.data)
      setLoading(false)
    }
    else {
      setLoading(false)
    }
  }

  const { activeTab5, setActiveTab5, offerreceivedTab, setOfferReceivedTab } = useContext(ThemeContext)


  const handleOfferReceived = (e, data) => {
    navigate("/profile")
    e.preventDefault()
    setOfferReceivedTab(data)
  }


  const [status, setstatus] = useState("false")
  const [minimumOffer, setMinOffer] = useState([])

  const [usdprice, setUsdPrice] = useState("")

  const handleOffer = (e, data) => {
    if (e.target.value !== "") {
      setstatus(true)
      const newUsers = offer.map((user) => ({
        ...user,
        minimumOffer: user._id == data._id ? e.target.value : user.minimumOffer
      }));
      setOffer(newUsers)

    }
    else {

      const newUsers = offer.map((user) => ({
        ...user,
        minimumOffer: user._id == data._id ? "" : user.minimumOffer
      }));
      setOffer(newUsers)
    }
  }

  const Offers = async () => {
    setLoading(true)
    const offerreceived = await postRequest("user/update_offers", { "address": address, "update": offer })
    if (offerreceived.status == true) {
      setLoading(false)
      setstatus(false)
      toast.success(offerreceived.message)
    }
    else {
      setLoading(false)
      setstatus(false)
      toast.error(offerreceived.message)
    }
  }


  return (
    <>
      <div className={`text-center ${loading ? 'spinner' : 'd-none'}`}>
        <img src={loader} alt='' className='spinAnimate' />
      </div>
      <div className="d-flex align-items-center mb-3">
        <h3 className="settingsHeading mb-0">{t("Offers")}</h3>
      </div>
      <div className="createCollectionCard mb-3">
        <form className="w-100">
          <div className="form-row">
            <div className="col-12 mb-3">
              <span className="formLabel">{t("Set Minimum Offer")}</span>
              <p className="greyTxt mb-3">{t("Set a minimum offer for collections to ignore low offers")}.</p>
              <button className="btn darkBtn" onClick={(e) => { handleOfferReceived(e, "OfferReceived"); setActiveTab5("7") }}>{t("View My Offers")}</button>
            </div>

            <div className="col-12 ">
              {offer && offer.length > 0 ?
                offer && offer.map((data, index) => (
                  <div className="borderedCnt offerRow mb-3" >
                    <div className="row align-items-center w-100 mx-0">
                      <div className="col-md-8 col-lg-12 col-xl-8 d-flex flex-wrap align-items-center">
                        <img src={data.logoImage ? data.logoImage : defaultImage} height="50" width="50" style={{ objectFit: "cover", borderRadius: "50%" }} className="mr-2 mb-2" onError={handleImageError} />
                        <div className="d-flex flex-column">
                          <h5 className="fs-16 fw-600 mr-2">{data.collectionName} <img src={chainIconMap[data.chain]} className="ml-2" /></h5>
                          <p className="fs-12 greyTxt">{t("Floor Price")}: <span className="">{parseFloat(data.floorPrice)} {nativeCoinMap[data.chain]}</span></p>
                          <p className="fs-12 greyTxt mb-0">{data.count} {t("Items")}</p>
                        </div>
                      </div>

                      <div className="col-md-4 col-lg-12 col-xl-4">
                        <p className="fs-12 greyTxt text-xl-right">{t("Minimum Offer")}</p>
                        <div className="input-group verified mb-2">
                          <input type="number" className="form-control" name={`minimumOffer${index}`} onKeyUp={(e) => handleOffer(e, data)} defaultValue={data.minimumOffer} onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} min="0" />
                          <div className="input-group-append">
                            <div className="input-group-text">
                              <span className="greyTxt">{nativecoin[data.chain]}</span>
                            </div>
                          </div>
                        </div>
                        <p className="fs-12 greyTxt text-xl-right">${(data.minimumOffer ? (data.minimumOffer * data.usdPrice).toFixed(8) : 0)}</p>
                      </div>
                    </div>
                  </div>
                ))
                :
                <div className="col-12">
                  <div className='noItemFound'>
                    <div className='text-center'>
                      <img src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : noItemfoundImage} alt="noItemfoundImage" className='img-fluid' />
                    </div>
                    <div className='nofounttxt mb-2'>
                      {t("No collections to manage offers")}.
                    </div>
                    <p className="greyTxt mb-4">{t("No collections to manage offers")}.</p>
                  </div>
                </div>
              }
              {offer && offer.length > 0 ?
                status == true ?
                  <button type="button" className="btn btn-block gradientBtn mb-2" onClick={Offers}>{t("Save")}</button>
                  :
                  <button type="button" className="btn btn-block gradientBtn mb-2" disabled>{t("Save")}</button>
                :
                ""
              }
            </div>
          </div>
        </form>
      </div>

      <Modal isOpen={modal} toggle={toggle} centered="true" className="curMdl createScsMdl" backdropClassName="selCurBp" keyboard={false}>
        <div className="createContent">
          <button className="btn closeBtn" onClick={toggle}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z" fill="#595F6A" />
            </svg>
          </button>

          <div className="w-100">
            <div className="d-flex justify-content-center">
              <img src={IconModal} />
            </div>
            <h3 className="walletHeading my-2">{t("Are you sure?")}</h3>
            <h3 className="walletSubHeading mb-3 ">{t("By turning this feature off you may receive items that are reported stolen or no longer match the traits you've specified")}.</h3>
            <button type="button" className="btn btn-block gradientBtn mb-2">{t("Continue")}</button>
            <button type="button" className="btn btn-block darkBtn">{t("Get Back")}</button>
          </div>
        </div>
      </Modal>

    </>
  );
};

export default SettingsOffers;