import React, { useContext, useEffect, useState } from 'react';


import yellowTick from '../../assets/images/collection/yellow-tick_20px.svg';
import noItemfoundImage from '../../assets/images/collection/noItemfoundImage.svg';
import cnddsLight from '../../assets/images/cnddsLight.svg'
import {
    useAccount,
} from 'wagmi'
import { useNavigate, useParams } from 'react-router-dom';
import { ThemeContext } from '../context/context';
import { useTranslation } from 'react-i18next';
import loader from '../../assets/images/Loader.png'
import ResponsivePagination from 'react-responsive-pagination';
import defaultImage from '../../assets/images/homeExplorer/placeholder.png'
import { handleImageError } from '../../hooks/useHandleImg';
import { nativeCoinMap } from '../../utils/getFilterData';
import DataService from '../../lib/DataService';
import arrow from '../../assets/images/Icon2.png';

const landPerRow = 6

const listtag = [
    {
        name: 'listed'
    },
    {
        name: 'On Auction'
    },
    {
        name: 'Binance'
    },
    {
        name: 'Loaded Lions'
    },
    {
        name: '123-11,623.442 ETH'
    },
    {
        name: 'ETH'
    }
]


const ListViewItem = (props) => {


    const { t } = useTranslation()
    const [videoError, setVideoError] = useState(false)



    const { address } = useAccount()

    const [loading, setLoading] = useState(false);

    const { id } = useParams()


    const { collectedsearch, setCollectedSearch, collectedfilter, setCollectedFilter, collectedstatus, setcollectedStatus, checkcollectedChain, setcollectedCheckChain, chooselistingcollectedchain, setListingCollectedChain, collectedminAmount, setCollectedMinAmount, collectedmaxAmount, setCollectedMaxAmount, Collectedstatus, setCollectedStatus, createdfiltersortby, setCreatedFilterSortby, createdfiltersortorder, setCreatedFilterSortorder, contractAddresscollected, setContarctAddressCollected, checkcollectedCurrency, setcollectedCheckCurrency } = useContext(ThemeContext)
    const [page, setPage] = useState(1)
    const { getRequest } = DataService()

    useEffect(() => {
        GetCollected1()
    }, [address, Collectedstatus, collectedsearch, collectedfilter, collectedstatus, checkcollectedChain, Collectedstatus == true ? chooselistingcollectedchain : "",
        Collectedstatus == true ? collectedminAmount : "",
        Collectedstatus == true ? collectedmaxAmount : "", createdfiltersortby, createdfiltersortorder, id, page, contractAddresscollected, checkcollectedCurrency])


    const [getcollected, setGetcollected] = useState('')
    const [totalactivitycount, setTotalActivity] = useState("")
    const [count, setCount] = useState("")
    const handleVideoError = () => {
        setVideoError(true);
    };
    function handlePageChange(page) {
        setPage(page);
    }

    const GetCollected1 = async () => {
        setLoading(true)
        const fetch = await getRequest(`user/collected-nfts?address=${id ? id : address}&page=${collectedsearch !== "" ? 1 : contractAddresscollected > 0 ? 1 : checkcollectedChain > 0 ? 1 : Collectedstatus == true ? 1 : createdfiltersortorder !== "desc" ? 1 : createdfiltersortby !== "_id" ? 1 : collectedstatus > 0 ? 1 : checkcollectedCurrency > 0 ? 1 : page}&limit=15&sortField=${createdfiltersortby}&sortOrder=${createdfiltersortorder}&filter=${collectedsearch}&collectionName=${contractAddresscollected &&
            contractAddresscollected.map(item => {
                return (
                    item.collectionName
                )
            })}&contractAddress=${contractAddresscollected &&
            contractAddresscollected.map(item => {
                return (
                    item.contractAddress
                )
            })}&chain=${checkcollectedChain}&currencyName=${checkcollectedCurrency}&sortStatusField=${collectedstatus}&currency=${Collectedstatus == true ? chooselistingcollectedchain : ""}&minPrice=${Collectedstatus == true ? collectedminAmount : ""}&maxPrice=${Collectedstatus == true ? collectedmaxAmount : ""}`)
        if (fetch.status == true) {
            setGetcollected(fetch.data)
            setTotalActivity(Math.ceil(fetch.count / 15))
            setCount(fetch.count)
            setLoading(false)
            setPage(collectedsearch !== "" ? 1 : contractAddresscollected > 0 ? 1 : checkcollectedChain > 0 ? 1 : Collectedstatus == true ? 1 : createdfiltersortorder !== "desc" ? 1 : createdfiltersortby !== "_id" ? 1 : collectedstatus > 0 ? 1 : checkcollectedCurrency > 0 ? 1 : page)
        }
        else {
            setGetcollected('')
            setTotalActivity('')
            setCount('')
            setLoading(false)
            setPage(1)
        }
    }

    const [sortOrder, setSortOrder] = useState({
        field: '',
        ascending: true,
    });

    const handleSort = (field) => {
        setSortOrder((prevSortOrder) => ({
            field,
            ascending: field === prevSortOrder.field ? !prevSortOrder.ascending : true,
        }));
    };

    const sortedData = [...getcollected].sort((a, b) => {
        const aValue = a[sortOrder.field];
        const bValue = b[sortOrder.field];

        if (aValue === undefined || bValue === undefined) {
            return sortOrder.ascending ? (aValue === undefined ? 1 : -1) : bValue === undefined ? 1 : -1;
        }

        if (typeof aValue !== typeof bValue) {
            return sortOrder.ascending ? (typeof aValue > typeof bValue ? 1 : -1) : typeof bValue > typeof aValue ? 1 : -1;
        }

        const compareResult = aValue.localeCompare ? aValue.localeCompare(bValue) : aValue - bValue;

        return sortOrder.ascending ? compareResult : -compareResult;
    });


    const listViewrow = sortedData && sortedData.map((item, index) =>
        <>
            <tr onClick={() => navigate(`/assets/${item.chain}/${item.contractAddress}/${item.nftId}`)} style={{ cursor: "pointer" }}>
                <td >
                    <div className='d-flex align-items-center'>
                        <div className='tblImg'>


                            {typeof item?.asset == "string" && item?.asset !== undefined && !item?.asset?.startsWith(process.env.S3_URL) && !item?.asset?.startsWith('ipfs') && item?.asset !== null ?

                                item?.asset?.endsWith('mp4') ?
                                    videoError ? <img src={defaultImage} />
                                        :
                                        <video loop autoPlay muted playsInline >
                                            <source src={item?.asset} type="video/mp4" onError={handleVideoError} />
                                        </video>
                                    :
                                    item?.asset !== 'undefined' ?
                                        <img src={item?.asset ? item?.asset : defaultImage} alt={`item.imgsrc`} onError={handleImageError} />

                                        :
                                        <img src={defaultImage} alt={`item.imgsrc`} />
                                :
                                <img src={defaultImage} alt={`item.imgsrc`} />
                            }

                        </div>

                        <div>
                            <div className='fw-600 ml-2'>{item.nftName !== "" ? item.nftName : item.nftId ? `#${item.nftId}` : '---'} #{item.supply}</div>
                            <div className='smTxt dark-text-secondary ml-2'>{item.collectionName}
                                <img src={item.verification == "Verified" ? yellowTick : ""} height="18" className="ml-1" /></div>
                        </div>
                    </div>
                </td>
                <td><span className="dark-text-grey">{item.floorPrice ? parseFloat((item.floorPrice)?.toFixed(8)) : "--"} {item.floorPrice ? nativeCoinMap[item.chain] : ""}</span></td>
                <td className='text-right' style={{ opacity: "0.5" }}>{item.price ? parseFloat((item.price)?.toFixed(8)) : "--"} {item.price ? nativeCoinMap[item.chain] : ""}</td>
                <td className='text-right' style={{ opacity: "0.5" }}>{item.highestBid ? item.highestBid : "--"} {item.highestBid ? nativeCoinMap[item.chain] : ""}</td>
                <td className='text-right'>{item.price ? parseFloat((item.price)?.toFixed(8)) : t("Not Listed")} {item.price ? nativeCoinMap[item.chain] : ""}</td>
                <td className="text-right"><span className="dark-text-grey" style={{ opacity: "0.5" }}>
                    --
                </span></td>
            </tr>
        </>
    )


    const navigate = useNavigate()


    const closecollectedsearch = () => {
        setCollectedSearch("")
    }
    const closecollectedfilter = () => {
        setCollectedFilter('Recently Received')
        setCreatedFilterSortby('_id')
        setCreatedFilterSortorder('desc')
    }
    const closecollectedstatus = (data) => {
        let a = collectedstatus.includes(data)
        if (a == true) {
            const index = collectedstatus.indexOf(data);
            if (index > -1) {
                collectedstatus.splice(index, 1);
                const nerArr = [...collectedstatus]
                setcollectedStatus(nerArr);
                if (nerArr.length == 0) {
                    setcollectedStatus([""]);
                }
            }

        }
    }

    const litstedTagLoop3 = collectedstatus && collectedstatus.map((item) => {
        return (
            <>
                {item == "" ?
                    ""
                    :
                    <>
                        <div className='tagLabel'>
                            <span style={{ textTransform: "capitalize" }}>{item}</span>
                            <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={() => closecollectedstatus(item)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        closecollectedstatus(item)
                                    }
                                }}
                                role="button" tabIndex={0}></i>
                        </div>
                    </>
                }
            </>
        );
    });


    const litstedTagLoop2 = checkcollectedChain && checkcollectedChain.map((item) => {
        return (
            <>
                {item == "" ?
                    ""
                    :
                    <>
                        <div className='tagLabel'>
                            <span style={{ textTransform: "capitalize" }}>{item}</span>
                            <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={() => closecollectedchain(item)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        closecollectedchain(item)
                                    }
                                }}
                                role="button" tabIndex={0}></i>
                        </div>
                    </>
                }
            </>
        );
    });

    const litstedTagLoop4 =
        checkcollectedCurrency &&
        checkcollectedCurrency.map(item => {
            return (
                <>
                    {item == '' ? (
                        ''
                    ) : (
                        <>
                            <div className='tagLabel'>
                                <span style={{ textTransform: "capitalize" }}>{item}</span>
                                <i
                                    className='fas fa-times ml-2'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => closecollectedcurrency(item)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' || e.key === ' ') {
                                            closecollectedcurrency(item)
                                        }
                                    }}
                                    role="button" tabIndex={0}
                                ></i>
                            </div>
                        </>
                    )}
                </>
            )
        })

    const closecollectedchain = (item) => {
        let a = checkcollectedChain.includes(item)

        if (a == true) {

            const index = checkcollectedChain.indexOf(item);
            if (index > -1) {
                checkcollectedChain.splice(index, 1);
                const nerArr = [...checkcollectedChain]
                setcollectedCheckChain(nerArr);
            }
        }
    }

    const closecollectedcurrency = item => {
        let a = checkcollectedCurrency.includes(item)

        if (a == true) {
            const index = checkcollectedCurrency.indexOf(item)
            if (index > -1) {
                checkcollectedCurrency.splice(index, 1)
                const nerArr = [...checkcollectedCurrency]
                setcollectedCheckCurrency(nerArr)
            }
        }
    }

    const litstedCollection =
        contractAddresscollected &&
        contractAddresscollected.map(item => {
            return (
                <>
                    {item == '' ? (
                        ''
                    ) : (
                        <>
                            <div className='tagLabel'>
                                <span>{item.collectionName}</span>
                                <i
                                    className='fas fa-times ml-2'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => closeCollection(item)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' || e.key === ' ') {
                                            closeCollection(item)
                                        }
                                    }}
                                    role="button" tabIndex={0}
                                ></i>
                            </div>
                        </>
                    )}
                </>
            )
        })

    const closeCollection = data => {
        let a = contractAddresscollected.some(item => item.contractAddress === data.contractAddress);
        if (a == true) {
            const updatedList = contractAddresscollected.filter(item => item.contractAddress !== data.contractAddress);
            setContarctAddressCollected(updatedList);
        }
    }

    const closesearch1 = () => {
        setCollectedMinAmount("")
        setCollectedMaxAmount("")
        setCollectedStatus(false)
    }


    const clearAll = () => {
        setCollectedSearch("")
        setcollectedStatus([""])
        setcollectedCheckChain([])
        setContarctAddressCollected([])
        setCollectedMinAmount("")
        setCollectedMaxAmount("")
        setCollectedStatus(false)
        setCollectedFilter('Recently Received')
        setCreatedFilterSortby('_id')
        setCreatedFilterSortorder('desc')
        setcollectedCheckCurrency([])
    }

    const Back = () => {
        setCollectedSearch("")
        setcollectedStatus([""])
        setcollectedCheckChain([])
        setContarctAddressCollected([])
        setCollectedMinAmount("")
        setCollectedMaxAmount("")
        setCollectedStatus(false)
        setCollectedFilter('Recently Received')
        setCreatedFilterSortby('_id')
        setCreatedFilterSortorder('desc')
        setcollectedCheckCurrency([])
    }

    const handlekeydown = (e) => {
        if (e.key === 'Enter' || e.key === ' ') {
            closesearch1()
        }
    }

    return (
        <>
            <div className={loading ? 'spinner' : 'd-none'}>
                <img src={loader} alt='' className='spinAnimate' />
            </div>

            <div className='py-3'>
                <div className='row mb-4'>
                </div>

                <div className='col-lg-6'><span class="cmntxt">{count ? count : ""} {count ? t("Items") : ""}</span></div>
                <div className='fiterSelectlabel mb-4'>
                    {collectedsearch ?
                        <div className='tagLabel'>
                            <span style={{ textTransform: "capitalize" }}>{collectedsearch}</span>
                            <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={closecollectedsearch}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        closecollectedsearch()
                                    }
                                }}
                                role="button" tabIndex={0}></i>
                        </div>
                        :
                        ""}

                    {collectedstatus.includes("") ? "" : litstedTagLoop3}
                    {litstedTagLoop2}{litstedTagLoop4}{litstedCollection}

                    {Collectedstatus == true ?
                        typeof chooselistingcollectedchain !== "undefined" && collectedminAmount && collectedmaxAmount ?
                            <div className='tagLabel'>
                                <span>{chooselistingcollectedchain} {collectedminAmount}-{collectedmaxAmount}</span>
                                <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={closesearch1} onKeyDown={handlekeydown} role="button" tabIndex={0}></i>
                            </div>
                            :
                            typeof chooselistingcollectedchain !== "undefined" && collectedminAmount ?
                                <div className='tagLabel'>
                                    <span>{chooselistingcollectedchain} {collectedminAmount}</span>
                                    <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={closesearch1} onKeyDown={handlekeydown} role="button" tabIndex={0}></i>
                                </div>
                                :
                                typeof chooselistingcollectedchain !== "undefined" && collectedmaxAmount ?
                                    <div className='tagLabel'>
                                        <span>{chooselistingcollectedchain} {collectedmaxAmount}</span>
                                        <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={closesearch1} onKeyDown={handlekeydown} role="button" tabIndex={0}></i>
                                    </div>
                                    :
                                    ""
                        :
                        ""
                    }

                    {Number(collectedstatus.filter(item => item.trim() !== "").length) + Number(checkcollectedChain?.length) + Number(contractAddresscollected?.length) + Number(collectedsearch.trim() === "" ? 0 : collectedsearch.trim().replace(/\s+/g, "").split(/\s+/).length) + Number(checkcollectedCurrency.length) > 1 ?
                        <button className='clearAllBtn' onClick={clearAll}>{t("Clear All")}</button>
                        :
                        ""
                    }
                </div>
            </div>
            <div className='table-responsive'>
                <table className='table table-borderless listViewTable'>
                    {
                        sortedData && sortedData.length > 0 ?
                            <thead>
                                <tr>
                                    <th>{t("Items")}</th>
                                    <th onClick={() => handleSort('floorPrice')}>{t("Floor Price")} {sortOrder.field !== 'floorPrice' ? <img src={arrow} width="14px" style={{  cursor: "pointer" }}/> : sortOrder.field === 'floorPrice' && (sortOrder.ascending ? <i class="fa fa-angle-up" style={{ fontSize: "14px", cursor: "pointer" }}></i> : <i class="fa fa-angle-down" style={{ fontSize: "14px", cursor: "pointer" }}></i>)}</th>
                                    <th className='text-right'>{t("Price")}</th>
                                    <th className='text-right' onClick={() => handleSort('highestBid')}>{t("Highest Bid")} {sortOrder.field !== 'highestBid' ? <img src={arrow} width="14px" style={{  cursor: "pointer" }}/> : sortOrder.field === 'highestBid' && (sortOrder.ascending ? <i class="fa fa-angle-up" style={{ fontSize: "14px", cursor: "pointer" }}></i> : <i class="fa fa-angle-down" style={{ fontSize: "14px", cursor: "pointer" }}></i>)}</th>
                                    <th className='text-right'onClick={() => handleSort('price')}>{t("Listing Price")} {sortOrder.field !== 'price' ? <img src={arrow} width="14px" style={{  cursor: "pointer" }}/> : sortOrder.field === 'price' && (sortOrder.ascending ? <i class="fa fa-angle-up" style={{ fontSize: "14px", cursor: "pointer" }}></i> : <i class="fa fa-angle-down" style={{ fontSize: "14px", cursor: "pointer" }}></i>)}</th>
                                    <th className='text-right'>{t("Difference")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            :
                            ""
                    }
                    <tbody>
                        {
                            sortedData && sortedData.length > 0 ?
                                listViewrow
                                :
                                <div className='noItemFound'>
                                    {loading == false ?
                                        <>
                                            <div className='text-center'>
                                                <img src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : noItemfoundImage} alt="noItemfoundImage" className='img-fluid' />
                                            </div>

                                            {collectedsearch || (collectedfilter == "Recently Received" ? "" : collectedfilter) || !collectedstatus.includes("") || (Collectedstatus == true ? typeof chooselistingcollectedchain !== "undefined" && collectedminAmount || collectedmaxAmount : "") || checkcollectedChain?.length > 0 || checkcollectedCurrency?.length > 0 || contractAddresscollected?.length > 0 ?
                                                <>
                                                    <div className='nofounttxt mb-4'>
                                                        {t("No items were found. Try to broaden your search.")}
                                                    </div>
                                                    <div>
                                                        <button className='applyBtn' onClick={Back}>
                                                            {t("Back to all items")}
                                                        </button>
                                                    </div>
                                                </>
                                                :
                                                <div className='nofounttxt mb-4'>
                                                    {t("Nothing to display")}
                                                </div>
                                            }
                                        </>
                                        :
                                        ""
                                    }
                                </div>

                        }
                    </tbody>
                </table>
            </div>

            <div className='row justify-content-center'>
                <div className='col-lg-4 col-md-6 col-sm-8'>
                    <div class="bri_pagenation2">
                        {sortedData && sortedData.length > 0 ?
                            <div class=''>
                                <nav aria-label="Page ">
                                    <ResponsivePagination
                                        total={totalactivitycount}
                                        current={page}
                                        onPageChange={page => handlePageChange(page)}
                                        previousLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.125 5.75L5.71875 1.125C5.84375 0.96875 6.09375 0.96875 6.25 1.125L6.84375 1.75C7 1.875 7 2.125 6.84375 2.28125L3.15625 6L6.84375 9.75C7 9.90625 7 10.125 6.84375 10.2812L6.25 10.9062C6.09375 11.0625 5.84375 11.0625 5.71875 10.9062L1.125 6.28125C0.96875 6.125 0.96875 5.90625 1.125 5.75Z" fill="#F7F7F7"></path></svg>}
                                        nextLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.84375 6.28125L2.28125 10.9062C2.125 11.0625 1.875 11.0625 1.75 10.9062L1.125 10.2812C0.96875 10.125 0.96875 9.90625 1.125 9.75L4.8125 6L1.125 2.28125C0.96875 2.125 0.96875 1.875 1.125 1.75L1.75 1.125C1.875 0.96875 2.125 0.96875 2.28125 1.125L6.84375 5.75C7 5.90625 7 6.125 6.84375 6.28125Z" fill="#F7F7F7"></path></svg>}
                                        previousClassName="page-link-1"
                                        nextClassName="page-link-1"
                                    />
                                </nav>
                            </div>
                            :
                            ""
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ListViewItem;