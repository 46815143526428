import React, { useEffect, useState } from 'react';

import Header from '../Header/Header';
import { Outlet, Link, useNavigate, useLocation } from 'react-router-dom';
import IconModal from '../../assets/images/IconModal.png';




import collectionBanner from '../../assets/images/collection/collectionBanner.png';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Modal, UncontrolledPopover, PopoverBody, UncontrolledTooltip } from 'reactstrap';
import { useToggleClassName } from '../../hooks/useToggleClassName';
import { useTranslation } from 'react-i18next';

const MintLayout = () => {
  const bannerimgsize = '100%';

  useEffect(() => {
    document.body.classList.add('collectionpages');
  }, []);


  const ProfileIcon = () => {
    return (
      <>
        <svg className="mr-2" width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.78125 9C12.0938 9 14 10.9062 14 13.2188V14.5C14 15.3438 13.3125 16 12.5 16H1.5C0.65625 16 0 15.3438 0 14.5V13.2188C0 10.9062 1.875 9 4.1875 9C5.09375 9 5.5 9.5 7 9.5C8.46875 9.5 8.875 9 9.78125 9ZM13 14.5V13.2188C13 11.4375 11.5625 10 9.78125 10C9.1875 10 8.5625 10.5 7 10.5C5.40625 10.5 4.8125 10 4.1875 10C2.40625 10 1 11.4375 1 13.2188V14.5C1 14.7812 1.21875 15 1.5 15H12.5C12.75 15 13 14.7812 13 14.5ZM7 8C4.78125 8 3 6.21875 3 4C3 1.8125 4.78125 0 7 0C9.1875 0 11 1.8125 11 4C11 6.21875 9.1875 8 7 8ZM7 1C5.34375 1 4 2.375 4 4C4 5.65625 5.34375 7 7 7C8.625 7 10 5.65625 10 4C10 2.375 8.625 1 7 1Z" fill="#FFFFFF" />
        </svg>
      </>
    )
  }

  const NotificationIcon = () => {
    return (
      <>
        <svg className="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 15C8.53125 15 9 14.5625 9 14H10C10 15.125 9.09375 16 8 16C6.875 16 6 15.125 6 14H7C7 14.5625 7.4375 15 8 15ZM14.5312 10.4688C14.9688 10.9062 15.0938 11.5312 14.875 12.0938C14.6562 12.6562 14.0938 13 13.5 13H2.5C1.875 13 1.3125 12.6562 1.09375 12.0938C0.875 11.5312 1 10.9062 1.4375 10.4688C2.3125 9.65625 2.96875 8.78125 2.96875 5.8125C2.96875 3.34375 4.96875 1.3125 7.5 1.0625V0.5C7.5 0.25 7.71875 0 8 0C8.25 0 8.5 0.25 8.5 0.5V1.0625C11 1.3125 13 3.34375 13 5.8125C13 8.78125 13.6562 9.65625 14.5312 10.4688ZM13.5 12C13.9375 12 14.1562 11.5 13.8438 11.1875C12.75 10.1562 12 9 12 5.8125C12 3.71875 10.1875 2 8 2C5.78125 2 3.96875 3.71875 3.96875 5.8125C3.96875 9 3.21875 10.1562 2.125 11.1875C1.8125 11.5 2.03125 12 2.5 12H13.5Z" fill="white" />
        </svg>
      </>
    )
  }

  const OffersIcon = () => {
    return (
      <>
        <svg className="mr-2" width="18" height="8" viewBox="0 0 18 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.4375 1.4375C17.8125 1.78125 18 2.25 18 2.71875C17.9688 3.21875 17.75 3.6875 17.375 3.96875L14.2188 6.5C13.7812 6.84375 13.2812 7.03125 12.75 7.03125H0.25C0.09375 7.03125 0 6.90625 0 6.78125V6.28125C0 6.125 0.09375 6.03125 0.25 6.03125H12.75C13.0625 6.03125 13.3438 5.90625 13.5938 5.71875L16.75 3.21875C17.0625 2.9375 17.0938 2.46875 16.75 2.1875C16.6562 2.0625 16.5 2.03125 16.3438 2.03125C16.1875 2.03125 16 2.09375 15.875 2.1875L13.9375 3.71875C13.7188 3.90625 13.4062 4 13.125 4H7.5C7.21875 4 7 3.78125 7 3.5C7 3.25 7.21875 3 7.5 3H10.25C10.6562 3 11 2.6875 11 2.25C11 1.84375 10.6562 1.5 10.25 1.5H5.65625C5.21875 1.5 4.8125 1.65625 4.4375 1.90625L3 3H0.25C0.09375 3 0 2.90625 0 2.75V2.25C0 2.125 0.09375 2 0.25 2H2.65625L3.84375 1.125C4.375 0.71875 5 0.5 5.65625 0.5H10.1562C11.0312 0.5 11.8438 1.125 11.9688 2C12.0312 2.375 11.9688 2.71875 11.8125 3H13.125C13.1875 3 13.2812 3 13.3125 2.9375L15.25 1.40625C15.5625 1.15625 15.9375 1.03125 16.3438 1.03125C16.75 1.03125 17.125 1.15625 17.4375 1.4375Z" fill="white" />
        </svg>
      </>
    )
  }

  const AccountIcon = () => {
    return (
      <>
        <svg className="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 0.25C12.25 0.25 15.75 3.75 15.75 8C15.75 12.2812 12.25 15.75 8 15.75C3.71875 15.75 0.25 12.2812 0.25 8C0.25 3.75 3.71875 0.25 8 0.25ZM13.25 3.8125C12.9375 3.40625 12.5938 3.0625 12.1875 2.75L10.2188 4.71875C10.6562 5 11 5.34375 11.2812 5.78125L13.25 3.8125ZM8 11C9.625 11 11 9.65625 11 8C11 6.375 9.625 5 8 5C6.34375 5 5 6.375 5 8C5 9.65625 6.34375 11 8 11ZM11.3438 2.15625C9.28125 0.96875 6.6875 0.96875 4.625 2.15625L6.6875 4.21875C7.53125 3.9375 8.4375 3.9375 9.28125 4.21875L11.3438 2.15625ZM3.78125 2.75C3.375 3.0625 3.03125 3.40625 2.71875 3.8125L4.6875 5.78125C4.96875 5.34375 5.3125 5 5.75 4.71875L3.78125 2.75ZM2.125 4.65625C0.9375 6.71875 0.9375 9.3125 2.125 11.375L4.1875 9.3125C3.90625 8.46875 3.90625 7.5625 4.1875 6.71875L2.125 4.65625ZM2.71875 12.2188C3.03125 12.625 3.375 12.9688 3.78125 13.2812L5.75 11.3125C5.3125 11.0312 4.96875 10.6875 4.6875 10.25L2.71875 12.2188ZM4.625 13.875C6.6875 15.0625 9.28125 15.0625 11.3438 13.875L9.28125 11.8125C8.4375 12.0938 7.53125 12.0938 6.6875 11.8125L4.625 13.875ZM12.1875 13.2812C12.5938 12.9688 12.9375 12.625 13.25 12.2188L11.2812 10.25C11 10.6875 10.6562 11.0312 10.2188 11.3125L12.1875 13.2812ZM13.8438 11.375C15.0312 9.3125 15.0312 6.71875 13.8438 4.65625L11.7812 6.71875C12.0625 7.5625 12.0625 8.46875 11.7812 9.3125L13.8438 11.375Z" fill="white" />
        </svg>
      </>
    )
  }

  const EarningsIcon = () => {
    return (
      <>
        <svg className="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 0.25C12.2812 0.25 15.75 3.71875 15.75 8C15.75 12.2812 12.2812 15.75 8 15.75C3.71875 15.75 0.25 12.2812 0.25 8C0.25 3.71875 3.71875 0.25 8 0.25ZM8 14.75C11.7188 14.75 14.75 11.75 14.75 8C14.75 4.28125 11.7188 1.25 8 1.25C4.25 1.25 1.25 4.28125 1.25 8C1.25 11.75 4.25 14.75 8 14.75ZM9.25 7.84375C10.0625 8.0625 10.625 8.8125 10.625 9.65625C10.625 10.6875 9.78125 11.5 8.71875 11.5H8.5V12.75C8.5 12.9062 8.375 13 8.25 13H7.75C7.59375 13 7.5 12.9062 7.5 12.75V11.5H7.3125C6.71875 11.5 6.15625 11.3125 5.71875 10.9688C5.59375 10.875 5.59375 10.6875 5.6875 10.5938L6.03125 10.2188C6.125 10.125 6.25 10.125 6.375 10.1875C6.625 10.4062 6.96875 10.5 7.3125 10.5H8.71875C9.21875 10.5 9.625 10.125 9.625 9.65625C9.625 9.25 9.34375 8.90625 8.96875 8.8125L6.71875 8.1875C5.90625 7.9375 5.34375 7.21875 5.34375 6.375C5.34375 5.34375 6.1875 4.5 7.25 4.5H7.5V3.25C7.5 3.125 7.59375 3 7.75 3H8.25C8.375 3 8.5 3.125 8.5 3.25V4.5H8.65625C9.25 4.5 9.8125 4.71875 10.25 5.0625C10.375 5.15625 10.375 5.34375 10.2812 5.4375L9.9375 5.8125C9.84375 5.90625 9.71875 5.90625 9.59375 5.84375C9.34375 5.625 9 5.5 8.65625 5.5H7.25C6.75 5.5 6.34375 5.90625 6.34375 6.375C6.34375 6.78125 6.625 7.125 7 7.21875L9.25 7.84375Z" fill="white" />
        </svg>

      </>
    )
  }


  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);

  const navigate = useNavigate()
  let path = useLocation()

  const backprofile = () => {
    if (path.pathname === "/settingsprofile") {
      toggle1()
    } else {
      navigate('/profile')
    }

  }

  const continueEditing = () => {
    navigate("/settingsprofile")
    setModal1(!modal1)
  }

  const discardchanges = () => {
    navigate("/profile")
    setModal1(!modal1)
  }


  const SettingData = {
    settingsprofile: "Profile",
    settingsnotification: "Notification",
    settingsoffers: "Offers",
    settingsaccountsupport: "AccountSupport",
    settingsearnings: "Earnings"
  }


  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split("/");
  useToggleClassName()

  const { t } = useTranslation()


  useEffect(() => {
    if (splitLocation)
      document.title = `${SettingData[splitLocation[1]]} - Settings | Wor NFT`
  }, [splitLocation])

  return (
    <>

      <section className="mycollectionSec">
        <div className='container-fluid'>
          <div className="row">
            <div className="col-lg-2 mb-3 pr-lg-5 text-lg-right mb-5" >
              <button className="backIcon ml-0" onClick={backprofile}><i class="fa fa-angle-left" style={{ fontSize: "15px" }}></i></button>
            </div>
            <div className="col-lg-3 mb-3">
              <h3 className="collectionSecHeading text-left">{t("Settings")} </h3>
              <ul className="settingsTabs">
                <li className="nav-item"><Link className={`nav-link ${splitLocation[1] === "settingsprofile" ? "active" : ""}`} to="settingsprofile"><ProfileIcon />{t("Profile")}</Link></li>
                <li className="nav-item"><Link className={`nav-link ${splitLocation[1] === "settingsnotification" ? "active" : ""}`} to="settingsnotification"><NotificationIcon />{t("Notification")}</Link></li>
                <li className="nav-item"><Link className={`nav-link ${splitLocation[1] === "settingsoffers" ? "active" : ""}`} to="settingsoffers"><OffersIcon />{t("Offers")}</Link></li>
                <li className="nav-item"><Link className={`nav-link ${splitLocation[1] === "settingsaccountsupport" ? "active" : ""}`} to="settingsaccountsupport"><AccountIcon />{t("Account Support")}</Link></li>
                <li className="nav-item"><Link className={`nav-link ${splitLocation[1] === "settingsearnings" ? "active" : ""}`} to="settingsearnings"><EarningsIcon />{t("Earnings")}</Link></li>
              </ul>
            </div>
            <div className="col-lg-6">
              <Outlet />
            </div>
          </div>

        </div>

        <Modal isOpen={modal1} toggle={toggle1} centered="true" className="curMdl createScsMdl" backdropClassName="selCurBp" keyboard={false}
        >
          <div className="createContent">
            <button className="btn closeBtn" onClick={toggle1}>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z" fill="#595F6A" />
              </svg>
            </button>

            <div className="w-100">
              <div className="d-flex justify-content-center">
                <img src={IconModal} />
              </div>
              <h3 className="walletHeading my-2">{t("Are you sure?")}</h3>
              <h3 className="walletSubHeading mb-3 ">{t("You are about to leave this page. All unsaved changes will be lost. Are you sure?")}</h3>
              <button type="button" className="btn btn-block gradientBtn mb-2" onClick={continueEditing}>{t("Continue Editing")}</button>
              <button type="button" className="btn btn-block darkBtn" onClick={discardchanges}>{t("Discard Changes")}</button>
            </div>
          </div>
        </Modal>
      </section>
    </>
  )

}



export default MintLayout;