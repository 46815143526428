import cndds from '../../../assets/images/cndds.png'
import cnddsLight from '../../../assets/images/cnddsLight.svg'
import { useTranslation } from 'react-i18next'


export function AssetTraits({ asset }) {

	const { t } = useTranslation()


	const traits = asset.traits.map((trait) => (
		<div className='col-sm-6 col-lg-4' key={trait.traitName}>
			<div className='traitCnt'>
				<span className='cardLabel'>{trait.traitName}</span>
				<div className='cardValue'>
					<span className='yellowTxt mr-2'>{trait.traitValue}</span>
					{`${trait.traitPercent} %`}
				</div>
			</div>
		</div>
	))

	if (traits.length === 0) {
		return (
			<div className='h464pxCenter'>
				<div class='noResf text-center'>
					<img class='img-fluid' src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : cndds} alt='' />
					<h1>{t('No Traits')}</h1>
				</div>
			</div>
		)
	}

	return <div className='row'>{traits}</div>
}
