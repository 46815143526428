import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import style from './bnr.module.scss'
import Verefied from './assets/Verefied.svg'
import { Link } from 'react-router-dom'
import { handleImageError, handleBannerImageError } from '../../hooks/useHandleImg'
import DefaultImage from '../../assets/images/homeExplorer/placeholder.png'
import DefaultBannerImage from '../../assets/images/profile/profileCover.png'
import helpcenterBanner from '../../assets/images/helpcenter/helpcenterBanner.png'

import arrowLeft from './assets/arrow-left.svg'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import loader from '../../assets/images/Loader.png'
import noItemfoundImage from '../../assets/images/collection/noItemfoundImage.svg'
import cnddsLight from '../../assets/images/cnddsLight.svg'
import { nativeCoinMap } from '../../utils/getFilterData'
import DataService from '../../lib/DataService'
const HomeBanner = () => {
   const [videoError, setVideoError] = useState(false);
   const { t } = useTranslation()

   const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      initialSlide: 0,
      centerMode: false,
   };
   const { getRequest } = DataService()

   const slider = useRef();
   const [loading, setLoading] = useState(false);

   const next = () => {
      slider.current.slickNext();
   };
   const previous = () => {
      slider.current.slickPrev();
   };

   useEffect(() => {
      getCollection()
   }, [])

   const [collection, setCollection] = useState("")

   const getCollection = async () => {
      setLoading(true)
      const fetch = await getRequest("home/get_banner_collection")
      if (fetch.status == true) {
         setCollection(fetch.data)
         setLoading(false)
      }
      else {
         setLoading(false)
         setCollection()

      }
   }

   const handleVideoError = () => {
      setVideoError(true);
   };

   return (
      <>
         <div className={`text-center ${loading ? 'spinner' : 'd-none'}`}>
            <img src={loader} alt='' className='spinAnimate' />
         </div>
         <div className={style.bnr}>
            <div className={style.bnrMn}>
               <div className="">

                  <div className={style.bnrSld}>
                     {
                        collection && collection.length > 0 ?
                           <>
                              <Slider {...settings} ref={(c) => (slider.current = c)} >
                                 {
                                    collection && collection.map((data, index) => (
                                       <div className="position-relative">
                                          <div className={style.bnrIg}>
                                             {typeof data?.bannerImage == "string" && data?.bannerImage !== undefined && !data?.bannerImage?.startsWith(process.env.S3_URL) &&
                                                !data?.bannerImage?.startsWith("ipfs") && data?.bannerImage !== null ?

                                                data?.imageFormat?.startsWith('video') ?
                                                   videoError ?
                                                      <img src={DefaultBannerImage} alt="Itemimg1" className='homevideo' />
                                                      :
                                                      
                                                      <video loop autoPlay muted playsInline className='homevideo'>
                                                         <source src={data.bannerImage ? data?.bannerImage : DefaultBannerImage} type="video/mp4" onError={handleVideoError} />
                                                      </video>
                                                   :
                                                   data?.bannerImage !== 'undefined' ?
                                                      <img src={data?.bannerImage ? data?.bannerImage : DefaultBannerImage} alt="Itemimg1" width={1440} height={552} onError={handleBannerImageError} />
                                                      :
                                                      <img src={DefaultBannerImage} alt="Itemimg1" width={1440} height={552} />
                                                :
                                                <img src={DefaultBannerImage} alt="Itemimg1" width={1440} height={552} />
                                             }
                                          </div>

                                          <div className={style.bnrCon}>
                                             <div className={style.atrIg}>
                                                {typeof data?.logoImage == "string" && data?.logoImage !== undefined && !data?.logoImage?.startsWith(process.env.S3_URL) &&
                                                   !data?.logoImage?.startsWith("ipfs") && data?.logoImage !== null ? <img src={data?.logoImage !== "" ? data?.logoImage : DefaultImage} alt="author" width={88} height={88} onError={handleImageError} /> : <img src={DefaultImage} alt="author" width={88} height={88} />}
                                             </div>
                                             <h2>
                                                <span>{data.collectionName}</span>
                                                {data.collectionVerification === "Verified" &&
                                                   <img src={Verefied} alt="Verified" width={40} height={40} />
                                                }
                                             </h2>
                                             <div className='text-center'>
                                                <p className={style.atrNm}>
                                                   {data?.userName && <span>{t("By")} <strong>{data.userName}</strong></span>}
                                                   {data.profileVerification == "Verified" ?
                                                      <img src={Verefied} alt="Verified" width={20} height={20} />
                                                      :
                                                      ""
                                                   }
                                                </p>
                                                <p className={style.vlmFlr}>
                                                   <span className={style.vlmCon}>
                                                      {t("Volume")} <strong>{data.volume == "0" ? 0 : parseFloat(data.volume.toFixed(8))} {nativeCoinMap[data.chain]}</strong>
                                                   </span>
                                                   <span className={style.flrCon}>

                                                      {t("Floor")} <strong>{data.floorPrice == "0" ? 0 : parseFloat(data.floorPrice.toFixed(8))} {data.floorPrice == 0 ? nativeCoinMap[data.chain] : data.floorCurrency}</strong>
                                                   </span>
                                                </p>
                                             </div>
                                             <div className={style.bnrLk} >
                                                <Link to={`/collection/${data.slug}`}>
                                                   {t("View Collection")}
                                                </Link>
                                             </div>
                                          </div>
                                       </div>
                                    ))

                                 }
                              </Slider>
                              <div className={`container-fluid ${style.containAbsolute}`} >
                                 <button className={`btn ${style.btnSld} ${style.btnSldLt}`} onClick={previous}>
                                    <img src={arrowLeft} alt="arrow left" width={20} height={20} />
                                 </button>

                                 <button className={`btn ${style.btnSld} ${style.btnSldRt}`} onClick={next}>
                                    <img src={arrowLeft} alt="arrow left" width={20} height={20} />
                                 </button>
                              </div>
                           </>
                           :
                           <div className='bannernothingDisplayParent'>
                           <div className='nthing_cl_banner'>
                              <img
                                 src={helpcenterBanner}
                                 alt='collectionBanner'
                                 width={'100%'}
                                 height={'100%'}
                              />
                           </div>
                           <div className='noItemFound justify-content-center'>
                              {loading == false ?

                                 <>
                                    <div className='text-center'>
                                       <img src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : noItemfoundImage} alt="noItemfoundImage" className='img-fluid' />
                                    </div>
                                    <div className='nofounttxt mb-4 text-white'>
                                       {t("Nothing to display.")}
                                    </div>
                                 </>
                                 :
                                 ""
                              }
                           </div>
                        </div>
                     }
                  </div>

               </div>

            </div>
         </div>
      </ >

   );
}
export default HomeBanner
