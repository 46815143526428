import { Input, InputGroup, Modal, ModalHeader, ToastHeader } from "reactstrap"
import React, { useState, useEffect, useContext } from 'react';

import "./Bridge.scss"

import elprd from "./assets/elprd.png"
import swap from "./assets/swap.png"
import searchIcon from "./assets/search.svg"

import Web3 from "web3";
import bnbabi from '../AbiArray/bnbabi.json'
import ethabi from '../AbiArray/ethabi.json'
import polygonabi from '../AbiArray/polygonabi.json'
import usdtabi from '../AbiArray/usdtabi.json'
import busdabi from '../AbiArray/busd.json'
import worabi from '../AbiArray/WorTokenAbi.json'
import wethabi from '../AbiArray/weth.json'
import wbnbabi from '../AbiArray/wbnb.json'
import wmaticabi from '../AbiArray/wmatic.json'
import { ThemeContext } from "../context/context";
import 'react-toastify/dist/ReactToastify.css';
import contracts from "../../config/constants/contracts";
import okxWeb3 from '@okwallet/extension';
import { formatAmount } from "../../utils/formatAmount";
import { useToast } from "../../utils/toast/customSuccess";
import {
   useAccount,
   useNetwork,
   useSwitchNetwork,
} from 'wagmi'
import {
   useGetBnbBalance,
   useGetEthBalance,
   useGetMaticBalance
} from '../../hooks/useBalance'
import { useNavigate } from "react-router-dom";
import { NumericFormat } from 'react-number-format'
import loader from '../../assets/images/Loader.png'

import { chainId } from '../../config/constants/chains'
import { getProvider } from "../../utils/getProvider";
import { useContractProvider } from '../../utils/useContractProvider'
import { useOkxSwitchNetwork } from '../../hooks/useOkxSwitchNetwork'
import { useToggleClassName } from "../../hooks/useToggleClassName";
import { useTranslation } from "react-i18next";
import DataService from "../../lib/DataService";
const chainIdMap = {
   ethereum: chainId.ETHEREUM,
   binance: chainId.BINANCE,
   polygon: chainId.POLYGON
}

const chainMap = {
   [chainId.ETHEREUM]: "ethereum",
   [chainId.BINANCE]: "binance",
   [chainId.POLYGON]: "polygon",
}

const Bridge = () => {
   const toast = useToast()
   useToggleClassName()
   const { getRequest, postRequest } = DataService()

   const [modal, setModal] = useState(false);
   const toggle = () => {
      setModal(!modal);
      fetchdata()
   }

   useEffect(() => {
      document.title = ' Bridge | Wor NFT'
   }, [])

   const { address, isConnected } = useAccount()


   useEffect(() => {

   }, [address])

   const { switchNetworkAsync } = useSwitchNetwork()
   const { switchNetwork } = useOkxSwitchNetwork()

   const { chain: network } = useNetwork()
   const chainid = network?.id

   const [modal1, setModal1] = useState(false);
   const toggle1 = () => {
      setModal1(!modal1);
      fetchdata()
   }
   const [loading, setLoading] = useState(false);
   let [color, setColor] = useState("#ffffff");

   const { theme, setTheme } = useContext(ThemeContext);

   const [currencycoin, setCurrencyCoin] = useState("")

   const [currencycointype, setCurrencyCoinType] = useState("")
   const [currencyimage, setCurrencyimage] = useState("")
   const [currencycoin1, setCurrencyCoin1] = useState("")
   const [currencycointype1, setCurrencyCoinType1] = useState("")
   const [currencyimage1, setCurrencyimage1] = useState("")
   const [currencysymbol, setCurrencySymbol] = useState("")
   const [currencysymbol1, setCurrencySymbol1] = useState("")
   const [fromamount, setFromamount] = useState("")
   const [toamount, setToamount] = useState("")
   const [minamount, setMinAmount] = useState("")
   const [maxamount, setMaxamount] = useState("")
   const [Network, setNetwork] = useState("")
   const [Network1, setNetwork1] = useState("")

   const { balance: bnbBalance, refresh } = useGetBnbBalance()
   const { balance: ethBalance, refresheth } = useGetEthBalance()
   const { balance: maticBalance } = useGetMaticBalance()
   const { t } = useTranslation()

   const navigate = useNavigate()


   const { pro } = useContractProvider()
   const walletType = localStorage.getItem('walletType')
   const provider = getProvider()

   const web3 = new Web3(provider);
   const bnbcontractMarket = new web3.eth.Contract(bnbabi, contracts.bridge.Bnb_contract)
   const ethcontractMarket = new web3.eth.Contract(ethabi, contracts.bridge.Eth_contract)
   const polygoncontractMarket = new web3.eth.Contract(polygonabi, contracts.bridge.Polygon_contract)
   const usdtcontractMarket = new web3.eth.Contract(usdtabi, contracts.usdt)
   const busdcontractMarket = new web3.eth.Contract(busdabi, contracts.busd)
   const worcontractMarket = new web3.eth.Contract(worabi, contracts.wor)
   const wethcontractMarket = new web3.eth.Contract(wethabi, contracts.weth)
   const wbnbcontractMarket = new web3.eth.Contract(wbnbabi, contracts.wbnb)
   const wmaticcontractMarket = new web3.eth.Contract(wmaticabi, contracts.wmatic)


   const [fromcurrencybal, setfromCurrencybal] = useState("")
   const [tocurrencybal, settoCurrencybal] = useState("")

   var BnbRpcurl = "https://bsc-testnet.publicnode.com";
   var polygonRpcurl = "https://polygon-amoy-bor-rpc.publicnode.com";
   var EthRpcurl = "https://chain-proxy.wallet.coinbase.com?targetName=ethereum-sepolia"

   var polygonBalance = new Web3(new Web3.providers.HttpProvider(polygonRpcurl));
   var bianceBalance = new Web3(new Web3.providers.HttpProvider(BnbRpcurl));
   var EthBalance = new Web3(new Web3.providers.HttpProvider(EthRpcurl));

   let busdcontract = new bianceBalance.eth.Contract(busdabi, contracts.busd);
   let worcontract = new bianceBalance.eth.Contract(worabi, contracts.wor);
   let usdtcontract = new EthBalance.eth.Contract(usdtabi, contracts.usdt);
   let wethcontract = new EthBalance.eth.Contract(wethabi, contracts.weth);
   let wbnbcontract = new bianceBalance.eth.Contract(wbnbabi, contracts.wbnb)
   let wmaticcontract = new polygonBalance.eth.Contract(wmaticabi, contracts.wmatic)

   const [usetgetBal, setUsergetBal] = useState("")
   const [adminfeeamount, setAdminFeeAmount] = useState("")

   const getCurrency = async (data) => {

      setLoading(true)
      setCurrencyCoin(data.currencyName)
      setCurrencyCoinType(data.currencyType)
      setCurrencyimage(data.currencyImage)
      setCurrencySymbol(data.currencySymbol)
      setNetwork(data.currencyNetwork)
      setMinAmount(data.minAmount)
      setMaxamount(data.maxAmount)
      localStorage.setItem("currency", data.currencySymbol)
      setModal(!modal)
      const fetch = await postRequest("admin/nft/get_single_pair", { "fromCurrency": data.currencySymbol, "toCurrency": currencysymbol1, "fromAmount": fromamount })
      if (fetch.status == true) {
         setLoading(false)
         setToamount(fetch.usdPrice)
         setUsergetBal((Number(fetch.usdPrice) - ((data.adminFee * +fetch.usdPrice) / 100)))
         setAdminFeeAmount((formatAmount((data.adminFee * +fetch.usdPrice) / 100)))
      }
      if (data.minAmount && fromamount && data.currencySymbol) {
         if (data.minAmount > fromamount) {
            document.getElementById('fromamount1').innerHTML = t('Your Min Amount is') + data.minAmount
            setLoading(false)
         }
         else if (data.maxAmount < fromamount) {
            document.getElementById('fromamount1').innerHTML = t('Your Max Amount is') + data.maxAmount
            setLoading(false)
         }
         else {
            document.getElementById('fromamount1').innerHTML = ''
            fetchdata()
            setLoading(false)
         }
      }
      if (data.currencySymbol == "BUSD") {
         let balance = await busdcontract.methods.balanceOf(address).call();
         setfromCurrencybal(balance / 1e18)
         setLoading(false)
      }
      if (data.currencySymbol == "WOR") {
         let balance = await worcontract.methods.balanceOf(address).call();
         setfromCurrencybal(balance / 1e18)
         setLoading(false)
      }
      if (data.currencySymbol == "USDT") {
         let balance = await usdtcontract.methods.balanceOf(address).call();
         setfromCurrencybal(balance / 1e6)
         setLoading(false)
      }
      if (data.currencySymbol == "ETH") {
         var balance = EthBalance.eth.getBalance(address).then(async (bal) => {
            setfromCurrencybal(bal / 1e18)
            setLoading(false)
         })
      }
      if (data.currencySymbol == "BNB") {
         var balance = bianceBalance.eth.getBalance(address).then(async (bal) => {
            setfromCurrencybal(bal / 1e18)
            setLoading(false)
         })

      }
      if (data.currencySymbol == "MATIC") {
         var balance = polygonBalance.eth.getBalance(address).then(async (bal) => {
            setfromCurrencybal(bal / 1e18)
            setLoading(false)
         })
      }
      if (data.currencySymbol == "WETH") {
         let balance = await wethcontract.methods.balanceOf(address).call();
         setfromCurrencybal(balance / 1e18)
         setLoading(false)
      }
      if (data.currencySymbol == "WBNB") {
         let balance = await wbnbcontract.methods.balanceOf(address).call();
         setfromCurrencybal(balance / 1e18)
         setLoading(false)
      }
      if (data.currencySymbol == "WMATIC") {
         let balance = await wmaticcontract.methods.balanceOf(address).call();
         setfromCurrencybal(balance / 1e18)
         setLoading(false)
      }
      document.getElementById('fromcurrency1').innerHTML = ''
      fetchdata()

   }
   

   const [feecurrency, setFeeCurrency] = useState("")

   const getCurrency1 = async (data) => {
      setLoading(true)
      setCurrencyCoin1(data.currencyName)
      setCurrencyCoinType1(data.currencyType)
      setCurrencyimage1(data.currencyImage)
      setCurrencySymbol1(data.currencySymbol)
      setNetwork1(data.currencyNetwork)
      setFeeCurrency(data.adminFee)
      setModal1(!modal1)
      const fetch = await postRequest("admin/nft/get_single_pair", { "fromCurrency": currencysymbol, "toCurrency": data.currencySymbol, "fromAmount": fromamount })
      if (fetch.status == true) {
         setToamount(fetch.usdPrice)
         setUsergetBal((Number(fetch.usdPrice) - ((data.adminFee * +fetch.usdPrice) / 100)))
         setAdminFeeAmount((formatAmount((data.adminFee * +fetch.usdPrice) / 100)))
         setLoading(false)
      }
      if (data.currencySymbol == "BUSD") {
         let balance = await busdcontract.methods.balanceOf(address).call();
         settoCurrencybal(balance / 1e18)
         setLoading(false)
      }
      if (data.currencySymbol == "WOR") {
         let balance = await worcontract.methods.balanceOf(address).call();
         settoCurrencybal(balance / 1e18)
         setLoading(false)
      }
      if (data.currencySymbol == "USDT") {
         let balance = await usdtcontract.methods.balanceOf(address).call();
         settoCurrencybal(balance / 1e6)
         setLoading(false)
      }
      if (data.currencySymbol == "ETH") {
         var balance = EthBalance.eth.getBalance(address).then(async (bal) => {
            settoCurrencybal(bal / 1e18)
            setLoading(false)
         })
      }
      if (data.currencySymbol == "BNB") {
         var balance = bianceBalance.eth.getBalance(address).then(async (bal) => {
            settoCurrencybal(bal / 1e18)
            setLoading(false)
         })
      }
      if (data.currencySymbol == "MATIC") {
         var balance = polygonBalance.eth.getBalance(address).then(async (bal) => {
            settoCurrencybal(bal / 1e18)
            setLoading(false)
         })
      }
      if (data.currencySymbol == "WETH") {
         let balance = await wethcontract.methods.balanceOf(address).call();
         settoCurrencybal(balance / 1e18)
         setLoading(false)
      }
      if (data.currencySymbol == "WBNB") {
         let balance = await wbnbcontract.methods.balanceOf(address).call();
         settoCurrencybal(balance / 1e18)
         setLoading(false)
      }
      if (data.currencySymbol == "WMATIC") {
         let balance = await wmaticcontract.methods.balanceOf(address).call();
         settoCurrencybal(balance / 1e18)
         setLoading(false)
      }

      document.getElementById('tocurrency1').innerHTML = ''
      fetchdata()
   }

   const getAmount = async (e) => {
      setToamount("")
      if (e.target.value === '' || !e.target.value) {
         setFromamount('')
         document.getElementById('fromamount1').innerHTML = t('Please enter amount')
      }

      else if (e.target.value.length > 8) {
         document.getElementById('fromamount1').innerHTML = t("Only allow 8 digits")
      }

      else {
         setFromamount(e.target.value)
         document.getElementById('fromamount1').innerHTML = ''
         const fetch = await postRequest("admin/nft/get_single_pair", { "fromCurrency": currencysymbol, "toCurrency": currencysymbol1, "fromAmount": e.target.value })
         if (fetch.status == true) {
            setToamount(fetch.usdPrice)
            setUsergetBal((Number(fetch.usdPrice) - ((feecurrency * +fetch.usdPrice) / 100)))
            setAdminFeeAmount((formatAmount((feecurrency * +fetch.usdPrice) / 100)))
         }
         if (minamount && e.target.value && currencysymbol) {

            if (minamount > Number(e.target.value)) {
               document.getElementById('fromamount1').innerHTML = t('Your Min Amount is') + minamount
            }
            else if (maxamount < Number(e.target.value)) {
               document.getElementById('fromamount1').innerHTML = t('Your Max Amount is') + maxamount
            }
            else {
               document.getElementById('fromamount1').innerHTML = ''
            }
         }
      }

   }


   useEffect(() => {
      fetchdata()
   }, [])

   const [currencies, setCurrencies] = useState("")
   const fetchdata = async () => {
      const fetch = await getRequest("currencies")
      if (fetch.status == true) {
         setCurrencies(fetch.data)
      }
   }

 
   var claim;
   var contract;

   const contractMap = {
      "ETH": ethcontractMarket,
      "MATIC": polygoncontractMarket,
      "BNB": bnbcontractMarket,
      "USDT": ethcontractMarket,
      "BUSD": bnbcontractMarket,
      "WOR": bnbcontractMarket,
      "WBNB": bnbcontractMarket,
      "WMATIC": polygoncontractMarket,
      "WETH": ethcontractMarket
   };

   const Buy = async () => {
      setLoading(true)
      if (address) {

         contract = contractMap[currencysymbol1];

         if (fromamount == "" && currencysymbol == "" && currencysymbol1 == "") {
            document.getElementById('fromamount1').innerHTML = t('Please enter amount')
            document.getElementById('fromcurrency1').innerHTML = t('Please choose from currency')
            document.getElementById('tocurrency1').innerHTML = t('Please choose to currency')
            setLoading(false)
         }
         else if (currencysymbol == "") {
            document.getElementById('fromcurrency1').innerHTML = t('Please choose from currency')
            setLoading(false)
         }
         else if (currencysymbol1 == "") {
            document.getElementById('tocurrency1').innerHTML = t('Please choose to currency')
            setLoading(false)
         }
         else if (fromamount == "") {
            document.getElementById('fromamount1').innerHTML = t('Please enter amount')
            setLoading(false)
         }
         else if (minamount > fromamount) {
            document.getElementById('fromamount1').innerHTML = t('Your Min Amount is') + minamount
            setLoading(false)
         }
         else if (maxamount < fromamount) {
            document.getElementById('fromamount1').innerHTML = t('Your Max Amount is') + maxamount
            setLoading(false)
         }
         else {
            const balancecheck = await postRequest("admin/nft/check_claim_balance", { "network": Network1, "amount": formatAmount(Number(toamount)), "toCurrency": currencysymbol1 })
            if (balancecheck.status == true) {

               if (currencysymbol == "BNB" && currencycointype == "coin") {
                  try {

                     if (walletType === 'okx') {
                        if (Network !== chainMap[parseInt(window.okxwallet.chainId)]) {
                           await switchNetwork(chainIdMap[Network])
                        }
                     } else {
                        if (
                           currencysymbol == "BNB" &&
                           chainid !== chainId.BINANCE
                        ) {
                           await switchNetworkAsync(chainId.BINANCE)
                        }
                     }
                     var balance = web3.eth.getBalance(address).then(async (bal) => {
                        const bnbbalance = web3.utils.fromWei(bal, 'ether');
                        if (Number(bnbbalance) > Number(fromamount)) {
                           const buyprice1 = web3.utils.toWei(fromamount, 'ether');

                           try {
                              if (currencysymbol1 == "WBNB") {

                                 try {

                                    const deposit = await wbnbcontractMarket.methods.deposit().send({ from: address, value: buyprice1 }).on('transactionHash', async (hash) => {
                                       const getTransactionReceipt = async (hash) => {
                                          const result = await web3.eth.getTransactionReceipt(hash)
                                          if (result === null) {
                                             getTransactionReceipt(hash)
                                          } else {
                                             let data = {
                                                "fromCurrency": currencysymbol,
                                                "toCurrency": currencysymbol1,
                                                "fromAddress": result.to,
                                                "toAddress": result.from,
                                                "fromAmount": fromamount?.toString(),
                                                "toAmount": (Number(toamount))?.toString(),
                                                "adminFee": "0",
                                                "txnHash": result.transactionHash,
                                                "network": Network1,
                                                "type": "claim",
                                                "status": "completed"
                                             }
                                             const fetch = await postRequest("bridge/claim", data)
                                             if (fetch.status == true) {
                                                toast.success("Transaction completed successfully")
                                                setCurrencyCoin("Select Currency")
                                                setCurrencyCoin1("Select Currency")
                                                setCurrencyimage("")
                                                setCurrencyimage1("")
                                                setFromamount("")
                                                setToamount("")
                                                setCurrencySymbol("")
                                                setCurrencySymbol1("")
                                                setfromCurrencybal("")
                                                settoCurrencybal("")
                                                setLoading(false)
                                                navigate("/bridgehistory")
                                                refresh()
                                                refresheth()
                                                return
                                             }
                                          }
                                       }
                                       getTransactionReceipt(hash)

                                    }).on('receipt', async (approveresult) => {

                                    })
                                 }
                                 catch (err) {
                                    toast.error("Transaction rejected")
                                    setLoading(false)
                                 }
                              }
                              else {
                                 const deposit = await bnbcontractMarket.methods.deposit("0x0000000000000000000000000000000000000000", buyprice1).send({ from: address, value: buyprice1 }).on('transactionHash', async (hash) => { }).on('receipt', async (approveresult) => {
                                    const fetch = await postRequest("bridge/deposit", { "fromCurrency": currencysymbol, "fromAddress": approveresult.events.DepositEvent.returnValues.user, "toAddress": approveresult.to, "fromAmount": (approveresult.events.DepositEvent.returnValues.amount / 1e18)?.toString(), "txnHash": approveresult.events.DepositEvent.transactionHash, "type": "deposit", "toCurrency": currencysymbol1, "toAmount": formatAmount(toamount?.toString()), "status": "completed" })
                                    if (fetch.status == true) {
                                       const buyprice2 = web3.utils.toWei(formatAmount(toamount.toString()), 'ether');
                                       const buyprice3 = toamount * 1e6;

                                       const getvrs = await postRequest("admin/nft/get_vrs", { "userAddress": address, "currencySymbol": currencysymbol1, "amount": formatAmount(toamount?.toString()) })
                                       if (getvrs.status == true) {
                                          {
                                             try {

                                                if (walletType == 'okx') {
                                                   if (Network1 !== chainMap[parseInt(window.okxwallet.chainId)]) {
                                                      await switchNetwork(chainIdMap[Network1])
                                                   }
                                                } else {
                                                   if (currencysymbol1 == "ETH" && chainid !== chainId.ETHEREUM) { await switchNetworkAsync(chainId.ETHEREUM) }
                                                   else if (currencysymbol1 == "USDT" && chainid !== chainId.ETHEREUM) { await switchNetworkAsync(chainId.ETHEREUM) }
                                                   else if (currencysymbol1 == "BUSD" && chainid !== chainId.BINANCE) { }
                                                   else if (currencysymbol1 == "WOR" && chainid !== chainId.BINANCE) { }
                                                   else if (currencysymbol1 == "MATIC" && chainid !== chainId.AMOY) { await switchNetworkAsync(chainId.AMOY) }
                                                   else if (currencysymbol1 == "ETH" && chainid == chainId.ETHEREUM) { await switchNetworkAsync(chainId.ETHEREUM) }
                                                   else if (currencysymbol1 == "USDT" && chainid == chainId.ETHEREUM) { await switchNetworkAsync(chainId.ETHEREUM) }
                                                   else if (currencysymbol1 == "BUSD" && chainid == chainId.BINANCE) { }
                                                   else if (currencysymbol1 == "WOR" && chainid == chainId.BINANCE) { }
                                                   else if (currencysymbol1 == "MATIC" && chainid == chainId.AMOY) { await switchNetworkAsync(chainId.AMOY) }
                                                }
                                             }
                                             catch (error) {

                                                toast.error('User rejected the switch network')
                                                setLoading(false)

                                                return
                                             }

                                             setTimeout(async () => {
                                                try {
                                                   claim = await contract.methods.claim(currencysymbol1 == "USDT" ? buyprice3 : buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, currencysymbol1 == "USDT" ? contracts.usdt : currencysymbol1 == "BUSD" ? contracts.busd : currencysymbol1 == "WOR" ? contracts.wor : "0x0000000000000000000000000000000000000000").send({ from: address }).on('transactionHash', async (hash) => {
                                                      const getTransactionReceipt = async (hash) => {
                                                         const result = await web3.eth.getTransactionReceipt(hash)
                                                         if (result === null) {
                                                            getTransactionReceipt(hash)
                                                         } else {
                                                            let data = {
                                                               "fromCurrency": currencysymbol,
                                                               "toCurrency": currencysymbol1,
                                                               "fromAddress": result.to,
                                                               "toAddress": result.from,
                                                               "fromAmount": fromamount?.toString(),
                                                               "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100))?.toString(),
                                                               "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100))?.toString(),
                                                               "txnHash": result.transactionHash,
                                                               "network": Network1,
                                                               "type": "claim",
                                                               "status": "completed"
                                                            }
                                                            const fetch = await postRequest("bridge/claim", data)
                                                            if (fetch.status == true) {
                                                               toast.success("Transaction completed successfully")
                                                               setCurrencyCoin("Select Currency")
                                                               setCurrencyCoin1("Select Currency")
                                                               setCurrencyimage("")
                                                               setCurrencyimage1("")
                                                               setFromamount("")
                                                               setToamount("")
                                                               setCurrencySymbol("")
                                                               setCurrencySymbol1("")
                                                               setfromCurrencybal("")
                                                               settoCurrencybal("")
                                                               setLoading(false)
                                                               navigate("/bridgehistory")
                                                               refresh()
                                                               refresheth()
                                                               return
                                                            }
                                                         }
                                                      }
                                                      getTransactionReceipt(hash)
                                                   }).on('receipt', async (approveresult) => {
                                                   })

                                                }
                                                catch (err) {
                                                   let data =
                                                   {
                                                      "fromCurrency": currencysymbol,
                                                      "toCurrency": currencysymbol1,
                                                      "fromAddress": currencysymbol1 == "ETH" ? contracts.bridge.Eth_contract : currencysymbol1 == "MATIC" ? contracts.bridge.Polygon_contract : currencysymbol1 == "USDT" ? contracts.bridge.Eth_contract : currencysymbol1 == "BUSD" ? contracts.bridge.Bnb_contract : currencysymbol1 == "WOR" ? contracts.bridge.Bnb_contract : "",
                                                      "toAddress": address,
                                                      "fromAmount": fromamount?.toString(),
                                                      "toAmount": toamount?.toString(),
                                                      "network": Network1,
                                                      "adminFee": "0",
                                                      "type": "claim",
                                                      "status": "cancelled"
                                                   }
                                                   let fetch = await postRequest("bridge/claim", data)
                                                   if (fetch.status == true) {
                                                      toast.error("Transaction Rejected")
                                                      setLoading(false)
                                                   }
                                                }
                                             }, 10000)

                                          }
                                       }
                                       else {
                                          toast.error(getvrs.data)
                                          let data =
                                          {
                                             "fromCurrency": currencysymbol,
                                             "toCurrency": currencysymbol1,
                                             "fromAddress": currencysymbol1 == "ETH" ? contracts.bridge.Eth_contract : currencysymbol1 == "MATIC" ? contracts.bridge.Polygon_contract : currencysymbol1 == "USDT" ? contracts.bridge.Eth_contract :
                                                currencysymbol1 == "BUSD" ? contracts.bridge.Bnb_contract : currencysymbol1 == "WOR" ? contracts.bridge.Bnb_contract : "",
                                             "toAddress": address,
                                             "fromAmount": fromamount?.toString(),
                                             "toAmount": toamount?.toString(),
                                             "network": Network1,
                                             "adminFee": "0",
                                             "type": "claim",
                                             "status": "cancelled"
                                          }
                                          let fetch = await postRequest("bridge/claim", data)
                                          if (fetch.status == true) {
                                             navigate("/bridgehistory")
                                             setLoading(false)
                                          }
                                       }
                                    }
                                 })
                              }
                           }
                           catch (error) {
                              toast.error("Transaction rejected")
                              setLoading(false)
                           }
                        }
                        else {
                           toast.error("Insufficient bnb balance")
                           setLoading(false)
                        }
                     })

                  }
                  catch (error) {
                     setLoading(false)
                  }

               }
               else if (currencysymbol == "ETH" && currencycointype == "coin") {
                  try {
                     if (walletType === 'okx') {
                        if (Network !== chainMap[parseInt(window.okxwallet.chainId)]) {
                           await switchNetwork(chainIdMap[Network])
                        }
                     } else {
                        if (
                           currencysymbol == "ETH" &&
                           chainid !== chainId.ETHEREUM
                        ) {
                           await switchNetworkAsync(chainId.ETHEREUM)
                        }
                     }

                     var balance = web3.eth.getBalance(address).then(async (bal) => {
                        const ethbalance = web3.utils.fromWei(bal, 'ether');
                        if (Number(ethbalance) > Number(fromamount)) {
                           const buyprice1 = web3.utils.toWei(fromamount, 'ether');
                           try {

                              if (currencysymbol1 == "WETH") {
                                 try {
                                    const deposit = await wethcontractMarket.methods.deposit().send({ from: address, value: buyprice1 }).on('transactionHash', async (hash) => {

                                       const getTransactionReceipt = async (hash) => {
                                          const result = await web3.eth.getTransactionReceipt(hash)
                                          if (result === null) {
                                             getTransactionReceipt(hash)
                                          } else {
                                             let data = {
                                                "fromCurrency": currencysymbol,
                                                "toCurrency": currencysymbol1,
                                                "fromAddress": result.to,
                                                "toAddress": result.from,
                                                "fromAmount": fromamount?.toString(),
                                                "toAmount": (Number(toamount))?.toString(),
                                                "adminFee": "0",
                                                "txnHash": result.transactionHash,
                                                "network": Network1,
                                                "type": "claim",
                                                "status": "completed"
                                             }
                                             const fetch = await postRequest("bridge/claim", data)
                                             if (fetch.status == true) {
                                                toast.success("Transaction completed successfully")
                                                setCurrencyCoin("Select Currency")
                                                setCurrencyCoin1("Select Currency")
                                                setCurrencyimage("")
                                                setCurrencyimage1("")
                                                setFromamount("")
                                                setToamount("")
                                                setCurrencySymbol("")
                                                setCurrencySymbol1("")
                                                setfromCurrencybal("")
                                                settoCurrencybal("")
                                                setLoading(false)
                                                navigate("/bridgehistory")
                                                refresh()
                                                refresheth()
                                                return
                                             }
                                          }
                                       }
                                       getTransactionReceipt(hash)



                                    }).on('receipt', async (approveresult) => {

                                    })
                                 }
                                 catch (err) {
                                    toast.error("Transaction rejected")
                                    setLoading(false)
                                 }

                              }
                              else {
                                 const deposit = await ethcontractMarket.methods.deposit("0x0000000000000000000000000000000000000000", buyprice1).send({ from: address, value: buyprice1 }).on('transactionHash', async (hash) => { }).on('receipt', async (approveresult) => {

                                    const fetch = await postRequest("bridge/deposit", { "fromCurrency": currencysymbol, "fromAddress": approveresult.events.DepositEvent.returnValues.user, "toAddress": approveresult.to, "fromAmount": (approveresult.events.DepositEvent.returnValues.amount / 1e18)?.toString(), "txnHash": approveresult.events.DepositEvent.transactionHash, "type": "deposit", "toCurrency": currencysymbol1, "toAmount": formatAmount(toamount?.toString()), "status": "completed" })
                                    if (fetch.status == true) {
                                       const buyprice2 = web3.utils.toWei(formatAmount(toamount?.toString()), 'ether');
                                       const buyprice3 = toamount * 1e6;
                                       const getvrs = await postRequest("admin/nft/get_vrs", { "userAddress": address, "currencySymbol": currencysymbol1, "amount": formatAmount(toamount?.toString()) })
                                       if (getvrs.status == true) {
                                          try {

                                             if (walletType === 'okx') {
                                                if (Network1 !== chainMap[parseInt(window.okxwallet.chainId)]) {
                                                   await switchNetwork(chainIdMap[Network1])
                                                }
                                             } else {
                                                if (currencysymbol1 == "BNB" && chainid !== chainId.BINANCE) { await switchNetworkAsync(chainId.BINANCE) }
                                                else if (currencysymbol1 == "BUSD" && chainid !== chainId.BINANCE) { await switchNetworkAsync(chainId.BINANCE) }
                                                else if (currencysymbol1 == "WOR" && chainid !== chainId.BINANCE) { await switchNetworkAsync(chainId.BINANCE) }
                                                else if (currencysymbol1 == "USDT" && chainid !== chainId.ETHEREUM) { }
                                                else if (currencysymbol1 == "MATIC" && chainid !== chainId.AMOY) { await switchNetworkAsync(chainId.AMOY) }
                                                else if (currencysymbol1 == "BNB" && chainid == chainId.BINANCE) { await switchNetworkAsync(chainId.BINANCE) }
                                                else if (currencysymbol1 == "BUSD" && chainid == chainId.BINANCE) { await switchNetworkAsync(chainId.BINANCE) }
                                                else if (currencysymbol1 == "WOR" && chainid == chainId.BINANCE) { await switchNetworkAsync(chainId.BINANCE) }
                                                else if (currencysymbol1 == "USDT" && chainid == chainId.ETHEREUM) { }
                                                else if (currencysymbol1 == "MATIC" && chainid == chainId.AMOY) { await switchNetworkAsync(chainId.AMOY) }
                                             }
                                          }
                                          catch (error) {

                                             toast.error('User rejected the switch network')
                                             setLoading(false)

                                             return
                                          }
                                          setTimeout(async () => {
                                             try {

                                                claim = await contract.methods.claim(currencysymbol1 == "USDT" ? buyprice3 : buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, currencysymbol1 == "USDT" ? contracts.usdt : currencysymbol1 == "BUSD" ? contracts.busd : currencysymbol1 == "WOR" ? contracts.wor : "0x0000000000000000000000000000000000000000").send({ from: address }).on('transactionHash', async (hash) => {

                                                   const getTransactionReceipt = async (hash) => {
                                                      const result = await web3.eth.getTransactionReceipt(hash)
                                                      if (result === null) {
                                                         getTransactionReceipt(hash)
                                                      } else {
                                                         let data = {
                                                            "fromCurrency": currencysymbol,
                                                            "toCurrency": currencysymbol1,
                                                            "fromAddress": result.to,
                                                            "toAddress": result.from,
                                                            "fromAmount": fromamount?.toString(),
                                                            "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100))?.toString(),
                                                            "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100))?.toString(),
                                                            "txnHash": result.transactionHash,
                                                            "network": Network1,
                                                            "type": "claim",
                                                            "status": "completed"
                                                         }
                                                         const fetch = await postRequest("bridge/claim", data)
                                                         if (fetch.status == true) {
                                                            toast.success("Transaction completed successfully")
                                                            setCurrencyCoin("Select Currency")
                                                            setCurrencyCoin1("Select Currency")
                                                            setCurrencyimage("")
                                                            setCurrencyimage1("")
                                                            setFromamount("")
                                                            setToamount("")
                                                            setCurrencySymbol("")
                                                            setCurrencySymbol1("")
                                                            setfromCurrencybal("")
                                                            settoCurrencybal("")
                                                            setLoading(false)
                                                            navigate("/bridgehistory")
                                                            refresh()
                                                            refresheth()
                                                            return
                                                         }
                                                      }
                                                   }
                                                   await getTransactionReceipt(hash)
                                                }).on('receipt', async (approveresult) => {

                                                })

                                             }

                                             catch (err) {
                                                let data =
                                                {
                                                   "fromCurrency": currencysymbol,
                                                   "toCurrency": currencysymbol1,
                                                   "fromAddress": currencysymbol1 == "ETH" ? contracts.bridge.Eth_contract : currencysymbol1 == "MATIC" ? contracts.bridge.Polygon_contract : currencysymbol1 == "USDT" ? contracts.bridge.Eth_contract :
                                                      currencysymbol1 == "BUSD" ? contracts.bridge.Bnb_contract : currencysymbol1 == "WOR" ? contracts.bridge.Bnb_contract : currencysymbol1 == "BNB" ? contracts.bridge.Bnb_contract : "",
                                                   "toAddress": address,
                                                   "fromAmount": fromamount?.toString(),
                                                   "toAmount": toamount?.toString(),
                                                   "network": Network1,
                                                   "adminFee": "0",
                                                   "type": "claim",
                                                   "status": "cancelled"
                                                }
                                                let fetch = await postRequest("bridge/claim", data)
                                                if (fetch.status == true) {
                                                   toast.error("Transaction Rejected")
                                                   setLoading(false)
                                                }
                                             }
                                          }, 10000)

                                       }
                                       else {
                                          toast.error(getvrs.data)
                                          let data =
                                          {
                                             "fromCurrency": currencysymbol,
                                             "toCurrency": currencysymbol1,
                                             "fromAddress": currencysymbol1 == "BNB" ? contracts.bridge.Bnb_contract : currencysymbol1 == "MATIC" ? contracts.bridge.Polygon_contract : currencysymbol1 == "USDT" ? contracts.bridge.Eth_contract :
                                                currencysymbol1 == "BUSD" ? contracts.bridge.Bnb_contract : currencysymbol1 == "WOR" ? contracts.bridge.Bnb_contract : "",
                                             "toAddress": address,
                                             "fromAmount": fromamount?.toString(),
                                             "toAmount": toamount?.toString(),
                                             "network": Network1,
                                             "adminFee": "0",
                                             "type": "claim",
                                             "status": "cancelled"
                                          }
                                          let fetch = await postRequest("bridge/claim", data)
                                          if (fetch.status == true) {
                                             navigate("/bridgehistory")
                                             setLoading(false)
                                          }
                                       }

                                    }
                                 })
                              }
                           }
                           catch (error) {
                              toast.error("Transaction rejected")
                              setLoading(false)
                           }
                        }
                        else {
                           toast.error("Insufficient eth balance")
                           setLoading(false)
                        }
                     })

                  }
                  catch (error) {
                     setLoading(false)
                  }
               }
               else if (currencysymbol == "MATIC" && currencycointype == "coin") {
                  try {
                     if (walletType === 'okx') {
                        if (Network !== chainMap[parseInt(window.okxwallet.chainId)]) {
                           await switchNetwork(chainIdMap[Network])
                        }
                     } else {
                        if (
                           currencysymbol == "MATIC" &&
                           chainid !== chainId.AMOY
                        ) {
                           await switchNetworkAsync(chainId.AMOY)
                        }
                     }

                     var balance = web3.eth.getBalance(address).then(async (bal) => {
                        const maticbalance = web3.utils.fromWei(bal, 'ether');
                        if (Number(maticbalance) > Number(fromamount)) {
                           const buyprice1 = web3.utils.toWei(fromamount, 'ether');
                           try {
                              if (currencysymbol1 == "WMATIC") {

                                 try {
                                    const deposit = await wmaticcontractMarket.methods.deposit().send({ from: address, value: buyprice1 }).on('transactionHash', async (hash) => {

                                       const getTransactionReceipt = async (hash) => {
                                          const result = await web3.eth.getTransactionReceipt(hash)
                                          if (result === null) {
                                             getTransactionReceipt(hash)
                                          } else {
                                             let data = {
                                                "fromCurrency": currencysymbol,
                                                "toCurrency": currencysymbol1,
                                                "fromAddress": result.to,
                                                "toAddress": result.from,
                                                "fromAmount": fromamount?.toString(),
                                                "toAmount": (Number(toamount))?.toString(),
                                                "adminFee": "0",
                                                "txnHash": result.transactionHash,
                                                "network": Network1,
                                                "type": "claim",
                                                "status": "completed"
                                             }
                                             const fetch = await postRequest("bridge/claim", data)
                                             if (fetch.status == true) {
                                                toast.success("Transaction completed successfully")
                                                setCurrencyCoin("Select Currency")
                                                setCurrencyCoin1("Select Currency")
                                                setCurrencyimage("")
                                                setCurrencyimage1("")
                                                setFromamount("")
                                                setToamount("")
                                                setCurrencySymbol("")
                                                setCurrencySymbol1("")
                                                setfromCurrencybal("")
                                                settoCurrencybal("")
                                                setLoading(false)
                                                navigate("/bridgehistory")
                                                refresh()
                                                refresheth()
                                                return
                                             }
                                          }
                                       }
                                       getTransactionReceipt(hash)


                                    }).on('receipt', async (approveresult) => {

                                    })
                                 }
                                 catch (err) {
                                    toast.error("Transaction rejected")
                                    setLoading(false)
                                 }

                              }
                              else {
                                 const deposit = await polygoncontractMarket.methods.deposit("0x0000000000000000000000000000000000000000", buyprice1).send({ from: address, value: buyprice1 }).on('transactionHash', async (hash) => { }).on('receipt', async (approveresult) => {
                                    const fetch = await postRequest("bridge/deposit", { "fromCurrency": currencysymbol, "fromAddress": approveresult.events.DepositEvent.returnValues.user, "toAddress": approveresult.to, "fromAmount": (approveresult.events.DepositEvent.returnValues.amount / 1e18)?.toString(), "txnHash": approveresult.events.DepositEvent.transactionHash, "type": "deposit", "toCurrency": currencysymbol1, "toAmount": formatAmount(toamount?.toString()), "status": "completed" })
                                    if (fetch.status == true) {
                                       const buyprice2 = web3.utils.toWei(formatAmount(toamount?.toString()), 'ether');
                                       const buyprice3 = toamount * 1e6;

                                       const getvrs = await postRequest("admin/nft/get_vrs", { "userAddress": address, "currencySymbol": currencysymbol1, "amount": formatAmount(toamount?.toString()) })
                                       if (getvrs.status == true) {

                                          try {

                                             if (walletType === 'okx') {
                                                if (Network1 !== chainMap[parseInt(window.okxwallet.chainId)]) {
                                                   await switchNetwork(chainIdMap[Network1])
                                                }
                                             } else {
                                                if (currencysymbol1 == "BNB" && chainid !== chainId.BINANCE) { await switchNetworkAsync(chainId.BINANCE) }
                                                else if (currencysymbol1 == "BUSD" && chainid !== chainId.BINANCE) { await switchNetworkAsync(chainId.BINANCE) }
                                                else if (currencysymbol1 == "WOR" && chainid !== chainId.BINANCE) { await switchNetworkAsync(chainId.BINANCE) }
                                                else if (currencysymbol1 == "ETH" && chainid !== chainId.ETHEREUM) { await switchNetworkAsync(chainId.ETHEREUM) }
                                                else if (currencysymbol1 == "USDT" && chainid !== chainId.ETHEREUM) { await switchNetworkAsync(chainId.ETHEREUM) }
                                                if (currencysymbol1 == "BNB" && chainid == chainId.BINANCE) { await switchNetworkAsync(chainId.BINANCE) }
                                                else if (currencysymbol1 == "BUSD" && chainid == chainId.BINANCE) { await switchNetworkAsync(chainId.BINANCE) }
                                                else if (currencysymbol1 == "WOR" && chainid == chainId.BINANCE) { await switchNetworkAsync(chainId.BINANCE) }
                                                else if (currencysymbol1 == "ETH" && chainid == chainId.ETHEREUM) { await switchNetworkAsync(chainId.ETHEREUM) }
                                                else if (currencysymbol1 == "USDT" && chainid == chainId.ETHEREUM) { await switchNetworkAsync(chainId.ETHEREUM) }
                                             }
                                          }
                                          catch (error) {

                                             toast.error('User rejected the switch network')
                                             setLoading(false)

                                             return
                                          }
                                          setTimeout(async () => {
                                             try {
                                                claim = await contract.methods.claim(currencysymbol1 == "USDT" ? buyprice3 : buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, currencysymbol1 == "USDT" ? contracts.usdt : currencysymbol1 == "BUSD" ? contracts.busd : currencysymbol1 == "WOR" ? contracts.wor : "0x0000000000000000000000000000000000000000").send({ from: address }).on('transactionHash', async (hash) => {
                                                   const getTransactionReceipt = async (hash) => {
                                                      const result = await web3.eth.getTransactionReceipt(hash)
                                                      if (result === null) {
                                                         getTransactionReceipt(hash)
                                                      } else {
                                                         let data = {
                                                            "fromCurrency": currencysymbol,
                                                            "toCurrency": currencysymbol1,
                                                            "fromAddress": result.to,
                                                            "toAddress": result.from,
                                                            "fromAmount": fromamount?.toString(),
                                                            "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100))?.toString(),
                                                            "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100))?.toString(),
                                                            "txnHash": result.transactionHash,
                                                            "network": Network1,
                                                            "type": "claim",
                                                            "status": "completed"
                                                         }
                                                         const fetch = await postRequest("bridge/claim", data)
                                                         if (fetch.status == true) {
                                                            toast.success("Transaction completed successfully")
                                                            setCurrencyCoin("Select Currency")
                                                            setCurrencyCoin1("Select Currency")
                                                            setCurrencyimage("")
                                                            setCurrencyimage1("")
                                                            setFromamount("")
                                                            setToamount("")
                                                            setCurrencySymbol("")
                                                            setCurrencySymbol1("")
                                                            setfromCurrencybal("")
                                                            settoCurrencybal("")
                                                            setLoading(false)
                                                            navigate("/bridgehistory")
                                                            refresh()
                                                            refresheth()
                                                            return
                                                         }
                                                      }
                                                   }
                                                   getTransactionReceipt(hash)
                                                }).on('receipt', async (approveresult) => {


                                                })


                                             }
                                             catch (err) {
                                                let data =
                                                {
                                                   "fromCurrency": currencysymbol,
                                                   "toCurrency": currencysymbol1,
                                                   "fromAddress": currencysymbol1 == "ETH" ? contracts.bridge.Eth_contract : currencysymbol1 == "BNB" ? contracts.bridge.Bnb_contract : currencysymbol1 == "USDT" ? contracts.bridge.Eth_contract :
                                                      currencysymbol1 == "BUSD" ? contracts.bridge.Bnb_contract : currencysymbol1 == "WOR" ? contracts.bridge.Bnb_contract : "",
                                                   "toAddress": address,
                                                   "fromAmount": fromamount?.toString(),
                                                   "toAmount": toamount?.toString(),
                                                   "network": Network1,
                                                   "adminFee": "0",
                                                   "type": "claim",
                                                   "status": "cancelled"
                                                }
                                                let fetch = await postRequest("bridge/claim", data)
                                                if (fetch.status == true) {
                                                   toast.error("Transaction Rejected")
                                                   setLoading(false)
                                                }
                                             }
                                          }, 10000)

                                       }
                                       else {
                                          toast.error(getvrs.data)
                                          let data =
                                          {
                                             "fromCurrency": currencysymbol,
                                             "toCurrency": currencysymbol1,
                                             "fromAddress": currencysymbol1 == "BNB" ? contracts.bridge.Bnb_contract : currencysymbol1 == "ETH" ? contracts.bridge.Eth_contract : currencysymbol1 == "USDT" ? contracts.bridge.Eth_contract :
                                                currencysymbol1 == "BUSD" ? contracts.bridge.Bnb_contract : currencysymbol1 == "WOR" ? contracts.bridge.Bnb_contract : "",
                                             "toAddress": address,
                                             "fromAmount": fromamount?.toString(),
                                             "toAmount": toamount?.toString(),
                                             "network": Network1,
                                             "adminFee": "0",
                                             "type": "claim",
                                             "status": "cancelled"
                                          }
                                          let fetch = await postRequest("bridge/claim", data)
                                          if (fetch.status == true) {
                                             navigate("/bridgehistory")
                                             setLoading(false)
                                          }
                                       }

                                    }
                                 })
                              }
                           }
                           catch (error) {
                              toast.error("Transaction rejected")
                              setLoading(false)
                           }
                        }
                        else {
                           toast.error("Insufficient Matic balance")
                           setLoading(false)
                        }
                     })

                  }
                  catch (error) {
                     setLoading(false)
                  }
               }
               else if (currencysymbol == "USDT" && currencycointype == "token") {
                  try {
                     if (walletType === 'okx') {
                        if (Network !== chainMap[parseInt(window.okxwallet.chainId)]) {
                           await switchNetwork(chainIdMap[Network])
                        }
                     } else {
                        if (
                           currencysymbol == "USDT" &&
                           chainid !== chainId.ETHEREUM
                        ) {
                           await switchNetworkAsync(chainId.ETHEREUM)
                        }
                     }
                     const balance = await usdtcontractMarket.methods.balanceOf(address).call().then(async (bal) => {
                        const tetherbalance = bal / 1e6;
                        if (Number(tetherbalance) > Number(fromamount)) {
                           const buyprice1 = fromamount * 1e6;
                           try {
                              const approve = await usdtcontractMarket.methods.approve(contracts.bridge.Eth_contract, buyprice1).send({ from: address }).on('transactionHash1', async (hash) => { }).on('receipt1', async (approveresult) => { })
                              const deposit = await ethcontractMarket.methods.deposit(contracts.usdt, buyprice1).send({ from: address, value: 0 }).on('transactionHash', async (hash) => { }).on('receipt', async (approveresult) => {

                                 const fetch = await postRequest("bridge/deposit", { "fromCurrency": currencysymbol, "fromAddress": approveresult.events.DepositEvent.returnValues.user, "toAddress": approveresult.to, "fromAmount": (approveresult.events.DepositEvent.returnValues.amount / 1e6)?.toString(), "txnHash": approveresult.events.DepositEvent.transactionHash, "type": "deposit", "toCurrency": currencysymbol1, "toAmount": formatAmount(toamount?.toString()), "status": "completed" })
                                 if (fetch.status == true) {
                                    const buyprice2 = web3.utils.toWei(formatAmount(toamount?.toString()), 'ether');

                                    const getvrs = await postRequest("admin/nft/get_vrs", { "userAddress": address, "currencySymbol": currencysymbol1, "amount": formatAmount(toamount?.toString()) })
                                    if (getvrs.status == true) {
                                       {

                                          try {
                                             if (walletType === 'okx') {
                                                if (Network1 !== chainMap[parseInt(window.okxwallet.chainId)]) {
                                                   await switchNetwork(chainIdMap[Network1])
                                                }
                                             } else {

                                                if (currencysymbol1 == "BNB" && chainid !== chainId.BINANCE) { await switchNetworkAsync(chainId.BINANCE) }
                                                else if (currencysymbol1 == "BUSD" && chainid !== chainId.BINANCE) { await switchNetworkAsync(chainId.BINANCE) }
                                                else if (currencysymbol1 == "WOR" && chainid !== chainId.BINANCE) { await switchNetworkAsync(chainId.BINANCE) }
                                                else if (currencysymbol1 == "ETH" && chainid !== chainId.ETHEREUM) { }
                                                else if (currencysymbol1 == "MATIC" && chainid !== chainId.AMOY) { await switchNetworkAsync(chainId.AMOY) }
                                                else if (currencysymbol1 == "BNB" && chainid == chainId.BINANCE) { await switchNetworkAsync(chainId.BINANCE) }
                                                else if (currencysymbol1 == "BUSD" && chainid == chainId.BINANCE) { await switchNetworkAsync(chainId.BINANCE) }
                                                else if (currencysymbol1 == "WOR" && chainid == chainId.BINANCE) { await switchNetworkAsync(chainId.BINANCE) }
                                                else if (currencysymbol1 == "ETH" && chainid == chainId.ETHEREUM) { }
                                                else if (currencysymbol1 == "MATIC" && chainid == chainId.AMOY) { await switchNetworkAsync(chainId.AMOY) }
                                             }
                                          }
                                          catch (error) {

                                             toast.error('User rejected the switch network')
                                             setLoading(false)

                                             return
                                          }

                                          setTimeout(async () => {
                                             try {
                                                claim = await contract.methods.claim(buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, currencysymbol1 == "USDT" ? contracts.usdt : currencysymbol1 == "BUSD" ? contracts.busd : currencysymbol1 == "WOR" ? contracts.wor : "0x0000000000000000000000000000000000000000").send({ from: address }).on('transactionHash', async (hash) => {

                                                   const getTransactionReceipt = async (hash) => {
                                                      const result = await web3.eth.getTransactionReceipt(hash)
                                                      if (result === null) {
                                                         getTransactionReceipt(hash)
                                                      } else {
                                                         let data = {
                                                            "fromCurrency": currencysymbol,
                                                            "toCurrency": currencysymbol1,
                                                            "fromAddress": result.to,
                                                            "toAddress": result.from,
                                                            "fromAmount": fromamount?.toString(),
                                                            "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100))?.toString(),
                                                            "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100))?.toString(),
                                                            "txnHash": result.transactionHash,
                                                            "network": Network1,
                                                            "type": "claim",
                                                            "status": "completed"
                                                         }
                                                         const fetch = await postRequest("bridge/claim", data)
                                                         if (fetch.status == true) {
                                                            toast.success("Transaction completed successfully")
                                                            setCurrencyCoin("Select Currency")
                                                            setCurrencyCoin1("Select Currency")
                                                            setCurrencyimage("")
                                                            setCurrencyimage1("")
                                                            setFromamount("")
                                                            setToamount("")
                                                            setCurrencySymbol("")
                                                            setCurrencySymbol1("")
                                                            setfromCurrencybal("")
                                                            settoCurrencybal("")
                                                            setLoading(false)
                                                            navigate("/bridgehistory")
                                                            refresh()
                                                            refresheth()
                                                            return
                                                         }
                                                      }
                                                   }
                                                   getTransactionReceipt(hash)
                                                }).on('receipt', async (approveresult) => {

                                                })
                                             }
                                             catch (err) {
                                                let data =
                                                {
                                                   "fromCurrency": currencysymbol,
                                                   "toCurrency": currencysymbol1,
                                                   "fromAddress": currencysymbol1 == "ETH" ? contracts.bridge.Eth_contract : currencysymbol1 == "MATIC" ? contracts.bridge.Polygon_contract : currencysymbol1 == "BNB" ? contracts.bridge.Bnb_contract : currencysymbol1 == "BUSD" ? contracts.bridge.Bnb_contract : currencysymbol1 == "WOR" ? contracts.bridge.Bnb_contract : "",
                                                   "toAddress": address,
                                                   "fromAmount": fromamount?.toString(),
                                                   "toAmount": toamount?.toString(),
                                                   "network": Network1,
                                                   "adminFee": "0",
                                                   "type": "claim",
                                                   "status": "cancelled"
                                                }
                                                let fetch = await postRequest("bridge/claim", data)
                                                if (fetch.status == true) {
                                                   toast.error("Transaction Rejected")
                                                   setLoading(false)
                                                }
                                             }
                                          }, 10000)
                                       }

                                    }
                                    else {
                                       toast.error(getvrs.data)
                                       let data =
                                       {
                                          "fromCurrency": currencysymbol,
                                          "toCurrency": currencysymbol1,
                                          "fromAddress": currencycoin1 == "BNB" ? contracts.bridge.Bnb_contract : currencycoin1 == "Ethereum" ? contracts.bridge.Eth_contract : currencycoin1 == "MATIC" ? contracts.bridge.Polygon_contract :
                                             currencycoin1 == "BUSD" ? contracts.bridge.Bnb_contract : currencycoin1 == "WOR" ? contracts.bridge.Bnb_contract : "",
                                          "toAddress": address,
                                          "fromAmount": fromamount?.toString(),
                                          "toAmount": toamount?.toString(),
                                          "network": Network1,
                                          "adminFee": "0",
                                          "type": "claim",
                                          "status": "cancelled"
                                       }
                                       let fetch = await postRequest("bridge/claim", data)
                                       if (fetch.status == true) {
                                          navigate("/bridgehistory")
                                          setLoading(false)
                                       }
                                    }

                                 }
                              })
                           }
                           catch (error) {
                              setLoading(false)
                           }
                        }
                        else {
                           toast.error("Insufficient tether(usdt) balance")
                           setLoading(false)
                        }
                     })

                  }
                  catch (error) {
                     setLoading(false)
                  }
               }
               else if (currencysymbol == "BUSD" && currencycointype == "token") {
                  try {
                     if (walletType === 'okx') {
                        if (Network !== chainMap[parseInt(window.okxwallet.chainId)]) {
                           await switchNetwork(chainIdMap[Network])
                        }
                     } else {
                        if (
                           currencysymbol == "BUSD" &&
                           chainid !== chainId.BINANCE
                        ) {
                           await switchNetworkAsync(chainId.BINANCE)
                        }
                     }
                     const balance = await busdcontractMarket.methods.balanceOf(address).call().then(async (bal) => {
                        const busdbalance = web3.utils.fromWei(bal, 'ether');
                        if (Number(busdbalance) > Number(fromamount)) {
                           const buyprice1 = web3.utils.toWei(fromamount, 'ether');
                           const approve = await busdcontractMarket.methods.approve(contracts.bridge.Bnb_contract, buyprice1).send({ from: address }).on('transactionHash1', async (hash) => { }).on('receipt1', async (approveresult) => {
                           })
                           const deposit = await bnbcontractMarket.methods.deposit(contracts.busd, buyprice1).send({ from: address, value: 0 }).on('transactionHash', async (hash) => { }).on('receipt', async (approveresult) => {

                              const fetch = await postRequest("bridge/deposit", { "fromCurrency": currencysymbol, "fromAddress": approveresult.events.DepositEvent.returnValues.user, "toAddress": approveresult.to, "fromAmount": (approveresult.events.DepositEvent.returnValues.amount / 1e18)?.toString(), "txnHash": approveresult.events.DepositEvent.transactionHash, "type": "deposit", "toCurrency": currencysymbol1, "toAmount": formatAmount(toamount?.toString()), "status": "completed" })
                              if (fetch.status == true) {
                                 const buyprice2 = web3.utils.toWei(formatAmount(toamount.toString()), 'ether');
                                 const buyprice3 = toamount * 1e6;

                                 const getvrs = await postRequest("admin/nft/get_vrs", { "userAddress": address, "currencySymbol": currencysymbol1, "amount": formatAmount(toamount?.toString()) })
                                 if (getvrs.status == true) {
                                    try {

                                       if (walletType === 'okx') {
                                          if (Network1 !== chainMap[parseInt(window.okxwallet.chainId)]) {
                                             await switchNetwork(chainIdMap[Network1])
                                          }
                                       } else {
                                          if (currencysymbol1 == "BNB" && chainid !== chainId.BINANCE) { }
                                          else if (currencysymbol1 == "WOR" && chainid !== chainId.BINANCE) { }
                                          else if (currencysymbol1 == "ETH" && chainid !== chainId.ETHEREUM) { await switchNetworkAsync(chainId.ETHEREUM) }
                                          else if (currencysymbol1 == "USDT" && chainid !== chainId.ETHEREUM) { await switchNetworkAsync(chainId.ETHEREUM) }
                                          else if (currencysymbol1 == "MATIC" && chainid !== chainId.AMOY) { await switchNetworkAsync(chainId.AMOY) }
                                          else if (currencysymbol1 == "BNB" && chainid == chainId.BINANCE) { }
                                          else if (currencysymbol1 == "WOR" && chainid == chainId.BINANCE) { }
                                          else if (currencysymbol1 == "ETH" && chainid == chainId.ETHEREUM) { await switchNetworkAsync(chainId.ETHEREUM) }
                                          else if (currencysymbol1 == "USDT" && chainid == chainId.ETHEREUM) { await switchNetworkAsync(chainId.ETHEREUM) }
                                          else if (currencysymbol1 == "MATIC" && chainid == chainId.AMOY) { await switchNetworkAsync(chainId.AMOY) }
                                       }
                                    }
                                    catch (error) {

                                       toast.error('User rejected the switch network')
                                       setLoading(false)

                                       return
                                    }

                                    setTimeout(async () => {
                                       try {
                                          claim = await contract.methods.claim(currencysymbol1 == "USDT" ? buyprice3 : buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, currencysymbol1 == "USDT" ? contracts.usdt : currencysymbol1 == "BUSD" ? contracts.busd : currencysymbol1 == "WOR" ? contracts.wor : "0x0000000000000000000000000000000000000000").send({ from: address }).on('transactionHash', async (hash) => {

                                             const getTransactionReceipt = async (hash) => {
                                                const result = await web3.eth.getTransactionReceipt(hash)
                                                if (result === null) {
                                                   getTransactionReceipt(hash)
                                                } else {
                                                   let data = {
                                                      "fromCurrency": currencysymbol,
                                                      "toCurrency": currencysymbol1,
                                                      "fromAddress": result.to,
                                                      "toAddress": result.from,
                                                      "fromAmount": fromamount?.toString(),
                                                      "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100))?.toString(),
                                                      "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100))?.toString(),
                                                      "txnHash": result.transactionHash,
                                                      "network": Network1,
                                                      "type": "claim",
                                                      "status": "completed"
                                                   }
                                                   const fetch = await postRequest("bridge/claim", data)
                                                   if (fetch.status == true) {
                                                      toast.success("Transaction completed successfully")
                                                      setCurrencyCoin("Select Currency")
                                                      setCurrencyCoin1("Select Currency")
                                                      setCurrencyimage("")
                                                      setCurrencyimage1("")
                                                      setFromamount("")
                                                      setToamount("")
                                                      setCurrencySymbol("")
                                                      setCurrencySymbol1("")
                                                      setfromCurrencybal("")
                                                      settoCurrencybal("")
                                                      setLoading(false)
                                                      navigate("/bridgehistory")
                                                      refresh()
                                                      refresheth()
                                                      return
                                                   }
                                                }
                                             }
                                             getTransactionReceipt(hash)
                                          }).on('receipt', async (approveresult) => {

                                          })
                                       }
                                       catch (err) {
                                          let data =
                                          {
                                             "fromCurrency": currencysymbol,
                                             "toCurrency": currencysymbol1,
                                             "fromAddress": currencysymbol1 == "ETH" ? contracts.bridge.Eth_contract : currencysymbol1 == "MATIC" ? contracts.bridge.Polygon_contract : currencysymbol1 == "USDT" ? contracts.bridge.Eth_contract :
                                                currencysymbol1 == "BNB" ? contracts.bridge.Bnb_contract : currencysymbol1 == "WOR" ? contracts.bridge.Bnb_contract : "",
                                             "toAddress": address,
                                             "fromAmount": fromamount?.toString(),
                                             "toAmount": toamount?.toString(),
                                             "network": Network1,
                                             "adminFee": "0",
                                             "type": "claim",
                                             "status": "cancelled"
                                          }
                                          let fetch = await postRequest("bridge/claim", data)
                                          if (fetch.status == true) {
                                             toast.error("Transaction Rejected")
                                             setLoading(false)
                                          }
                                       }
                                    }, 10000)

                                 }
                                 else {
                                    toast.error(getvrs.data)
                                    let data =
                                    {
                                       "fromCurrency": currencysymbol,
                                       "toCurrency": currencysymbol1,
                                       "fromAddress": currencysymbol1 == "BNB" ? contracts.bridge.Bnb_contract : currencysymbol1 == "ETH" ? contracts.bridge.Eth_contract : currencysymbol1 == "MATIC" ? contracts.bridge.Polygon_contract :
                                          currencysymbol1 == "USDT" ? contracts.bridge.Eth_contract : currencysymbol1 == "WOR" ? contracts.bridge.Bnb_contract : "",
                                       "toAddress": address,
                                       "fromAmount": fromamount?.toString(),
                                       "toAmount": toamount?.toString(),
                                       "network": Network1,
                                       "adminFee": "0",
                                       "type": "claim",
                                       "status": "cancelled"
                                    }
                                    let fetch = await postRequest("bridge/claim", data)
                                    if (fetch.status == true) {
                                       navigate("/bridgehistory")
                                       setLoading(false)
                                    }
                                 }

                              }
                           })
                        }
                        else {
                           toast.error("Insufficient busd balance")
                           setLoading(false)
                        }
                     })
                  }
                  catch (error) {
                     setLoading(false)
                  }
               }
               else if (currencysymbol == "WOR" && currencycointype == "token") {
                  try {
                     if (walletType === 'okx') {
                        if (Network !== chainMap[parseInt(window.okxwallet.chainId)]) {
                           await switchNetwork(chainIdMap[Network])
                        }
                     } else {
                        if (
                           currencysymbol == "WOR" &&
                           chainid !== chainId.BINANCE
                        ) {
                           await switchNetworkAsync(chainId.BINANCE)
                        }
                     }
                     const balance = await worcontractMarket.methods.balanceOf(address).call().then(async (bal) => {
                        const worbalance = web3.utils.fromWei(bal, 'ether');
                        if (Number(worbalance) > Number(fromamount)) {
                           const buyprice1 = web3.utils.toWei(fromamount, 'ether');
                           const approve = await worcontractMarket.methods.approve(contracts.bridge.Bnb_contract, buyprice1).send({ from: address }).on('transactionHash1', async (hash) => { }).on('receipt1', async (approveresult) => {
                           })
                           const deposit = await bnbcontractMarket.methods.deposit(contracts.wor, buyprice1).send({ from: address, value: 0 }).on('transactionHash', async (hash) => { }).on('receipt', async (approveresult) => {

                              const fetch = await postRequest("bridge/deposit", { "fromCurrency": currencysymbol, "fromAddress": approveresult.events.DepositEvent.returnValues.user, "toAddress": approveresult.to, "fromAmount": (approveresult.events.DepositEvent.returnValues.amount / 1e18)?.toString(), "txnHash": approveresult.events.DepositEvent.transactionHash, "type": "deposit", "toCurrency": currencysymbol1, "toAmount": formatAmount(toamount?.toString()), "status": "completed" })
                              if (fetch.status == true) {
                                 const buyprice2 = web3.utils.toWei(formatAmount(toamount?.toString()), 'ether');
                                 const buyprice3 = toamount * 1e6;
                                 const getvrs = await postRequest("admin/nft/get_vrs", { "userAddress": address, "currencySymbol": currencysymbol1, "amount": formatAmount(toamount?.toString()) })
                                 if (getvrs.status == true) {
                                    {

                                       try {
                                          if (walletType === 'okx') {
                                             if (Network1 !== chainMap[parseInt(window.okxwallet.chainId)]) {
                                                await switchNetwork(chainIdMap[Network1])
                                             }
                                          } else {

                                             if (currencysymbol1 == "BNB" && chainid !== chainId.BINANCE) { }
                                             else if (currencysymbol1 == "BUSD" && chainid !== chainId.BINANCE) { }
                                             else if (currencysymbol1 == "ETH" && chainid !== chainId.ETHEREUM) { await switchNetworkAsync(chainId.ETHEREUM) }
                                             else if (currencysymbol1 == "USDT" && chainid !== chainId.ETHEREUM) { await switchNetworkAsync(chainId.ETHEREUM) }
                                             else if (currencysymbol1 == "MATIC" && chainid !== chainId.AMOY) { await switchNetworkAsync(chainId.AMOY) }
                                             else if (currencysymbol1 == "BNB" && chainid == chainId.BINANCE) { }
                                             else if (currencysymbol1 == "BUSD" && chainid == chainId.BINANCE) { }
                                             else if (currencysymbol1 == "ETH" && chainid == chainId.ETHEREUM) { await switchNetworkAsync(chainId.ETHEREUM) }
                                             else if (currencysymbol1 == "USDT" && chainid == chainId.ETHEREUM) { await switchNetworkAsync(chainId.ETHEREUM) }
                                             else if (currencysymbol1 == "MATIC" && chainid == chainId.AMOY) { await switchNetworkAsync(chainId.AMOY) }
                                          }
                                       }
                                       catch (error) {

                                          toast.error('User rejected the switch network')
                                          setLoading(false)

                                          return
                                       }
                                       setTimeout(async () => {
                                          try {
                                             claim = await contract.methods.claim(currencysymbol1 == "USDT" ? buyprice3 : buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, currencysymbol1 == "USDT" ? contracts.usdt : currencysymbol1 == "BUSD" ? contracts.busd : currencysymbol1 == "WOR" ? contracts.wor : "0x0000000000000000000000000000000000000000").send({ from: address }).on('transactionHash', async (hash) => {

                                                const getTransactionReceipt = async (hash) => {
                                                   const result = await web3.eth.getTransactionReceipt(hash)
                                                   if (result === null) {
                                                      getTransactionReceipt(hash)
                                                   } else {
                                                      let data = {
                                                         "fromCurrency": currencysymbol,
                                                         "toCurrency": currencysymbol1,
                                                         "fromAddress": result.to,
                                                         "toAddress": result.from,
                                                         "fromAmount": fromamount?.toString(),
                                                         "toAmount": (Number(toamount) - ((getvrs.adminFee * +toamount) / 100))?.toString(),
                                                         "adminFee": (formatAmount((getvrs.adminFee * +toamount) / 100))?.toString(),
                                                         "txnHash": result.transactionHash,
                                                         "network": Network1,
                                                         "type": "claim",
                                                         "status": "completed"
                                                      }
                                                      const fetch = await postRequest("bridge/claim", data)
                                                      if (fetch.status == true) {
                                                         toast.success("Transaction completed successfully")
                                                         setCurrencyCoin("Select Currency")
                                                         setCurrencyCoin1("Select Currency")
                                                         setCurrencyimage("")
                                                         setCurrencyimage1("")
                                                         setFromamount("")
                                                         setToamount("")
                                                         setCurrencySymbol("")
                                                         setCurrencySymbol1("")
                                                         setfromCurrencybal("")
                                                         settoCurrencybal("")
                                                         setLoading(false)
                                                         navigate("/bridgehistory")
                                                         refresh()
                                                         refresheth()
                                                         return
                                                      }
                                                   }
                                                }
                                                getTransactionReceipt(hash)
                                             }).on('receipt', async (approveresult) => {

                                             })
                                          }
                                          catch (err) {
                                             let data =
                                             {
                                                "fromCurrency": currencysymbol,
                                                "toCurrency": currencysymbol1,
                                                "fromAddress": currencysymbol1 == "ETH" ? contracts.bridge.Eth_contract : currencysymbol1 == "MATIC" ? contracts.bridge.Polygon_contract : currencysymbol1 == "USDT" ? contracts.bridge.Eth_contract :
                                                   currencysymbol1 == "BUSD" ? contracts.bridge.Bnb_contract : currencysymbol1 == "BNB" ? contracts.bridge.Bnb_contract : "",
                                                "toAddress": address,
                                                "fromAmount": fromamount?.toString(),
                                                "toAmount": toamount?.toString(),
                                                "network": Network1,
                                                "adminFee": "0",
                                                "type": "claim",
                                                "status": "cancelled"
                                             }
                                             let fetch = await postRequest("bridge/claim", data)
                                             if (fetch.status == true) {
                                                toast.error("Transaction Rejected")
                                                setLoading(false)
                                             }
                                          }
                                       }, 10000)
                                    }
                                 }
                                 else {
                                    toast.error(getvrs.data)
                                    let data =
                                    {
                                       "fromCurrency": currencysymbol,
                                       "toCurrency": currencysymbol1,
                                       "fromAddress": currencysymbol1 == "BNB" ? contracts.bridge.Bnb_contract : currencysymbol1 == "ETH" ? contracts.bridge.Eth_contract : currencysymbol1 == "MATIC" ? contracts.bridge.Polygon_contract :
                                          currencysymbol1 == "USDT" ? contracts.bridge.Eth_contract : currencysymbol1 == "BUSD" ? contracts.bridge.Bnb_contract : "",
                                       "toAddress": address,
                                       "fromAmount": fromamount?.toString(),
                                       "toAmount": toamount?.toString(),
                                       "network": Network1,
                                       "adminFee": "0",
                                       "type": "claim",
                                       "status": "cancelled"
                                    }
                                    let fetch = await postRequest("bridge/claim", data)
                                    if (fetch.status == true) {
                                       navigate("/bridgehistory")
                                       setLoading(false)
                                    }
                                 }

                              }
                           })
                        }
                        else {
                           toast.error("Insufficient wor token balance")
                           setLoading(false)
                        }
                     })
                  }
                  catch (error) {
                     setLoading(false)
                  }
               }

            }
            else {
               toast.error(balancecheck.message)
               setLoading(false)
            }
         }
      }
      else {
         toast.error("Please Connectwallet")
         setLoading(false)
      }
   }

   const Exchange = async () => {

      setCurrencyCoin(currencycoin1)
      setCurrencyCoinType(currencycointype1)
      setCurrencyCoin1(currencycoin)
      setCurrencyCoinType1(currencycointype)
      setFromamount(toamount.toString())
      setCurrencySymbol(currencysymbol1)
      setCurrencySymbol1(currencysymbol)
      setCurrencyimage(currencyimage1)
      setCurrencyimage1(currencyimage)
      setNetwork(Network1)
      setNetwork1(Network)
      setfromCurrencybal(tocurrencybal)
      settoCurrencybal(fromcurrencybal)
      const fetch = await postRequest("admin/nft/get_single_pair", { "fromCurrency": currencysymbol1, "toCurrency": currencysymbol, "fromAmount": toamount })
      if (fetch.status == true) {
         setToamount(fetch.usdPrice)
         setUsergetBal((Number(fetch.usdPrice) - ((feecurrency * +fetch.usdPrice) / 100)))
         setAdminFeeAmount((formatAmount((feecurrency * +fetch.usdPrice) / 100)))
      }
      if (minamount && toamount && currencysymbol1) {

         if (minamount > Number(toamount)) {
            document.getElementById('fromamount1').innerHTML = t('Your Min Amount is') + minamount
         }
         else if (maxamount < Number(toamount)) {
            document.getElementById('fromamount1').innerHTML = t('Your Max Amount is') + maxamount
         }
         else {
            document.getElementById('fromamount1').innerHTML = ''
         }
      }
   }

   const fromsearchItems = async (searchValue) => {
      const search = await getRequest(`currencies?filter=${searchValue}`)
      if (search.status == true) {
         setCurrencies(search.data)
      }
   }
   const tosearchItems = async (searchValue) => {
      const search = await getRequest(`currencies?filter=${searchValue}`)
      if (search.status == true) {
         setCurrencies(search.data)
      }
   }

   return (
      <>
         <div className={loading ? "spinner" : "d-none"}>
            <img src={loader} alt='' className='spinAnimate' />
         </div>

         <section class="BriSec inrPgMt inrPgMb">
            <div class="container">
               <div class="brhsd text-center">
                  <h1>{t("Bridge")}</h1>

               </div>
               <div class="row justify-content-center">
                  <div class="col-xl-6 col-lg-8">
                     <div class="crdbrg">
                        <div class="FrmIpt">

                           <div className="d-flex justify-content-between">
                              <h1>{t("You Send")}</h1>
                              {currencysymbol ?
                                 <h1>{t("Balance")} {fromcurrencybal ? fromcurrencybal.toFixed(4) : 0} {currencysymbol ? currencysymbol : ""}</h1>
                                 :
                                 ""
                              }
                           </div>
                           <div class="d-flex justify-content-center justify-content-between">
                              <div class="form-group">
                                 <NumericFormat class="form-control" name="fromamount" value={fromamount} onChange={getAmount} allowNegative={false} customInput={Input} />
                                 <span id='fromamount1' style={{ color: "red" }}></span>
                              </div>

                              <div>
                                 <button class="btn sltbtn" onClick={toggle}>
                                    {currencyimage ?
                                       <span><img src={currencyimage} style={{ width: "20px", height: "20px", borderRadius: "50%" }} alt="" /></span>
                                       :
                                       ""
                                    }
                                    <span>
                                       {currencysymbol ? currencysymbol : t("Select")}
                                    </span>
                                    <span>
                                       <svg width="10" height="6" viewBox="0 0 10 6" fill="none"
                                          xmlns="http://www.w3.org/2000/svg">
                                          <path d="M1 1L5 5L9 1" stroke="white" />
                                       </svg>
                                    </span>
                                 </button>
                                 <span id='fromcurrency1' style={{ color: "red" }}></span>
                              </div>
                           </div>
                        </div>

                        <div class="text-right my-4" >
                           {!["WBNB", "WETH", "WMATIC"].includes(currencysymbol1) ?
                              <a href="#">
                                 <img src={swap} alt="swap" onClick={Exchange} onKeyDown={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                       Exchange()
                                    }
                                 }}
                                    role="button" tabIndex={0} />
                              </a>
                              :
                              ""
                           }
                        </div>

                        <div class="FrmIpt mb-35">

                           <div className="d-flex justify-content-between">
                              <h1>{t("You Get")}</h1>
                              {currencysymbol1 ?
                                 <h1>{t("Balance")} {tocurrencybal ? tocurrencybal.toFixed(4) : 0} {currencysymbol1 ? currencysymbol1 : ""}</h1>
                                 :
                                 ""
                              }
                           </div>
                           <div class="d-flex justify-content-center justify-content-between">
                              <div class="form-group">
                                 <NumericFormat type="number" min="0" class="form-control" name="toamount" value={toamount} allowNegative={false} customInput={Input} disabled />
                              </div>

                              <div>
                                 <button class="btn sltbtn" onClick={toggle1}>
                                    <span>
                                       {currencyimage1 ?
                                          <img src={currencyimage1} style={{ width: "20px", height: "20px", borderRadius: "50%" }} />
                                          :
                                          ""
                                       }</span>
                                    <span>
                                       {currencysymbol1 ? currencysymbol1 : t("Select")}
                                    </span>
                                    <span>
                                       <svg width="10" height="6" viewBox="0 0 10 6" fill="none"
                                          xmlns="http://www.w3.org/2000/svg">
                                          <path d="M1 1L5 5L9 1" stroke="white" />
                                       </svg>
                                    </span>
                                 </button>
                                 <span id='tocurrency1' style={{ color: "red" }}></span>

                              </div>
                           </div>
                           {currencysymbol1 ?
                              !["WBNB", "WETH", "WMATIC"].includes(currencysymbol1) ?
                                 <div style={{ justifyContent: "center", display: "flex" }}>
                                    <span style={{ color: "red" }}>{t("Admin Fee")} :{feecurrency}%</span>
                                 </div>
                                 :
                                 ""
                              :
                              ""
                           }
                           {toamount && currencysymbol1 && currencysymbol && fromamount ?
                              !["WBNB", "WETH", "WMATIC"].includes(currencysymbol1) ?
                                 <div style={{ justifyContent: "space-between", display: "flex" }}>
                                    <span style={{ color: "#ffa900" }}>{t("Net Amount")} : {Number(usetgetBal).toFixed(8)}</span>
                                    <span style={{ color: "#ffa900" }}>{t("Admin Fee")} : {Number(adminfeeamount).toFixed(8)}</span>

                                 </div>
                                 :
                                 ""
                              :
                              ""
                           }

                        </div>
                        {fromcurrencybal == 0 || toamount == 0 ?

                           < button class="btn swpbtn" disabled> {t("Swap")}</button>
                           :
                           <button class="btn swpbtn" onClick={Buy}> {t("Swap")}</button>
                        }

                     </div>
                  </div>
               </div>
            </div>
            <div class="shdcd">
               <img class="img-fluid" src={elprd} alt="elprd" />
            </div>
         </section >


         <Modal isOpen={modal} toggle={toggle} centered="true" className="curMdl" backdropClassName="selCurBp" keyboard={false}>
            <div className="selCrHd">
               <button className="btn">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z" fill="#595F6A" />
                  </svg>
               </button>
               <span>{t("Select Currency")}</span>
               <button className="btn" onClick={toggle}>
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z" fill="#595F6A" />
                  </svg>
               </button>
            </div>
            <div className="selCrSrh">
               <InputGroup className="curSrh">
                  <button className="btn" type="button">
                     <img src={searchIcon} alt="search" />
                  </button>
                  <Input placeholder={t("Search")} name="searchInput" onChange={(e) => fromsearchItems(e.target.value)} />
               </InputGroup>
            </div>
            <div className="selCrLs">

               {currencies && currencies.length > 0 ? (
                  currencies
                     .filter(item => {
                        const allowedCurrencies = currencysymbol1 ? [currencysymbol1] : ["ETH", "BNB", "MATIC", "USDT", "BUSD", "WOR"];

                        if (currencysymbol1 === "WMATIC") { allowedCurrencies.push("MATIC"); }
                        else if (currencysymbol1 === "WBNB") { allowedCurrencies.push("BNB"); }
                        else if (currencysymbol1 === "WETH") { allowedCurrencies.push("ETH"); }
                        else if (currencysymbol1 === "BNB") {
                           allowedCurrencies.pop("BNB");
                           allowedCurrencies.push("ETH", "MATIC", "BUSD", "USDT", "WOR");
                        } else if (currencysymbol1 === "ETH") {
                           allowedCurrencies.pop("ETH");
                           allowedCurrencies.push("BNB", "MATIC", "BUSD", "USDT", "WOR");
                        } else if (currencysymbol1 === "MATIC") {
                           allowedCurrencies.pop("MATIC");
                           allowedCurrencies.push("BNB", "ETH", "BUSD", "USDT", "WOR");
                        } else if (currencysymbol1 === "BUSD") {
                           allowedCurrencies.pop("BUSD");
                           allowedCurrencies.push("BNB", "ETH", "MATIC", "USDT", "WOR");
                        } else if (currencysymbol1 === "USDT") {
                           allowedCurrencies.pop("USDT");
                           allowedCurrencies.push("BNB", "ETH", "MATIC", "BUSD", "WOR");
                        } else if (currencysymbol1 === "WOR") {
                           allowedCurrencies.pop("WOR");
                           allowedCurrencies.push("BNB", "ETH", "MATIC", "BUSD", "USDT");
                        }

                        return allowedCurrencies.includes(item.currencySymbol) && !["WBNB", "WETH", "WMATIC"].includes(item.currencySymbol);
                     })
                     .map((user, index) => (
                        <button type="button" className="btn" onClick={() => getCurrency(user)} key={index}>
                           <img src={user.currencyImage} style={{ width: "20px", height: "20px", borderRadius: "50%" }} alt={user.currencySymbol} />
                           <span>{user.currencyName}({user.currencySymbol})</span>
                        </button>
                     ))
               ) : (
                  <div className="not-found text-center">
                     <label>{t("No Data Found")}</label>
                  </div>
               )}


            </div>
         </Modal>

         <Modal isOpen={modal1} toggle={toggle1} centered="true" className="curMdl" backdropClassName="selCurBp" keyboard={false}>
            <div className="selCrHd">
               <button className="btn">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z" fill="#595F6A" />
                  </svg>
               </button>
               <span>{t("Select Currency")}</span>
               <button className="btn" onClick={toggle1}>
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z" fill="#595F6A" />
                  </svg>
               </button>
            </div>
            <div className="selCrSrh">
               <InputGroup className="curSrh">
                  <button className="btn" type="button">
                     <img src={searchIcon} alt="search" />
                  </button>
                  <Input placeholder={t("Search")} onChange={(e) => tosearchItems(e.target.value)} />
               </InputGroup>
            </div>
            <div className="selCrLs">

               {currencies && currencies.length > 0 ? (
                  currencies
                     .filter(item => {
                        const exclusions = {
                           BNB: ["WETH", "WMATIC", "BNB"],
                           ETH: ["WBNB", "WMATIC", "ETH"],
                           MATIC: ["WETH", "WBNB", "MATIC"],
                           WOR: ["WETH", "WBNB", "WMATIC", "WOR"],
                           USDT: ["WETH", "WBNB", "WMATIC", "USDT"],
                           BUSD: ["WETH", "WBNB", "WMATIC", "BUSD"],
                        };

                        return !exclusions[currencysymbol]?.includes(item.currencySymbol);
                     })
                     .map((user, index) => (
                        <button type="button" className="btn" onClick={() => getCurrency1(user)} key={index}>
                           <img src={user.currencyImage} style={{ width: "20px", height: "20px", borderRadius: "50%" }} alt={user.currencySymbol} />
                           <span>{(user.currencyName).toUpperCase()}({user.currencySymbol})</span>
                        </button>
                     ))
               ) : (
                  <div className="not-found text-center">
                     <label>{t("No Data Found")}</label>
                  </div>
               )}

            </div>
         </Modal>

      </>
   )
}
export default Bridge;