import { useQuery } from '@tanstack/react-query'
import { flattenFilters } from '../../../utils/flattenFilters'
import { convertObjectToQueryString } from '../../../utils/convertObjectToQueryString'
import DataService from '../../../lib/DataService'

const { getRequest } = DataService()
export async function fetchCollectionActivity(
	collectionAddress,
	filters,
	pageParam,
	callback
) {
	
	const flattenedFilters = flattenFilters(filters)
	const queryString = convertObjectToQueryString(flattenedFilters)
	const apiUrl = `collection/activity?address=${collectionAddress}&page=${pageParam}&${queryString}`;

	const response = await getRequest(apiUrl);
	callback(response.count ?? response.data.length)
	return response.data
}

export function useFetchCollectionActivity(collectionAddress) {
	return useQuery({
		queryKey: ['collection_activity', collectionAddress],
		queryFn: () => fetchCollectionActivity(collectionAddress),
		enabled: !!collectionAddress
	})
}
