import React, { useEffect, useState } from 'react';
import angleDown from '../../assets/images/angleDown.svg';
import discord from '../../assets/images/discord.svg';
import telegramPlane from '../../assets/images/telegram-plane.svg';
import twitter from '../../assets/images/twitterNew.svg';
import instagram from '../../assets/images/instagram.svg';
import youtube from '../../assets/images/youtube.svg';
import infoIcon from '../../assets/images/infoIcon.svg';
import Pencil from '../../assets/images/Pencil.svg';
import defaultImage from '../../assets/images/homeExplorer/placeholder.png'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, UncontrolledPopover, PopoverBody, Input } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useToast } from '../../utils/toast/customSuccess';
import ScaleLoader from "react-spinners/ScaleLoader";
import reject from './decline.png'
import { NumericFormat } from 'react-number-format'
import { useTranslation } from 'react-i18next';
import { handleBannerImageError, handleImageError } from '../../hooks/useHandleImg';
import { nativeCoinMap, chainIconMap } from '../../utils/getFilterData';
import DataService from '../../lib/DataService'

export const ChooseCollection = () => {
  const { postformdataRequest, getRequest, postRequest } = DataService()
  const { t } = useTranslation()
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#ffffff");
  const toast = useToast()


  const navigate = useNavigate()
  const params = useParams()
  const mycollection = () => {
    navigate("/mycollection")
  }

  useEffect(() => {
    singlecollection()
    Category()
  }, [])

  const [singledata, setSingleData] = useState("")
  const [getcategory, setCategory] = useState("")

  const [Collectionverify, setCollectionVerify] = useState("")
  const [Logoverify, setLogoVerify] = useState("")



  const singlecollection = async () => {
    setLoading(true)
    const collection = await postRequest("user/getSingleCollection", { "id": params.id })
    if (collection.status == true) {
      setSingleData(collection.data)
      setDescription(collection.data.description)
      setBannerImage(collection.data.bannerImage)
      setDiscord(collection.data.discord)
      setTelegram(collection.data.telegram)
      setTwitter(collection.data.twitter)
      setInsta(collection.data.instagram)
      setYoutube(collection.data.youtube)
      setSingleCategory(collection.data.categoryName)
      setImgtype(collection.data.imageFormat)
      setRoyalityfee(collection.data.royaltyFee)
      setVerify(collection.data.twitterVerify)
      SetWholeVerify(collection.data.verification)
      setCollectionVerify(collection.data.collectionNameVerify)
      setLogoVerify(collection.data.logoVerify)
      setLoading(false)
    }
    else {
    }
  }

  const Category = async () => {
    const category = await postRequest("user/getCategoryList")
    if (category.status == true) {
      setCategory(category.data)
    }
    else {
    }
  }

  const [singlecategory, setSingleCategory] = useState("")
  const categories = (data) => {
    if (data.name == "" || data.name == undefined) {
      document.getElementById('category').innerHTML = t('Please choose categories')
    }
    else {
      setSingleCategory(data.name)
      document.getElementById('category').innerHTML = ''

    }
  }

  const [description, setDescription] = useState("")
  const [bannerimage, setBannerImage] = useState()
  const [discord1, setDiscord] = useState("")
  const [telegram, setTelegram] = useState("")
  const [twitter1, setTwitter] = useState("")
  const [insta, setInsta] = useState("")
  const [youtube1, setYoutube] = useState("")
  const [imgtype, setImgtype] = useState("")
  const [royalityfee, setRoyalityfee] = useState("")
  let imageFile;
  let size;
  let maxSizeKB;
  let maxSize;

  const imageupload = (e) => {
    imageFile = e.target.files[0];
    size = parseFloat(e.target.files[0]?.size);
    maxSizeKB = 10000;
    maxSize = 100 * 1024 * 1024;
    if (imageFile != undefined) {
      if (size < maxSize) {
        if (!imageFile.name.match(/\.(webp|png|jpg|jpeg|gif|mp4)$/)) {
          document.getElementById('uploadimage').innerHTML = t('Please select valid image for WEBP,JPG,JPEG,GIF,PNG,MP4')
        }
        else if (imageFile == "" || imageFile == undefined) {
          setBannerImage('')
          document.getElementById('uploadimage').innerHTML = t('Please choose image')
        }
        else {
          if (imageFile.type === 'video/mp4') {
            setBannerImage(imageFile);
            setImgtype(imageFile.type)
          }
          else {
            const reader = new FileReader()
            reader.onload = e => {
              const img = new Image()
              img.onload = () => {
                const width = img.naturalWidth;
                const height = img.naturalHeight;
                if (width >= 1400 && height >= 360) {
                  setBannerImage(imageFile);
                  setImgtype(imageFile.type)
                  document.getElementById('uploadimage').innerHTML = ''
                }
                else {
                  setBannerImage('');
                  setImgtype('')
                  document.getElementById('uploadimage').innerHTML =
                    'Use image size height 360 pixels, width 1400 pixels.';
                }
              }
              img.onerror = () => {
                document.getElementById('uploadimage').innerHTML =
                  t('Unsupported image file')
                setBannerImage('')
                setImgtype('')

              }
              img.src = e.target.result
            }
            reader.readAsDataURL(imageFile)
            document.getElementById('uploadimage').innerHTML = ''
          }
        }
      }
      else {
        document.getElementById('uploadimage').innerHTML = t('Maximum file size is 100mb')
        setBannerImage("")
      }
    }
    else {

    }
  }
  let disreg = /(https?: \/\/)?(www\.)?(discord\.(gg|io|me|li)|discordapp\.com\/invite)\/.+[a-z]/
  let telreg = /(https?:\/\/)?(www[.])?(telegram|t)\.me\/([a-zA-Z0-9_-]*)\/?$/
  let twireg = /^(?:https?:\/\/)?(?:www\.)?(twitter|x)\.com\/(#!\/)?[a-zA-Z0-9_]+$/
  let instareg = /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/
  let youreg = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/

  const Discord = (e) => {

    setDiscord(e.target.value)

  }
  const Telegram = (e) => {

    setTelegram(e.target.value)

  }

  const Twitter = (e) => {
    if (e.target.value == "" || e.target.value == undefined) {
      setTwitter(e.target.value)
      document.getElementById('twit').innerHTML = t('Please enter twitter url')
    }
    else if (!twireg.test(e.target.value)) {
      setTwitter(e.target.value)
      document.getElementById('twit').innerHTML = t('Invalid url')
    }
    else {
      setTwitter(e.target.value)
      document.getElementById('twit').innerHTML = ''
    }
  }
  const Instagram = (e) => {

    setInsta(e.target.value)

  }
  const Youtube = (e) => {

    setYoutube(e.target.value)

  }


  var numbers = /^[0-9]+$/;

  const Royalityfee1 = (e) => {
    if (e.target.value == "" || e.target.value == undefined) {
      setRoyalityfee(e.target.value)
      document.getElementById('royality').innerHTML = t('Please enter royalty fee')
    }
    else if (e.target.value >= 31) {
      setRoyalityfee(e.target.value)
      document.getElementById('royality').innerHTML = t('Maximum royalty fee is 30 %')
    }
    else {
      setRoyalityfee(e.target.value)
      document.getElementById('royality').innerHTML = ''
    }
  }

  const [verify, setVerify] = useState("")
  const [wholeverify, SetWholeVerify] = useState("")

  const Verify = () => {

    if (singledata && singledata.volume >= 5) {
      setVerify(singledata.twitterVerify == "" ? "Pending" : singledata.twitterVerify == "Rejected" ? "Pending" : singledata.twitterVerify == "Verified" ? "Verified" : "")

      SetWholeVerify(singledata.verification == "" ? "Pending" : singledata.verification == "Pending" ? "Pending" : singledata.verification == "Verified" ? "Verified" : "")

      setCollectionVerify(singledata.collectionNameVerify == "" ? "" : singledata.collectionNameVerify == "Verified" ? "Verified" : "")
      setLogoVerify(singledata.logoVerify == "" ? "" : singledata.logoVerify == "Verified" ? "Verified" : "")
    }
    else {
      toast.error(`Minimum 5 ${singledata.chain == "binance" ? "BNB" : singledata.chain == "ethereum" ? "ETH" : singledata.chain == "polygon" ? "MATIC" : ""} of Volume to verify`)

    }
  }


  const SavesChanges = async () => {
    setLoading(true)
    if (bannerimage == "" && bannerimage == "undefined" && singlecategory == "" && royalityfee == "" && twitter1 == "") {
      document.getElementById('uploadimage').innerHTML = t('Please choose image')
      document.getElementById('category').innerHTML = t('Please choose categories')
      document.getElementById('twit').innerHTML = t('Please enter twitter url')
      document.getElementById('royality').innerHTML = t('Please enter royalty fee')

      setLoading(false)
    }
    else if (bannerimage == "" || bannerimage == "undefined") {
      document.getElementById('uploadimage').innerHTML = t('Please choose image')
      setLoading(false)
    }
    else if (singlecategory == "" || singlecategory == undefined) {
      document.getElementById('category').innerHTML = t('Please choose categories')
      setLoading(false)
    }
    else if (royalityfee == "" || royalityfee == 0) {
      document.getElementById('royality').innerHTML = t('Please enter royalty fee')
      setLoading(false)
    }

    else if (royalityfee >= 31) {
      document.getElementById('royality').innerHTML = t('Maximum royalty fee is 30 %')
      setLoading(false)
    }
    else if (twitter1 == "" || twitter1 == undefined) {
      document.getElementById('twit').innerHTML = t('Please enter twitter url')
      setLoading(false)
    }
    else if (!twireg.test(twitter1)) {
      document.getElementById('twit').innerHTML = t('Invalid url')
      setLoading(false)
    }
    else {

      const formData = new FormData()
      formData.append('collectionName', singledata.collectionName)
      formData.append('categoryName', singlecategory)
      formData.append('logoImage', singledata.logoImage)
      formData.append('bannerImage', bannerimage)
      formData.append('description', description)
      formData.append('royaltyFee', royalityfee)
      formData.append('discord', discord1)
      formData.append('telegram', telegram)
      formData.append('twitter', twitter1)
      formData.append('instagram', insta)
      formData.append('youtube', youtube1)
      formData.append('url', singledata.url)
      formData.append('chain', singledata.chain)
      formData.append('_id', singledata._id)
      formData.append('imageFormat', imgtype)
      formData.append('verification', wholeverify)
      formData.append('twitterVerify', verify)

      const editcollection = await postformdataRequest("user/update-single-collection", formData)
      if (editcollection.status == true) {
        toast.success(editcollection.message)
        setLoading(false)
        setTimeout(() => {
          navigate("/mycollection")
        }, 1000)
      }
      else {
        toast.error(editcollection.message)
        setLoading(false)
      }
    }

  }

  const cancel = () => {
    navigate("/mycollection")
  }


  const renderVerificationContent = () => {
    if (
      singledata.collectionNameVerify === "" &&
      singledata.logoVerify === "" &&
      singledata.twitterVerify === "" &&
      twitter1 &&
      twireg.test(twitter1)
    ) {
      return (
        <div className="getVerified" style={{ cursor: "pointer" }} onClick={Verify} onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            Verify()
          }
        }}
          role="button" tabIndex={0}>
          <span>{verify === "" ? t("Get Verified") : verify === "Rejected" ? t("Get Verified") : t(verify)}</span>
        </div>
      );
    } else if (
      singledata.collectionNameVerify === "Verified" &&
      singledata.logoVerify === "Verified" &&
      singledata.twitterVerify === "Verified"
    ) {
      return (
        <div className="getVerified" style={{ cursor: "pointer" }}>
          <p>{t(singledata && singledata.verification)}</p>
        </div>
      );
    } else if (
      singledata && singledata.twitterVerify === "Rejected" &&
      twitter1 &&
      twireg.test(twitter1)
    ) {
      return (
        <div className="getVerified" style={{ cursor: "pointer" }} onClick={Verify} onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            Verify()
          }
        }}
          role="button" tabIndex={0}>
          <span>{verify === "" ? t("Get Verified") : verify === "Rejected" ? t("Get Verified") : t(verify)}</span>
        </div>
      );
    } else if (singledata && singledata.twitterVerify === "Pending") {
      return (
        <div className="getVerified" style={{ cursor: "pointer" }}>
          <span>{verify === "" ? t("Get Verified") : verify === "Rejected" ? t("Get Verified") : t(verify)}</span>
        </div>
      );
    } else if (
      twitter1 &&
      twireg.test(twitter1) &&
      Collectionverify !== "" &&
      Logoverify !== ""
    ) {
      return (
        <div className="getVerified" style={{ cursor: "pointer" }} onClick={Verify} onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            Verify()
          }
        }}
          role="button" tabIndex={0}>
          <span>{verify === "" ? t("Get Verified") : verify === "Rejected" ? t("Get Verified") : t(verify)}</span>
        </div>
      );
    } else if (
      singledata.collectionNameVerify === "" &&
      twitter1 &&
      twireg.test(twitter1)
    ) {
      return (
        <div className="getVerified" style={{ cursor: "pointer" }} onClick={Verify} onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            Verify()
          }
        }}
          role="button" tabIndex={0}>
          <span>{Collectionverify === "" ? t("Pending") : Collectionverify === "Verified" ? t("Verified") : ""}</span>
        </div>
      );
    } else if (
      singledata.logoVerify === "" &&
      twitter1 &&
      twireg.test(twitter1)
    ) {
      return (
        <div className="getVerified" style={{ cursor: "pointer" }} onClick={Verify} onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            Verify()
          }
        }}
          role="button" tabIndex={0}>
          <span>{Logoverify === "" ? t("Pending") : Logoverify === "Verified" ? t("Verified") : ""}</span>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <div className={loading ? "spinner" : "d-none"}>
        <ScaleLoader color={color} size={50} />
      </div>
      <div className="row">
        <div className="col-lg-2 mb-3">
          <button className="backIcon" onClick={mycollection}><i className="fas fa-angle-left"></i></button>
        </div>
        <div className="col-lg-3 mb-3">
          <h3 className="collectionSecHeading text-left">{t("Edit")} <br />{t("Collection")}</h3>
        </div>
        <div className="col-lg-6">
          <div className="createCollectionCard">
            <form>
              <div className="walletCnt mb-3">
                {singledata ?
                  <h3> {singledata.creator
                    ? singledata.creator.slice(0, 5) +
                    '...' +
                    singledata.creator.slice(38, 42)
                    : ''}</h3>

                  :
                  ""
                }
                <span className="walletLabel successLabel ml-auto">{singledata.creator ? t("Wallet Connected") : t("Connect Wallet")}</span>
              </div>
              <div className="form-row">
                <div className="col-12 mb-4 d-flex align-items-center flex-wrap">
                  <div className="imgContainer mr-4 mbVCh">
                    <span className="formLabel">{t("Logo Image")} </span>
                    <div className="d-flex align-items-center flex-wrap">
                      <div className="imgCnt">
                        {!singledata?.logoImage?.startsWith(process.env.S3_URL) && !singledata?.logoImage?.startsWith('ipfs') && singledata?.logoImage !== null ?
                          <img className="logoImg" src={singledata && singledata?.logoImage ? singledata?.logoImage : defaultImage} onError={handleImageError} />
                          :
                          <img className="logoImg" src={defaultImage} />
                        }
                      </div>
                    </div>
                  </div>
                  <div className="coverContainer mbVCh">
                    <span className="formLabel">{t("Cover Image")} <span className='text-danger'>*</span></span>
                    <div className="coverCnt d-flex flex-column">

                      {typeof bannerimage == "string" && bannerimage !== "undefined" && !bannerimage.startsWith(process.env.S3_URL) && !bannerimage.startsWith('ipfs') && bannerimage !== null ?

                        imgtype.startsWith('video') ?
                          <video
                            className='vitag'
                            src={bannerimage}
                            alt="Uploaded"

                            muted
                            autoPlay
                          />
                          :
                          (!bannerimage?.startsWith(process.env.S3_URL) && !bannerimage?.startsWith('ipfs') && bannerimage !== null ?
                            <img className="coverImg" src={bannerimage ? bannerimage : defaultImage} onError={handleBannerImageError} /> :
                            <img className="coverImg" src={defaultImage} />)

                        :
                        typeof bannerimage == "object" ?
                          imgtype.startsWith('video') ?
                            <video
                              className='vitag'
                              src={URL.createObjectURL(bannerimage)}
                              alt="Uploaded"

                              muted
                              autoPlay
                            />
                            :
                            < img className="coverImg" src={URL.createObjectURL(bannerimage)} />
                          :
                          <a href="#"><img src={Pencil} /></a>
                      }
                      {singledata && singledata.verification == "Verified" ?
                        ""
                        :
                        <div className=" ndblock editIcon ">
                          <div class="text-center">
                            <p>{t("PNG, GIF, WEBP, JPG, JPEG, MP4")}.</p>
                            <p className="fs-12">{t("Max 100mb. At least 1400*360 pixels")}.</p>
                            <input type="file" id="chooseBtn" accept=".png, .gif, .jpg, .jpeg,.webp, .mp4" onChange={(e) => imageupload(e)} />
                            <label for="chooseBtn" className="chooseFileBtn mt-2 mx-auto">
                              <span className='textClr'>{t("Choose File")}</span>
                            </label>

                          </div>
                        </div>
                      }
                    </div>
                    <span id='uploadimage' className='text-danger'></span>
                  </div>
                </div>

                <div className="col-12 mb-3">
                  <div className="d-flex align-items-center flex-wrap">
                    <span className="formLabel">{t("Collection Name")}</span>
                  </div>
                  <input type="text" placeholder={t("Name your collection")} className="form-control" value={singledata && singledata.collectionName} />
                </div>

                <div className="col-12 mb-3">
                  <div className="d-flex align-items-center flex-wrap">
                    <span className="formLabel">{t("Collection Symbol")}</span>
                  </div>
                  <input type="text" placeholder="Collection symbol" className="form-control" value={singledata && singledata.collectionSymbol} />
                </div>

                <div className="col-12 mb-3">
                  <span className="formLabel">{t("Description (Optional)")}</span>
                  {singledata && singledata.verification == "Verified" ?
                    < textarea className="form-control" placeholder={t("Enter Description")} name="description" defaultValue={description} disabled></textarea>
                    :
                    <textarea className="form-control" placeholder={t("Enter Description")} name="description" onChange={(e) => setDescription(e.target.value)} defaultValue={description}></textarea>
                  }
                </div>

                <div className="col-12 mb-3 urlFieldCnt">
                  <div className="d-flex align-items-center flex-wrap">
                    <span className="formLabel">{t("Collection URL")}</span>
                  </div>
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                    </div>
                    <input type="text" class="form-control" placeholder={t("Enter URL")} value={singledata && singledata.url} />
                  </div>

                  <hr />
                </div>

                <div className="col-12 mb-3">
                  <span className="formLabel">{t("Royalty Fee")}({t("In Percentage")}) <span className='text-danger'>*</span></span>
                  {singledata && singledata.royaltyFee == 0 ?
                    < NumericFormat className="form-control" placeholder={t("Enter Royalty Fee")} name="royalityfee" onChange={Royalityfee1} value={royalityfee == 0 ? "" : royalityfee} allowNegative={false} customInput={Input} decimalScale={0} />
                    :
                    <NumericFormat className="form-control" placeholder={t("Enter Royalty Fee")} name="royalityfee" value={royalityfee} allowNegative={false} customInput={Input} disabled />
                  }
                </div>
                <span id='royality' className='text-danger'></span>

                <div className="col-12 mb-3">
                  <div className="d-flex align-items-center flex-wrap">
                    <span className="formLabel mr-2">{t("Blockchain")}</span>
                    <UncontrolledDropdown className="ml-auto">
                      <DropdownToggle caret className="d-flex align-items-center">
                        <img src={chainIconMap[singledata.chain]} className="mr-2" />
                        {nativeCoinMap[singledata.chain]}
                      </DropdownToggle>
                    </UncontrolledDropdown>
                  </div>
                  <hr />
                </div>

                <div className="col-12 mb-3">
                  <div className="d-flex align-items-center flex-wrap">
                    <span className="formLabel mr-2">{t("Category")} <span className='text-danger'>*</span></span>
                    <UncontrolledDropdown className="ml-auto">
                      <DropdownToggle caret className="d-flex align-items-center">
                        {singlecategory ? t(singlecategory) : t("Select Category")}
                        {singledata && singledata.verification == "Verified" ?
                          ""
                          :
                          <img src={angleDown} className="ml-auto" />
                        }
                      </DropdownToggle>
                      {singledata && singledata.verification == "Verified" ?
                        ""
                        :
                        <DropdownMenu>
                          {getcategory && getcategory.map((data) => (
                            <div onClick={() => categories(data)} onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                categories(data)
                              }
                            }}
                              role="button" tabIndex={0}>
                              <DropdownItem tag="a" value={t(data.name)}>
                                {t(data.name)}
                              </DropdownItem>
                            </div>
                          ))}
                        </DropdownMenu>

                      }

                    </UncontrolledDropdown>
                  </div>
                  <div style={{ justifyContent: "end", display: "flex" }}>
                    <span id='category' className='text-danger'></span>
                  </div>
                  <hr />
                </div>

                <div className="col-12 mb-3">
                  <div className="d-flex align-items-center flex-wrap">
                    <span className="formLabel">{t("Social Media")} <span className='text-danger'>*</span></span>
                  </div>
                  <div className="d-flex urlFieldCnt">
                    <div className="socilaMediaIconCnt mr-2"><img src={discord} /></div>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                      </div>
                      {singledata && singledata.verification == "Verified" ?
                        <input type="text" class="form-control" placeholder={t("Enter Discord Url")} name="discord1" defaultValue={discord1} disabled />
                        :
                        <input type="text" class="form-control" placeholder={t("Enter Discord Url")} name="discord1" onKeyUp={Discord} defaultValue={discord1} />
                      }
                    </div>
                  </div>
                  <span id='dis' className='ml-5 text-danger'></span>

                  <div className="d-flex urlFieldCnt">
                    <div className="socilaMediaIconCnt mr-2"><img src={telegramPlane} /></div>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                      </div>
                      {singledata && singledata.verification == "Verified" ?
                        <input type="text" class="form-control" placeholder={t("Enter Telegram Url")} name="telegram" defaultValue={telegram} disabled />
                        :
                        <input type="text" class="form-control" placeholder={t("Enter Telegram Url")} name="telegram" onKeyUp={Telegram} defaultValue={telegram} />
                      }
                    </div>
                  </div>
                  <span id='tel' className='ml-5 text-danger'></span>

                  <div className="d-flex urlFieldCnt">
                    <div className="socilaMediaIconCnt mr-2"><img src={twitter} /></div>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                      </div>
                      {singledata && singledata.twitterVerify == "Verified" ?
                        <input type="text" class="form-control" placeholder={t("Enter Twitter Url")} name="twitter1" defaultValue={twitter1} disabled />
                        :
                        <input type="text" class="form-control" placeholder={t("Enter Twitter Url")} name="twitter1" onKeyUp={Twitter} defaultValue={twitter1} />
                      }
                    </div>
                  </div>
                  <span id='twit' className='ml-5 text-danger'></span>

                  <div className="d-flex urlFieldCnt">
                    <div className="socilaMediaIconCnt mr-2"><img src={instagram} /></div>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                      </div>
                      {singledata && singledata.verification == "Verified" ?
                        <input type="text" class="form-control" placeholder={t("Enter Instagram Url")} name="insta" defaultValue={insta} disabled />
                        :
                        <input type="text" class="form-control" placeholder={t("Enter Instagram Url")} name="insta" onKeyUp={Instagram} defaultValue={insta} />
                      }
                    </div>
                  </div>
                  <span id='insta' className='ml-5 text-danger'></span>

                  <div className="d-flex urlFieldCnt">
                    <div className="socilaMediaIconCnt mr-2"><img src={youtube} /></div>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                      </div>
                      {singledata && singledata.verification == "Verified" ?
                        <input type="text" class="form-control" placeholder={t("Enter Youtube Url")} name="youtube1" defaultValue={youtube1} disabled />
                        :
                        <input type="text" class="form-control" placeholder={t("Enter Youtube Url")} name="youtube1" onKeyUp={Youtube} defaultValue={youtube1} />
                      }
                    </div>
                  </div>
                  <span id='tube' className='ml-5 text-danger'></span>

                  <hr />
                </div>

                <div className="col-12 mb-3">
                  <div className="d-flex align-items-center flex-wrap">
                    <span className="formLabel">{t("Verification")}</span>
                    <div className="dark-primary ml-auto">
                      {t("Requirements")} <img src={infoIcon} id="popoverLegacy" />
                    </div>
                    <UncontrolledPopover trigger="legacy" placement="bottom" target="popoverLegacy" className="verifyPopOver">
                      <PopoverBody>
                        <div>
                          <label class="custCheck">{t("Logo Image")}.
                            {singledata.logoVerify == "Verified" ?
                              <input type="checkbox" checked />
                              :
                              ""
                            }
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div>
                          <label class="custCheck">{t("Title")}.
                            {singledata.collectionNameVerify == "Verified" ?
                              <input type="checkbox" checked />
                              :
                              ""
                            }
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div>
                          {singledata && singledata.emailVerify == "Verified" ?
                            <>
                              <label class="custCheck">{t("Verified email")}.
                                <input type="checkbox" checked />
                                <span class="checkmark"></span>
                              </label>
                            </>
                            :
                            <>
                              <label class="custCheck">{t("Verified email")}.
                                <input type="checkbox" disabled />
                                <span class="checkmark1"></span>
                              </label>
                            </>
                          }
                        </div>
                        <div>
                          {singledata && singledata.twitterVerify == "Verified" ?
                            <>
                              <label class="custCheck">{t("Connected Twitter account")}..
                                <input type="checkbox" checked />
                                <span class="checkmark"></span>
                              </label>
                            </>
                            :
                            singledata && singledata.twitterVerify == "Rejected" ?
                              <>
                                <img src={reject} width="25px" height="25px" />
                                <label>{t("Connected Twitter account")}.</label>
                              </>
                              :

                              <div>
                                <label class="custCheck">{t("Connected Twitter account")}..
                                  <input type="checkbox" disabled />
                                  <span class="checkmark1"></span>
                                </label>
                              </div>
                          }


                        </div>
                        <div>
                          {singledata && singledata.volume >= 5 ?
                            <label class="custCheck">{t("Minimum 5")} {nativeCoinMap[singledata.chain]} of Volume.
                              <input type="checkbox" checked />
                              <span class="checkmark"></span>
                            </label>
                            :
                            <label class="custCheck">{t("Minimum 5")} {nativeCoinMap[singledata.chain]} of Volume.
                              <input type="checkbox" disabled />
                              <span class="checkmark1"></span>
                            </label>
                          }
                        </div>
                      </PopoverBody>
                    </UncontrolledPopover>
                  </div>
                  <div style={{ justifyContent: "end", display: "flex" }} className='mt-2'>

                    {renderVerificationContent()}
                  </div>
                </div>

                <div className="col-12 d-flex align-items-center mb-3">
                  <button type="button" className="btn btn-block blackBtn mx-2 my-2" onClick={cancel}>{t("Cancel")}</button>
                  {singledata && singledata.verification == "Verified" ?
                    <button type="button" className="btn btn-block gradientBtn mx-2 my-2 text-nowrap" disabled>{t("Save Changes")}</button>
                    :
                    bannerimage && singlecategory && royalityfee > 0 && twitter1 ?
                      <button type="button" className="btn btn-block gradientBtn mx-2 my-2 text-nowrap" onClick={SavesChanges}>{t("Save Changes")}</button>
                      :
                      <button type="button" className="btn btn-block gradientBtn mx-2 my-2 text-nowrap" disabled>{t("Save Changes")}</button>

                  }
                </div>
              </div>
            </form>
          </div>
        </div >
      </div >

    </>
  );
};

export default ChooseCollection;