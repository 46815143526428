import { create } from 'zustand'

const useEditListingModalStore = create(set => ({
	isOpen: false,
	asset: null,
	actions: {
		toggleEditListingModal: () =>
			set(state => ({
				...state,
				isOpen: !state.isOpen
			})),
		setAsset: asset =>
			set(state => ({
				...state,
				asset
			}))
	}
}))

export const useEditListingModalState = () =>
	useEditListingModalStore(({ isOpen, asset }) => ({
		isOpen,
		asset
	}))

export const useEditListingModalActions = () =>
	useEditListingModalStore(state => state.actions)
