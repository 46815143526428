import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { useAccount } from 'wagmi'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import { Spinner } from '../../../components/loader/spinner/Spinner'
import { useFetchOfferList } from '../apis/fetchAssetDetails'
import { formatAddress } from '../../../utils/formatAddress'
import { useAcceptOfferModalActions } from '../../../components/modals/accept-offer/store'

import cndds from '../../../assets/images/cndds.png'
import cnddsLight from '../../../assets/images/cnddsLight.svg'

import { useState } from 'react'
import { axios } from '../../../lib/axios'
import { useToast } from '../../../utils/toast/customSuccess'

import walletLoaderCnt from "../../../assets/images/walletLoader.png"
import walletLightLoaderCnt from '../../../assets/images/wallet-light-loader.png'
import walletLoader from "../../../assets/images/loaderCircle.png"
import redClose from '../../../assets/images/redClose.svg';
import { queryClient } from '../../../lib/react-query'
import { formatAmount } from '../../../utils/formatAmount'
import DataService from '../../../lib/DataService'
export function OfferList({ asset }) {
	const { t } = useTranslation()

	const params = useParams()
	const { address: account } = useAccount()

	const { toggleAcceptOfferModal, setAsset } = useAcceptOfferModalActions()

	const { data, isLoading } = useFetchOfferList(
		params.collectionAddress,
		params.nftId,
	)
	const { getRequest, postRequest } = DataService()

	const owner = !!account && asset.owners.find((o) => o.address === account.toLowerCase())

	const [canceloffermade, setCancelOfferMade] = useState("")
	const [loaders, setloaders] = useState(false)
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);
	const toast = useToast()


	const canceloffer = (data) => {
		setCancelOfferMade(data)
		toggle()
	}

	const offer = async () => {
		setloaders(true)

		if (account) {
			let data = {
				_id: canceloffermade.offerId,
				type: canceloffermade.type,
				contractAddress: params.collectionAddress,
				address: account
			}

			const fetch = await postRequest(`user/cancel_offerMade`, data)
			if (fetch.status == true) {
				setTimeout(() => {
					setModal(!modal)
					toast.success(fetch.message)
					setloaders(false)
					queryClient.invalidateQueries(['makeOffer'])
				}, 1000)
			} else {
				setModal(!modal)
				setloaders(false)
				toast.error(fetch.message)
			}
		}
		else {
			toast.error("Please connect wallet")
		}
	}

	const Back = () => {
		setModal(!modal)
	}

	if (isLoading) {
		return (
			<div class='noResf text-center'>
				<Spinner />
			</div>
		)
	}

	if (!data?.data?.length) {
		return (
			<div className='h464pxCenter'>
				<div class='noResf text-center'>
					<img class='img-fluid' src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : cndds} alt='' />
					<h1>{t('Nothing to display.')}</h1>
				</div>
			</div>
		)
	}

	const handleCancelOffer = (e, item) => {
		e.stopPropagation();
		canceloffer(item);
	};
	return (
		<>
			<div className='table-responsive scroller'>
				<table className='table table-borderless table-hover mb-0 hmecollection nftTables_offers'>
					<thead className='trd_head'>
						<tr>
							<th>{t('Price')}</th>
							<th>{t('USD Price')}</th>
							<th>{t('Quantity')}</th>
							<th>{t('Floor Difference')}</th>
							<th>{t('Expiration')}</th>
							<th>{t('From')}</th>
							<th></th>
						</tr>
					</thead>
					<tbody className='trdtable_body'>
						{data?.data?.map((item) => (
							<tr>
								<td>
									{formatAmount(parseFloat(item.price))} {item.currency}
								</td>
								<td>${parseFloat(item.priceInUsd.toFixed(2))}</td>
								<td>{item.quantity}</td>
								<td>
									{item?.floorDifference?.floordifference != null ? parseFloat(item?.floorDifference?.floordifference.toFixed(2)) : 0}%{' '}
									{item?.floorDifference?.up
										? 'above'
										: 'below'}
								</td>
								<td>{moment(item.saleExpiry).fromNow()}</td>
								<td className='yellowTxt hover'>
									<Link
										className='yellowTxt'
										to={`/profile/${item.fromAddress}`}
									>
										{item.from !== 'Unnamed'
											? item.from
											: formatAddress(item.fromAddress)}
									</Link>
								</td>
								{!!account && item.type === 'make-collection-offer' ?
									account?.toLowerCase() === asset.collection.creator ?
										<td>
											<button
												className='btn btnAccept'
												onClick={() => {
													setAsset({ ...asset, bestOffer: item })
													toggleAcceptOfferModal()
												}}
											>
												<span>{t('Accept')}</span>
											</button>
										</td> : null :
									item.address === account?.toLowerCase() ? (
										<td>
											<button
												className='btn btnAccept'
												onClick={(e) => handleCancelOffer(e, item)}
											>
												<span>{t('Cancel')}</span>
											</button>
										</td>
									) : !!owner && item.address !== account?.toLowerCase() ?
										<td>
											<button
												className='btn btnAccept'
												onClick={() => {
													setAsset({ ...asset, bestOffer: item })
													toggleAcceptOfferModal()
												}}
											>
												<span>{t('Accept')}</span>
											</button>
										</td> : null
								}

							</tr>
						))}
					</tbody>
				</table>
			</div>

			<Modal isOpen={modal} toggle={toggle} centered="true" className="mymodal" backdropClassName="selCurBp" backdrop='static' keyboard={false}>
				{loaders == true ? (
					<>
						<ModalHeader >
							<h5 className='modal-title'>{t("Cancel Offer")}</h5>
						</ModalHeader>
						<ModalBody>
							<p className="dark-text-secondary text-center">{t("To continue send transaction with your wallet")}.</p>
							<div className="d-flex justify-content-center align-items-center loaderCont">
								{localStorage.getItem('light_theme') == 'true' ?
									<img src={walletLightLoaderCnt} /> :
									<img src={walletLoaderCnt} />
								}
								<div className="loaderAnimCnt">
									<img src={walletLoader} className="loaderAnimation" />
								</div>
							</div>
						</ModalBody>
					</>
				) : (
					<>
						<ModalHeader >
							<div className="d-flex flex-column align-items-center px-4">
								<img src={redClose} className="mb-3" alt="greenCheck" />
								{t("Are you sure you want to cancel your offer?")}
							</div>
						</ModalHeader>
						<ModalBody className="pt-0">
							<div className='mt-4'>
								<button className='btn btn-block darkBtn mb-2 text-danger' onClick={offer}>{t("Cancel Offer")}</button>
								<button className='btn btn-block darkBtn mb-2' onClick={Back}>{t("Get Back")}</button>
							</div>
						</ModalBody>
					</>
				)}
			</Modal>
		</>
	)
}
