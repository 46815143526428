import React, { useContext, useEffect, useState } from 'react';
import defaultImage from '../../../assets/images/homeExplorer/placeholder.png'
import checkTickIcon from '../../../assets/images/toast/checkIcon.svg';
import removeIcon from '../../../assets/images/toast/checkIcon.svg';
import yellowTick20px from '../../../assets/images/collection/yellow-tick_20px.svg';
import { ThemeContext } from '../../context/context';
import {
    useAccount,
} from 'wagmi'
import noItemfoundImage from '../../../assets/images/collection/noItemfoundImage.svg';
import cnddsLight from '../../../assets/images/cnddsLight.svg'
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ResponsivePagination from 'react-responsive-pagination';
import { handleImageError } from '../../../hooks/useHandleImg';
import { ExploreLoader } from '../../../features/collection/components/loader/ExploreLoader';
import { nativeCoinMap, chainIconMap } from '../../../utils/getFilterData';
import DataService from '../../../lib/DataService';
const landPerRow = 6

const GridViewItem = (props) => {

    const { t } = useTranslation()

    const navigate = useNavigate()

    const { address, isConnected } = useAccount()
    const { id } = useParams()

    const gridview = (props.filterview) ? 'g-col-5' : 'g-col-6';
    const [loading, setLoading] = useState(false)
    let [color, setColor] = useState('#ffffff')
    const { postformdataRequest, getRequest, postRequest } = DataService()

    const { createfilter, setCreateFilter, createsortFieldfilter, setCreatesortFieldFilter, createsortOrderfilter, setCreatesortOrderFilter, createdsearch, setCreatedSearch, createstatus, setCreateStatus, createSortStatusField, setCreateSortStatusField, createSortStatus, setCreateSortStatus, checkcreateChain, setcreateCheckChain, checkcreatecollection, setCheckcreateCollection, checkcreatetype, setCheckcreateType } = useContext(ThemeContext)

    const [next, setNext] = useState(landPerRow)
    const [page, setPage] = useState(1)
    const [getcreatednft, setGetCreatedNft] = useState('')
    const [videoError, setVideoError] = useState(false)
    const [totalnftcount, setTotalNftCount] = useState(0)
    const [count, setCount] = useState('')

    function handlePageChange(page) {
        setPage(page);
    }

    useEffect(() => {

        GetCreatedNFT()
    }, [address, page, createfilter, createsortFieldfilter, createsortOrderfilter, createdsearch, createstatus, createSortStatusField, createSortStatus, checkcreateChain, checkcreatecollection, checkcreatetype, id])

    const [exploreLoading, setExploreLoading] = useState(false)

    const GetCreatedNFT = async () => {
        setExploreLoading(true)
        const fetch = await getRequest(
            `user/getcreatedNFTS?ownerAddress=${id ? id : address}&page=${createsortFieldfilter !== "_id" ? 1 : createsortOrderfilter !== "desc" ? 1 : createdsearch !== "" ? 1 : checkcreatecollection > 0 ? 1 : checkcreateChain > 0 ? 1 : createstatus > 0 ? 1 : page}&limit=15&sortField=${createsortFieldfilter}&sortOrder=${createsortOrderfilter}&filter=${createdsearch}&collectionName=${checkcreatecollection}&chain=${checkcreateChain}&sortStatusField=${createstatus}`
        )

        if (fetch.status == true) {
            let cnVerify = fetch.data;

            const getcollection = await postRequest("user/getCreatedCollectionNFT", { "address": id ? id : address })
            if (getcollection.status == true) {
                let getcollectionverify = getcollection.data;
                for (let i = 0; i < cnVerify.length; i++) {
                    for (let j = 0; j < getcollectionverify.length; j++) {
                        if (getcollectionverify[j].collectionName === cnVerify[i].collectionName) {
                            let data = getcollectionverify[j].verification;
                            cnVerify[i]['verification'] = data;
                        }
                    }
                }
                setGetCreatedNft(cnVerify)
            }

            setTotalNftCount(Math.ceil(fetch.count / 15))
            setCount(fetch.count)
            setExploreLoading(false)
            setPage(createsortFieldfilter !== "_id" ? 1 : createsortOrderfilter !== "desc" ? 1 : createdsearch !== "" ? 1 : checkcreatecollection > 0 ? 1 : checkcreateChain > 0 ? 1 : createstatus > 0 ? 1 : page)
        }
        else {
            setGetCreatedNft('')
            setTotalNftCount('')
            setPage(1)
            setCount('')
            setExploreLoading(false)
            setPage(1)

        }
    }

    const clearAll = () => {
        setCreateFilter("Recently Received")
        setCreatesortFieldFilter("_id")
        setCreatesortOrderFilter("desc")
        setCreatedSearch("")
        setCreateStatus([""])
        setCreateSortStatusField("")
        setCreateSortStatus("")
        setcreateCheckChain([])
        setCheckcreateCollection([])
    }


    const Back = () => {
        setCreateFilter("Recently Received")
        setCreatesortFieldFilter("_id")
        setCreatesortOrderFilter("desc")
        setCreatedSearch("")
        setCreateStatus([""])
        setCreateSortStatusField("")
        setCreateSortStatus("")
        setcreateCheckChain([])
        setCheckcreateCollection([])
    }

    const closechain = (item) => {
        let a = checkcreateChain.includes(item)

        if (a == true) {

            const index = checkcreateChain.indexOf(item);
            if (index > -1) {
                checkcreateChain.splice(index, 1);
                const nerArr = [...checkcreateChain]
                setcreateCheckChain(nerArr);
            }
        }
    }


    const closecollection = (item) => {
        let a = checkcreatecollection.includes(item)

        if (a == true) {

            const index = checkcreatecollection.indexOf(item);
            if (index > -1) {
                checkcreatecollection.splice(index, 1);
                const nerArr = [...checkcreatecollection]
                setCheckcreateCollection(nerArr);
            }

        }
    }


    const closesearch = () => {
        setCreatedSearch("")

    }
    const closefilter = () => {
        setCreateFilter("Recently Received")
        setCreatesortFieldFilter("_id")
        setCreatesortOrderFilter("desc")

    }
    const closestatus = (data) => {
        setCreateSortStatusField("")
        setCreateSortStatus("")
        setCreatesortFieldFilter("")
        setCreatesortOrderFilter("")
        let a = createstatus.includes(data)
        if (a == true) {
            const index = createstatus.indexOf(data);
            if (index > -1) {
                createstatus.splice(index, 1);
                const nerArr = [...createstatus]
                setCreateStatus(nerArr);
                if (nerArr.length == 0) {
                    setCreateStatus([""]);
                }
            }

        }
    }
    const handleVideoError = () => {
        setVideoError(true);
    };
    const listNavigate = (e, item) => {
        e.stopPropagation()
        navigate(`/listforsale/${item.chain}/${item.contractAddress}/${item.nftId}`)
    }

    const litstedTagLoop3 = createstatus && createstatus.map((item) => {
        return (
            <>
                {item == "" ?
                    ""
                    :
                    <>
                        <div className='tagLabel'>
                            <span style={{ textTransform: "capitalize" }} className='ml-2'>{t(item)}</span>
                            <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={() => closestatus(item)} onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    closestatus(item)
                                }
                            }}
                                role="button" tabIndex={0}></i>
                        </div>
                    </>
                }
            </>
        );
    });



    const litstedTagLoop1 = checkcreatecollection && checkcreatecollection.map((item) => {
        return (
            <>
                {item == "" ?
                    ""
                    :
                    <>
                        <div className='tagLabel'>
                            <span>{item}</span>
                            <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={() => closecollection(item)} onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    closecollection(item)
                                }
                            }}
                                role="button" tabIndex={0}></i>
                        </div>
                    </>
                }
            </>
        );
    });


    const litstedTagLoop2 = checkcreateChain && checkcreateChain.map((item) => {
        return (
            <>
                {item == "" ?
                    ""
                    :
                    <>
                        <div className='tagLabel'>
                            <span>{item}</span>
                            <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={() => closechain(item)} onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    closechain(item)
                                }
                            }}
                                role="button" tabIndex={0}></i>
                        </div>
                    </>
                }
            </>
        );
    });


    const ItemCard = getcreatednft && getcreatednft.map((item) => {
        return (
            <>
                <div className='grid-col' style={{ width: '100%', height: "100%", objectFit: "cover" }}>
                    <div className={`gridItemCard ${item.itemadded ? 'active' : ''}`}>
                        <div className='gItemImg'>
                            {typeof item?.asset == "string" && item?.asset !== undefined && !item?.asset?.startsWith(process.env.S3_URL) && !item?.asset?.startsWith('ipfs') && item?.asset !== null ?

                                item?.imageFormat?.startsWith('video') ?
                                    videoError ? <img src={defaultImage} alt="Itemimg1" className='img-fluid w-100 itemImage' />
                                        :

                                        <video autoPlay muted playsInline className='img-fluid w-100 itemImage' style={{
                                            width: '100px',
                                            height: '150px',
                                            objectFit: 'cover'
                                        }}>
                                            <source src={item?.asset ? item.asset : defaultImage} type="video/mp4" onError={handleVideoError} />
                                        </video>

                                    :
                                    item?.asset !== 'undefined' ?
                                        <img src={item?.asset ? item?.asset : defaultImage} alt="Itemimg1" className='img-fluid w-100 itemImage' onError={handleImageError} />
                                        :
                                        <img src={defaultImage} alt="Itemimg1" className='img-fluid w-100 itemImage' />
                                :
                                <img src={defaultImage} alt="Itemimg1" className='img-fluid w-100 itemImage' />

                            }
                            {item.contractStandard == "ERC1155" ?
                                <div className='idTag'>
                                    #{item.supply}
                                </div>
                                :
                                ""
                            }
                            <div className='hoverImgCnt' onClick={() => navigate(`/assets/${item.chain}/${item.contractAddress}/${item.nftId}`)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        navigate(`/assets/${item.chain}/${item.contractAddress}/${item.nftId}`)
                                    }
                                }}
                                role="button" tabIndex={0}>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className="d-flex align-items-center">
                                        <img src={chainIconMap[item.chain]} alt="ETHIcon" className='cointypeImg' />
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between align-items-center'>

                                </div>
                            </div>
                        </div>
                        <div className='botcntCard'>
                            <div className='bottomSmImg'>
                                <img src={item.asset} height={73} alt="ItemimgSm1" />
                            </div>
                            <div className='botcntTxt'>
                                <div className='itemLabel'>{item.collectionName} <img src={item.verification == "Verified" ? yellowTick20px : ""} height="12" /></div>
                                <div className='itemName'>{item.nftName !== "" ? item.nftName : item.nftId ? `#${item.nftId}` : '---'}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    })


    return (
        <>


            <div className='py-3'>
                <div className='row mb-4'>
                    <div className='col-lg-6'><span class="cmntxt">{count > 0 ? count : ""} {count > 0 ? t("Items") : ""}</span></div>

                </div>
                <div className='fiterSelectlabel mb-4'>
                    {createdsearch ?
                        <div className='tagLabel'>
                            <span>{createdsearch}</span>
                            <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={closesearch} onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    closesearch()
                                }
                            }}
                                role="button" tabIndex={0}></i>
                        </div>
                        :
                        ""}


                    {createstatus.includes("All") ? "" : litstedTagLoop3}{litstedTagLoop1}{litstedTagLoop2}
                    {
                        Number(createstatus.filter(item => item.trim() !== "").length) + Number(checkcreateChain.length) + Number(checkcreatecollection.length) + Number(createdsearch.trim() === "" ? 0 : createdsearch.trim().split(/\s+/).length) > 1 ?

                            <button className='clearAllBtn' onClick={clearAll}>{t("Clear All")}</button>
                            :
                            ""
                    }

                </div>


                {exploreLoading == true ? (
                    <ExploreLoader />

                ) :
                    getcreatednft && getcreatednft.length > 0 ?
                        <div className={`gridrow ${gridview}`}>
                            {ItemCard}
                        </div>
                        :
                        <div className='noItemFound'>
                            {loading == false ?
                                <>
                                    <div className='text-center'>
                                        <img src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : noItemfoundImage} alt="noItemfoundImage" className='img-fluid' />
                                    </div>

                                    {
                                        createdsearch || (createfilter == "Recently Received" ? "" :
                                            createfilter) || checkcreateChain.length > 0 || checkcreatecollection.length > 0 ||
                                            !createstatus.includes("") ?
                                            <>
                                                <div className='nofounttxt mb-4'>
                                                    {t('No items were found. Try to broaden your search.')}
                                                </div>
                                                < div >
                                                    <button className='applyBtn' onClick={Back}>
                                                        {t("Back to all items")}
                                                    </button>
                                                </div>
                                            </>
                                            :
                                            <div className='nofounttxt mb-4'>
                                                {t('Nothing to display.')}
                                            </div>
                                    }
                                </>
                                :
                                ""
                            }
                        </div>
                }


                <div className='row justify-content-center'>
                    <div className='col-lg-4 col-md-6 col-sm-8'>
                        <div class="bri_pagenation2">
                            {getcreatednft && getcreatednft.length > 0 ?
                                <div class=''>
                                    <nav aria-label="Page ">
                                        <ResponsivePagination
                                            total={totalnftcount}
                                            current={page}
                                            onPageChange={page => handlePageChange(page)}
                                            previousLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.125 5.75L5.71875 1.125C5.84375 0.96875 6.09375 0.96875 6.25 1.125L6.84375 1.75C7 1.875 7 2.125 6.84375 2.28125L3.15625 6L6.84375 9.75C7 9.90625 7 10.125 6.84375 10.2812L6.25 10.9062C6.09375 11.0625 5.84375 11.0625 5.71875 10.9062L1.125 6.28125C0.96875 6.125 0.96875 5.90625 1.125 5.75Z" fill="#F7F7F7"></path></svg>}
                                            nextLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.84375 6.28125L2.28125 10.9062C2.125 11.0625 1.875 11.0625 1.75 10.9062L1.125 10.2812C0.96875 10.125 0.96875 9.90625 1.125 9.75L4.8125 6L1.125 2.28125C0.96875 2.125 0.96875 1.875 1.125 1.75L1.75 1.125C1.875 0.96875 2.125 0.96875 2.28125 1.125L6.84375 5.75C7 5.90625 7 6.125 6.84375 6.28125Z" fill="#F7F7F7"></path></svg>}
                                            previousClassName="page-link-1"
                                            nextClassName="page-link-1"
                                        />
                                    </nav>
                                </div>
                                :
                                ""
                            }
                        </div>
                    </div>
                </div>
                <useToast toastImgIcon={checkTickIcon} toastmassage={t("Added to Cart")} />
                <useToast toastImgIcon={removeIcon} toastmassage={t("Removed from Cart")} />


            </div >
        </>

    )
}

export default GridViewItem;