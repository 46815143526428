import React, { useState, useEffect, useCallback } from 'react'
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Modal,
	ModalBody,
	ModalHeader,
} from 'reactstrap'
import { NumericFormat } from 'react-number-format'
import { useAccount } from 'wagmi'
import { debounce } from 'lodash'
import { format } from 'date-fns'

import { useEditListingModalActions, useEditListingModalState } from './store'
import { CloseIcon } from '../makeOffer/CloseIcon'
import moment from 'moment'
import { handleImageError } from '../../../hooks/useHandleImg'
import { useEditListing } from './listing'
import { useToast } from '../../../utils/toast/customSuccess'

import '../../../assets/scss/modalStyle.scss'

import yellowtick20 from '../../../assets/images/collection/yellow-tick_20px.svg'
import defaultImage from '../../../assets/images/homeExplorer/placeholder.png'
import { nativeCoinMap, nativeCoinMap1 } from '../../../utils/getFilterData'
import { useTranslation } from 'react-i18next'

const intervals = [
	{ label: '12 hours', duration: 720, format: '12H' },
	{ label: '1 day', duration: 1440, format: '1D' },
	{ label: '3 days', duration: 4320, format: '3D' },
	{ label: '7 days', duration: 10080, format: '7D' },
	{ label: '1 month', duration: 43200, format: '1M' }
]



export const EditListingModal = props => {

	const toast = useToast()
	const { address: account } = useAccount()
	const [videoError, setVideoError] = useState(false)
	const { t } = useTranslation()

	const { isOpen, asset } = useEditListingModalState()
	const { toggleEditListingModal } = useEditListingModalActions()

	const [bidAmount, setBidAmount] = useState()
	console.log('bidAmount: ', bidAmount);
	const [selectedInterval, setSelectedInterval] = useState(intervals[4])

	const [isChecked, setIsChecked] = useState(true)

	const editListingMutation = useEditListing()

	const handleCheckboxChange = event => {
		setIsChecked(event.target.checked)
	}

	const [dropdownOpen, setDropdownOpen] = useState(false)
	const [date, setDate] = useState('')
	const [time, setTime] = useState('')

	const toggleDropdown = () => setDropdownOpen(prevState => !prevState)

	const handleIntervalChange = interval => {
		setSelectedInterval(interval)
	}

	useEffect(() => {
		const currentDate = new Date()
		const nextDate = new Date(
			currentDate.getTime() + selectedInterval.duration * 60000
		)

		const formatDate = format(nextDate, 'dd-MM-yyyy hh:mm a')
		const dateTimeArray = formatDate.split(' ')
		setDate(dateTimeArray[0])
		setTime(dateTimeArray[1] + ' ' + dateTimeArray[2])
	}, [selectedInterval])

	const debounceValidateBidAmount = useCallback(
		debounce(floatValue => floatValue, 500),
		[asset]
	)

	const handleBidAmountChange = values => {
		const { floatValue } = values
		setBidAmount(floatValue)
		debounceValidateBidAmount(floatValue)
	}

	if (!asset) {
		return null
	}


	const isVideo = asset?.details?.asset?.includes('.mp4')

	const CheckTick = () => {
		return (
			<>
				<svg
					width='12'
					height='9'
					viewBox='0 0 12 9'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M10.1953 0.46875C10.3125 0.351562 10.5 0.351562 10.5938 0.46875L11.2734 1.125C11.3672 1.24219 11.3672 1.42969 11.2734 1.52344L4.24219 8.55469C4.125 8.67188 3.96094 8.67188 3.84375 8.55469L0.703125 5.4375C0.609375 5.32031 0.609375 5.13281 0.703125 5.03906L1.38281 4.35938C1.47656 4.26562 1.66406 4.26562 1.78125 4.35938L4.03125 6.63281L10.1953 0.46875Z'
						fill='#FFA900'
					/>
				</svg>
			</>
		)
	}

	const intervalItems = intervals.map(interval => (
		<DropdownItem
			key={interval.label}
			onClick={() => handleIntervalChange(interval)}
		>
			<div>{interval.label} </div>
			<div>{selectedInterval.label === interval.label && <CheckTick />}</div>
		</DropdownItem>
	))

	const handleEditList = async () => {
		const data = {
			orderId: asset?.details?.orderId,
			price: bidAmount,
			address: account
		}

		const res = await editListingMutation.mutateAsync(isChecked ? data : { ...data, duration: selectedInterval?.format })
		if (res.status) {
			toggleEditListingModal()
			setBidAmount()
			toast.success(res.message)
		} else {
			toggleEditListingModal()
			setBidAmount()
			toast.error(res.message)
		}
	}



	const allowedCurrencies = asset?.allowedCurrencies.reduce((acc, cur) => {
		if (!acc.hasOwnProperty(cur.currencySymbol)) {
			acc[cur.currencySymbol] = cur
		}
		return acc
	}, {})


	const finalAmount = bidAmount ?
		bidAmount : asset?.details.price

	const usdPrice =
		finalAmount *
		allowedCurrencies[nativeCoinMap1[asset?.details?.currency]]?.usdPrice

	const handleVideoError = () => {
		setVideoError(true);
	};
	return (
		<>
			<Modal
				isOpen={isOpen}
				centered='true'
				className='mymodal'
				backdropClassName='selCurBp'
				keyboard={false}
			>
				<ModalHeader
					toggle={toggleEditListingModal}
					close={
						<CloseIcon
							onClose={() => {
								toggleEditListingModal()
								setBidAmount()
							}} />
					}
				>
					<h5 className='modal-title'>{t("Edit Listing")}</h5>
				</ModalHeader>
				<ModalBody>
					<div className='d-flex justify-content-between borderBox'>
						<span className='dark-text-secondary fs-14 fw-400'>
							{t("Floor Price")}
						</span>
						<span className='dark-text fs-16 fw-400'>{asset?.details?.floorPrice ? asset?.details?.floorPrice : '-'}</span>
					</div>
					<div>
						<div className='cartItem'>
							<div className='itemImg'>
								{!isVideo ? (
									typeof asset?.details?.asset == "string" && asset?.details?.asset !== undefined && !asset?.details?.asset?.startsWith(process.env.S3_URL) &&
										!asset?.details?.asset?.startsWith("ipfs") && asset?.details?.asset !== null ?
										<img
											className='object-cover'
											alt='asset'
											src={asset?.details?.asset ? asset?.details?.asset : defaultImage}
											onError={handleImageError}
										/> : <img
											className='object-cover'
											alt='asset'
											src={defaultImage}
										/>
								) : (
									videoError ? <img
										className='object-cover'
										alt='asset'
										src={defaultImage}
									/> :


										<video loop autoPlay muted playsInline preload='metadata' controlslist='nodownload' id='video-asset' style={{
											objectFit: 'contain',
											height: '84px',
											width: '84px'
										}}>
											<source src={asset?.details?.asset ? asset?.details?.asset : defaultImage} type="video/mp4" onError={handleVideoError} />
										</video>

								)}
							</div>
							<div className='flex-grow-1 p-3'>
								<div className='d-flex justify-content-between mb-2'>
									<div className='itemName'>
										{asset?.collection.name}{' '}
										{asset?.collection.isVerified && (
											<img
												src={yellowtick20}
												alt='yellowtick20'
												className='ml-1'
											/>
										)}
									</div>
									<span className='fs-14 fw-400 dark-text-secondary'>{usdPrice
										? `$ ${parseFloat(
											usdPrice.toFixed(8)
										)}`
										: '$--'}</span>
								</div>
								<div className='d-flex justify-content-between mb-n1'>
									<div className='itemId'>{asset?.details.name}</div>
									<span className='itemBal'>{`${asset?.details.price} ${asset?.details.currency}`}</span>
								</div>
							</div>
						</div>
					</div>
					<hr className='hrCls' />
					<div className='position-relative zindex1'>
						<div className='d-flex justify-content-between mb-1'>
							<span className='fs-14 fw-600 dark-text'>{t("Set New Price")}</span>
						</div>
						<div className='dark-text-secondary fs-12 fw-400 mb12px'>
							{t("If you want to increase the price, you will be prompted to cancels all of your existing listings first. This will cost gas.")}
						</div>
						<div className='form-group formInputs'>
							<InputGroup>
								<NumericFormat
									className='form-control mx-auto'
									placeholder="0.00"
									value={bidAmount}
									allowNegative={false}
									customInput={Input}
									onValueChange={handleBidAmountChange}
								/>
								<InputGroupAddon addonType='append'>
									<InputGroupText className='pr-0'>
										<span className='fs-16 fw-400'>
											{asset?.details.currency}
										</span>
									</InputGroupText>
								</InputGroupAddon>
							</InputGroup>
						</div>

						<div className='innermodalcard mb-3'>
							<div className='d-flex align-items-center'>
								<div className='flex-fill'>
									<div className='fs-14 fw-600 dark-text'>
										{t("Use previous expiration date")}
									</div>
									<div className='fs-12 fw-400 dark-text-secondary'>
										{moment
											.unix(asset?.details?.saleExpiry)
											.format('MM-DD-YYYY, hh:mm A')}
									</div>
								</div>
								<div>
									<div class='custom-control custom-switch customSwitch ml-3 mr-2'>
										<input
											type='checkbox'
											class='custom-control-input'
											id='customSwitch1'
											checked={isChecked}
											onChange={handleCheckboxChange}
											disabled={editListingMutation.isLoading}
										/>
										<label
											class='custom-control-label'
											for='customSwitch1'
										></label>
									</div>
								</div>
							</div>
						</div>

						{!isChecked ? (
							<>
								<div className='d-flex justify-content-between mb-1'>
									<span className='fs-14 fw-600 dark-text'>{t("Duration")}</span>
								</div>
								<div className='row form-row position-relative'>
									<div className='col-lg-8'>
										<div className='form-group formInputs'>
											<InputGroup>
												<Input placeholder={date} value={date} />
												<InputGroupAddon addonType='append'>
													<InputGroupText className='pr-0'>
														<span className='fs-16 fw-400'>{time}</span>
													</InputGroupText>
												</InputGroupAddon>
											</InputGroup>
										</div>
									</div>
									<div className='col-lg-4'>
										<Dropdown
											isOpen={dropdownOpen}
											toggle={toggleDropdown}
											className='customdropdown w-100'
										>
											<DropdownToggle className='d-flex align-items-center w-100'>
												{selectedInterval.label}
												<i
													className={`fas ${dropdownOpen
														? 'fa-angle-up ml-auto'
														: 'fa-angle-down ml-auto'
														}`}
												></i>
											</DropdownToggle>
											<DropdownMenu right>{intervalItems}</DropdownMenu>
										</Dropdown>
									</div>
								</div>
							</>
						) : null}
					</div>

					<div className='mt-3'>
						<button
							className='btn btn-block checkoutBtn'
							onClick={handleEditList}
							disabled={editListingMutation.isLoading || bidAmount == undefined}
						>
							{editListingMutation.isLoading ? t('Loading') : t('Continue')}
						</button>
					</div>

					<div className='mt-4'>
						<button
							className='btn btn-block cancelBtn'
							onClick={() => {
								toggleEditListingModal()
								setBidAmount()
							}}
						>
							{t("Cancel")}
						</button>
					</div>
				</ModalBody>
			</Modal>
		</>
	)
}

export default EditListingModal
