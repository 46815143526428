import { useAccount, usePublicClient } from 'wagmi'

import { useNftContract } from './useContract'

export function useApprovalForAll(nftContractAddress, chainId) {
	const nftContract = useNftContract(nftContractAddress, chainId)
	const { address: account } = useAccount()
	const publicClient = usePublicClient({ chainId })

	async function approveForAll(operator) {
		const isApprovedForAll = await nftContract.read.isApprovedForAll([
			account,
			operator
		])

		if (!isApprovedForAll) {
			const hash = await nftContract.write.setApprovalForAll(
				[operator, true],
				{ account }
			)

			const transactionReceipt =
				await publicClient.waitForTransactionReceipt({
					hash
				})

			if (transactionReceipt.status !== 'success') {
				throw new Error('Something went wrong.')
			}
		}
	}

	return { approveForAll }
}
