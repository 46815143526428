import { useMutation } from 'wagmi'
import { useQueryClient } from '@tanstack/react-query'

import DataService from '../../../lib/DataService'

const { postRequest } = DataService()

function cancelListing(orderId) {
	return postRequest('asset/cancel-listing', { orderId })
}

export function useCancelListing() {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (orderId) => cancelListing(orderId),
		onSuccess: () => {
			queryClient.invalidateQueries(['asset'])
			queryClient.invalidateQueries(['listings'])
		}
	})
}
