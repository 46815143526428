import { useCallback, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { useAccount, useNetwork, useQueryClient, useSwitchNetwork } from 'wagmi'
import {
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Modal,
	ModalBody,
	ModalHeader
} from 'reactstrap'
import useSWR from 'swr'
import { formatUnits } from 'viem'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'

import { chainId } from '../../../config/constants/chains'
import contracts from '../../../config/constants/contracts'
import { useApproveToken } from '../../../hooks/useApproveToken'
import { useGetNativeCoinBalance } from '../../../hooks/useBalance'
import { useTokenContract } from '../../../hooks/useContract'
import { useBidPlacedSuccessfullyModalActions } from '../bid-placed-successful/store'
import { CloseIcon } from '../buy-now/CloseIcon'
import { useSomethingWentWrongModalActions } from '../error/store'
import { usePurchaseLoadingModalActions } from '../purchase-loading/store'
import { useBid } from './bid'
import { usePlaceBidModalActions, usePlaceBidModalState } from './store'
import { usePlaceBid } from '../../../hooks/usePlaceBid'
import { handleImageError } from '../../../hooks/useHandleImg'

import yellowtick20 from '../../../assets/images/yellow-tick_20px.svg'
import defaultImage from '../../../assets/images/homeExplorer/placeholder.png'
import { chainIdMap, chainMap } from '../../../utils/getFilterData'
import { useOkxSwitchNetwork } from '../../../hooks/useOkxSwitchNetwork'
import { removeLastDecimalValue } from '../../../utils/formatAmount'

export function PlaceBidModal() {
	const [videoError, setVideoError] = useState(false)
	const [bidAmount, setBidAmount] = useState()
	const [hasFunds, setHasFunds] = useState(false)
	const [isBidHighest, setIsBidHighest] = useState(false)
	const [error, setError] = useState(null)
	const [refreshBalance, setRefreshBalance] = useState(false)

	const { t } = useTranslation()

	const { togglePlaceBidModal } = usePlaceBidModalActions()
	const { isOpen, asset } = usePlaceBidModalState()
	const { toggleBidPlacedSuccessfullyModal, setAsset } =
		useBidPlacedSuccessfullyModalActions()
	const { togglePurchaseLoadingModal, setHeading } =
		usePurchaseLoadingModalActions()
	const { toggleSomethingWentWrongModal } =
		useSomethingWentWrongModalActions()

	const { address: account } = useAccount()
	const { switchNetworkAsync } = useSwitchNetwork()
	const { switchNetwork } = useOkxSwitchNetwork()
	const walletType = localStorage.getItem('walletType')
	const { chain } = useNetwork()
	const chainid = chainIdMap[asset?.details.chain]


	const tokenAddress =
		asset && !asset.details.isCoin
			? contracts[asset.details.currency?.toLowerCase()]
			: null
	const tokenContract = useTokenContract(tokenAddress, chainid)
	const marketplaceContractAddress = asset
		? contracts.marketplace[chainIdMap[asset.details.chain]]
		: null

	const { balance: nativeCoinBalance, refresh: refreshNative } = useGetNativeCoinBalance(chainid)

	const { data: tokenBalance } = useSWR(
		['tokenBalance', tokenContract, refreshBalance],
		async () => {
			const decimals = await tokenContract.read.decimals()
			const rawBalance = await tokenContract.read.balanceOf([account])
			return parseFloat(formatUnits(rawBalance, decimals))
		}
	)
	const queryClient = useQueryClient()
	const { mutateAsync: bid } = useBid()
	const { placeBid } = usePlaceBid(
		marketplaceContractAddress,
		chainIdMap[asset?.details.chain]
	)
	const { approve } = useApproveToken(tokenAddress, chainid)

	const throttledHandlePlacingABid = useCallback(
		debounce(handlePlacingABid, 500),
		[asset, bidAmount]
	)
	const debounceValidateBidAmount = useCallback(
		debounce((floatValue) => validateBidAmount(floatValue), 500),
		[asset, tokenBalance]
	)

	if (!asset) {
		return null
	}

	const balance = asset.details.isCoin ? nativeCoinBalance : parseFloat(tokenBalance?.toFixed(6))
	const isVideo = asset.details.asset.endsWith('.mp4')

	function validateBidAmount(floatValue) {
		setError(null)
		if (floatValue === undefined) {
			setError('Enter an amount')
			return
		}
		if (floatValue >= balance) {
			setHasFunds(false)
		} else {
			setHasFunds(true)
		}

		if (floatValue > 0 && floatValue > asset.details.highestBidAmount) {
			setIsBidHighest(true)
		} else {
			setIsBidHighest(false)
		}
	}

	const handleBidAmountChange = (values) => {
		const { floatValue } = values
		setBidAmount(floatValue)
		debounceValidateBidAmount(floatValue)
	}

	async function handlePlacingABid() {
		setHeading('Place Bid')
		togglePurchaseLoadingModal()
		setAsset(asset)
		try {
			if (walletType === 'okx') {
				if (
					asset.details.chain !== chainMap[parseInt(window.okxwallet.chainId)]
				) {
					await switchNetwork(chainIdMap[asset.details.chain])
				}
			} else {
				if (asset.details.chain === 'binance' && chainid !== chainId.BINANCE) {
					await switchNetworkAsync(chainId.BINANCE)
				} else if (
					asset.details.chain === 'ethereum' &&
					chainid !== chainId.ETHEREUM
				) {
					await switchNetworkAsync(chainId.ETHEREUM)
				} else if (asset.details.chain === 'polygon' && chainid !== chainId.AMOY) {
					await switchNetworkAsync(chainId.AMOY)
				}
			}


			if (!asset.details.isCoin) {
				await approve(bidAmount.toString(), marketplaceContractAddress)
			}

			await placeBid({
				owner: asset.details.seller,
				nftAddress: asset.details.contractAddress,
				tokenId: asset.details.nftId,
				bidAmount
			})

			const bidInfo = {
				bidder: account,
				bidAmount,
				id: asset.details.orderId
			}
			const res = await bid(bidInfo)

			if (res.status) {
				togglePlaceBidModal()
				toggleBidPlacedSuccessfullyModal()
				refreshNative()
				setBidAmount()
				queryClient.refetchQueries(['asset'])
				setRefreshBalance(!refreshBalance)
				return
			}

			throw new Error('Something went wrong')
		} catch (error) {
			toggleSomethingWentWrongModal()
		} finally {
			togglePurchaseLoadingModal()
		}
	}
	const handleVideoError = () => {
		setVideoError(true)
	}

	return (
		<Modal
			isOpen={isOpen}
			centered='true'
			className='mymodal'
			backdropClassName='selCurBp'
			keyboard={false}
		>
			<ModalHeader
				toggle={togglePlaceBidModal}
				close={<CloseIcon onClose={togglePlaceBidModal} />}
			>
				<h5 className='modal-title'>{t("Place a Bid")}</h5>
			</ModalHeader>
			<ModalBody>
				<div>
					<div className='cartItem'>
						<div className='itemImg'>
							{!isVideo ? (
								typeof asset?.details?.asset == 'string' &&
									asset?.details?.asset !== undefined &&
									!asset?.details?.asset?.startsWith(
										process.env.S3_URL
									) &&
									!asset?.details?.asset?.startsWith('ipfs') &&
									asset?.details?.asset !== null ? (
									<img
										className='object-cover'
										alt='asset'
										src={
											asset.details.asset
												? asset.details.asset
												: defaultImage
										}
										onError={handleImageError}
									/>
								) : (
									<img
										className='object-cover'
										alt='asset'
										src={defaultImage}
									/>
								)
							) : videoError ? (
								<img
									className='object-cover'
									alt='asset'
									src={defaultImage}
								/>
							) : (

								<video loop autoPlay muted playsInline preload='metadata' controlslist='nodownload' id='video-asset' style={{
									objectFit: 'cover',
									height: '84px',
									width: '84px'
								}}>
									<source src={asset.details.asset ? asset.details.asset : defaultImage} type="video/mp4" onError={handleVideoError} />
								</video>
							)}
						</div>
						<div className='flex-grow-1 p-3'>
							<div className='d-flex justify-content-between mb-2'>
								<div className='itemName'>
									{asset.collection.name}{' '}
									{asset.collection.isVerified && (
										<img
											src={yellowtick20}
											alt='yellowtick20'
											className='ml-1'
										/>
									)}
								</div>
							</div>
							<div className='d-flex justify-content-between mb-n1'>
								<div className='itemId'>
									{asset.details.name}
								</div>
								<span className='itemBal'>{`${asset.details.price * asset.details.quantity
									} ${asset.details.currency}`}</span>
							</div>
						</div>
					</div>
				</div>
				<hr className='hrCls' />
				<div className='borderRoundedCard'>
					<div class='d-flex justify-content-between mb-2'>
						<div className='fs-14 fw-400 dark-text-secondary'>
							{t('Your Balance')}
						</div>
						<div className='fs-16 fw-600 dark-text'>
							{balance !== undefined
								? `${removeLastDecimalValue(balance)} ${asset.details.currency}`
								: 'Loading...'}
						</div>
					</div>
					<div class='d-flex justify-content-between'>
						<div className='fs-14 fw-400 dark-text-secondary'>
							{t('Highest Bid')}
						</div>
						<div className='fs-16 fw-600 dark-text'>
							{`${asset.details.highestBidAmount} ${asset.details.currency}`}
						</div>
					</div>
				</div>

				<hr className='hrCls mt-3' />

				<div className='position-relative zindex1'>
					<div className='d-flex justify-content-between mb-1'>
						<span className='fs-14 fw-600 dark-text'>{t("Bid")}</span>
						{bidAmount
							? !hasFunds && (
								<span className='fs-14 fw-400 dark-red'>
									{t('Not enough funds')}
								</span>
							)
							: null}

						{error && (
							<span className='fs-14 fw-400 dark-red'>
								{error}
							</span>
						)}
					</div>
					<div className='form-group formInputs'>
						<InputGroup
							className={`${bidAmount
								? !hasFunds || !isBidHighest || error
									? 'err'
									: ''
								: ''
								}`}
						>
							<NumericFormat
								className='form-control mx-auto'
								placeholder={
									asset.details.highestBidAmount
										? asset.details.highestBidAmount
										: asset.details.price *
										asset.details.quantity
								}
								value={bidAmount}
								allowNegative={false}
								customInput={Input}
								onValueChange={handleBidAmountChange}
							/>
							<InputGroupAddon addonType='append'>
								<InputGroupText
									className='pr-0'
									style={{ color: 'white' }}
								>
									{asset.details.currency}
								</InputGroupText>
							</InputGroupAddon>
						</InputGroup>
					</div>
				</div>

				{!!bidAmount && !isBidHighest && (
					<div className='warningbx'>
						<svg
							width='18'
							height='16'
							viewBox='0 0 18 16'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M8.50781 5.125H9.46484C9.57422 5.125 9.62891 5.20703 9.62891 5.31641L9.4375 10.6758C9.4375 10.7578 9.35547 10.8125 9.27344 10.8125H8.69922C8.61719 10.8125 8.53516 10.7578 8.53516 10.6758L8.34375 5.31641C8.34375 5.20703 8.39844 5.125 8.50781 5.125ZM9 11.3594C9.41016 11.3594 9.76562 11.7148 9.76562 12.125C9.76562 12.5625 9.41016 12.8906 9 12.8906C8.5625 12.8906 8.23438 12.5625 8.23438 12.125C8.23438 11.7148 8.5625 11.3594 9 11.3594ZM16.6836 12.7812C17.2031 13.6562 16.5469 14.75 15.5625 14.75H2.4375C1.42578 14.75 0.796875 13.6562 1.28906 12.7812L7.85156 1.40625C8.34375 0.53125 9.62891 0.53125 10.1211 1.40625L16.6836 12.7812ZM15.5625 13.875C15.8906 13.875 16.1094 13.5195 15.918 13.2188L9.35547 1.84375C9.19141 1.57031 8.78125 1.57031 8.61719 1.84375L2.05469 13.2188C1.89062 13.5195 2.08203 13.875 2.4375 13.875H15.5625Z'
								fill='white'
							/>
						</svg>
						<span className='pl-2'>
							{t("Your bid should be higher than")}
							<span class='fw-600'>{`${asset.details.highestBidAmount} ${asset.details.currency}`}</span>
							!
						</span>
					</div>
				)}
				<div className='mt-4' onClick={throttledHandlePlacingABid} onKeyDown={(e) => {
					if (e.key === 'Enter' || e.key === ' ') {
						throttledHandlePlacingABid()
					}
				}}
					role="button"
					tabIndex={0}>
					<button
						className='btn btn-block checkoutBtn'
						disabled={!isBidHighest || !hasFunds || !!error}
					>
						{t("Place a Bid")}{' '}
					</button>
				</div>
			</ModalBody>
		</Modal>
	)
}
