import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import OffersList from './components/OffersList'
import { parseQueryStringToObject } from '../../utils/parseQueryStringToObject'
import { flattenFilters } from '../../utils/flattenFilters'
import { convertObjectToQueryString } from '../../utils/convertObjectToQueryString'
import { useCollectionFilterOffer } from './apis/collectionOffer'
import { ItemsViewTabs } from './components/ItemsViewTabs'
import { Filters } from './components/Filters'

export const Offers = ({ collectionAddress, collectionDetails, tab }) => {
	const [searchParams, setSearchParams] = useSearchParams()
	const [filters, setFilters] = useState(() =>
		parseQueryStringToObject(searchParams)
	)

	const [activeTab, setActiveTab] = useState('grid')
	const [showFilters, setShowFilters] = useState(true)

	const { data: traitsData } = useCollectionFilterOffer(collectionAddress)
	

	useEffect(() => {
		const filters = parseQueryStringToObject(searchParams)
		setFilters(filters)
	}, [setFilters, searchParams])

	const handleFiltersChange = (filters) => {
		setFilters(filters)
		const flattenedFilters = flattenFilters(filters)
		const queryString = convertObjectToQueryString(flattenedFilters)
		setSearchParams(queryString)
	}

	return (
		<Filters
			itemsViewTabsContent={
				<OffersList
					activeTab={activeTab}
					showFilters={showFilters}
					collectionDetails={collectionDetails}
					filters={filters}
					handleFiltersChange={handleFiltersChange}
				/>
			}
			showFilters={showFilters}
			setShowFilters={setShowFilters}
			traits={traitsData}
			filters={filters}
			handleFiltersChange={handleFiltersChange}
			collectionDetails={collectionDetails}
			tab={tab}
		>
			<ItemsViewTabs
				activeTab={activeTab}
				setActiveTab={setActiveTab}
			></ItemsViewTabs>
		</Filters>
	)
}
