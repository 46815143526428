import Skeleton from 'react-loading-skeleton'
import { times } from 'lodash'

export function NftsGridPlaceholder({ numItems = 12, showFilters = true }) {
	const gridview = showFilters ? 'g-col-5' : 'g-col-6'

	return (
		<div className='py-3 nft-grid-skeleton'>
			<div className={`gridrow ${gridview}`}>
				{times(numItems).map((itemKey) => (
					<div className='grid-item' key={itemKey}>
						<Skeleton
							className='item-img'
							enableAnimation={false}
						/>
						<Skeleton className='img-placeholder' />
						<div className='item-details'>
							<Skeleton
								width='85%'
								className='ml-1'
								baseColor='#292929'
							/>
							<div className='d-flex align-items-center justify-content-between details'>
								<Skeleton baseColor='#292929' />
								<Skeleton baseColor='#292929' />
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}
