
import DataService from '../../../lib/DataService'

const { getRequest } = DataService()

export async function fetchActivity(
	collectionAddress,
	nftId,
	selectedTabs,
	pageParam
) {
	let tabdata = '';

	if (selectedTabs && selectedTabs.length > 0) {
		const filteredTabs = selectedTabs.filter(tab => tab !== 'all');
		const transformedTabs = filteredTabs.map((tab, i) => `type[${i}]=${tab}`);
		tabdata = transformedTabs.join('&');
	} else {
		console.log("selectedTabs is undefined or empty");
	}

	const apiUrl = `asset/activity?collection=${collectionAddress}&id=${nftId}&${tabdata}&page=${pageParam}`;
	const response = await getRequest(apiUrl);
	return response.data;
}