import {

   TabContent,
   TabPane,
   Nav,
   NavItem,
   NavLink,
   UncontrolledCollapse,
   UncontrolledTooltip
} from 'reactstrap'
import React, { useContext, useEffect, useState } from 'react'
import classnames from 'classnames'

import updown from './assets/updown.png'
import sm4 from './assets/sm4.png'
import sm3 from './assets/sm3.png'
import sm2 from './assets/sm2.png'
import sm1 from './assets/sm1.png'
import angleDown from './assets/angle-down.png'
import DefaultImage from '../../assets/images/homeExplorer/placeholder.png'
import { handleImageError } from '../../hooks/useHandleImg'
import { useAccount } from 'wagmi'
import { useToast } from '../../utils/toast/customSuccess'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from '../context/context'
import { useNavigate } from 'react-router-dom'
import loader from '../../assets/images/Loader.png'
import ResponsivePagination from 'react-responsive-pagination';
import nodatayet from "./assets/nodatayet.svg";
import nodatalightyet from "../../assets/images/nodatalightyet.svg";

import cndds from '../../assets/images/cndds.png'
import cnddsLight from '../../assets/images/cnddsLight.svg'

import { useToggleClassName } from '../../hooks/useToggleClassName'
import { nativeCoinMap, chainIconMap } from '../../utils/getFilterData'
import { formatCompactNumber } from '../../utils/formatCompactNumber'
import DataService from '../../lib/DataService'
const landPerRow = 6

const Bridge = () => {
   const { t } = useTranslation()
   useToggleClassName()

   useEffect(() => {
      document.title = "Ranking | Wor NFT"
   }, [])


   const { getRequest, postRequest } = DataService()

   const RankingData = {
      1: "Trending",
      2: "Top",
      3: "Watchlist",
   }

   const [activeTab, setActiveTab] = useState(
      sessionStorage.getItem('tab') ? sessionStorage.getItem('tab') : '1'
   )
   const { activeTab4, setActiveTab4, watchlistTab, setWatchlistTab } =
      useContext(ThemeContext)


   useEffect(() => {
      if (activeTab4)
         document.title = `${RankingData[activeTab4]} - Ranking | Wor NFT`
   }, [activeTab4])



   const toggle = tab => {
      if (activeTab4 !== tab) setActiveTab4(tab)
      setcollectedCheckChain([])
      setCheckCategory([])
   }

   const [activeTab1, setActiveTab1] = useState('1')
   const toggle1 = tab => {
      if (activeTab1 !== tab) setActiveTab1(tab)
   }

   const { address, isConnected } = useAccount()

   const [loading, setLoading] = useState(false)
   let [color, setColor] = useState('#ffffff')
   const [next, setNext] = useState(landPerRow)
   const [page, setPage] = useState(1)

   const navigate = useNavigate()

   const Chains = [
      {
         id: 1,
         name: t('BNB'),
         value: 'binance'
      },
      {
         id: 2,
         name: t('ETH'),
         value: 'ethereum'
      },
      {
         id: 3,
         name: t('MATIC'),
         value: 'polygon'
      }
   ]

   const toast = useToast()
   const [checkcollectedChain, setcollectedCheckChain] = useState([])
   const [checkcategory, setCheckCategory] = useState([])
   const [ranking, setRanking] = useState('')

   useEffect(() => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth'
      })
      fetchdata()
      getCategory()
   }, [
      activeTab4,
      page,
      address,
      checkcollectedChain,
      activeTab1,
      checkcategory
   ])

   const [count, setCount] = useState('')


   function handlePageChange(page) {
      setPage(page);
   }


   const fetchdata = async () => {
      setLoading(true)
      const fetch = await getRequest(
         `home/ranking?page=${checkcategory > 0 ? 1 : checkcollectedChain > 0 ? 1 : page}&size=100&type=${activeTab4 == '1' ? 'trending' : activeTab4 == '2' ? 'top' : 'watchlist'
         }&categoryName=${checkcategory}&filter=${activeTab1 == '1'
            ? 1
            : activeTab1 == '2'
               ? 6
               : activeTab1 == '3'
                  ? '24'
                  : activeTab1 == '4'
                     ? 7
                     : activeTab1 == '5'
                        ? 30 :
                        activeTab1 == '6'
                           ? '' :
                           ""
         }&chains=${checkcollectedChain}&address=${address}`
      )
      if (fetch.status == true) {
         setRanking(fetch.data);
         setCount(Math.ceil(fetch.count / 100))
         setLoading(false)
         setPage(checkcategory > 0 ? 1 : checkcollectedChain > 0 ? 1 : page)
      } else if (fetch.status == false) {
         setRanking('')
         setCount('')
         setLoading(false)
         setPage(1)
      }
   }

   const [categories, setCategories] = useState('')

   const getCategory = async () => {
      const fetch = await getRequest('home/categories')
      if (fetch.status == true) {
         setCategories(fetch.data)
      } else {
         setCategories('')
      }
   }

   const handleCheckboxChange = item => {
      let a = checkcollectedChain.includes(item.value)

      if (a == true) {
         const index = checkcollectedChain.indexOf(item.value)
         if (index > -1) {
            checkcollectedChain.splice(index, 1)
            const nerArr = [...checkcollectedChain]
            setcollectedCheckChain(nerArr)
         }
      } else if (a == false) {
         setcollectedCheckChain([...checkcollectedChain, item.value])
      }
   }

   const litstedChain =
      checkcollectedChain &&
      checkcollectedChain.map(item => {
         return (
            <>
               {item == '' ? (
                  ''
               ) : (
                  <>
                     <div className='tagLabel'>
                        <span style={{ textTransform: "capitalize" }}>{item}</span>
                        <i
                           className='fas fa-times ml-2'
                           style={{ cursor: 'pointer' }}
                           onClick={() => closecollectedchain(item)}
                           onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                 closecollectedchain(item)
                              }
                           }}
                           role="button" tabIndex={0}
                        ></i>
                     </div>
                  </>
               )}
            </>
         )
      })

   const closecollectedchain = item => {
      let a = checkcollectedChain.includes(item)

      if (a == true) {
         const index = checkcollectedChain.indexOf(item)
         if (index > -1) {
            checkcollectedChain.splice(index, 1)
            const nerArr = [...checkcollectedChain]
            setcollectedCheckChain(nerArr)
         }
      }
   }

   const handleCheckboxCollection = item => {
      let a = checkcategory.includes(item.name)

      if (a == true) {
         const index = checkcategory.indexOf(item.name)
         if (index > -1) {
            checkcategory.splice(index, 1)
            const nerArr = [...checkcategory]
            setCheckCategory(nerArr)
         }
      } else if (a == false) {
         setCheckCategory([...checkcategory, item.name])
      }
   }

   const litstedCategory =
      checkcategory &&
      checkcategory.map(item => {
         return (
            <>
               {item == '' ? (
                  ''
               ) : (
                  <>
                     <div className='tagLabel'>
                        <span>{t(item)}</span>
                        <i
                           className='fas fa-times ml-2'
                           style={{ cursor: 'pointer' }}
                           onClick={() => closecategory(item)}
                           onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                 closecategory(item)
                              }
                           }}
                           role="button" tabIndex={0}
                        ></i>
                     </div>
                  </>
               )}
            </>
         )
      })

   const closecategory = item => {
      let a = checkcategory.includes(item)

      if (a == true) {
         const index = checkcategory.indexOf(item)
         if (index > -1) {
            checkcategory.splice(index, 1)
            const nerArr = [...checkcategory]
            setCheckCategory(nerArr)
         }
      }
   }

   const [isLoggedIn, setIsLoggedIn] = useState(
      localStorage.getItem('isLoggedIn')
   )
   useEffect(() => {
      const handleStorage = (e) => {
         setIsLoggedIn(localStorage.getItem('isLoggedIn'))
      }
      window.addEventListener('storage', handleStorage)

      return () => {
         window.removeEventListener('storage', handleStorage)
      }
   }, [])

   const watchlist = async (data) => {
      setLoading(true);
      if (address && isLoggedIn == 'true') {
         const a = data.watchlistedBy.includes(
            address && address.toLowerCase()
         )
         if (a == true) {
            const fetch = await getRequest(
               `home/watchlist-mutation?id=${data.rankingId}&userAddress=${address ? address.toLowerCase() : ''
               }&isWatchlist=${false}`
            )
            if (fetch.status == true) {
               fetchdata()
               toast.error(t('Removed from Watchlist'))
            }
         } else {
            const fetch = await getRequest(
               `home/watchlist-mutation?id=${data.rankingId}&userAddress=${address ? address.toLowerCase() : ''
               }&isWatchlist=${true}`
            )
            if (fetch.status == true) {
               fetchdata()
               toast.star(<div>{t("Added to Watchlist")} <span style={{ color: "#FFC300", cursor: "pointer" }} onClick={view} onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                     view()
                  }
               }}
                  role="button" tabIndex={0}>{t("View")}</span></div>)
            }
         }
      } else {
         toast.error('Please connect wallet')
         setLoading(false)
      }
   }

   const view = () => {
      setActiveTab4('3')
   }

   const clearAll = () => {
      setcollectedCheckChain([])
      setCheckCategory([])
   }

   const Back = () => {
      setcollectedCheckChain([])
      setCheckCategory([])
   }
   var isMobile = window.matchMedia("only screen and (max-width: 767px)").matches;

   const getTooltip = (data) => {
      return data.watchlistedBy.includes(address && address.toLowerCase()) == true ?


         <>
            <svg id="tooltipTrend" width="19" height="18" viewBox="0 0 19 18"
               fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => watchlist(data)} onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                     watchlist(data)
                  }
               }}
               role="button" tabIndex={0} style={{ cursor: "pointer" }}>
               <path
                  d="M8.52246 1.5625C8.89746 0.8125 9.95996 0.84375 10.3037 1.5625L12.3662 5.71875L16.9287 6.375C17.7412 6.5 18.0537 7.5 17.46 8.09375L14.1787 11.3125L14.96 15.8438C15.085 16.6562 14.21 17.2812 13.4912 16.9062L9.42871 14.75L5.33496 16.9062C4.61621 17.2812 3.74121 16.6562 3.86621 15.8438L4.64746 11.3125L1.36621 8.09375C0.772461 7.5 1.08496 6.5 1.89746 6.375L6.49121 5.71875L8.52246 1.5625Z"
                  fill="#FFA900" />
            </svg>
            {!isMobile &&
               <UncontrolledTooltip placement="top" target="tooltipTrend" className="tooltipCnt red-tooltip" >
                  {t("Remove from Watchlist")}
               </UncontrolledTooltip>
            }
         </>
         :
         <>
            <svg id="tooltip1" width="19" height="18" viewBox="0 0 19 18"
               fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => watchlist(data)} onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                     watchlist(data)
                  }
               }}
               role="button" tabIndex={0} style={{ cursor: "pointer" }}>
               <path
                  d="M16.9287 6.375C17.7412 6.5 18.0537 7.5 17.46 8.09375L14.1787 11.3125L14.96 15.8438C15.085 16.6562 14.21 17.2812 13.4912 16.9062L9.42871 14.75L5.33496 16.9062C4.61621 17.2812 3.74121 16.6562 3.86621 15.8438L4.64746 11.3125L1.36621 8.09375C0.772461 7.5 1.08496 6.5 1.89746 6.375L6.49121 5.71875L8.52246 1.5625C8.89746 0.8125 9.95996 0.84375 10.3037 1.5625L12.3662 5.71875L16.9287 6.375ZM12.5537 10.7812L15.71 7.71875L11.3662 7.09375L9.42871 3.15625L7.45996 7.09375L3.11621 7.71875L6.27246 10.7812L5.52246 15.0938L9.42871 13.0625L13.3037 15.0938L12.5537 10.7812Z"
                  fill="#595F6A" />
            </svg>
            {!isMobile &&

               <UncontrolledTooltip placement="top" target="tooltip1" className="tooltipCnt red-tooltip">
                  {t("Add to Watchlist")}
               </UncontrolledTooltip>
            }
         </>

   }


   const [sortOrder, setSortOrder] = useState({
      field: '',
      ascending: true,
   });

   const handleSort = (field) => {
      setSortOrder((prevSortOrder) => ({
         field,
         ascending: field === prevSortOrder.field ? !prevSortOrder.ascending : true,
      }));
   };

   const sortedData = [...ranking].sort((a, b) => {
      const aValue = a[sortOrder.field];
      const bValue = b[sortOrder.field];

      if (aValue === undefined || bValue === undefined) {
         return sortOrder.ascending ? (aValue === undefined ? 1 : -1) : bValue === undefined ? 1 : -1;
      }

      if (typeof aValue !== typeof bValue) {
         return sortOrder.ascending ? (typeof aValue > typeof bValue ? 1 : -1) : typeof bValue > typeof aValue ? 1 : -1;
      }

      const compareResult = aValue.localeCompare ? aValue.localeCompare(bValue) : aValue - bValue;

      return sortOrder.ascending ? compareResult : -compareResult;
   });



   return (
      <>
         <div className={loading ? 'spinner' : 'd-none'}>
            <img src={loader} alt='' className='spinAnimate' />
         </div>


         <section class='TraSec'>
            <div class='container-fluid'>
               <div class='d-flex align-items-center justify-content-between flex-wrap'>
                  <div class='mb-36'>
                     <Nav tabs className='trad_tab_hd'>
                        <NavItem>
                           <NavLink
                              className={classnames({ active: activeTab4 === '1' })}
                              onClick={() => {
                                 toggle('1')
                                 setActiveTab1('1')
                              }}
                           >
                              {t('Trending')}
                           </NavLink>
                        </NavItem>
                        <NavItem>
                           <NavLink
                              className={classnames({ active: activeTab4 === '2' })}
                              onClick={() => {
                                 toggle('2')
                                 setActiveTab1('1')
                              }}
                           >
                              {t('Top')}
                           </NavLink>
                        </NavItem>
                        <NavItem>
                           <NavLink
                              className={classnames({ active: activeTab4 === '3' })}
                              onClick={() => {
                                 toggle('3')
                                 setWatchlistTab('Watchlist')
                                 setActiveTab1('1')
                              }}
                           >
                              {t('Watchlist')}
                           </NavLink>
                        </NavItem>
                     </Nav>
                  </div>
                  <div class='mb-36'>
                     <div class=' mt-2 '>
                        <Nav tabs className='hrs_tab_hd'>
                           <NavItem>
                              <NavLink
                                 className={classnames({ active: activeTab1 === '1' })}
                                 onClick={() => {
                                    toggle1('1')
                                 }}
                                 style={{ cursor: "pointer" }}
                              >
                                 {t('1h')}
                              </NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 className={classnames({ active: activeTab1 === '2' })}
                                 onClick={() => {
                                    toggle1('2')
                                 }}
                                 style={{ cursor: "pointer" }}
                              >
                                 {t('6h')}
                              </NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 className={classnames({ active: activeTab1 === '3' })}
                                 onClick={() => {
                                    toggle1('3')
                                 }}
                                 style={{ cursor: "pointer" }}
                              >
                                 {t('24h')}
                              </NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 className={classnames({ active: activeTab1 === '4' })}
                                 onClick={() => {
                                    toggle1('4')
                                 }}
                                 style={{ cursor: "pointer" }}
                              >
                                 {t('7d')}
                              </NavLink>
                           </NavItem>
                           {activeTab4 == "2" || activeTab4 == "3" ?
                              <>
                                 <NavItem>
                                    <NavLink
                                       className={classnames({ active: activeTab1 === '5' })}
                                       onClick={() => {
                                          toggle1('5')
                                       }}
                                       style={{ cursor: "pointer" }}
                                    >
                                       {t('30d')}
                                    </NavLink>
                                 </NavItem>
                                 <NavItem>
                                    <NavLink
                                       className={classnames({ active: activeTab1 === '6' })}
                                       onClick={() => {
                                          toggle1('6')
                                       }}
                                       style={{ cursor: "pointer" }}
                                    >
                                       {t('All')}
                                    </NavLink>
                                 </NavItem>
                              </>
                              :
                              ""
                           }
                        </Nav>
                     </div>
                  </div>
               </div>
               <div>
                  <div class='row'>

                     <div class='col-xl-9 order-xl-1 order-2 mb-3'>
                        <TabContent activeTab={activeTab4}>
                           <TabPane tabId={activeTab4}>
                              <div>
                                 <div class='mb-2 d-flex flexwrap'>
                                    <div className='fiterSelectlabel mb-4'>
                                       {litstedChain}{litstedCategory}
                                       {Number(checkcollectedChain?.length) + Number(checkcategory.length) > 1 ?
                                          <label class="artcrd mr-1" onClick={clearAll}
                                             onKeyDown={(e) => {
                                                if (e.key === 'Enter' || e.key === ' ') {
                                                   clearAll()
                                                }
                                             }}
                                             role="button" tabIndex={0}>{t("Clear All")}</label>
                                          :
                                          ""
                                       }
                                    </div>
                                 </div>
                                 {sortedData && sortedData.length > 0 ?
                                    <>
                                       <div class="table-responsive">
                                          <table class="table table-borderless mb-0 hmecollection">

                                             <thead class="trd_head">
                                                <tr>
                                                   <th></th>
                                                   <th>{t("Collections")} </th>
                                                   <th></th>
                                                   <th onClick={() => handleSort('volume')}>{t("Volume")} {sortOrder.field !== 'volume' ? <img src={updown} width="14px" style={{  cursor: "pointer" }}/> : sortOrder.field === 'volume' && (sortOrder.ascending ? <i class="fa fa-angle-up" style={{ fontSize: "14px", cursor: "pointer" }}></i> : <i class="fa fa-angle-down" style={{ fontSize: "14px", cursor: "pointer" }}></i>)}
                                                      
                                                   </th>
                                                   <th onClick={() => handleSort('volumeChange')}>{t("Volume Change")} {sortOrder.field !== 'volumeChange' ? <img src={updown} width="14px" style={{  cursor: "pointer" }}/> : sortOrder.field === 'volumeChange' && (sortOrder.ascending ? <i class="fa fa-angle-up" style={{ fontSize: "14px", cursor: "pointer" }}></i> : <i class="fa fa-angle-down" style={{ fontSize: "14px", cursor: "pointer" }}></i>)}
                                                     
                                                   </th>
                                                   <th onClick={() => handleSort('floorPrice')}>{t("Floor Price")} {sortOrder.field !== 'floorPrice' ? <img src={updown} width="14px" style={{ cursor: "pointer" }}/> : sortOrder.field === 'floorPrice' && (sortOrder.ascending ? <i class="fa fa-angle-up" style={{ fontSize: "14px", cursor: "pointer" }}></i> : <i class="fa fa-angle-down" style={{ fontSize: "14px", cursor: "pointer" }}></i>)}
                                                   </th>
                                                   <th onClick={() => handleSort('floorChange')}>{t("Floor Change")} {sortOrder.field !== 'floorChange' ? <img src={updown} width="14px" style={{  cursor: "pointer" }}/> : sortOrder.field === 'floorChange' && (sortOrder.ascending ? <i class="fa fa-angle-up" style={{ fontSize: "14px", cursor: "pointer" }}></i> : <i class="fa fa-angle-down" style={{ fontSize: "14px", cursor: "pointer" }}></i>)}
                                                   </th>
                                                   <th>{t("Sales")}</th>
                                                   <th>
                                                      {t("Owners")}
                                                   </th>
                                                   <th>{t("Items")} </th>
                                                </tr>
                                             </thead>
                                             {sortedData && sortedData.map((data, index) => (
                                                <tbody class="trdtable_body" >
                                                   <tr key={index}>
                                                      <td class="gld">
                                                         {page == 1
                                                            ? index + 1
                                                            : (page - 1) * 100 +
                                                            (index + 1)}
                                                      </td>
                                                      <td
                                                         onClick={() => navigate(`/collection/${data.slug}`)} style={{ cursor: "pointer" }}
                                                      >
                                                         <div class="d-flex align-items-center">
                                                            <span class="mr-2">
                                                               {!data?.logoImage?.startsWith(process.env.S3_URL) && !data?.logoImage?.startsWith('ipfs') && data?.logoImage !== null ? <img src={data?.logoImage !== "" ? data?.logoImage : DefaultImage} alt="" height="40px" width="40px" style={{ objectFit: "cover", borderRadius: "50%" }} onError={handleImageError} /> : <img src={DefaultImage} alt="" height="40px" width="40px" style={{ objectFit: "cover", borderRadius: "50%" }} />}
                                                            </span>
                                                            <h1 class="mr-2"> {data?.collectionName}</h1>
                                                            <span class="mr-2">
                                                               <img src={data?.verification == "Pending" ? "" : sm4} alt="" />
                                                            </span>
                                                            <span>
                                                               <img src={data?.chain == "binance" ? sm1 : data?.chain == "ethereum" ? sm2 : data?.chain == "polygon" ? sm3 : ""} alt="" />
                                                            </span>
                                                         </div>
                                                      </td>
                                                      <td>
                                                         {getTooltip(data)}

                                                      </td>
                                                      <td>
                                                         {data.volume == "0" ? 0 : parseFloat(data?.volume?.toFixed(5))} {nativeCoinMap[data.chain]}
                                                      </td>
                                                      {data.isVolumeUp == true ?
                                                         <td class="green" style={{ justifyContent: "center" }}>
                                                            {data.volumeChange ? data.volumeChange : "0"}
                                                         </td>
                                                         :
                                                         <td class="red" style={{ justifyContent: "center" }}>
                                                            {data.volumeChange ? data.volumeChange : "0"}
                                                         </td>
                                                      }
                                                      <td>
                                                         {data.floorPrice == "0" ? 0 : parseFloat(data.floorPrice.toFixed(5))} {data.floorPrice == 0 ? nativeCoinMap[data.chain] : data?.floorCurrency}
                                                      </td>

                                                      {data.isFloorUp == true ?
                                                         <td class="green" style={{ justifyContent: "center" }}>
                                                            {data.floorChange ? data.floorChange : "0"}
                                                         </td>
                                                         :
                                                         <td class="red" style={{ justifyContent: "center" }}>
                                                            {data.floorChange ? data.floorChange : "0"}
                                                         </td>
                                                      }
                                                      <td>{data.rankingsoldItems}</td>
                                                      <td>
                                                         {data.owners}
                                                      </td>
                                                      <td>
                                                         {formatCompactNumber(data.totalItems)}
                                                      </td>
                                                   </tr>
                                                </tbody>
                                             ))
                                             }
                                          </table>
                                       </div>
                                       <div className='row justify-content-center'>
                                          <div className='col-lg-4 col-md-6 col-sm-8'>
                                             <div class="bri_pagenation2">

                                                {ranking && ranking.length > 0 ?
                                                   <div class=''>
                                                      <nav aria-label="Page ">
                                                         <ResponsivePagination
                                                            total={count}
                                                            current={page}
                                                            onPageChange={page => handlePageChange(page)}
                                                            previousLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.125 5.75L5.71875 1.125C5.84375 0.96875 6.09375 0.96875 6.25 1.125L6.84375 1.75C7 1.875 7 2.125 6.84375 2.28125L3.15625 6L6.84375 9.75C7 9.90625 7 10.125 6.84375 10.2812L6.25 10.9062C6.09375 11.0625 5.84375 11.0625 5.71875 10.9062L1.125 6.28125C0.96875 6.125 0.96875 5.90625 1.125 5.75Z" fill="#F7F7F7"></path></svg>}
                                                            nextLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.84375 6.28125L2.28125 10.9062C2.125 11.0625 1.875 11.0625 1.75 10.9062L1.125 10.2812C0.96875 10.125 0.96875 9.90625 1.125 9.75L4.8125 6L1.125 2.28125C0.96875 2.125 0.96875 1.875 1.125 1.75L1.75 1.125C1.875 0.96875 2.125 0.96875 2.28125 1.125L6.84375 5.75C7 5.90625 7 6.125 6.84375 6.28125Z" fill="#F7F7F7"></path></svg>}
                                                            previousClassName="page-link-1"
                                                            nextClassName="page-link-1"
                                                         />
                                                      </nav>
                                                   </div>
                                                   :
                                                   ""
                                                }
                                             </div>
                                          </div>
                                       </div>
                                    </>
                                    :

                                    activeTab4 == "3" ?
                                       <div className="col-12" style={{ display: "flex", justifyContent: "center" }}>
                                          <div class="nthhkj text-center" style={{ maxWidth: "210px" }}>

                                             <div class='noResf text-center'>
                                                <img class="img-fluid" src={localStorage.getItem('light_theme') == 'true' ? nodatalightyet : nodatayet} alt="nodatayet" />
                                                <div className='fs-16 fw-600 dark-text mb-2'>
                                                   {t("No data yet")}.
                                                </div>
                                                <div className='fs-14 fw-400 dark-text-secondary'>{t("Select a collection and click on")} <span><svg width="19" height="18"
                                                   viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ verticalAlign: "top" }}>
                                                   <path
                                                      d="M8.52246 1.5625C8.89746 0.8125 9.95996 0.84375 10.3037 1.5625L12.3662 5.71875L16.9287 6.375C17.7412 6.5 18.0537 7.5 17.46 8.09375L14.1787 11.3125L14.96 15.8438C15.085 16.6562 14.21 17.2812 13.4912 16.9062L9.42871 14.75L5.33496 16.9062C4.61621 17.2812 3.74121 16.6562 3.86621 15.8438L4.64746 11.3125L1.36621 8.09375C0.772461 7.5 1.08496 6.5 1.89746 6.375L6.49121 5.71875L8.52246 1.5625Z"
                                                      fill="#FFA900" />
                                                </svg>
                                                </span> {t("star to add it to watchlist")}.</div>
                                             </div>
                                          </div>
                                       </div>
                                       :
                                       <div className="col-12" style={{ display: "flex", justifyContent: "center" }}>
                                          <div class="nthhkj text-center" style={{ maxWidth: "210px" }}>
                                             <div class='noResf text-center'>
                                                <img class='img-fluid' src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : cndds} alt='' />
                                                {Number(checkcollectedChain?.length) + Number(checkcategory.length) == 0 ?
                                                   <h1>{t('Nothing to display.')}</h1>
                                                   :
                                                   <h1> {t('No items were found. Try to broaden your search.')}</h1>
                                                }
                                             </div>
                                          </div>
                                       </div>

                                 }

                              </div>
                           </TabPane>

                        </TabContent>
                     </div>
                     <div class='col-xl-3 col-md-6 order-xl-2 order-1 mb-3'>
                        <div class='filtl filter_px-0'>
                           <h1>{t('Filter')}</h1>
                           <div
                              class='d-flex align-items-center justify-content-between hdfil'
                              id='collapse1'
                              style={{ cursor: 'pointer' }}
                           >
                              <h2>{t('Categories')}</h2>
                              <img class='rntar' src={angleDown} alt='' />
                           </div>
                           <div className="hdfil">
                              <UncontrolledCollapse toggler='#collapse1'>
                                 <div class='bxnflt'>
                                    {categories && categories.length > 0 ? (
                                       categories &&
                                       categories.map((data, index) => (
                                          <div
                                             class='d-flex align-items-center mb-1'
                                             index={data.id}
                                          >
                                             <label
                                                class='cuschk mr-2'
                                                For={`checkbox${index + 1}`}
                                             >
                                                <input
                                                   type='checkbox'
                                                   id={`checkbox${index + 1}`}
                                                   name='collectionchoose'
                                                   checked={
                                                      checkcategory.length > 0
                                                         ? checkcategory.includes(data.name)
                                                         : false
                                                   }
                                                   onChange={() => handleCheckboxCollection(data)}
                                                />
                                                <span class='checkmark'></span>
                                             </label>
                                             <h3>{t(data.name)}</h3>
                                          </div>
                                       ))
                                    ) : (
                                       <p
                                          style={{ justifyContent: 'center', display: 'flex' }}
                                       >
                                          {t("No Data Found")}
                                       </p>
                                    )}

                                 </div>
                              </UncontrolledCollapse>
                           </div>
                           <div
                              class='d-flex align-items-center justify-content-between hdfil'
                              id='collapse2'
                              style={{ cursor: 'pointer' }}
                           >
                              <h2>{t('Chains')}</h2>
                              <img class='rntar' src={angleDown} alt='' />
                           </div>
                           <div className="hdfil">
                              <UncontrolledCollapse toggler='#collapse2'>
                                 <div class='bxnflt'>
                                    {Chains &&
                                       Chains.map((data, index) => (
                                          <div class='d-flex align-items-center mb-1 justify-content-between'>
                                             <div class='d-flex align-items-center '>
                                                <label
                                                   class='cuschk mr-2'
                                                   For={`checkbx1${index + 1}`}
                                                >
                                                   <input
                                                      type='checkbox'
                                                      id={`checkbx1${index + 1}`}
                                                      name='chains'
                                                      checked={
                                                         checkcollectedChain?.length > 0
                                                            ? checkcollectedChain.includes(data.value)
                                                            : false
                                                      }
                                                      onChange={() => handleCheckboxChange(data)}
                                                   />
                                                   <span class='checkmark'></span>
                                                </label>
                                                <h3 style={{ textTransform: "capitalize" }}>
                                                   {' '}
                                                   <span class='mr-1'>
                                                      <img
                                                         src={
                                                            data.value == 'binance'
                                                               ? sm1
                                                               : data.value == 'ethereum'
                                                                  ? sm2
                                                                  : data.value == 'polygon'
                                                                     ? sm3
                                                                     : ''
                                                         }
                                                         className='mr-1'
                                                         width='20px'
                                                      />
                                                   </span>
                                                   {data.value}
                                                </h3>
                                             </div>
                                             <h3>{data.name}</h3>
                                          </div>
                                       ))}

                                 </div>
                              </UncontrolledCollapse>
                           </div>
                        </div>
                     </div>


                  </div>
               </div>
            </div>
         </section >
      </>
   )
}
export default Bridge
