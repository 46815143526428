import { useMutation } from 'wagmi'
import { useQueryClient } from '@tanstack/react-query'

import DataService from '../../../lib/DataService'

const {  postRequest } = DataService()

function makeCollectionOffer(data) {
	return postRequest('collection/make-offer', { ...data })
}

export function useCollectionMakeOffer() {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: makeCollectionOffer,
		onSuccess: () => {
		}
	})
}
