import React, { useEffect, useState } from 'react';
import './Settings.scss';
import { axios } from '../../lib/axios'
import { useToast } from '../../utils/toast/customSuccess'
import {
  useAccount,
} from 'wagmi'
import { NumericFormat } from 'react-number-format'
import {
  Input,
} from 'reactstrap'
import { useTranslation } from 'react-i18next';
import DataService from '../../lib/DataService';
export const SettingsNotification = () => {

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const { address, isConnected } = useAccount()

  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);
  const { postRequest } = DataService()



  const toast = useToast()
  const { t } = useTranslation()

  useEffect(() => {
    getNotification()
  }, [])

  const [getallnotify, setGetAllNotify] = useState("")

  const getNotification = async () => {
    const fetch = await postRequest("user/get_notifications", { "address": address })
    if (fetch.status == true) {
      setGetAllNotify(fetch.data[0])

    }
  }


  const updateNotification = async (notificationType, value) => {
    const fetch = await postRequest("user/update_Notifications", { [notificationType]: value, "address": address });
    if (fetch.status === true) {
      toast.success(fetch.message);
      getNotification();
    } else {
      toast.error(fetch.message);
    }
  };

  const itemSoldChange = async (e) => {
    await updateNotification("itemSold", e.target.checked);
  };

  const bidActivityChange = async (e) => {
    await updateNotification("bidActivity", e.target.checked);
  };

  const priceChange = async (e) => {
    await updateNotification("priceChange", e.target.checked);
  };

  const auctionExpiration = async (e) => {
    await updateNotification("auctionExpiration", e.target.checked);
  };

  const outbid = async (e) => {
    await updateNotification("outbid", e.target.checked);
  };

  const ownedItemUpdates = async (e) => {
    await updateNotification("ownedItemUpdates", e.target.checked);
  };

  const successfulPurchase = async (e) => {
    await updateNotification("successfulPurchase", e.target.checked);
  };

  const successfulMint = async (e) => {
    await updateNotification("successfulMint", e.target.checked);
  };

  const [mintheresold, setmintheresold] = useState("")
  const minimumBidThreshold = async (e) => {
    e.preventDefault()
    const fetch = await postRequest("user/update_Notifications", { "minimumBidThreshold": mintheresold, "address": address })
    if (fetch.status == true) {
      toast.success(fetch.message)
    }
    else {
      toast.error(fetch.message)
    }
  }

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <h3 className="settingsHeading mb-0">{t("Notifications")}</h3>
      </div>
      <div className="createCollectionCard mb-3">
        <form className="w-100">
          <p className="greyTxt mb-3">{t("Select which notifications you would like to receive")}.</p>
          <div className="form-row">
            <div className="col-12 mb-3">
              <div className="freezeCnt">
                <div>
                  <span className="formLabel">{t("Item Sold")}</span>
                  <p>{t("When someone purchased one of your items")}</p>
                </div>
                <div className="ml-auto">
                  <label className="switch">
                    <input type="checkbox" onChange={itemSoldChange} checked={getallnotify && getallnotify.itemSold == true ? true : false} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>


            <div className="col-12 mb-3">
              <div className="freezeCnt">
                <div>
                  <span className="formLabel">{t("Bid Activity")}</span>
                  <p>{t("When someone bids on one of your items")}</p>
                </div>
                <div className="ml-auto">
                  <label className="switch">
                    <input type="checkbox" onChange={bidActivityChange} checked={getallnotify && getallnotify.bidActivity == true ? true : false} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>

            <div className="col-12 mb-3">
              <div className="freezeCnt">
                <div>
                  <span className="formLabel">{t("Price Change")}</span>
                  <p>{t("When an item you made an offer on changes in price")}</p>
                </div>
                <div className="ml-auto">
                  <label className="switch">
                    <input type="checkbox" onChange={priceChange} checked={getallnotify && getallnotify.priceChange == true ? true : false} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>

            <div className="col-12 mb-3">
              <div className="freezeCnt">
                <div>
                  <span className="formLabel">{t("Auction Expiration")}</span>
                  <p>{t("When a timed auction you created ends")}</p>
                </div>
                <div className="ml-auto">
                  <label className="switch">
                    <input type="checkbox" onChange={auctionExpiration} checked={getallnotify && getallnotify.auctionExpiration == true ? true : false} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>

            <div className="col-12 mb-3">
              <div className="freezeCnt">
                <div>
                  <span className="formLabel">{t("Outbid")}</span>
                  <p>{t("When an offer you placed is exceeded by another user")}</p>
                </div>
                <div className="ml-auto">
                  <label className="switch">
                    <input type="checkbox" onChange={outbid} checked={getallnotify && getallnotify.outbid == true ? true : false} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>

            <div className="col-12 mb-3">
              <div className="freezeCnt">
                <div>
                  <span className="formLabel">{t("Owned Item Updates")}</span>
                  <p>{t("When a significant update occurs for one of the items you have purchased on WARRIOR")}.</p>
                </div>
                <div className="ml-auto">
                  <label className="switch">
                    <input type="checkbox" onChange={ownedItemUpdates} checked={getallnotify && getallnotify.ownedItemUpdates == true ? true : false} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>

            <div className="col-12 mb-3">
              <div className="freezeCnt">
                <div>
                  <span className="formLabel">{t("Successful Purchase")}</span>
                  <p>{t("When you successfully buy an item")}</p>
                </div>
                <div className="ml-auto">
                  <label className="switch">
                    <input type="checkbox" onChange={successfulPurchase} checked={getallnotify && getallnotify.successfulPurchase == true ? true : false} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>

            <div className="col-12 mb-3">
              <div className="freezeCnt">
                <div>
                  <span className="formLabel">{t("Successful Mint")}</span>
                  <p>{t("When you successfully mint an item")}</p>
                </div>
                <div className="ml-auto">
                  <label className="switch">
                    <input type="checkbox" onChange={successfulMint} checked={getallnotify && getallnotify.successfulMint == true ? true : false} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>

            <hr className="w-100" />

            <div className="col-12 mb-3">
              <span className="formLabel">{t("Minimum Bid Threshold")}</span>
              <p className="greyTxt">{t("Receive notifications only when you receive offers with a value greater than or equal to this amount of ETH")}.</p>
              <div className="input-group verified">
                <NumericFormat className="form-control" onChange={(e) => setmintheresold(e.target.value)} value={getallnotify && getallnotify.minimumBidThreshold} allowNegative={false} customInput={Input} />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="greyTxt">ETH</span>
                  </div>
                </div>
                {mintheresold ?
                  <button className="btn gradientBtn ml-2" onClick={minimumBidThreshold}>{t("Save")}</button>
                  :
                  <button className="btn gradientBtn ml-2" disabled>{t("Save")}</button>

                }
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default SettingsNotification;