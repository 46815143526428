import React, { useContext, useEffect, useState } from 'react';

import '../../../assets/scss/filtersection.scss'
import { Button, Collapse, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import yellowTick from '../../../assets/images/collection/yellow-tick_20px.svg';
import { ThemeContext } from '../../context/context';
import {
    useAccount,

} from 'wagmi'
import { useToast } from '../../../utils/toast/customSuccess';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { NumericFormat } from 'react-number-format'
import defaultImage from '../../../assets/images/homeExplorer/placeholder.png'
import { handleImageError } from '../../../hooks/useHandleImg';
import { nativeCoinMap } from '../../../utils/getFilterData';
import DataService from '../../../lib/DataService';
const FilterActivitySection = (props) => {

    const { t } = useTranslation()

    const [isOpenCollapse1, setIsOpenCollapse1] = useState(false);
    const toggleCollapse1 = () => {
        setIsOpenCollapse1(!isOpenCollapse1);
    }
    const [isOpenCollapse2, setIsOpenCollapse2] = useState(false);
    const toggleCollapse2 = () => {
        setIsOpenCollapse2(!isOpenCollapse2);
    }
    const { postRequest } = DataService()

    const { address, isConnected } = useAccount()

    const toast = useToast()
    const Chains = [

        {
            id: 1,
            name: "BNB",
            value: "binance",
        },
        {
            id: 2,
            name: "ETH",
            value: "ethereum",
        },
        {
            id: 3,
            name: "MATIC",
            value: "polygon",
        }
    ]



    const { id } = useParams()

    useEffect(() => {
        GetFilterCollection()
    }, [address, id])

    const { collection, setCollection, chooselistingchain, setListingChain, minAmount, setMinAmount, maxAmount, setMaxAmount, status, setStatus, singlecollectionList, setSingleCollectionList } = useContext(ThemeContext)

    const GetFilterCollection = async () => {
        const getcollection = await postRequest("user/getListedCollection", { "address": id ? id : address })
        if (getcollection.status == true) {
            setCollection(getcollection.data)
        }
        else {
            setCollection('')
        }
    }



    const Apply = () => {

        if (Number(maxAmount) > 0) {
            if (Number(minAmount) > Number(maxAmount)) {
                toast.error("Minimum must be less than maximum")
                setMinAmount("")
                setMaxAmount("")
            }
            else {
                setStatus(true)
            }
        }
        else {
            setStatus(true)
        }
    }

    const handleOnKeyDown = async (e) => {
        if (e.key == 'Enter' || e.key == "") {
            const searchcollection = await postRequest("user/getListedCollection", { "address": id ? id : address, "filter": e.target.value })
            if (searchcollection.status == true) {
                setCollection(searchcollection.data)
            }
            else {
                setCollection("")
            }
        }
    }


    const singlelistingcollection = (data) => {
        let a = singlecollectionList.includes(data.collectionName)
        if (a == true) {
            const index = singlecollectionList.indexOf(data.collectionName);
            if (index > -1) {
                singlecollectionList.splice(index, 1);
                const nerArr = [...singlecollectionList]
                setSingleCollectionList(nerArr);
            }
        } else if (a == false) {
            setSingleCollectionList(
                [...singlecollectionList, data.collectionName]
            );
        }
    }


    return (
        <>
            <div className='filtercard'>
                <div>
                    <Button className='filterTit' onClick={toggleCollapse1}>
                        <span>{t("Collections")}</span>
                        <i className={`fas ${isOpenCollapse1 ? 'fa-angle-up' : 'fa-angle-down'}`} ></i>
                    </Button>
                    <Collapse isOpen={isOpenCollapse1}>
                        <div className='collapseCnt'>
                            <div className="form-group formInputs">
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText className='px-2'>
                                            <svg
                                                width="17"
                                                height="17"
                                                viewBox="0 0 17 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M15.875 14.6562C16.0312 14.8125 16.0312 15.0625 15.875 15.1875L15.1562 15.9062C15.0312 16.0625 14.7812 16.0625 14.625 15.9062L10.8438 12.125C10.7812 12.0312 10.75 11.9375 10.75 11.8438V11.4375C9.59375 12.4062 8.125 13 6.5 13C2.90625 13 0 10.0938 0 6.5C0 2.9375 2.90625 0 6.5 0C10.0625 0 13 2.9375 13 6.5C13 8.125 12.375 9.625 11.4062 10.75H11.8125C11.9062 10.75 12 10.8125 12.0938 10.875L15.875 14.6562ZM6.5 11.5C9.25 11.5 11.5 9.28125 11.5 6.5C11.5 3.75 9.25 1.5 6.5 1.5C3.71875 1.5 1.5 3.75 1.5 6.5C1.5 9.28125 3.71875 11.5 6.5 11.5Z"
                                                    fill="white"
                                                />
                                            </svg>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder={t("Search collection")} name="searchInput" onKeyDown={handleOnKeyDown} />
                                </InputGroup>
                            </div>
                            <div className='scroller htcheckbox'>
                                {collection && collection.length > 0 ?
                                    collection && collection.map((data, index) => (
                                        <div className={`${singlecollectionList.length > 0 ? singlecollectionList.includes(data.collectionName) ? 'h-verCollection' : "filterofferscard notrans mb-2" : "filterofferscard notrans mb-2"}`} onClick={() => singlelistingcollection(data)} onKeyDown={(e) => {
                                            if (e.key === 'Enter' || e.key === ' ') {
                                                singlelistingcollection(data)
                                            }
                                        }}
                                            role="button" tabIndex={0} style={{ cursor: "pointer" }}>
                                            <div className='d-flex align-items-center'>
                                                {!data?.logoImage?.startsWith(process.env.S3_URL) && !data?.logoImage?.startsWith('ipfs') && data?.logoImage !== null ? <img src={data?.logoImage ? data?.logoImage : defaultImage} className="mr-2" height="36" width="36" style={{ objectFit: "cover", borderRadius: "10px" }} onError={handleImageError} /> : <img src={defaultImage} className="mr-2" height="36" width="36" style={{ objectFit: "cover", borderRadius: "10px" }} />}
                                                <div>
                                                    <div className='fs-12 fw-600 dark-text'>
                                                        {data.collectionName}
                                                        <img src={data.verification == "Verified" ? yellowTick : ""} className="ml-1" />
                                                    </div>
                                                    <div>{t("Listed")}: {data?.listedItems == "" ? 0 : data?.listedItems?.toString()}/{data?.totalItems?.toString()}</div>
                                                </div>
                                                <div className='text-right ml-auto'>
                                                    <div><span className="whiteTxt">{t("Floor")}:</span> {data.floorPrice == "0" ? 0 : parseFloat(data?.floorPrice?.toFixed(6))} {nativeCoinMap[data.chain]}</div>
                                                </div>
                                            </div>

                                        </div>
                                    ))
                                    :

                                    <p style={{ justifyContent: "center", display: "flex" }}>{t("No Data Found")}</p>

                                }
                            </div>
                        </div>
                    </Collapse>
                </div>
                <div>
                    <Button className='filterTit' onClick={toggleCollapse2}>
                        <span>{t("Price")} </span>
                        <i className={`fas ${isOpenCollapse2 ? 'fa-angle-up' : 'fa-angle-down'}`} ></i>
                    </Button>
                    <Collapse isOpen={isOpenCollapse2}>
                        <div className='collapseCnt'>
                            <div className='row align-items-center'>
                                <div className='col-12' style={{ minWidth: '96px' }}>
                                    <div className='form-group formInputs'>
                                        <Input type="select" onChange={(e) => setListingChain(e.target.value)}>
                                            <option selected value="">{t("Select")}</option>
                                            {Chains && Chains.map((data, index) => (
                                                <>
                                                    <option value={data.name} key={index}>{data.name}</option>
                                                </>
                                            ))}
                                        </Input>
                                    </div>
                                </div>

                                <div className='col'>
                                    <div className='form-group formInputs'>
                                        <NumericFormat className='form-control' placeholder={t('Min')} name="minamount" onChange={(e) => setMinAmount(e.target.value)} value={minAmount} allowNegative={false} customInput={Input} />
                                    </div>
                                </div>
                                <div className='col-auto text-center px-0 mb-3'>{t("to")}</div>
                                <div className='col'>
                                    <div className='form-group formInputs'>
                                        <NumericFormat className='form-control' placeholder={t('Max')} name="maxamount" onChange={(e) => setMaxAmount(e.target.value)} value={maxAmount} allowNegative={false} customInput={Input} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                {chooselistingchain && minAmount || maxAmount ?
                                    <button className='applybtn' onClick={Apply}>{t("Apply")}</button>
                                    :
                                    <button className='applybtn' disabled>{t("Apply")}</button>
                                }
                            </div>
                        </div>
                    </Collapse>
                </div>
            </div>
        </>
    );
}

export default FilterActivitySection;