import { create } from 'zustand'

const useBidsModalStore = create((set) => ({
	isOpen: false,
	actions: {
		toggleBidsModal: () =>
			set((state) => ({
				...state,
				isOpen: !state.isOpen
			}))
	}
}))

export const useBidsModalState = () =>
	useBidsModalStore(({ isOpen }) => ({
		isOpen
	}))

export const useBidsModalActions = () =>
	useBidsModalStore((state) => state.actions)
