import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Filters } from "./components/Filters";
import { ItemsViewTabs } from "./components/ItemsViewTabs";
import { useFetchCollectionTraits } from "./apis/fetchCollectionTraits";
import CollectionActivity from "./components/CollectionActivity";

import noItemfoundImage from "../../assets/images/collection/noItemfoundImage.svg";
import cnddsLight from '../../assets/images/cnddsLight.svg'

import { parseQueryStringToObject } from "../../utils/parseQueryStringToObject";
import { convertObjectToQueryString } from "../../utils/convertObjectToQueryString";
import { useTranslation } from "react-i18next";

export function NoItemsFound({ onClick }) {
  const { t } = useTranslation()

  return (
    <div className="noItemFound">
      <div className="text-center">
        <img
          src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : noItemfoundImage}
          alt="noItemfoundImage"
          className="img-fluid"
        />
      </div>
      <div className="nofounttxt mb-4">{t("Nothing to display")}.</div>
      <div>
        <button className="applyBtn" onClick={onClick}>
          {t("Back to all items")}
        </button>
      </div>
    </div>
  );
}

const flattenFilters = (filters, parentKey = "") => {
  return Object.entries(filters).reduce((acc, [key, value]) => {
    const currentKey = parentKey ? `${parentKey}[${key}]` : key;

    if (typeof value === "object" && value !== null) {
      return { ...acc, ...flattenFilters(value, currentKey) };
    } else {
      return { ...acc, [currentKey]: value };
    }
  }, {});
};

export function Activity(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState(
    parseQueryStringToObject(searchParams)
  );

  useEffect(() => {
    const filters = parseQueryStringToObject(searchParams)
    setFilters(filters)
  }, [setFilters, searchParams])

  const handleFiltersChange = (filters) => {
    setFilters(filters);
    const flattenedFilters = flattenFilters(filters);
    const queryString = convertObjectToQueryString(flattenedFilters);
    setSearchParams(queryString);
  };

  const [activeTab, setActiveTab] = useState("grid");
  const [showFilters, setShowFilters] = useState(true);

  const { data: traitsData } = useFetchCollectionTraits(
    props.collectionAddress
  );

  return (
    <Filters
      itemsViewTabsContent={
        <CollectionActivity
          activeTab={activeTab}
          showFilters={showFilters}
          collectionDetails={props.collectionDetails}
          filters={filters}
          handleFiltersChange={handleFiltersChange}
        />
      }
      showFilters={showFilters}
      setShowFilters={setShowFilters}
      traits={traitsData}
      filters={filters}
      handleFiltersChange={handleFiltersChange}
      collectionDetails={props.collectionDetails}
      tab={props.tab}
    >
      {props.tab === "3" ? null : (
        <ItemsViewTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        ></ItemsViewTabs>
      )}
    </Filters>
  );
}
