import { useQuery } from '@tanstack/react-query'

import { convertObjectToQueryString } from '../../../utils/convertObjectToQueryString'
import { flattenFilters } from '../../../utils/flattenFilters'
import DataService from '../../../lib/DataService'

const { getRequest } = DataService()
export async function fetchCollectionNfts(
	address,
	collectionAddress,
	filters,
	pageParam,
	callback
) {

	const flattenedFilters = flattenFilters(filters)
	const queryString = convertObjectToQueryString(flattenedFilters)

	const apiUrl = `collection/assets?userAddress=${address}&address=${collectionAddress}&page=${pageParam}&${queryString}`;

	const response = await getRequest(apiUrl);

	callback(response.count ?? 0)
	return response.data
}

export function useFetchCollectionNfts(address, collectionAddress) {
	return useQuery({

		queryKey: ['nfts', address, collectionAddress],
		queryFn: () => fetchCollectionNfts(address, collectionAddress),
		enabled: !!collectionAddress
	})
}
