import axios from 'axios'
import * as CryptoJS from 'crypto-js'
import { BACKEND_URL } from '../config/constants'

const environment = {
	dbvSTQDV6gwJkEv5IIBQA: '#wAiPfbNAbCWnc1DevkEy#', 
	apiUrl: BACKEND_URL 
}

const DataService = () => {
	const REST_API_SERVER = environment.apiUrl
	const token = localStorage.getItem('access-token')


	const getGen = (url, getTime) => {
		url = `${url}`
		const date = getTime
		const ex_date = Math.round(date / 1000) + 5
		const payload = {
			exp: ex_date, 
			isHuman: true
		}

		const token = signToken(payload, url)


		const encrtpted_token = CryptoJS.AES.encrypt(
			token,
			environment.dbvSTQDV6gwJkEv5IIBQA
		).toString()

		return { token: encrtpted_token }
	}

	const base64url = source => {
		let encodedSource = CryptoJS.enc.Base64.stringify(source)
		encodedSource = encodedSource.replace(/=+$/, '')
		encodedSource = encodedSource.replace(/\+/g, '-')
		encodedSource = encodedSource.replace(/\//g, '_')
		return encodedSource
	}

	const signToken = (payload, key) => {
		const secret = key
		let token = encodeToken(payload)
		let signature = CryptoJS.HmacSHA256(token, secret)
		signature = base64url(signature)
		const signedToken = `${token}.${signature}`
		return signedToken
	}

	const encodeToken = payload => {
		const header = {
			alg: 'HS256',
			typ: 'JWT'
		}
		const stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header))
		const encodedHeader = base64url(stringifiedHeader)
		const stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(payload))
		const encodedData = base64url(stringifiedData)
		const token = `${encodedHeader}.${encodedData}`
		return token
	}

	const handleError = error => {
		let errorMessage = 'Unknown error!'
		if (error.response) {
			errorMessage = `Error Code: ${error.response.status}\nMessage: ${error.response.data}`
		} else if (error.request) {
			errorMessage = 'No response received from server.'
		} else {
			errorMessage = `Error: ${error.message}`
		}
		console.error(errorMessage)
		return Promise.reject(errorMessage)
	}

	const doAnotherthing = async () => {
		const datetime = await axios.get(`${REST_API_SERVER}home/getCurrentTime`)
		return datetime.data
	}

	const getRequest = async url => {
		try {
			const datetime = await doAnotherthing()
			const tokenHandling = getGen(`${REST_API_SERVER}${url}`, datetime.data)
			const headers = {
				'cache-control': 'no-cache',
				'content-type': 'application/json',
				Authorization: `Bearer ${token}`,
				NvEzKhOaT: tokenHandling.token,
			}
			const response = await axios.get(`${REST_API_SERVER}${url}`, { headers })
			return response.data
		} catch (error) {
			return handleError(error)
		}
	}

	const postRequest = async (url, requestData) => {
		try {
			const datetime = await doAnotherthing()
			const tokenHandling = getGen(`${REST_API_SERVER}${url}`, datetime.data)
			const headers = {
				'cache-control': 'no-cache',
				'content-type': 'application/json',
				Authorization: `Bearer ${token}`,
				NvEzKhOaT: tokenHandling.token,
			}
			const response = await axios.post(
				`${REST_API_SERVER}${url}`,
				requestData,
				{ headers }
			)
			return response.data
		} catch (error) {
			return handleError(error)
		}
	}


	
	const postformdataRequest = async (url, formData) => {
		try {
			const datetime = await doAnotherthing();
			const tokenHandling = getGen(`${REST_API_SERVER}${url}`, datetime.data);
			const headers = {
				'cache-control': 'no-cache',
				Authorization: `Bearer ${token}`,
				NvEzKhOaT: tokenHandling.token,
			};

			const response = await axios.post(
				`${REST_API_SERVER}${url}`,
				formData,
				{
					headers: {
						...headers,
						'content-type': 'multipart/form-data',
					},
				}
			);
			return response.data;
		} catch (error) {
			return handleError(error);
		}
	};


	return {
		getRequest,
		postRequest,
		postformdataRequest
	}
}

export default DataService
