import { chainId } from './chains'

export default {
	bridge: {
		// [chainId.BINANCE]: '0x9F8A1Ae206e80c3325C263d359699336553FbbD4',
		// [chainId.ETHEREUM]: '0x975f794B4f54A7719C996EDa54Cb26e34eD8023F',
		// [chainId.POLYGON]: '0x6c1b9f8221e2f7fc7bd41e9f38f3a88b723e2d38'
		Eth_contract: "0x975f794b4f54a7719c996eda54cb26e34ed8023f",
		Bnb_contract: "0x9f8a1ae206e80c3325c263d359699336553fbbd4",
		Polygon_contract: "0x6c1b9f8221e2f7fc7bd41e9f38f3a88b723e2d38",
	},
	marketplace: {
		[chainId.BINANCE]: '0xa18a560909F4f3E28179a74C7AB2f60340Fd06cF',
		[chainId.ETHEREUM]: '0x2483C93Ab2a2BE334d4641cE372109f3600167Fb',
		[chainId.POLYGON]: '0x5fee32cdb740fb8f54eeed5d985b79bf5abf3e59'
	},
	Minimal_proxy:
	{
		Proxy_ETH: "0xb715b9fd8f656c51228e2fe3c34e4e24af5e0a36",
		Proxy_BNB: "0x4a4c4391a5e30f703fdfbaa271b01f07252122ca",
		Proxy_Polygon: "0x315d6e812c20b6c3fda32e49e703de124db666e8",

	},
	zero: '0x0000000000000000000000000000000000000000',
	usdt: '0x6C1b9f8221E2f7FC7Bd41E9f38f3a88B723E2d38',
	busd: '0x821581AD0C87B5E30cCfAbCc4f437fba38aA56C8',
	wor: '0xDD27DEf85B7Eb62A149d8627DFb0EDdFAC3A82E8',
	weth: '0x7b79995e5f793A07Bc00c21412e50Ecae098E7f9',
	wbnb: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
	wmatic: '0xec979cd18a20f48889c2b44cd7d8300282e35aa5'
}
