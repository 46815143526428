import { useCallback } from 'react'
import { useAccount } from 'wagmi'

import { useToast } from '../utils/toast/customSuccess'
import { PUBLIC_NODES } from '../config/nodes'
import { chainId } from '../config/constants/chains'

const chains = {
	[chainId.BINANCE]: 'Binance Testnet',
	[chainId.ETHEREUM]: 'Sepholia Testnet',
	[chainId.POLYGON]: 'Amoy Testnet',
	[chainId.AMOY]: 'Amoy Testnet'
} 

export const useOkxSwitchNetwork = () => {
	const { isConnected } = useAccount()
	const toast = useToast()

	const okxwallet = window.okxwallet

	const switchNetwork = useCallback(
		async (chainId) => {
			if (okxwallet) {
				try {
					await okxwallet.request({
						method: 'wallet_switchEthereumChain',
						params: [{ chainId: `0x${chainId.toString(16)}` }]
					})
				} catch (error) {
					if (error.code === 4902) {
						try {
							await okxwallet.request({
								method: 'wallet_addEthereumChain',
								params: [
									{
										chainId: `0x${chainId.toString(16)}`,
										rpcUrls: PUBLIC_NODES[chainId],
										chainName: chains[chainId]
									}
								]
							})
						} catch (error) {
						}
					}
				}
			}
		},
		[isConnected, toast]
	)

	return {
		switchNetwork
	}
}
