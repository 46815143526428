import { create } from 'zustand'

const useSomethingWenWrongModalStore = create((set) => ({
	isOpen: false,
	actions: {
		toggleSomethingWentWrongModal: () =>
			set((state) => ({
				...state,
				isOpen: !state.isOpen
			})),
		openSomethingWentWrongModal: () =>
			set((state) => ({
				...state,
				isOpen: true
			}))
	}
}))

export const useSomethingWentWrongModalState = () =>
	useSomethingWenWrongModalStore(({ isOpen }) => ({
		isOpen
	}))

export const useSomethingWentWrongModalActions = () =>
	useSomethingWenWrongModalStore((state) => state.actions)
