import React, { Fragment, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { formatAmount } from '../../utils/formatAmount'
import HomeBanner from '../../components/HomeBanner/HomeBanner'
import DefaultImage from '../../assets/images/homeExplorer/placeholder.png'
import {
	handleImageError
} from '../../hooks/useHandleImg'
import badgeCheck from '../../assets/images/badgeCheck.svg'
import ethIconSm from '../../assets/images/ethIconSm.png'
import maticIconSm from '../../assets/images/maticIconSm.png'
import bnbIconSm from '../../assets/images/bnbIconSm.png'
import clock from '../../assets/images/clock.svg'
import list from '../../assets/images/tag.png'
import cart from '../../assets/images/cart.png'
import angleDown from '../../assets/images/angleDown.svg'
import { useToast } from '../../utils/toast/customSuccess'
import noItemfoundImage from '../../assets/images/collection/noItemfoundImage.svg'
import cnddsLight from '../../assets/images/cnddsLight.svg'
import { useTranslation } from 'react-i18next'
import { UsefetchNotable, GetFetchViewCount, UsefetchFeatured } from './api'
import loader from '../../assets/images/Loader.png'
import { hoursToGo } from '../../utils/formatRoundTime'
import { nativeCoinMap } from '../../utils/getFilterData'
import DataService from '../../lib/DataService'
const Home = props => {
	const { className } = props
	const { t } = useTranslation()

	useEffect(() => {
		document.title = "Wor NFT"
	}, [])

	const { getRequest } = DataService()

	const [loading, setLoading] = useState(false)
	const [videoError, setVideoError] = useState(false)
	const [collections, setCollections] = useState(null)
	const [category, setCategory] = useState(null)
	const [fetchCollections, setFetchCollections] = useState(null)
	const [nfts, setNfts] = useState(null)
	const [activeCategoryTab, setActiveCategoryTab] = useState('All')
	const [activeTab3, setActiveTab3] = useState('1')
	const [getNetwork, setGetNetwork] = useState('all')
	const [getDays, setGetDays] = useState('7')
	const [getType, setGetType] = useState('trending')

	const { data: notable } = UsefetchNotable()

	const { data: featured } = UsefetchFeatured()

	const viewCountMutation = GetFetchViewCount()

	const [activeTab, setActiveTab] = useState('1')
	const toggle = tab => {
		if (activeTab !== tab) setActiveTab(tab)
	}

	const [activeTab1, setActiveTab1] = useState('4')
	const toggle1 = tab => {
		if (activeTab1 !== tab) setActiveTab1(tab)
	}

	const [activeTab2, setActiveTab2] = useState('1')
	const toggle2 = tab => {
		if (activeTab2 !== tab) setActiveTab2(tab)
	}

	let networkArr = [
		{
			chain: 'all',
			name: t('All Chains')
		},
		{
			chain: 'binance',
			name: t('Binance')
		},
		{
			chain: 'ethereum',
			name: t('Ethereum')
		},
		{
			chain: 'polygon',
			name: t('Polygon')
		}
	]

	function SamplePrevArrow(props) {
		const { className, style, onClick } = props
		return (
			<button type='button' class='slick-prev' onClick={onClick}>
				<img src={angleDown} alt='' class='img-fluid' />
			</button>
		)
	}

	function SampleNextArrow(props) {
		const { className, style, onClick } = props
		return (
			<button type='button' class='slick-next' onClick={onClick}>
				<img src={angleDown} alt='' class='img-fluid' />
			</button>
		)
	}

	const toast = useToast()

	const handleCollection = () => {
		navigate('/homecollection')
	}

	const handleActivity = () => {
		navigate('/activity')
	}

	var settings = {
		dots: false,
		infinite: !!fetchCollections && fetchCollections.length > 3,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		variableWidth: true,
		initialSlide: 0,
		centerMode: false,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
	}

	var settings1 = {
		dots: false,
		infinite: !!featured && featured?.data?.length > 3,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		variableWidth: true,
		initialSlide: 0,
		centerMode: false,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />
	}

	var settings2 = {
		dots: false,
		infinite: !!notable && notable?.data?.length > 3,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		variableWidth: true,
		initialSlide: 0,
		centerMode: false,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />
	}

	async function toggle3(tab) {
		setLoading(true)
		if (activeCategoryTab !== tab) setActiveCategoryTab(tab)
		try {
			if (tab === 'All') {
				tab = 'all';
			}
			const data = await getRequest(`home/getCategoryCollection?category=${tab}`);
			if (data.status == true) {
				setLoading(false);
				setFetchCollections(data.data);
			} else {
				setLoading(false);
				setFetchCollections('');
				throw new Error(data.message);
			}
		} catch (error) {
			setLoading(false)
		}
	}

	useEffect(() => {
		getCollections({ day: getDays, chain: networkArr[0].chain, type: getType })
		setGetNetwork(networkArr[0].chain)

		getNfts('all')
		getCategory()

		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}, [])

	const navigate = useNavigate()

	const handleNftRoute = async data => {
		navigate(`/collection/${data.slug}`)
	}

	const handleFeatured = async data => {
		navigate(`/collection/${data.slug}`)
	}

	const handleNotable = async data => {
		navigate(`/collection/${data.slug}`)
	}

	const handleTrending = async data => {
		navigate(`/collection/${data.slug}`)
	}

	async function getCategory() {
		setLoading(true)

		try {
			const data = await getRequest('home/categories')

			if (data.status == true) {
				setLoading(false)
				setCategory(data.data)
			} else {
				setLoading(false)
				setCategory('')
				throw new Error(data.message)
			}
		} catch (error) {
			setLoading(false)
		}
	}

	async function getCollections(item) {
		setLoading(true)

		try {
			const data = await getRequest(
				`home/collections?filter=${item.day}&chains=${item.chain}&type=${item.type}`
			)

			if (data.status == true) {
				setLoading(false)
				setCollections(data.data)
			} else {
				setLoading(false)
				setCollections('')
				throw new Error(data.message)
			}
		} catch (error) {
			setLoading(false)
		}
	}

	async function getNfts(tab) {
		setLoading(true)

		try {
			const data = await getRequest(`home/nfts?filtertype=${tab}&page=0&size=10`)

			if (data.status == true) {
				setLoading(false)
				setNfts(data.data)
			} else {
				setLoading(false)
				setNfts('')
				throw new Error(data.message)
			}

			if (activeCategoryTab === 'All') {
				const data = await getRequest(
					`home/getCategoryCollection?category=All`
				)
				if (data.status == true) {
					setLoading(false)
					setFetchCollections(data.data)
				} else {
					setLoading(false)
					setFetchCollections('')
					throw new Error(data.message)
				}
			}
		} catch (error) {
			setLoading(false)
		}
	}

	const chainImageMap = {
		binance: bnbIconSm,
		polygon: maticIconSm,
		ethereum: ethIconSm
	}

	const coinMap = {
		binance: 'BNB',
		polygon: 'MATIC',
		ethereum: 'ETH'
	}

	const handleCount = async count => {
		await viewCountMutation.mutateAsync({ collectionName: count })
	}

	const handleVideoError = () => {
		setVideoError(true)
	}

	return (
		<Fragment>
			<div className={`text-center ${loading ? 'spinner' : 'd-none'}`}>
				<img src={loader} alt='' className='spinAnimate' />
			</div>

			<HomeBanner />
			<section className='collectionActivitySec'>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-xl-7'>
							<div className='card my-1'>
								<div className='d-flex align-items-center mb-3'>
									<h3 className='gradientHeading mb-0'>{t('Collections')}</h3>
									<button
										className='btn gradientBtn ml-auto'
										onClick={() => handleCollection()}
									>
										{t('View All')}
									</button>
								</div>
								<div className='d-flex align-items-center flex-wrap mb-2'>
									<Nav tabs className='mr-2 mr-sm-0 mb-3 mb-sm-0'>
										<NavItem>
											<NavLink
												className={classnames({
													active: activeTab === '1'
												})}
												onClick={() => {
													toggle('1')
													setGetType('trending')
													getCollections({
														day: getDays,
														chain: getNetwork,
														type: 'trending'
													})
												}}
											>
												{t('Trending')}
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({
													active: activeTab === '2'
												})}
												onClick={() => {
													toggle('2')
													setGetType('top')
													getCollections({
														day: getDays,
														chain: getNetwork,
														type: 'top'
													})
												}}
											>
												{t('Top')}
											</NavLink>
										</NavItem>
									</Nav>

									<Nav
										tabs
										className='ml-sm-auto checkTab mr-0 mr-sm-3 mb-3 mb-sm-0'
									>
										<NavItem>
											<NavLink
												className={classnames({
													active: activeTab1 === '1'
												})}
												onClick={() => {
													toggle1('1')
													setGetDays('1')
													getCollections({
														day: '1',
														chain: getNetwork,
														type: getType
													})
												}}
											>
												{t('1h')}
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({
													active: activeTab1 === '2'
												})}
												onClick={() => {
													toggle1('2')
													setGetDays('6')
													getCollections({
														day: '6',
														chain: getNetwork,
														type: getType
													})
												}}
											>
												{t('6h')}
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({
													active: activeTab1 === '3'
												})}
												onClick={() => {
													toggle1('3')
													setGetDays('24')
													getCollections({
														day: '24',
														chain: getNetwork,
														type: getType
													})
												}}
											>
												{t('24h')}
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({
													active: activeTab1 === '4'
												})}
												onClick={() => {
													toggle1('4')
													setGetDays('7')
													getCollections({
														day: '7',
														chain: getNetwork,
														type: getType
													})
												}}
											>
												{t('7d')}
											</NavLink>
										</NavItem>
										{getType == "top" ?
											<>
												<NavItem>
													<NavLink
														className={classnames({
															active: activeTab1 === '5'
														})}
														onClick={() => {
															toggle1('5')
															setGetDays('30')
															getCollections({
																day: '30',
																chain: getNetwork,
																type: getType
															})
														}}
													>
														{t('30d')}
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
														className={classnames({
															active: activeTab1 === '6'
														})}
														onClick={() => {
															toggle1('6')
															setGetDays('')
															getCollections({
																day: '',
																chain: getNetwork,
																type: getType
															})
														}}
													>
														{t('All')}
													</NavLink>
												</NavItem>
											</>
											:
											""
										}
									</Nav>
									<select
										className=''
										onChange={e => {
											setGetNetwork(e.target.value)
											getCollections({
												chain: e.target.value,
												day: getDays,
												type: getType
											})
										}}
									>
										{networkArr &&
											networkArr.map(item => (
												<option value={item.chain}>{item.name}</option>
											))}
									</select>
								</div>
								<div className='w-100 mt-4'>
									<TabContent activeTab={activeTab}>
										<TabPane tabId='1'>
											<div className='table-responsive'>
												<table className='table table-hover'>
													<thead>
														<tr>
															<th></th>
															<th>{t('Collections')}</th>
															<th className='text-right'>{t('Floor Price')}</th>
															<th className='text-right'>{t('Volume')}</th>
														</tr>
													</thead>
													<tbody>
														{collections && collections.length > 0 ? (
															collections
																.slice(0, 10)
																.map((collection, index) => (
																	<tr>
																		<td className='lgTxt ylwTxt altFont'>
																			{index + 1}
																		</td>
																		<td>
																			<div
																				className='d-flex align-items-center'
																				style={{ cursor: 'pointer' }}
																				onClick={() => {
																					handleCount(collection.collectionName)
																					handleTrending(collection)
																				}}
																				onKeyDown={(e) => {
																					if (e.key === 'Enter' || e.key === ' ') {
																						handleCount(collection.collectionName)
																						handleTrending(collection)
																					}
																				}}
																				role="button" tabIndex={0}
																			>
																				{!collection?.logoImage?.startsWith(
																					process.env.S3_URL
																				) &&
																					!collection?.logoImage?.startsWith(
																						'ipfs'
																					) &&
																					collection?.logoImage !== null ? (
																					<img
																						src={
																							collection?.logoImage !== ''
																								? collection?.logoImage
																								: DefaultImage
																						}
																						className='itemIcon mr-3'
																						style={{
																							height: '60px',
																							aspectRatio: '1',
																							borderRadius: '8px'
																						}}
																						onError={handleImageError}
																					/>
																				) : (
																					<img
																						src={DefaultImage}
																						className='itemIcon mr-3'
																						style={{
																							height: '60px',
																							aspectRatio: '1',
																							borderRadius: '8px'
																						}}
																					/>
																				)}
																				<h3 className='mr-2'>
																					{collection?.collectionName}
																				</h3>
																				<img
																					src={chainImageMap[collection.chain]}
																					className='mr-2'
																					height='20'
																				/>
																			</div>
																		</td>
																		<td className='text-right'>
																			<div className='d-flex flex-column'>
																				<div className=''>
																					{collection?.floorPrice == '0'
																						? 0
																						: parseFloat(collection?.floorPrice.toFixed(
																							8
																						))}{' '}
																					{collection?.floorPrice == 0 ? coinMap[collection.chain] : collection.floorCurrency}
																				</div>
																				<div
																					className={
																						collection.isFloorUp
																							? 'smTxt text-success boldTxt'
																							: 'smTxt text-danger boldTxt'
																					}
																				>
																					{collection?.floorChange}
																				</div>
																			</div>
																		</td>
																		<td className='text-right'>
																			<div className='d-flex flex-column'>
																				<div className=''>
																					{collection.volume == '0'
																						? 0
																						: formatAmount(collection.volume?.toFixed(8))}{' '}
																					{coinMap[collection.chain]}
																				</div>
																				<div
																					className={
																						collection.isVolumeUp
																							? 'smTxt text-success boldTxt'
																							: 'smTxt text-danger boldTxt'
																					}
																				>
																					{collection.volumeChange}
																				</div>
																			</div>
																		</td>
																	</tr>
																))
														) : (
															<tr>
																{loading == false ? (
																	<td colSpan={4}>
																		<div className='noItemFound'>
																			<div className='text-center'>
																				<img
																					src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : noItemfoundImage}
																					alt='noItemfoundImage'
																					className='img-fluid'
																				/>
																			</div>
																			<div className='nofounttxt mb-4'>
																				{t('Nothing to display.')}
																			</div>
																		</div>
																	</td>
																) : (
																	''
																)}
															</tr>
														)}
													</tbody>
												</table>
											</div>
										</TabPane>
										<TabPane tabId='2'>
											<div className='table-responsive'>
												<table className='table table-hover'>
													<thead>
														<tr>
															<th></th>
															<th>{t('Collections')}</th>
															<th className='text-right'>{t('Floor Price')}</th>
															<th className='text-right'>{t('Volume')}</th>
														</tr>
													</thead>
													<tbody>
														{collections && collections.length > 0 ? (
															collections
																.slice(0, 10)
																.map((collection, index) => (
																	<tr>
																		<td className='lgTxt ylwTxt altFont'>
																			{index + 1}
																		</td>
																		<td>
																			<div
																				className='d-flex align-items-center'
																				style={{ cursor: 'pointer' }}
																				onClick={() => {
																					handleCount(collection.collectionName)
																					handleTrending(collection)
																				}}
																				onKeyDown={(e) => {
																					if (e.key === 'Enter' || e.key === ' ') {
																						handleCount(collection.collectionName)
																						handleTrending(collection)
																					}
																				}}
																				role="button" tabIndex={0}
																			>
																				{!collection?.logoImage?.startsWith(
																					process.env.S3_URL
																				) &&
																					!collection?.logoImage?.startsWith(
																						'ipfs'
																					) &&
																					collection?.logoImage !== null ? (
																					<img
																						src={
																							collection?.logoImage !== '' &&
																								collection?.logoImage !== null
																								? collection?.logoImage
																								: DefaultImage
																						}
																						className='itemIcon mr-3'
																						style={{
																							height: '60px',
																							aspectRatio: '1',
																							borderRadius: '8px'
																						}}
																						onError={handleImageError}
																					/>
																				) : (
																					<img
																						src={DefaultImage}
																						className='itemIcon mr-3'
																						style={{
																							height: '60px',
																							aspectRatio: '1',
																							borderRadius: '8px'
																						}}
																					/>
																				)}
																				<h3 className='mr-2'>
																					{collection.collectionName}
																				</h3>
																				<img
																					src={chainImageMap[collection.chain]}
																					className='mr-2'
																					height='20'
																				/>
																			</div>
																		</td>
																		<td className='text-right'>
																			<div className='d-flex flex-column'>
																				<div className=''>
																					{collection?.floorPrice == '0'
																						? 0
																						: parseFloat(
																							collection?.floorPrice.toFixed(8)
																						)}{' '}
																					{collection?.floorPrice == 0 ? coinMap[collection.chain] : collection.floorCurrency}
																				</div>
																				<div
																					className={
																						collection.isFloorUp
																							? 'smTxt text-success boldTxt'
																							: 'smTxt text-danger boldTxt'
																					}
																				>
																					{collection?.floorChange}
																				</div>
																			</div>
																		</td>
																		<td className='text-right'>
																			<div className='d-flex flex-column'>
																				<div className=''>
																					{collection.volume == '0'
																						? 0
																						:
																						formatAmount(collection.volume?.toFixed(8))
																					}{' '}
																					{coinMap[collection.chain]}
																				</div>
																				<div
																					className={
																						collection.isVolumeUp
																							? 'smTxt text-success boldTxt'
																							: 'smTxt text-danger boldTxt'
																					}
																				>
																					{collection.volumeChange}
																				</div>
																			</div>
																		</td>
																	</tr>
																))
														) : (
															<tr>
																{loading == false ? (
																	<td colSpan={4}>
																		<div className='noItemFound'>
																			<div className='text-center'>
																				<img
																					src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : noItemfoundImage}
																					alt='noItemfoundImage'
																					className='img-fluid'
																				/>
																			</div>
																			<div className='nofounttxt mb-4'>
																				{t('Nothing to display.')}
																			</div>
																		</div>
																	</td>
																) : (
																	''
																)}
															</tr>
														)}
													</tbody>
												</table>
											</div>
										</TabPane>
									</TabContent>
								</div>
							</div>
						</div>
						<div className='col-xl-5'>
							<div className='card my-1'>
								<div className='d-flex align-items-center mb-3'>
									<h3 className='gradientHeading mb-0'>{t('Activity')}</h3>
									<button
										className='btn gradientBtn ml-auto'
										onClick={() => handleActivity()}
									>
										{t('View All')}
									</button>
								</div>
								<div className='d-flex align-items-center mb-2'>
									<Nav tabs className='threeTab'>
										<NavItem>
											<NavLink
												className={classnames({
													active: activeTab2 === '1'
												})}
												onClick={() => {
													toggle2('1')
													getNfts('all')
												}}
											>
												{t('All')}
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({
													active: activeTab2 === '2'
												})}
												onClick={() => {
													toggle2('2')
													getNfts('sales')
												}}
											>
												{t('Sales')}
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({
													active: activeTab2 === '3'
												})}
												onClick={() => {
													toggle2('3')
													getNfts('listings')
												}}
											>
												{t('Listings')}
											</NavLink>
										</NavItem>
									</Nav>
								</div>
								<div className='w-100 mt-4'>
									<TabContent activeTab={activeTab2}>
										<TabPane tabId='1'>
											<div className='table-responsive'>
												<table className='table table-hover'>
													<thead>
														<tr>
															<th></th>
															<th>{t('Items')}</th>
															<th className='text-right'>{t('Price')}</th>
															<th className='text-right'>
																<img src={clock} />
															</th>
														</tr>
													</thead>
													<tbody>
														{nfts && nfts.length > 0 ? (
															nfts.slice(0, 10).map(nft => (
																<>
																	<tr
																		onClick={() =>
																			navigate(
																				`/assets/${nft.chain}/${nft.contractAddress}/${nft.nftId}`
																			)
																		}
																		style={{ cursor: 'pointer' }}
																	>
																		<td>
																			<div className='d-flex align-items-center labelCnt'>
																				<img
																					src={nft.type == 'sale' ? cart : list}
																					className='mr-2'
																				/>
																				{t(nft.type)}
																			</div>
																		</td>
																		<td>
																			<div
																				className='d-flex align-items-center'
																				style={{ cursor: 'pointer' }}
																			>
																				{nft?.imageFormat &&
																					!nft?.asset?.startsWith(
																						process.env.S3_URL
																					) &&
																					!nft?.asset?.startsWith('ipfs') &&
																					nft?.imageFormat !== '' &&
																					nft?.asset !== null ? (
																					nft?.imageFormat &&
																						nft?.imageFormat.startsWith(
																							'image'
																						) ? (
																						<img
																							src={
																								nft?.asset !== ''
																									? nft?.asset
																									: DefaultImage
																							}
																							className='itemIcon mr-3'
																							style={{
																								height: '60px',
																								aspectRatio: '1',
																								borderRadius: '12px'
																							}}
																							onError={handleImageError}
																						/>
																					) : videoError ? (
																						<img
																							src={DefaultImage}
																							className='itemIcon mr-3'
																							style={{
																								height: '60px',
																								aspectRatio: '1',
																								borderRadius: '12px'
																							}}
																						/>
																					) : (
																						// <video
																						// 	src={nft?.asset}
																						// 	className='itemIcon mr-3'
																						// 	controlsList='nodownload'
																						// 	muted
																						// 	loop
																						// 	autoPlay
																						// 	style={{
																						// 		height: '60px',
																						// 		aspectRatio: '1',
																						// 		borderRadius: '12px',
																						// 		background: '#0a0a0b'
																						// 	}}
																						// 	onError={handleVideoError}
																						// />

																						<video loop autoPlay muted playsInline controlsList='nodownload' style={{
																							height: '60px',
																							aspectRatio: '1',
																							borderRadius: '12px',
																							background: '#0a0a0b'
																						}} className='itemIcon mr-3'>
																							<source src={nft?.asset} type="video/mp4" onError={handleVideoError} />
																						</video>
																					)
																				) : (
																					<img
																						src={DefaultImage}
																						className='itemIcon mr-3'
																						style={{
																							height: '60px',
																							aspectRatio: '1',
																							borderRadius: '12px'
																						}}
																					/>
																				)}

																				<div className='d-flex flex-column'>
																					<h3 className='mr-2'>
																						{nft?.nftName !== ''
																							? nft?.nftName
																							: `#${nft?.nftId}`}
																					</h3>
																					<div className='d-flex align-items-center smTxt gryTxt'>
																						{nft?.collectionName}
																					</div>
																				</div>
																			</div>
																		</td>
																		<td className='text-right'>
																			<div className='d-flex flex-column'>
																				<div className=''>
																					{parseFloat(nft?.price?.toFixed(8))}{' '}
																					{nft?.currency}
																				</div>
																				<div className='smTxt gryTxt'>
																					$
																					{parseFloat(
																						(
																							nft?.price * nft?.usdPrice
																						).toFixed(8)
																					)}
																				</div>
																			</div>
																		</td>
																		<td className='gryTxt text-right'>
																			{hoursToGo(nft?.createdAt)}
																		</td>
																	</tr>
																</>
															))
														) : (
															<tr>
																<td colSpan={4}>
																	<div className='noItemFound'>
																		<div className='text-center'>
																			<img
																				src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : noItemfoundImage}
																				alt='noItemfoundImage'
																				className='img-fluid'
																			/>
																		</div>
																		<div className='nofounttxt mb-4'>
																			{t('Nothing to display.')}
																		</div>
																	</div>
																</td>
															</tr>
														)}
													</tbody>
												</table>
											</div>
										</TabPane>
										<TabPane tabId='2'>
											<div className='table-responsive'>
												<table className='table table-hover'>
													<thead>
														<tr>
															<th></th>
															<th>{t('Items')}</th>
															<th className='text-right'>{t('Price')}</th>
															<th className='text-right'>
																<img src={clock} />
															</th>
														</tr>
													</thead>
													<>
														<tbody>
															{nfts && nfts.length > 0 ? (
																nfts.slice(0, 10).map(nft => (
																	<tr
																		onClick={() =>
																			navigate(
																				`/assets/${nft.chain}/${nft.contractAddress}/${nft.nftId}`
																			)
																		}
																		style={{ cursor: 'pointer' }}
																	>
																		<td>
																			<div className='d-flex align-items-center labelCnt'>
																				<img src={cart} className='mr-2' />
																				{t(nft.type)}
																			</div>
																		</td>

																		<td>
																			<div
																				className='d-flex align-items-center'
																				style={{ cursor: 'pointer' }}
																			>
																				{nft?.imageFormat &&
																					!nft?.asset?.startsWith(
																						process.env.S3_URL
																					) &&
																					!nft?.asset?.startsWith('ipfs') &&
																					nft?.imageFormat !== '' &&
																					nft?.asset !== null ? (
																					nft?.imageFormat &&
																						nft?.imageFormat?.startsWith(
																							'image'
																						) ? (
																						<img
																							src={
																								nft?.asset !== ''
																									? nft?.asset
																									: DefaultImage
																							}
																							className='itemIcon mr-3'
																							style={{
																								height: '60px',
																								aspectRatio: '1',
																								borderRadius: '12px'
																							}}
																							onError={handleImageError}
																						/>
																					) : videoError ? (
																						<img
																							src={DefaultImage}
																							className='itemIcon mr-3'
																							style={{
																								height: '60px',
																								aspectRatio: '1',
																								borderRadius: '12px'
																							}}
																						/>
																					) : (
																						// <video
																						// 	src={nft?.asset}
																						// 	className='itemIcon mr-3'
																						// 	controlsList='nodownload'
																						// 	muted
																						// 	loop
																						// 	autoPlay
																						// 	style={{
																						// 		height: '60px',
																						// 		aspectRatio: '1',
																						// 		borderRadius: '12px',
																						// 		background: '#0a0a0b'
																						// 	}}
																						// 	onError={handleVideoError}
																						// />

																						<video loop autoPlay muted playsInline controlsList='nodownload' style={{
																							height: '60px',
																							aspectRatio: '1',
																							borderRadius: '12px',
																							background: '#0a0a0b'
																						}} className='itemIcon mr-3'>
																							<source src={nft?.asset} type="video/mp4" onError={handleVideoError} />
																						</video>
																					)
																				) : (
																					<img
																						src={DefaultImage}
																						className='itemIcon mr-3'
																						style={{
																							height: '60px',
																							aspectRatio: '1',
																							borderRadius: '12px'
																						}}
																					/>
																				)}

																				<div className='d-flex flex-column'>
																					<h3 className='mr-2'>
																						{nft?.nftName !== ''
																							? nft?.nftName
																							: `#${nft?.nftId}`}
																					</h3>
																					<div className='d-flex align-items-center smTxt gryTxt'>
																						{nft?.collectionName}
																					</div>
																				</div>
																			</div>
																		</td>
																		<td className='text-right'>
																			<div className='d-flex flex-column'>
																				<div className=''>
																					{parseFloat(nft?.price.toFixed(8))}{' '}
																					{coinMap[nft?.chain]}
																				</div>

																				<div className='smTxt gryTxt'>
																					$
																					{parseFloat(
																						(
																							nft?.price * nft?.usdPrice
																						).toFixed(8)
																					)}
																				</div>
																			</div>
																		</td>
																		<td className='gryTxt text-right'>
																			{hoursToGo(nft?.createdAt)}
																		</td>
																	</tr>
																))
															) : (
																<tr>
																	{loading == false ? (
																		<td colSpan={4}>
																			<div className='noItemFound'>
																				<div className='text-center'>
																					<img
																						src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : noItemfoundImage}
																						alt='noItemfoundImage'
																						className='img-fluid'
																					/>
																				</div>
																				<div className='nofounttxt mb-4'>
																					{t('Nothing to display.')}
																				</div>
																			</div>
																		</td>
																	) : (
																		''
																	)}
																</tr>
															)}
														</tbody>
													</>
												</table>
											</div>
										</TabPane>
										<TabPane tabId='3'>
											<div className='table-responsive'>
												<table className='table table-hover'>
													<thead>
														<tr>
															<th></th>
															<th>{t('Items')}</th>
															<th className='text-right'>{t('Price')}</th>
															<th className='text-right'>
																<img src={clock} />
															</th>
														</tr>
													</thead>
													<>
														<tbody>
															{nfts && nfts.length > 0 ? (
																nfts.slice(0, 10).map(nft => (
																	<tr
																		onClick={() =>
																			navigate(
																				`/assets/${nft.chain}/${nft.contractAddress}/${nft.nftId}`
																			)
																		}
																		style={{ cursor: 'pointer' }}
																	>
																		<td>
																			<div className='d-flex align-items-center labelCnt'>
																				<img src={list} className='mr-2' />
																				{t(nft.type)}
																			</div>
																		</td>
																		<td>
																			<div
																				className='d-flex align-items-center'
																				style={{ cursor: 'pointer' }}
																			>
																				{nft?.imageFormat &&
																					!nft?.asset?.startsWith(
																						process.env.S3_URL
																					) &&
																					!nft?.asset?.startsWith('ipfs') &&
																					nft?.imageFormat !== '' &&
																					nft?.asset !== null ? (
																					nft?.imageFormat &&
																						nft?.imageFormat?.startsWith(
																							'image'
																						) ? (
																						<img
																							src={
																								nft?.asset !== ''
																									? nft?.asset
																									: DefaultImage
																							}
																							className='itemIcon mr-3'
																							style={{
																								height: '60px',
																								aspectRatio: '1',
																								borderRadius: '12px',
																								cursor: 'pointer'
																							}}
																							onError={handleImageError}
																						/>
																					) : videoError ? (
																						<img
																							src={DefaultImage}
																							className='itemIcon mr-3'
																							style={{
																								height: '60px',
																								aspectRatio: '1',
																								borderRadius: '12px',
																								cursor: 'pointer'
																							}}
																						/>
																					) : (

																						<video loop autoPlay muted playsInline controlsList='nodownload' style={{
																							height: '60px',
																							aspectRatio: '1',
																							borderRadius: '12px',
																							background: '#0a0a0b',
																							cursor: 'pointer'
																						}} className='itemIcon mr-3'>
																							<source src={nft?.asset} type="video/mp4" onError={handleVideoError} />
																						</video>
																					)
																				) : (
																					<img
																						src={DefaultImage}
																						className='itemIcon mr-3'
																						style={{
																							height: '60px',
																							aspectRatio: '1',
																							cursor: 'pointer',
																							borderRadius: '12px'
																						}}
																					/>
																				)}

																				<div className='d-flex flex-column'>
																					<h3 className='mr-2'>
																						{nft?.nftName !== ''
																							? nft?.nftName
																							: `#${nft?.nftId}`}
																					</h3>
																					<div className='d-flex align-items-center smTxt gryTxt'>
																						{nft?.collectionName}
																					</div>
																				</div>
																			</div>
																		</td>
																		<td className='text-right'>
																			<div className='d-flex flex-column'>
																				<div className=''>
																					{parseFloat(nft?.price.toFixed(8))}{' '}
																					{coinMap[nft?.chain]}
																				</div>
																				<div className='smTxt gryTxt'>
																					$
																					{parseFloat(
																						(
																							nft?.price * nft?.usdPrice
																						).toFixed(8)
																					)}
																				</div>
																			</div>
																		</td>
																		<td className='gryTxt text-right'>
																			{hoursToGo(nft?.createdAt)}
																		</td>
																	</tr>
																))
															) : (
																<tr>
																	{loading == false ? (
																		<td colSpan={4}>
																			<div className='noItemFound'>
																				<div className='text-center'>
																					<img
																						src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : noItemfoundImage}
																						alt='noItemfoundImage'
																						className='img-fluid'
																					/>
																				</div>
																				<div className='nofounttxt mb-4'>
																					{t('Nothing to display.')}
																				</div>
																			</div>
																		</td>
																	) : (
																		''
																	)}
																</tr>
															)}
														</tbody>
													</>
												</table>
											</div>
										</TabPane>
									</TabContent>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='tabSliderSec'>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-12'>
							<Nav tabs>
								<NavItem>
									<NavLink
										className={classnames({
											active: activeCategoryTab === 'All'
										})}
										onClick={() => {
											toggle3('All')
										}}
									>
										{t('All')}
									</NavLink>
								</NavItem>
								{category &&
									category.map((category, index) => (
										<NavItem>
											<NavLink
												className={classnames({
													active: activeCategoryTab === category?.name
												})}
												onClick={() => {
													toggle3(category?.name)
												}}
											>
												{t(category?.name)}
											</NavLink>
										</NavItem>
									))}
							</Nav>
						</div>
					</div>
				</div>
				<div className='mt-36 ml-xl-5 ml-3'>
					<TabContent activeTab={activeCategoryTab}>
						<TabPane tabId={activeCategoryTab}>
							{fetchCollections && fetchCollections?.length > 0 ?
								<Slider {...settings}>
									{
										fetchCollections && fetchCollections?.map((collection, index) => (
											<div
												className='card'
												style={{ cursor: 'pointer' }}
												onClick={() => {
													handleNftRoute(collection)
													handleCount(collection.collectionName)
												}}
												onKeyDown={(e) => {
													if (e.key === 'Enter' || e.key === ' ') {
														handleNftRoute(collection)
														handleCount(collection.collectionName)
													}
												}}
												role="button" tabIndex={0}
											>
												{!collection?.logoImage?.startsWith(process.env.S3_URL) &&
													!collection?.logoImage?.startsWith('ipfs') &&
													collection?.logoImage !== null ? (
													<img
														src={
															collection?.logoImage !== ''
																? collection?.logoImage
																: DefaultImage
														}
														className='banner'
														style={{ cursor: 'pointer' }}
														alt=''
														onError={handleImageError}
													/>
												) : (
													<img src={DefaultImage} className='banner' alt='' />
												)}
												<div className='infoCnt'>
													<div className='d-flex align-items-center mb-1'>
														<h3 className='heading'>
															{collection?.collectionName}
														</h3>
														{collection?.verification === 'Verified' && (
															<img
																src={badgeCheck}
																className='mr-2'
																height='20'
															/>
														)}
													</div>
													<div className='d-flex align-items-center flex-wrap'>
														<div className='valueCnt my-1'>
															{t('Volume')}{' '}
															<span>
																{collection.volume == '0'
																	? 0
																	: (formatAmount(collection?.volume?.toFixed(8)))}{' '}
																{collection.chain == 'ethereum'
																	? t('ETH')
																	: collection.chain == 'polygon'
																		? t('MATIC')
																		: collection.chain == 'binance'
																			? t('BNB')
																			: null}
															</span>
														</div>
														<div className='valueCnt my-1 ml-auto'>
															{t('Floor')}{' '}
															<span>
																{collection?.floorPrice == '0'
																	? 0
																	: parseFloat(
																		collection?.floorPrice?.toFixed(8)
																	)}{' '}
																{collection?.floorPrice == 0 ? collection.chain == 'ethereum'
																	? t('ETH')
																	: collection.chain == 'polygon'
																		? t('MATIC')
																		: collection.chain == 'binance'
																			? t('BNB')
																			: null : collection.floorCurrency}
															</span>
														</div>
													</div>
												</div>
											</div>
										))
									}
								</Slider>
								:
								<div className='noItemFound'>
									{loading == true ? (
										''
									) : (
										<>
											<div className='text-center'>
												<img
													src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : noItemfoundImage}
													alt='noItemfoundImage'
													className='img-fluid'
												/>
											</div>
											<div className='nofounttxt mb-4'>
												{t('Nothing to display.')}
											</div>
										</>
									)}
								</div>
							}
						</TabPane>
					</TabContent>
				</div>
			</section>

			<section className='FeaturedSec'>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-12'>
							<h3 className='gradientHeading'>{t('Featured')}.</h3>
						</div>
					</div>
				</div>
				<div className='ml-xl-5 ml-3'>
					{featured && featured?.data?.length > 0 ? (
						<Slider {...settings1}>
							{featured?.data?.map(item => (
								<div
									className='card'
									style={{ cursor: 'pointer' }}
									onClick={() => {
										handleCount(item.collectionName)
										handleFeatured(item)
									}}
									onKeyDown={(e) => {
										if (e.key === 'Enter' || e.key === ' ') {
											handleCount(item.collectionName)
											handleFeatured(item)
										}
									}}
									role="button" tabIndex={0}
								>
									{(item?.imageFormat &&
										item?.imageFormat?.startsWith('image')) ||
										item?.imageFormat == '' ? (
										!item?.logoImage?.startsWith(process.env.S3_URL) &&
											!item?.logoImage?.startsWith('ipfs') &&
											item?.logoImage !== null ? (
											<img
												src={
													item?.bannerImage != 'undefined'
														? item?.bannerImage
														: DefaultImage
												}
												className='banner'
												style={{ cursor: 'pointer' }}
												alt='banner'
												onError={handleImageError}
											/>
										) : (
											<img src={DefaultImage} className='banner' alt='banner' style={{ cursor: 'pointer' }} />
										)
									) : videoError ? (
										<img src={DefaultImage} className='banner' alt='banner' style={{ cursor: 'pointer' }} />
									) : (

										<video loop autoPlay muted playsInline style={{ height: '314px' }} controlsList='nodownload' className='banner'>
											<source src={item?.bannerImage} type="video/mp4" onError={handleVideoError} style={{ cursor: 'pointer' }} />
										</video>
									)}
									<div className='infoCnt'>
										<div className='d-flex align-items-end mb-1 infoTop'>
											{!item?.logoImage?.startsWith(process.env.S3_URL) &&
												!item?.logoImage?.startsWith('ipfs') &&
												item?.logoImage !== null ? (
												<img
													src={
														item?.logoImage !== ''
															? item?.logoImage
															: DefaultImage
													}
													className='profPic mr-2'
													onError={handleImageError}
													style={{ cursor: 'pointer' }}
												/>
											) : (
												<img src={DefaultImage} className='profPic mr-2' style={{ cursor: 'pointer' }} />
											)}
											<div className='d-flex align-items-center mb-2'>
												<h3 className='heading'>{item?.collectionName}</h3>
												{item?.verification === 'Verified' && (
													<img src={badgeCheck} className='mr-2' height='20' />
												)}
											</div>
										</div>
										<div className='d-flex align-items-center flex-wrap'>
											<div className='valueCnt my-1'>
												{t('Volume')}{' '}
												<span>
													{item.volume == 0 ? 0 : (parseFloat(item.volume)?.toFixed(8))}{' '}
													{nativeCoinMap[item.chain]}
												</span>
											</div>
											<div className='valueCnt my-1 ml-auto'>
												{t('Floor')}{' '}
												<span>
													{parseFloat(item.floorPrice)}{' '}
													{item.floorPrice == 0 ? nativeCoinMap[item.chain] : item.floorCurrency}
												</span>
											</div>
										</div>
									</div>
								</div>
							))}
						</Slider>
					) : (
						<div className='noItemFound'>
							{loading == false ? (
								<>
									<div className='text-center'>
										<img
											src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : noItemfoundImage}
											alt='noItemfoundImage'
											className='img-fluid'
										/>
									</div>
									<div className='nofounttxt mb-4'>
										{t('Nothing to display.')}
									</div>
								</>
							) : (
								''
							)}
						</div>
					)}
				</div>
			</section>

			<section className='notableSec tabSliderSec'>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-12'>
							<h3 className='gradientHeading'>{t('Notable Collections')}.</h3>
						</div>
					</div>
				</div>
				<div className='ml-xl-5 ml-3'>
					{notable && notable?.data?.length > 0 ? (
						<Slider {...settings2}>
							{notable?.data?.map(item => (
								<div>
									<div
										className='card'
										style={{ cursor: 'pointer' }}
										onClick={() => handleNotable(item)}
										onKeyDown={(e) => {
											if (e.key === 'Enter' || e.key === ' ') {
												handleNotable(item)
											}
										}}
										role="button" tabIndex={0}
									>
										{!item?.logoImage?.startsWith(process.env.S3_URL) &&
											!item?.logoImage?.startsWith('ipfs') &&
											item?.logoImage !== null ? (
											<img
												src={
													item?.logoImage !== ''
														? item?.logoImage
														: DefaultImage
												}
												className='banner'
												style={{ cursor: 'pointer' }}
												onError={handleImageError}
											/>
										) : (
											<img src={DefaultImage} className='banner' style={{ cursor: 'pointer' }} />
										)}
										<div className='infoCnt'>
											<div className='d-flex align-items-center mb-1'>
												<h3 className='heading'>{item?.collectionName}</h3>
												{item?.verification === 'Verified' && (
													<img src={badgeCheck} className='mr-2' height='20' />
												)}
											</div>
											<div className='d-flex align-items-center flex-wrap'>
												<div className='valueCnt my-1'>
													{t('Volume')}{' '}
													<span>
														{item.volume == 0 ? 0 : parseFloat(item.volume.toFixed(5))}{' '}
														{nativeCoinMap[item.chain]}
													</span>
												</div>
												<div className='valueCnt my-1 ml-auto'>
													Floor{' '}
													<span>
														{item.floorPrice == 0
															? 0
															: parseFloat(item.floorPrice.toFixed(5))}{' '}
														{item.floorPrice == 0 ? nativeCoinMap[item.chain] : item.floorCurrency}
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							))}
						</Slider>
					) : (
						<div className='noItemFound'>
							{loading == false ? (
								<>
									<div className='text-center'>
										<img
											src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : noItemfoundImage}
											alt='noItemfoundImage'
											className='img-fluid'
										/>
									</div>
									<div className='nofounttxt mb-4'>
										{t('Nothing to display.')}
									</div>
								</>
							) : (
								''
							)}
						</div>
					)}
				</div>
			</section>
		</Fragment>
	)
}

export default Home
