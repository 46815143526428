import { useQuery } from '@tanstack/react-query'

import { flattenFilters } from '../../../utils/flattenFilters'
import { convertObjectToQueryString } from '../../../utils/convertObjectToQueryString'
import DataService from '../../../lib/DataService'

const { getRequest } = DataService()
export async function collectionOffer(
	collectionAddress,
	account,
	filters,
	pageParam
) {
	
	const flattenedFilters = flattenFilters(filters)
	const queryString = convertObjectToQueryString(flattenedFilters)
	const apiUrl = `collection/offers?address=${collectionAddress}&user=${account}&page=${pageParam}&${queryString}`;

	const response = await getRequest(apiUrl);
	return response.data
}

export function useCollectionOffer(collectionAddress) {
	return useQuery({
		queryKey: ['collection_offer', collectionAddress],
		queryFn: () => collectionOffer(collectionAddress),
		enabled: !!collectionAddress
	})
}

function collectionFilterOffer(collectionAddress) {
	
	return getRequest(`collection/filter-for-offers?address=${collectionAddress}`)

}

export function useCollectionFilterOffer(collectionAddress) {
	return useQuery({
		queryKey: ['collection_filter_offer', collectionAddress],
		queryFn: () => collectionFilterOffer(collectionAddress),
		enabled: !!collectionAddress
	})
}
