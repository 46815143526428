import React, { useEffect, useState } from 'react';

import style from './profilelayout.module.scss'

import { Outlet, useNavigate, useParams } from 'react-router-dom';
import loader from '../../assets/images/Loader.png'
import profileBanner from '../../assets/images/profile/profileCover.png';
import Pencil from '../../assets/images/Pencil.svg';
import {
    useAccount,
} from 'wagmi'
import DataService from '../../lib/DataService'
import { useToast } from '../../utils/toast/customSuccess';
const ProfileLayout = (props) => {
    const bannerimgsize = '100%';
    const [videoError, setVideoError] = useState(false)

    useEffect(() => {
        document.body.classList.add('collectionpages');
    }, []);

    const { address, isConnected } = useAccount()
    const [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#ffffff");
    const { postformdataRequest, postRequest } = DataService()

    const toast = useToast()
    const navigate = useNavigate()
    const [profilecoverimage, setprofilecoverimage] = useState()
    const [coverimgtype, setcoverimgtype] = useState()
    const [data, setData] = useState("")

    const { id } = useParams()
    useEffect(() => {
        GetProfile()
    }, [address, id])

    const GetProfile = async () => {
        setLoading(true)
        const update = await postRequest("user/getSingleProfile", { "address": id ? id : address })
        if (update.status == true) {
            setLoading(false)
            setData(update.data)
            setprofilecoverimage(update.data.cover)
            setcoverimgtype(update.data.coverImageType)
        }
        else {
            setLoading(false)
            setprofilecoverimage()
            setcoverimgtype()
        }
    }

    let size;
    let maxSizeKB;
    let maxSize;

    const imageupload1 = async (e) => {
        let imageFile1 = e.target.files[0];
        size = parseFloat(e.target.files[0]?.size);
        maxSizeKB = 2000;
        maxSize = 100 * 1024 * 1024;
        if (imageFile1 != undefined) {
            if (size < maxSize) {
                if (!imageFile1.name.match(/\.(webp|png|jpg|jpeg|gif|mp4)$/)) {
                    toast.error('Please select valid image for WEBP,GIF,PNG,MP4')
                }
                else if (imageFile1 == "" || imageFile1 == undefined) {
                    toast.error('Please choose cover image')
                }
                else {

                    if (imageFile1.type === 'video/mp4') {
                        setLoading(true)
                        const formData = new FormData()
                        formData.append('address', address.toLowerCase())
                        formData.append('name', data.name)
                        formData.append('logo', data.logo)
                        formData.append('cover', imageFile1)
                        formData.append('bio', data.bio)
                        formData.append('email', data.email)
                        formData.append('twitter', data.twitter)
                        formData.append('instagram', data.instagram)
                        formData.append('website', data.website)
                        formData.append('verification', data.verification)
                        formData.append('twitterVerify', data.twitterVerify)
                        formData.append('nameVerify', data.nameVerify)
                        formData.append('logoVerify', data.logoVerify)
                        formData.append('emailVerify', data.emailVerify)
                        formData.append('coverImageType', imageFile1.type)
                        formData.append('logoImageType', data.logoImageType)

                        const update = await postformdataRequest("user/update-profile", formData)
                        if (update.status == true) {
                            toast.success(update.message)
                            setLoading(false)
                            GetProfile()
                        }
                        else {
                            toast.error(update.message)
                            setLoading(false)
                        }
                    }
                    else {

                        const reader = new FileReader()
                        reader.onload = e => {
                            const img = new Image()
                            img.onload = async () => {
                                const width = img.naturalWidth;
                                const height = img.naturalHeight;
                                if (width >= 1400 && height >= 360) {
                                    setLoading(true)
                                    const formData = new FormData()
                                    formData.append('address', address.toLowerCase())
                                    formData.append('name', data.name)
                                    formData.append('logo', data.logo)
                                    formData.append('cover', imageFile1)
                                    formData.append('bio', data.bio)
                                    formData.append('email', data.email)
                                    formData.append('twitter', data.twitter)
                                    formData.append('instagram', data.instagram)
                                    formData.append('website', data.website)
                                    formData.append('verification', data.verification)
                                    formData.append('twitterVerify', data.twitterVerify)
                                    formData.append('nameVerify', data.nameVerify)
                                    formData.append('logoVerify', data.logoVerify)
                                    formData.append('emailVerify', data.emailVerify)
                                    formData.append('coverImageType', imageFile1.type)
                                    formData.append('logoImageType', data.logoImageType)

                                    const update = await postformdataRequest("user/update-profile", formData)
                                    if (update.status == true) {
                                        toast.success(update.message)
                                        setLoading(false)
                                        GetProfile()
                                    }
                                    else {
                                        toast.error(update.message)
                                        setLoading(false)
                                    }
                                }
                                else {
                                    toast.error('Use image size height 360 pixels, width 1400 pixels.')
                                }
                            }
                            img.onerror = () => {
                                toast.error('Unsupported image file')
                                setLoading(false)
                            }
                            img.src = e.target.result
                        }
                        reader.readAsDataURL(imageFile1)

                    }
                }
            }
            else {
                toast.error('Maximum file size is 100mb')
            }
        }
        else {

        }
    }

    const handleVideoError = () => {
        setVideoError(true);
    };

    return (
        <>
            <div className={loading ? "spinner" : "d-none"}>
                <img src={loader} alt='' className='spinAnimate' />
            </div>
            <div className={style.cl_banner}>
                <div className={style.coverBannerCnt}>
                    {typeof profilecoverimage == "string" && profilecoverimage !== "undefined" ?
                        coverimgtype.startsWith('video') ?
                            videoError ? <img src={profileBanner} alt='profileBanner' width={bannerimgsize} height="400" style={{ objectFit: "cover" }} /> :

                                <video loop autoPlay muted playsInline controlslist='nodownload' className='vitag1' width={bannerimgsize} height="400" style={{ objectFit: "cover" }}>
                                    <source src={profilecoverimage} type="video/mp4" onError={handleVideoError} />
                                </video>
                            :
                            profilecoverimage ?
                                <img src={profilecoverimage} width={bannerimgsize} height="400" style={{ objectFit: "cover" }} onError={handleVideoError} />
                                :
                                <img src={profileBanner} alt='profileBanner' width={bannerimgsize} height="400" style={{ objectFit: "cover" }} />
                        :
                        <img src={profileBanner} alt='profileBanner' width={bannerimgsize} height="400" style={{ objectFit: "cover" }} />
                    }

                </div>
                {data && data.verification == 'Verified' ?
                    ""
                    :
                    id === address || id == undefined ?
                        <label htmlFor='userBannerImg' className={style.editIconCnt}>
                            <input id="userBannerImg" type='file' className={style.fileInputType} onChange={(e) => imageupload1(e)} accept="image/png, image/gif, video/mp4,image/webp,image/jpg,image/jpeg" />
                            <img src={Pencil} />
                        </label>
                        :
                        ""
                }
            </div>

            <Outlet context={[data]} />
        </>
    )
}

export default ProfileLayout;