import {  useInfiniteQuery } from '@tanstack/react-query'
import DataService from '../../../lib/DataService'

const { getRequest } = DataService()

export const fetchEarnings = ({address, pageParam}) => {
    return getRequest(`user/earnings?address=${address}&limit=10&page=${pageParam}`)
}

export const GetFetchEarnings = ({ address, pageParam }) => {
	return useInfiniteQuery({
		queryKey: ['earnings_list', address, pageParam],
		queryFn: ({ pageParam = 1 }) => fetchEarnings({ address, pageParam }),
		getNextPageParam: (lastPage, allPages) => {
			return lastPage?.length ? allPages?.length + 1 : undefined
		},
		enabled: !!address
	})
}


