import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAccount } from 'wagmi'

import DataService from '../../../lib/DataService'

const {  postRequest } = DataService()

function checkoutItems(assets, address) {
	const data = {
		orders: assets.map((asset) => ({
			orderId: asset.details.orderId,
			address
		}))
	}

	return postRequest('cart/checkout', { ...data })
}

export function useCheckoutItems() {
	const queryClient = useQueryClient()
	const { address: account } = useAccount()

	return useMutation({
		mutationFn: (assets) => checkoutItems(assets, account),
		onSuccess: () => {
			queryClient.invalidateQueries(['cart'])
			queryClient.invalidateQueries(['asset'])
		}
	})
}
