
import React, { useState, useEffect } from "react";
import { handleImageError } from "../../hooks/useHandleImg";
import DefaultImage from "../../assets/images/homeExplorer/placeholder.png";

import tag from "./assets/tag.png";
import { useParams } from "react-router-dom";
import { formatDistance } from "date-fns";
import PaginationComponent from "react-reactstrap-pagination";
import noItemfoundImage from "../../assets/images/collection/noItemfoundImage.svg";
import cnddsLight from '../../assets/images/cnddsLight.svg'
import { useToggleClassName } from "../../hooks/useToggleClassName";
import { useTranslation } from "react-i18next";
import DataService from "../../lib/DataService";
const Bridge = () => {
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  useToggleClassName();

  const [activeTab1, setActiveTab1] = useState("1");
  const toggle1 = (tab) => {
    if (activeTab1 !== tab) setActiveTab1(tab);
  };

  const { id } = useParams();
	const { getRequest, postRequest } = DataService()

  const [nfts, setNfts] = useState(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getNfts();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [page]);

  async function getNfts() {
    try {
      const { data } = await getRequest(
        `home/nfts?collection=${id}&page=${page}&size=10`
      );

      if (data.status) {
        setNfts(data.data);
      } else {
        throw new Error(data.message);
      }
    } catch (error) {}
  }

  const coinMap = {
    binance: "BNB",
    polygon: "MATIC",
    ethereum: "ETH",
  };
  const { t } = useTranslation()


  return (
    <>
      <section class="TraSec">
        <div class="container">
          <div>
            <div class="row">
              <div class="col-xl-12 order-xl-1 order-2 mb-3">
                <div>
                  <div class="table-responsive">
                    <table class="table table-borderless mb-0 hmecollection">
                      <thead class="trd_head">
                        <tr>
                          <th></th>
                          <th>Item </th>
                          <th>Price</th>
                          <th>Rarity</th>
                          <th>Quantity</th>
                          <th>From</th>
                          <th>To</th>
                          <th>
                            <svg
                              width="12"
                              height="13"
                              viewBox="0 0 12 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6 0.6875C9.21094 0.6875 11.8125 3.28906 11.8125 6.5C11.8125 9.71094 9.21094 12.3125 6 12.3125C2.78906 12.3125 0.1875 9.71094 0.1875 6.5C0.1875 3.28906 2.78906 0.6875 6 0.6875ZM6 11.1875C8.57812 11.1875 10.6875 9.10156 10.6875 6.5C10.6875 3.92188 8.57812 1.8125 6 1.8125C3.39844 1.8125 1.3125 3.92188 1.3125 6.5C1.3125 9.10156 3.39844 11.1875 6 11.1875ZM7.42969 8.75L5.4375 7.29688C5.36719 7.25 5.34375 7.15625 5.34375 7.08594V3.21875C5.34375 3.07812 5.46094 2.9375 5.625 2.9375H6.375C6.51562 2.9375 6.65625 3.07812 6.65625 3.21875V6.54688L8.20312 7.69531C8.34375 7.78906 8.36719 7.95312 8.27344 8.09375L7.82812 8.67969C7.73438 8.82031 7.57031 8.84375 7.42969 8.75Z"
                                fill="#595F6A"
                              />
                            </svg>
                          </th>
                        </tr>
                      </thead>
                      {nfts && nfts.length > 0 ? (
                        nfts.map((nft) => (
                          <>
                            <tbody class="trdtable_body">
                              <tr>
                                <td>
                                  <label class="liscdbs">
                                    <span class="mr-1">
                                      <img src={tag} alt="" />
                                    </span>
                                    list
                                  </label>
                                </td>
                                <td>
                                  <div class="d-flex align-items-center">
                                    <span class="mr-2">
                                      {!nft?.asset?.startsWith(
                                        process.env.S3_URL
                                      ) && !nft?.asset?.startsWith("ipfs") && nft?.asset !== null? (
                                        <img
                                          src={
                                            nft?.asset !== ""
                                              ? nft?.asset
                                              : DefaultImage
                                          }
                                          alt=""
                                          style={{
                                            height: "60px",
                                            aspectRatio: "1",
                                            borderRadius: "12px",
                                          }}
                                          onError={handleImageError}
                                        />
                                      ) : (
                                        <img
                                          src={DefaultImage}
                                          alt=""
                                          style={{
                                            height: "60px",
                                            aspectRatio: "1",
                                            borderRadius: "12px",
                                          }}
                                        />
                                      )}
                                    </span>
                                    <div>
                                      <h2>{nft?.nftName}</h2>
                                      <p>{nft?.collectionName} </p>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <h3>
                                    {nft?.price} {coinMap[nft.chain]}
                                  </h3>
                                </td>
                                <td>--</td>
                                <td>1</td>
                                <td class="orncl">--</td>
                                <td class="orncl">--</td>
                                <td>
                                  {formatDistance(
                                    new Date(nft.createdAt),
                                    new Date(),
                                    {
                                      includeSeconds: true,
                                      addSuffix: true,
                                    }
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </>
                        ))
                      ) : (
                        <div className="noItemFound">
                          <div className="text-center">
                            <img
                              src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : noItemfoundImage}
                              alt="noItemfoundImage"
                              className="img-fluid"
                            />
                          </div>
                          <div className="nofounttxt mb-4">
                            {t("Nothing to display")}.
                          </div>
                        </div>
                      )}
                    </table>
                  </div>

                  {nfts && nfts.length > 0 ? (
                    <div class="bri_pagenation2">
                      <nav aria-label="Page ">
                        <div class="pagination justify-content-center align-items-center mb-0">
                          <PaginationComponent
                            size="sm"
                            defaultActivePage={1}
                            totalItems={nfts && nfts.length}
                            maxPaginationNumbers={3}
                            pageSize={10}
                            onSelect={(page) => setPage(page)}
                          />
                        </div>
                      </nav>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Bridge;
