import React, { useEffect, useRef, useState } from 'react'
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useOnClickOutside } from '../../../hooks/useOnClickOutside'
import { useFetchCollectionTraits } from '../apis/fetchCollectionTraits'

export function SearchItems({ filters, handleFiltersChange }) {
	const { t } = useTranslation()

	const [show, setShow] = useState(false)
	const [traits, setTraits] = useState()
	const [searchText, setSearchText] = useState()

	const traitRef = useRef(null)
	useOnClickOutside(traitRef, () => {
		setShow(false)
	})

	const params = useParams()
	const { data, status } = useFetchCollectionTraits(params.collectionAddress)

	useEffect(() => {
		if (status === 'success') {
			if (data.status) {
				setTraits(data.data)
			}
		}
	}, [status, data])

	useEffect(() => {
		setSearchText(filters.search.query ?? '')
	}, [filters.search.query])

	const handleTraitClick = async ({ traitKey, value }) => {
		const stringTraitsArray = Object.entries(
			filters.search.stringTraits ?? {}
		)
		const [traitIndex, traitObject] =
			stringTraitsArray.find((s) => s[1].name === traitKey) ?? []

		const values = Object.values(traitObject ? traitObject.values : {})
		const valuesArray = values.includes(value.traitName)
			? values.filter((v) => v !== value.traitName)
			: [...values, value.traitName]
		const o = valuesArray.reduce((acc, cur, curIndex) => {
			acc = { ...acc, [curIndex]: cur }
			return acc
		}, {})

		const stringTraitsObj = stringTraitsArray
			.filter((t) => t[1].name !== traitKey)
			.reduce((acc, cur, curIndex) => {
				acc = { ...acc, [curIndex]: cur[1] }
				return acc
			}, {})

		const obj = traitIndex
			? {
				[traitIndex]: {
					name: encodeURIComponent(traitKey),
					values: o
				}
			}
			: {
				[stringTraitsArray.length]: {
					name: encodeURIComponent(traitKey),
					values: {
						0: encodeURIComponent(value.traitName)
					}
				}
			}

		const updatedfilters = {
			...filters,
			search: {
				...filters.search,
				stringTraits:
					valuesArray.length !== 0
						? {
							...filters.search.stringTraits,
							...obj
						}
						: stringTraitsObj
			}
		}
		handleFiltersChange(updatedfilters)
	}

	const handleOnChange = (e) => {
		if (e.target.value === '') {
			setSearchText(e.target.value)
			setTraits(data.data)
			setShow(true)
			return
		}

		const traits = data.data
			.filter(
				(t) =>
					t.values.findIndex((v) =>
						v.traitName
							.toLowerCase()
							.includes(e.target.value.toLowerCase())
					) !== -1
			)
			.map((t) => {
				return {
					...t,
					values: t.values.filter((v) => {
						return v.traitName
							.toLowerCase()
							.includes(e.target.value.toLowerCase())
					})
				}
			})

		setSearchText(e.target.value)
		setTraits(traits)
	}

	const handleOnKeyDown = (e) => {
		if (e.key !== 'Enter' || e.target.value === '') return

		if (traits.length > 0) {
			handleTraitClick({
				traitKey: traits[0].key,
				value: traits[0].values[0]
			})
			setShow(false)
			return
		}

		const updatedfilters = {
			...filters,
			search: {
				...filters.search,
				query: e.target.value
			}
		}
		handleFiltersChange(updatedfilters)
		setShow(false)
	}

	return (
		<div
			className='form-group formInputs mr-3 flex-grow-1 filterGroup'
			ref={traitRef}
		>
			<InputGroup className='mb-2'>
				<InputGroupAddon addonType='prepend'>
					<InputGroupText className='px-2'>
						<svg
							width='17'
							height='17'
							viewBox='0 0 17 17'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M15.875 14.6562C16.0312 14.8125 16.0312 15.0625 15.875 15.1875L15.1562 15.9062C15.0312 16.0625 14.7812 16.0625 14.625 15.9062L10.8438 12.125C10.7812 12.0312 10.75 11.9375 10.75 11.8438V11.4375C9.59375 12.4062 8.125 13 6.5 13C2.90625 13 0 10.0938 0 6.5C0 2.9375 2.90625 0 6.5 0C10.0625 0 13 2.9375 13 6.5C13 8.125 12.375 9.625 11.4062 10.75H11.8125C11.9062 10.75 12 10.8125 12.0938 10.875L15.875 14.6562ZM6.5 11.5C9.25 11.5 11.5 9.28125 11.5 6.5C11.5 3.75 9.25 1.5 6.5 1.5C3.71875 1.5 1.5 3.75 1.5 6.5C1.5 9.28125 3.71875 11.5 6.5 11.5Z'
								fill='white'
							/>
						</svg>
					</InputGroupText>
				</InputGroupAddon>
				<Input
					placeholder={t('Search Items')}
					onFocus={() => {
						setShow(true)
					}}
					value={searchText}
					onChange={handleOnChange}
					onKeyDown={handleOnKeyDown}
				/>
			</InputGroup>
			{show && traits?.length !== 0 && (
				<div className='collectionFilter filterSubmenu scroller'>
					{traits?.map((trait, i) => (
						<React.Fragment key={i}>
							{i !== 0 && <hr />}
							<Trait
								filters={filters}
								trait={trait}
								handleFiltersChange={handleFiltersChange}
								onTraitValueClick={() => {
									setShow(false)
								}}
							/>
						</React.Fragment>
					))}
				</div>
			)}
		</div>
	)
}

function Trait({ filters, trait, onTraitValueClick, handleFiltersChange }) {
	return (
		<>
			<div className='filterSubHead mb-2'>
				<h3>{trait.key}</h3>
			</div>
			<hr />
			{trait.values.map((value, i) => (
				<TraitValue
					key={i}
					value={value}
					filters={filters}
					traitKey={trait.key}
					handleFiltersChange={handleFiltersChange}
					onTraitValueClick={onTraitValueClick}
				/>
			))}
		</>
	)
}

function TraitValue({
	filters,
	traitKey,
	value,
	onTraitValueClick,
	handleFiltersChange
}) {
	const handleTraitClick = async () => {
		const stringTraitsArray = Object.entries(
			filters.search.stringTraits ?? {}
		)
		const [traitIndex, traitObject] =
			stringTraitsArray.find((s) => s[1].name === traitKey) ?? []

		const values = Object.values(traitObject ? traitObject.values : {})
		const valuesArray = values.includes(value.traitName)
			? values.filter((v) => v !== value.traitName)
			: [...values, value.traitName]
		const o = valuesArray.reduce((acc, cur, curIndex) => {
			acc = { ...acc, [curIndex]: cur }
			return acc
		}, {})

		const stringTraitsObj = stringTraitsArray
			.filter((t) => t[1].name !== traitKey)
			.reduce((acc, cur, curIndex) => {
				acc = { ...acc, [curIndex]: cur[1] }
				return acc
			}, {})

		const obj = traitIndex
			? {
				[traitIndex]: {
					name: encodeURIComponent(traitKey),
					values: o
				}
			}
			: {
				[stringTraitsArray.length]: {
					name: encodeURIComponent(traitKey),
					values: {
						0: encodeURIComponent(value.traitName)
					}
				}
			}

		const updatedfilters = {
			...filters,
			search: {
				...filters.search,
				stringTraits:
					valuesArray.length !== 0
						? {
							...filters.search.stringTraits,
							...obj
						}
						: stringTraitsObj
			}
		}
		handleFiltersChange(updatedfilters)
		onTraitValueClick()
	}

	const handleKeyDown = (event) => {
		if (event.key === 'Enter' || event.key === ' ') {
			handleTraitClick();
		}
	};

	return (
		<div
			className='filterRow d-flex align-items-center hover'
			onClick={handleTraitClick} onKeyDown={handleKeyDown}
			role="button"
			tabIndex={0}
		>
			<div>
				<h3 className='heading'>{value.traitName}</h3>
				<p>{value.percentage} %</p>
			</div>
		</div>
	)
}
