import { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";
import DefaultImage from "../../../assets/images/homeExplorer/placeholder.png";
import { handleImageError } from '../../../hooks/useHandleImg'
import { AssetPlaceholder } from "./loader/AssetPlaceholder";
import useCountdown from "../../../hooks/useCountdown";
import { formatRoundTime } from "../../../utils/formatRoundTime";

import { CloseIcon } from "../../../components/modals/buy-now/CloseIcon";

import timerIcon from "../../../assets/images/timerIcon.svg";
import likeIcon from "../../../assets/images/likeIcon1.svg";
import viewIcon from "../../../assets/images/viewIcon.svg";

export function AssetCard({ assetDetails }) {
  const { t } = useTranslation();
  const [videoError, setVideoError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const { secondsRemaining } = useCountdown(assetDetails.saleExpiry);
  const countdown = formatRoundTime(secondsRemaining);
  const isVideo = assetDetails.asset.includes(".mp4");

  const toggle = () => setIsOpen((current) => !current);
  const handleVideoError = () => {
    setVideoError(true);
  };

  const toggleCloseModal = async () => {
    setIsOpen(false)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      if (!isVideo) {
        toggle();
      }
    }
  };

  const handleKeyDown1 = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.stopPropagation();
    }
  };
     
  return (
    <>
      <div
        className={`nftBannerCnt ${isImageLoaded ? 'hover' : ''}`}
        onClick={!isVideo ? toggle : () => { }}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        role="button"
      >
        {!isVideo ? (
          <>
            {isImageLoaded ? null : <AssetPlaceholder />}
            <img
              src={assetDetails.asset}
              className='nftBanner'
              style={isImageLoaded ? {} : { display: 'none' }}
              onLoad={() => setIsImageLoaded(true)}
            />
            {isImageLoaded ? (
              <div className='counterCnt d-flex align-items-center flex-wrap'>
                <div
                  className='counter d-flex align-items-center ml-auto cursor-default' style={{ gap: '4px' }}
                  onClick={(e) => e.stopPropagation()} onKeyDown={handleKeyDown1}
                  tabIndex={0}
                  role="button"
                >
                  <img src={likeIcon} alt="likeIcon" />
                  <span className='mr-2'>
                    {assetDetails.favoritesCount}{' '}
                    {t('Favorites')}
                  </span>
                  <img src={viewIcon} alt="viewIcon" />
                  <span>{assetDetails.viewsCount} {t("Views")}</span>
                </div>
              </div>
            ) : null}
          </>
        ) : (
          <>
            {videoError ?
              <img
                src={DefaultImage}
                className="nftBanner"
                style={isImageLoaded ? {} : { display: "none" }}
                onLoad={() => setIsImageLoaded(true)}
              /> :
              

              <video controls loop playsInline autoplay="" preload='metadata' controlslist='nodownload' id='video-asset' style={{
                objectFit: "contain",
                height: "530px",
                width: "100%",
              }}>
                <source src={assetDetails?.asset !== "" ? assetDetails?.asset : DefaultImage} type="video/mp4" onError={handleVideoError} />
              </video>

            }
            <div className="counterCnt d-flex align-items-center flex-wrap">
              {assetDetails.isMarketplace && (
                <div className='counter d-flex align-items-center cursor-default'>
                  <img src={timerIcon} alt='timerIcon' />
                  <span>{countdown}</span>
                </div>
              )}
              <div className='counter d-flex align-items-center ml-auto cursor-default' style={{ gap: '4px' }}>
                <img src={likeIcon} alt='likeIcon' />
                <span className='mr-2'>
                  {assetDetails.favoritesCount}{' '}
                  {t('Favorites')}
                </span>
                <img src={viewIcon} alt='viewIcon' />
                <span>{assetDetails.viewsCount} {t("Views")}</span>
              </div>
            </div>
          </>
        )}
      </div>

      <Modal
        isOpen={isOpen}
        toggle={toggle}
        centered='true'
        className='curMdl lgMdl'
        backdropClassName='selCurBp'
        keyboard={false}
      >
        <div className="modalclose">
          <CloseIcon onClose={toggleCloseModal} />
        </div>
        <img src={assetDetails.asset} />
      </Modal>
    </>
  );
}
