import { useQueryClient } from '@tanstack/react-query'
import { format } from 'date-fns'
import { Signature } from 'ethers'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Modal,
	ModalBody,
	ModalHeader,
	UncontrolledDropdown
} from 'reactstrap'
import useSWR from 'swr'
import { formatUnits, parseUnits } from 'viem'
import { useAccount } from 'wagmi'
import Web3 from 'web3'

import contracts from '../../../config/constants/contracts'
import { useApproveToken } from '../../../hooks/useApproveToken'
import {
	useMarketplaceContract,
	useTokenContract
} from '../../../hooks/useContract'
import { handleImageError } from '../../../hooks/useHandleImg'
import { formatAmount } from '../../../utils/formatAmount'
import { getOrder } from '../../../utils/getOrder'
import { getProvider } from '../../../utils/getProvider'
import { useSomethingWentWrongModalActions } from '../error/store'
import { useOfferPlacedSuccessfullyModalActions } from '../offer-placed-successful/store'
import { usePurchaseLoadingModalActions } from '../purchase-loading/store'
import { CloseIcon } from './CloseIcon'
import { useMakeOffer } from './offer'
import { useMakeOfferModalActions, useMakeOfferModalState } from './store'

import yellowtick20 from '../../../assets/images/collection/yellow-tick_20px.svg'
import defaultImage from '../../../assets/images/homeExplorer/placeholder.png'
import { chainIdMap, nativeCoinMap, nativeCoinMap1 } from '../../../utils/getFilterData'

const intervals = [
	{ label: '12 hours', duration: 720, format: '12H' },
	{ label: '1 day', duration: 1440, format: '1D' },
	{ label: '3 days', duration: 4320, format: '3D' },
	{ label: '7 days', duration: 10080, format: '7D' },
	{ label: '1 month', duration: 43200, format: '1M' }
]

const wrappedCurrencyMap = {
	ethereum: 'weth',
	binance: 'wbnb',
	polygon: 'wmatic'
}


export const MakeOfferModal = props => {
	const { t } = useTranslation()

	const [videoError, setVideoError] = useState()
	const [isBidHighest, setIsBidHighest] = useState(false)
	const [bidAmount, setBidAmount] = useState()
	const [hasFunds, setHasFunds] = useState(false)
	const [selectedInterval, setSelectedInterval] = useState(intervals[4])
	const [dropdownOpen, setDropdownOpen] = useState(false)
	const [date, setDate] = useState('')
	const [time, setTime] = useState('')
	const [selectedDate, setSelectedDate] = useState('')
	const [selectedCurrency, setSelectedCurrency] = useState()
	const [refreshBalance, setRefreshBalance] = useState(false)

	const { isOpen, asset } = useMakeOfferModalState()
	const { toggleMakeOfferModal } = useMakeOfferModalActions()
	const { togglePurchaseLoadingModal, setHeading } =
		usePurchaseLoadingModalActions()
	const { toggleOfferPlacedSuccessfullyModal, setAsset } =
		useOfferPlacedSuccessfullyModalActions()
	const { toggleSomethingWentWrongModal } = useSomethingWentWrongModalActions()

	useEffect(() => {
		if (!asset) return

		setSelectedCurrency(asset.allowedCurrencies[0])
	}, [asset])

	const queryClient = useQueryClient()
	const { address: account } = useAccount()
	const { mutateAsync: makeOffer } = useMakeOffer()

	const chainId = chainIdMap[asset?.details.chain]
	const tokenAddress =
		selectedCurrency?.currencyType === 'coin'
			? contracts[wrappedCurrencyMap[selectedCurrency?.currencyNetwork]]
			: selectedCurrency?.contractAddress
	const tokenContract = useTokenContract(tokenAddress, chainId)

	const marketplaceContractAddress = asset
		? contracts.marketplace[chainId]
		: null
	const marketplaceContract = useMarketplaceContract(
		marketplaceContractAddress,
		chainId
	)
	const { approve } = useApproveToken(tokenAddress, chainId)

	const { data: tokenBalance } = useSWR(
		['tokenBalance', tokenContract, refreshBalance],
		async () => {
			const decimals = await tokenContract.read.decimals()
			const rawBalance = await tokenContract.read.balanceOf([account])
			return parseFloat(formatUnits(rawBalance, decimals))
		}
	)

	const debounceValidateBidAmount = useCallback(
		debounce(floatValue => validateBidAmount(floatValue), 500),
		[asset, validateBidAmount, tokenBalance]
	)

	useEffect(() => {
		debounceValidateBidAmount(bidAmount)
	}, [selectedCurrency, bidAmount, tokenBalance])

	const isVideo = asset?.details?.asset?.endsWith('.mp4')

	const balance = tokenBalance

	function validateBidAmount(floatValue) {
		setHasFunds(false)
		if (floatValue >= tokenBalance) {
			setHasFunds(false)
		} else {
			setHasFunds(true)
		}
		if (floatValue > 0 && floatValue >= asset.details.minOfferPrice) {
			setIsBidHighest(true)
		} else {
			setIsBidHighest(false)
		}
	}

	const handleBidAmountChange = values => {
		const { floatValue } = values
		setBidAmount(floatValue)
		debounceValidateBidAmount(floatValue)
	}

	const toggleDropdown = () => setDropdownOpen(!dropdownOpen)

	const handleIntervalChange = interval => {
		setSelectedInterval(interval)
	}

	useEffect(() => {
		const nextDate = new Date().getTime() + selectedInterval.duration * 60000

		const formatDate = format(nextDate, 'dd-MM-yyyy hh:mm a')
		const dateTimeArray = formatDate.split(' ')
		setDate(dateTimeArray[0])
		setSelectedDate(nextDate)
		setTime(dateTimeArray[1] + ' ' + dateTimeArray[2])
	}, [selectedInterval, isOpen])

	const handleMakeOffer = async () => {
		setHeading('Purchase')
		togglePurchaseLoadingModal()

		const order = getOrder({
			asset: {
				...asset,
				offeror: account,
				owner: { ...asset.owner, address: account },
				details: {
					...asset.details,
					currencyAddress:
						selectedCurrency?.currencyType === 'coin'
							? contracts[wrappedCurrencyMap[selectedCurrency?.currencyNetwork]]
							: selectedCurrency?.contractAddress,
					price: bidAmount
				}
			},
			assetType: asset.details.contractStandard,
			orderType: 'buy'
		})
		const currency =
			selectedCurrency?.currencyType === 'coin'
				? wrappedCurrencyMap[selectedCurrency?.currencyNetwork].toUpperCase()
				: selectedCurrency?.currencySymbol

		const provider = getProvider()
		const web3 = new Web3(provider)

		try {
			const expiry = Math.floor(selectedDate / 1000)
			const nftAddress = asset.collection.address
			const numberOfNfts = 1
			try {
				const hash = await marketplaceContract.read.getHash([
					numberOfNfts,
					expiry,
					nftAddress,
					account
				])

				const sign = await web3.eth.personal.sign(hash, account)

				const { v, r, s } = Signature.from(sign)
				const buyerSig = [v, r, s]

				await approve(bidAmount.toString(), marketplaceContractAddress)

				setAsset(asset)

				const data = {
					currency,
					expiry,
					contractAddress: asset?.details?.contractAddress,
					nftId: asset?.details?.nftId,
					price: bidAmount,
					duration: selectedInterval?.format,
					address: account,
					orderTuple: order,
					signTuple: buyerSig,
					buyingAmount: parseUnits(
						bidAmount.toString(),
						selectedCurrency?.currencyDecimal
					).toString(),
					royaltyFee: asset?.details?.royalty,
					adminFee: asset?.details?.platformFee
				}

				const res = await makeOffer(data)

				if (res.status) {
					queryClient.invalidateQueries(['makeOffer'])
					queryClient.invalidateQueries(['asset'])
					setBidAmount(null)
					setRefreshBalance(!refreshBalance)
					toggleMakeOfferModal()
					toggleOfferPlacedSuccessfullyModal()
					return
				} else {
					toggleSomethingWentWrongModal()
				}
			} catch (error) { }
		} catch (error) {
			toggleSomethingWentWrongModal()
		} finally {
			togglePurchaseLoadingModal()
		}
	}

	const CheckTick = () => {
		return (
			<>
				<svg
					width='12'
					height='9'
					viewBox='0 0 12 9'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M10.1953 0.46875C10.3125 0.351562 10.5 0.351562 10.5938 0.46875L11.2734 1.125C11.3672 1.24219 11.3672 1.42969 11.2734 1.52344L4.24219 8.55469C4.125 8.67188 3.96094 8.67188 3.84375 8.55469L0.703125 5.4375C0.609375 5.32031 0.609375 5.13281 0.703125 5.03906L1.38281 4.35938C1.47656 4.26562 1.66406 4.26562 1.78125 4.35938L4.03125 6.63281L10.1953 0.46875Z'
						fill='#FFA900'
					/>
				</svg>
			</>
		)
	}

	const intervalItems = intervals.map(interval => (
		<DropdownItem
			key={interval.label}
			onClick={() => handleIntervalChange(interval)}
		>
			<div>{interval.label} </div>
			{selectedInterval.label === interval.label && (
				<div>
					<CheckTick />
				</div>
			)}
		</DropdownItem>
	))

	if (!asset) {
		return null
	}

	const allowedCurrencies = asset.allowedCurrencies.reduce((acc, cur) => {
		if (!acc.hasOwnProperty(cur.currencySymbol)) {
			acc[cur.currencySymbol] = cur
		}
		return acc
	}, {})


	const usdPrice =
		bidAmount &&
		bidAmount * allowedCurrencies[nativeCoinMap1[selectedCurrency?.currencySymbol]]?.usdPrice

	const handleVideoError = () => {
		setVideoError(true)
	}
	return (
		<>
			<Modal
				isOpen={isOpen}
				centered='true'
				className='mymodal'
				backdropClassName='selCurBp'
				keyboard={false}
			>
				<ModalHeader
					toggle={toggleMakeOfferModal}
					close={
						<CloseIcon
							onClose={() => {
								toggleMakeOfferModal()
								setBidAmount()
							}}
						/>
					}
				>
					<h5 className='modal-title'>{t('Make an Offer')}</h5>
				</ModalHeader>
				<ModalBody>
					<div>
						<div className='cartItem'>
							<div className='itemImg'>
								{!isVideo ? (
									typeof asset?.details?.asset == 'string' &&
										asset?.details?.asset !== undefined &&
										!asset?.details?.asset?.startsWith(process.env.S3_URL) &&
										!asset?.details?.asset?.startsWith('ipfs') &&
										asset?.details?.asset !== null ? (
										<img
											className='object-cover'
											alt='asset'
											src={
												asset.details.asset ? asset.details.asset : defaultImage
											}
											onError={handleImageError}
										/>
									) : (
										<img
											className='object-cover'
											alt='asset'
											src={defaultImage}
										/>
									)
								) : videoError ? (
									<img
										className='object-cover'
										alt='asset'
										src={defaultImage}
									/>
								) : (
									

									<video loop autoPlay muted playsInline preload='metadata' controlslist='nodownload' id='video-asset' style={{
										objectFit: 'contain',
										height: '84px',
										width: '84px'
									}}>
										<source src={asset.details.asset ? asset.details.asset : defaultImage} type="video/mp4" onError={handleVideoError} />
									</video>

								)}
							</div>
							<div className='flex-grow-1 p-3'>
								<div className='d-flex justify-content-between mb-2'>
									<div className='itemName'>
										{asset?.collection.name}{' '}
										{asset?.collection.isVerified && (
											<img
												src={yellowtick20}
												alt='yellowtick20'
												className='ml-1'
											/>
										)}
									</div>
									<span className='fs-14 fw-400 dark-text-secondary'>
										{usdPrice ? `$ ${parseFloat(usdPrice.toFixed(2))}` : '$--'}
									</span>
								</div>
								<div className='d-flex justify-content-between mb-n1'>
									<div className='itemId'>{asset?.details.name}</div>
									<span className='itemBal'>
										{!bidAmount
											? '--'
											: `${parseFloat(formatAmount(bidAmount))} ${selectedCurrency?.currencyType === 'coin'
												? wrappedCurrencyMap[
													selectedCurrency?.currencyNetwork
												].toUpperCase()
												: selectedCurrency?.currencySymbol
											}`}
									</span>
								</div>
							</div>
						</div>
					</div>
					<hr className='hrCls' />

					<div className='borderRoundedCard'>
						<div class='d-flex justify-content-between mb-2'>
							<div className='fs-14 fw-400 dark-text-secondary'>
								{t('Your Balance')}
							</div>
							<div className='fs-16 fw-600 dark-text'>
								{balance === undefined
									? 'Loading...'
									: `${parseFloat(balance.toFixed(6))} ${selectedCurrency?.currencyType === 'coin'
										? wrappedCurrencyMap[
											selectedCurrency?.currencyNetwork
										].toUpperCase()
										: selectedCurrency?.currencySymbol
									}`}
							</div>
						</div>
					</div>

					<hr className='hrCls mt-3' />

					<div className='position-relative zindex1'>
						<div className='d-flex justify-content-between mb-1'>
							<span className='fs-14 fw-600 dark-text'>{t('Price')}</span>
							{!!bidAmount && !hasFunds ? (
								<span className='fs-14 fw-400 dark-red'>
									{t('Not enough funds')}
								</span>
							) : null}
							{!!bidAmount && !isBidHighest ? (
								<span className='fs-14 fw-400 dark-red'>
									{t(
										`Minimum offer price is ${asset.details.minOfferPrice} ${nativeCoinMap[asset.details.chain]
										}`
									)}
								</span>
							) : null}
						</div>
						<div className='form-group formInputs position-relative zindex1'>
							<InputGroup
								className={`${!!bidAmount && !hasFunds ? 'err' : ''}`}
							>
								<NumericFormat
									className='form-control mx-auto'
									placeholder={t('Price')}
									value={bidAmount}
									allowNegative={false}
									customInput={Input}
									onValueChange={handleBidAmountChange}
									key={selectedCurrency?.currencySymbol}
								/>
								<InputGroupAddon addonType='append'>
									{asset.allowedCurrencies.length === 1 ? (
										<InputGroupText className='pr-0 dark-text'>
											{selectedCurrency?.currencyType === 'coin'
												? wrappedCurrencyMap[
													selectedCurrency?.currencyNetwork
												].toUpperCase()
												: selectedCurrency?.currencySymbol}
										</InputGroupText>
									) : (
										<InputGroupText className='pr-0'>
											<UncontrolledDropdown>
												<DropdownToggle className='input-Dropdown'>
													{selectedCurrency?.currencyType === 'coin'
														? wrappedCurrencyMap[
															selectedCurrency?.currencyNetwork
														]?.toUpperCase()
														: selectedCurrency?.currencySymbol}
													<i className='fas fa-angle-down ml-2'></i>
												</DropdownToggle>
												<DropdownMenu
													right
													className='ig-dmenu'
													style={{ zIndex: '999' }}
												>
													{asset.allowedCurrencies.map(currency => (
														<DropdownItem
															key={currency.currencySymbol}
															onClick={() => setSelectedCurrency(currency)}
														>
															{currency.currencyType === 'coin'
																? wrappedCurrencyMap[
																	selectedCurrency?.currencyNetwork
																]?.toUpperCase()
																: currency.currencySymbol}
														</DropdownItem>
													))}
												</DropdownMenu>
											</UncontrolledDropdown>
										</InputGroupText>
									)}
								</InputGroupAddon>
							</InputGroup>
						</div>

						<div className='d-flex justify-content-between mb-1 mt-3'>
							<span className='fs-14 fw-600 dark-text'>{t('Duration')}</span>
						</div>
						<div className='row form-row position-relative'>
							<div className='col-lg-8'>
								<div className='form-group formInputs'>
									<InputGroup>
										<Input value={date} placeholder={date} disabled />
										<InputGroupAddon addonType='append'>
											<InputGroupText className='pr-0'>
												<span className='fs-16 fw-400'>{time}</span>
											</InputGroupText>
										</InputGroupAddon>
									</InputGroup>
								</div>
							</div>
							<div className='col-lg-4'>
								<Dropdown
									className='customdropdown w-100'
									isOpen={dropdownOpen}
									toggle={toggleDropdown}
								>
									<DropdownToggle className='w-100 d-flex align-items-center justify-content-between'>
										{selectedInterval.label}
										<i
											className={`ml-2 fas ${dropdownOpen ? 'fa-angle-up' : 'fa-angle-down'
												}`}
										></i>
									</DropdownToggle>
									<DropdownMenu right>{intervalItems}</DropdownMenu>
								</Dropdown>
							</div>
						</div>
					</div>

					<div className='mt-4'>
						<button
							className='btn btn-block btnSecondary'
							disabled={
								!bidAmount ||
								!hasFunds ||
								!isBidHighest ||
								balance === undefined
							}
							onClick={() => handleMakeOffer()}
						>
							{t('Make an Offer')}{' '}
						</button>
					</div>
				</ModalBody>
			</Modal>
		</>
	)
}
