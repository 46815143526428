import { useState } from 'react'

import DefaultBannerImage from '../../../assets/images/profile/profileCover.png'
import { handleBannerImageError } from '../../../hooks/useHandleImg'
import { CollectionBannerPlaceholder } from './loader/CollectionHeaderPlaceholder'

export default function CollectionBanner({ collection }) {
	const [isImageLoaded, setIsImageLoaded] = useState(false)
	const [isVideoLoaded, setIsVideoLoaded] = useState(false)

	const isVideo = collection.bannerImage.endsWith('.mp4')

	return (
		<>
			<div className='cl_banner'>
				{isVideo ? isVideoLoaded ? null : <CollectionBannerPlaceholder /> : null}
				{isVideo ? (
					
					<video
						id='video-asset'
						loop
						autoPlay
						muted
						playsInline
						preload='metadata'
						controlslist='nodownload'
						style={
							isVideoLoaded
								? {
										height: '400px',
										width: '100%',
										objectFit: 'cover',
										objectPosition: 'center'
								  }
								: {
										display: 'none'
								  }
						}
						onCanPlay={() => setIsVideoLoaded(true)}
					>
						<source src={collection.bannerImage} type='video/mp4' />
					</video>
				) : (
					<>
						{isImageLoaded ? null : <CollectionBannerPlaceholder />}
						{typeof collection?.bannerImage == 'string' &&
						collection?.bannerImage !== undefined &&
						!collection?.bannerImage?.startsWith(
							process.env.S3_URL
						) &&
						!collection?.bannerImage?.startsWith('ipfs') &&
						collection?.bannerImage !== null ? (
							<img
								src={
									collection?.bannerImage !== 'undefined'
										? collection?.bannerImage
										: DefaultBannerImage
								}
								alt='collectionBanner'
								width='100%'
								height='500'
								style={
									isImageLoaded
										? {}
										: {
												display: 'none',
												objectFit: 'cover'
										  }
								}
								onLoad={() => setIsImageLoaded(true)}
								onError={handleBannerImageError}
							/>
						) : (
							<img
								src={DefaultBannerImage}
								alt='collectionBanner'
								width='100%'
								height='500'
								style={
									isImageLoaded
										? {}
										: {
												display: 'none',
												objectFit: 'cover'
										  }
								}
								onLoad={() => setIsImageLoaded(true)}
							/>
						)}
					</>
				)}
			</div>
		</>
	)
}
