import { create } from 'zustand'

const useCollectionStore = create((set) => ({
	noOfItems: 0,
	actions: {
		setNoOfItems: (noOfItems) =>
			set((state) => ({
				...state,
				noOfItems
			}))
	}
}))

export const useCollectionState = () =>
	useCollectionStore(({ noOfItems }) => ({
		noOfItems
	}))

export const useCollectionActions = () =>
	useCollectionStore((state) => state.actions)
