import { useMutation } from 'wagmi'
import { useQueryClient } from '@tanstack/react-query'
import DataService from '../../../lib/DataService'

const { postRequest } = DataService()

function toggleFavorites(asset) {
	return postRequest('home/watchlist-mutation_collection', {
		...asset
	})
}

export function useToggleWatchlist() {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: toggleFavorites,
		onSuccess: () => {
			queryClient.invalidateQueries(['collection'])
		}
	})
}
