import React, { useContext, useEffect, useState } from 'react';

import style from './helpcenterLayout.module.scss'
import Header from '../Header/Header';
import { Outlet } from 'react-router-dom';
import iconMail from '../../assets/images/helpcenter/mail.svg';
import iconPhone from '../../assets/images/helpcenter/phone.svg';
import helpcenterBanner from '../../assets/images/helpcenter/helpcenterBanner.png';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import loader from '../../assets/images/Loader.png'
import { ThemeContext } from '../../views/context/context';
import { useDebounce } from '../../utils/useDebounce';
import { useTranslation } from 'react-i18next';
import DataService from '../../lib/DataService';

const HelpCenterLayout = () => {

    const { t } = useTranslation()

    const bannerimgsize = '100%';

    useEffect(() => {
        document.body.classList.add('helpcenterpages');
    }, []);

    useEffect(() => {

        document.title = 'Help Center | Wor NFT'
    }, [])

    const [loading, setLoading] = useState(false);

    const { helpsearch, setHelpsearch } = useContext(ThemeContext)
    const debouncedSearchText = useDebounce(helpsearch, 1000)
	const { getRequest } = DataService()

    const [socialmedia, setSocialMedia] = useState("")

    const handleOnKeyDown = async (e) => {
        setHelpsearch(e.target.value);
    }

    useEffect(() => {
        Gethelpcenter()
        socialMedia()
    }, [debouncedSearchText])


    const socialMedia = async () => {
        setLoading(true)
        const social = await getRequest('home/social-media')
        if (social.status == true) {
            setLoading(false)
            setSocialMedia(social.data)
        } else {
            setLoading(false)
            setSocialMedia('')
        }
    }


    const [data, setData] = useState("")
    const [count, setCount] = useState()

    const Gethelpcenter = async () => {
        setLoading(true)
        const update = await getRequest(`home/get_helpcenter?filter=${helpsearch}`)
        if (update.status == true) {
            setLoading(false)
            setData(update.data)
            setCount(update?.count)
        }
        else {
            setLoading(false)
            setData("")
            setCount()
        }
    }



    return (
        <>
            <div className={`text-center ${loading ? 'spinner' : 'd-none'}`}>
                <img src={loader} alt='' className='spinAnimate' />
            </div>
            <Header />
            <div className={style.bannerParent}>
                <div className={style.cl_banner}>
                    <img src={helpcenterBanner} alt='collectionBanner' width={bannerimgsize} height={bannerimgsize} />
                </div>
                <div className={style.bannerContent}>
                    <div className={style.maxwidthcnt}>
                        <div className={style.bannerTit}>How can we <span>help you</span>?</div>
                        <div className='formInputs mx-4'>
                            <InputGroup className='mb-2'>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText className='px-2'>
                                        <svg
                                            width="17"
                                            height="17"
                                            viewBox="0 0 17 17"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M15.875 14.6562C16.0312 14.8125 16.0312 15.0625 15.875 15.1875L15.1562 15.9062C15.0312 16.0625 14.7812 16.0625 14.625 15.9062L10.8438 12.125C10.7812 12.0312 10.75 11.9375 10.75 11.8438V11.4375C9.59375 12.4062 8.125 13 6.5 13C2.90625 13 0 10.0938 0 6.5C0 2.9375 2.90625 0 6.5 0C10.0625 0 13 2.9375 13 6.5C13 8.125 12.375 9.625 11.4062 10.75H11.8125C11.9062 10.75 12 10.8125 12.0938 10.875L15.875 14.6562ZM6.5 11.5C9.25 11.5 11.5 9.28125 11.5 6.5C11.5 3.75 9.25 1.5 6.5 1.5C3.71875 1.5 1.5 3.75 1.5 6.5C1.5 9.28125 3.71875 11.5 6.5 11.5Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </InputGroupText>
                                </InputGroupAddon>
                                
                                <Input
                                    name='searchName'
                                    className='form-control'
                                    type='text'
                                    value={helpsearch}
                                    placeholder={t(
                                        'Search'
                                    )}
                                    onChange={handleOnKeyDown}
                                    autoComplete='off'
                                />
                            </InputGroup>
                        </div>
                    </div>
                </div>
            </div>
            <Outlet context={[data, count]} />
            <div className={style.getintouchSec}>
                <div className='container-fluid'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-6 col-md-8'>
                            <div className='git_wizard'>
                                <div className='secTit text-white'>{t("Get in")} <span>{t("touch")}.</span></div>
                                <div>
                                    <div className='d-flex align-items-center'>
                                        <div className='iconbx'>
                                            <img src={iconPhone} alt="iconPhone" />
                                        </div>
                                        <div className='IconRTxt'>{socialmedia?.contactNumber}</div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className='iconbx mb-0'>
                                            <img src={iconMail} alt="iconMail" />
                                        </div>
                                        <div className='IconRTxt mb-0'>{socialmedia?.contactMail}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HelpCenterLayout;