import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { useState } from 'react'
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import { chainId } from '../../../config/constants/chains'
import { useMakeOfferModalActions } from '../../../components/modals/makeOffer/store'
import { useConnectWalletModalActions } from '../../../components/modals/connect-wallet/store'
import { useAcceptOfferModalActions } from '../../../components/modals/accept-offer/store'

import user from '../../../../src/assets/images/header/profileDDImg1.svg'
import { chainIdMap, chainMap } from '../../../utils/getFilterData'
import { useOkxSwitchNetwork } from '../../../hooks/useOkxSwitchNetwork'





export function ListStation({ asset }) {
	const [activeTab, setActiveTab] = useState('1')
	const navigate = useNavigate()

	const isLogin = localStorage.getItem('isLoggedIn')

	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab)
	}

	const { t } = useTranslation()

	const { address: account } = useAccount()
	const { switchNetworkAsync } = useSwitchNetwork()
	const { switchNetwork } = useOkxSwitchNetwork()
	const walletType = localStorage.getItem('walletType')
	const { chain } = useNetwork()

	const { toggleMakeOfferModal, setAsset: setMakeOfferAssetState } =
		useMakeOfferModalActions()
	const { toggleConnectWalletModal, setConnectWalletModalState } =
		useConnectWalletModalActions()
	const { toggleAcceptOfferModal, setAsset } = useAcceptOfferModalActions()

	const owner = !!account && asset.owners.find((o) => o.address === account.toLowerCase())

	const handleMakeOffer = async () => {
		setMakeOfferAssetState(asset)

		setConnectWalletModalState({ chainId: chainIdMap[asset.details.chain] })
		if (!account) {
			toggleConnectWalletModal()
			setConnectWalletModalState({
				callback: toggleMakeOfferModal
			})
			return
		}

		try {
			if (walletType === 'okx') {
				if (
					asset.details.chain !== chainMap[parseInt(window.okxwallet.chainId)]
				) {
					await switchNetwork(chainIdMap[asset.details.chain])
				}
			} else {
				if (asset.details.chain === 'binance' && chain.id !== chainId.BINANCE) {
					await switchNetworkAsync(chainId.BINANCE)
				} else if (
					asset.details.chain === 'ethereum' &&
					chain.id !== chainId.ETHEREUM
				) {
					await switchNetworkAsync(chainId.ETHEREUM)
				} else if (
					asset.details.chain === 'polygon' &&
					chain.id !== chainId.AMOY
				) {
					await switchNetworkAsync(chainId.AMOY)
				}
			}
			

			toggleMakeOfferModal()
		} catch (error) { }
	}

	return (
		<>
			{asset.details.contractStandard === 'ERC1155' ? (
				!!owner && asset.owners.length !== 1 ? (
					<div className='nftCard d-block'>
						<div className='row w-100'>
							<div className='col-lg-6'>
								<Nav
									tabs
									className='trad_tab_hd navNftDetails d-inline-flex'
								>
									<NavItem>
										<NavLink
											className={classnames({
												active: activeTab === '1'
											})}
											onClick={() => toggle('1')}
										>
											{t("Sell")}
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={classnames({
												active: activeTab === '2'
											})}
											onClick={() => toggle('2')}
										>
											{t("Buy")}
										</NavLink>
									</NavItem>
								</Nav>
							</div>
							<div className='col-lg-6 text-right'>
								<div className='d-inline-flex align-items-center'>
									<img src={user} alt='user' />
									<span className='pl-2 fs-15 fw-400'>
										{t("You Own")} {asset.details.ownedSupplies}
									</span>
								</div>
							</div>
						</div>
						<div>
							<TabContent activeTab={activeTab}>
								<TabPane tabId='1'>
									<>
										{!!account &&
											owner &&
											account.toLowerCase() !==
											asset?.bestOffer?.address &&
											account.toLowerCase() ===
											owner.address &&
											asset.details.hasOffers && (
												<div className='d-flex align-items-center w-100 p-1'>
													<div className='d-flex flex-column'>
														<span className='cardLabel'>
															{t("Best Offer")}
														</span>
														<div className='d-flex align-items-center mb-2'>
															<h3 className='mb-0 mr-2'>{`${asset.bestOffer.price} ${asset.bestOffer.currency}`}</h3>
															<span className='cardLabel'>
																$
																{parseFloat(
																	asset.bestOffer.priceInUsd.toFixed(
																		2
																	)
																)}
															</span>
														</div>
													</div>
												</div>
											)}

										<div className='w-100 d-flex align-items-center flex-nowrap'>
											{!!account &&
												owner &&
												account.toLowerCase() !==
												asset?.bestOffer?.address &&
												account.toLowerCase() ===
												owner.address ? (
												<>
													<button
														className={`btn gradientBtn btn160 mr-2 my-1 ${!asset.details
															.hasOffers
															? 'w-100'
															: ''
															}`}
														onClick={() =>
															navigate(
																`/listforsale/${asset.details.chain}/${asset.details.contractAddress}/${asset.details.nftId}`
															)
														}
														style={
															asset.details
																.hasOffers
																? {
																	flexShrink:
																		'1',
																	minWidth:
																		'220px'
																}
																: {}
														}
													>
														{t('List For Sale')}
													</button>

													{asset.details
														.hasOffers && (
															<button
																className='btn darkBtn my-1 w-100'
																onClick={() => {
																	setAsset(asset)
																	toggleAcceptOfferModal()
																}}
																style={{
																	flexGrow: '1'
																}}
															>
																<svg
																	className='mr-2'
																	width='19'
																	height='8'
																	viewBox='0 0 19 8'
																	fill='none'
																	xmlns='http://www.w3.org/2000/svg'
																>
																	<path
																		d='M17.7188 1.75C18.2188 2.1875 18.5 2.8125 18.4688 3.4375C18.4688 4.09375 18.1562 4.6875 17.625 5.0625L14.5 7.4375C14 7.8125 13.375 8 12.75 8H1C0.71875 8 0.5 7.78125 0.5 7.5V7C0.5 6.75 0.71875 6.5 1 6.5H12.75C13.0625 6.5 13.3438 6.40625 13.5938 6.21875L16.75 3.875C17.0625 3.625 17.0938 3.1875 16.75 2.90625C16.5312 2.71875 16.125 2.71875 15.875 2.90625L13.9688 4.34375C13.7188 4.53125 13.4375 4.625 13.125 4.625H8.90625C8.6875 4.625 8.5 4.4375 8.5 4.21875V4.1875C8.5 3.96875 8.6875 3.78125 8.90625 3.78125H10.7812C11.7188 3.78125 11.6875 2.5 10.8125 2.5H6.3125C5.75 2.5 5.21875 2.6875 4.78125 3.03125L3.5 4H1C0.71875 4 0.5 3.78125 0.5 3.5V3C0.5 2.75 0.71875 2.5 1 2.5H2.96875L3.875 1.84375C4.5625 1.3125 5.4375 1 6.3125 1H10.8125C12.0312 1 12.9688 1.9375 12.9688 3.125H13.0938L14.9688 1.71875C15.5625 1.25 16.75 0.9375 17.7188 1.75Z'
																		fill='white'
																	/>
																</svg>
																{t("Accept Offer")}
																<span class='fw-400 pl-1'>{`${parseFloat(
																	asset.bestOffer.price.toFixed(
																		5
																	)
																)} ${asset.bestOffer
																	.currency
																	}`}</span>
															</button>
														)}
												</>
											) : (
												<div style={{ flexGrow: '1' }}>
													<button
														className='btn gradientBtn btn160 mr-2 my-1 d-inline-flex w-100'
														onClick={() =>
															navigate(
																`/listforsale/${asset.details.chain}/${asset.details.contractAddress}/${asset.details.nftId}`
															)
														}
													>
														{t("List for Sale")}
													</button>
												</div>
											)}
										</div>
									</>
								</TabPane>
								<TabPane tabId='2'>
									{/* <div
								class='w-100 d-flex align-items-center justify-content-between flex-wrap'
								style={{ gap: '10px' }}
							>
								<button class='btn gradientBtn btn160 mr-2 my-1 flex-grow-1'>
									Buy now
								</button>
							</div> */}
									<div style={{ flexGrow: '1' }}>
										<button
											className='btn gradientBtn btn160 mr-2 my-1 d-inline-flex w-100'
											onClick={handleMakeOffer}
										>
											{t("Make Offer")}
										</button>
									</div>
								</TabPane>
							</TabContent>
						</div>
					</div>
				) : !!owner && asset.owners.length === 1 ? (
					<>
						{!!account &&
							owner &&
							account.toLowerCase() === owner.address &&
							asset.details.hasOffers && (
								<div className='d-flex align-items-center w-100 p-1'>
									<div className='d-flex flex-column'>
										<span className='cardLabel'>
											{t("Best Offer")}
										</span>
										<div className='d-flex align-items-center mb-2'>
											<h3 className='mb-0 mr-2'>{`${asset.bestOffer.price} ${asset.bestOffer.currency}`}</h3>
											<span className='cardLabel'>
												$
												{parseFloat(
													asset.bestOffer.priceInUsd.toFixed(
														2
													)
												)}
											</span>
										</div>
									</div>
									<div className='text-right ml-auto' >
										{asset.details.hasOffers ?
											<><div className='d-inline-flex align-items-center'>
												<img src={user} alt='user' />
												<span className='pl-2 fs-15 fw-400'>
													{t("You Own")} {asset.details.ownedSupplies}
												</span>
											</div>
											</> : ""}
									</div>
								</div>
							)}
						{!!account &&
							owner &&
							account.toLowerCase() === owner.address ? (
							<>
								{!asset.details.hasOffers ?
									<div className='text-right p-1 w-100'>
										<div className='d-inline-flex align-items-center'>
											<img src={user} alt='user' />
											<span className='pl-2 fs-15 fw-400'>
												{t("You Own")} {asset.details.ownedSupplies}
											</span>
										</div>
									</div> : ""}
								<div className='w-100 d-flex align-items-center flex-nowrap'>
									<button
										className={`btn gradientBtn btn160 mr-2 my-1 ${!asset.details.hasOffers
											? 'w-100'
											: ''
											}`}
										onClick={() =>
											navigate(
												`/listforsale/${asset.details.chain}/${asset.details.contractAddress}/${asset.details.nftId}`
											)
										}
										style={
											asset.details.hasOffers
												? {
													flexShrink: '1',
													minWidth: '220px'
												}
												: {}
										}
									>
										{t('List For Sale')}
									</button>


									{asset.details.hasOffers && (
										<button
											className='btn darkBtn my-1 w-100'
											onClick={() => {
												setAsset(asset)
												toggleAcceptOfferModal()
											}}
											style={{ flexGrow: '1' }}
										>
											<svg
												className='mr-2'
												width='19'
												height='8'
												viewBox='0 0 19 8'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													d='M17.7188 1.75C18.2188 2.1875 18.5 2.8125 18.4688 3.4375C18.4688 4.09375 18.1562 4.6875 17.625 5.0625L14.5 7.4375C14 7.8125 13.375 8 12.75 8H1C0.71875 8 0.5 7.78125 0.5 7.5V7C0.5 6.75 0.71875 6.5 1 6.5H12.75C13.0625 6.5 13.3438 6.40625 13.5938 6.21875L16.75 3.875C17.0625 3.625 17.0938 3.1875 16.75 2.90625C16.5312 2.71875 16.125 2.71875 15.875 2.90625L13.9688 4.34375C13.7188 4.53125 13.4375 4.625 13.125 4.625H8.90625C8.6875 4.625 8.5 4.4375 8.5 4.21875V4.1875C8.5 3.96875 8.6875 3.78125 8.90625 3.78125H10.7812C11.7188 3.78125 11.6875 2.5 10.8125 2.5H6.3125C5.75 2.5 5.21875 2.6875 4.78125 3.03125L3.5 4H1C0.71875 4 0.5 3.78125 0.5 3.5V3C0.5 2.75 0.71875 2.5 1 2.5H2.96875L3.875 1.84375C4.5625 1.3125 5.4375 1 6.3125 1H10.8125C12.0312 1 12.9688 1.9375 12.9688 3.125H13.0938L14.9688 1.71875C15.5625 1.25 16.75 0.9375 17.7188 1.75Z'
													fill='white'
												/>
											</svg>
											{t("Accept Offer")}
											<span class='fw-400 pl-1'>{`${parseFloat(
												asset.bestOffer.price.toFixed(5)
											)} ${asset.bestOffer.currency
												}`}</span>
										</button>
									)}
								</div>
							</>
						) : (
							<div className='w-100 d-flex align-items-center flex-nowrap'>
								<button
									className='btn gradientBtn btn160 mr-2 my-1 flex-grow-1'
									onClick={handleMakeOffer}
								>
									{t('Make Offer')}
								</button>
							</div>
						)}
					</>
				) : (
					<div className='nftCard d-block'>
						<div style={{ flexGrow: '1' }}>
							<button
								className='btn gradientBtn btn160 mr-2 my-1 d-inline-flex w-100'
								onClick={handleMakeOffer}
							>
								{t('Make Offer')}
							</button>
						</div>
					</div>
				)
			) : (
				<>
					{!!account &&
						owner &&
						account.toLowerCase() === owner.address &&
						asset.details.hasOffers && (
							<div className='d-flex align-items-center w-100 p-1'>
								<div className='d-flex flex-column'>
									<span className='cardLabel'>
										{t("Best Offer")}
									</span>
									<div className='d-flex align-items-center mb-2'>
										<h3 className='mb-0 mr-2'>{`${asset.bestOffer.price} ${asset.bestOffer.currency}`}</h3>
										<span className='cardLabel'>
											$
											{parseFloat(
												asset.bestOffer.priceInUsd.toFixed(
													2
												)
											)}
										</span>
									</div>
								</div>
							</div>
						)}
					{!!account &&
						owner &&
						account.toLowerCase() === owner.address ? (
						<>
							<div className='w-100 d-flex align-items-center flex-nowrap'>
								<button
									className={`btn gradientBtn btn160 mr-2 my-1 ${!asset.details.hasOffers ? 'w-100' : ''
										}`}
									onClick={() =>
										navigate(
											`/listforsale/${asset.details.chain}/${asset.details.contractAddress}/${asset.details.nftId}`
										)
									}
									style={
										asset.details.hasOffers
											? {
												flexShrink: '1',
												minWidth: '220px'
											}
											: {}
									}
								>
									{t('List For Sale')}
								</button>

								{asset.details.hasOffers && (

									<button
										className='btn darkBtn my-1 w-100'
										onClick={() => {
											setAsset(asset)
											toggleAcceptOfferModal()
										}}
										style={{ flexGrow: '1' }}
									>
										<svg
											className='mr-2'
											width='19'
											height='8'
											viewBox='0 0 19 8'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M17.7188 1.75C18.2188 2.1875 18.5 2.8125 18.4688 3.4375C18.4688 4.09375 18.1562 4.6875 17.625 5.0625L14.5 7.4375C14 7.8125 13.375 8 12.75 8H1C0.71875 8 0.5 7.78125 0.5 7.5V7C0.5 6.75 0.71875 6.5 1 6.5H12.75C13.0625 6.5 13.3438 6.40625 13.5938 6.21875L16.75 3.875C17.0625 3.625 17.0938 3.1875 16.75 2.90625C16.5312 2.71875 16.125 2.71875 15.875 2.90625L13.9688 4.34375C13.7188 4.53125 13.4375 4.625 13.125 4.625H8.90625C8.6875 4.625 8.5 4.4375 8.5 4.21875V4.1875C8.5 3.96875 8.6875 3.78125 8.90625 3.78125H10.7812C11.7188 3.78125 11.6875 2.5 10.8125 2.5H6.3125C5.75 2.5 5.21875 2.6875 4.78125 3.03125L3.5 4H1C0.71875 4 0.5 3.78125 0.5 3.5V3C0.5 2.75 0.71875 2.5 1 2.5H2.96875L3.875 1.84375C4.5625 1.3125 5.4375 1 6.3125 1H10.8125C12.0312 1 12.9688 1.9375 12.9688 3.125H13.0938L14.9688 1.71875C15.5625 1.25 16.75 0.9375 17.7188 1.75Z'
												fill='white'
											/>
										</svg>
										{t("Accept Offer")}
										<span class='fw-400 pl-1'>{`${parseFloat(
											asset.bestOffer.price.toFixed(5)
										)} ${asset.bestOffer.currency}`}</span>
									</button>
								)}
							</div>
						</>
					) : (
						<div className='w-100 d-flex align-items-center flex-nowrap'>
							<button
								className='btn gradientBtn btn160 mr-2 my-1 flex-grow-1'
								onClick={handleMakeOffer}
							>
								{t('Make Offer')}
							</button>
						</div>
					)}
				</>
			)
			}
		</>
	)
}
