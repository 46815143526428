import { forwardRef, useCallback, useRef, useState } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { fetchActivity } from '../apis/fetchActivity'
import { hoursToGo } from '../../../utils/formatRoundTime'

import cart from '../../../assets/images/cart.png'
import tag from '../../../assets/images/tag.png'
import clock from '../../../assets/images/clock.svg'
import offerIcon from '../../../assets/images/collection/offerIcon.svg'
import collectionOfferIcon from '../../../assets/images/collection/collectionOfferIcon.svg'
import cndds from '../../../assets/images/cndds.png'
import cnddsLight from '../../../assets/images/cnddsLight.svg'
import { formatAmount } from '../../../utils/formatAmount'


const Activity = forwardRef(function Activity({ activity, i }, ref) {
	const navigate = useNavigate()

	const { t } = useTranslation()


	return (
		<tr ref={ref ? ref : null}>
			<td>
				{' '}
				<div className='d-flex align-items-center'>
					<div className='tableTag'>
						<img
							src={
								activity.type === 'list'
									? tag
									: activity.type === 'sale'
										? cart
										: activity.type === 'make-collection-offer'
											? collectionOfferIcon
											: activity.type === 'make-offer'
												? offerIcon
												: cart
							}
							className='mr-2'
						/>
						{activity.type === 'make-offer'
							? t('Offer')
							: activity.type === 'make-collection-offer'
								? t('Collection Offer')
								: t(activity.type)}
					</div>
					{['make-collection-offer', 'make-offer', 'list'].includes(
						activity.type
					) && activity.isExpired ? (
						<div className='tableTag ml-2'>
							<span className='text-danger '>{t('Expired')}</span>
						</div>
					) : null}
				</div>
			</td>
			<td>
				{activity.type === 'mint'
					? '-'
					: `${formatAmount(parseFloat(activity.price.toFixed(8)))} ${activity.currency
					}`}
			</td>
			{activity.fromAddress === '' || null ? (
				<td className='yellowTxt'>-</td>
			) : (
				<td
					className='yellowTxt hover'
					onClick={() => navigate(`/profile/${activity.fromAddress}`)}
				>
					{activity.from === 'Unnamed'
						? activity.fromAddress
						: activity.from}
				</td>
			)}
			{activity.to === null || '' ? (
				<td className='yellowTxt'>-</td>
			) : (
				<td
					className='yellowTxt hover'
					onClick={() => navigate(`/profile/${activity.toAddress}`)}
				>
					{activity.to === 'Unnamed'
						? activity.toAddress
						: activity.to}
				</td>
			)}
			<td>{hoursToGo(new Date(activity.createdAt).getTime())}</td>
		</tr>
	)
})

export function AssetActivity({ asset }) {
	const { t } = useTranslation()
	const [activeTab, setActiveTab] = useState('all')
	const [selectedTabs, setSelectedTabs] = useState(['all'])

	const params = useParams()
	const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
		useInfiniteQuery({
			queryKey: [
				'activities',
				params.collectionAddress,
				params.nftId,
				selectedTabs
			],
			queryFn: ({ pageParam = 1 }) =>
				fetchActivity(
					params.collectionAddress,
					params.nftId,
					selectedTabs,
					pageParam
				),
			getNextPageParam: (lastPage, allPages) => {
				return lastPage?.length ? allPages?.length + 1 : undefined
			},
			enabled: !!(params.collectionAddress && params.nftId)
		})

	const inetrsectionObserver = useRef()
	const lastActivityRef = useCallback(
		(activity) => {
			if (isFetchingNextPage) {
				return
			}

			if (inetrsectionObserver.current) {
				inetrsectionObserver.current.disconnect()
			}

			inetrsectionObserver.current = new IntersectionObserver(
				(activities) => {
					if (activities[0].isIntersecting && hasNextPage) {
						fetchNextPage()
					}
				}
			)

			if (activity) {
				inetrsectionObserver.current.observe(activity)
			}
		},
		[isFetchingNextPage, hasNextPage, fetchNextPage]
	)

	const activities = data?.pages?.map((page) => {
		return page?.map((activity, i) => {
			if (page.length === i + 1) {
				return (
					<Activity
						key={activity._id}
						ref={lastActivityRef}
						activity={activity}
					/>
				)
			}
			return <Activity key={activity._id} activity={activity} i={i} />
		})
	})

	const hasNoActivities = activities?.[0]?.length === 0

	function handleTabClick(tab) {
		if (tab === 'all') {
			setSelectedTabs(['all'])
			return
		}

		if (selectedTabs.includes(tab)) {
			setSelectedTabs((st) => st.filter((t) => t !== 'all').filter((t) => t !== tab))
			return
		}

		setSelectedTabs((st) => [...st.filter((t) => t !== 'all'), tab])
	}

	return (
		<div className='col-12'>
			<div className='nftCard'>
				<h3 className='heading mb-3'>{t('Activity')}</h3>
				<Nav tabs className='trad_tab_hd navNftDetails'>
					<NavItem>
						<NavLink
							className={classnames({
								active: selectedTabs.includes('all')
							})}
							onClick={() => handleTabClick('all')}
						>
							{t('All')}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({
								active: selectedTabs.includes('sales')
							})}
							onClick={() => handleTabClick('sales')}
						>
							{t('Sales')}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({
								active: selectedTabs.includes('listings')
							})}
							onClick={() => handleTabClick('listings')}
						>
							{t('Listings')}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({
								active: selectedTabs.includes('offers')
							})}
							onClick={() => handleTabClick('offers')}
						>
							{t('Offers')}
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent
					activeTab={'all'}
					className='w-100 scroller'
					style={{
						height: '400px',
						overflowY: 'auto',
						overflowX: 'hidden'
					}}
				>
					<TabPane tabId='all'>
						<div className='row'>
							<div className='col-12'>
								<div className='table-responsive activityTableCnt scroller'>
									<table className='table table-borderless table-hover mb-0 hmecollection'>
										<thead className='trd_head'>
											{!hasNoActivities && <tr>
												<th></th>
												<th>{t('Price')}</th>
												<th>{t('From')}</th>
												<th>{t('To')}</th>
												<th>
													<img src={clock} />
												</th>
											</tr>}
										</thead>
										<tbody className='trdtable_body'>
											{activities}
										</tbody>
									</table>
								</div>
							</div>
							{hasNoActivities && (
								<div className='col-12'>
									<div class='noResf text-center'>
										<img
											class='img-fluid'
											src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : cndds}
											alt=''
										/>
										<h1>{t('Nothing to display.')}</h1>
									</div>
								</div>
							)}
						</div>
					</TabPane>
					<TabPane tabId='sales'>
						<div className='row'>
							<div className='col-12'>
								<div className='table-responsive'>
									<table className='table table-borderless table-hover mb-0 hmecollection'>
										<thead className='trd_head'>
											{!hasNoActivities && <tr>
												<th></th>
												<th>{t('Price')}</th>
												<th>{t('From')}</th>
												<th>{t('To')}</th>
												<th>
													<img src={clock} />
												</th>
											</tr>}
										</thead>
										<tbody className='trdtable_body'>
											{activities}
										</tbody>
									</table>
								</div>
							</div>
							{hasNoActivities && (
								<div className='col-12'>
									<div class='noResf text-center'>
										<img
											class='img-fluid'
											src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : cndds}
											alt=''
										/>
										<h1>{t('Nothing to display.')}</h1>
									</div>
								</div>
							)}
						</div>
					</TabPane>
					<TabPane tabId='listings'>
						<div className='row'>
							<div className='col-12'>
								<div className='table-responsive scroller'>
									<table className='table table-borderless table-hover mb-0 hmecollection'>
										<thead className='trd_head'>
											{!hasNoActivities && <tr>
												<th></th>
												<th>{t('Price')}</th>
												<th style={{ width: '280px' }}>
													{t('From')}
												</th>
												<th style={{ width: '280px' }}>
													{t('To')}
												</th>
												<th>
													<img src={clock} />
												</th>
											</tr>}
										</thead>
										<tbody className='trdtable_body'>
											{activities}
										</tbody>
									</table>
								</div>
							</div>
							{hasNoActivities && (
								<div className='col-12'>
									<div class='noResf text-center'>
										<img
											class='img-fluid'
											src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : cndds}
											alt=''
										/>
										<h1>{t('Nothing to display.')}</h1>
									</div>
								</div>
							)}
						</div>
					</TabPane>
					<TabPane tabId='offers'>
						<div className='row'>
							<div className='col-12'>
								<div className='table-responsive'>
									<table className='table table-borderless table-hover mb-0 hmecollection'>
										<thead className='trd_head'>
											{!hasNoActivities && <tr>
												<th></th>
												<th>{t('Price')}</th>
												<th style={{ width: '280px' }}>
													{t('From')}
												</th>
												<th style={{ width: '280px' }}>
													{t('To')}
												</th>
												<th>
													<img src={clock} alt='' />
												</th>
											</tr>}
										</thead>
										<tbody className='trdtable_body'>
											{activities}
										</tbody>
									</table>
								</div>
							</div>
							{hasNoActivities && (
								<div className='col-12'>
									<div class='noResf text-center'>
										<img
											class='img-fluid'
											src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : cndds}
											alt=''
										/>
										<h1>{t('Nothing to display.')}</h1>
									</div>
								</div>
							)}
						</div>
					</TabPane>
				</TabContent>
			</div>
		</div>
	)
}
