import { getContract as viemGetContract } from 'viem'

export const getContract = ({ abi, address, signer, publicClient }) => {
	const contract = viemGetContract({
		abi,
		address,
		publicClient,
		walletClient: signer
	})

	return {
		...contract,
		account: signer?.account,
		chain: signer?.chain
	}
}
