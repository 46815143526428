import React, { useEffect } from 'react'
import style from './homeExplorerLayout.module.scss'
import { Outlet } from 'react-router-dom'
import homeExplorerBanner from '../../assets/images/homeExplorer/homeExplorerBanner.png'
import { useTranslation } from 'react-i18next'

const HomeExplorerLayout = () => {
	const bannerimgsize = '100%'

	useEffect(() => {
		document.body.classList.add('helpcenterpages')
	}, [])
	const { t } = useTranslation()


	return (
		<>
			<div className='position-relative'>
				<div className={style.cl_banner}>
					<img
						src={homeExplorerBanner}
						alt='collectionBanner'
						width={bannerimgsize}
						height={bannerimgsize}
					/>
				</div>

				<div className={style.bannerContent}>
					<div className={style.maxwidthcnt}>
						<div className='container-fluid'>
							<div className='row'>
								<div className='col-12'>
									<div className={style.bannerTit}>
										{t("Explore")} <span>{t("best NFTs")}</span>!
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Outlet />
		</>
	)
}

export default HomeExplorerLayout
