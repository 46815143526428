import { create } from 'zustand'

const usePurchaseLoadingModalStore = create((set) => ({
	isOpen: false,
	heading: 'Purchase',
	actions: {
		togglePurchaseLoadingModal: () =>
			set((state) => ({
				...state,
				isOpen: !state.isOpen
			})),
		closePurchaseLoadingModal: () =>
			set((state) => ({
				...state,
				isOpen: false
			})),
		setHeading: (heading) =>
			set((state) => ({
				...state,
				heading
			}))
	}
}))

export const usePurchaseLoadingModalState = () =>
	usePurchaseLoadingModalStore(({ isOpen, heading }) => ({
		isOpen,
		heading
	}))

export const usePurchaseLoadingModalActions = () =>
	usePurchaseLoadingModalStore((state) => state.actions)
