import { create } from 'zustand'

const useReportModalStore = create(set => ({
	isOpen: false,
	asset: null,
	actions: {
		toggleReportModal: () =>
			set(state => ({
				...state,
				isOpen: !state.isOpen
			})),
		setAsset: asset =>
			set(state => ({
				...state,
				asset
			}))
	}
}))

export const useReportModalState = () =>
	useReportModalStore(({ isOpen, asset }) => ({
		isOpen,
		asset
	}))

export const useReportModalActions = () =>
	useReportModalStore(state => state.actions)
