import React, { useEffect } from 'react'

import { Outlet } from 'react-router-dom'
import { useToggleClassName } from '../../hooks/useToggleClassName'

const CollectionLayout = () => {
	const bannerimgsize = '100%'

	useEffect(() => {
		document.body.classList.add('collectionpages')
	}, [])
	useToggleClassName()

	return (
		<>
			<section className='mycollectionSec'>
				<div className='container-fluid'>
					<Outlet />
				</div>
			</section>
		</>
	)
}

export default CollectionLayout
