import { useQuery } from '@tanstack/react-query'

import DataService from '../../../lib/DataService'

const { getRequest } = DataService()
export async function fetchCartItems(address) {
	
	return getRequest(`cart?address=${address}`)

}

export function useFetchCartItems(address, isOpen) {
	return useQuery({
		queryKey: ['cart', address, isOpen],
		queryFn: () => fetchCartItems(address),
		enabled: !!address
	})
}
