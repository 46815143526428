import { CountryCodes } from 'validator/lib/isISO31661Alpha2'
import getTimePeriods from './getTimePeriods'

export const padTime = num => num.toString().padStart(2, '0')

export const formatRoundTime = secondsBetweenBlocks => {
	const { days, hours, minutes, seconds } = getTimePeriods(secondsBetweenBlocks)
	const minutesSeconds = `${padTime(minutes)}:${padTime(seconds)}`

	if (days > 0) {
		return `${padTime(days)}:${padTime(hours)}:${minutesSeconds}`
	}

	if (hours > 0) {
		return `${padTime(hours)}:${minutesSeconds}`
	}

	return minutesSeconds
}

export const getAuctionCountdown = secondsBetweenBlocks => {
	const { days, hours, minutes, seconds } = getTimePeriods(secondsBetweenBlocks)

	return {
		days: padTime(days),
		hours: padTime(hours),
		minutes: padTime(minutes),
		seconds: padTime(seconds)
	}
}

export function timeUntil(futureDateInSeconds) {
	const now = new Date()
	const diffInSeconds = futureDateInSeconds - now.getTime() / 1000
	const { days, hours, minutes, seconds } = getTimePeriods(diffInSeconds)

	if (days > 0) {
		return `${days} ${days === 1 ? 'Day' : 'Days'}`
	} else if (hours > 0) {
		return `${hours} ${days === 1 ? 'Hour' : 'Hours'}`
	} else if (minutes > 0) {
		return `${minutes} ${days === 1 ? 'Minute' : 'Minutes'}`
	} else {
		return `${seconds} Seconds`
	}
}

export const daysSince = date => {
	const dateInSeconds = new Date(date).getTime() / 1000

	const { days, hours, minutes, seconds } = getTimePeriods(dateInSeconds)

	if (days > 0) {
		return `${padTime(days)}d`
	}

	if (hours > 0) {
		return `${padTime(hours)}h`
	}

	if (minutes > 0) {
		return `${padTime(minutes)}m`
	}

	return `${padTime(seconds)}s`
}

export function calculateTimeSinceGivenDate(givenDate) {
	const currentDate = new Date()
	const timeSinceGivenDate =
		currentDate.getTime() - new Date(givenDate).getTime()

	const days = Math.floor(timeSinceGivenDate / (1000 * 60 * 60 * 24))
	const hours = Math.floor(
		(timeSinceGivenDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
	)
	const minutes = Math.floor(
		(timeSinceGivenDate % (1000 * 60 * 60)) / (1000 * 60)
	)
	const seconds = Math.floor((timeSinceGivenDate % (1000 * 60)) / 1000)

	if (days > 0) {
		return `${days} d`
	} else if (hours > 0) {
		return `${hours} h`
	} else if (minutes > 0) {
		return `${minutes} m`
	} else {
		return `${seconds} s`
	}
}

export function calculateTimeUntilGivenDate(givenDate) {
	const currentDate = new Date()
	const timeSinceGivenDate = givenDate - currentDate.getTime() / 1000

	const days = Math.floor(timeSinceGivenDate / (1000 * 60 * 60 * 24))
	const hours = Math.floor(
		(timeSinceGivenDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
	)
	const minutes = Math.floor(
		(timeSinceGivenDate % (1000 * 60 * 60)) / (1000 * 60)
	)
	const seconds = Math.floor((timeSinceGivenDate % (1000 * 60)) / 1000)

	if (days > 0) {
		return `${days} d`
	} else if (hours > 0) {
		return `${hours} h`
	} else if (minutes > 0) {
		return `${minutes} m`
	} else {
		return `${seconds} s`
	}
}

export function hoursToGo(futureDateInSeconds) {
	const now = new Date()
	const diffInSeconds = futureDateInSeconds - now.getTime() / 1000
	const { days, hours, minutes, seconds } = getTimePeriods(diffInSeconds)

	if (days > 0) {
		return `${days} ${days === 1 ? 'day' : 'days'}`
	} else if (hours > 0) {
		return `${hours} ${days === 1 ? 'hour' : 'hours'}`
	} else if (minutes > 0) {
		return `${minutes} ${days === 1 ? 'minute' : 'minutes'}`
	} else {
		return `${Math.round(seconds)} ${Math.round(seconds) === 1 ? 'second' : 'seconds'}`
	}
}