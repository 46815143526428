import { useQuery } from '@tanstack/react-query'

import { ThemeContext } from '../../../views/context/context'
import { useContext } from 'react'
import DataService from '../../../lib/DataService'

const { getRequest } = DataService()
function collectionFloorChart(collectionAddress, Analyticsfilter) {
	return getRequest(`collection/floor-chart?contractAddress=${collectionAddress}&filter=${Analyticsfilter}`)
}

export function useCollectionFloorChart(collectionAddress) {
	const { Analyticsfilter, setAnalyticsFilter } = useContext(ThemeContext)

	return useQuery({
		queryKey: ['floor', collectionAddress, Analyticsfilter],
		queryFn: () => collectionFloorChart(collectionAddress, Analyticsfilter),
		enabled: !!(collectionAddress)
	})
}
