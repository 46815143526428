import React from 'react'
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import {
    useAccount,
} from 'wagmi'
import loader from '../../assets/images/Loader.png'
import { useTranslation } from 'react-i18next';
import DataService from '../../lib/DataService'

export default function Conversation() {
    const {  postRequest } = DataService()
    const { id } = useParams();
    const { t } = useTranslation()

    const [loading, setLoading] = useState(false);
    const [emailres, setEmail] = useState("");
    const [allchat, setAllChat] = useState([]);
    const [ticketuser, setusername] = useState("");

    const [reply, setReply] = useState("");

    const navigate = useNavigate()

    const { address, isConnected } = useAccount();

    useEffect(() => {
        GetProfile();
    }, [address, emailres, id]);

    const GetProfile = async () => {
        try {
            const update = await postRequest("user/getSingleProfile", { address: address ? address : "" });
            if (update.status === true) {
                setEmail(update.data.email);
                conversationhistory(update.data.email, id);
            }
            else {
                setEmail("");
            }
        } catch (error) {
            console.error('Error fetching profile data:', error);
        }
    };


    const conversationhistory = async (email, ticketId) => {
        if (ticketId && email) {
            setLoading(true);

            try {
                const responseData = await postRequest('user/ticket-conversation', { id: ticketId, email: email });
                if (responseData?.status === true) {
                    setAllChat(responseData.data.comments);
                    setReply(responseData.data.count === 2);
                    const tickeruser = responseData?.data?.comments[0]?.via?.source?.to?.name
                    setusername(tickeruser)
                    setLoading(false);
                } else {
                    navigate('/supportTicket')
                }
            } catch (error) {
                console.error('Error fetching ticket conversation:', error);
                setLoading(false);
            }
        }
    };

   
    return (
        <div>
            <div className={`text-center ${loading ? 'spinner' : 'd-none'}`}>
                <img src={loader} alt='' className='spinAnimate' />
            </div>
            <div className='container-fluid'>

                <div className='row'>
                    <div className='col-12 text-center'>
                        <div className='cmsTit mb-3'>{t('Conversation History')}</div>
                    </div>
                </div>
                <div className='row justify-content-center'>
                    <div className='col-lg-10'>
                        <div className='filtl mb-3'   >

                            <div className='messageChatBox'>
                                {allchat?.map((data, index) => (

                                    (data.via.channel === "api" || data.via.channel === "email") ?
                                        <div className='userMessage'>
                                            <div class="messagebx">
                                                <div className='fs-16 fw-500 mb-2'>{ticketuser}</div>
                                                <div className='dark-text-secondary'>{data.body}
                                                    <br />
                                                    {data.attachments && data.attachments[0]?.thumbnails[0]?.content_url
                                                        ? <img src={data.attachments[0].thumbnails[0].content_url} alt="" style={{ width: '100px', height: '100px' }} />
                                                        : ""}</div>
                                            </div>
                                            <div className='text-right fs-14 fw-400 dark-text'>{new Date(
                                                data.created_at
                                            ).toLocaleString()}</div>
                                        </div>
                                        :
                                        <div className='adminMessage'>
                                            <div class="messagebx">
                                                <div className='fs-16 fw-500 mb-2'>Admin</div>
                                                <div className='dark-text-secondary'>{data.body}<br />
                                                    {data.attachments && data.attachments[0]?.thumbnails[0]?.content_url
                                                        ? <img src={data.attachments[0].thumbnails[0].content_url} alt="" style={{ width: '100px', height: '100px' }} />
                                                        : ""}</div>
                                            </div>

                                            <div className='fs-14 fw-400 dark-text d-flex flexewrap'>
                                                <p style={{ color: "red" }}>
                                                    {index === allchat.length - 1 && Object.values(data.via.source.from).length === 0 ?
                                                        " Please respond to the administrator via email" : " "}
                                                </p>
                                                <p className="text-right" style={{ marginLeft: index === allchat.length - 1 && Object.values(data.via.source.from).length === 0 ? "43%" : "80%" }}>
                                                    {new Date(data.created_at).toLocaleString()}
                                                </p>
                                            </div>
                                        </div>

                                ))}

                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    )
}