import React, { useEffect, useState } from 'react';
import {  Modal} from 'reactstrap';
import '../../../src/views/Settings/Settings.scss';
import { useToast } from '../../utils/toast/customSuccess'
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DataService from '../../lib/DataService';
export const Subscribeverify = () => {

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const { t } = useTranslation()

    const toast = useToast()

    const navigate = useNavigate()
    const {postRequest } = DataService()

    const params = useParams();

    const data1 = {
        "_id": params.id,
    }

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        let data = await postRequest(`home/verify_subscribe`, data1);
        if (data.status == true) {
            toast.success(data.message)
            toggle()
        }
        else {
            toast.error(data.message)
            navigate("/")
        }
    }


    const myprofile = () => {
        navigate("/")
    }



    return (
        <>
            <Modal isOpen={modal} toggle={toggle} centered="true" className="curMdl createScsMdl" backdropClassName="selCurBp" backdrop='static' keyboard={false}>
                <div className="createContent">
                    <div className="w-100">
                        <div className="d-flex justify-content-center">
                            <svg className='mb12px' width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="40" height="40" rx="12" fill="#00B976" />
                                <path d="M25.5938 14.625C25.75 14.4688 26 14.4688 26.125 14.625L27.0312 15.5C27.1562 15.6562 27.1562 15.9062 27.0312 16.0312L17.6562 25.4062C17.5 25.5625 17.2812 25.5625 17.125 25.4062L12.9375 21.25C12.8125 21.0938 12.8125 20.8438 12.9375 20.7188L13.8438 19.8125C13.9688 19.6875 14.2188 19.6875 14.375 19.8125L17.375 22.8438L25.5938 14.625Z" fill="white" />
                            </svg>
                        </div>
                        <h3 className="walletHeading my-2">{t("Email Subscribed")}</h3>
                        <h3 className="walletSubHeading mb-3">{t("Your email address was successfully Subscribed.")}</h3>
                        <button type="button" className="btn btn-block gradientBtn" onClick={myprofile}>{t("Home Page")}</button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Subscribeverify;