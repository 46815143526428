import {
	BINANCE_BLOCK_EXPLORER_URL,
	ETHEREUM_BLOCK_EXPLORER_URL,
	POLYGON_BLOCK_EXPLORER_URL
} from '../../../config/constants'
import { formatAddress } from '../../../utils/formatAddress'
import { formatDate } from '../../../utils/formatDate'

import ETHIcon from '../../../assets/images/ethIconSm.png'
import BNBIcon from '../../../assets/images/bnbIconSm.png'
import MaticIcon from '../../../assets/images/maticIconSm.png'
import { useTranslation } from 'react-i18next'
import { chainIconMap } from '../../../utils/getFilterData'

export function AssetDetails({ asset }) {

	const { t } = useTranslation()

	const chainMap = {
		ethereum: 'Ethereum',
		binance: 'Binance Smart Chain',
		polygon: 'Polygon'
	}

	const blockExplorerUrlMap = {
		ethereum: ETHEREUM_BLOCK_EXPLORER_URL,
		binance: BINANCE_BLOCK_EXPLORER_URL,
		polygon: POLYGON_BLOCK_EXPLORER_URL
	}

	return (
		<>
			<div className='row align-items-center justify-content-between'>
				<div className='col-sm-6 col-auto'>
					<span className='tableLabel'>{t("Contract")}</span>
				</div>
				<div className='col-sm-6 text-sm-right col-auto'>
					<a
						href={
							blockExplorerUrlMap[asset.details.chain] +
							'address/' +
							asset.details.contractAddress
						}
						target='_blank'
						className='tableValue yellowTxt'
						style={{ textDecoration: 'none' }}
					>
						{formatAddress(asset.details.contractAddress, 8)}
					</a>
				</div>
			</div>
			<div className='row align-items-center justify-content-between'>
				<div className='col-sm-6 col-auto'>
					<span className='tableLabel'>{t("Token ID")}</span>
				</div>
				<div className='col-sm-6 col-auto text-sm-right'>
					<a
						href={asset.details.uri}
						target='_blank'
						className='tableValue yellowTxt'
						style={{ textDecoration: 'none' }}
					>
						{asset.details.nftId}
					</a>
				</div>
			</div>
			<div className='row align-items-center justify-content-between'>
				<div className='col-sm-6 col-auto'>
					<span className='tableLabel'>{t("Token Standard")}</span>
				</div>
				<div className='col-sm-6 col-auto text-sm-right'>
					<span className='tableValue'>{asset.details.contractStandard}</span>
				</div>
			</div>
			<div className='row align-items-center justify-content-between'>
				<div className='col-sm-6 col-auto'>
					<span className='tableLabel'>{t("Chain")}</span>
				</div>
				<div className='col-sm-6 col-auto text-sm-right'>
					<span className='tableValue'>
						<img
							src={chainIconMap[asset.details.chain]}
							alt='ETHIcon'
							className='mr-2'
						/>{' '}
						{chainMap[asset.details.chain]}
					</span>
				</div>
			</div>
			<div className='row align-items-center justify-content-between'>
				<div className='col-sm-6 col-auto'>
					<span className='tableLabel'>{t("Last Updated")}</span>
				</div>
				<div className='col-sm-6 col-auto text-sm-right'>
					<span className='tableValue'>
						{formatDate(asset.details.updatedAt)}
					</span>
				</div>
			</div>
			<div className='row align-items-center justify-content-between'>
				<div className='col-sm-6 col-auto'>
					<span className='tableLabel'>{t("Creator Earnings")}</span>
				</div>
				<div className='col-sm-6 col-auto text-sm-right'>
					<span className='tableValue'>{asset.details.royalty}%</span>
				</div>
			</div>
		</>
	)
}
