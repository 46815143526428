import { useEffect, useMemo } from 'react'
import {
	erc20ABI,
	useAccount,
	useBalance,
	useContractRead,
	useNetwork
} from 'wagmi'
import { formatEther, formatUnits } from 'viem'

import { chainId } from '../config/constants/chains'
import { BIG_ZERO, bigIntToBigNumber } from '../utils/bigNumber'

export const useTokenBalance = (tokenAddress) => {
	const { chain } = useNetwork()
	const { address: account } = useAccount()
	const chainId = chain?.id

	const { data: decimals } = useContractRead({
		chainId,
		abi: erc20ABI,
		address: tokenAddress,
		functionName: 'decimals'
	})

	const { data, status, ...rest } = useContractRead({
		chainId,
		abi: erc20ABI,
		address: tokenAddress,
		functionName: 'balanceOf',
		args: [account],
		enabled: !!account
	})

	return {
		...rest,
		fetchStatus: status,
		balance: useMemo(
			() =>
				typeof data !== 'undefined'
					? formatUnits(bigIntToBigNumber(data.toString()), decimals)
					: BIG_ZERO,
			[data]
		)
	}
}

export const useGetNativeCoinBalance = (chainId) => {
	const { address: account } = useAccount()
	const { status, refetch, data } = useBalance({
		chainId,
		address: account,
		enabled: !!account
	})

	return {
		balance: data?.value
			? parseFloat(formatEther(data.value)).toFixed(6)
			: 0,
		fetchStatus: status,
		refresh: refetch
	}
}

export const useGetEthBalance = () => {
	const { address: account } = useAccount()
	const { status, refetch, data } = useBalance({
		chainId: chainId.ETHEREUM,
		address: account,
		enabled: !!account
	})

	return {
		balance: data?.value ? parseFloat(formatEther(data.value)) : 0,
		fetchStatus: status,
		refresheth: refetch
	}
}

export const useGetBnbBalance = () => {
	const { address: account } = useAccount()
	const { status, refetch, data } = useBalance({
		chainId: chainId.BINANCE,
		address: account,
		enabled: !!account
	})

	return {
		balance: data?.value ? parseFloat(formatEther(data.value)) : 0,
		fetchStatus: status,
		refresh: refetch
	}
}

export const useGetMaticBalance = () => {
	const { address: account } = useAccount()
	const { status, refetch, data } = useBalance({
		chainId: chainId.POLYGON,
		address: account,
		enabled: !!account
	})

	return {
		balance: data?.value ? parseFloat(formatEther(data.value)) : 0,
		fetchStatus: status,
		refresh: refetch
	}
}
