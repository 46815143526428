import React, { useContext, useEffect, useState } from 'react'
import IconModal from '../../assets/images/IconModal.png'
import walletLoaderCnt from '../../assets/images/walletLoader.png'
import walletLightLoaderCnt from '../../assets/images/wallet-light-loader.png'
import walletLoader from '../../assets/images/loaderCircle.png'
import {
	Modal,
	UncontrolledCollapse,
	ModalHeader,
	ModalBody
} from 'reactstrap'
import { ThemeContext } from '../context/context'
import { useNavigate } from 'react-router-dom'
import { useToast } from '../../utils/toast/customSuccess'
import Web3 from 'web3'
import contracts from '../../config/constants/contracts'
import Minimalproxy from '../AbiArray/MinimalproxyEth.json'
import angleDown from '../../assets/images/angleDown.svg'
import {
	useGetBnbBalance,
	useGetEthBalance,
	useGetMaticBalance
} from '../../hooks/useBalance'
import {
	useAccount,
	useConnect,
	useNetwork,
	useSwitchNetwork,
	useBalance
} from 'wagmi'
import { useTranslation } from 'react-i18next'
import { chainId } from '../../config/constants/chains'
import { getProvider } from '../../utils/getProvider'
import { useContractProvider } from '../../utils/useContractProvider'
import { useOkxSwitchNetwork } from '../../hooks/useOkxSwitchNetwork'
import { chainIdMap, chainMap } from '../../utils/getFilterData'
import { CloseIcon } from '../../components/modals/makeOffer/CloseIcon'
import DataService from '../../lib/DataService'
export const ChooseCollection = () => {
	const { t } = useTranslation()
	const [modal, setModal] = useState(false)
	const toggle = () => setModal(!modal)

	const navigate = useNavigate()

	const back = () => {
		navigate('/choosecollection')
		setBlockChain('')
	}
	const { postformdataRequest, getRequest, postRequest } = DataService()

	const { blockchain, setBlockChain } = useContext(ThemeContext)

	const { address } = useAccount()

	const { switchNetworkAsync } = useSwitchNetwork()
	const { switchNetwork } = useOkxSwitchNetwork()

	const { chain: network } = useNetwork()
	const chainid = network?.id

	const balance = useBalance({
		address: address
	})

	const { balance: bnbBalance, refresh } = useGetBnbBalance()
	const { balance: ethBalance, refresheth } = useGetEthBalance()
	const { balance: maticBalance } = useGetMaticBalance()

	const toast = useToast()

	const [collectionimage, setCollectionIamge] = useState()
	const [collectionname, setCollectionName] = useState('')
	const [collectionsymbol, setCollectionsymbol] = useState('')
	const [description, setDescription] = useState('')
	const [url1, setUrl] = useState('')
	let imageFile
	let maxSize

	const imageupload = (e) => {
		imageFile = e.target.files[0];
		if (!imageFile) {
			return;
		}
		maxSize = 100 * 1024 * 1024;
		const validFormats = /\.(webp|jpg|jpeg|png|gif)$/;

		if (imageFile.size >= maxSize) {
			document.getElementById('uploadimage').innerHTML = t('Maximum file size is 100mb');
			setCollectionIamge('');
			return;
		}

		if (!imageFile.name.match(validFormats)) {
			document.getElementById('uploadimage').innerHTML = t('Please select a valid image (WEBP, PNG, JPG, JPEG, GIF)');
			setCollectionIamge('');
			return;
		}

		const reader = new FileReader();
		reader.onload = (e) => {
			const img = new Image();
			img.onload = () => {
				const width = img.naturalWidth;
				const height = img.naturalHeight;
				if (width >= 1400 && height >= 360) {
					setCollectionIamge(imageFile);
					document.getElementById('uploadimage').innerHTML = '';
				} else {
					setCollectionIamge('');
					document.getElementById('uploadimage').innerHTML =
						'Use image size height 360 pixels, width 1400 pixels.';
				}
			};

			img.onerror = () => {
				document.getElementById('uploadimage').innerHTML = 'Unsupported image file';
				setCollectionIamge('');
			};

			img.src = e.target.result;
		};

		reader.readAsDataURL(imageFile);
	};


	const setCollectionName1 = async e => {
		const regex = /^[A-Za-z0-9 ]+$/
		const chars = e.target.value.split('')
		const char = chars.pop()

		if (!regex.test(char)) {
			e.target.value = chars.join('')
		} else if (e.target.value == '' || e.target.value == undefined) {
			setCollectionName('')
			document.getElementById('name').innerHTML = t('Please enter collection name')
		} else if (e.target.value.length < 3) {
			setCollectionName(e.target.value)
			setCollectionsymbol(e.target.value.slice(0, 1))
			setUrl(e.target.value)
			document.getElementById('name').innerHTML =
				t('Collection Name atleast 3 characters')
		} else if (e.target.value.length > 30) {
			setCollectionName(e.target.value)
			setCollectionsymbol(e.target.value.slice(0, 1))
			setUrl(e.target.value)
			document.getElementById('name').innerHTML =
				t('Collection Name must be at most 30 characters')
		} else {
			setCollectionName(e.target.value)
			setCollectionsymbol(e.target.value.slice(0, 1))
			setUrl(e.target.value)
			setTimeout(async () => {
				const checkname = await postRequest('user/checkCollection', {
					collectionName: e.target.value
				})
				if (checkname.status == true) {
					document.getElementById('name').innerHTML = ''
					document.getElementById('urls').innerHTML = ''
					document.getElementById('symbol').innerHTML = ''
				} else {
					setCollectionName(e.target.value)
					setCollectionsymbol(e.target.value.slice(0, 1))
					setUrl(e.target.value)
					document.getElementById('name').innerHTML = checkname.message
				}
			}, 500)

		}
	}

	const setCollectionsymbol1 = e => {
		const regex = /^[A-Za-z0-9 ]+$/
		const chars = e.target.value.split('')
		const char = chars.pop()
		if (!regex.test(char)) {
			e.target.value = chars.join('')
		} else if (e.target.value == '' || e.target.value == undefined) {
			setCollectionsymbol(e.target.value)
			document.getElementById('symbol').innerHTML =
				t('Please enter collection symbol')
		} else {
			setCollectionsymbol(e.target.value)
			document.getElementById('symbol').innerHTML = ''
		}
	}

	const setDescription1 = e => {
		setDescription(e.target.value)
	}

	var res =
		/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
	const setUrl1 = async e => {
		const regex = /^[A-Za-z0-9 ]+$/

		const chars = e.target.value.split('')
		const char = chars.pop()
		if (!regex.test(char)) {
			e.target.value = chars.join('')
		} else if (e.target.value.match(res)) {
			document.getElementById('urls').innerHTML = 'Not allowed url'
		} else if (e.target.value == '' || e.target.value == undefined || e.target.value == "undefined" || e.target.value == null) {
			document.getElementById('urls').innerHTML = t('Please enter collection url')
		} else {
			setUrl(e.target.value)
			const checkurl = await postRequest('user/checkCollectionUrl', {
				url: `${process.env.REACT_APP_PUBLIC_URL}/collection/${e.target.value}`
			})
			if (checkurl.status == true) {
				document.getElementById('urls').innerHTML = ''
			} else {
				setUrl(e.target.value)
				document.getElementById('urls').innerHTML = checkurl?.message
			}
		}
	}

	const [hover, setHover] = useState('')
	const [hover1, setHover1] = useState('collapseCard')
	const [contracttype, setContractType] = useState('Proxy')

	const Proxycontract = () => {
		setHover('collapseCard1')
		setHover1('collapseCard')
		setContractType('Proxy')
	}
	const Standarad = () => {
		setHover1('collapseCard1')
		setHover('collapseCard')
		setContractType('Standard')
	}



	const { pro } = useContractProvider()
	const walletType = localStorage.getItem('walletType')

	const { theme, setTheme } = useContext(ThemeContext)
	const provider = getProvider()

	const web3 = new Web3(provider)
	const bnbminimalproxy = new web3.eth.Contract(
		Minimalproxy,
		contracts.Minimal_proxy.Proxy_BNB
	)
	const ethminimalproxy = new web3.eth.Contract(
		Minimalproxy,
		contracts.Minimal_proxy.Proxy_ETH
	)
	const polygonminimalproxy = new web3.eth.Contract(
		Minimalproxy,
		contracts.Minimal_proxy.Proxy_Polygon
	)

	const [loader, setLoader] = useState(false)
	const [loader1, setLoader1] = useState(false)
	const [error, setError] = useState(false)

	useEffect(() => {
		getAbi()
		balCheck()
	}, [address])

	const [abiarray, setAbiArray] = useState('')

	const getAbi = async () => {
		const GetAbi = await postRequest('user/getABI', { "contractType": "ERC1155" })
		if (GetAbi.status == true) {
			setAbiArray(GetAbi.data)
		} else {
			toast.error(GetAbi.message)
		}
	}

	const [showimage, setShowImage] = useState('')

	const [id, setId] = useState('')
	const [checkBalance, setCheckBalance] = useState()

	const balCheck = () => {
		if (sessionStorage.getItem('blockchain') === 'ethereum') {
			setCheckBalance(ethBalance)
		} else if (sessionStorage.getItem('blockchain') === 'binance') {
			setCheckBalance(bnbBalance)
		} else if (sessionStorage.getItem('blockchain') === 'polygon') {
			setCheckBalance(maticBalance)
		}
	}

	const createcollection = async () => {
		// setLoader1(true)
		let contract
		if (address) {
			if (sessionStorage.getItem('blockchain') === 'ethereum') {
				contract = ethminimalproxy
			} else if (sessionStorage.getItem('blockchain') === 'binance') {
				contract = bnbminimalproxy
			} else if (sessionStorage.getItem('blockchain') === 'polygon') {
				contract = polygonminimalproxy
			}

			if (
				collectionimage == undefined &&
				collectionname == '' &&
				description == '' &&
				url1 == '' &&
				collectionsymbol == ''
			) {
				document.getElementById('uploadimage').innerHTML = t('Please choose image')
				document.getElementById('name').innerHTML =
					t('Please enter collection name')
				document.getElementById('symbol').innerHTML =
					t('Please enter collection symbol')
				document.getElementById('urls').innerHTML =
					t('Please enter collection url')
				setLoader1(false)
			} else if (collectionimage == undefined || collectionimage == '') {
				document.getElementById('uploadimage').innerHTML = t('Please choose image')
				setLoader1(false)
			} else if (collectionname == '') {
				document.getElementById('name').innerHTML =
					t('Please enter collection name')
				setLoader1(false)
			} else if (collectionname.length < 3) {
				document.getElementById('name').innerHTML =
					t('Collection Name atleast 3 characters')
				setLoader1(false)
			} else if (collectionname.length > 30) {
				document.getElementById('name').innerHTML =
					t('Collection Name must be at most 30 characters')
				setLoader1(false)
			} else if (collectionsymbol == '' || collectionsymbol == undefined) {
				document.getElementById('symbol').innerHTML =
					t('Please enter currency symbol')
				setLoader1(false)
			} else if (url1 == '' || collectionname == '') {
				document.getElementById('urls').innerHTML =
					t('Please enter collection url')
				setLoader1(false)
			}
			else {
				toggle()
				const checkname = await postRequest('user/checkCollection', {
					collectionName: collectionname
				})
				if (checkname.status == true) {
					const checkurl = await postRequest('user/checkCollectionUrl', {
						url: `${process.env.REACT_APP_PUBLIC_URL}/collection/${url1
							? url1.trim().replace(/\s+/g, '_')
							: collectionname.trim().replace(/\s+/g, '_')
							}`
					})
					if (checkurl.status == true) {

						try {
							setLoader1(true)
							if (walletType === 'okx') {
								if (
									sessionStorage.getItem('blockchain') !==
									chainMap[parseInt(window.okxwallet.chainId)]
								) {
									await switchNetwork(
										chainIdMap[sessionStorage.getItem('blockchain')]
									)
								}
							} else {
								if (
									sessionStorage.getItem('blockchain') === 'binance' &&
									chainid !== chainId.BINANCE
								) {
									await switchNetworkAsync(chainId.BINANCE)
								} else if (
									sessionStorage.getItem('blockchain') === 'ethereum' &&
									chainid !== chainId.ETHEREUM
								) {
									await switchNetworkAsync(chainId.ETHEREUM)
								} else if (
									sessionStorage.getItem('blockchain') === 'polygon' &&
									chainid !== chainId.AMOY
								) {
									await switchNetworkAsync(chainId.AMOY)
								}
							}
						} catch (error) {
							toast.error('User rejected the switch network')
							setModal(false)
							setLoader1(false)
							return
						}

						if (contracttype == 'Proxy') {
							setLoader1(true)

							try {
								if (Number(checkBalance) > 0) {
									try {
										let create = await contract.methods
											.createClone(
												collectionname,
												collectionsymbol,
												address.toString()
											)
											.send({ from: address.toString() })
											.on('transactionHash', async hash => { })
											.on('receipt', async approveresult => {
												const formData = new FormData()
												formData.append('type', 'user')
												formData.append('creator', address)
												formData.append(
													'transactionHash',
													approveresult.transactionHash
												)
												formData.append(
													'contractAddress',
													approveresult.events.CreateNEWContract.returnValues
														.newNFTContract
												)
												formData.append(
													'chain',
													sessionStorage.getItem('blockchain')
												)
												formData.append('contractStandard', 'ERC1155')
												formData.append('collectionName', collectionname)
												formData.append('description', description)
												formData.append('contractType', contracttype)
												formData.append('logoImage', collectionimage)
												formData.append('collectionSymbol', collectionsymbol)
												formData.append(
													'url',
													`${process.env.REACT_APP_PUBLIC_URL}/collection/${url1
														? url1.trim().replace(/\s+/g, '_')
														: collectionname.trim().replace(/\s+/g, '_')
													}`
												)

												const collectioncreate = await postformdataRequest(
													'user/create-collection',
													formData
												)
												if (collectioncreate.status == true) {
													toast.success(collectioncreate.message)
													setId(collectioncreate.data._id)
													setCollectionName('')
													setCollectionIamge('')
													setDescription('')
													setUrl('')
													setShowImage(collectioncreate.data.logoImage)
													setLoader1(false)
													setLoader(true)
													refresh()
													refresheth()
												} else {
													toast.error(collectioncreate.message)
													setCollectionName('')
													setCollectionIamge('')
													setDescription('')
													setUrl('')
													setModal(false)
													navigate('/mycollection')
												}
											})
									} catch (err) {
										console.log('err: ', err);
										const errorMessage = err.message
										const splitError = errorMessage.split('{');
										const errorString = splitError[0].trim();
										console.log('errorString: ', errorString);
										if (errorString == "insufficient funds") {
											toast.error(errorString)
											setModal(false)
										}
										else {
											toast.error("Transaction rejected")
											setModal(false)
										}
									}
									document.getElementById('uploadimage').innerHTML = ''
									document.getElementById('name').innerHTML = ''
									document.getElementById('urls').innerHTML = ''
								} else {
									toast.error('Insufficient balance')
									window.location.reload()
									setModal(false)
								}
							} catch (err) {
								console.log('err: ', err);
								setModal(false)
							}

						}
						else if (contracttype == 'Standard') {
							setLoader1(true)
							try {

								if (Number(checkBalance) > 0) {
									var nft_tokenContract = new web3.eth.Contract(abiarray.abi)

									if (nft_tokenContract) {
										try {
											await nft_tokenContract
												.deploy({
													data: abiarray.byteCode,
													arguments: [String(collectionname), String(collectionsymbol), address]
												})
												.send({
													from: address,
													// gas: 2141985
												})
												.on('transactionHash', async hash => { })
												.on('receipt', async approveresult => {
													const formData = new FormData()
													formData.append('type', 'user')
													formData.append('creator', address)
													formData.append(
														'transactionHash',
														approveresult?.transactionHash
													)
													formData.append(
														'contractAddress',
														approveresult?.contractAddress
													)
													formData.append(
														'chain',
														sessionStorage.getItem('blockchain')
													)
													formData.append('collectionName', collectionname)
													formData.append('contractType', contracttype)
													formData.append('collectionSymbol', collectionsymbol)
													formData.append('logoImage', collectionimage)
													formData.append('description', description)
													formData.append('contractStandard', 'ERC1155')
													formData.append(
														'url',
														`${process.env.REACT_APP_PUBLIC_URL}/collection/${url1
															? url1.trim().replace(/\s+/g, '_')
															: collectionname.trim().replace(/\s+/g, '_')
														}`
													)
													const collectioncreate = await postformdataRequest(
														'user/create-collection',
														formData
													)
													if (collectioncreate.status == true) {
														toast.success(collectioncreate.message)
														setId(collectioncreate.data._id)
														setCollectionName('')
														setCollectionIamge('')
														setDescription('')
														setUrl('')
														setShowImage(collectioncreate.data.logoImage)
														setLoader1(false)
														setLoader(true)
														refresh()
														refresheth()
													} else {
														toast.error(collectioncreate.message)
														setCollectionName('')
														setCollectionIamge('')
														setDescription('')
														setUrl('')
														setModal(false)
														navigate('/mycollection')
													}
												})
										} catch (err) {
											console.log('err: ', err);
											const errorMessage = err.message
											const splitError = errorMessage.split('{');
											const errorString = splitError[0].trim();
											console.log('errorString: ', errorString);
											if (errorString == "insufficient funds") {
												toast.error(errorString)
												setModal(false)
											}
											else {
												toast.error("Transaction rejected")
												setModal(false)
											}
										}
									}
								} else {
									toast.error('Insufficient balance')
									window.location.reload()
									setModal(false)
								}
							} catch (err) {
								setModal(false)
							}
						}
					} else {
						setModal(false)
						document.getElementById('urls').innerHTML = checkurl.message
					}
				} else {
					document.getElementById('name').innerHTML = checkname.message
					setModal(false)
				}
			}
		} else {
			setModal(false)
			toast.error('Please Connectwallet')
		}
	}

	const mycollection = () => {
		navigate('/editcollection/' + id)
	}

	const getback = () => {
		navigate("/mycollection")
	}

	return (
		<>
			<div className='row'>
				<div className='col-lg-2 mb-3' onClick={back} onKeyDown={(e) => {
					if (e.key === 'Enter' || e.key === ' ') {
						back()
					}
				}}
					role="button" tabIndex={0}>
					<button className='backIcon'>
						<i className='fas fa-angle-left'></i>
					</button>
				</div>
				<div className='col-lg-3 mb-3'>
					<h3 className='collectionSecHeading text-left'>{t("Create Collection")}</h3>
				</div>
				<div className='col-lg-6'>
					<div className='createCollectionCard position-relative'>
						<form className='w-100'>
							{address ? (
								<div className='walletCnt mb-3'>
									<h3>
										{address
											? address.slice(0, 5) + '...' + address.slice(38, 42)
											: ''}
									</h3>
									<div className='walletLabel successLabel ml-auto'>
										{t("Wallet Connected")}
									</div>
								</div>
							) : (
								<div className='walletCnt mb-3'>
									<h3></h3>
									<div
										className='walletLabel successLabel ml-auto'
										style={{ color: 'red' }}
									>
										{t("Please Connect Wallet")}
									</div>
								</div>
							)}

							<div className='form-row'>
								<div className='col-12 mb-4'>
									<span className='formLabel'>{t("Logo Image")}</span>
									<div className='d-flex align-items-center flex-wrap'>
										<div className='imgCnt mr-4'>
											{collectionimage ? (
												<img
													src={URL.createObjectURL(collectionimage)}
													style={{ width: '160px', height: '160px' }}
												/>
											) : (
												<img src='' />
											)}
										</div>
										<div className='d-flex flex-column'>
											<p>{t("PNG, GIF, JPG, JPEG, WEBP")}.</p>
											<p className='fs-12 greyTxt'>
												{t("Max 100mb.At least 1400*360 pixels")}.
											</p>
											<div className='chooseFileBtn mt-2'>
												<input
													type='file'
													id='chooseBtn'
													accept='.png, .gif, .webp, .jpg, .jpeg'
													onChange={e => imageupload(e)}
												/>
												<label for='chooseBtn'>{t("Choose File")}</label>
											</div>
										</div>
									</div>
									<div id='uploadimage' style={{ color: 'red' }}></div>
								</div>

								<div className='col-6 mb-3 '>
									<div className=' d-flex align-items-center  flex-wrap '>
										<span className='formLabel'>{t("Collection Name")}</span>
									</div>
									<input
										type='text'
										placeholder={t('Name Your Collection')}
										className='form-control'
										name='collectionname'
										onChange={setCollectionName1}
									/>
									<label className='text-danger errLabel ml-auto'>
										{' '}
										<div id='name' className='text-danger'></div>{' '}
									</label>
								</div>

								<div className='col-6 mb-3 '>
									<div className='d-flex align-items-center flex-wrap'>
										<span className='formLabel'>{t("Collection Symbol")}</span>
									</div>
									<input
										type='text'
										placeholder={t('Collection Symbol')}
										className='form-control'
										name='collectionsymbol'
										onChange={e => setCollectionsymbol1(e)}
										value={collectionsymbol}
									/>
									<label className='text-danger errLabel ml-auto'>
										{' '}
										<div id='symbol' style={{ color: 'red' }}></div>{' '}
									</label>
								</div>

								<div className='col-12 mb-3'>
									<span className='formLabel'>{t("Description (Optional)")}</span>
									<textarea
										className='form-control'
										placeholder={t('Enter Description')}
										name='description'
										onChange={setDescription1}
									></textarea>
								</div>

								<div className='col-12 mb-3 urlFieldCnt'>
									<div className='d-flex align-items-center flex-wrap'>
										<span className='formLabel'>{t("Collection URL")}</span>
									</div>
									<div class='input-group mb-2 d-none'>
										<div class='input-group-prepend'>
											<div class='input-group-text'>
												{process.env.REACT_APP_PUBLIC_URL}/collection/
											</div>
										</div>
										<input
											type='text'
											class='form-control'
											placeholder={t('Enter URL')}
											name='url1'
											onChange={setUrl1}
											defaultValue={
												collectionname
													? collectionname.trim().replace(/\s+/g, '_')
													: ''
											}
										/>
									</div>

									<div className="clcUrl">
										<span>{process.env.REACT_APP_PUBLIC_URL}/collection/</span>
										<input
											type='text'
											class=''
											placeholder={t('Enter URL')}
											name='url1'
											onChange={setUrl1}
											defaultValue={
												collectionname
													? collectionname.trim().replace(/\s+/g, '_')
													: ''
											}
										/>
									</div>

									<label className='text-danger errLabel ml-auto'>
										{' '}
										<div id='urls' style={{ color: 'red' }}></div>
									</label>
								</div>
								<div className='collapseCnt px-0'>
									<div
										className='collapseHead d-flex align-items-center'
										id='collapse'
									>
										<h3 className='collapseHeading'>{t("Advanced Settings")}</h3>
										<img
											src={angleDown}
											className='ml-auto collapseArrow'
											style={{ cursor: 'pointer' }}
										/>
									</div>
									<UncontrolledCollapse
										toggler='#collapse'
										className='collapseBody'
									>
										<p className='fs-16 mb-1'>{t("Customize your contract type")}</p>
										<p className='fs-14'>
											<a href='#'>{t("Learn more")}</a> {t("about each contract type")}.
										</p>
										<div
											className={`${hover == ''
												? 'collapseCard1'
												: contracttype == 'Proxy'
													? 'collapseCard1'
													: 'collapseCard'
												}`}
											onClick={Proxycontract}
											onKeyDown={(e) => {
												if (e.key === 'Enter' || e.key === ' ') {
													Proxycontract()
												}
											}}
											role="button" tabIndex={0}
											style={{ cursor: 'pointer' }}
										>
											<h3>{t("Proxy contract")}</h3>
											<p>
												{t("Recommended for most creators. This type of contract is cheaper to deploy but will require that wallets who mint, transfer, and sell items from this collection pay additional gas fees")}.
											</p>
										</div>
										<div
											className={`${hover1 == 'collapseCard'
												? 'collapseCard'
												: 'collapseCard1'
												}`}
											onClick={Standarad}
											onKeyDown={(e) => {
												if (e.key === 'Enter' || e.key === ' ') {
													Standarad()
												}
											}}
											role="button" tabIndex={0}
											style={{ cursor: 'pointer' }}
										>
											<h3>{t("Standard contract")}</h3>
											<p>
												{t("Recommended for advanced creators. This type of contract is more expensive to deploy but minting,transfers, and sale interactions will require less extra gas fees")}.
											</p>
										</div>
									</UncontrolledCollapse>
								</div>

								<div className='col-12 mb-3 pt-3'>
									<button
										type='button'
										className='btn btn-block gradientBtn'
										onClick={createcollection}
									>
										{t("Create Collection")}
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>

			<Modal
				isOpen={modal}
				toggle={toggle}
				centered='true'
				className='mymodal'
				backdropClassName='selCurBp'
				backdrop='static'
				keyboard={false}
			>
				<ModalHeader
					close={loader == true ? <CloseIcon onClose={getback} customclass="closefix" /> : null}
				>{loader == true ? <div className='d-flex justify-content-center align-items-center mb-3'>
					<img src={showimage} width='100px' header='100px' style={{ borderRadius: "12px" }} />
				</div> : ""}</ModalHeader>
				<ModalBody>
					<div className='text-center mt-n4'>
						{loader == true ? (
							<>
								<h3 className='checkTit mb-2'>
									{t("Collection Created")} <br />
									{t("Successfully")}!
								</h3>
								<button
									type='button'
									className='btn btn-block gradientBtn '
									onClick={mycollection}
								>
									{t("Edit Collection")}
								</button>

							</>
						) : (
							''
						)}
						{loader1 == true ? (

							<div>
								<h3 className='walletHeading my-3'>{t("Loading")}</h3>
								<h3 className='walletSubHeading'>
									{t("To continue send transaction with your wallet")}.
								</h3>
								<div className='d-flex justify-content-center align-items-center loaderCont'>
									{localStorage.getItem('light_theme') == 'true' ? (
										<img src={walletLightLoaderCnt} />
									) : (
										<img src={walletLoaderCnt} />
									)}
									<div className='loaderAnimCnt'>
										<img src={walletLoader} className='loaderAnimation' />
									</div>
								</div>
							</div>

						) : (
							''
						)}
						{error == true ? (
							<div className='createContent'>
								<button className='btn closeBtn' onClick={toggle}>
									<svg
										width='12'
										height='12'
										viewBox='0 0 12 12'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z'
											fill='#595F6A'
										/>
									</svg>
								</button>
								<div className='d-flex justify-content-center'>
									<img src={IconModal} />
								</div>
								<h3 className='walletHeading my-3'>{t("Something went wrong")}</h3>
								<button type='button' className='btn btn-block darkBtn'>
									{t('Okay')}
								</button>
							</div>
						) : (
							''
						)}
					</div>
				</ModalBody>
			</Modal>
		</>
	)
}

export default ChooseCollection
