import { useEffect, useState } from 'react'
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'
import {
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
	UncontrolledTooltip
} from 'reactstrap'
import classnames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { io } from 'socket.io-client'

import { useBuyNowModalActions } from '../../../components/modals/buy-now/store'
import { useMakeOfferModalActions } from '../../../components/modals/makeOffer/store'
import { useEditListingModalActions } from '../../../components/modals/edit-listing-modal/store'
import { useConnectWalletModalActions } from '../../../components/modals/connect-wallet/store'
import { useCheckoutModalActions } from '../../../components/modals/checkout/store'
import { chainId } from '../../../config/constants/chains'
import { axios } from '../../../lib/axios'
import { useToast } from '../../../utils/toast/customSuccess'
import useCountdown from '../../../hooks/useCountdown'
import { getAuctionCountdown } from '../../../utils/formatRoundTime'

import minus from '../../../assets/images/minus.svg'
import info from '../../../assets/images/infoIcon.svg'
import user from '../../../../src/assets/images/header/profileDDImg1.svg'
import { chainIdMap, chainMap } from '../../../utils/getFilterData'
import { useOkxSwitchNetwork } from '../../../hooks/useOkxSwitchNetwork'





export function BuyNowStation({ asset }) {
	const [activeTab, setActiveTab] = useState('sell')
	const navigate = useNavigate()

	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab)
	}

	const canList = asset.details.isListable
	const { t } = useTranslation()

	return (
		<>
			{canList ? (
				<div className='nftCard d-block p-1 p-sm-3'>
					<div className='row justify-content-between align-items-center'>
						<div className='col-auto pr-0'>
							<Nav
								tabs
								className='trad_tab_hd navNftDetails d-inline-flex'
							>
								<NavItem>
									<NavLink
										className={classnames({
											active: activeTab === 'sell'
										})}
										onClick={() => toggle('sell')}
									>
										{t("Sell")}
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({
											active: activeTab === 'buy'
										})}
										onClick={() => toggle('buy')}
									>
										{t("Buy")}
									</NavLink>
								</NavItem>
							</Nav>
						</div>
						<div className='col-auto text-right'>
							<div className='d-inline-flex align-items-center mb-3'>
								<img src={user} alt='user' />
								<span className='pl-2 fs-15 fw-400'>
									{t("You Own")} {asset.details.ownedSupplies}
								</span>
							</div>
						</div>
					</div>
					<div>
						<TabContent activeTab={activeTab}>
							<TabPane tabId='sell'>
								<div className='py-3'>
									<div>
										<button
											className='btn gradientBtn btn160 mr-2 my-1 d-inline-flex w-100'
											onClick={() =>
												navigate(
													`/listforsale/${asset.details.chain}/${asset.details.contractAddress}/${asset.details.nftId}`
												)
											}
										>
											{t("List for Sale")}
										</button>
									</div>
								</div>
							</TabPane>
							<TabPane tabId='buy'>
								<Buy asset={asset} />
							</TabPane>
						</TabContent>
					</div>
				</div>
			) : (
				<Buy asset={asset} />
			)}
		</>
	)
}

function Buy({ asset }) {
	const { t } = useTranslation()
	const [socket, setSocket] = useState()
	const [currentPrice, setCurrentPrice] = useState()
	const [cart, setCart] = useState(true)

	const { secondsRemaining } = useCountdown(asset.details.saleExpiry)
	const { days, hours, minutes, seconds } =
		getAuctionCountdown(secondsRemaining)

	const { toggleBuyNowModal, setAsset: setBuyNowModalAssetState } =
		useBuyNowModalActions()
	const { toggleMakeOfferModal, setAsset: setMakeOfferAssetState } =
		useMakeOfferModalActions()
	const { toggleEditListingModal, setAsset: setEditListingAssetState } =
		useEditListingModalActions()
	const { setAsset: setCheckoutModalAssetState } = useCheckoutModalActions()
	const { toggleConnectWalletModal, setConnectWalletModalState } =
		useConnectWalletModalActions()

	const { address: account, isConnected } = useAccount()
	const { switchNetworkAsync } = useSwitchNetwork()
	const { switchNetwork } = useOkxSwitchNetwork()
	const walletType = localStorage.getItem('walletType')
	const { chain } = useNetwork()
	const queryClient = useQueryClient()
	const toast = useToast()

	const isOnFixed =
		asset.details.isMarketplace && asset.details.saleType === 'fixed'
	const isOnDutchAuction =
		asset.details.isMarketplace &&
		asset.details.saleType === 'dutch-auction'
	const isToken721 = asset.details.contractStandard === 'ERC721'
	const [owner] = asset.owners

	useEffect(() => {
		if (!asset) return
		if (asset.details.saleType !== 'dutch-auction') return

		const s = io(process.env.REACT_APP_BACKEND_SOCKET_URL)
		setSocket(s)

		return () => {
			s.disconnect()
		}
	}, [asset])

	useEffect(() => {
		if (socket && asset) {
			socket.emit('get-order-id', asset.details.orderId)
		}
	}, [socket, asset])

	useEffect(() => {
		if (socket && asset) {
			socket.on('dutch-price', (price) => {
				setCurrentPrice(price)
				const updatedAsset = {
					...asset,
					details: {
						...asset.details,
						price
					}
				}
				setBuyNowModalAssetState(updatedAsset)
			})
		}
	}, [socket, asset])

	const handleBuyNow = async () => {
		if (asset.details.saleType === 'dutch-auction') {
			const updatedAsset = {
				...asset,
				details: {
					...asset.details,
					price: currentPrice ? currentPrice : asset.details.price
				}
			}
			setBuyNowModalAssetState(updatedAsset)
		} else {
			setBuyNowModalAssetState(asset)
		}

		setCheckoutModalAssetState([asset])
		setConnectWalletModalState({ chainId: chainIdMap[asset.details.chain] })

		if (!account) {
			toggleConnectWalletModal()
			setConnectWalletModalState({
				callback: toggleBuyNowModal
			})
			return
		}

		try {
			
			if (walletType === 'okx') {
				if (
					asset.details.chain !== chainMap[parseInt(window.okxwallet.chainId)]
				) {
					await switchNetwork(chainIdMap[asset.details.chain])
				}
			} else {
				if (asset.details.chain === 'binance' && chain.id !== chainId.BINANCE) {
					await switchNetworkAsync(chainId.BINANCE)
				} else if (
					asset.details.chain === 'ethereum' &&
					chain.id !== chainId.ETHEREUM
				) {
					await switchNetworkAsync(chainId.ETHEREUM)
				} else if (
					asset.details.chain === 'polygon' &&
					chain.id !== chainId.AMOY
				) {
					await switchNetworkAsync(chainId.AMOY)
				}
			}
			toggleBuyNowModal()

		} catch (error) {
		}
	}

	const handleMakeOffer = async () => {
		setMakeOfferAssetState(asset)

		setConnectWalletModalState({ chainId: chainIdMap[asset.details.chain] })
		if (!account) {
			toggleConnectWalletModal()
			setConnectWalletModalState({
				callback: toggleMakeOfferModal
			})
			return
		}

		try {
			if (asset.details.chain !== chainMap[chain.id]) {
				await switchNetworkAsync(chainIdMap[asset.details.chain])
			}

			toggleMakeOfferModal()
		} catch (error) {
		}
	}

	const handleEditListing = async () => {
		setEditListingAssetState(asset)

		setConnectWalletModalState({ chainId: chainIdMap[asset.details.chain] })
		if (!account) {
			toggleConnectWalletModal()
			setConnectWalletModalState({
				callback: toggleEditListingModal
			})
			return
		}

		try {
			if (asset.details.chain !== chainMap[chain.id]) {
				await switchNetworkAsync(chainIdMap[asset.details.chain])
			}

			toggleEditListingModal()
		} catch (error) { }
	}

	const toggleCart = (asset) => (account) => {
		axios
			.post('asset/cart-mutation', {
				orderId: asset.details.orderId,
				userAddress: account,
				status: !asset.details.isCart
			})
			.then((res) => {
				if (res.data) {
					toast.success(
						`Item ${res.data.isCart ? 'added to' : 'removed from'
						} the cart`
					)
					queryClient.invalidateQueries(['asset'])
					queryClient.invalidateQueries(['nfts'])
					queryClient.invalidateQueries(['cart'])
					queryClient.invalidateQueries(['listings'])
					setTimeout(() => {
						setCart(true)
					}, 5000);
				}
			})
			.catch((error) => { })
	}

	const handleToggleCart = () => {
		setCart(false)
		if (!account) {
			toggleConnectWalletModal()
			setConnectWalletModalState({
				callback: toggleCart(asset)
			})
			return
		} else {
			toggleCart(asset)(account)
		}
	}

	return (
		<>
			<div className='d-flex align-items-center justify-content-between flex-wrap w-100 p-0'>
				<div className='d-flex flex-column mr-2'>
					<span className='cardLabel d-flex' style={{ gap: '5px' }}>
						{t(isOnFixed ? t('Price') : t('Current Bid'))}
						{isOnDutchAuction && (
							<div className='hover'>
								<img
									alt='information icon'
									id='bid-info'
									src={info}
								/>
								<UncontrolledTooltip
									placement='top'
									target='bid-info'
									className='tooltipCnt red-tooltip'
								>
									Ends at {asset.details.dutchEndPrice}{' '}
									{asset.details.currency}
								</UncontrolledTooltip>
							</div>
						)}
					</span>
					<div className='d-flex align-items-center mb-2'>
						<h3 className='mb-0 mr-1'>{`${parseFloat(
							currentPrice
								? currentPrice.toFixed(8)
								: asset?.details?.price?.toFixed(8)
						)} ${asset.details.currency}`}</h3>
						<span className='cardLabel'>
							$
							{parseFloat(asset?.details?.priceInUsd?.toFixed(2))}
						</span>
					</div>
				</div>
				<div className='mb-2 fs-14 fw-400 dark-text-secondary d-inline-flex flex-column justify-content-center align-items-sm-end'>
					<div>
						<span>{t("Ends in")}</span>
					</div>
					<div>
						<span class='fw-600 dark-text'>{days}</span> Days{' '}
						<span class='fw-600  dark-text'>{hours}</span> Hours{' '}
						<span class='fw-600  dark-text'>{minutes}</span> Mins{' '}
						<span class='fw-600 dark-text'>{seconds}</span> Secs
					</div>
				</div>
			</div>
			<div
				className='w-100 d-flex align-items-center justify-content-between flex-wrap'
				style={{ gap: '10px' }}
			>
				{isToken721 ? (
					account?.toLowerCase() !== owner?.address ? (
						<>
							<button
								className='btn gradientBtn btn160 mr-2 my-1 flex-grow-1'
								onClick={handleBuyNow}
							>
								{asset.details.saleType === 'fixed'
									? t('Buy Now')
									: t('Bid To Buy')}
							</button>
							{isConnected || cart
								? asset.details.saleType === 'fixed' &&
								(!asset.details.isCart ? (
									cart ? <button
										className='btn gradientBtn mr-2 my-1'
										onClick={handleToggleCart}
									>
										<svg
											width='19'
											height='16'
											viewBox='0 0 19 16'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M17.2188 2C17.7188 2 18.0625 2.46875 17.9688 2.9375L16.5625 9.4375C16.4688 9.78125 16.1875 10 15.8125 10H6.0625L6.34375 11.5H15.1562C15.6562 11.5 16 11.9688 15.9062 12.4375L15.875 12.5625C16.25 12.9375 16.5 13.4375 16.5 14C16.5 15.125 15.5938 16 14.5 16C13.375 16 12.5 15.125 12.5 14C12.5 13.6562 12.5938 13.3125 12.75 13H8.21875C8.375 13.3125 8.5 13.6562 8.5 14C8.5 15.125 7.59375 16 6.5 16C5.375 16 4.5 15.125 4.5 14C4.5 13.4688 4.6875 13 5.03125 12.625L2.875 1.5H0.375C0.15625 1.5 0 1.34375 0 1.125V0.375C0 0.1875 0.15625 0 0.375 0H3.5C3.84375 0 4.15625 0.28125 4.21875 0.625L4.5 2H17.2188ZM6.5 14.75C6.90625 14.75 7.25 14.4375 7.25 14C7.25 13.5938 6.90625 13.25 6.5 13.25C6.0625 13.25 5.75 13.5938 5.75 14C5.75 14.4375 6.0625 14.75 6.5 14.75ZM14.5 14.75C14.9062 14.75 15.25 14.4375 15.25 14C15.25 13.5938 14.9062 13.25 14.5 13.25C14.0625 13.25 13.75 13.5938 13.75 14C13.75 14.4375 14.0625 14.75 14.5 14.75ZM15.2188 8.5L16.3125 3.5H4.78125L5.75 8.5H15.2188Z'
												fill='#0A0A0B'
											/>
										</svg>
									</button> : <></>
								) : (
									cart ? <button
										className='btn gradientBtn my-1'
										onClick={handleToggleCart}
									>
										<img
											src={minus}
											alt='shoppingcart'
										/>
									</button> : <></>
								))
								: <></>}
							<button
								className='btn darkBtn my-1 flex-grow-1'
								onClick={handleMakeOffer}
							>
								<svg
									className='mr-2'
									width='19'
									height='8'
									viewBox='0 0 19 8'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M17.7188 1.75C18.2188 2.1875 18.5 2.8125 18.4688 3.4375C18.4688 4.09375 18.1562 4.6875 17.625 5.0625L14.5 7.4375C14 7.8125 13.375 8 12.75 8H1C0.71875 8 0.5 7.78125 0.5 7.5V7C0.5 6.75 0.71875 6.5 1 6.5H12.75C13.0625 6.5 13.3438 6.40625 13.5938 6.21875L16.75 3.875C17.0625 3.625 17.0938 3.1875 16.75 2.90625C16.5312 2.71875 16.125 2.71875 15.875 2.90625L13.9688 4.34375C13.7188 4.53125 13.4375 4.625 13.125 4.625H8.90625C8.6875 4.625 8.5 4.4375 8.5 4.21875V4.1875C8.5 3.96875 8.6875 3.78125 8.90625 3.78125H10.7812C11.7188 3.78125 11.6875 2.5 10.8125 2.5H6.3125C5.75 2.5 5.21875 2.6875 4.78125 3.03125L3.5 4H1C0.71875 4 0.5 3.78125 0.5 3.5V3C0.5 2.75 0.71875 2.5 1 2.5H2.96875L3.875 1.84375C4.5625 1.3125 5.4375 1 6.3125 1H10.8125C12.0312 1 12.9688 1.9375 12.9688 3.125H13.0938L14.9688 1.71875C15.5625 1.25 16.75 0.9375 17.7188 1.75Z'
										fill='white'
									/>
								</svg>
								{t('Make an Offer')}
							</button>
						</>
					) : (
						<button
							className='btn gradientBtn btn-block btn160 mr-2 my-1'
							onClick={handleEditListing}
						>
							{t('Edit Listing')}
						</button>
					)
				) : (
					<>
						{account?.toLowerCase() === asset.details.seller ? (
							<>
								<button
									className='btn gradientBtn btn-block mr-2 my-1 flex-grow-1'
									onClick={handleEditListing}
								>
									{t('Edit Listing')}
								</button>
								{asset.details.owners.length > 1 && (
									<button
										className='btn darkBtn my-1'
										onClick={handleMakeOffer}
									>
										<svg
											className='mr-2'
											width='19'
											height='8'
											viewBox='0 0 19 8'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M17.7188 1.75C18.2188 2.1875 18.5 2.8125 18.4688 3.4375C18.4688 4.09375 18.1562 4.6875 17.625 5.0625L14.5 7.4375C14 7.8125 13.375 8 12.75 8H1C0.71875 8 0.5 7.78125 0.5 7.5V7C0.5 6.75 0.71875 6.5 1 6.5H12.75C13.0625 6.5 13.3438 6.40625 13.5938 6.21875L16.75 3.875C17.0625 3.625 17.0938 3.1875 16.75 2.90625C16.5312 2.71875 16.125 2.71875 15.875 2.90625L13.9688 4.34375C13.7188 4.53125 13.4375 4.625 13.125 4.625H8.90625C8.6875 4.625 8.5 4.4375 8.5 4.21875V4.1875C8.5 3.96875 8.6875 3.78125 8.90625 3.78125H10.7812C11.7188 3.78125 11.6875 2.5 10.8125 2.5H6.3125C5.75 2.5 5.21875 2.6875 4.78125 3.03125L3.5 4H1C0.71875 4 0.5 3.78125 0.5 3.5V3C0.5 2.75 0.71875 2.5 1 2.5H2.96875L3.875 1.84375C4.5625 1.3125 5.4375 1 6.3125 1H10.8125C12.0312 1 12.9688 1.9375 12.9688 3.125H13.0938L14.9688 1.71875C15.5625 1.25 16.75 0.9375 17.7188 1.75Z'
												fill='white'
											/>
										</svg>
										{t('Make an Offer')}
									</button>
								)}
							</>
						) : (

							<>
								<button
									className='btn gradientBtn btn160 mr-2 my-1 flex-grow-1'
									onClick={handleBuyNow}
								>
									{asset.details.saleType === 'fixed'
										? t('Buy Now')
										: t('Bid To Buy')}
								</button>
								{isConnected || cart
									? asset.details.saleType === 'fixed' &&
									(!asset.details.isCart ? (
										cart ? <button
											className='btn gradientBtn mr-2 my-1'
											onClick={handleToggleCart}
										>
											<svg
												width='19'
												height='16'
												viewBox='0 0 19 16'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													d='M17.2188 2C17.7188 2 18.0625 2.46875 17.9688 2.9375L16.5625 9.4375C16.4688 9.78125 16.1875 10 15.8125 10H6.0625L6.34375 11.5H15.1562C15.6562 11.5 16 11.9688 15.9062 12.4375L15.875 12.5625C16.25 12.9375 16.5 13.4375 16.5 14C16.5 15.125 15.5938 16 14.5 16C13.375 16 12.5 15.125 12.5 14C12.5 13.6562 12.5938 13.3125 12.75 13H8.21875C8.375 13.3125 8.5 13.6562 8.5 14C8.5 15.125 7.59375 16 6.5 16C5.375 16 4.5 15.125 4.5 14C4.5 13.4688 4.6875 13 5.03125 12.625L2.875 1.5H0.375C0.15625 1.5 0 1.34375 0 1.125V0.375C0 0.1875 0.15625 0 0.375 0H3.5C3.84375 0 4.15625 0.28125 4.21875 0.625L4.5 2H17.2188ZM6.5 14.75C6.90625 14.75 7.25 14.4375 7.25 14C7.25 13.5938 6.90625 13.25 6.5 13.25C6.0625 13.25 5.75 13.5938 5.75 14C5.75 14.4375 6.0625 14.75 6.5 14.75ZM14.5 14.75C14.9062 14.75 15.25 14.4375 15.25 14C15.25 13.5938 14.9062 13.25 14.5 13.25C14.0625 13.25 13.75 13.5938 13.75 14C13.75 14.4375 14.0625 14.75 14.5 14.75ZM15.2188 8.5L16.3125 3.5H4.78125L5.75 8.5H15.2188Z'
													fill='#0A0A0B'
												/>
											</svg>
										</button> : ""
									) : (
										cart ? <button
											className='btn gradientBtn my-1'
											onClick={handleToggleCart}
										>
											<img
												src={minus}
												alt='shoppingcart'
											/>
										</button> : ""
									))
									: ""}
								<button
									className='btn darkBtn my-1 flex-grow-1'
									onClick={handleMakeOffer}
								>
									<svg
										className='mr-2'
										width='19'
										height='8'
										viewBox='0 0 19 8'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M17.7188 1.75C18.2188 2.1875 18.5 2.8125 18.4688 3.4375C18.4688 4.09375 18.1562 4.6875 17.625 5.0625L14.5 7.4375C14 7.8125 13.375 8 12.75 8H1C0.71875 8 0.5 7.78125 0.5 7.5V7C0.5 6.75 0.71875 6.5 1 6.5H12.75C13.0625 6.5 13.3438 6.40625 13.5938 6.21875L16.75 3.875C17.0625 3.625 17.0938 3.1875 16.75 2.90625C16.5312 2.71875 16.125 2.71875 15.875 2.90625L13.9688 4.34375C13.7188 4.53125 13.4375 4.625 13.125 4.625H8.90625C8.6875 4.625 8.5 4.4375 8.5 4.21875V4.1875C8.5 3.96875 8.6875 3.78125 8.90625 3.78125H10.7812C11.7188 3.78125 11.6875 2.5 10.8125 2.5H6.3125C5.75 2.5 5.21875 2.6875 4.78125 3.03125L3.5 4H1C0.71875 4 0.5 3.78125 0.5 3.5V3C0.5 2.75 0.71875 2.5 1 2.5H2.96875L3.875 1.84375C4.5625 1.3125 5.4375 1 6.3125 1H10.8125C12.0312 1 12.9688 1.9375 12.9688 3.125H13.0938L14.9688 1.71875C15.5625 1.25 16.75 0.9375 17.7188 1.75Z'
											fill='white'
										/>
									</svg>
									{t('Make an Offer')}
								</button>
							</>

						)}
					</>
				)}
			</div>
		</>
	)
}
