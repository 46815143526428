import { useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useAccount } from 'wagmi'
import useSWR from 'swr'
import { formatUnits } from 'viem'
import { useTranslation } from 'react-i18next'

import { CloseIcon } from './CloseIcon'
import { useBuyNowModalActions, useBuyNowModalState } from './store'
import { formatAddress } from '../../../utils/formatAddress'
import { copyText } from '../../../utils/copyText'
import { CheckIcon } from '../../../components/CheckIcon'
import { useTokenContract } from '../../../hooks/useContract'
import {
	useGetBnbBalance,
	useGetEthBalance,
	useGetMaticBalance
} from '../../../hooks/useBalance'
import contracts from '../../../config/constants/contracts'
import { useApproveToken } from '../../../hooks/useApproveToken'
import { useBuyNow } from '../../../hooks/useBuyNow'
import { usePurchaseLoadingModalActions } from '../purchase-loading/store'
import { useSomethingWentWrongModalActions } from '../error/store'
import { useCheckoutModalActions } from '../checkout/store'
import { handleImageError } from '../../../hooks/useHandleImg'
import { formatAmount } from '../../../utils/formatAmount'

import defaultImage from '../../../assets/images/homeExplorer/placeholder.png'
import yellowtick20 from '../../../assets/images/yellow-tick_20px.svg'
import copyIcon from '../../../assets/images/copyIcon.svg'

import '../modal.scss'
import { chainIdMap } from '../../../utils/getFilterData'

export function BuyNowModal({ GetActivityNFT }) {
	const [videoError, setVideoError] = useState(false)
	const [copied, setCopied] = useState(false)
	const [refreshBalance, setRefreshBalance] = useState(false)

	const { t } = useTranslation()

	const { isOpen, asset } = useBuyNowModalState()
	const { toggleBuyNowModal } = useBuyNowModalActions()
	const { togglePurchaseLoadingModal, setHeading } =
		usePurchaseLoadingModalActions()
	const { toggleSomethingWentWrongModal } =
		useSomethingWentWrongModalActions()
	const { toggleCheckoutModal } = useCheckoutModalActions()

	const { address: account } = useAccount()
	const { balance: bnbBalance, refresh } = useGetBnbBalance()
	const { balance: ethBalance, refresheth } = useGetEthBalance()
	const { balance: maticBalance } = useGetMaticBalance()

	const chainId = chainIdMap[asset?.details.chain]
	const tokenAddress =
		asset && !asset.details.isCoin
			? contracts[asset.details.currency?.toLowerCase()]
			: null

	const tokenContract = useTokenContract(tokenAddress, chainId)
	const marketplaceContractAddress = asset
		? contracts.marketplace[chainIdMap[asset.details.chain]]
		: null

	const { data: tokenBalance } = useSWR(
		['tokenBalance', tokenContract, refreshBalance],
		async () => {
			const decimals = await tokenContract.read.decimals()
			const rawBalance = await tokenContract.read.balanceOf([account])
			return parseFloat(formatUnits(rawBalance, decimals))
		}
	)

	const { approve } = useApproveToken(tokenAddress, chainId)
	const { buyNow } = useBuyNow(
		marketplaceContractAddress,
		chainIdMap[asset?.details.chain]
	)

	if (!asset) {
		return null
	}

	const nativeCurrencyBalanceMap = {
		ethereum: ethBalance,
		binance: bnbBalance,
		polygon: maticBalance
	}
	const isVideo = asset?.details?.asset?.endsWith('.mp4')

	const total = asset.details.price
	const isFundsSufficient = asset.details.isCoin
		? total < nativeCurrencyBalanceMap[asset.details.chain]
		: tokenBalance && total < tokenBalance

	const handleCheckout = async () => {
		setHeading('Purchase')
		setRefreshBalance(!refreshBalance)
		toggleBuyNowModal()
		togglePurchaseLoadingModal()
		try {
			if (!asset.details.isCoin) {
				await approve(
					asset.details.price.toString(),
					marketplaceContractAddress
				)
			}
			const res = await buyNow(asset)

			toggleCheckoutModal()
			if (res.status === "success") {
				refresh()
				refresheth()
			}
			if (GetActivityNFT !== undefined) {
				GetActivityNFT()
			}
		} catch (error) {
			toggleSomethingWentWrongModal()
		} finally {
			togglePurchaseLoadingModal()
		}
	}

	const handleVideoError = () => {
		setVideoError(true)
	}

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			setCopied(true);
			copyText(account, () => {
				setTimeout(() => {
					setCopied(false);
				}, 600);
			});
		}
	};

	return (
		<Modal
			isOpen={isOpen}
			centered='true'
			className='mymodal'
			backdropClassName='selCurBp'
			keyboard={false}
		>
			<ModalHeader
				toggle={toggleBuyNowModal}
				close={<CloseIcon onClose={toggleBuyNowModal} />}
			>
				<h5 className='modal-title'>{t('Buy NFT')}</h5>
			</ModalHeader>
			<ModalBody>
				<div>
					<div className='cartItem'>
						<div className='itemImg'>
							{!isVideo ? (
								typeof asset?.details?.asset == 'string' &&
									asset?.details?.asset !== undefined &&
									!asset?.details?.asset?.startsWith(
										process.env.S3_URL
									) &&
									!asset?.details?.asset?.startsWith('ipfs') &&
									asset?.details?.asset !== null ? (
									<img
										className='object-cover'
										alt='asset'
										src={
											asset?.details?.asset
												? asset?.details?.asset
												: defaultImage
										}
										onError={handleImageError}
									/>
								) : (
									<img
										className='object-cover'
										alt='asset'
										src={defaultImage}
									/>
								)
							) : videoError ? (
								<img
									className='object-cover'
									alt='asset'
									src={defaultImage}
								/>
							) : (


								<video loop autoPlay muted playsInline preload='metadata' controlslist='nodownload' id='video-asset' style={{
									objectFit: 'contain',
									height: '84px',
									width: '84px'
								}}>
									<source src={asset.details.asset
										? asset.details.asset
										: defaultImage} type="video/mp4" onError={handleVideoError} />
								</video>

							)}
						</div>
						<div className='flex-grow-1 p-3'>
							<div className='d-flex justify-content-between mb-2'>
								<div className='itemName'>
									{asset.collection.name}{' '}
									{asset.collection.isVerified && (
										<img
											src={yellowtick20}
											alt='yellowtick20'
											className='ml-1'
										/>
									)}
								</div>
								<span className='fs-14 fw-400 dark-text-secondary'></span>
							</div>
							<div className='d-flex justify-content-between mb-n1'>
								<div className='itemId'>
									{asset.details.name}
								</div>
								<span className='itemBal'>{`${parseFloat(
									asset.details.price.toFixed(8)
								)} ${asset.details.currency}`}</span>
							</div>
						</div>
					</div>
				</div>
				<hr className='hrCls' />
				<div className='position-relative zindex1'>
					<div className='d-flex align-items-center mb-3'>
						<span className='fs-14 fw-600 dark-text'>
							{t('Wallet')}
						</span>
						<span className='walletLabel successLabel ml-2'>
							{t('Connected')}
						</span>
					</div>

					<div className='walletCnt d-flex align-items-center flex-wrap mb-3'>
						<h3>{formatAddress(account, 8)}</h3>
						{copied ? (
							<CheckIcon className='ml-auto' />
						) : (
							<img
								src={copyIcon}
								alt='copy-icon'
								className='ml-auto hover'
								onClick={() => {
									setCopied(true)
									copyText(account, () => {
										setTimeout(() => {
											setCopied(false)
										}, 600)
									})
								}}
								onKeyDown={handleKeyDown} tabIndex={0}
							/>

						)}
					</div>
				</div>

				<hr className='hrCls mt-3' />

				<div className='borderRoundedCard'>
					<div class='d-flex justify-content-between'>
						<div className='fs-14 fw-400 dark-text-secondary'>
							{t('Total')}
						</div>
						<div className='fs-16 fw-700 dark-text'>
							<span className='dark-text-grey fs-16 fw-400'>
								($ {formatAmount(asset.details.priceInUsd)} )
							</span>{' '}
							{`${parseFloat(total.toFixed(8))} ${asset.details.currency
								}`}
						</div>
					</div>
				</div>

				<div className='mt-4'>
					{isFundsSufficient === undefined ? (
						<button
							className='btn btn-block checkoutBtn'
							disabled={!isFundsSufficient}
						>
							{t('Checkout')}{' '}
						</button>
					) : isFundsSufficient ? (
						<button
							className='btn btn-block checkoutBtn'
							onClick={handleCheckout}
						>
							{t('Checkout')}{' '}
						</button>
					) : (
						<p className='text-danger fs-14 text-center'>
							{t('Insufficient funds!')}
						</p>
					)}
				</div>
			</ModalBody>
		</Modal>
	)
}
