import { useMutation } from 'wagmi'
import { useQueryClient } from '@tanstack/react-query'
import DataService from '../../../lib/DataService'

const { postRequest } = DataService()

function editListing(data) {
	return postRequest('asset/edit-listing', { ...data })
}

export function useEditListing() {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: editListing,
		onSuccess: () => {
			queryClient.invalidateQueries(['asset'])
			queryClient.invalidateQueries(['activities'])
			queryClient.invalidateQueries(['listings'])
		}
	})
}
