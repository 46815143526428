import { useQuery } from '@tanstack/react-query'

import DataService from '../../../lib/DataService'

const { getRequest } = DataService()
function priceHistory(collectionAddress, nftId) {
	
	return getRequest(`asset/price-history?collection=${collectionAddress}&id=${nftId}`)

}

export function usePriceHistory(collectionAddress, nftId) {
	return useQuery({
		queryFn: () => priceHistory(collectionAddress, nftId),
		enabled: !!(collectionAddress && nftId)
	})
}
