import React, { useEffect, useState, useContext } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import '../Activity/profileActivity.scss';
import './profileOfferMade.scss';

import yellowTick from '../../../assets/images/collection/yellow-tick_20px.svg';
import redClose from '../../../assets/images/redClose.svg';
import walletLoaderCnt from "../../../assets/images/walletLoader.png"
import walletLightLoaderCnt from '../../../assets/images/wallet-light-loader.png'
import walletLoader from "../../../assets/images/loaderCircle.png"
import { ThemeContext } from '../../context/context';
import { axios } from '../../../lib/axios'
import {
    useAccount,
} from 'wagmi'
import noItemfoundImage from '../../../assets/images/collection/noItemfoundImage.svg';
import cnddsLight from '../../../assets/images/cnddsLight.svg'
import { useNavigate, useParams } from 'react-router-dom';
import loader from '../../../assets/images/Loader.png'
import moment from 'moment';
import { useToast } from '../../../utils/toast/customSuccess'

import ResponsivePagination from 'react-responsive-pagination';
import defaultImage from '../../../assets/images/homeExplorer/placeholder.png'
import { handleImageError } from '../../../hooks/useHandleImg';
import arrow from '../../../assets/images/Icon2.png';
import { useTranslation } from 'react-i18next';
import DataService from '../../../lib/DataService';
import { CloseIcon } from '../../../components/modals/makeOffer/CloseIcon';

const landPerRow = 6

const ActivityCollection = () => {

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const getback = () => {
		setModal(!modal)
	}
    const { getRequest, postRequest } = DataService()


    const navigate = useNavigate()
    const toast = useToast()
    const { address } = useAccount()
    const { id } = useParams()

    const [loading, setLoading] = useState(false)
    let [color, setColor] = useState('#ffffff')
    const { offermadestatus, setOfferMadeStatus, offermadetype, setOfferMadeType, offermadecollection, setOfferMadeCollection, offermadesearch, setOffermadeSearch } = useContext(ThemeContext)


    const [next, setNext] = useState(landPerRow)
    const [page, setPage] = useState(1)
    const [count1, setCount] = useState('')

    const [totalnftcount, setTotalNftCount] = useState(0)

    function handlePageChange(page) {
        setPage(page);
    }

    useEffect(() => {

        GetOfferMade()

    }, [address, id, page, offermadestatus, offermadetype, offermadecollection, offermadesearch])

    let forDeletion = ["traitoffer"]

    const [offerMade, setOfferMade] = useState("")
    const [videoError, setVideoError] = useState(false)

    const GetOfferMade = async () => {
        setLoading(true)
        const fetch = await getRequest(`user/offers_made?address=${id ? id : address}&page=${offermadecollection > 0 ? 1 : offermadesearch !== "" ? 1 : offermadetype > 0 ? 1 : page}&limit=15&collectionName=${offermadecollection}&filter=${offermadesearch}&status=${offermadestatus}&type=${offermadetype.filter(item => !forDeletion.includes(item))}&trait=${offermadetype.includes("traitoffer") ? "traitoffer" : ""}`)
        if (fetch.status == true) {
            setOfferMade(fetch.data)
            setLoading(false)
            setCount(Math.ceil(fetch.count / 15))
            setTotalNftCount(fetch.count)
            setPage(offermadecollection > 0 ? 1 : offermadesearch !== "" ? 1 : offermadetype > 0 ? 1 : page)
        }
        else {
            setLoading(false)
            setOfferMade("")
            setTotalNftCount('')
            setCount("")
            setPage(1)
        }
    }
    const { t } = useTranslation()

    const Typee = {
        "make-offer": t("Single Offers"),
        "make-collection-offer": t("Collection Offers"),
        "traitoffer": t("Trait Collection Offers"),
    };


    const handleVideoError = () => {
        setVideoError(true);
    };
    const litstedCollection =
        offermadecollection &&
        offermadecollection.map(item => {
            return (
                <>
                    {item == '' ? (
                        ''
                    ) : (
                        <>
                            <div className='tagLabel'>
                                <span>{item}</span>
                                <i
                                    className='fas fa-times ml-2'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => closeCollection(item)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' || e.key === ' ') {
                                            closeCollection(item)
                                        }
                                    }}
                                    role="button" tabIndex={0}
                                ></i>
                            </div>
                        </>
                    )}
                </>
            )
        })

    const closeCollection = item => {
        let a = offermadecollection.includes(item)
        if (a == true) {
            const index = offermadecollection.indexOf(item)
            if (index > -1) {
                offermadecollection.splice(index, 1)
                const nerArr = [...offermadecollection]
                setOfferMadeCollection(nerArr)
            }
        }
    }


    const clearAll = () => {
        setOfferMadeStatus([""])
        setOfferMadeType([""])
        setOfferMadeCollection([])
        setOffermadeSearch("")
    }

    const Back1 = () => {
        setOfferMadeStatus([""])
        setOfferMadeType([""])
        setOfferMadeCollection([])
        setOffermadeSearch("")
    }

    const closesearch = () => {
        setOffermadeSearch("")
    }

    const closestatus = (data) => {
        let a = offermadestatus.includes(data)
        if (a == true) {
            const index = offermadestatus.indexOf(data);
            if (index > -1) {
                offermadestatus.splice(index, 1);
                const nerArr = [...offermadestatus]
                setOfferMadeStatus(nerArr);
                if (nerArr.length == 0) {
                    setOfferMadeStatus([""]);
                }
            }

        }
    }

    const closetype = (data) => {
        let a = offermadetype.includes(data)
        if (a == true) {
            const index = offermadetype.indexOf(data);
            if (index > -1) {
                offermadetype.splice(index, 1);
                const nerArr = [...offermadetype]
                setOfferMadeType(nerArr);
                if (nerArr.length == 0) {
                    setOfferMadeType([""]);
                }
            }

        }
    }

    const liststatus = offermadestatus && offermadestatus.map((item) => {
        return (
            <>
                {item == "" ?
                    ""
                    :
                    <>
                        <div className='tagLabel'>
                            <span style={{ textTransform: "capitalize" }}>{item == 1 ? t("Active") : item == 4 ? t("Expiring") : item == 3 ? t("Inactive") : ""}</span>
                            <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={() => closestatus(item)} onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    closestatus(item)
                                }
                            }}
                                role="button" tabIndex={0}></i>
                        </div>
                    </>
                }
            </>
        );
    });
    const listtype = offermadetype && offermadetype.map((item) => {
        return (
            <>
                {item == "" ?
                    ""
                    :
                    <>
                        <div className='tagLabel'>
                            <span style={{ textTransform: "capitalize" }}>{Typee[item]}</span>
                            <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={() => closetype(item)} onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    closetype(item)
                                }
                            }}
                                role="button" tabIndex={0}></i>
                        </div>
                    </>
                }
            </>
        );
    });

    const [sortOrder, setSortOrder] = useState({
        field: '',
        ascending: true,
    });

    const handleSort = (field) => {
        setSortOrder((prevSortOrder) => ({
            field,
            ascending: field === prevSortOrder.field ? !prevSortOrder.ascending : true,
        }));
    };

    const sortedData = [...offerMade].sort((a, b) => {
        const aValue = a[sortOrder.field];
        const bValue = b[sortOrder.field];

        if (aValue === undefined || bValue === undefined) {
            return sortOrder.ascending ? (aValue === undefined ? 1 : -1) : bValue === undefined ? 1 : -1;
        }

        if (typeof aValue !== typeof bValue) {
            return sortOrder.ascending ? (typeof aValue > typeof bValue ? 1 : -1) : typeof bValue > typeof aValue ? 1 : -1;
        }

        const compareResult = aValue.localeCompare ? aValue.localeCompare(bValue) : aValue - bValue;

        return sortOrder.ascending ? compareResult : -compareResult;
    });

    const listViewrow = sortedData && sortedData.map((item, index) =>
        <>

            <tr

                onClick={() => {
                    item.type !== "make-offer" ?
                        navigate(
                            `/collection/${item.slug}`
                        )
                        :
                        navigate(
                            `/assets/${item.chain}/${item.contractAddress}/${item.nftId}`
                        )
                }
                }
                style={{ cursor: 'pointer' }}
            >

                <td>
                    <div className='d-flex align-items-center'>
                        <div className='tblImg'>
                            {typeof item?.asset == "string" && item?.asset !== undefined && !item?.asset?.startsWith(process.env.S3_URL) && !item?.asset?.startsWith('ipfs') && item?.asset !== null ?

                                item?.imageFormat?.startsWith('video') ?
                                    videoError ? <img src={defaultImage} alt={`item.imgsrc`} />
                                        :

                                        <video autoplay muted playsInline preload='metadata' controlslist='nodownload' className='img-fluid w-100 itemImage' style={{ width: "60px", height: "70px", borderRadius: "12px" }}>
                                            <source src={item?.asset ? item?.asset : defaultImage} type="video/mp4" onError={handleVideoError} />
                                        </video>

                                    :
                                    item?.asset !== 'undefined' ?
                                        <img src={item?.asset ? item?.asset : defaultImage} alt={`item.imgsrc`} onError={handleImageError} />
                                        :
                                        <img src={defaultImage} alt={`item.imgsrc`} />
                                :
                                <img src={defaultImage} alt={`item.imgsrc`} />
                            }

                        </div>
                        <div>
                            <div className='fw-600'>{item.type === 'make-collection-offer' ? item.collectionName : item.nftName !== "" ? item.nftName : item.nftId ? `#${item.nftId}` : '---'}<img src={item.verification == "Verified" ? yellowTick : ""} height="18" className="ml-1" /></div>
                        </div>
                    </div>
                </td>
                <td className='text-right'>
                    <div>{item.price ? item?.price?.toFixed(8) : ""} {item.currency ? item.currency : ""}</div>
                    <div className='fs-12 dark-text-secondary'>${(item.price * item.usdPrice)?.toFixed(8)}</div>
                </td>
                <td className='text-right'>{item.quantity}</td>
                <td className='text-right'>{item.price ? (item.quantity * item?.price)?.toFixed(8) : ""} {item.currency ? item.currency : ""}</td>
                {item.isExpired == true ? <td className='text-right'><span style={{ color: 'red' }}>{t("Expired")}</span> </td> :
                    <td className='text-right'>{item.saleExpiry == 0 ? '-' : moment(item.saleExpiry).fromNow()}</td>}
                <td className='text-right'>{item.createdAt == 0 ? '-' : moment(item.createdAt).fromNow()}</td>
                {!!address && address.toLowerCase() === item.fromAddress ?
                    <td className='text-right'><span className="cancelTag" onClick={e => {
                        e.stopPropagation();
                        canceloffer(item);
                    }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                                e.stopPropagation();
                                canceloffer(item);
                            }
                        }}
                        role="button" tabIndex={0}
                    >{t("Cancel")}</span></td>
                    : null}
            </tr>
        </>
    )


    const [canceloffermade, setCancelOfferMade] = useState("")
    const [loaders, setloaders] = useState(false)

    const canceloffer = (data) => {
        setCancelOfferMade(data)
        toggle()
    }

    const offer = async () => {
        setloaders(true)

        if (address) {
            let data = {
                _id: canceloffermade._id,
                type: canceloffermade.type,
                contractAddress: canceloffermade.contractAddress,
                address
            }

            const fetch = await postRequest(`user/cancel_offerMade`, data)
            if (fetch.status == true) {
                setTimeout(() => {
                    setModal(!modal)
                    toast.cancel("Offer canceled.")
                    GetOfferMade()
                    setloaders(false)
                }, 1000)
            } else {
                setModal(!modal)
                setloaders(false)
                toast.error(fetch.message)
            }
        }
        else {
            toast.error("Please connect wallet")
        }
    }

    const Back = () => {
        setModal(!modal)
    }


    return (

        <>
            <div className={`text-center ${loading ? 'spinner' : 'd-none'}`}>
                <img src={loader} alt='' className='spinAnimate' />
            </div>
            <div className='row my-3'>
                <div className='col-lg-6'><span class="cmntxt">{totalnftcount ? totalnftcount : "0"} {t("offers made")}</span></div>
            </div>

            <div className='fiterSelectlabel mb-4 mt-4'>
                {offermadesearch ?
                    <div className='tagLabel'>
                        <span>{offermadesearch}</span>
                        <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={closesearch} onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                                closesearch()
                            }
                        }}
                            role="button" tabIndex={0}></i>
                    </div>
                    :
                    ""}

                {offermadestatus.includes("All") ? "" : liststatus}{offermadetype.includes("All") ? "" : listtype}{litstedCollection}

                {
                    Number(offermadestatus.filter(item => item.trim() !== "").length) + Number(offermadetype.filter(item => item.trim() !== "").length) + Number(offermadecollection.length) + Number(offermadesearch.trim() === "" ? 0 : offermadesearch.trim().replace(/\s+/g, "").split(/\s+/).length) > 1 ?
                        <button className='clearAllBtn' onClick={clearAll}>{t("Clear All")}</button>
                        :
                        ""
                }


            </div>
            {offerMade && offerMade.length > 0 ?
                <div className='table-responsive'>
                    <table className='table table-borderless activityTable offerTable'>

                        <thead>
                            <tr>
                                <th>{t("Offer")}</th>
                                <th className='text-right' onClick={() => handleSort('price')}>{t("Price")} {sortOrder.field !== 'price' ? <img src={arrow} width="14px" style={{  cursor: "pointer" }}/> : sortOrder.field === 'price' && (sortOrder.ascending ? <i class="fa fa-angle-up" style={{ fontSize: "14px", cursor: "pointer" }}></i> : <i class="fa fa-angle-down" style={{ fontSize: "14px", cursor: "pointer" }}></i>)}</th>
                                <th className='text-right' onClick={() => handleSort('quantity')}>{t("Quantity")} {sortOrder.field !== 'quantity' ? <img src={arrow} width="14px" style={{  cursor: "pointer" }}/> : sortOrder.field === 'quantity' && (sortOrder.ascending ? <i class="fa fa-angle-up" style={{ fontSize: "14px", cursor: "pointer" }}></i> : <i class="fa fa-angle-down" style={{ fontSize: "14px", cursor: "pointer" }}></i>)}</th>
                                <th className='text-right' >Total</th>
                                <th className='text-right' onClick={() => handleSort('saleExpiry')}>{t("Expiration")} {sortOrder.field !== 'saleExpiry' ? <img src={arrow} width="14px" style={{  cursor: "pointer" }}/> : sortOrder.field === 'saleExpiry' && (sortOrder.ascending ? <i class="fa fa-angle-up" style={{ fontSize: "14px", cursor: "pointer" }}></i> : <i class="fa fa-angle-down" style={{ fontSize: "14px", cursor: "pointer" }}></i>)}</th>
                                <th className='text-right' onClick={() => handleSort('createdAt')}>{t("Created")} {sortOrder.field !== 'createdAt' ? <img src={arrow} width="14px" style={{  cursor: "pointer" }}/> : sortOrder.field === 'createdAt' && (sortOrder.ascending ? <i class="fa fa-angle-up" style={{ fontSize: "14px", cursor: "pointer" }}></i> : <i class="fa fa-angle-down" style={{ fontSize: "14px", cursor: "pointer" }}></i>)}</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            {listViewrow}
                        </tbody>
                    </table>
                </div>
                :
                <div className='noItemFound'>
                    {loading == false ?
                        <>
                            <div className='text-center'>
                                <img src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : noItemfoundImage} alt="noItemfoundImage" className='img-fluid' />
                            </div>

                            {offermadesearch || offermadecollection.length > 0 || !offermadestatus.includes("") || !offermadetype.includes("") ?
                                <>
                                    <div className='nofounttxt mb-4'>
                                        {t("No items were found. Try to broaden your search.")}
                                    </div>
                                    <div>
                                        <button className='applyBtn' onClick={Back1}>
                                            {t("Back to all items")}
                                        </button>
                                    </div>
                                </>
                                :
                                <div className='nofounttxt mb-4'>
                                    {t("Nothing to display.")}
                                </div>
                            }
                        </>
                        :
                        ""
                    }
                </div>

            }

            <div className='row justify-content-center'>
                <div className='col-lg-4 col-md-6 col-sm-8'>
                    <div class="bri_pagenation2">
                        {offerMade && offerMade.length > 0 ?
                            <div class=''>
                                <nav aria-label="Page ">
                                    <ResponsivePagination
                                        total={count1}
                                        current={page}
                                        onPageChange={page => handlePageChange(page)}
                                        previousLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.125 5.75L5.71875 1.125C5.84375 0.96875 6.09375 0.96875 6.25 1.125L6.84375 1.75C7 1.875 7 2.125 6.84375 2.28125L3.15625 6L6.84375 9.75C7 9.90625 7 10.125 6.84375 10.2812L6.25 10.9062C6.09375 11.0625 5.84375 11.0625 5.71875 10.9062L1.125 6.28125C0.96875 6.125 0.96875 5.90625 1.125 5.75Z" fill="#F7F7F7"></path></svg>}
                                        nextLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.84375 6.28125L2.28125 10.9062C2.125 11.0625 1.875 11.0625 1.75 10.9062L1.125 10.2812C0.96875 10.125 0.96875 9.90625 1.125 9.75L4.8125 6L1.125 2.28125C0.96875 2.125 0.96875 1.875 1.125 1.75L1.75 1.125C1.875 0.96875 2.125 0.96875 2.28125 1.125L6.84375 5.75C7 5.90625 7 6.125 6.84375 6.28125Z" fill="#F7F7F7"></path></svg>}
                                        previousClassName="page-link-1"
                                        nextClassName="page-link-1"
                                    />
                                </nav>
                            </div>
                            :
                            ""
                        }
                    </div>
                </div>
            </div>

            <Modal isOpen={modal} toggle={toggle} centered="true" className="mymodal" backdropClassName="selCurBp" backdrop='static' keyboard={false}>
                {loaders == true ? (
                    <>
                        <ModalHeader>{t("Cancel Offer")}</ModalHeader>
                        <ModalBody>
                            <p className="dark-text-secondary text-center">{t("To continue send transaction with your wallet")}.</p>
                            <div className="d-flex justify-content-center align-items-center loaderCont">
                                {localStorage.getItem('light_theme') == 'true' ?
                                    <img src={walletLightLoaderCnt} /> :
                                    <img src={walletLoaderCnt} />
                                }
                                <div className="loaderAnimCnt">
                                    <img src={walletLoader} className="loaderAnimation" />
                                </div>
                            </div>
                        </ModalBody>
                    </>
                ) : (
                    <>
                        <ModalHeader close={loading == true ? null : <CloseIcon onClose={getback} customclass="closefix" />}>
                            <div className="d-flex flex-column align-items-center px-4">
                                <img src={redClose} className="mb-3" alt="greenCheck" />
                                {t("Are you sure you want to cancel your offer?")}
                            </div>
                        </ModalHeader>
                        <ModalBody className="pt-0">
                            <div className='mt-4'>
                                <button className='btn btn-block darkBtn mb-2 text-danger' onClick={offer}>{t("Cancel Offer")}</button>
                                <button className='btn btn-block darkBtn mb-2' onClick={Back}>{t("Get Back")}</button>
                            </div>
                        </ModalBody>
                    </>
                )}
            </Modal>
        </>
    )
}

export default ActivityCollection;