import { useState } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi'

import { Items } from './Items'
import { Activity } from './Activity'
import { Offers } from './Offers'
import { useMakeCollectionOfferModalActions } from '../../components/modals/collection-offer/store'
import { useConnectWalletModalActions } from '../../components/modals/connect-wallet/store'
import AnalyticsCollectionMain from '../../views/collection/collectionAnalyticsMain'
import { chainId } from '../../config/constants/chains'
import { chainIdMap, chainMap } from '../../utils/getFilterData'
import { useOkxSwitchNetwork } from '../../hooks/useOkxSwitchNetwork'



export default function Tabs({ collectionAddress, collectionDetails }) {
	const { t } = useTranslation()

	const [activeTab, setActiveTab] = useState('1')
	const [searchParams, setSearchParams] = useSearchParams()
	const { address: account } = useAccount()
	const { chain } = useNetwork()
	const { switchNetworkAsync } = useSwitchNetwork()
	const { switchNetwork } = useOkxSwitchNetwork()
	const walletType = localStorage.getItem('walletType')

	const { toggleMakeCollectionOfferModal, setCollection } =
		useMakeCollectionOfferModalActions()
	const { toggleConnectWalletModal, setConnectWalletModalState } =
		useConnectWalletModalActions()

	const isCreator =
		account &&
		account.toLowerCase() ===
		collectionDetails?.data?.creator?.address?.toLowerCase()

	async function handleMakeOffer() {
		{
			setCollection(collectionDetails?.data)
			setConnectWalletModalState({
				chainId: chainIdMap[collectionDetails?.data.analytics.chain]
			})
			if (!account) {
				toggleConnectWalletModal()
				setConnectWalletModalState({
					callback: toggleMakeCollectionOfferModal
				})
				return
			}

			try {
				if (walletType === 'okx') {
					if (
						collectionDetails?.data.analytics.chain !== chainMap[parseInt(window.okxwallet.chainId)]
					) {
						await switchNetwork(chainIdMap[collectionDetails?.data.analytics.chain])
					}
				} else {
					if (
						collectionDetails?.data.analytics.chain === 'binance' &&
						chain.id !== chainId.BINANCE
					) {
						await switchNetworkAsync(chainId.BINANCE)
					} else if (
						collectionDetails?.data.analytics.chain === 'ethereum' &&
						chain.id !== chainId.ETHEREUM
					) {
						await switchNetworkAsync(chainId.ETHEREUM)
					} else if (
						collectionDetails?.data.analytics.chain === 'polygon' &&
						chain.id !== chainId.AMOY
					) {
						await switchNetworkAsync(chainId.AMOY)
					}
				}
			} catch (error) {
			}

			toggleMakeCollectionOfferModal()
		}
	}

	return (
		<div className='container-fluid'>
			<div className='row'>
				<div className='col-lg-6'>
					<Nav
						tabs
						className='mycollectiontabs'
						style={{ cursor: 'pointer' }}
					>
						<NavItem>
							<NavLink
								className={classnames({
									active: activeTab === '1'
								})}
								onClick={() => {
									setSearchParams('')
									setActiveTab('1')
								}}
							>
								{t('Items')}
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={classnames({
									active: activeTab === '2'
								})}
								onClick={() => {
									setSearchParams('')
									setActiveTab('2')
								}}
							>
								{t('Offers')}
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={classnames({
									active: activeTab === '3'
								})}
								onClick={() => {
									setSearchParams('')
									setActiveTab('3')
								}}
							>
								{t('Activity')}
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={classnames({
									active: activeTab === '4'
								})}
								onClick={() => {
									setSearchParams('')
									setActiveTab('4')
								}}
							>
								{t('Analytics')}
							</NavLink>
						</NavItem>
					</Nav>
				</div>
				{!isCreator && (
					<div className='col-lg-6 text-right'>
						{!collectionDetails?.data?.isMoralis ? (
							<button
								className='mb-4 collectOfferBtn'
								onClick={handleMakeOffer}
							>
								{t('Make Collection Offer')}
							</button>
						) : (
							''
						)}
					</div>
				)}
			</div>
			<div className='row'>
				<div className='col-12'>
					<TabContent activeTab={activeTab}>
						<TabPane tabId='1'>
							{activeTab === '1' && (
								<Items
									collectionAddress={collectionAddress}
									collectionDetails={collectionDetails}
								/>
							)}
						</TabPane>

						<TabPane tabId='2'>
							{activeTab === '2' && (
								<Offers
									collectionAddress={collectionAddress}
									collectionDetails={collectionDetails}
									tab={activeTab}
								/>
							)}
						</TabPane>
						<TabPane tabId='3'>
							{activeTab === '3' && (
								<Activity
									collectionAddress={collectionAddress}
									collectionDetails={collectionDetails}
									tab={activeTab}
								/>
							)}
						</TabPane>
						<TabPane tabId='4'>
							{activeTab === '4' && <AnalyticsCollectionMain />}
						</TabPane>
					</TabContent>
				</div>
			</div>
		</div>
	)
}