import { useMutation } from '@tanstack/react-query'
import { axios } from '../../../lib/axios'
import DataService from '../../../lib/DataService'
const config = {
	headers: {
		'Content-Type': 'multipart/form-data'
	}
}

const { postformdataRequest, postRequest } = DataService()


export const getCollection = data => {
	return postRequest('user/get_collection_names', { ...data })
}

export const useGetCollection = () => {
	return useMutation(getCollection, {
		onMutate: () => { },
		onError: error => { },
		onSuccess: getOneCollectionData => {

		}
	})
}

export const createNFT = data => {
	return postformdataRequest('user/create_nft', data)
}

export const useCreateNFT = () => {
	return useMutation(createNFT, {
		onMutate: () => { },
		onError: error => { },
		onSuccess: getOneCollectionData => {
		}
	})
}

export const getRoyalityFee = data => {
	return postRequest('admin/nft/get_royalty_fee', { ...data })
}

export const useGetRoyalityFee = () => {
	return useMutation(getRoyalityFee, {
		onMutate: () => { },
		onError: error => { },
		onSuccess: getOneCollectionData => {
		}
	})
}


export const getName = data => {
	return postRequest('admin/nft/check_NFT_name', { ...data })
}

export const useGetName = () => {
	return useMutation(getName, {

	})
}
