import React, { useEffect, useState } from 'react';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '../../../utils/useDebounce';
import cndds from "../../../assets/images/cndds.png"
import cnddsLight from '../../../assets/images/cnddsLight.svg'


const FilterOffer = ({ traits, filters, onTraitsChange }) => {
    const { t } = useTranslation()
    const [selectedStatuses, setSelectedStatuses] = useState(
        !filters.search.stringTraits ? ['No Trait'] : Object.values(filters.search.stringTraits)
    )
    const [activeItem, setActiveItem] = useState('No Trait');



    const handleOffersFilter = (item) => {
        setActiveItem(item.traitValue)
        if (item.traitName === 'No Trait') {
            const updatedfilters = {
                ...filters,
                search: {
                    ...filters.search,
                    stringTraits: null
                }
            }
            onTraitsChange(updatedfilters)
        } else {
            const updatedfilters = {
                ...filters,
                search: {
                    ...filters.search,
                    stringTraits: {
                        0: {
                            name: item.traitName,
                            values: {
                                0: item.traitValue
                            }
                        }
                    }

                }
            }
            onTraitsChange(updatedfilters)
        }
    }


    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchText = useDebounce(searchTerm, 500);
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        if (traits && searchTerm == "")
            setSearchResults(traits && traits?.data)
    }, [searchResults, traits])

    const filterData = (searchTerm) => {
        if (searchTerm) {
            const filteredResults = traits?.data.filter(item =>
                item.traitName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                item.traitValue?.toLowerCase().includes(searchTerm?.toLowerCase())
            );

            setSearchResults(filteredResults);
        }
        else {
            setSearchResults(traits?.data)
        }
    };

    useEffect(() => {
        filterData(debouncedSearchText);
    }, [debouncedSearchText]);

    useEffect(() => {
        setSelectedStatuses(
            !filters.search.stringTraits
                ? ['ALL']
                : Object.values(filters.search.stringTraits)
        )
        setSearchResults(traits && traits?.data)
    }, [filters])

    return (
        <>
            <div className='filtercard'>
                <div className='filterTit'>{t("Traits")}</div>
                <div className='collapseCnt'>
                    <div className="form-group formInputs">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText className='px-2'>
                                    <svg
                                        width="17"
                                        height="17"
                                        viewBox="0 0 17 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M15.875 14.6562C16.0312 14.8125 16.0312 15.0625 15.875 15.1875L15.1562 15.9062C15.0312 16.0625 14.7812 16.0625 14.625 15.9062L10.8438 12.125C10.7812 12.0312 10.75 11.9375 10.75 11.8438V11.4375C9.59375 12.4062 8.125 13 6.5 13C2.90625 13 0 10.0938 0 6.5C0 2.9375 2.90625 0 6.5 0C10.0625 0 13 2.9375 13 6.5C13 8.125 12.375 9.625 11.4062 10.75H11.8125C11.9062 10.75 12 10.8125 12.0938 10.875L15.875 14.6562ZM6.5 11.5C9.25 11.5 11.5 9.28125 11.5 6.5C11.5 3.75 9.25 1.5 6.5 1.5C3.71875 1.5 1.5 3.75 1.5 6.5C1.5 9.28125 3.71875 11.5 6.5 11.5Z"
                                            fill="white"
                                        />
                                    </svg>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder={t("Search")} name="searchInput" value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)} />
                        </InputGroup>
                    </div>
                    {searchResults?.length > 0 ? searchResults?.map((item) => (
                        <div className={activeItem === item.traitValue ? 'filterofferscard active mb-2' : 'filterofferscard mb-2'} onClick={() => handleOffersFilter(item)} onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                                handleOffersFilter(item)
                            }
                        }}
                            role="button"
                            tabIndex={0}>
                            <div className='d-flex justify-content-between '>
                                <div>
                                    <div className='fs-16 fw-600 dark-text'>{item.price} {item.currency}</div>
                                    <div className='text-nowrap'>Volume: {parseFloat(item.volume.toFixed(8))} {item.currency}</div>
                                </div>
                                {item.traitName != "No Trait" && item.traitValue != "No Trait" ? (
                                    <div className='text-right'>
                                        <div className='text-nowrap'><span className='spanTextRight'>{item.traitName}</span> <span>{item.percentage}%</span></div>
                                        <div>{item.traitValue}</div>
                                    </div>) : (<div className='mt-3'>{t("No Traits")}</div>)}
                            </div>
                        </div>
                    )) :
                        <div className="noResf text-center" >
                            <img class="img-fluid" src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : cndds} alt="" />
                            <h1>{t("Nothing yet")}</h1>
                        </div>
                    }
                </div>
            </div>
        </>
    );
}

export default FilterOffer;