import { create } from 'zustand'

const useMakeCollectionOfferModalStore = create((set) => ({
	isOpen: false,
	collection: null,
	actions: {
		toggleMakeCollectionOfferModal: () =>
			set((state) => ({
				...state,
				isOpen: !state.isOpen
			})),
		setCollection: (collection) =>
			set((state) => ({
				...state,
				collection
			}))
	}
}))

export const useMakeCollectionOfferModalState = () =>
	useMakeCollectionOfferModalStore(({ isOpen, collection }) => ({
		isOpen,
		collection
	}))

export const useMakeCollectionOfferModalActions = () =>
	useMakeCollectionOfferModalStore((state) => state.actions)
