import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import { usePurchaseLoadingModalState } from './store'

import walletLoaderCnt from '../../../assets/images/walletLoader.png'
import walletLightLoaderCnt from '../../../assets/images/wallet-light-loader.png'
import walletLoader from '../../../assets/images/loaderCircle.png'
import { useTranslation } from 'react-i18next'

export function PurchaseLoadingModal() {
	const { isOpen, heading } = usePurchaseLoadingModalState()
	const { t } = useTranslation()

	return (
		<Modal
			isOpen={isOpen}
			centered='true'
			className='mymodal'
			backdropClassName='selCurBp'
			keyboard={false}
		>
			<ModalHeader>
			<h5 className='modal-title'>{t(heading)}</h5>
			</ModalHeader>
			<ModalBody>
				<h3 className='walletSubHeading'>
					{t("To continue send transaction with your wallet")}.
				</h3>
				<div className='d-flex justify-content-center align-items-center loaderCont'>
					{localStorage.getItem('light_theme') == 'true' ?
						<img src={walletLightLoaderCnt} /> :
						<img src={walletLoaderCnt} />
					}
					<div className='loaderAnimCnt'>
						<img src={walletLoader} className='loaderAnimation' />
					</div>
				</div>
			</ModalBody>
		</Modal>
	)
}
