import React, { useEffect } from 'react'

import style from './cmsLayout.module.scss'
import Header from '../Header/Header'
import { Outlet } from 'react-router-dom'
import helpcenterBanner from '../../assets/images/helpcenter/helpcenterBanner.png'
import { useToggleClassName } from '../../hooks/useToggleClassName'

const CMSPagesLayout = (props) => {
	const HeadPrimeTxt = props.HeadPrimeTxt
	const headFirstTxt = props.headFirstTxt
	const bannerimgsize = '100%'

	useEffect(() => {
		document.body.classList.add('helpcenterpages')
	}, [])
	useToggleClassName()

	return (
		<>
			<Header />
			<div className={style.bannerParent}>
				<div className={style.cl_banner}>
					<img
						src={helpcenterBanner}
						alt='collectionBanner'
						width={bannerimgsize}
						height={bannerimgsize}
					/>
				</div>
				<div className={style.bannerContent}>
					<div className={style.maxwidthcnt}>
						<div className={style.bannerTit}>
							<span>{HeadPrimeTxt}</span> {headFirstTxt}
						</div>
					</div>
				</div>
			</div>
			<div className='py-5'>
				<Outlet />
			</div>
		</>
	)
}

export default CMSPagesLayout
