import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAccount } from 'wagmi'

import DataService from '../../../lib/DataService'

const {  postRequest } = DataService()

function buy(asset, address) {
	const data = {
		orderId: asset.details.orderId,
		currency: asset.details.currency,
		price: asset.details.price,
		address
	}

	return postRequest('asset/buy', { ...data })
}

export function useBuy() {
	const queryClient = useQueryClient()
	const { address: account } = useAccount()

	return useMutation({
		mutationFn: asset => buy(asset, account),
		onSuccess: () => {
			queryClient.invalidateQueries(['asset'])
			queryClient.invalidateQueries(['listings'])
			queryClient.invalidateQueries(['activity'])
		}
	})
}
