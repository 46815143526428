import { Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'

export function ItemsViewTabs({ activeTab, setActiveTab, children }) {
	return (
		<>
			<div className='mr-3 mb-2'>
				<Nav pills className='ItemsTabsViewTabs'>
					<NavItem className='hover'>
						<NavLink
							onClick={() => {
								setActiveTab('list')
							}}
							className={classnames({
								active: activeTab === 'list'
							})}
						>
							<svg
								width='16'
								height='14'
								viewBox='0 0 16 14'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M1.5 10.5C2.3125 10.5 3 11.1875 3 12C3 12.8438 2.3125 13.5 1.5 13.5C0.65625 13.5 0 12.8438 0 12C0 11.1875 0.65625 10.5 1.5 10.5ZM1.5 5.5C2.3125 5.5 3 6.1875 3 7C3 7.84375 2.3125 8.5 1.5 8.5C0.65625 8.5 0 7.84375 0 7C0 6.1875 0.65625 5.5 1.5 5.5ZM1.5 0.5C2.3125 0.5 3 1.1875 3 2C3 2.84375 2.3125 3.5 1.5 3.5C0.65625 3.5 0 2.84375 0 2C0 1.1875 0.65625 0.5 1.5 0.5ZM15.5 1.25C15.75 1.25 16 1.5 16 1.75V2.25C16 2.53125 15.75 2.75 15.5 2.75H5.5C5.21875 2.75 5 2.53125 5 2.25V1.75C5 1.5 5.21875 1.25 5.5 1.25H15.5ZM15.5 6.25C15.75 6.25 16 6.5 16 6.75V7.25C16 7.53125 15.75 7.75 15.5 7.75H5.5C5.21875 7.75 5 7.53125 5 7.25V6.75C5 6.5 5.21875 6.25 5.5 6.25H15.5ZM15.5 11.25C15.75 11.25 16 11.5 16 11.75V12.25C16 12.5312 15.75 12.75 15.5 12.75H5.5C5.21875 12.75 5 12.5312 5 12.25V11.75C5 11.5 5.21875 11.25 5.5 11.25H15.5Z'
									fill={
										activeTab === 'list'
											? '#FFA900'
											: 'white'
									}
									fillOpacity={
										activeTab === 'list' ? 'list' : '0.6'
									}
								/>
							</svg>
						</NavLink>
					</NavItem>
					<NavItem className='hover'>
						<NavLink
							onClick={() => {
								setActiveTab('grid')
							}}
							className={classnames({
								active: activeTab === 'grid'
							})}
						>
							<svg
								width='20'
								height='20'
								viewBox='0 0 20 20'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M16.5 3C17.3125 3 18 3.6875 18 4.5V15.5C18 16.3438 17.3125 17 16.5 17H3.5C2.65625 17 2 16.3438 2 15.5V4.5C2 3.6875 2.65625 3 3.5 3H16.5ZM8.15625 4.25V7.25H11.8125V4.25H8.15625ZM8.15625 8.5V11.5H11.8125V8.5H8.15625ZM6.90625 15.75V12.75H3.25V15.375C3.25 15.5938 3.40625 15.75 3.625 15.75H6.90625ZM6.90625 11.5V8.5H3.25V11.5H6.90625ZM6.90625 7.25V4.25H3.625C3.40625 4.25 3.25 4.4375 3.25 4.625V7.25H6.90625ZM11.8125 15.75V12.75H8.15625V15.75H11.8125ZM16.75 15.75V12.75H13.0625V15.75H16.75ZM16.75 11.5V8.5H13.0625V11.5H16.75ZM16.75 7.25V4.25H13.0625V7.25H16.75Z'
									fill={
										activeTab === 'grid'
											? '#FFA900'
											: 'white'
									}
									fillOpacity={
										activeTab === 'grid' ? 'list' : '0.6'
									}
								/>
							</svg>
						</NavLink>
					</NavItem>
				</Nav>
			</div>
			{children}
		</>
	)
}
