export function getProvider() {
	if (!window.ethereum) return

	let provider
	const walletType = localStorage.getItem('walletType')
	if (walletType === 'coinbaseWallet') {
		if (window.ethereum.providers != undefined) {
			provider = window.ethereum.providers.find(
				(provider) => provider.isCoinbaseWallet
			)
		} else {
			provider = window.web3.currentProvider
		}
	} else if (walletType === 'metaMask') {
		if (window.ethereum.providers != undefined) {
			provider = window.ethereum.providers.find(
				(provider) => provider.isMetaMask
			)
		} else {
			provider = window.ethereum
		}
	} else if (walletType == 'okx') {
		provider = window.okxwallet
	}

	return provider
}
