import React, { useContext, useEffect, useState } from 'react';

import { Button, Collapse, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import bnbIconSm from '../../assets/images/bnbIconSm.png';
import ethIconSm from '../../assets/images/ethIconSm.png';
import maticIconSm from '../../assets/images/maticIconSm.png';
import yellowTick from '../../assets/images/collection/yellow-tick_20px.svg';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../context/context';
import {
    useAccount,
} from 'wagmi'
import { useToast } from '../../utils/toast/customSuccess';
import { useParams } from 'react-router-dom';
import { NumericFormat } from 'react-number-format'
import defaultImage from '../../assets/images/homeExplorer/placeholder.png'
import { handleImageError } from '../../hooks/useHandleImg';
import { nativeCoinMap } from '../../utils/getFilterData';
import DataService from '../../lib/DataService';
const FilterSection = (props) => {

    const { t } = useTranslation()

    const [isOpenCollapse1, setIsOpenCollapse1] = useState(false);
    const toggleCollapse1 = () => {
        setIsOpenCollapse1(!isOpenCollapse1);
    }
    const [isOpenCollapse2, setIsOpenCollapse2] = useState(false);
    const toggleCollapse2 = () => {
        setIsOpenCollapse2(!isOpenCollapse2);
    }
    const [isOpenCollapse3, setIsOpenCollapse3] = useState(false);
    const toggleCollapse3 = () => {
        setIsOpenCollapse3(!isOpenCollapse3);
    }
    const [isOpenCollapse4, setIsOpenCollapse4] = useState(false);
    const toggleCollapse4 = () => {
        setIsOpenCollapse4(!isOpenCollapse4);
    }
    const [isOpenCollapse5, setIsOpenCollapse5] = useState(false);
    const toggleCollapse5 = () => {
        setIsOpenCollapse5(!isOpenCollapse5);
    }

    const { address, isConnected } = useAccount()

    const toast = useToast()


    const Status = [
        {
            id: 1,
            name: t("All"),
            sortStatusField: "",

        },
        {
            id: 2,
            name: t("Listed"),
            sortStatusField: "list",
        },
        {
            id: 3,
            name: t("On Auction"),
            sortStatusField: "auction",
        },
        {
            id: 4,
            name: t("New"),
            sortStatusField: "new",
        },
        {
            id: 5,
            name: t("Has Offers"),
            sortStatusField: "make-offer",
        }

    ]

    const { collectedstatus, setcollectedStatus, checkcollectedChain, setcollectedCheckChain, chooselistingcollectedchain, setListingCollectedChain, collectedminAmount, setCollectedMinAmount, collectedmaxAmount, setCollectedMaxAmount, Collectedstatus, setCollectedStatus, singlecollectedcollectionList, setSingleCollectedCollectionList,
        contractAddresscollected, setContarctAddressCollected, checkcollectedCurrency, setcollectedCheckCurrency, filtercollection, setFiltercollection } = useContext(ThemeContext)

    const FilterCollectedStatus = (data) => {
        setcollectedStatus(data.sortStatusField)
        if (data.sortStatusField == "") {
            setcollectedStatus([""])
        }
        else {
            let a = collectedstatus.includes(data.sortStatusField)
            if (a == true) {
                const index = collectedstatus.indexOf(data.sortStatusField);
                if (index > -1) {
                    collectedstatus.splice(index, 1);
                    const nerArr = [...collectedstatus]
                    setcollectedStatus(nerArr);
                    if (nerArr.length == 0) {
                        setcollectedStatus([""]);
                    }
                }

            } else if (a == false) {
                const index = collectedstatus.indexOf("");
                if (index > -1) {
                    collectedstatus.splice(index, 1);
                }
                setcollectedStatus(
                    [...collectedstatus, data.sortStatusField]
                );
            }
        }
    }
    const { postformdataRequest, getRequest, postRequest } = DataService()


    const Chains = [
        {
            id: 1,
            name: t("BNB"),
            value: "binance",
        },
        {
            id: 2,
            name: t("ETH"),
            value: "ethereum",
        },
        {
            id: 3,
            name: t("MATIC"),
            value: "polygon",
        }
    ]

    const ChainCurrency = [
        {
            id: 1,
            name: t("BNB"),
            value: "binance",
        },
        {
            id: 2,
            name: t("ETH"),
            value: "ethereum",
        },
        {
            id: 3,
            name: t("MATIC"),
            value: "polygon",
        },
        {
            id: 4,
            name: t("WOR"),
            value: "wor",
        },
        {
            id: 5,
            name: t("BUSD"),
            value: "busd",
        },
        {
            id: 6,
            name: t("USDT"),
            value: "usdt",
        },
    ]

    const Currency = [
        {
            id: 1,
            name: t("BNB"),
            value: "BNB",
        },
        {
            id: 2,
            name: t("ETH"),
            value: "ETH",
        },
        {
            id: 3,
            name: t("MATIC"),
            value: "MATIC",
        },
        {
            id: 4,
            name: t("USDT"),
            value: "USDT",
        },
        {
            id: 5,
            name: t("WOR"),
            value: "WOR",
        },
        {
            id: 6,
            name: t("BUSD"),
            value: "BUSD",
        }
    ]

    const { id } = useParams()


    const handleCheckboxChange = (item) => {
        let a = checkcollectedChain.includes(item.value)

        if (a == true) {

            const index = checkcollectedChain.indexOf(item.value);
            if (index > -1) {
                checkcollectedChain.splice(index, 1);
                const nerArr = [...checkcollectedChain]
                setcollectedCheckChain(nerArr);
            }

        } else if (a == false) {
            setcollectedCheckChain(
                [...checkcollectedChain, item.value]
            );
        }
    }

    const handleCheckboxCurrencyChange = (item) => {
        let a = checkcollectedCurrency.includes(item.value)

        if (a == true) {

            const index = checkcollectedCurrency.indexOf(item.value);
            if (index > -1) {
                checkcollectedCurrency.splice(index, 1);
                const nerArr = [...checkcollectedCurrency]
                setcollectedCheckCurrency(nerArr);
            }

        } else if (a == false) {
            setcollectedCheckCurrency(
                [...checkcollectedCurrency, item.value]
            );
        }
    }


    useEffect(() => {
        GetFilterCollection()
    }, [address, id])


    const GetFilterCollection = async () => {
        const getcollection = await getRequest(`user/owned-collections?address=${id ? id : address}`)
        if (getcollection.status == true) {
            setFiltercollection(getcollection.data)
        }
        else {
            setFiltercollection("")
        }
    }


    const [search, setSearch] = useState("")

    const handleOnKeyDown = async (e) => {
        if (e.key == 'Enter' || e.key == "") {
            const searchcollection = await getRequest(`user/owned-collections?address=${id ? id : address}&search=${e.target.value}`)
            if (searchcollection.status == true) {
                setFiltercollection(searchcollection.data)
            }
            else {
                setFiltercollection("")
            }
        }

    }


    const singlelistingcollection = (data) => {

        let a = contractAddresscollected.some(item => item.contractAddress === data.contractAddress);

        if (a == true) {
            const updatedList = contractAddresscollected.filter(item => item.contractAddress !== data.contractAddress);
            setContarctAddressCollected(updatedList);
        } else if (a == false) {
            setContarctAddressCollected(
                [...contractAddresscollected, { contractAddress: data.contractAddress, collectionName: data.collectionName }]
            );
        }
    }

    const Apply = () => {

        if (Number(collectedmaxAmount) > 0) {
            if (Number(collectedminAmount) > Number(collectedmaxAmount)) {
                toast.error("Minimum must be less than maximum")
                setCollectedMinAmount("")
                setCollectedMaxAmount("")
            }
            else {
                setCollectedStatus(true)
            }
        }
        else {
            setCollectedStatus(true)
        }
    }

    return (
        <>
            <div className='filtercard'>
                <div>
                    <Button className='filterTit' onClick={toggleCollapse1}>
                        <span>{t("Status")} </span>
                        <i className={`fas ${isOpenCollapse1 ? 'fa-angle-up' : 'fa-angle-down'}`} ></i>
                    </Button>
                    <Collapse isOpen={isOpenCollapse1}>
                        <div className='collapseCnt'>
                            {Status && Status.map((data, index) => (
                                <button className={collectedstatus?.length > 0 ? collectedstatus?.includes(data.sortStatusField) ? 'filterStatusbtn active' : 'filterStatusbtn' : 'filterStatusbtn'} onClick={() => FilterCollectedStatus(data)}>{data.name}</button>
                            ))}
                        </div>
                    </Collapse>
                </div>
                <div>
                    <Button className='filterTit' onClick={toggleCollapse2}>
                        <span>{t("Chains")} </span>
                        <i className={`fas ${isOpenCollapse2 ? 'fa-angle-up' : 'fa-angle-down'}`} ></i>
                    </Button>
                    <Collapse isOpen={isOpenCollapse2}>
                        <div className='collapseCnt'>
                            <div className='filterInnercard'>

                                {Chains && Chains.map((data, index) => (
                                    <div class="d-flex align-items-center mb-2" key={data.id} >
                                        <label class="cuschk mr-2 mb-0" For={`checkbx1${index + 1}`}>
                                            <input type="checkbox" id={`checkbx1${index + 1}`} name="chains" checked={checkcollectedChain?.length > 0 ? checkcollectedChain.includes(data.value) : false} onChange={() => handleCheckboxChange(data)} />
                                            <span class="checkmark"></span>
                                        </label>
                                        <img src={data.value == "binance" ? bnbIconSm : data.value == "ethereum" ? ethIconSm : data.value == "polygon" ? maticIconSm : ""} className="mr-1" width="20px" />
                                        <h3 className='mb-0 filterLabel' style={{ textTransform: "capitalize" }}>{data.value}</h3>
                                        <h3 className='mb-0 filterLabel dark-text-secondary ml-auto'>{data.name}</h3>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </Collapse>
                </div>
                <div>
                    <Button className='filterTit' onClick={toggleCollapse3}>
                        <span>{t("Collections")} </span>
                        <i className={`fas ${isOpenCollapse3 ? 'fa-angle-up' : 'fa-angle-down'}`} ></i>
                    </Button>
                    <Collapse isOpen={isOpenCollapse3}>
                        <div className='collapseCnt'>
                            <div className="form-group formInputs">
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText className='px-2'>
                                            <svg
                                                width="17"
                                                height="17"
                                                viewBox="0 0 17 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M15.875 14.6562C16.0312 14.8125 16.0312 15.0625 15.875 15.1875L15.1562 15.9062C15.0312 16.0625 14.7812 16.0625 14.625 15.9062L10.8438 12.125C10.7812 12.0312 10.75 11.9375 10.75 11.8438V11.4375C9.59375 12.4062 8.125 13 6.5 13C2.90625 13 0 10.0938 0 6.5C0 2.9375 2.90625 0 6.5 0C10.0625 0 13 2.9375 13 6.5C13 8.125 12.375 9.625 11.4062 10.75H11.8125C11.9062 10.75 12 10.8125 12.0938 10.875L15.875 14.6562ZM6.5 11.5C9.25 11.5 11.5 9.28125 11.5 6.5C11.5 3.75 9.25 1.5 6.5 1.5C3.71875 1.5 1.5 3.75 1.5 6.5C1.5 9.28125 3.71875 11.5 6.5 11.5Z"
                                                    fill="white"
                                                />
                                            </svg>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder={t("Search Collection")} name="searchInput" onKeyDown={handleOnKeyDown} />
                                </InputGroup>
                            </div>

                            <div className='scroller htcheckbox'>
                                {filtercollection && filtercollection.length > 0 ?
                                    filtercollection && filtercollection.map((data, index) => (
                                        <div className=
                                            {`${contractAddresscollected?.length > 0 ? contractAddresscollected.some(item => item.contractAddress === data.contractAddress) ? 'h-verCollection' : "filterofferscard notrans mb-2" :
                                                "filterofferscard notrans mb-2"
                                                }`} onClick={() => singlelistingcollection(data)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter' || e.key === ' ') {
                                                    singlelistingcollection(data)
                                                }
                                            }}
                                            role="button" tabIndex={0} style={{ cursor: "pointer" }}>
                                            <div className='d-flex align-items-center'>
                                                {!data?.logoImage?.startsWith(process.env.S3_URL) && !data?.logoImage?.startsWith('ipfs') && data?.logoImage !== null ? <img src={data?.logoImage !== "" ? data?.logoImage : defaultImage} className="mr-2" height="36" width="36" style={{ objectFit: "cover", borderRadius: "10px" }} onError={handleImageError} /> : <img src={defaultImage} className="mr-2" height="36" width="36" style={{ objectFit: "cover", borderRadius: "10px" }} />}
                                                <div>
                                                    <div className='fs-12 fw-600 dark-text'>
                                                        {data.collectionName}
                                                        <img src={data.verification == "Verified" ? yellowTick : ""} className="ml-1" />
                                                    </div>
                                                    <div>{t("Listed")}: {data.listedNfts == "" ? 0 : data.listedNfts?.toString()}/{data.totalNfts?.toString()}</div>
                                                </div>
                                                <div className='text-right ml-auto'>
                                                    <div><span className="whiteTxt">{t("Floor")}:</span> {data.floorPrice == "0" ? 0 : parseFloat(data?.floorPrice?.toFixed(6))} {nativeCoinMap[data.chain]}</div>
                                                    <div><span className="whiteTxt">{t("Volume")}:</span>{data.volume == "0" ? 0 : parseFloat(data?.volume?.toFixed(6))} {nativeCoinMap[data.chain]}</div>
                                                </div>
                                            </div>

                                        </div>
                                    ))
                                    :

                                    <p style={{ justifyContent: "center", display: "flex" }}>No Data Found</p>

                                }
                            </div>


                        </div>
                    </Collapse>
                </div>
                <div>
                    <Button className='filterTit' onClick={toggleCollapse4}>
                        <span>{t("Price")} </span>
                        <i className={`fas ${isOpenCollapse4 ? 'fa-angle-up' : 'fa-angle-down'}`} ></i>
                    </Button>
                    <Collapse isOpen={isOpenCollapse4}>
                        <div className='collapseCnt'>
                            <div className='row align-items-center'>
                                <div className='col-12' style={{ minWidth: '90px' }}>
                                    <div className='form-group formInputs'>
                                        <Input type="select" onChange={(e) => setListingCollectedChain(e.target.value)}>
                                            <option selected value="">{t("Select")}</option>
                                            {ChainCurrency && ChainCurrency.map((data, index) => (
                                                <>
                                                    <option value={data.name} key={index}>{data.name}</option>
                                                </>
                                            ))}
                                        </Input>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='form-group formInputs'>
                                        <NumericFormat className='form-control' placeholder={t('Min')} name="collectedminAmount" onChange={(e) => setCollectedMinAmount(e.target.value)} allowNegative={false} customInput={Input} value={collectedminAmount} />

                                    </div>
                                </div>
                                <div className='col-auto text-center px-0 mb-3'>{t("to")}</div>
                                <div className='col'>
                                    <div className='form-group formInputs'>
                                        <NumericFormat className='form-control' placeholder={t('Max')} name="collectedmaxAmount" onChange={(e) => setCollectedMaxAmount(e.target.value)} value={collectedmaxAmount} allowNegative={false} customInput={Input} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                {chooselistingcollectedchain && collectedminAmount || collectedmaxAmount ?
                                    <button className='applybtn' onClick={Apply}>{t("Apply")}</button>
                                    :
                                    <button className='applybtn' disabled>{t("Apply")}</button>
                                }
                            </div>
                        </div>
                    </Collapse>
                </div>
                <div>
                    <Button className='filterTit' onClick={toggleCollapse5}>
                        <span>{t("Currency")} </span>
                        <i className={`fas ${isOpenCollapse5 ? 'fa-angle-up' : 'fa-angle-down'}`} ></i>
                    </Button>
                    <Collapse isOpen={isOpenCollapse5}>
                        <div className='collapseCnt'>
                            <div className='filterInnercard'>
                                {Currency && Currency.map((data, index) => (
                                    <div class="d-flex align-items-center mb-2" key={data.id} >
                                        <label class="cuschk mr-2 mb-0" For={`checkbx12${index + 1}`}>
                                            <input type="checkbox" id={`checkbx12${index + 1}`} name="currency" checked={checkcollectedCurrency?.length > 0 ? checkcollectedCurrency.includes(data.value) : false} onChange={() => handleCheckboxCurrencyChange(data)} />
                                            <span class="checkmark"></span>
                                        </label>

                                        <h3 className='mb-0 filterLabel' style={{ textTransform: "capitalize" }}>{data.name}</h3>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Collapse>
                </div>

            </div>
        </>
    );
}

export default FilterSection;