import React, { useState, useEffect, useLayoutEffect, useRef, useContext } from 'react';
import loader from '../../../assets/images/Loader.png'

import { InputGroup, InputGroupAddon, InputGroupText, Input, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from 'reactstrap';
import classnames from 'classnames';
import '../../../assets/scss/filterInputItem.scss';
import Hiddenfiltersection from './Hiddenfiltersection';
import filterIcon from '../../../assets/images/collection/filterIcon.svg';
import arrowLeftIcon from '../../../assets/images/collection/arrowLeftIcon.svg';
import noItemfoundImage from '../../../assets/images/collection/noItemfoundImage.svg';
import cnddsLight from '../../../assets/images/cnddsLight.svg'
import { ThemeContext } from '../../context/context';
import { useAccount } from 'wagmi';
import { useNavigate, useParams } from 'react-router-dom';
import defaultImage from '../../../assets/images/homeExplorer/placeholder.png'
import { handleImageError } from '../../../hooks/useHandleImg'
import hideIcon from '../../../assets/images/Icon1.png'
import { useTranslation } from 'react-i18next';
import moreIcon from '../assets/moreIcon.svg'
import yellowTick from '../../../assets/images/collection/yellow-tick_20px.svg';
import yellowTick20px from '../../../assets/images/collection/yellow-tick_20px.svg'
import { useToast } from '../../../utils/toast/customSuccess'
import { nativeCoinMap, chainIconMap } from '../../../utils/getFilterData';
import DataService from '../../../lib/DataService';
const ItemsCollection = (props) => {

  const { t } = useTranslation()

  const [activeTab, setActiveTab] = useState('2');
  const [isFilterView, setfilterView] = useState(true)
  const { address, isConnected } = useAccount()
  const [page, setPage] = useState(1)
  const [hidden, setHidden] = useState("")
  const [videoError, setVideoError] = useState(false)
  const gridview = activeTab ? 'g-col-5' : 'g-col-6'

  const toast = useToast()

  const { getRequest, postRequest } = DataService()



  const hideNFT = async (id, contractAddress) => {
    let hide = false
    const HidedNFT = await postRequest(`user/hide-nft?nftId=${id}&isHide=${hide}&owner=${address}&contractAddress=${contractAddress}`)
    if (HidedNFT.status === true) {
      GetHidden()
      toast.unhide('1item successfully unhidden.')
    } else {
      toast.error('Something went wrong')
    }
  }

  const handleVideoError = async () => {
    setVideoError(true)
  }

  const { hiddensearch, setHiddensearch, hiddenfilter, setHiddenFilter,
    hiddenstatus, sethiddenStatus, checkhiddenChain, setCheckhiddenChain,
    singlehiddencollectionList, chooselistinghiddenchain, hiddenminAmount,
    hiddenmaxAmount, Hiddenstatus, setHiddenStatus, contractAddresshidden, setContarctAddressHidden, checkhiddencollection, setCheckhiddenCollection, hiddenfiltersortby, setHiddenFilterSortby, hiddenfiltersortorder, setHiddenFilterSortorder, setHideCOllection
  } = useContext(ThemeContext)

  const { id } = useParams()

  useEffect(() => {
    GetHidden()

  }, [address, id, page, Hiddenstatus, hiddensearch, hiddenfilter,
    hiddenstatus,
    checkhiddenChain,
    singlehiddencollectionList,
    contractAddresshidden,
    Hiddenstatus == true ? chooselistinghiddenchain : '',
    Hiddenstatus == true ? hiddenminAmount : '',
    Hiddenstatus == true ? hiddenmaxAmount : '',
    Hiddenstatus == true ? hiddenstatus : '',
    checkhiddencollection, setCheckhiddenCollection,
    id])

  const toggle = tab => {
    if (setActiveTab !== tab) setActiveTab(tab)
  }
  const filterShow = () => {
    setfilterView(!isFilterView)
  }

  const CheckTick = () => {
    return (
      <>
        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.1953 0.46875C10.3125 0.351562 10.5 0.351562 10.5938 0.46875L11.2734 1.125C11.3672 1.24219 11.3672 1.42969 11.2734 1.52344L4.24219 8.55469C4.125 8.67188 3.96094 8.67188 3.84375 8.55469L0.703125 5.4375C0.609375 5.32031 0.609375 5.13281 0.703125 5.03906L1.38281 4.35938C1.47656 4.26562 1.66406 4.26562 1.78125 4.35938L4.03125 6.63281L10.1953 0.46875Z" fill="#FFA900" />
        </svg>
      </>
    )
  }

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);



  const Back = () => {
    sethiddenStatus([""])
    setCheckhiddenChain([])
    setHiddensearch('')
    setCheckhiddenChain([])
    setContarctAddressHidden([])
    sethiddenStatus()
    setHiddenStatus(false)
    setHiddenFilter('Recently Received')
    setHiddenFilterSortby('_id')
    setHiddenFilterSortorder('desc')
  }
  const ref = useRef(null)
  const [divWidth, setDivWidth] = useState('')
  const [divWidth1, setDivWidth1] = useState([window.innerWidth])
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()


  const GetHidden = async () => {
    setLoading(true)
    const fetch = await getRequest(
      `user/get_hidden_nfts?address=${id ? id : address}&page=${hiddensearch !== ''
        ? 1
        : hiddenstatus > 0 ? 1 : checkhiddenChain > 0 ? 1 : hiddenfiltersortby !== '_id'
          ? 1
          : contractAddresshidden > 0 ? 1 : page}&limit=15&filter=${hiddensearch}&sortField=${hiddenfiltersortby}&sortStatusField=${hiddenstatus}&chain=${checkhiddenChain}&collectionName=${contractAddresshidden &&
          contractAddresshidden.map(item => {
            return (
              item?.collectionName
            )
          })}&contractAddress=${contractAddresshidden &&
          contractAddresshidden.map(item => {
            return item?.contractAddress
          })
      }`
    )
    if (fetch?.status == true) {

      setHidden(fetch.data)
      setLoading(false)
      setPage(hiddensearch !== "" ? 1 : contractAddresshidden > 0 ? 1 : checkhiddenChain > 0 ? 1 : Hiddenstatus == true ? 1 : hiddenfiltersortorder !== "desc" ? 1 : hiddenfiltersortby !== "_id" ? 1 : hiddenstatus > 0 ? 1 : page)
      const getcollection = await postRequest("user/getHidedCollectionNFT", { "address": id ? id : address })
      if (getcollection.status == true) {
        setHideCOllection(getcollection.data)
      }

    }
    else {
      setHidden("")
      setLoading(false)
      const getcollection = await postRequest("user/getHidedCollectionNFT", { "address": id ? id : address })
      if (getcollection.status == true) {
        setHideCOllection(getcollection.data)
      }
    }
  }



  const handleResize = () => {
    setDivWidth(ref.current.offsetWidth)
    setDivWidth1([window.innerWidth])
  }
  useEffect(() => {
    if (ref.current) window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [ref])

  useEffect(() => {
    const handleWindowResize = () => {
      setDivWidth1(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useLayoutEffect(() => {
    setDivWidth(ref.current.offsetWidth)
    setDivWidth1([window.innerWidth])
  }, [])


  const ItemCard =
    hidden &&
    hidden.map(item => {
      return (
        !item?.isHide ? <></> : <>
          <div
            className='grid-col'
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
            onClick={() =>
              navigate(
                `/assets/${item.chain}/${item.contractAddress}/${item.nftId}`
              )
            }
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                navigate(`/assets/${item.chain}/${item.contractAddress}/${item.nftId}`)
              }
            }}
            role="button" tabIndex={0}
          >
            <div className={`gridItemCard ${item.itemadded ? 'active' : ''}`}>
              <div className='gItemImg'>
                {typeof item?.asset == 'string' &&
                  item?.asset !== undefined &&
                  !item?.asset?.startsWith(process.env.S3_URL) &&
                  !item?.asset?.startsWith('ipfs') && item?.asset !== null ? (
                  item?.imageFormat?.startsWith('video') ? (
                    videoError ? <img
                      src={defaultImage}
                      className='img-fluid w-100 itemImage'
                    /> :


                      <video autoPlay muted playsInline className='img-fluid w-100 itemImage' style={{
                        width: '100px',
                        height: '150px',
                        objectFit: 'cover'
                      }}>
                        <source src={item?.asset ? item.asset : defaultImage} type="video/mp4" onError={handleVideoError} />
                      </video>

                  ) : item?.asset !== 'undefined' ? (
                    <img
                      src={item?.asset ? item?.asset : defaultImage}
                      className='img-fluid w-100 itemImage'
                      onError={handleImageError}
                    />
                  ) : (
                    <img
                      src={defaultImage}
                      className='img-fluid w-100 itemImage'
                    />
                  )
                ) : (
                  <img
                    src={defaultImage}
                    className='img-fluid w-100 itemImage'
                  />
                )}
                {item.contractStandard == "ERC1155" ?
                  <div className='idTag'>#{item.supply}</div>
                  :
                  ""
                }
                <div className='hoverImgCnt'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex align-items-center'>
                      <img
                        src={
                          chainIconMap[item.chain]
                        }
                        alt='ETHIcon'
                        className='cointypeImg'
                      />
                    </div>
                  </div>
                  <div className='d-flex justify-content-between align-items-center'>

                    {!!address && address.toLowerCase() === item.owner ? (
                      <button className='cartBtnCircle' onClick={e => {
                        e.stopPropagation()
                      }} style={{ marginInlineStart: "auto" }}>
                        <UncontrolledDropdown>
                          <DropdownToggle className="plainToggle">
                            <img src={moreIcon} alt="shoppingcart" />
                          </DropdownToggle>
                          <DropdownMenu right className="dropdownMenu">
                            <DropdownItem onClick={() => hideNFT(item?._id, item?.contractAddress)}>
                              <img src={hideIcon} alt="hideIcon" className='mr-2' /> {t("Unhide")}
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </button>) : null}
                  </div>
                </div>
              </div>
              <div className='botcntCard'>
                <div className='bottomSmImg'>
                  <img src={item.imgsmsrc} height={73} alt='ItemimgSm1' />
                </div>
                <div className='botcntTxt'>
                  <div className='itemLabel'>
                    {item.collectionName}{' '}
                    <img
                      src={
                        item.verification == 'Verified' ? yellowTick20px : ''
                      }
                      height='12'
                    />
                  </div>
                  <div className='itemName'>
                    {item.nftName !== ''
                      ? item.nftName
                      : item.nftId
                        ? `#${item.nftId}`
                        : '---'}
                  </div>
                  <div class='d-flex justify-content-between'>
                    <div class='itemPricetxt'>{t("Highest Bid")}</div>
                    <div class='itemPriceval'>
                      {item.highestBid ? item.highestBid : '--'}{' '}
                      {item.highestBid
                        ? nativeCoinMap[item.chain]
                        : ''}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )
    })

  const listViewrow = hidden && hidden.map((item, index) =>
    <>
      <tr >
        <td >
          <div className='d-flex align-items-center'>
            <div className='tblImg'>


              {typeof item?.asset == "string" && item?.asset !== undefined && !item?.asset?.startsWith(process.env.S3_URL) && !item?.asset?.startsWith('ipfs') && item?.asset !== null ?

                item?.imageFormat?.startsWith('video') ?
                  videoError ? <img src={defaultImage} alt={`item.imgsrc`} />
                    : <video

                      src={item?.asset}
                      alt="Uploaded"
                      muted
                      autoPlay
                      onError={handleVideoError}
                    />
                  :
                  item?.asset !== 'undefined' ?
                    <img src={item?.asset ? item?.asset : defaultImage} alt={`item.imgsrc`} onError={handleImageError} />

                    :
                    <img src={defaultImage} alt={`item.imgsrc`} />
                :
                <img src={defaultImage} alt={`item.imgsrc`} />
              }

            </div>

            <div>
              <div className='fw-600 ml-2'>{item.nftName !== "" ? item.nftName : item.nftId ? `#${item.nftId}` : '---'} #{item.supply}</div>
              <div className='smTxt dark-text-secondary ml-2'>{item.collectionName}
                <img src={item.verification == "Verified" ? yellowTick : ""} height="18" className="ml-1" /></div>
            </div>
          </div>
        </td>
        <td><span className="dark-text-grey">{item.floorPrice ? item.floorPrice : "--"} {item.floorPrice ? nativeCoinMap[item.chain] : ""}</span></td>
        <td className='text-right' style={{ opacity: "0.5" }}>{item.price ? item.price : "--"} {item.price ? nativeCoinMap[item.chain] : ""}</td>
        <td className='text-right' style={{ opacity: "0.5" }}>{item.supply}</td>
        <td className='text-right'>{item.price ? item.price : t("Not Listed")} {item.price ? nativeCoinMap[item.chain] : ""}</td>
        <td className="text-right"><span className="dark-text-grey" style={{ opacity: "0.5" }}>
          --
        </span></td>
        <td className='text-right'>
          <span className='cancelTag' onClick={() => hideNFT(item?._id, item?.contractAddress)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                hideNFT(item?._id, item?.contractAddress)
              }
            }}
            role="button" tabIndex={0}>
            {t('Unhide')}
          </span>
        </td>

      </tr>

    </>
  )

  const hiddenFilter = [

    {
      name: t("Recently Received"),
      value: "recently received",
      sortField: "_id",
      sortOrder: "desc"
    },
    {
      name: t("Price: Low - High"),
      value: "price low to high",
      sortField: "price",
      sortOrder: "asc"
    },
    {
      name: t("Price: High - Low"),
      value: "price high to low",
      sortField: "price",
      sortOrder: "desc"
    },
    {
      name: t("Oldest"),
      value: "oldest",
      sortField: "_id",
      sortOrder: "asc"
    },
    {
      name: t("Highest Floor"),
      value: "highest floor",
      sortField: "floorPrice",
      sortOrder: "desc"
    },
    {
      name: t("Best Offer"),
      value: "best offer",
      sortField: "price",
      sortOrder: "desc"
    },
    {
      name: t("Recently Created"),
      value: "recently created",
      sortField: "_id",
      sortOrder: "desc"
    },
    {
      name: t("Highest For Sale"),
      value: "highest for sale",
      sortField: "price",
      sortOrder: "desc"
    },
    {
      name: t("Recently Listed"),
      value: "recently listed",
      sortField: "_id",
      sortOrder: "desc"
    },
  ]

  const closecollectedfilter = () => {
    setHiddenFilter('Recently Received')
    setHiddenFilterSortby('_id')
    setHiddenFilterSortorder('desc')
  }

  const chooseFilterdata = (data) => {
    setHiddenFilter(data.name)
    setHiddenFilterSortby(data.sortField)
    setHiddenFilterSortorder(data.sortOrder)
  }

  const handleOnKeyDown = (e) => {
    if (e.key == 'Enter' || e.key == '') {
      setHiddensearch(e.target.value)
    }
  }

  const clearAll = () => {
    setHiddensearch('')
    sethiddenStatus([''])
    setCheckhiddenChain([])
    setContarctAddressHidden([])
    setHiddenStatus(false)
    setHiddenFilter('Recently Received')
    setHiddenFilterSortby('_id')
    setHiddenFilterSortorder('desc')
  }

  const closecollectedsearch = () => {
    setHiddensearch('')
  }

  const closesearch1 = () => {
    setHiddenStatus(false)
  }

  const closecollectedstatus = data => {
    let a = hiddenstatus.includes(data)
    if (a == true) {
      const index = hiddenstatus.indexOf(data)
      if (index > -1) {
        hiddenstatus.splice(index, 1)
        const nerArr = [...hiddenstatus]
        sethiddenStatus(nerArr)
        if (nerArr.length == 0) {
          sethiddenStatus([''])
        }
      }
    }
  }

  const litstedCollection =
    contractAddresshidden &&
    contractAddresshidden.map(item => {
      return (
        <>
          {item == '' ? (
            ''
          ) : (
            <>
              <div className='tagLabel'>
                <span>{item.collectionName}</span>
                <i
                  className='fas fa-times ml-2'
                  style={{ cursor: 'pointer' }}
                  onClick={() => closeCollection(item)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      closeCollection(item)
                    }
                  }}
                  role="button" tabIndex={0}
                ></i>
              </div>
            </>
          )}
        </>
      )
    })




  const closeCollection = data => {
    let a = contractAddresshidden.some(
      item => item.contractAddress === data.contractAddress
    )
    if (a == true) {
      const updatedList = contractAddresshidden.filter(
        item => item.contractAddress !== data.contractAddress
      )
      setContarctAddressHidden(updatedList)
    }
  }


  const litstedTagLoop3 =
    hiddenstatus &&
    hiddenstatus.map(item => {
      return (
        <>
          {item == '' ? (
            ''
          ) : (
            <>
              <div className='tagLabel'>
                <span className='' style={{ textTransform: 'capitalize' }}>
                  {t(item)}
                </span>
                <i
                  className='fas fa-times ml-2'
                  style={{ cursor: 'pointer' }}
                  onClick={() => closecollectedstatus(item)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      closecollectedstatus(item)
                    }
                  }}
                  role="button" tabIndex={0}
                ></i>
              </div>
            </>
          )}
        </>
      )
    })

  const litstedTagLoop2 =
    checkhiddenChain &&
    checkhiddenChain.map(item => {
      return (
        <>
          {item == '' ? (
            ''
          ) : (
            <>
              <div className='tagLabel'>
                <span>{item}</span>
                <i
                  className='fas fa-times ml-2'
                  style={{ cursor: 'pointer' }}
                  onClick={() => closecollectedchain(item)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      closecollectedchain(item)
                    }
                  }}
                  role="button" tabIndex={0}
                ></i>
              </div>
            </>
          )}
        </>
      )
    })

  const closecollectedchain = item => {
    let a = checkhiddenChain.includes(item)

    if (a == true) {
      const index = checkhiddenChain.indexOf(item)
      if (index > -1) {
        checkhiddenChain.splice(index, 1)
        const nerArr = [...checkhiddenChain]
        setCheckhiddenChain(nerArr)
      }
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      closesearch1();
    }
  };

  return (
    <>
      <div className={`text-center ${loading ? 'spinner' : 'd-none'}`}>
        <img src={loader} alt='' className='spinAnimate' />
      </div>

      <div className='filterbtnshow' style={{ 'marginLeft': (((divWidth1[0] - divWidth) + 30) / 2) * -1 + 17 }}>
        <button className='filtershowhideBtn' onClick={filterShow} >
          {!isFilterView ? <>
            <img src={arrowLeftIcon} alt='arrowLeftIcon' />
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ 'position': 'absolute', 'right': '7px', 'marginTop': '2px' }}>
              <circle cx="5" cy="5" r="4" fill="url(#paint0_linear_462_183603)" stroke="#1D2025" stroke-width="2" />
              <defs>
                <linearGradient id="paint0_linear_462_183603" x1="1.54737" y1="1.46316" x2="8.41053" y2="8.78947" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FFA900" />
                  <stop offset="1" stop-color="#FFEDAE" />
                  <stop offset="1" stop-color="#FFF6A4" />
                </linearGradient>
              </defs>
            </svg>
          </> : <img src={filterIcon} alt='filterIcon' />}
        </button>
      </div>
      <div className="mb-5" ref={ref}>
        <div className="row">
          <div className="col-lg-12">
            <div className="itemWizard">
              <div className={`filterSection ${isFilterView ? 'show' : 'hide'}`} >
                <Hiddenfiltersection />
              </div>
              <div className={`viewItemscard ${isFilterView ? 'collapseWidth' : 'fullWidth'}`}>
                <div className="form-inline">
                  <div className="form-group formInputs mr-3 flex-grow-1">
                    <InputGroup className='mb-2'>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText className='px-2'>
                          <svg
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.875 14.6562C16.0312 14.8125 16.0312 15.0625 15.875 15.1875L15.1562 15.9062C15.0312 16.0625 14.7812 16.0625 14.625 15.9062L10.8438 12.125C10.7812 12.0312 10.75 11.9375 10.75 11.8438V11.4375C9.59375 12.4062 8.125 13 6.5 13C2.90625 13 0 10.0938 0 6.5C0 2.9375 2.90625 0 6.5 0C10.0625 0 13 2.9375 13 6.5C13 8.125 12.375 9.625 11.4062 10.75H11.8125C11.9062 10.75 12 10.8125 12.0938 10.875L15.875 14.6562ZM6.5 11.5C9.25 11.5 11.5 9.28125 11.5 6.5C11.5 3.75 9.25 1.5 6.5 1.5C3.71875 1.5 1.5 3.75 1.5 6.5C1.5 9.28125 3.71875 11.5 6.5 11.5Z"
                              fill="white"
                            />
                          </svg>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder={t("Search Items")} name="searchInput" onKeyDown={handleOnKeyDown} />
                    </InputGroup>
                  </div>
                  <div className="form-group formInputs mr-3 mb-2">
                    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className='customdropdown'>
                      <DropdownToggle>
                        <span>{hiddenfilter ? t(hiddenfilter) : t("Select Option")}
                        </span>
                        <i className={`fas ${dropdownOpen ? 'fa-angle-up' : 'fa-angle-down'}`}></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        {hiddenFilter && hiddenFilter.map((data, index) => (
                          <DropdownItem onClick={() => chooseFilterdata(data)}><div>{t(data.name)}</div><div>{hiddenfilter == data.value ? <CheckTick /> : ""}</div></DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <div className="mr-3 mb-2">
                    <Nav pills className="ItemsTabsViewTabs">
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            toggle("1");
                          }}
                          className={classnames({
                            active: activeTab === "1",
                          })}
                        >
                          <svg
                            width="16"
                            height="14"
                            viewBox="0 0 16 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.5 10.5C2.3125 10.5 3 11.1875 3 12C3 12.8438 2.3125 13.5 1.5 13.5C0.65625 13.5 0 12.8438 0 12C0 11.1875 0.65625 10.5 1.5 10.5ZM1.5 5.5C2.3125 5.5 3 6.1875 3 7C3 7.84375 2.3125 8.5 1.5 8.5C0.65625 8.5 0 7.84375 0 7C0 6.1875 0.65625 5.5 1.5 5.5ZM1.5 0.5C2.3125 0.5 3 1.1875 3 2C3 2.84375 2.3125 3.5 1.5 3.5C0.65625 3.5 0 2.84375 0 2C0 1.1875 0.65625 0.5 1.5 0.5ZM15.5 1.25C15.75 1.25 16 1.5 16 1.75V2.25C16 2.53125 15.75 2.75 15.5 2.75H5.5C5.21875 2.75 5 2.53125 5 2.25V1.75C5 1.5 5.21875 1.25 5.5 1.25H15.5ZM15.5 6.25C15.75 6.25 16 6.5 16 6.75V7.25C16 7.53125 15.75 7.75 15.5 7.75H5.5C5.21875 7.75 5 7.53125 5 7.25V6.75C5 6.5 5.21875 6.25 5.5 6.25H15.5ZM15.5 11.25C15.75 11.25 16 11.5 16 11.75V12.25C16 12.5312 15.75 12.75 15.5 12.75H5.5C5.21875 12.75 5 12.5312 5 12.25V11.75C5 11.5 5.21875 11.25 5.5 11.25H15.5Z"
                              fill={activeTab === "1" ? "#FFA900" : "white"}
                              fillOpacity={activeTab === "1" ? "1" : "0.6"}
                            />
                          </svg>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            toggle("2");
                          }}
                          className={classnames({
                            active: activeTab === "2",
                          })}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.5 3C17.3125 3 18 3.6875 18 4.5V15.5C18 16.3438 17.3125 17 16.5 17H3.5C2.65625 17 2 16.3438 2 15.5V4.5C2 3.6875 2.65625 3 3.5 3H16.5ZM8.15625 4.25V7.25H11.8125V4.25H8.15625ZM8.15625 8.5V11.5H11.8125V8.5H8.15625ZM6.90625 15.75V12.75H3.25V15.375C3.25 15.5938 3.40625 15.75 3.625 15.75H6.90625ZM6.90625 11.5V8.5H3.25V11.5H6.90625ZM6.90625 7.25V4.25H3.625C3.40625 4.25 3.25 4.4375 3.25 4.625V7.25H6.90625ZM11.8125 15.75V12.75H8.15625V15.75H11.8125ZM16.75 15.75V12.75H13.0625V15.75H16.75ZM16.75 11.5V8.5H13.0625V11.5H16.75ZM16.75 7.25V4.25H13.0625V7.25H16.75Z"
                              fill={activeTab === "2" ? "#FFA900" : "white"}
                              fillOpacity={activeTab === "2" ? "1" : "0.6"}
                            />
                          </svg>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </div>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                  </TabPane>
                  <TabPane tabId="2">
                  </TabPane>
                </TabContent>
                <br />
                <div className=''>
                  <div className='row '>
                    <div className='col-lg-6'>
                      <span class='cmntxt'>
                        {hidden?.length} {t("hidden items")}
                      </span>
                    </div>
                  </div>
                  <div className='fiterSelectlabel mb-4'>
                    {hiddensearch ? (
                      <div className='tagLabel'>
                        <span>{hiddensearch}</span>
                        <i
                          className='fas fa-times ml-2'
                          style={{ cursor: 'pointer' }}
                          onClick={closecollectedsearch}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              closecollectedsearch()
                            }
                          }}
                          role="button" tabIndex={0}
                        ></i>
                      </div>
                    ) : (
                      ''
                    )}

                    {hiddenstatus.includes('') ? '' : litstedTagLoop3}
                    {litstedTagLoop2}
                    {litstedCollection}

                    {Hiddenstatus == true ? (
                      typeof chooselistinghiddenchain !== 'undefined' ? (
                        <div className='tagLabel'>
                          <span>
                            {chooselistinghiddenchain}
                          </span>
                          <i
                            className='fas fa-times ml-2'
                            style={{ cursor: 'pointer' }}
                            onClick={closesearch1}
                            onKeyDown={handleKeyDown}

                            role="button" tabIndex={0}
                          ></i>
                        </div>
                      ) : typeof chooselistinghiddenchain !== 'undefined' ? (
                        <div className='tagLabel'>
                          <span>
                            {chooselistinghiddenchain}
                          </span>
                          <i
                            className='fas fa-times ml-2'
                            style={{ cursor: 'pointer' }}
                            onClick={closesearch1}
                            onKeyDown={handleKeyDown}

                            role="button" tabIndex={0}
                          ></i>
                        </div>
                      ) : typeof chooselistinghiddenchain !== 'undefined' ? (
                        <div className='tagLabel'>
                          <span>
                            {chooselistinghiddenchain}
                          </span>
                          <i
                            className='fas fa-times ml-2'
                            style={{ cursor: 'pointer' }}
                            onClick={closesearch1}
                            onKeyDown={handleKeyDown}
                            role="button" tabIndex={0}
                          ></i>
                        </div>
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )}

                    {

                      Number(hiddenstatus.filter(item => item.trim() !== "").length) + Number(checkhiddenChain?.length) + Number(contractAddresshidden?.length) + Number(checkhiddencollection?.length) + Number(hiddensearch.trim() === "" ? 0 : hiddensearch.trim().replace(/\s+/g, "").split(/\s+/).length) > 1 ?

                        (
                          <button className='clearAllBtn' onClick={clearAll}>
                            {t('Clear All')}
                          </button>
                        ) : (
                          ''
                        )}
                  </div>
                </div>
                {hidden && hidden?.length > 0 ? (
                  activeTab === "1" ? <>
                    <div className='table-responsive'>
                      <table className='table table-borderless activityTable'>
                        {hidden && hidden?.length > 0 ?
                          <thead>
                            <tr>
                              <th></th>
                              <th>{t("Items")}</th>
                              <th className='text-right'>{t("Price")}</th>
                              <th className='text-right'>{t("Quantity")}</th>
                              <th className='text-right'>{t("From")}</th>
                              <th className='text-right'>{t("To")}</th>
                              <th className='text-right'>
                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.6665 4.6875C13.8774 4.6875 16.479 7.28906 16.479 10.5C16.479 13.7109 13.8774 16.3125 10.6665 16.3125C7.45557 16.3125 4.854 13.7109 4.854 10.5C4.854 7.28906 7.45557 4.6875 10.6665 4.6875ZM10.6665 15.1875C13.2446 15.1875 15.354 13.1016 15.354 10.5C15.354 7.92188 13.2446 5.8125 10.6665 5.8125C8.06494 5.8125 5.979 7.92188 5.979 10.5C5.979 13.1016 8.06494 15.1875 10.6665 15.1875ZM12.0962 12.75L10.104 11.2969C10.0337 11.25 10.0103 11.1562 10.0103 11.0859V7.21875C10.0103 7.07812 10.1274 6.9375 10.2915 6.9375H11.0415C11.1821 6.9375 11.3228 7.07812 11.3228 7.21875V10.5469L12.8696 11.6953C13.0103 11.7891 13.0337 11.9531 12.9399 12.0938L12.4946 12.6797C12.4009 12.8203 12.2368 12.8438 12.0962 12.75Z" fill="#595F6A" />
                                </svg>
                              </th>
                            </tr>
                          </thead>
                          :
                          ""
                        }
                        <tbody>
                          {hidden && hidden?.length > 0 ?
                            listViewrow
                            :
                            <div className='noItemFound justify-content-center'>
                              {loading == false ?
                                <>
                                  <div className='text-center'>
                                    <img src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : noItemfoundImage} alt="noItemfoundImage" className='img-fluid' />
                                  </div>

                                  <div>
                                    {Number(hiddenstatus.filter(item => item.trim() !== "").length) + Number(checkhiddenChain.filter(item => item.trim() !== "").length) + Number(checkhiddencollection.length) + Number(checkhiddencollection?.length) + Number(hiddensearch.trim() === "" ? 0 : hiddensearch.trim().replace(/\s+/g, "").split(/\s+/).length) > 0 ?
                                      <>
                                        <div className='nofounttxt mb-4'>
                                          {t('No items were found. Try to broaden your search.')}
                                        </div>
                                        <button className='applyBtn' onClick={() => Back()}>
                                          {t("Back to all items")}
                                        </button>
                                      </>
                                      :
                                      <div className='nofounttxt mb-4'>
                                        {t('Nothing to display.')}
                                      </div>
                                    }
                                  </div>
                                </>
                                :
                                ""
                              }
                            </div>
                          }


                        </tbody>
                      </table>
                    </div>

                  </>
                    : <>
                      <div className={`gridrow ${gridview}`}>{ItemCard}</div>
                    </>
                ) : <div className='noItemFound'>
                  <div className='text-center'>
                    <img src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : noItemfoundImage} alt="noItemfoundImage" className='img-fluid' />
                  </div>

                  <div>
                    {Number(hiddenstatus.filter(item => item.trim() !== "").length) + Number(checkhiddenChain.filter(item => item.trim() !== "").length) + Number(checkhiddencollection.length) + Number(checkhiddencollection?.length) + Number(hiddensearch.trim() === "" ? 0 : hiddensearch.trim().replace(/\s+/g, "").split(/\s+/).length) > 0 ?
                      <>
                        <div className='nofounttxt mb-4'>
                          {t('No items were found. Try to broaden your search.')}
                        </div>
                        <button className='applyBtn' onClick={() => clearAll()} >
                          {t("Back to all items")}
                        </button>
                      </>
                      :
                      <div className='nofounttxt mb-4'>
                        {t('Nothing to display.')}
                      </div>
                    }
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
}

export default ItemsCollection;