import React, { useEffect, useState } from 'react';
import checkTickIcon from '../../../assets/images/toast/checkIcon.svg';
import removeIcon from '../../../assets/images/toast/checkIcon.svg';
import noItemfoundImage from '../../../assets/images/collection/noItemfoundImage.svg';
import cnddsLight from '../../../assets/images/cnddsLight.svg'
import {
    useAccount,
} from 'wagmi'
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ResponsivePagination from 'react-responsive-pagination';
import defaultImage from '../../../assets/images/homeExplorer/placeholder.png'
import { handleImageError } from '../../../hooks/useHandleImg';
import { ExploreLoader } from '../../../features/collection/components/loader/ExploreLoader';
import { chainIconMap } from '../../../utils/getFilterData';
import DataService from '../../../lib/DataService';
const landPerRow = 6;
const GridViewItem = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()


    const { address } = useAccount()
    const { id } = useParams()
    const { getRequest, postRequest } = DataService()

    const [next, setNext] = useState(landPerRow)
    const [page, setPage] = useState(1)
    const [videoError, setVideoError] = useState(false)

    useEffect(() => {
        GetFavourite()
    }, [address, page, id])

    const [getallfavorite, setGetAllFavorite] = useState("")
    const [totalnftcount, setTotalNftCount] = useState(0)
    const [count, setCount] = useState('')
    function handlePageChange(page) {
        setPage(page);
    }
    const [exploreLoading, setExploreLoading] = useState(false)

    const GetFavourite = async () => {
        setExploreLoading(true)
        const fetch = await getRequest(
            `user/getUserFavNft?userAddress=${id ? id : address}&page=${page}&limit=15`
        )
        if (fetch.status == true) {
            setGetAllFavorite(fetch.data)
            setExploreLoading(false)
            setTotalNftCount(Math.ceil(fetch.count / 15))
            setCount(fetch.count)
        }
        else {
            setExploreLoading(false)
            setGetAllFavorite()
            setTotalNftCount()
            setCount('')
        }
    }

    const handleVideoError = () => {
        setVideoError(true);
    };

    const ItemCard = getallfavorite && getallfavorite.map((item) => {
        return (
            <>
                <div className='grid-col' style={{ width: "100%", height: "100%", objectFit: "cover" }} onClick={() => navigate(`/assets/${item.chain}/${item.contractAddress}/${item.nftId}`)} onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        navigate(`/assets/${item.chain}/${item.contractAddress}/${item.nftId}`)
                    }
                }}
                    role="button" tabIndex={0}>
                    <div className={`gridItemCard ${item.itemadded ? 'active' : ''}`}>
                        <div className='gItemImg' >
                            {typeof item?.asset == "string" && item?.asset !== undefined && !item?.asset?.startsWith(process.env.S3_URL) && !item?.asset?.startsWith('ipfs') && item?.asset !== null ?

                                item?.imageFormat?.startsWith('video') ?
                                    videoError ? <img src={defaultImage} alt="Itemimg1" className='img-fluid w-100 itemImage' />
                                        :

                                        <video autoPlay muted playsInline className='img-fluid w-100 itemImage' style={{
                                            width: '100px',
                                            height: '150px',
                                            objectFit: 'cover'
                                        }}>
                                            <source src={item?.asset ? item.asset : defaultImage} type="video/mp4" onError={handleVideoError} />
                                        </video>
                                    :
                                    item?.asset !== 'undefined' ?
                                        <img src={item?.asset ? item?.asset : defaultImage} alt="Itemimg1" className='img-fluid w-100 itemImage' onError={handleImageError} />
                                        :
                                        <img src={defaultImage} alt="Itemimg1" className='img-fluid w-100 itemImage' />
                                :
                                <img src={defaultImage} alt="Itemimg1" className='img-fluid w-100 itemImage' />
                            }
                            {item.contractStandard == "ERC1155" ?
                                <div className='idTag'>
                                    #{item.supply}
                                </div>
                                :
                                ""
                            }
                            <div className='hoverImgCnt'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className="d-flex align-items-center">
                                        <img src={chainIconMap[item.chain]} alt="ETHIcon" className='cointypeImg' />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='botcntCard'>
                            <div className='bottomSmImg'>
                                {!item?.asset?.startsWith(process.env.S3_URL) && !item?.asset?.startsWith('ipfs') && item?.asset !== null ?
                                    <img src={item?.asset ? item?.asset : defaultImage} height={73} alt="ItemimgSm1" /> : <img src={defaultImage} height={73} alt="ItemimgSm1" onError={handleImageError} />}
                            </div>
                            <div className='botcntTxt'>
                                <div className='itemLabel'>{item.collectionName}
                                </div>
                                <div className='itemName'>{item.nftName !== "" ? item.nftName : item.nftId ? `#${item.nftId}` : '---'}</div>
                                <div className='d-flex justify-content-between mb-1'>
                                    <div className="itemPricetxt">{t("Last Sale")}</div>
                                    <div className="itemPriceval">{item.lastSale ? parseFloat((item?.lastSale)?.toFixed(8)) : "--"} {item.lastSale ? item.currency ? item.currency : "" : ""}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    })

    return (
        <>

            <div className='pb-3'>
                <div className='row mb-4'>
                    <div className='col-lg-6'><span class="cmntxt">{count > 0 ? count : ""} {count > 0 ? t("Items") : ""}</span></div>
                </div>

                {exploreLoading == true ? (
                    <ExploreLoader />

                ) :
                    getallfavorite && getallfavorite.length > 0 ?
                        <div className="gridrow g-col-6">
                            {ItemCard}
                        </div>
                        :
                        <div className='noItemFound'>
                            <div className='text-center'>
                                <img src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : noItemfoundImage} alt="noItemfoundImage" className='img-fluid' />
                            </div>
                            <div className='nofounttxt mb-4'>
                                {t('Nothing to display.')}
                            </div>
                        </div>

                }

                <div className='row justify-content-center'>
                    <div className='col-lg-4 col-md-6 col-sm-8'>
                        <div class="bri_pagenation2">
                            {getallfavorite && getallfavorite.length > 0 ?
                                <div class=''>
                                    <nav aria-label="Page ">
                                        <ResponsivePagination
                                            total={totalnftcount}
                                            current={page}
                                            onPageChange={page => handlePageChange(page)}
                                            previousLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.125 5.75L5.71875 1.125C5.84375 0.96875 6.09375 0.96875 6.25 1.125L6.84375 1.75C7 1.875 7 2.125 6.84375 2.28125L3.15625 6L6.84375 9.75C7 9.90625 7 10.125 6.84375 10.2812L6.25 10.9062C6.09375 11.0625 5.84375 11.0625 5.71875 10.9062L1.125 6.28125C0.96875 6.125 0.96875 5.90625 1.125 5.75Z" fill="#F7F7F7"></path></svg>}
                                            nextLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.84375 6.28125L2.28125 10.9062C2.125 11.0625 1.875 11.0625 1.75 10.9062L1.125 10.2812C0.96875 10.125 0.96875 9.90625 1.125 9.75L4.8125 6L1.125 2.28125C0.96875 2.125 0.96875 1.875 1.125 1.75L1.75 1.125C1.875 0.96875 2.125 0.96875 2.28125 1.125L6.84375 5.75C7 5.90625 7 6.125 6.84375 6.28125Z" fill="#F7F7F7"></path></svg>}
                                            previousClassName="page-link-1"
                                            nextClassName="page-link-1"
                                        />
                                    </nav>
                                </div>
                                :
                                ""
                            }
                        </div>
                    </div>
                </div>

                <useToast toastImgIcon={checkTickIcon} toastmassage={t("Added to Cart")} />
                <useToast toastImgIcon={removeIcon} toastmassage={t("Removed from Cart")} />


            </div>
        </>
    )
}

export default GridViewItem;