import { create } from 'zustand'

const useOfferPlacedSuccessfullyModalStore = create((set) => ({
	isOpen: false,
	asset: null,
	actions: {
		toggleOfferPlacedSuccessfullyModal: () =>
			set((state) => ({
				...state,
				isOpen: !state.isOpen
			})),
		setAsset: (asset) =>
			set((state) => ({
				...state,
				asset
			}))
	}
}))

export const useOfferPlacedSuccessfullyModalState = () =>
	useOfferPlacedSuccessfullyModalStore(({ isOpen, asset }) => ({
		isOpen,
		asset
	}))

export const useOfferPlacedSuccessfullyModalActions = () =>
	useOfferPlacedSuccessfullyModalStore((state) => state.actions)
