import { useState } from 'react'

import NftsInGridView from './NftsInGridView'
import NftsInListView from './NftsInListView.'

export default function CollectionNfts({
	view,
	showFilters,
	collectionDetails,
	filters,
	handleFiltersChange
}) {
	let content = (
		<NftsInGridView
			view={view}
			showFilters={showFilters}
			collectionDetails={collectionDetails}
			filters={filters}
			handleFiltersChange={handleFiltersChange}
		/>
	)

	if (view === 'list') {
		content = (
			<NftsInListView
				view={view}
				showFilters={showFilters}
				collectionDetails={collectionDetails}
				filters={filters}
				handleFiltersChange={handleFiltersChange}
			/>
		)
	}

	return content
}
