import { create } from 'zustand'

const usePlaceBidModalStore = create((set) => ({
	isOpen: false,
	asset: null,
	actions: {
		togglePlaceBidModal: () =>
			set((state) => ({
				...state,
				isOpen: !state.isOpen
			})),
		setAsset: (asset) =>
			set((state) => ({
				...state,
				asset
			}))
	}
}))

export const usePlaceBidModalState = () =>
	usePlaceBidModalStore(({ isOpen, asset }) => ({
		isOpen,
		asset
	}))

export const usePlaceBidModalActions = () =>
	usePlaceBidModalStore((state) => state.actions)
