import { useEffect, useState } from 'react'
import { Modal } from 'reactstrap'
import { useAccount } from 'wagmi'
import { watchAccount } from '@wagmi/core'
import { useTranslation } from 'react-i18next'
import { isAndroid, isIOS, isMobile } from 'react-device-detect'

import {
	useConnectWalletModalActions,
	useConnectWalletModalState
} from './store'
import useAuth, {
	WalletConnectorNotFoundError,
	WalletSwitchChainError
} from '../../../hooks/useAuth'
import { useToast } from '../../../utils/toast/customSuccess'
import { timeout } from '../../../utils/timeout'

import connectWalletIcon from '../../../assets/images/connectWalletIcon.png'
import metamaskIcon from '../../../assets/images/metamaskIcon.png'
import okxIcon from '../../../assets/images/okxIcon.png'
import coinbaseIcon from '../../../assets/images/coinbaseIcon.png'
import walletLoaderCnt from '../../../assets/images/walletLoader.png'
import walletLightLoaderCnt from '../../../assets/images/wallet-light-loader.png'
import walletLoader from '../../../assets/images/loaderCircle.png'
import walletTick from '../../../assets/images/walletTick.png'
import DataService from '../../../lib/DataService'
export default function ConnectWalletModal() {
	const { t } = useTranslation()
	const { login, logout } = useAuth()
	const { postRequest } = DataService()

	var Metamaskurl = 'https://metamask.app.link/dapp/'
	var okxurl = 'okx://wallet/dapp/details?dappUrl='
	var coinbaseurl = 'https://go.cb-w.com/dapp?cb_url='
	const { isOpen, isIdle, isLoading, isSuccess, callback } =
		useConnectWalletModalState()
	const { toggleConnectWalletModal, setConnectWalletModalState } =
		useConnectWalletModalActions()
	const toast = useToast()
	const { isConnected, account } = useAccount()
	const { chainId } = useConnectWalletModalState()
	const [address, setAddress] = useState()

	const isMetaMaskInstalled = !isMobile ? window?.ethereum?.isMetaMask : true
	const isOkxsInstalled = !isMobile ? window.okxwallet : true

	useEffect(() => {
		if (isConnected) return

		setConnectWalletModalState({ isIdle: true })
	}, [isConnected, setConnectWalletModalState])

	useEffect(() => {
		const unwatch = watchAccount(async (account) => {
			setAddress(account.address)
		})

		return () => {
			unwatch()
		}
	}, [])

	const connect = (connectorId) => {
		setConnectWalletModalState({ isIdle: false, isLoading: true })
		login({ connectorId, chainId })
			.then(async ({ account }) => {
				localStorage.setItem('isLoggedIn', 'true')
				localStorage.setItem('walletType', connectorId)

				dispatchEvent(new Event('storage'))
				setConnectWalletModalState({ isLoading: false })

				await timeout(async () => {
					setConnectWalletModalState({
						isSuccess: true
					})
					toggleConnectWalletModal()
					const update = await postRequest(
						'user/importCollectionsUser',
						{
							address: account
						}
					)
					if (update?.status == true) {
						const response = await postRequest('user/importNfts', {
							address: account
						})
						if (response?.status == true) {
							await postRequest('user/deleteNfts', {
								address: account
							})
						}
					}

				})
				await timeout(() => {
					callback(account)
				})
				setConnectWalletModalState({
					callback: () => { }
				})
			})
			.catch(async (error) => {
				if (error.message.includes('Connector already connected')) {
					try {
						const res = await postRequest('user/connect-wallet', {
							address: address,
							type: connectorId
						})
						if (res.status) {
							localStorage.setItem(
								'access-token',
								res.data.authToken
							)
							sessionStorage.setItem('WorAddress', address)
							localStorage.setItem('isLoggedIn', 'true')
							dispatchEvent(new Event('storage'))
							setConnectWalletModalState({ isLoading: false })
							await timeout(() => {
								setConnectWalletModalState({
									isSuccess: true
								})
								toggleConnectWalletModal()
							})
							await timeout(() => {
								callback(address)
							})
						}
						return
					} catch (error) {
						logout()
						throw new Error(error)
					}
				}
				logout()
				setConnectWalletModalState({ isIdle: true, isLoading: false })
				if (error instanceof WalletConnectorNotFoundError) {
				} else if (error instanceof WalletSwitchChainError) {
					toast.error(error.message)
				} else if (
					error.message.includes('User rejected the request')
				) {
					toast.error('User rejected the request')
				} else if (error.message.includes('Try connecting')) {
					toast.error(error.message)
				}
			})
	}

	const handleConnect = (connectorId) => async () => {
		if (isMobile) {
			if (!window.ethereum) {
				let dAppUrl = process.env.REACT_APP_PUBLIC_URL
				if (connectorId === 'metaMask') {
					// if (isIOS) {
					// 	dAppUrl = process.env.REACT_DAPP_URL
					// 	window.open(dAppUrl, '_self')
					// } else if (isAndroid) {
						
					// }
					const metamaskAppDeepLink = Metamaskurl + dAppUrl.replace("https://", "")
					window.open(metamaskAppDeepLink)
				} else if (connectorId === 'okx') {
					if (isIOS) {
						const encodedDappUrl = encodeURIComponent(dAppUrl);
						const deepLink = "okx://wallet/dapp/url?dappUrl=" + encodedDappUrl;
						const encodedUrl = "https://www.okx.com/download?deeplink=" + encodeURIComponent(deepLink);
						alert(encodedUrl)
						window.open(encodedUrl)
					} else if (isAndroid) {
						const okxAppDeepLink =
							okxurl + dAppUrl
						window.open(okxAppDeepLink, '_self')
					}
				} else if (connectorId === 'coinbaseWallet') {
					dAppUrl = process.env.REACT_APP_URL
					const coinbaseAppDeepLink =
						coinbaseurl + dAppUrl
					window.open(coinbaseAppDeepLink, '_self')
				}
			} else {
				connect(connectorId)

			}
		} else {
			connect(connectorId)
		}
	}

	return (
		<Modal
			isOpen={isOpen}
			toggle={toggleConnectWalletModal}
			centered='true'
			className='curMdl lgMdl'
			backdropClassName='selCurBp'
			keyboard={false}
		>
			<div className='selCrHd'>
				<button className='btn'>
					<svg
						width='12'
						height='12'
						viewBox='0 0 12 12'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z'
							fill='#595F6A'
						/>
					</svg>
				</button>
				<img src={connectWalletIcon} />
				<button className='btn' onClick={toggleConnectWalletModal}>
					<svg
						width='12'
						height='12'
						viewBox='0 0 12 12'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z'
							fill='#595F6A'
						/>
					</svg>
				</button>
			</div>
			<h3 className='walletHeading'>{t('Connect Wallet')}</h3>
			{isIdle ? (
				<>
					<p className='walletSubHeading'>
						{t(
							'Choose how you want to connect. There are several wallet providers.'
						)}
					</p>
					<div className='d-flex flex-wrap mt-3 justify-content-center'>
						{isMetaMaskInstalled ? (
							<div
								className='walletIconCnt'
								onClick={handleConnect('metaMask')}
								onKeyDown={(e) => {
									if (e.key === 'Enter' || e.key === ' ') {
										handleConnect('metaMask')
									}
								}}
								role="button"
								tabIndex={0}
							>
								<img src={metamaskIcon} />
								<h3 className='heading'>Meta Mask</h3>
							</div>
						) : (
							<a
								href='https://metamask.io/download/'
								target='_blank'
								className='walletIconCnt'
							>
								<img src={metamaskIcon} />
								<h3 className='heading'>Meta Mask</h3>
							</a>
						)}
						{isOkxsInstalled ? (
							<div
								className='walletIconCnt'
								onClick={handleConnect('okx')}
								onKeyDown={(e) => {
									if (e.key === 'Enter' || e.key === ' ') {
										handleConnect('okx')
									}
								}}
								role="button"
								tabIndex={0}
							>
								<img src={okxIcon} />
								<h3 className='heading'>OKX</h3>
							</div>
						) : (
							<a
								href='https://www.okx.com/download'
								target='_blank'
								className='walletIconCnt'
							>
								<img src={okxIcon} />
								<h3 className='heading'>OKX</h3>
							</a>
						)}
						<div
							className='walletIconCnt'
							onClick={handleConnect('coinbaseWallet')}
							onKeyDown={(e) => {
								if (e.key === 'Enter' || e.key === ' ') {
									handleConnect('coinbaseWallet')
								}
							}}
							role="button"
							tabIndex={0}
						>
							<img src={coinbaseIcon} />
							<h3 className='heading'>Coinbase</h3>
						</div>
					</div>
				</>
			) : isLoading ? (
				<div className='d-flex justify-content-center align-items-center loaderCont'>
					{localStorage.getItem('light_theme') == 'true' ?
						<img src={walletLightLoaderCnt} /> :
						<img src={walletLoaderCnt} />
					}
					<div className='loaderAnimCnt'>
						<img src={walletLoader} className='loaderAnimation' />
					</div>
				</div>
			) : isSuccess ? (
				<div className='d-flex justify-content-center align-items-center'>
					<img src={walletTick} />
				</div>
			) : null}
		</Modal>
	)
}
