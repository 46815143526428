import React, { useContext, useEffect, useState } from 'react';

import '../../../assets/scss/filtersection.scss'
import { Button, Collapse, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import yellowTick from '../../../assets/images/collection/yellow-tick_20px.svg';
import bnbIconSm from '../../../assets/images/bnbIconSm.png';
import ethIconSm from '../../../assets/images/ethIconSm.png';
import maticIconSm from '../../../assets/images/maticIconSm.png';
import { ThemeContext } from '../../context/context';
import {
    useAccount,

} from 'wagmi'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import DataService from '../../../lib/DataService';
const FilterActivitySection = (props) => {

    const { t } = useTranslation()

    const [isOpenCollapse1, setIsOpenCollapse1] = useState(false);
    const toggleCollapse1 = () => {
        setIsOpenCollapse1(!isOpenCollapse1);
    }
    const [isOpenCollapse2, setIsOpenCollapse2] = useState(false);
    const toggleCollapse2 = () => {
        setIsOpenCollapse2(!isOpenCollapse2);
    }
    const [isOpenCollapse3, setIsOpenCollapse3] = useState(false);
    const toggleCollapse3 = () => {
        setIsOpenCollapse3(!isOpenCollapse3);
    }

    const { postformdataRequest, getRequest, postRequest } = DataService()

    const { address, isConnected } = useAccount()
    const { id } = useParams()


    const Status = [
        {
            id: 1,
            name: "All",
            value: "all",
            sortStatusField: "",
            sortStatus: "",
            sortField: "",
            sortOrder: "",
        },
        {
            id: 2,
            name: "Sales",
            value: "sale",
            sortStatusField: "",
            sortStatus: "",
            sortField: "",
            sortOrder: "",
        },

        {
            id: 3,
            name: "Listings",
            value: "list",
            sortField: "",
            sortOrder: "",
            sortStatusField: "",
            sortStatus: ""
        },
        {
            id: 4,
            name: "Offers",
            value: "make-offer",
            sortStatusField: "",
            sortStatus: "",
            sortField: "",
            sortOrder: "",
        },
        {
            id: 5,
            name: "Collection Offers",
            value: "make-collection-offer",
            sortStatusField: "",
            sortStatus: "",
            sortField: "",
            sortOrder: "",
        },
        {
            id: 6,
            name: "Mint",
            value: "mint",
            sortStatusField: "",
            sortStatus: "",
            sortField: "",
            sortOrder: "",
        }
    ]

    const Chains = [
        {
            id: 1,
            name: "BNB",
            value: "binance",
        },
        {
            id: 2,
            name: "ETH",
            value: "ethereum",
        },
        {
            id: 3,
            name: "MATIC",
            value: "polygon",
        }
    ]


    const { activitystatus, setActivityStatus, checkactivityChain, setactivityCheckChain, checkactivitycollection, setCheckactivityCollection } = useContext(ThemeContext)

    const FilterActivityStatus = (data) => {
        setActivityStatus(data.value)
        if (data.value == "all") {
            setActivityStatus(["all"])
        }
        else {
            let a = activitystatus.includes(data.value)
            if (a == true) {
                const index = activitystatus.indexOf(data.value);
                if (index > -1) {
                    activitystatus.splice(index, 1);
                    const nerArr = [...activitystatus]
                    setActivityStatus(nerArr);
                    if (nerArr.length == 0) {
                        setActivityStatus(["all"]);
                    }
                }

            } else if (a == false) {
                const index = activitystatus.indexOf("all");
                if (index > -1) {
                    activitystatus.splice(index, 1);
                }
                setActivityStatus(
                    [...activitystatus, data.value]
                );

            }
        }
    }

    const handleCheckboxChange = (item) => {
        let a = checkactivityChain.includes(item.value)

        if (a == true) {

            const index = checkactivityChain.indexOf(item.value);
            if (index > -1) {
                checkactivityChain.splice(index, 1);
                const nerArr = [...checkactivityChain]
                setactivityCheckChain(nerArr);
            }

        } else if (a == false) {
            setactivityCheckChain(
                [...checkactivityChain, item.value]
            );
        }
    }


    useEffect(() => {
        GetFilterCollection()
    }, [address, id])

    const [collection, setCollection] = useState()

    const GetFilterCollection = async () => {
        const getcollection = await postRequest("user/getcollectionandNFTCount", { "address": id ? id : address })
        if (getcollection.status == true) {
            setCollection(getcollection.data)
        }
    }


    const handleCheckboxCollection = (item) => {
        let a = checkactivitycollection.includes(item.collectionName)

        if (a == true) {

            const index = checkactivitycollection.indexOf(item.collectionName);
            if (index > -1) {
                checkactivitycollection.splice(index, 1);
                const nerArr = [...checkactivitycollection]
                setCheckactivityCollection(nerArr);
            }

        } else if (a == false) {
            setCheckactivityCollection(
                [...checkactivitycollection, item.collectionName]
            );
        }
    }

    const handleOnKeyDown = async (e) => {
        if (e.key == 'Enter' || e.key == "") {
            const searchcollection = await postRequest("user/getcollectionandNFTCount", { "address": id ? id : address, "filter": e.target.value })
            if (searchcollection.status == true) {
                setCollection(searchcollection.data)
            }
            else {
                setCollection("")
            }
        }
    }

    return (
        <>
            <div className='filtercard'>
                <div>
                    <Button className='filterTit' onClick={toggleCollapse1}>
                        <span>{t("Event Type")} </span>
                        <i className={`fas ${isOpenCollapse1 ? 'fa-angle-up' : 'fa-angle-down'}`} ></i>
                    </Button>
                    <Collapse isOpen={isOpenCollapse1}>
                        <div className='collapseCnt'>
                            {Status && Status.map((data, index) => (
                                <button className={activitystatus.length > 0 ? activitystatus.includes(data.value) ? 'filterStatusbtn active' : 'filterStatusbtn' : 'filterStatusbtn'} onClick={() => FilterActivityStatus(data)}>{t(data.name)}</button>
                            ))}
                        </div>
                    </Collapse>
                </div>

                <div>
                    <Button className='filterTit' onClick={toggleCollapse2}>
                        <span>{t("Chains")} </span>
                        <i className={`fas ${isOpenCollapse2 ? 'fa-angle-up' : 'fa-angle-down'}`} ></i>
                    </Button>
                    <Collapse isOpen={isOpenCollapse2}>
                        <div className='collapseCnt'>
                            <div className='filterInnercard'>

                                {Chains && Chains.map((data, index) => (
                                    <div class="d-flex align-items-center mb-2" key={data.id} >
                                        <label class="cuschk mr-2 mb-0" For={`checkbx1${index + 1}`}>
                                            <input type="checkbox" id={`checkbx1${index + 1}`} name="chains" checked={checkactivityChain.length > 0 ? checkactivityChain.includes(data.value) : false} onChange={() => handleCheckboxChange(data)} />
                                            <span class="checkmark"></span>
                                        </label>
                                        <img src={data.value == "binance" ? bnbIconSm : data.value == "ethereum" ? ethIconSm : data.value == "polygon" ? maticIconSm : ""} className="mr-1" width="20px" />
                                        <h3 className='mb-0 filterLabel' style={{ textTransform: "capitalize" }}>{data.value}</h3>
                                        <h3 className='mb-0 filterLabel dark-text-secondary ml-auto'>{data.name}</h3>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Collapse>
                </div>

                <div>
                    <Button className='filterTit' onClick={toggleCollapse3}>
                        <span>{t("Collections")}</span>
                        <i className={`fas ${isOpenCollapse3 ? 'fa-angle-up' : 'fa-angle-down'}`} ></i>
                    </Button>
                    <Collapse isOpen={isOpenCollapse3}>
                        <div className='collapseCnt'>
                            <div className="form-group formInputs">
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText className='px-2'>
                                            <svg
                                                width="17"
                                                height="17"
                                                viewBox="0 0 17 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M15.875 14.6562C16.0312 14.8125 16.0312 15.0625 15.875 15.1875L15.1562 15.9062C15.0312 16.0625 14.7812 16.0625 14.625 15.9062L10.8438 12.125C10.7812 12.0312 10.75 11.9375 10.75 11.8438V11.4375C9.59375 12.4062 8.125 13 6.5 13C2.90625 13 0 10.0938 0 6.5C0 2.9375 2.90625 0 6.5 0C10.0625 0 13 2.9375 13 6.5C13 8.125 12.375 9.625 11.4062 10.75H11.8125C11.9062 10.75 12 10.8125 12.0938 10.875L15.875 14.6562ZM6.5 11.5C9.25 11.5 11.5 9.28125 11.5 6.5C11.5 3.75 9.25 1.5 6.5 1.5C3.71875 1.5 1.5 3.75 1.5 6.5C1.5 9.28125 3.71875 11.5 6.5 11.5Z"
                                                    fill="white"
                                                />
                                            </svg>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder={t("Search collection")} name="searchInput" onKeyDown={handleOnKeyDown} />
                                </InputGroup>
                            </div>

                            <div className='filterInnercard'>
                                <div class="scroller htcheckbox">
                                    {collection && collection.length > 0 ?
                                        collection && collection.map((data, index) =>
                                        (
                                            <div class="d-flex align-items-center mb-2" key={data.id}>
                                                <label class="cuschk mr-2 mb-0" For={`checkbox${index + 1}`}>
                                                    <input type="checkbox" id={`checkbox${index + 1}`} name="collectionchoose" checked={checkactivitycollection.length > 0 ? checkactivitycollection.includes(data.collectionName) : false} onChange={() => handleCheckboxCollection(data)} />
                                                    <span class="checkmark"></span>
                                                </label>
                                                <h3 className='mb-0 filterLabel'>{data.collectionName}</h3>
                                                <img src={data.verification == "Verified" ? yellowTick : ""} className="ml-1" />
                                                <h3 className='mb-0 filterLabel dark-text-secondary ml-auto'>{data.count}</h3>
                                            </div>
                                        ))
                                        :
                                        "No Data Found"
                                    }
                                </div>
                            </div>
                        </div>
                    </Collapse>
                </div>

            </div>
        </>
    );
}

export default FilterActivitySection;