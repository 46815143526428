import { useContext, useEffect, useRef, useState } from 'react'
import { Input } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from '../../views/context/context'
import { useToast } from '../../utils/toast/customSuccess'
import logo from './assets/logo.svg'
import logoLight from './assets/logo-light.svg'
import instagram from './assets/instagram.svg'
import twitter from './assets/twitter.svg'
import facebook from './assets/facebook.svg'
import telegram from './assets/telegram-plane.svg'
import discord from './assets/discord.svg'
import loader from '../../assets/images/Loader.png'

import style from './Footer.module.scss'
import DataService from '../../lib/DataService'

const RightArrow = () => {
	return (
		<svg
			width='6'
			height='10'
			viewBox='0 0 6 10'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M5.1875 5.28125L1.53125 8.90625C1.375 9.0625 1.125 9.0625 1 8.90625L0.78125 8.6875C0.625 8.53125 0.625 8.3125 0.78125 8.15625L3.96875 5L0.78125 1.875C0.625 1.71875 0.625 1.5 0.78125 1.34375L1 1.125C1.125 0.96875 1.375 0.96875 1.53125 1.125L5.21875 4.75C5.34375 4.90625 5.34375 5.125 5.1875 5.28125Z'
				fill='white'
			/>
		</svg>
	)
}

const Footer = () => {
	const { t } = useTranslation()
	const [loading, setLoading] = useState(false)
	const inputRef = useRef(null)

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
		socialMedia()
	}, [])

	const {
		setExploreTab,
		setActiveTab,
		setFavouriteTab,
		setActiveTab5,
		themeClr,
		socialmedia,
		setSocialMedia,
		setActiveTab4,
		setWatchlistTab,
		resetState
	} = useContext(ThemeContext)

	const navigateTab = data => {
		setExploreTab(data)
	}

	const navigateTab1 = data => {
		setWatchlistTab(data)
	}

	const handleFavourite = data => {
		setFavouriteTab(data)
	}

	const toast = useToast()
	const { getRequest, postRequest } = DataService()

	const socialMedia = async () => {
		setLoading(true)
		const social = await getRequest('home/social-media')
		if (social.status == true) {
			setLoading(false)
			setSocialMedia(social.data)
		} else {
			setLoading(false)
			setSocialMedia('')
		}
	}

	const [email, setEmail] = useState('')
	let regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

	const handleEmail = async e => {
		if (e.target.value == '' || e.target.value == undefined) {
			setEmail(e.target.value)
			document.getElementById('email1').innerHTML = t('Please enter email address')
		} else if (!regex.test(e.target.value)) {
			setEmail(e.target.value)
			document.getElementById('email1').innerHTML = t('Invalid email address')
		} else {
			setEmail(e.target.value)
			document.getElementById('email1').innerHTML = ''
		}
	}

	const Emailupdate = async () => {
		setLoading(true)
		if (email == '' || email == undefined) {
			document.getElementById('email1').innerHTML = t('Please enter email address')
			setLoading(false)
			inputRef.current.focus()
		} else if (!regex.test(email)) {
			document.getElementById('email1').innerHTML = t('Invalid email address')
			setLoading(false)
		} else {
			document.getElementById('email1').innerHTML = ''
			const subscribeemail = await postRequest('home/subscribe', {
				email: email
			})
			if (subscribeemail.status == true) {
				setLoading(false)
				toast.success(subscribeemail.message)
				setEmail('')
			} else {
				setLoading(false)
				toast.error(subscribeemail.message)
				setEmail('')
			}
		}
	}

	const [isLoggedIn, setIsLoggedIn] = useState(
		localStorage.getItem('isLoggedIn')
	)

	useEffect(() => {
		const handleStorage = e => {
			setIsLoggedIn(localStorage.getItem('isLoggedIn'))
		}
		window.addEventListener('storage', handleStorage)

		return () => {
			window.removeEventListener('storage', handleStorage)
		}
	}, [])

	const Connect = () => {
		resetState()
		toast.error('Please connect wallet')
	}

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			resetState();
		}
	};

	return (
		<>
			<div className={`text-center ${loading ? 'spinner' : 'd-none'}`}>
				<img src={loader} alt='' className='spinAnimate' />
			</div>
			<footer className={style.ftr}>
				<div className={style.ftrTp}>
					<div className={style.glow1}></div>
					<div className={style.glow2}></div>
					<div className={style.glow3}></div>
					<div className='container-fluid'>
						<div className={style.ftLogo}>
							<Link to='/'>
								<img
									className={`${themeClr ? 'd-none' : ''}`}
									src={logo}
									alt='logo'
									onClick={() => resetState()}
									width={115}
									height={54}
									onKeyDown={handleKeyDown} role="button" tabIndex={0}
								/>
								<img
									className={`${themeClr ? style.lightLogo : 'd-none'}`}
									src={logoLight}
									alt='logo'
									onClick={() => resetState()}
									width={115}
									height={54}
									onKeyDown={handleKeyDown} role="button" tabIndex={0}
								/>
							</Link>
						</div>

						<div className={`row ${style.ftrR || ''}`}>
							<div
								className={`col-md-6 col-lg-4 order-1 order-md-1  ${style.ftrC}`}
							>
								<p className={style.ftrMuTl}>{t('Stay in the loop')}</p>
								<div className={style.ftrSbs}>
									<Input
										type='text'
										placeholder={t('Your Email')}
										name='email'
										onChange={handleEmail}
										value={email}
										innerRef={inputRef}
									/>
									<button
										className={`btn ${style['cta-button']}`}
										onClick={Emailupdate}
									>
										<RightArrow />
									</button>
								</div>
								<span
									id='email1'
									className='mt-2 d-block'
									style={{ color: 'red' }}
								/>
							</div>
							<div
								className={`col-md-6 col-lg-4 col-xl-3 order-2 order-md-2 ${style.ftrC}`}
							>
								<p className={style.ftrMuTl}>{t('Marketplace')}</p>
								<ul className={style.ftMnu}>
									<li>
										<Link
											to='/explorer'
											onClick={() => {
												resetState()
												navigateTab('all')
												setActiveTab('1')
											}}
										>
											{t("All NFT's")}
										</Link>
									</li>
									<li>
										<Link
											to='/explorer'
											onClick={() => {
												resetState()
												navigateTab('Art')
												setActiveTab('2')
											}}
										>
											{t('Art')}
										</Link>
									</li>
									<li>
										<Link
											to='/explorer'
											onClick={() => {
												resetState()
												navigateTab('Music')
												setActiveTab('4')
											}}
										>
											{t('Music')}
										</Link>
									</li>
									<li>
										<Link
											to='/explorer'
											onClick={() => {
												resetState()
												navigateTab('Photography')
												setActiveTab('5')
											}}
										>
											{t('Photography')}
										</Link>
									</li>
									<li>
										<Link
											to='/explorer'
											onClick={() => {
												resetState()
												navigateTab('Sports')
												setActiveTab('6')
											}}
										>
											{t('Sports')}
										</Link>
									</li>
								</ul>
							</div>
							<div
								className={`col-md-6 col-lg-4 col-xl-3 order-3 order-md-3 ${style.ftrC}`}
							>
								<p className={style.ftrMuTl}>{t('My Account')}</p>
								<ul className={style.ftMnu}>
									<li>
										{isLoggedIn == 'true' ? (
											<Link to='/profile' onClick={() => resetState()}>{t('Profile')}</Link>
										) : (
											<Link to='/' onClick={Connect}>
												{t('Profile')}
											</Link>
										)}
									</li>
									<li>
										{isLoggedIn == 'true' ? (
											<Link
												to='/profile'
												onClick={() => {
													handleFavourite('favorites')
													setActiveTab5('5')
												}}
											>
												{t('Favorites')}
											</Link>
										) : (
											<Link to='/' onClick={Connect}>
												{t('Favorites')}
											</Link>
										)}
									</li>
									<li>
										<Link
											to='/homecollection'
											onClick={() => {
												resetState()
												navigateTab1('Watchlist')
												setActiveTab4('3')
											}}
										>
											{t('Watchlist')}
										</Link>
									</li>
									<li>
										{isLoggedIn == 'true' ? (
											<Link to='/mycollection' onClick={() => resetState()}>{t('My Collections')}</Link>
										) : (
											<Link to='/' onClick={Connect}>
												{t('My Collections')}
											</Link>
										)}
									</li>
									<li>
										{isLoggedIn == 'true' ? (
											<Link to='/settingsprofile' onClick={() => resetState()}>{t('Settings')}</Link>
										) : (
											<Link to='/' onClick={Connect}>
												{t('Settings')}
											</Link>
										)}
									</li>
								</ul>
							</div>
							<div
								className={`col-md-6 col-lg-4 col-xl-2  order-4 order-md-4 ${style.ftrC}`}
							>
								<p className={style.ftrMuTl}>{t('Resources')}</p>
								<ul className={style.ftMnu}>

									<li>
										<Link to='/helpcenter'>{t('Help Center')}</Link>
									</li>
									<li>
										{isLoggedIn == 'true' ? (
											<Link to='/supportTicket' onClick={() => resetState()}>{t('Support Ticket')}</Link>
										) : (
											<Link to='/' onClick={Connect}>
												{t('Support Ticket')}
											</Link>
										)}
									</li>
								</ul>
							</div>

							<div
								className={`col-md-6 col-lg-4 order-6 order-md-5 ${style.ftrC}`}
							>
								<p className={style.ftrMuTl}>{t('Join the community')}</p>
								<ul className={style.ftrScl}>
									<li>
										<a
											href={socialmedia?.twitter}
											target='_blank'
										>
											<img
												src={twitter}
												alt='twitter'
												width={15}
												height={15}
											/>

										</a>
									</li>
									<li>
										<a
											href={socialmedia?.instagram}
											target='_blank'
										>
											<img
												src={instagram}
												alt='instagram'
												width={15}
												height={15}
											/>
										</a>
									</li>
									<li>
										<a
											href={socialmedia?.facebook}
											target='_blank'
										>
											<img
												src={facebook}
												alt='facebook'
												width={16}
												height={16}
											/>
										</a>
									</li>
									<li>
										<a
											href={socialmedia?.telegram}
											target='_blank'
										>
											<img
												src={telegram}
												alt='facebook'
												width={16}
												height={16}
											/>
										</a>
									</li>
									<li>
										<a
											href={socialmedia?.discord}
											target='_blank'
										>
											<img src={discord} alt='discord' width={20} height={15} />
										</a>
									</li>
								</ul>
							</div>
							<div
								className={`col-md-6 col-lg-4 col-xl-2 order-5 order-md-6 ${style.ftrC}`}
							>
								<p className={style.ftrMuTl}>{t('Stats')}</p>
								<ul className={style.ftMnu}>
									<li>
										<Link to='/homecollection' onClick={() => resetState()}>{t('Ranking')}</Link>
									</li>
									<li>
										<Link to='/activity' onClick={() => resetState()}>{t('Activity')}</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className={style.ftrBm}>
					<div className='container-fluid'>
						<div className='row align-items-center justify-content-between'>
							<div className='col-lg-auto'>
								<ul className={style.ftBmMu}>
									<li>
										<Link to='/privacy' onClick={() => resetState()}>{t('Privacy Policy')}</Link>
									</li>
									<li>
										<Link to='/terms' onClick={() => resetState()}>{t('Terms of Service')}</Link>
									</li>
									<li>
										<Link to='/Legalnotice' onClick={() => resetState()}>{t('Legal Notices')}</Link>
									</li>
								</ul>
							</div>
							<div className='col-lg-auto'>
								<p className={style.ftrCpr}>
									© {new Date().getFullYear()} {t("WARRIOR. All rights reserved")}.
								</p>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	)
}
export default Footer
