import React, { useEffect, useState } from 'react'

export const useContractProvider = () => {
	const walletType = localStorage.getItem('walletType')

	const [pro, setPro] = useState()

	useEffect(() => {
		
		providerSet()
	}, [walletType])

	const providerSet = () => {
		if (!window.ethereum) return

		let provider
		if (walletType === 'coinbaseWallet' && walletType != 'okx') {
			if (window.ethereum.providers != undefined) {
				provider = window.ethereum.providers.find(provider => {
					return provider.isCoinbaseWallet
				})
				setPro(provider)
			} else {
				provider = window.web3.currentProvider
				setPro(provider)
			}
		} else if (walletType === 'metaMask' && walletType != 'okx') {
			if (window.ethereum.providers != undefined) {
				provider = window.ethereum.providers.find(provider => {
					return provider.isMetaMask
				})
				setPro(provider)
			} else {
				provider = window.ethereum
				setPro(provider)
			}
		} else if (walletType == 'okx') {
			provider = window.okxwallet
			setPro(provider)
		}
	}

	return {
		pro
	}
}
