
/**
 * Format a payload of form data to the format required by Zendesk
 *
 * @param {String} subject  - Zendesk ticket subject
 * @param {String} email    - Email provided by user giving feedback
 * @param {String} feedback - Feedback provided by user
 * @param {String} pageURL  - URL of page user submitted feedback from
 *
 * @return {Object}         - Payload for Zendesk Requests API
 *
 */


export const formatPayload = (
  subject,
  email,
  defaultName,
  feedback,
  pageURL,

) => {
  const requester = email
    ? { name: defaultName, email, }
    : { name: defaultName, };

  const comment = pageURL
    ? {
      body: `${feedback}
  ${pageURL}`
    }
    : { body: feedback };

  return {
    request: {
      requester,
      subject,
      comment
    }
  };
};