import { useQuery, useMutation } from '@tanstack/react-query'
import DataService from '../../../lib/DataService'
const { getRequest, postRequest } = DataService()

function fetchAssetDetails(collectionName, nftId, chain, address) {
	
	return getRequest(`asset/details?collection=${collectionName}&id=${nftId}&chain=${chain}&address=${address}`)

}

export function useFetchListAssetDetails(
	collectionName,
	nftId,
	chain,
	address
) {
	return useQuery({
		queryKey: ['listsale', collectionName, nftId, chain, address],
		queryFn: () => fetchAssetDetails(collectionName, nftId, chain, address),
		enabled: !!(collectionName && nftId)
	})
}

function getFixedPriceSell(data) {
	return postRequest('asset/sell', { ...data })
}

export function useFixedPriceSell() {
	return useMutation({
		mutationFn: getFixedPriceSell
	})
}

function fetchOwnedCopies(collectionName, nftId, address) {
	
	return getRequest(`asset/owned-copies?collection=${collectionName}&nftId=${nftId}&user=${address}`)

}

export function useFetchOwnedCopies(collectionName, nftId, address) {
	return useQuery({
		queryKey: ['ownedcopies', collectionName, nftId, address],
		queryFn: () => fetchOwnedCopies(collectionName, nftId, address),
		enabled: !!(collectionName && nftId && address)
	})
}
