import { create } from 'zustand'

const useCheckoutModalStore = create((set) => ({
	isOpen: false,
	asset: null,
	actions: {
		toggleCheckoutModal: () =>
			set((state) => ({
				...state,
				isOpen: !state.isOpen
			})),
		closeCheckoutModal: () =>
			set((state) => ({
				...state,
				isOpen: false
			})),
		setAsset: (asset) =>
			set((state) => ({
				...state,
				asset
			}))
	}
}))

export const useCheckoutModalState = () =>
	useCheckoutModalStore(({ isOpen, asset }) => ({
		isOpen,
		asset
	}))

export const useCheckoutModalActions = () =>
	useCheckoutModalStore((state) => state.actions)
