import { useNavigate } from 'react-router-dom'

import notFound from '../../../assets/images/404.png'

export function AssetNotFound() {
	const navigate = useNavigate()
	return (
		<section className='errNotFoundCnt d-flex justify-content-center align-items-center'>
			<div className='container'>
				<div className='row justify-content-center align-items-center'>
					<img src={notFound} className='NFImg' />
					<div className='col-12 textCnt text-center d-flex flex-column align-items-center'>
						<h3 className='heading'>
							PAGE <span className='gradientTxt'>NOT FOUND</span>
						</h3>
						<p>SORRY, WE CAN’T FIND PAGE YOU’RE LOOKING FOR.</p>
					</div>
				</div>
			</div>
		</section>
	)
}
