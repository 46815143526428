import { useCallback, useState } from 'react'
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
	UncontrolledTooltip
} from 'reactstrap'
import { useAccount } from 'wagmi'
import { handleImageError } from '../../../hooks/useHandleImg'
import DefaultImage from '../../../assets/images/homeExplorer/placeholder.png'
import {
	EmailShareButton,
	TelegramShareButton,
	TwitterShareButton,
	FacebookShareButton
} from 'react-share'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { debounce } from 'lodash'

import { useConnectWalletModalActions } from '../../../components/modals/connect-wallet/store'
import { useToast } from '../../../utils/toast/customSuccess'
import { useReportModalActions } from '../../../components/modals/report-modal/store'
import { axios } from '../../../lib/axios'
import OwnedByModal from '../../../components/modals/owned-by/ownedby'
import { copyText } from '../../../utils/copyText'

import ellipsisV from '../../../assets/images/collection/ellipsis-v.svg'
import pennant from '../../../assets/images/collection/pennant.svg'
import shareIcon from '../../../assets/images/collection/shareIcon.svg'
import yellowTick from '../../../assets/images/collection/yellow-tick_20px.svg'
import heart from '../../../assets/images/heart.svg'
import likeIcon from '../../../assets/images/likeIcon.svg'
import profileImg from '../../../assets/images/default-profile.png'
import website from '../../../assets/images/globe.svg'
import DataService from '../../../lib/DataService'

export function AssetHeader({ asset }) {
	const { t } = useTranslation()
	const { address: account } = useAccount()
	const [isLoading, setIsLoading] = useState(false)
	const { toggleConnectWalletModal, setConnectWalletModalState } =
		useConnectWalletModalActions()
	const { toggleReportModal, setAsset: setReportAssetState } =
		useReportModalActions()
	const [ownedByModal, setOwnedByModal] = useState(false)
	const { postRequest } = DataService()

	const toast = useToast()
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const owner = asset?.owners?.length > 1 ? asset?.owners?.find((o) => o?.address == String(account?.toLowerCase())) : asset?.owners[0]
	var isMobile = window.matchMedia("only screen and (max-width: 767px)").matches;

	const toggleFavorites = (asset) => (account) => {
		setIsLoading(true)
		postRequest('asset/favorite-mutation', {
			nftId: asset.details.nftId,
			contractAddress: asset.details.contractAddress,
			status: !asset?.details?.favoritedBy?.includes(account.toLowerCase()),
			address: account
		})
			.then((data) => {
				if (data?.data?.isFavorite) {
					toast.heart(t('Added to Favorites.'))
				} else {
					toast.error(t('Removed from Favorites.'))
				}
				queryClient.invalidateQueries(['asset'])
			})
			.catch((error) => {

			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	const handleFavorites = () => {
		if (!account) {
			toggleConnectWalletModal()
			setConnectWalletModalState({
				callback: toggleFavorites(asset)
			})
		}
		else {
			toggleFavorites(asset)(account)
		}
	}

	const throttledHandleFavorites = useCallback(
		debounce(handleFavorites, 500),
		[asset]
	)

	const url = `${process.env.REACT_APP_PUBLIC_URL}/assets/${asset?.details?.chain}/${asset?.details?.contractAddress}/${asset?.details?.nftId}`

	const copyToLink = () => {
		copyText(url, () => {
			toast.success('Link Copied!')
		})
	}

	const handleReport = () => {
		setReportAssetState(asset)
		toggleReportModal()
	}
	const handleKeyDown = (event) => {
		if (event.key === 'Enter' || event.key === ' ') {
			throttledHandleFavorites();
		}
	};

	return (
		<div className='nftCard mb-3'>
			<div className='d-flex align-items-center w-100 mb-4 flex-wrap'>
				<h3 className='mb-0 mr-2'>{asset.details.name !== "" ? asset.details.name : `#${asset.details.nftId}`}</h3>
				<div className='d-flex align-items-center ml-auto'>
					<div
						className='iconBtn iconBtn-h40 d-flex justify-content-center align-items-center mr-2 hover'
						onClick={throttledHandleFavorites}
						onKeyDown={handleKeyDown}
						id='add-fav'
						style={{
							pointerEvents: `${isLoading ? 'none' : 'auto'}`,
							cursor: `${isLoading ? 'default' : 'pointer'
								}`
						}}
						tabIndex={0}
						role="button"
					>
						{asset?.details?.favoritedBy?.includes(account?.toLowerCase()) ? (
							<>
								<img src={heart} />

							</>
						) : (
							<>
								<img src={likeIcon} />
							</>
						)}

						{!isMobile && ( 
							<UncontrolledTooltip
								placement='top'
								target='add-fav'
								className='tooltipCnt red-tooltip'
								style={{ padding: '12px 16px', borderRadius: '12px', fontFamily: 'Poppins' }}
							>
								{asset?.details?.favoritedBy?.includes(account?.toLowerCase()) ? t('Remove from Favorites.') : t('Add to Favorites.')}
							</UncontrolledTooltip>
						)}


					</div>
					<UncontrolledDropdown className='mr-2'>
						<DropdownToggle className='iconBtn iconBtn-h40'>
							<img src={shareIcon} alt='shareIcon' />
						</DropdownToggle>
						<DropdownMenu right className='dropdownMenu'>
							<DropdownItem className='mb-0'>
								{/* <i class='fab fa-twitter-square mr-2'></i>{' '} */}
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M11.1527 8.92804L16.2525 3H15.044L10.6159 8.14724L7.07919 3H3L8.34821 10.7835L3 17H4.20855L8.88474 11.5643L12.6198 17H16.699L11.1524 8.92804H11.1527ZM9.49748 10.8521L8.95559 10.077L4.644 3.90978H6.50026L9.97976 8.88696L10.5216 9.66202L15.0446 16.1316H13.1883L9.49748 10.8524V10.8521Z" fill="white" />
								</svg>

								<TwitterShareButton
									title={'Check out this account on wornft'}
									url={`${process.env.REACT_APP_PUBLIC_URL}/assets/${asset?.details?.chain}/${asset?.details?.contractAddress}/${asset?.details?.nftId}`}
									via={['WorNft']}
								>
									{' '}
									{t("Twitter")}
								</TwitterShareButton>
							</DropdownItem>
							<DropdownItem className='mb-0'>
								{/* <i class='fab fa-telegram mr-2'></i> */}
								<svg
									width='16'
									height='16'
									viewBox='0 0 16 16'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M8 0.25C12.25 0.25 15.75 3.75 15.75 8C15.75 12.2812 12.25 15.75 8 15.75C3.71875 15.75 0.25 12.2812 0.25 8C0.25 3.75 3.71875 0.25 8 0.25ZM11.5625 5.53125C11.5938 5.4375 11.5938 5.34375 11.5625 5.21875C11.5625 5.15625 11.5 5.0625 11.4688 5.03125C11.375 4.9375 11.2188 4.9375 11.1562 4.9375C10.875 4.9375 10.4062 5.09375 8.25 6C7.5 6.3125 6 6.9375 3.75 7.9375C3.375 8.09375 3.1875 8.21875 3.15625 8.375C3.125 8.625 3.53125 8.71875 4 8.875C4.40625 9 4.9375 9.15625 5.21875 9.15625C5.46875 9.15625 5.75 9.0625 6.0625 8.84375C8.15625 7.40625 9.25 6.6875 9.3125 6.6875C9.375 6.6875 9.4375 6.65625 9.46875 6.6875C9.53125 6.75 9.53125 6.8125 9.5 6.84375C9.46875 7 7.5 8.8125 7.375 8.9375C6.9375 9.375 6.4375 9.65625 7.21875 10.1562C7.875 10.5938 8.25 10.875 8.9375 11.3125C9.375 11.5938 9.71875 11.9375 10.1562 11.9062C10.375 11.875 10.5938 11.6875 10.6875 11.0938C10.9688 9.75 11.4688 6.75 11.5625 5.53125Z'
										fill='white'
									/>
								</svg>
								<TelegramShareButton
									url={`${process.env.REACT_APP_PUBLIC_URL}/assets/${asset?.details?.chain}/${asset?.details?.contractAddress}/${asset?.details?.nftId}`}
									title={'Check out this account on wornft'}
									className='share-button'
									via={['WorNft']}
								>
									{t("Telegram")}
								</TelegramShareButton>
							</DropdownItem>
							<DropdownItem className='mb-0'>
								{/* <i class='fab fa-facebook mr-2'></i> */}
								<svg
									width='16'
									height='16'
									viewBox='0 0 16 16'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M15.75 8C15.75 11.875 12.9062 15.0938 9.1875 15.6562V10.25H11L11.3438 8H9.1875V6.5625C9.1875 5.9375 9.5 5.34375 10.4688 5.34375H11.4375V3.4375C11.4375 3.4375 10.5625 3.28125 9.6875 3.28125C7.9375 3.28125 6.78125 4.375 6.78125 6.3125V8H4.8125V10.25H6.78125V15.6562C3.0625 15.0938 0.25 11.875 0.25 8C0.25 3.71875 3.71875 0.25 8 0.25C12.2812 0.25 15.75 3.71875 15.75 8Z'
										fill='white'
									/>
								</svg>
								<FacebookShareButton
									title={'Check out this account on wornft'}
									url={`${process.env.REACT_APP_PUBLIC_URL}/assets/${asset?.details?.chain}/${asset?.details?.contractAddress}/${asset?.details?.nftId}`}
									via={['WorNft']}
									className='share-button'
								>
									{t("Facebook")}
								</FacebookShareButton>
							</DropdownItem>
							<DropdownItem className='mb-0'>
								{/* <i class='fas fa-envelope mr-2'></i> */}
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M16.5 4C17.3125 4 18 4.6875 18 5.5V14.5C18 15.3438 17.3125 16 16.5 16H3.5C2.65625 16 2 15.3438 2 14.5V5.5C2 4.6875 2.65625 4 3.5 4H16.5ZM3.5 5C3.21875 5 3 5.25 3 5.5V6.8125C3.65625 7.375 4.65625 8.1875 7.6875 10.5938C8.21875 11.0312 9.25 12.0312 10 12C10.7188 12.0312 11.75 11.0312 12.2812 10.5938C15.3125 8.1875 16.3125 7.375 17 6.8125V5.5C17 5.25 16.75 5 16.5 5H3.5ZM16.5 15C16.75 15 17 14.7812 17 14.5V8.09375C16.2812 8.6875 15.1562 9.59375 12.9062 11.375C12.25 11.9062 11.125 13.0312 10 13C8.84375 13.0312 7.71875 11.9062 7.0625 11.375C4.8125 9.59375 3.6875 8.6875 3 8.09375V14.5C3 14.7812 3.21875 15 3.5 15H16.5Z" fill="white" />
								</svg>

								<EmailShareButton
									title={'Check out this account on wornft'}
									url={`${process.env.REACT_APP_PUBLIC_URL}/assets/${asset?.details?.chain}/${asset?.details?.contractAddress}/${asset?.details?.nftId}`}
									via={['WorNft']}
									className='share-button'
								>
									{t("Email")}
								</EmailShareButton>
							</DropdownItem>
							<DropdownItem className='mb-0' onClick={copyToLink}>
								{/* <i class='fas fa-link mr-2'></i>{' '} */}
								<svg
									width='20'
									height='20'
									viewBox='0 0 20 20'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M11.4062 14.3438L8.90625 16.8125C7.34375 18.4062 4.75 18.4062 3.1875 16.8125C1.59375 15.25 1.59375 12.6562 3.1875 11.0938L5.65625 8.59375C5.6875 8.5625 5.71875 8.53125 5.75 8.5C6 8.28125 6.375 8.4375 6.40625 8.75C6.40625 8.90625 6.40625 9.0625 6.4375 9.21875C6.4375 9.3125 6.40625 9.4375 6.3125 9.53125C5.8125 10.0312 3.96875 11.875 3.96875 11.875C2.8125 13.0312 2.8125 14.875 3.96875 16.0312C5.125 17.1875 6.96875 17.1875 8.125 16.0312L10.5938 13.5625L10.625 13.5312C11.75 12.4062 11.75 10.5312 10.5938 9.40625C10.3438 9.15625 10.0625 8.9375 9.75 8.8125C9.59375 8.75 9.5 8.59375 9.5 8.4375C9.53125 8.28125 9.5625 8.125 9.59375 7.96875C9.65625 7.75 9.90625 7.65625 10.0938 7.71875C10.5625 7.9375 11 8.21875 11.4062 8.59375C12.9688 10.1875 12.9688 12.7812 11.4062 14.3438ZM8.5625 11.4375C7 9.84375 7 7.25 8.5625 5.6875L11.0625 3.21875C12.625 1.625 15.2188 1.625 16.7812 3.21875C18.375 4.78125 18.375 7.375 16.7812 8.9375L14.3125 11.4375C14.2812 11.4688 14.25 11.5 14.2188 11.5312C13.9688 11.75 13.5938 11.5938 13.5625 11.2812C13.5625 11.125 13.5625 10.9688 13.5312 10.8125C13.5312 10.7188 13.5625 10.5938 13.6562 10.5C14.1562 10 16 8.15625 16 8.15625C17.1562 7 17.1562 5.15625 16 4C14.8438 2.84375 13 2.84375 11.8438 4L9.375 6.46875L9.34375 6.5C8.21875 7.625 8.21875 9.5 9.375 10.625C9.625 10.875 9.90625 11.0938 10.2188 11.2188C10.375 11.2812 10.4688 11.4375 10.4688 11.5938C10.4375 11.75 10.4062 11.9062 10.375 12.0625C10.3125 12.2812 10.0625 12.375 9.875 12.3125C9.40625 12.0938 8.96875 11.8125 8.5625 11.4375Z'
										fill='white'
									/>
								</svg>
								{t('Copy Link')}
							</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>

					{account ?
						<UncontrolledDropdown>
							<DropdownToggle className='iconBtn iconBtn-h40'>
								<img src={ellipsisV} alt='ellipsisV' />
							</DropdownToggle>
							<DropdownMenu right className='dropdownMenu'>
								<DropdownItem onClick={handleReport}>
									<img
										src={pennant}
										alt='pennant'
										className='mr-2'
									/>{' '}
									{t('Report')}
								</DropdownItem>
								<DropdownItem >
									<img
										src={website}
										alt='website'
										className='mr-2'
									/>{' '}
									{t('Website')}
								</DropdownItem>
							</DropdownMenu>
							
						</UncontrolledDropdown>
						:
						""
					}
				</div>
			</div>
			<div className='row w-100'>
				<div className='col-lg-6 col-6'>
					<div className='d-flex align-items-center mb-lg-0 mb-0'>
						{!asset?.collection?.profile?.startsWith(process.env.S3_URL) && !asset?.collection?.profile?.startsWith('ipfs') && asset?.collection?.profile !== null ?
							<img
								src={asset?.collection?.profile !== "" ? asset?.collection?.profile : DefaultImage}
								height='44'
								width='44'
								className='mr-3 object-cover'
								style={{ borderRadius: '12px' }}
								onError={handleImageError}
							/> : <img
								src={DefaultImage}
								height='44'
								width='44'
								className='mr-3 object-cover'
								style={{ borderRadius: '12px' }}
							/>
						}
						<div className='d-flex flex-column'>
							<span className='cardLabel'>{t("Collection")}</span>
							<Link
								className='cardValue d-flex align-items-center hover text-decoration-none color-inherit'
								to={`/collection/${asset.collection.slug}`}
							>
								{asset.collection.name}{' '}
								{asset.collection.isVerified ? (
									<img src={yellowTick} className='ml-2' />
								) : null}
							</Link>
						</div>
					</div>
				</div>

				<div className='col-lg-6 col-6'>
					<div className='d-flex align-items-center'>
						{!owner?.profile?.startsWith(process.env.S3_URL) && !owner?.profile?.startsWith('ipfs') && owner?.profile !== null && owner?.profile !== "undefined" && owner?.profile !== "" && owner?.profile !== undefined ?
							<img
								src={
									owner && owner?.profile
										? owner?.profile
										: profileImg
								}
								height='44'
								width='44'
								className='mr-3 object-cover'
								style={{ borderRadius: '12px' }}
							/> :
							<img
								src={
									profileImg
								}
								height='44'
								width='44'
								className='mr-3 object-cover'
								style={{ borderRadius: '12px' }}
							/>}
						{
							asset.details.contractStandard === 'ERC721' ?
								<div className='d-flex flex-column'>
									<span className='cardLabel'>{t("Owner")}</span>
									<Link
										className='cardValue d-flex align-items-center hover text-decoration-none color-inherit'
										to={`/profile/${owner && owner?.address}`}
									>
										{owner && owner.name
											? owner.name
											: 'Unnamed'}
									</Link>
								</div>
								:
								<div>
									{asset.owners.length != 1 ? <span onClick={() => setOwnedByModal(true)} onKeyDown={(e) => {
										if (e.key === 'Enter' || e.key === ' ') {
											setOwnedByModal(true)
										}
									}}
										role="button" tabIndex={0} style={{ cursor: "pointer" }}>{asset.owners.length} {t("Owners")}</span> : <span onClick={() => setOwnedByModal(true)} onKeyDown={(e) => {
											if (e.key === 'Enter' || e.key === ' ') {
												setOwnedByModal(true)
											}
										}}
											role="button" tabIndex={0} style={{ cursor: "pointer" }}>{t("Owner")}</span>}<br />
									{asset.owners.length == 1 ? <span>{owner.name}</span> : ""}
								</div>
						}
					</div>
				</div>
			</div>
			<OwnedByModal modalOpen={ownedByModal} closeModal={() => setOwnedByModal(false)} ownerList={asset.owners} />
		</div>
	)
}