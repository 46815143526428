import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi'
import {
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
	UncontrolledTooltip
} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classnames from 'classnames'
import { useState } from 'react'

import { useBidsModalActions } from '../../../components/modals/bids/store'
import { usePlaceBidModalActions } from '../../../components/modals/place-bid/store'
import useCountdown from '../../../hooks/useCountdown'
import { getAuctionCountdown } from '../../../utils/formatRoundTime'
import { useCancelAuction } from '../../../hooks/useCancelAuction'
import contracts from '../../../config/constants/contracts'
import { chainId } from '../../../config/constants/chains'
import { usePurchaseLoadingModalActions } from '../../../components/modals/purchase-loading/store'
import { useSomethingWentWrongModalActions } from '../../../components/modals/error/store'
import { useToast } from '../../../utils/toast/customSuccess'

import info from '../../../assets/images/infoIcon.svg'
import user from '../../../../src/assets/images/header/profileDDImg1.svg'
import { chainIdMap, chainMap } from '../../../utils/getFilterData'
import { useOkxSwitchNetwork } from '../../../hooks/useOkxSwitchNetwork'



export function AuctionStation({ asset }) {
	const [activeTab, setActiveTab] = useState('sell')
	const navigate = useNavigate()

	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab)
	}

	const canList = asset.details.isListable
	const { t } = useTranslation()

	return (
		<>
			{canList ? (
				<div className='nftCard d-block'>
					<div className='row w-100'>
						<div className='col-lg-6'>
							<Nav
								tabs
								className='trad_tab_hd navNftDetails d-inline-flex'
							>
								<NavItem>
									<NavLink
										className={classnames({
											active: activeTab === 'sell'
										})}
										onClick={() => toggle('sell')}
									>
										{t("Sell")}
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({
											active: activeTab === 'buy'
										})}
										onClick={() => toggle('buy')}
									>
										{t("Buy")}
									</NavLink>
								</NavItem>
							</Nav>
						</div>
						<div className='col-lg-6 text-right'>
							<div className='d-inline-flex align-items-center'>
								<img src={user} alt='user' />
								<span className='pl-2 fs-15 fw-400'>
									{t("You Own")} {asset.details.ownedSupplies}
								</span>
							</div>
						</div>
					</div>
					<div>
						<TabContent activeTab={activeTab}>
							<TabPane tabId='sell'>
								<div className='py-3'>
									<div>
										<button
											className='btn gradientBtn btn160 mr-2 my-1 d-inline-flex w-100'
											onClick={() =>
												navigate(
													`/listforsale/${asset.details.chain}/${asset.details.contractAddress}/${asset.details.nftId}`
												)
											}
										>
											{t("List for Sale")}
										</button>
									</div>
								</div>
							</TabPane>
							<TabPane tabId='buy'>
								<Auction asset={asset} />
							</TabPane>
						</TabContent>
					</div>
				</div>
			) : (
				<Auction asset={asset} />
			)}
		</>
	)
}

function Auction({ asset }) {
	const { t } = useTranslation()

	const { address: account } = useAccount()
	const { chain } = useNetwork()
	const { switchNetworkAsync } = useSwitchNetwork()
	const { switchNetwork } = useOkxSwitchNetwork()
	const walletType = localStorage.getItem('walletType')
	const { secondsRemaining } = useCountdown(asset.details.saleExpiry)
	const { days, hours, minutes, seconds } =
		getAuctionCountdown(secondsRemaining)
	const toast = useToast()

	const { toggleBidsModal } = useBidsModalActions()
	const { setAsset } = usePlaceBidModalActions()
	const { togglePurchaseLoadingModal, setHeading } =
		usePurchaseLoadingModalActions()
	const { toggleSomethingWentWrongModal } =
		useSomethingWentWrongModalActions()

	const chainid = chainIdMap[asset.details.chain]
	const marketplaceContractAddress = asset
		? contracts.marketplace[chainid]
		: null

	const { cancelAuction } = useCancelAuction(marketplaceContractAddress, chainid)

	const now = Math.floor(new Date().getTime() / 1000)
	const isAuctionExpired = now > asset.details.saleExpiry
	const isOwner =
		!!account && account?.toLowerCase() === asset?.details?.seller

	const handleCancelListing = async () => {
		setHeading('Cancel Listing')
		togglePurchaseLoadingModal()
		try {
			if (walletType === 'okx') {
				if (
					asset.details.chain !== chainMap[parseInt(window.okxwallet.chainId)]
				) {
					await switchNetwork(chainIdMap[asset.details.chain])
				}
			} else {
				if (asset.details.chain === 'binance' && chain.id !== chainId.BINANCE) {
					await switchNetworkAsync(chainId.BINANCE)
				} else if (
					asset.details.chain === 'ethereum' &&
					chain.id !== chainId.ETHEREUM
				) {
					await switchNetworkAsync(chainId.ETHEREUM)
				} else if (
					asset.details.chain === 'polygon' &&
					chain.id !== chainId.AMOY
				) {
					await switchNetworkAsync(chainId.AMOY)
				}
			}


			await cancelAuction({
				owner: asset.details.seller,
				nftAddress: asset.details.contractAddress,
				tokenId: asset.details.nftId,
				orderId: asset.details.orderId,
			})
			toast.success('Listing canceled')
		} catch (error) {
			toggleSomethingWentWrongModal()
		} finally {
			togglePurchaseLoadingModal()
		}
	}

	const handleClick = () => {
		setAsset(asset);
		toggleBidsModal();
	};

	const handleKeyDown = (event) => {
		if (event.key === 'Enter' || event.key === ' ') {
			handleClick();
		}
	};


	return (
		<>
			{!isAuctionExpired && (
				<div className='d-flex algign-items-center flex-wrap w-100'>
					<div className='d-flex flex-column'>
						<span
							className='cardLabel d-flex'
							style={{ gap: '5px' }}
						>
							{t("Minimum Bid")}{' '}
							<span
								className='yellowTxt'
								style={{ cursor: 'pointer' }}
								onClick={handleClick}
								onKeyDown={handleKeyDown}
								role="button"
								tabIndex={0}
							>
								{t("View Bids")}
							</span>
							<div className='hover'>
								<img
									alt='information icon'
									id='bid-info'
									src={info}
								/>
								<UncontrolledTooltip
									placement='top'
									target='bid-info'
									className='tooltipCnt red-tooltip'
								>
									{t("The highest bidder will win the item at the end of the auction")}
								</UncontrolledTooltip>
							</div>
						</span>
						<div className='d-flex align-items-center mb-2'>
							<h3 className='mb-0 mr-2'>{`${parseFloat(
								asset.details.price.toFixed(8)
							)} ${asset.details.currency}`}</h3>
						</div>
					</div>
					<div className='ml-auto fs-14 fw-400 dark-text-secondary d-inline-flex flex-column justify-content-center align-items-end'>
						<div>
							<span>{t("Ends in")}</span>
						</div>
						<div>
							<span class='fw-600 dark-text'>{days}</span> Days{' '}
							<span class='fw-600  dark-text'>{hours}</span> Hours{' '}
							<span class='fw-600  dark-text'>{minutes}</span>{' '}
							Mins <span class='fw-600 dark-text'>{seconds}</span>{' '}
							Secs
						</div>
					</div>
				</div>
			)}
			<div className='d-flex align-items-center flex-wrap w-100'>
				{!isAuctionExpired &&
					(isOwner ? (
						<button
							className='btn gradientBtn btn-block btn160 mr-2 my-1'
							onClick={handleCancelListing}
						>
							{t('Cancel Listing')}
						</button>
					) : (
						<button
							className='btn gradientBtn btn-block btn160 mr-2 my-1'
							onClick={() => {
								setAsset(asset)
								toggleBidsModal()
							}}
						>
							{t('Place a Bid')}
						</button>
					))}
				{isAuctionExpired ? (
					isOwner ? (
						<button
							className='btn gradientBtn btn-block btn160 mr-2 my-1'
							onClick={handleCancelListing}
						>
							{t('Cancel Listing')}
						</button>
					) : (
						<button
							className='btn gradientBtn btn-block btn160 mr-2 my-1'
							onClick={() => {
								setAsset(asset)
								toggleBidsModal()
							}}
						>
							{t('View Bids')}
						</button>
					)
				) : null}
			</div>
		</>
	)
}
