export const flattenFilters = (filters, parentKey = '') => {
	return Object.entries(filters).reduce((acc, [key, value]) => {
		const currentKey = parentKey ? `${parentKey}[${key}]` : key

		if (typeof value === 'object' && value !== null) {
			return { ...acc, ...flattenFilters(value, currentKey) }
		} else {
			return { ...acc, [currentKey]: value }
		}
	}, {})
}
