import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import useCountdown from '../../../hooks/useCountdown'
import { getAuctionCountdown } from '../../../utils/formatRoundTime'
import { formatDateToTimeMonth } from '../../../utils/formatTime'
import { Spinner } from '../../loader/spinner/Spinner'
import { CloseIcon } from '../buy-now/CloseIcon'
import {
	usePlaceBidModalActions,
	usePlaceBidModalState
} from '../place-bid/store'
import { useFetchBids } from './fetchBids'
import { useBidsModalActions, useBidsModalState } from './store'
import contracts from '../../../config/constants/contracts'
import { chainId } from '../../../config/constants/chains'
import { useConnectWalletModalActions } from '../connect-wallet/store'
import { formatAddress } from '../../../utils/formatAddress'

import cndds from '../../../assets/images/cndds.png'
import cnddsLight from '../../../assets/images/cnddsLight.svg'
import { chainIdMap, chainMap } from '../../../utils/getFilterData'
import { useOkxSwitchNetwork } from '../../../hooks/useOkxSwitchNetwork'



export function BidsModal() {
	const { t } = useTranslation()

	const { toggleBidsModal } = useBidsModalActions()
	const { isOpen } = useBidsModalState()
	const { togglePlaceBidModal } = usePlaceBidModalActions()
	const { asset } = usePlaceBidModalState()
	const { data, isLoading } = useFetchBids(asset?.details?.orderId)
	const bids = data?.data?.bidderDetails
	const { secondsRemaining } = useCountdown(data?.data?.saleExpiry)
	const { days, hours, minutes, seconds } =
		getAuctionCountdown(secondsRemaining)
	const marketplaceContractAddress = asset
		? contracts.marketplace[chainIdMap[asset.details.chain]]
		: null
	const { toggleConnectWalletModal, setConnectWalletModalState } =
		useConnectWalletModalActions()
	const { switchNetworkAsync } = useSwitchNetwork()
	const { switchNetwork } = useOkxSwitchNetwork()
	const walletType = localStorage.getItem('walletType')
	const { address: account } = useAccount()
	const { chain: network } = useNetwork()
	const chainid = network?.id

	if (!asset || !account) {
		return null
	}

	const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true'

	const now = Math.floor(new Date().getTime() / 1000)
	const isAuctionExpired = now > asset.details.saleExpiry

	const handlePlaceBid = async () => {
		setConnectWalletModalState({ chainid: chainIdMap[asset.details.chain] })
		if (!account) {
			toggleConnectWalletModal()
			setConnectWalletModalState({
				callback: (account) => {
					if (account.toLowerCase() !== asset.details.seller) {
						togglePlaceBidModal()
					}
				}
			})
			return
		}

		try {
			if (walletType === 'okx') {
				if (
					asset.details.chain !== chainMap[parseInt(window.okxwallet.chainId)]
				) {
					await switchNetwork(chainIdMap[asset.details.chain])
				}
			} else {
				if (asset.details.chain === 'binance' && chainid !== chainId.BINANCE) {
					await switchNetworkAsync(chainId.BINANCE)
				} else if (
					asset.details.chain === 'ethereum' &&
					chainid !== chainId.ETHEREUM
				) {
					await switchNetworkAsync(chainId.ETHEREUM)
				} else if (asset.details.chain === 'polygon' && chainid !== chainId.AMOY) {
					await switchNetworkAsync(chainId.AMOY)
				}
			}
			togglePlaceBidModal()
		} catch (error) {
		}
	}

	return (
		<Modal
			isOpen={isOpen}
			centered='true'
			className='mymodal modalLG'
			backdropClassName='selCurBp'
			keyboard={false}
		>
			<ModalHeader
				toggle={toggleBidsModal}
				close={<CloseIcon onClose={toggleBidsModal} />}
			>
				<h5 className='modal-title'>{t('Bids')}</h5>
			</ModalHeader>
			<ModalBody>
				{isLoading ? (
					<div
						className='d-flex justify-content-center align-items-center'
						style={{ height: '500px' }}
					>
						<Spinner />
					</div>
				) : (
					<>
						<div className='borderBox mb-4'>
							<div className='d-flex flex-wrap justify-content-between'>
								<div>
									<div className='fs-14 fw-400 dark-text-secondary'>
										{t('Bids')}
									</div>
									<div className='fs-16 fw-400 dark-text'>
										{data?.data?.bids}
									</div>
								</div>
								<div>
									<div className='fs-14 fw-400 dark-text-secondary'>
										{t("Bidders")}
									</div>
									<div className='fs-16 fw-400 dark-text'>
										{data?.data?.bidders}
									</div>
								</div>
								<div style={{ width: '20%' }}>
									<div className='fs-14 fw-400 dark-text-secondary'>
										{t("Time Left")}
									</div>
									{!isAuctionExpired ? (
										<div className='fs-16 fw-400 dark-text'>
											{`${days}d ${hours}h ${minutes}m ${seconds}s`}
										</div>
									) : (
										t('Expired')
									)}
								</div>
								<div>
									<div className='fs-14 fw-400 dark-text-secondary'>
										{t('Duration')}
									</div>
									<div className='fs-16 fw-400 dark-text'>
										{data?.data?.duration}
									</div>
								</div>
							</div>
						</div>
						<div
							className='table-responsive scroller'
							style={{ overflowY: 'auto', maxHeight: '320px' }}
						>
							<table className='table table-borderless table-hover mb-0 hmecollection nftTables_offers'>
								<thead className='trd_head'>
									<tr>
										<th className='text-center'>
											{t("Bid Amount")}
										</th>
										<th className='text-center'>
											{t("USD Price")}
										</th>
										<th className='text-center'>
											{t("Bid Time")}
										</th>
										<th className='text-center'>{t("Bidder")}</th>
									</tr>
								</thead>
								<tbody
									className='trdtable_body'
									style={{ overflowY: 'auto' }}
								>
									{bids?.length === 0 ? (
										<tr>
											<td colspan='4'>
												<div className='col-12'>
													<div class='noResf d-flex flex-column justify-content-center align-items-center'>
														<img
															class='img-fluid'
															src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : cndds}
															alt=''
														/>
														<h1>{t("No Data")}</h1>
													</div>
												</div>
											</td>
										</tr>
									) : (
										bids?.map((bidder) => (
											<tr key={bidder.bidAmount}>
												<td className='text-center'>{`${bidder.bidAmount} ${data?.data?.currency}`}</td>
												<td className='text-center'>
													$
													{parseFloat(
														bidder.usdPrice.toFixed(
															2
														)
													)}
												</td>
												<td className='text-center'>
													{formatDateToTimeMonth(
														bidder.bidTime
													)}
												</td>
												<td className='yellowTxt text-center'>
													<Link
														className='yellowTxt'
														to={`/profile/${bidder.bidderAddress}`}
														onClick={() =>
															toggleBidsModal()
														}
													>
														{bidder.bidder ===
															'Unnamed'
															? formatAddress(
																bidder.bidderAddress,
																5
															)
															: bidder.bidder}
													</Link>
												</td>
											</tr>
										))
									)}
								</tbody>
							</table>
						</div>
						<div className='d-flex flex-wrap justify-content-between mt-4'>
							<button
								className='btn darkBtn my-1 '
								onClick={toggleBidsModal}
							>
								{t("Get Back")}
							</button>
							{!isAuctionExpired ? !isLoggedIn ? (
								<button
									className='btn gradientBtn my-1'
									onClick={handlePlaceBid}
								>
									{t("Place a Bid")}
								</button>
							) : (
								!!account &&
								account?.toLowerCase() !==
								asset?.details?.seller && (
									<button
										className='btn gradientBtn my-1'
										onClick={handlePlaceBid}
									>
										{t("Place a Bid")}
									</button>
								)
							) : null}
						</div>
					</>
				)}
			</ModalBody>
		</Modal>
	)
}
