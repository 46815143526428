import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import DataService from '../../../lib/DataService'

const { getRequest, postRequest } = DataService()
function fetchAssetDetails(collectionAddress, nftId, chain, address) {
	return getRequest(`asset/details?collection=${collectionAddress}&id=${nftId}&chain=${chain}&address=${address}`)

}

export function useFetchAssetDetails(collectionAddress, nftId, chain, address) {
	return useQuery({
		queryKey: ['asset', collectionAddress, nftId, chain, address],
		queryFn: () => fetchAssetDetails(collectionAddress, nftId, chain, address),
		enabled: !!(collectionAddress && nftId && chain)
	})
}

function fetchOfferList(collectionAddress, nftId) {
	
	return getRequest(`asset/offers?collection=${collectionAddress}&id=${nftId}`)

}

export function useFetchOfferList(collectionAddress, nftId) {
	return useQuery({
		queryKey: ['makeOffer', collectionAddress, nftId],
		queryFn: () => fetchOfferList(collectionAddress, nftId),
		enabled: !!(collectionAddress && nftId)
	})
}

function approveOffer(data) {
	return postRequest('asset/approve-offer', { ...data })
}

export function useApproveOffer() {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: approveOffer,
		onSuccess: () => {
			queryClient.invalidateQueries(['asset'])
			queryClient.invalidateQueries(['makeOffer'])
		}
	})
}
