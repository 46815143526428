import DefaultImage from '../assets/images/homeExplorer/placeholder.png'
import DefaultBannerImage from '../assets/images/profile/profileCover.png'

export const handleImageError = (event) => {
    event.target.src = DefaultImage;
};



export const handleBannerImageError = (event) =>{
    event.target.src = DefaultBannerImage;
}
