import React, { useState, useEffect, useContext } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import '../Activity/profileActivity.scss'
import './profileOfferReceived.scss'

import yellowTick from '../../../assets/images/collection/yellow-tick_20px.svg'
import greenCheck from '../../../assets/images/greenCheck.svg'

import { useToast } from '../../../utils/toast/customSuccess'
import { ThemeContext } from '../../context/context'
import noItemfoundImage from '../../../assets/images/collection/noItemfoundImage.svg'
import cnddsLight from '../../../assets/images/cnddsLight.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import loader from '../../../assets/images/Loader.png'
import contracts from '../../../config/constants/contracts'
import Marketplace from '../../AbiArray/MarketplaceBnbabi.json'

import Approveabi from '../../AbiArray/Approvalcollection/Bnbapproval.json'
import {
	useAccount,
	useConnect,
	useNetwork,
	useSwitchNetwork,
} from 'wagmi'
import Web3 from 'web3'
import { getOrder1 } from '../../../utils/getOrder'
import walletLoaderCnt from '../../../assets/images/walletLoader.png'
import walletLightLoaderCnt from '../../../assets/images/wallet-light-loader.png'
import walletLoader from '../../../assets/images/loaderCircle.png'
import ResponsivePagination from 'react-responsive-pagination'
import { getProvider } from '../../../utils/getProvider'
import { chainId } from '../../../config/constants/chains'

import { useContractProvider } from '../../../utils/useContractProvider'
import { useOkxSwitchNetwork } from '../../../hooks/useOkxSwitchNetwork'
import defaultImage from '../../../assets/images/homeExplorer/placeholder.png'
import { handleImageError } from '../../../hooks/useHandleImg'
import { chainIdMap, chainMap } from '../../../utils/getFilterData'
import DataService from '../../../lib/DataService'

const landPerRow = 6

const ActivityCollection = () => {
	const [modal, setModal] = useState(false)
	const toggle = () => setModal(!modal)

	const [modal1, setModal1] = useState(false)
	const toggle1 = () => setModal1(!modal1)

	const [modal2, setModal2] = useState(false)
	const toggle2 = () => setModal2(!modal2)

	const [modal3, setModal3] = useState(false)
	const toggle3 = () => setModal3(!modal3)

	const closeBtn = (
		<button className='close' onClick={() => onclose()}>
			<svg
				width='20'
				height='20'
				viewBox='0 0 20 20'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M12.3359 10.5L16.5547 14.7188C16.7891 14.9531 16.7891 15.3828 16.5547 15.6172L15.5781 16.5938C15.3438 16.8281 14.9141 16.8281 14.6797 16.5938L10.5 12.375L6.28125 16.5938C6.04688 16.8281 5.61719 16.8281 5.38281 16.5938L4.40625 15.6172C4.17188 15.3828 4.17188 14.9531 4.40625 14.7188L8.625 10.5L4.40625 6.32031C4.17188 6.08594 4.17188 5.65625 4.40625 5.42188L5.38281 4.44531C5.61719 4.21094 6.04688 4.21094 6.28125 4.44531L10.5 8.66406L14.6797 4.44531C14.9141 4.21094 15.3438 4.21094 15.5781 4.44531L16.5547 5.42188C16.7891 5.65625 16.7891 6.08594 16.5547 6.32031L12.3359 10.5Z'
					fill='#6E737E'
				/>
			</svg>
		</button>
	)
	const { getRequest, postRequest } = DataService()


	const closeBtn1 = (
		<button className='close' onClick={() => onclose1()}>
			<svg
				width='20'
				height='20'
				viewBox='0 0 20 20'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M12.3359 10.5L16.5547 14.7188C16.7891 14.9531 16.7891 15.3828 16.5547 15.6172L15.5781 16.5938C15.3438 16.8281 14.9141 16.8281 14.6797 16.5938L10.5 12.375L6.28125 16.5938C6.04688 16.8281 5.61719 16.8281 5.38281 16.5938L4.40625 15.6172C4.17188 15.3828 4.17188 14.9531 4.40625 14.7188L8.625 10.5L4.40625 6.32031C4.17188 6.08594 4.17188 5.65625 4.40625 5.42188L5.38281 4.44531C5.61719 4.21094 6.04688 4.21094 6.28125 4.44531L10.5 8.66406L14.6797 4.44531C14.9141 4.21094 15.3438 4.21094 15.5781 4.44531L16.5547 5.42188C16.7891 5.65625 16.7891 6.08594 16.5547 6.32031L12.3359 10.5Z'
					fill='#6E737E'
				/>
			</svg>
		</button>
	)

	const onclose = () => {
		setModal1(!modal1)
	}

	const onclose1 = () => {
		setModal1(!modal1)
	}

	const { t } = useTranslation()

	const navigate = useNavigate()

	const { address, isConnected } = useAccount()
	const { connectAsync, connectors } = useConnect()

	const { switchNetworkAsync } = useSwitchNetwork()
	const { switchNetwork } = useOkxSwitchNetwork()

	const { chain: network } = useNetwork()
	const chainid = network?.id

	const toast = useToast()

	const { id } = useParams()

	const [loading, setLoading] = useState(false)
	let [color, setColor] = useState('#ffffff')

	const {
		offerCollection,
		setOfferCollection,
		offersearchCollection,
		setOfferSearchCollection
	} = useContext(ThemeContext)

	const clearAll = () => {
		setOfferCollection([])
		setOfferSearchCollection('')
	}

	const Back = () => {
		setOfferCollection([])
		setOfferSearchCollection('')
	}

	const [next, setNext] = useState(landPerRow)
	const [page, setPage] = useState(1)

	useEffect(() => {
		Getofferreceived()
	}, [address, page, offerCollection, offersearchCollection, id])

	const [offerreceived, setOfferReceived] = useState('')
	const [videoError, setVideoError] = useState(false)
	const [count, setCount] = useState('')

	function handlePageChange(page) {
		setPage(page)
	}
	const handleVideoError = () => {
		setVideoError(true)
	}

	const [totalcount, setTotalCount] = useState('')

	const Getofferreceived = async () => {
		setLoading(true)
		const fetch = await getRequest(
			`user/offers_received?address=${id ? id : address}&page=${offerCollection > 0 ? 1 : offersearchCollection !== '' ? 1 : page
			}&limit=15&collectionName=${offerCollection}&filter=${offersearchCollection}`
		)
		if (fetch.status == true) {
			setOfferReceived(fetch.data)
			setLoading(false)
			setCount(Math.ceil(fetch.count / 15))
			setTotalCount(fetch.count)
			setPage(offerCollection > 0 ? 1 : offersearchCollection !== '' ? 1 : page)
		} else {
			setLoading(false)
			setOfferReceived('')
			setTotalCount('')
			setCount('')
			setPage(1)
		}
	}

	const listViewrow =
		offerreceived &&
		offerreceived.map((item, index) => (
			<>
				<tr
					onClick={e =>
						e.stopPropagation(
							navigate(
								`/assets/${item.chain}/${item.contractAddress}/${item.nftId}`
							)
						)
					}
					style={{ cursor: 'pointer' }}
				>
					<td>
						<div className='d-flex align-items-center'>
							<div className='tblImg'>
								{typeof item?.asset == 'string' &&
									item?.asset !== undefined &&
									!item?.asset?.startsWith(process.env.S3_URL) &&
									!item?.asset?.startsWith('ipfs') &&
									item?.asset !== null ? (
									item?.imageFormat?.startsWith('video') ? (
										videoError ? (
											<img src={defaultImage} alt={`item.imgsrc`} />
										) : (

											<video autoplay muted playsInline preload='metadata' controlslist='nodownload' style={{
												width: '60px',
												height: '70px',
												borderRadius: '12px'
											}}>
												<source src={item?.asset ? item?.asset : defaultImage} type="video/mp4" onError={handleVideoError} />
											</video>
										)
									) : item?.asset !== 'undefined' ? (
										<img
											src={item?.asset ? item?.asset : defaultImage}
											alt={`item.imgsrc`}
											onError={handleImageError}
										/>
									) : (
										<img src={defaultImage} alt={`item.imgsrc`} />
									)
								) : (
									<img src={defaultImage} alt={`item.imgsrc`} />
								)}
							</div>
							<div>
								<div className='fw-600 ml-2'>
									{item.nftName !== ''
										? item.nftName
										: item.nftId
											? `#${item.nftId}`
											: '---'}
									<img
										src={item.verification == 'Verified' ? yellowTick : ''}
										height='18'
										className='ml-1'
									/>
								</div>
							</div>
						</div>
					</td>
					<td className='text-right'>
						<div>
							{parseFloat(item?.price?.toFixed(8))}{' '}
							{item.currency ? item.currency : ''}
						</div>
						<div className='fs-12 dark-text-secondary'>
							${parseFloat((item.price * item.usdPrice)?.toFixed(8))}
						</div>
					</td>
					<td className='text-right'>
						{item.floorDifference?.floordifference !== null ? parseFloat(item.floorDifference?.floordifference)?.toFixed(2) : '0.00'}%{' '}
						{item.floorDifference?.up == true ? t('Above') : t('below')}
					</td>
					<td className='text-right'>{item.quantity}</td>
					<td className='text-right'>
						{item.saleExpiry == 0 ? '-' : moment(item.saleExpiry).fromNow()}
					</td>
					<td className='text-center'>
						{item.createdAt == 0 ? '-' : moment(item.createdAt).fromNow()}
					</td>

					{!!address && address.toLowerCase() === item.owner ? (
						<td className='text-right'>
							<span
								className='outlineBtn'
								onClick={e => e.stopPropagation(Accept(item))}
								onKeyDown={(e) => {
									if (e.key === 'Enter' || e.key === ' ') {
										e.stopPropagation(Accept(item))
									}
								}}
								role="button" tabIndex={0}
							>
								{t("Accept")}
							</span>
						</td>
					) : null}
				</tr>
			</>
		))

	const closecollection = item => {
		let a = offerCollection.includes(item)

		if (a == true) {
			const index = offerCollection.indexOf(item)
			if (index > -1) {
				offerCollection.splice(index, 1)
				const nerArr = [...offerCollection]
				setOfferCollection(nerArr)
			}
		}
	}
	const closesearch = () => {
		setOfferSearchCollection('')
	}

	const litstedTagLoop1 =
		offerCollection &&
		offerCollection.map(item => {
			return (
				<>
					{item == '' ? (
						''
					) : (
						<>
							<div className='tagLabel'>
								<span>{item}</span>
								<i
									className='fas fa-times ml-2'
									style={{ cursor: 'pointer' }}
									onClick={() => closecollection(item)}
									onKeyDown={(e) => {
										if (e.key === 'Enter' || e.key === ' ') {
											closecollection(item)
										}
									}}
									role="button" tabIndex={0}
								></i>
							</div>
						</>
					)}
				</>
			)
		})

	const [offeraccept, setOfferAccept] = useState('')

	const { pro } = useContractProvider()
	const walletType = localStorage.getItem('walletType')
	const provider = getProvider()

	const web3 = new Web3(provider)
	const bnbmarketplace = new web3.eth.Contract(
		Marketplace,
		contracts.marketplace[chainId.BINANCE]
	)
	const ethmarketplace = new web3.eth.Contract(
		Marketplace,
		contracts.marketplace[chainId.ETHEREUM]
	)
	const polygonmarketplace = new web3.eth.Contract(
		Marketplace,
		contracts.marketplace[chainId.POLYGON]
	)

	let contract
	let approvalTetherContract
	let approvalResponse

	const [acceptdata, setAcceptData] = useState('')

	const Accept = async item => {
		setModal1(true)
		setAcceptData(item)
	}

	const Acceptoffer = () => {
		setModal2(true)
	}

	const confirmAcceptOfffer = async () => {
		setModal(true)
		if (address) {
			const order = getOrder1({
				asset: {
					...acceptdata,
					offeror: acceptdata.fromAddress,
					details: {
						...acceptdata,
						price: acceptdata.price,
						currencyAddress: acceptdata.currencyAddress
					}
				},
				assetType: acceptdata.contractStandard,
				orderType: 'buy',
				expiry: acceptdata.expiry
			})

			try {
				if (walletType === 'okx') {
					if (
						acceptdata.chain !== chainMap[parseInt(window.okxwallet.chainId)]
					) {
						await switchNetwork(chainIdMap[acceptdata.chain])
					}
				} else {
					if (acceptdata.chain === 'binance' && chainid !== chainId.BINANCE) {
						await switchNetworkAsync(chainId.BINANCE)
					} else if (acceptdata.chain === 'ethereum' && chainid !== chainId.ETHEREUM) {
						await switchNetworkAsync(chainId.ETHEREUM)
					} else if (acceptdata.chain === 'polygon' && chainid !== chainId.AMOY) {
						await switchNetworkAsync(chainId.AMOY)
					}
				}
			} catch (error) {
				toast.error('User rejected the switch network')

				setModal(false)
				setModal1(false)
				return
			}
			approvalTetherContract = new web3.eth.Contract(
				Approveabi,
				acceptdata.contractAddress
			)
			if (acceptdata.chain === 'ethereum') {
				contract = ethmarketplace
				approvalResponse = await approvalTetherContract.methods
					.isApprovedForAll(address, contracts.marketplace[chainId.ETHEREUM])
					.call()
			} else if (acceptdata.chain === 'binance') {
				contract = bnbmarketplace
				approvalResponse = await approvalTetherContract.methods
					.isApprovedForAll(address, contracts.marketplace[chainId.BINANCE])
					.call()
			} else if (acceptdata.chain === 'polygon') {
				contract = polygonmarketplace
				approvalResponse = await approvalTetherContract.methods
					.isApprovedForAll(address, contracts.marketplace[chainId.POLYGON])
					.call()
			}


			if (approvalResponse) {
				const data = await postRequest('asset/buyer-fee-signature', {
					orderTuple: order,
					royalty: Number(acceptdata.royaltyFee) * 10,
					royaltyAddress: acceptdata.creator,
					network: acceptdata.chain
				})

				if (data.status == true) {
					const buyerFeeSig = [data.data.v, data.data.r, data.data.s]
					try {
						await contract.methods
							.buy(
								[order],
								acceptdata.signTuple,
								[buyerFeeSig],
								[Number(acceptdata.royaltyFee) * 10],
								[acceptdata.creator],
								[!acceptdata.isMinted],
								[acceptdata.uri !== '' ? acceptdata.uri : process.env.REACT_APP_PUBLIC_URL],
								acceptdata.totalQuantity
							)
							.send({
								from: address.toString()
							})
							.on('transactionHash', async hash => { })
							.on('receipt', async approveresult => {
								const params = {
									orderId: acceptdata._id,
									address: address,
									contractAddress: acceptdata.contractAddress,
									nftId: acceptdata.nftId,
									transactionHash: approveresult?.transactionHash
								}
								const approveRes = await postRequest(
									'asset/approve-offer',
									params
								)
								if (approveRes.status == true) {
									toast.success(approveRes.message)
									setModal(false)
									setModal1(false)
									setModal3(true)
									Getofferreceived()

								} else {
									toast.error(approveRes.message)
									setModal(false)
									setModal1(false)
								}
							})
					} catch (error) {
						setModal(false)
						setModal1(false)
					}
				} else {
					toast.error(data.message)
					setModal(false)
					setModal1(false)
				}
			} else {
				try {
					if (acceptdata.chain === 'ethereum') {
						contract = bnbmarketplace

						await approvalTetherContract.methods
							.setApprovalForAll(contracts.marketplace[chainId.ETHEREUM], true)
							.send({ from: address.toString() })
							.on('transactionHash', async hash => { })
							.on('receipt', async approveresult => { })
					} else if (acceptdata.chain === 'binance') {
						contract = ethmarketplace

						await approvalTetherContract.methods
							.setApprovalForAll(contracts.marketplace[chainId.BINANCE], true)
							.send({ from: address.toString() })
							.on('transactionHash', async hash => { })
							.on('receipt', async approveresult => { })
					} else if (acceptdata.chain === 'polygon') {
						contract = polygonmarketplace

						await approvalTetherContract.methods
							.setApprovalForAll(contracts.marketplace[chainId.POLYGON], true)
							.send({ from: address.toString() })
							.on('transactionHash', async hash => { })
							.on('receipt', async approveresult => { })
					}

					const data = await postRequest('asset/buyer-fee-signature', {
						orderTuple: order,
						royalty: Number(acceptdata.royaltyFee) * 10,
						royaltyAddress: acceptdata.creator,
						network: acceptdata.chain
					})

					if (data.status == true) {
						const buyerFeeSig = [data.data.v, data.data.r, data.data.s]
						try {
							await contract.methods
								.buy(
									[order],
									acceptdata.signTuple,
									[buyerFeeSig],
									[Number(acceptdata.royaltyFee) * 10],
									[acceptdata.creator],
									[!acceptdata.isMinted],
									[acceptdata.uri !== '' ? acceptdata.uri : process.env.REACT_APP_PUBLIC_URL],
									acceptdata.totalQuantity
								)
								.send({
									from: address.toString()
								})
								.on('transactionHash', async hash => { })
								.on('receipt', async approveresult => {
									const params = {
										orderId: acceptdata._id,
										address: address,
										contractAddress: acceptdata?.contractAddress,
										nftId: acceptdata.nftId,
										transactionHash: approveresult?.transactionHash
									}
									const approveRes = await postRequest(
										'asset/approve-offer',
										params
									)
									if (approveRes.status == true) {
										toast.success(approveRes.message)
										Getofferreceived()
										setModal(false)
										setModal1(false)
										setModal3(true)
									} else {
										toast.error(approveRes.message)
										setModal(false)
										setModal1(false)
									}
								})
						} catch (error) {
							setModal(false)
							setModal1(false)
						}
					} else {
						toast.error(data.message)
						setModal(false)
						setModal1(false)
					}
				} catch (error) {
					setModal(false)
					setModal1(false)
				}
			}
		} else {
			toast.error('Please connect wallet')
			setModal(false)
			setModal1(false)
		}
	}

	const Submit = () => {
		setModal3(false)
	}
	return (
		<>
			<div className={`text-center ${loading ? 'spinner' : 'd-none'}`}>
				<img src={loader} alt='' className='spinAnimate' />
			</div>
			<div className='row my-3'>
				<div className='col-lg-6'>
					<span class='cmntxt'>
						{totalcount ? totalcount : '0'} {t('offers received')}
					</span>
				</div>
			</div>
			<div className='fiterSelectlabel mb-4 mt-4'>
				{offersearchCollection ? (
					<div className='tagLabel'>
						<span>{offersearchCollection}</span>
						<i
							className='fas fa-times ml-2'
							style={{ cursor: 'pointer' }}
							onClick={closesearch}
							onKeyDown={(e) => {
								if (e.key === 'Enter' || e.key === ' ') {
									closesearch()
								}
							}}
							role="button" tabIndex={0}
						></i>
					</div>
				) : (
					''
				)}

				{litstedTagLoop1}
				{Number(
					offersearchCollection.trim() === ''
						? 0
						: offersearchCollection.trim().replace(/\s+/g, '').split(/\s+/)
							.length
				) +
					offerCollection.length >
					1 ? (
					<button className='clearAllBtn' onClick={clearAll}>
						Clear All
					</button>
				) : (
					''
				)}
			</div>
			{offerreceived && offerreceived.length > 0 ?
				<div className='table-responsive'>
					<table className='table table-borderless activityTable offerTable'>

						<thead>
							<tr>
								<th>{t("Offer")}</th>
								<th className='text-right'>{t("Price")}</th>
								<th className='text-right'>{t("Floor Difference")}</th>
								<th className='text-right'>{t("Quantity")}</th>
								<th className='text-right'>{t("Expiration")}</th>
								<th className='text-center'>{t("Received")}</th>
								<th></th>
							</tr>
						</thead>

						<tbody>
							{listViewrow}

						</tbody>
					</table>
				</div>

				: (
					<div className='noItemFound'>
						{loading == false ? (
							<>
								<div className='text-center'>
									<img
										src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : noItemfoundImage}
										alt='noItemfoundImage'
										className='img-fluid'
									/>
								</div>

								{offersearchCollection || offerCollection.length > 0 ? (
									<>
										<div className='nofounttxt mb-4'>
											{t(
												'No items were found. Try to broaden your search.'
											)}
										</div>
										<div>
											<button className='applyBtn' onClick={Back}>
												{t("Back to all items")}
											</button>
										</div>
									</>
								) : (
									<div className='nofounttxt mb-4'>
										{t('Nothing to display.')}
									</div>
								)}
							</>
						) : (
							''
						)}
					</div>

				)}

			<div className='row justify-content-center'>
				<div className='col-lg-4 col-md-6 col-sm-8'>
					<div class='bri_pagenation2'>
						{offerreceived && offerreceived.length > 0 ? (
							<div class=''>
								<nav aria-label='Page '>
									<ResponsivePagination
										total={count}
										current={page}
										onPageChange={page => handlePageChange(page)}
										previousLabel={
											<svg
												width='7'
												height='12'
												viewBox='0 0 7 12'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													d='M1.125 5.75L5.71875 1.125C5.84375 0.96875 6.09375 0.96875 6.25 1.125L6.84375 1.75C7 1.875 7 2.125 6.84375 2.28125L3.15625 6L6.84375 9.75C7 9.90625 7 10.125 6.84375 10.2812L6.25 10.9062C6.09375 11.0625 5.84375 11.0625 5.71875 10.9062L1.125 6.28125C0.96875 6.125 0.96875 5.90625 1.125 5.75Z'
													fill='#F7F7F7'
												></path>
											</svg>
										}
										nextLabel={
											<svg
												width='7'
												height='12'
												viewBox='0 0 7 12'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													d='M6.84375 6.28125L2.28125 10.9062C2.125 11.0625 1.875 11.0625 1.75 10.9062L1.125 10.2812C0.96875 10.125 0.96875 9.90625 1.125 9.75L4.8125 6L1.125 2.28125C0.96875 2.125 0.96875 1.875 1.125 1.75L1.75 1.125C1.875 0.96875 2.125 0.96875 2.28125 1.125L6.84375 5.75C7 5.90625 7 6.125 6.84375 6.28125Z'
													fill='#F7F7F7'
												></path>
											</svg>
										}
										previousClassName='page-link-1'
										nextClassName='page-link-1'
									/>
								</nav>
							</div>
						) : (
							''
						)}
					</div>
				</div>
			</div>

			<Modal
				isOpen={modal}
				toggle={toggle}
				centered='true'
				className='mymodal'
				backdropClassName='selCurBp'
				backdrop='static'
				keyboard={false}
			>
				<>
					<ModalHeader>{t("Accept Offer")}</ModalHeader>
					<ModalBody>
						<p className='dark-text-secondary text-center'>
							{t("To continue send transaction with your wallet")}.
						</p>
						<div className='d-flex justify-content-center align-items-center loaderCont'>
							{localStorage.getItem('light_theme') == 'true' ? (
								<img src={walletLightLoaderCnt} />
							) : (
								<img src={walletLoaderCnt} />
							)}
							<div className='loaderAnimCnt'>
								<img src={walletLoader} className='loaderAnimation' />
							</div>
						</div>
					</ModalBody>
				</>
			</Modal>

			<Modal
				isOpen={modal1}
				toggle={toggle1}
				centered='true'
				className='mymodal'
				backdropClassName='selCurBp'
				keyboard={false}
			>
				<ModalHeader toggle={toggle1} close={closeBtn}>
					{t("Accept Offer")}
				</ModalHeader>
				<ModalBody>
					<div>
						<div className='cartItem'>
							<div className='itemImg'>
								{typeof acceptdata?.asset == 'string' &&
									acceptdata?.asset !== undefined &&
									!acceptdata?.asset?.startsWith(process.env.S3_URL) &&
									!acceptdata?.asset?.startsWith('ipfs') &&
									acceptdata.asset !== null ? (
									acceptdata?.imageFormat?.startsWith('video') ? (
										videoError ? (
											<img src={defaultImage} alt={`item.imgsrc`} />
										) : (
											<video
												src={
													acceptdata?.asset ? acceptdata?.asset : defaultImage
												}
												alt='Uploaded'
												muted
												autoPlay
												style={{
													width: '60px',
													height: '70px',
													borderRadius: '12px'
												}}
												onError={handleVideoError}
											/>
										)
									) : acceptdata?.asset !== 'undefined' ? (
										<img
											src={acceptdata?.asset ? acceptdata?.asset : defaultImage}
											alt={`item.imgsrc`}
											onError={handleImageError}
										/>
									) : (
										<img src={defaultImage} alt={`item.imgsrc`} />
									)
								) : (
									<img src={defaultImage} alt={`item.imgsrc`} />
								)}
							</div>
							<div className='flex-grow-1 d-flex justify-content-between p-3'>
								<div className='d-flex flex-column mb-2'>
									<div className='mb-2'>
										<div className='itemName'>
											{acceptdata?.collectionName}
											{acceptdata?.verification == 'Verified' ? (
												<img
													src={yellowTick}
													alt='yellowtick20'
													className='ml-1'
												/>
											) : (
												''
											)}
										</div>
										<span className='fs-14 fw-400 dark-text-secondary'>
										</span>
									</div>

									<div className='itemId'>{acceptdata?.nftName}</div>
								</div>
								<div className='d-flex flex-column mb-n1 text-right'>
									<span className='itemBal mb-2'>
										$
										{(
											Number(acceptdata?.price) * Number(acceptdata?.usdPrice)
										)?.toFixed(8)}
									</span>
									<div className='itemId'>
										{acceptdata?.price}{' '}
										{acceptdata?.currency}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='borderRoundedCard'>
						<div class='d-flex justify-content-between mb-2'>
							<div className='fs-14 fw-400 dark-text-secondary'>
								{t("Floor Diference")}
							</div>
							<div className='fs-16 fw-400 dark-text'>
								{acceptdata?.floorDifference?.floordifference !== null
									? parseFloat(acceptdata?.floorDifference?.floordifference)?.toFixed(2)
									: '0.00'}
								% {acceptdata?.floorDifference?.up == true ? t('Above') : t('below')}
							</div>
						</div>
						<div class='d-flex justify-content-between mb-2'>
							<div className='fs-14 fw-400 dark-text-secondary'>{t("From")}</div>
							<div className='fs-16 fw-400 yellowTxt'>
								{acceptdata?.fromAddress?.slice(0, 10) + '...'}
							</div>
						</div>
						<div class='d-flex justify-content-between mb-2'>
							<div className='fs-14 fw-400 dark-text-secondary'>{t("Expiration")}</div>
							<div className='fs-16 fw-400 dark-text'>
								{acceptdata?.saleExpiry == 0
									? '-'
									: moment(acceptdata?.saleExpiry).fromNow()}
							</div>
						</div>
						<div class='d-flex justify-content-between mb-2'>
							<div className='fs-14 fw-400 dark-text-secondary'>
								{t("WARRIOR Fee")}
							</div>
							<div className='fs-16 fw-400 dark-text'>
								{acceptdata?.platformfee}%
							</div>
						</div>
						{address?.toLowerCase() == (acceptdata?.creator)?.toLowerCase() ?
							""
							:
							<div class='d-flex justify-content-between mb-2'>
								<div className='fs-14 fw-400 dark-text-secondary'>
									{t("Creator Earnings")}
								</div>
								<div className='fs-16 fw-400 dark-text'>
									{acceptdata?.royaltyFee}%
								</div>
							</div>
						}
						<div class='d-flex justify-content-between mb-2'>
							<div className='fs-14 fw-700 dark-text-secondary'>
								{t("Total Earnings")}
							</div>
							{address?.toLowerCase() == (acceptdata?.creator)?.toLowerCase() ?
								<div className='fs-16 fw-700 dark-text'>
									<span className='dark-text-grey fs-16 fw-400'>
										($
										{acceptdata &&
											(
												acceptdata?.price *
												(1 -
													(Number(acceptdata?.platformfee / 100))) *
												acceptdata?.usdPrice
											).toFixed(6)}
										)
									</span>{' '}
									{(
										acceptdata?.price *
										(1 -
											(Number(acceptdata?.platformfee / 100)))
									).toFixed(6)}{' '}
									{acceptdata?.currency}
								</div>
								:
								<div className='fs-16 fw-700 dark-text'>
									<span className='dark-text-grey fs-16 fw-400'>
										($
										{acceptdata &&
											(
												acceptdata?.price *
												(1 -
													(Number(acceptdata?.platformfee / 100) +
														Number(acceptdata?.royaltyFee) / 100)) *
												acceptdata?.usdPrice
											).toFixed(6)}
										)
									</span>{' '}
									{(
										acceptdata?.price *
										(1 -
											(Number(acceptdata?.platformfee / 100) +
												Number(acceptdata?.royaltyFee) / 100))
									).toFixed(6)}{' '}
									{acceptdata?.currency}
								</div>
							}
						</div>
					</div>
					<div className='mt-4'>
						<button
							className='btn btn-block checkoutBtn'
							onClick={confirmAcceptOfffer}
						>
							{t("Accept")}{' '}
						</button>
					</div>
				</ModalBody>
			</Modal>


			<Modal isOpen={modal3} toggle={toggle3} centered="true" className="mymodal" backdropClassName="selCurBp" backdrop="static" keyboard={false}>
				<ModalHeader onClick={toggle3} close={closeBtn}>
					<div className="d-flex flex-column align-items-center">
						<img src={greenCheck} className="mb-3" alt="greenCheck" />
						{acceptdata?.offermadeProfile ? acceptdata?.offermadeProfile : acceptdata?.fromAddress?.slice(0, 10) + '...'} {t("received the NFT!")}
					</div>
				</ModalHeader>
				<ModalBody className="pt-0">
					<div>
						{/* <p className="dark-text-secondary text-center">Once payment is finalised, you will be notified.</p> */}
						<div className='cartItem'>
							<div className='itemImg'>
								{typeof acceptdata?.asset == 'string' &&
									acceptdata?.asset !== undefined &&
									!acceptdata?.asset?.startsWith(process.env.S3_URL) &&
									!acceptdata?.asset?.startsWith('ipfs') &&
									acceptdata.asset !== null ? (
									acceptdata?.imageFormat?.startsWith('video') ? (
										videoError ? (
											<img src={defaultImage} alt={`item.imgsrc`} />
										) : (
											<video
												src={
													acceptdata?.asset ? acceptdata?.asset : defaultImage
												}
												alt='Uploaded'
												muted
												autoPlay
												style={{
													width: '60px',
													height: '70px',
													borderRadius: '12px'
												}}
												onError={handleVideoError}
											/>
										)
									) : acceptdata?.asset !== 'undefined' ? (
										<img
											src={acceptdata?.asset ? acceptdata?.asset : defaultImage}
											alt={`item.imgsrc`}
											onError={handleImageError}
										/>
									) : (
										<img src={defaultImage} alt={`item.imgsrc`} />
									)
								) : (
									<img src={defaultImage} alt={`item.imgsrc`} />
								)}
							</div>
							<div className='flex-grow-1 d-flex justify-content-between p-3'>
								<div className='d-flex flex-column mb-2'>
									<div className="mb-2">
										<div className='itemName'>{acceptdata?.collectionName}
											{acceptdata?.verification == 'Verified' ? (
												<img
													src={yellowTick}
													alt='yellowtick20'
													className='ml-1'
												/>
											) : (
												''
											)}</div>
										<span className='fs-14 fw-400 dark-text-secondary'>
											{/* $-- */}
										</span>
									</div>

									<div className='itemId'>{acceptdata?.nftName} {acceptdata?.nftId}</div>
								</div>
								<div className='d-flex flex-column mb-n1 text-right justify-content-center'>
									<div className='itemId'>{acceptdata?.price}{' '}
										{acceptdata?.currency}</div>
								</div>
							</div>
						</div>
					</div>
					<div className='mt-4'>
						<button className='btn btn-block checkoutBtn' onClick={Submit}>{t("Okay")}</button>
					</div>
				</ModalBody>
			</Modal>


		</>
	)
}

export default ActivityCollection
