import React, { Component, Suspense, useEffect, useState } from 'react'
import logo from './logo.svg'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-loading-skeleton/dist/skeleton.css'
import '../src/style.scss'
import {
	BrowserRouter,
	Navigate,
	Route,
	Routes,
	useNavigate
} from 'react-router-dom'
import Home from './views/Home/Home'
import Bridge from './views/Bridge/Bridge'
import BridgeHistory from './views/Bridge/BridgeHistroy'
import HomeCollection from './views/HomeCollection/HomeCollection'
import HomeActive from './views/HomeActive/HomeActive'
import HomeActive1 from './views/HomeActive1/HomeActive'

import CollectionPage from './views/collection/CollectionPage'
import CollectionLayout from './components/collectionLayout'
import MyCollectionLayout from './components/mycollectionLayout'
import MyCollection from './views/MyCollection/MyCollection'
import ChooseCollection from './views/MyCollection/ChooseCollection'
import CreateCollection from './views/MyCollection/CreateCollection'
import EditCollection from './views/MyCollection/EditCollection'
import MintLayout from './components/mintLayout'
import CreateNFT from './views/Mint/CreateNft'
import ProfilePage from './views/Profile/ProfilePage'
import ProfileLayout from './components/profileLayout'
import NftDetails from './views/NftDetails/NftDetails'
import SettingsLayout from './components/Settings'
import Profile from './views/Settings/Profile'
import Notification from './views/Settings/Notification'
import Offers from './views/Settings/Offers'
import Earnings from './views/Settings/Earnings'
import AccountSupport from './views/Settings/AccountSupport'
import Emailverify from './views/Settings/EmailVerifypage'
import Unsubscribe from './components/Footer/Unsubscribe'
import { Subscribeverify } from './components/Footer/SubscribeVerifypage'
import CMSPagesLayout from './components/CMSLayout';
import LegalNotice from './views/cmsPages/legal';
import PrivacyPage from './views/cmsPages/privacy';
import TermsPage from './views/cmsPages/terms';
import HelpCenterLayout from './components/helpCenterLayout';
import HelpCenterMainPage from './views/helpcenter/helpcenterMain';
import HelpCenterCategoryActive from './views/helpcenter/categoryActive';
import HelpCenterCategorySearch from './views/helpcenter/searchResult';
import { ThemeProvider } from '../src/views/context/context'
import { Toaster } from 'react-hot-toast'
import ChooseNftChain from './views/Mint/ChooseNftChain'
import Listforsale from './views/listforsale/Listforsale'
import ListforsaleLayout from './components/listforsaleLayout'
import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from './lib/react-query'
import { WagmiConfig } from 'wagmi'
import { wagmiConfig } from './lib/wagmi'
import { SWRConfig } from 'swr'
import { SkeletonTheme } from 'react-loading-skeleton'
import Nft from './features/nft/Nft'
import Collection from './features/collection/Collection'
import { ModalProvider } from './providers/modal-provider'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import HomeExplorerLayout from './components/homeExplorerLayout'
import HomeExplorerMain from './views/homeExplorer'
import Error from './views/Error/Error'
import PrivateRoutes from './components/Protectedroute'
import Conversation from './components/zen-desk/Conversation'
import SupportTicket from './components/zen-desk'
import { useTranslation } from 'react-i18next'

const App = props => {
	useEffect(() => {
		window.addEventListener('scroll', toggleVisible)
	}, [])

	const [visible, setVisible] = useState(false)
	const toggleVisible = () => {
		const scrolled = document.documentElement.scrollTop
		if (scrolled > 1000) {
			setVisible(true)
		} else if (scrolled <= 1000) {
			setVisible(false)
		}
	}
	const [profilePic, setProfilePic] = useState()
	const [username, setUsername] = useState()

	const [isLoggedIn, setIsLoggedIn] = useState(
		localStorage.getItem('isLoggedIn')
	)
	const { t } = useTranslation()


	useEffect(() => {
		const handleStorage = (e) => {
			setIsLoggedIn(localStorage.getItem('isLoggedIn'))
		}
		window.addEventListener('storage', handleStorage)

		return () => {
			window.removeEventListener('storage', handleStorage)
		}
	}, [])

	return (
		<React.Fragment>
			<BrowserRouter>
				<ThemeProvider>
					<Toaster gutter={20} />
					<QueryClientProvider client={queryClient}>
						<SWRConfig>
							<WagmiConfig config={wagmiConfig}>
								<SkeletonTheme baseColor='#202020' highlightColor='#444'>
									<ModalProvider />

									<div id='root-div'>

										<Header profilePic={profilePic} setProfilePic={setProfilePic} username={username} setUsername={setUsername} />

										<Routes history={props.history}>
											<Route path='/' element={<Home />} />


											<Route element={<PrivateRoutes />}>
												<Route path='/bridge' element={isLoggedIn === 'true' ? <Bridge /> : <Navigate to='/' />} />
												<Route
													path='/bridgehistory'
													element={isLoggedIn === 'true' ?
														<BridgeHistory /> : <Navigate to='/' />
													}
												/>
												<Route
													path='/homecollection'
													element={<HomeCollection />}
												/>
												<Route path='/activity' element={<HomeActive1 />} />
												<Route path='/nftList/:id' element={<HomeActive />} />
												{/*--------------------------------------zendesk-------------------------------  */}


												<Route path='/profile' element={isLoggedIn === 'true' ? <ProfileLayout profilePic={profilePic} setProfilePic={setProfilePic} username={username} setUsername={setUsername} /> : <Navigate to='/' />} >
													<Route index element={<ProfilePage profilePic={profilePic} setProfilePic={setProfilePic} username={username} setUsername={setUsername} />} />
												</Route>
												<Route path='/profile/:id' element={isLoggedIn === 'true' ? <ProfileLayout profilePic={profilePic} setProfilePic={setProfilePic} username={username} setUsername={setUsername} /> : <Navigate to='/' />}>
													<Route index element={<ProfilePage profilePic={profilePic} setProfilePic={setProfilePic} username={username} setUsername={setUsername} />} />
												</Route>
												<Route path='/' element={isLoggedIn === 'true' ? <MyCollectionLayout /> : <Navigate to='/' />}>
													<Route path='/mycollection' element={<MyCollection />} />
													<Route
														path='/choosecollection'
														element={<ChooseCollection />}
													/>
													<Route
														path='/createcollection'
														element={<CreateCollection />}
													/>
													<Route
														path='/editcollection/:id'
														element={<EditCollection />}
													/>
												</Route>
												<Route path='/' element={isLoggedIn === 'true' ? <MintLayout /> : <Navigate to='/' />}>
													<Route path='/createmint' element={<CreateNFT />} />
													<Route
														path='/chooseNftChain'
														element={<ChooseNftChain />}
													/>
												</Route>
												<Route path='/nftdetails/:id' element={<NftDetails />} />

												<Route path='/' element={<ListforsaleLayout />}>
													<Route
														path='/listforsale/:chain/:collectionAddress/:nftId'
														element={<Listforsale />}
													/>
												</Route>

												<Route path='/' element={isLoggedIn === 'true' ? <SettingsLayout /> : <Navigate to='/' />}>
													<Route path='/settingsprofile' element={<Profile profilePic={profilePic} setProfilePic={setProfilePic} username={username} setUsername={setUsername} />} />
													<Route
														path='/settingsnotification'
														element={<Notification />}
													/>
													<Route path='/settingsoffers' element={<Offers />} />
													<Route path='/settingsearnings' element={<Earnings />} />
													<Route
														path='/settingsaccountsupport'
														element={<AccountSupport />}
													/>
												</Route>
												<Route path='/emailverify/:id' element={<Emailverify />} />
												<Route path='/subscribeverify/:id' element={<Subscribeverify />} />
												<Route path='/subscription/:id' element={<Unsubscribe />} />


												<Route
													path='/collection/:collectionAddress'
													element={<Collection />}
												/>

												<Route
													path='/assets/:chain/:collectionAddress/:nftId'
													element={<Nft />}
												/>
												<Route path='/' element={<HelpCenterLayout />}>
													<Route path='/helpcenter' element={<HelpCenterMainPage />} />
													<Route path='/categoryactive/:id' element={<HelpCenterCategoryActive />} />
													<Route path='/searchResult/:id' element={<HelpCenterCategorySearch />} />

												</Route>

												<Route path='/' element={<CMSPagesLayout HeadPrimeTxt={t("Support")} headFirstTxt={t("Ticket")} />}>
													<Route path='/supportTicket' element={isLoggedIn === 'true' ? <SupportTicket /> : <Navigate to='/' />}>
													</Route>
													<Route path='/conversation/:id' element={isLoggedIn === 'true' ? <Conversation /> : <Navigate to='/' />}>
													</Route>
												</Route>


												<Route path='/' element={<CMSPagesLayout HeadPrimeTxt={t("Privacy")} headFirstTxt={t("Policy")} />}>
													<Route path='/privacy' element={<PrivacyPage />} />

												</Route>
												<Route path='/' element={<CMSPagesLayout HeadPrimeTxt={t("Terms")} headFirstTxt={t("of Service")} />}>
													<Route path='/terms' element={<TermsPage />} />
												</Route>
												<Route path='/' element={<CMSPagesLayout HeadPrimeTxt={t("Legal")} headFirstTxt={t("Notice")} />}>
													<Route path='/Legalnotice' element={<LegalNotice />} />
												</Route>

												<Route path='/' element={<HomeExplorerLayout />}>
													<Route path='/explorer' element={<HomeExplorerMain />} />
												</Route>

												
											</Route>
											<Route path='/*' element={<Error />} />
											<Route path='/404' element={<Error />} />

										</Routes>
										<Footer />
									</div>


								</SkeletonTheme>
							</WagmiConfig>
						</SWRConfig>
					</QueryClientProvider>

					<div className='d-flex justify-content-end'>
						<button
							className='scroll_btn'
							onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
							style={{ display: visible ? 'inline' : 'none' }}
						>
							<i className='fa fa-arrow-up'></i>
						</button>
					</div>
				</ThemeProvider>
			</BrowserRouter>
		</React.Fragment>
	)
}

export default App
