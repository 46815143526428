import React, { useContext, useEffect, useState } from 'react';

import '../../../assets/scss/filtersection.scss';
import ETHIcon from '../../../assets/images/collection/ETHIcon.png';
import BNBIcon from '../../../assets/images/collection/bnbCoin.png';
import MATICIcon from '../../../assets/images/collection/maticIconSm.png'
import { Button, Collapse, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import yellowTick from '../../../assets/images/collection/yellow-tick_20px.svg';
import { ThemeContext } from '../../context/context';
import { useAccount } from 'wagmi';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import defaultImage from '../../../assets/images/homeExplorer/placeholder.png'
import { handleImageError } from '../../../hooks/useHandleImg';
import { nativeCoinMap } from '../../../utils/getFilterData';
import DataService from '../../../lib/DataService';
const FilterSection = (props) => {

    const { t } = useTranslation()

    const [isOpenCollapse1, setIsOpenCollapse1] = useState(false);
    const toggleCollapse1 = () => {
        setIsOpenCollapse1(!isOpenCollapse1);
    }
    const [isOpenCollapse2, setIsOpenCollapse2] = useState(false);
    const toggleCollapse2 = () => {
        setIsOpenCollapse2(!isOpenCollapse2);
    }
    const [isOpenCollapse3, setIsOpenCollapse3] = useState(false);
    const toggleCollapse3 = () => {
        setIsOpenCollapse3(!isOpenCollapse3);
    }

    const { address } = useAccount()
    const params = useParams()
    const id = params.id

    const { postRequest } = DataService()



    const { hiddenstatus, sethiddenStatus, checkhiddenChain, setCheckhiddenChain, singlehiddencollectionList, contractAddresshidden, setContarctAddressHidden, hidecollection, setHideCOllection } = useContext(ThemeContext)


    const Status = [
        {
            id: 1,
            name: "All",
            sortStatusField: "",

        },
        {
            id: 2,
            name: "Listed",
            sortStatusField: "list",
        },
        {
            id: 3,
            name: "On Auction",
            sortStatusField: "auction",
        },
        {
            id: 4,
            name: "New",
            sortStatusField: "new",
        },
        {
            id: 5,
            name: "Has Offers",
            sortStatusField: "make-offer",
        }

    ]

    const FilterHiddenStatus = (data) => {
        sethiddenStatus(data.sortStatusField)
        if (data.sortStatusField == "") {
            sethiddenStatus([""])
        }
        else {
            let a = hiddenstatus.includes(data.sortStatusField)
            if (a == true) {
                const index = hiddenstatus.indexOf(data.sortStatusField);
                if (index > -1) {
                    hiddenstatus.splice(index, 1);
                    const nerArr = [...hiddenstatus]
                    sethiddenStatus(nerArr);
                    if (nerArr.length == 0) {
                        sethiddenStatus([""]);
                    }
                }

            } else if (a == false) {
                const index = hiddenstatus.indexOf("");
                if (index > -1) {
                    hiddenstatus.splice(index, 1);
                }
                sethiddenStatus(
                    [...hiddenstatus, data.sortStatusField]
                );

            }
        }

    }

    const Chains = [
        {
            id: 1,
            name: "BNB",
            value: "binance",
        },
        {
            id: 2,
            name: "ETH",
            value: "ethereum",
        },
        {
            id: 3,
            name: "MATIC",
            value: "polygon",
        }
    ]


    const handleCheckboxChange = (item) => {
        let a = checkhiddenChain.includes(item.value)

        if (a == true) {

            const index = checkhiddenChain.indexOf(item.value);
            if (index > -1) {
                checkhiddenChain.splice(index, 1);
                const nerArr = [...checkhiddenChain]
                setCheckhiddenChain(nerArr);
            }

        } else if (a == false) {
            setCheckhiddenChain(
                [...checkhiddenChain, item.value]
            );
        }
    }


    const handleOnKeyDowns = async (e) => {
        if (e.key == 'Enter' || e.key == "") {
            const searchcollection = await postRequest("user/getHidedCollectionNFT", { "address": id ? id : address, "filter": e.target.value })
            if (searchcollection.status == true) {
                setHideCOllection(searchcollection.data)
            }
            else {
                setHideCOllection("")
            }
        }
    }


    useEffect(() => {
        GetFilterCollection()
    }, [address, id])

    const GetFilterCollection = async () => {
        const getcollection = await postRequest("user/getHidedCollectionNFT", { "address": id ? id : address })
        if (getcollection.status == true) {
            setHideCOllection(getcollection.data)
        }
    }

    const singlelistingcollection = (data) => {

        let a = contractAddresshidden.some(item => item.contractAddress === data.contractAddress);

        if (a == true) {
            const updatedList = contractAddresshidden.filter(item => item.contractAddress !== data.contractAddress);
            setContarctAddressHidden(updatedList);
        } else if (a == false) {
            setContarctAddressHidden(
                [...contractAddresshidden, { contractAddress: data?.contractAddress, collectionName: data?.collectionName }]
            );
        }
    }



    return (
        <>
            <div className='filtercard'>
                <div>
                    <Button className='filterTit' onClick={toggleCollapse1}>
                        <span>{t("Status")} </span>
                        <i className={`fas ${isOpenCollapse1 ? 'fa-angle-up' : 'fa-angle-down'}`} ></i>
                    </Button>
                    <Collapse isOpen={isOpenCollapse1}>
                        <div className='collapseCnt'>
                            {Status && Status.map((data, index) => (
                                <button className={hiddenstatus.length > 0 ? hiddenstatus.includes(data.sortStatusField) ? 'filterStatusbtn active' : 'filterStatusbtn' : 'filterStatusbtn'} onClick={() => FilterHiddenStatus(data)}>{t(data.name)}</button>
                            ))}

                        </div>
                    </Collapse>
                </div>
                <div>
                    <Button className='filterTit' onClick={toggleCollapse2}>
                        <span>{t("Chains")} </span>
                        <i className={`fas ${isOpenCollapse2 ? 'fa-angle-up' : 'fa-angle-down'}`} ></i>
                    </Button>
                    <Collapse isOpen={isOpenCollapse2}>
                        <div className='collapseCnt'>
                            <div className='filterInnercard'>

                                {Chains && Chains.map((data, index) => (
                                    <div class="d-flex align-items-center mb-2" key={data.id} >
                                        <label class="cuschk mr-2 mb-0" For={`checkbx1${index + 1}`}>
                                            <input type="checkbox" id={`checkbx1${index + 1}`} name="chains" checked={checkhiddenChain.length > 0 ? checkhiddenChain.includes(data.value) : false} onChange={() => handleCheckboxChange(data)} />
                                            <span class="checkmark"></span>
                                        </label>
                                        <img src={data.value == "binance" ? BNBIcon : data.value == "ethereum" ? ETHIcon : data.value == "polygon" ? MATICIcon : ""} className="mr-1" width="20px" />
                                        <h3 className='mb-0 filterLabel' style={{ textTransform: "capitalize" }}>{data.value}</h3>
                                        <h3 className='mb-0 filterLabel dark-text-secondary ml-auto'>{data.name}</h3>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Collapse>
                </div>
                <div>
                    <Button className='filterTit' onClick={toggleCollapse3}>
                        <span>{t("Collection")} </span>
                        <i className={`fas ${isOpenCollapse3 ? 'fa-angle-up' : 'fa-angle-down'}`} ></i>
                    </Button>
                    <Collapse isOpen={isOpenCollapse3}>
                        <div className='collapseCnt'>
                            <div className="form-group formInputs">
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText className='px-2'>
                                            <svg
                                                width="17"
                                                height="17"
                                                viewBox="0 0 17 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M15.875 14.6562C16.0312 14.8125 16.0312 15.0625 15.875 15.1875L15.1562 15.9062C15.0312 16.0625 14.7812 16.0625 14.625 15.9062L10.8438 12.125C10.7812 12.0312 10.75 11.9375 10.75 11.8438V11.4375C9.59375 12.4062 8.125 13 6.5 13C2.90625 13 0 10.0938 0 6.5C0 2.9375 2.90625 0 6.5 0C10.0625 0 13 2.9375 13 6.5C13 8.125 12.375 9.625 11.4062 10.75H11.8125C11.9062 10.75 12 10.8125 12.0938 10.875L15.875 14.6562ZM6.5 11.5C9.25 11.5 11.5 9.28125 11.5 6.5C11.5 3.75 9.25 1.5 6.5 1.5C3.71875 1.5 1.5 3.75 1.5 6.5C1.5 9.28125 3.71875 11.5 6.5 11.5Z"
                                                    fill="white"
                                                />
                                            </svg>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder={t("Search collection")} name="searchInput" onKeyDown={handleOnKeyDowns} />
                                </InputGroup>
                            </div>
                            <div className='filterInnercard'>
                                <div class="scroller htcheckbox">
                                    {hidecollection && hidecollection.length > 0 ?
                                        hidecollection && hidecollection.map((data, index) => (
                                            <div className={`${singlehiddencollectionList.length > 0 ? singlehiddencollectionList.includes(data.collectionName) ? 'h-verCollection' : "filterofferscard notrans mb-2" : "filterofferscard notrans mb-2"}`} onClick={() => singlelistingcollection(data)} onKeyDown={(e) => {
                                                if (e.key === 'Enter' || e.key === ' ') {
                                                    singlelistingcollection(data)
                                                }
                                            }}
                                                role="button" tabIndex={0} style={{ cursor: "pointer" }}>
                                                <div className='d-flex align-items-center'>
                                                    {!data?.logoImage?.startsWith(process.env.S3_URL) && !data?.logoImage?.startsWith('ipfs') && data?.logoImage !== null ? <img src={data?.logoImage ? data?.logoImage : defaultImage} className="mr-2" height="36" width="36" style={{ objectFit: "cover", borderRadius: "10px" }} onError={handleImageError} /> : <img src={defaultImage} className="mr-2" height="36" width="36" style={{ objectFit: "cover", borderRadius: "10px" }} />}
                                                    <div>
                                                        <div className='fs-12 fw-600 dark-text'>
                                                            {data.collectionName}
                                                            <img src={data.verification == "Verified" ? yellowTick : ""} className="ml-1" />
                                                        </div>
                                                        <div>{t("Listed")}: {data?.listedNfts == "" ? 0 : data?.listedNfts?.toString()}/{data?.totalItems?.toString()}</div>
                                                    </div>
                                                    <div className='text-right ml-auto'>
                                                        <div><span className="whiteTxt">{t("Floor")}:</span> {data.floorPrice == "0" ? 0 : parseFloat(data?.floorPrice?.toFixed(6))} {nativeCoinMap[data.chain]}</div>
                                                    </div>
                                                </div>

                                            </div>
                                        ))
                                        :


                                        <p style={{ justifyContent: "center", display: "flex" }}>{t("No Data Found")}</p>}
                                </div>
                            </div>

                        </div>
                    </Collapse>
                </div>

            </div>
        </>
    );
}

export default FilterSection;