import {
	Modal,
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown
} from 'reactstrap'
import React, { useState, useEffect } from 'react'
import style from '../collection/collection.module.scss'
import classnames from 'classnames'

import nftBanner1 from './assets/nftBanner1.png'
import heart from './assets/heart.svg'
import refresh from './assets/refresh.svg'
import graph from './assets/graph.png'
import shareIcon from '../../assets/images/collection/shareIcon.svg'
import ellipsisV from '../../assets/images/collection/ellipsis-v.svg'
import pennant from '../../assets/images/collection/pennant.svg'
import userImg from '../../assets/images/collection/userImage.png'
import cart from '../../assets/images/cart.png'
import tag from '../../assets/images/tag.png'
import clock from '../../assets/images/clock.svg'
import transferIcon from './assets/transferIcon.svg'

import decreaseIcon from '../../assets/images/decreaseIcon.svg'
import increaseIcon from '../../assets/images/increaseIcon.svg'

import Itemimg1 from '../../assets/images/collection/Itemimg1.png'
import Itemimg2 from '../../assets/images/collection/Itemimg2.png'
import Itemimg3 from '../../assets/images/collection/Itemimg3.png'
import Itemimg4 from '../../assets/images/collection/Itemimg4.png'
import Itemimg5 from '../../assets/images/collection/Itemimg5.png'
import Itemimg6 from '../../assets/images/collection/Itemimg6.png'

import ItemimgSm1 from '../../assets/images/collection/Itemimg-sm1.png'
import ItemimgSm2 from '../../assets/images/collection/Itemimg-sm2.png'
import ItemimgSm3 from '../../assets/images/collection/Itemimg-sm3.png'
import ItemimgSm4 from '../../assets/images/collection/Itemimg-sm4.png'
import ItemimgSm5 from '../../assets/images/collection/Itemimg-sm5.png'
import ItemimgSm6 from '../../assets/images/collection/Itemimg-sm6.png'

import ETHIcon from '../../assets/images/collection/ETHIcon.png'
import shoppingcart from '../../assets/images/collection/shopping-cart.svg'
import MakeOfferModal from '../../components/makeOffer/makeOffer'
import BuyNFTModal from '../../components/buyNFT/buynftModal'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import defaultImg from '../../assets/images/header/userProfileImg.png'
import { useTranslation } from 'react-i18next'
import OwnedByModal from '../../components/modals/owned-by/ownedby'
import DataService from '../../lib/DataService'
const NftDetails = () => {

	const { t } = useTranslation()
	const [activeTab, setActiveTab] = useState('1')
	const { getRequest, postRequest } = DataService()

	const { id } = useParams()
	const toggle = tab => {
		if (activeTab !== tab) setActiveTab(tab)
	}

	const [activeTab1, setActiveTab1] = useState('1')
	const toggle1 = tab => {
		if (activeTab1 !== tab) setActiveTab1(tab)
	}

	const [modal, setModal] = useState(false)
	const toggle2 = () => setModal(!modal)

	const [makeOfferModal, setMakeOfferModal] = useState(false)
	const [buyNFTModal, setBuyNFTModal] = useState(false)
	const [ownedByModal, setOwnedByModal] = useState(true)

	const [nftData, setNftData] = useState()
	const fetchNft = async () => {
		const data = await getRequest(`user/get_single_NFT?id=${id}`)
		setNftData(data?.data)
	}

	useEffect(() => {
		fetchNft()
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		})
	}, [])

	return (
		<>
			<div className='withoutBannerHeaderPage'>

				<section class='TraSec'>
					<div class='container'>
						<div className='row'>
							<div className='col-lg-6 col-xl-5'>
								<div className='nftBannerCnt' onClick={toggle2} onKeyDown={(e) => {
									if (e.key === 'Enter' || e.key === ' ') {
										toggle2()
									}
								}}
									role="button" tabIndex={0}>
									<img src={nftData && nftData.asset} className='nftBanner' />
								</div>
								<div className='nftCard'>
									<div className='d-flex algign-items-center flex-wrap w-100'>
										<div className='d-flex flex-column'>
											<span className='cardLabel'>{t("Price")}</span>
											<div className='d-flex align-items-center mb-2'>
												<h3 className='mb-0 mr-2'>34.31 ETH</h3>
												<span className='cardLabel'>$53,941.95</span>
											</div>
										</div>
										<div className='btn darkBtn ml-auto d-flex justify-content-between'>
											<a>
												<img src={decreaseIcon} />
											</a>
											<span className='mx-3'>0.3</span>
											<a>
												<img src={increaseIcon} />
											</a>
										</div>
									</div>
									<div className='d-flex align-items-center flex-wrap'>
										<button
											className='btn gradientBtn btn160 mr-2 my-1'
											onClick={() => setBuyNFTModal(true)}
										>
											{t("Buy Now")}
										</button>
										<button className='btn gradientBtn mr-2 my-1'>
											<svg
												width='19'
												height='16'
												viewBox='0 0 19 16'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													d='M17.2188 2C17.7188 2 18.0625 2.46875 17.9688 2.9375L16.5625 9.4375C16.4688 9.78125 16.1875 10 15.8125 10H6.0625L6.34375 11.5H15.1562C15.6562 11.5 16 11.9688 15.9062 12.4375L15.875 12.5625C16.25 12.9375 16.5 13.4375 16.5 14C16.5 15.125 15.5938 16 14.5 16C13.375 16 12.5 15.125 12.5 14C12.5 13.6562 12.5938 13.3125 12.75 13H8.21875C8.375 13.3125 8.5 13.6562 8.5 14C8.5 15.125 7.59375 16 6.5 16C5.375 16 4.5 15.125 4.5 14C4.5 13.4688 4.6875 13 5.03125 12.625L2.875 1.5H0.375C0.15625 1.5 0 1.34375 0 1.125V0.375C0 0.1875 0.15625 0 0.375 0H3.5C3.84375 0 4.15625 0.28125 4.21875 0.625L4.5 2H17.2188ZM6.5 14.75C6.90625 14.75 7.25 14.4375 7.25 14C7.25 13.5938 6.90625 13.25 6.5 13.25C6.0625 13.25 5.75 13.5938 5.75 14C5.75 14.4375 6.0625 14.75 6.5 14.75ZM14.5 14.75C14.9062 14.75 15.25 14.4375 15.25 14C15.25 13.5938 14.9062 13.25 14.5 13.25C14.0625 13.25 13.75 13.5938 13.75 14C13.75 14.4375 14.0625 14.75 14.5 14.75ZM15.2188 8.5L16.3125 3.5H4.78125L5.75 8.5H15.2188Z'
													fill='#0A0A0B'
												/>
											</svg>
										</button>
										<button
											className='btn darkBtn my-1'
											onClick={() => setMakeOfferModal(true)}
										>
											<svg
												className='mr-2'
												width='19'
												height='8'
												viewBox='0 0 19 8'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													d='M17.7188 1.75C18.2188 2.1875 18.5 2.8125 18.4688 3.4375C18.4688 4.09375 18.1562 4.6875 17.625 5.0625L14.5 7.4375C14 7.8125 13.375 8 12.75 8H1C0.71875 8 0.5 7.78125 0.5 7.5V7C0.5 6.75 0.71875 6.5 1 6.5H12.75C13.0625 6.5 13.3438 6.40625 13.5938 6.21875L16.75 3.875C17.0625 3.625 17.0938 3.1875 16.75 2.90625C16.5312 2.71875 16.125 2.71875 15.875 2.90625L13.9688 4.34375C13.7188 4.53125 13.4375 4.625 13.125 4.625H8.90625C8.6875 4.625 8.5 4.4375 8.5 4.21875V4.1875C8.5 3.96875 8.6875 3.78125 8.90625 3.78125H10.7812C11.7188 3.78125 11.6875 2.5 10.8125 2.5H6.3125C5.75 2.5 5.21875 2.6875 4.78125 3.03125L3.5 4H1C0.71875 4 0.5 3.78125 0.5 3.5V3C0.5 2.75 0.71875 2.5 1 2.5H2.96875L3.875 1.84375C4.5625 1.3125 5.4375 1 6.3125 1H10.8125C12.0312 1 12.9688 1.9375 12.9688 3.125H13.0938L14.9688 1.71875C15.5625 1.25 16.75 0.9375 17.7188 1.75Z'
													fill='white'
												/>
											</svg>
											{t("Make an Offer")}
										</button>
									</div>
								</div>
							</div>

							<div className='col-lg-6 col-xl-7'>
								<div className='nftCard mb-3'>
									<div className='d-flex align-items-center w-100 mb-4 flex-wrap'>
										<h3 className='mb-0 mr-2'>
											{nftData ? nftData.nftName : 'Nft name'}
										</h3>
										<div className='d-flex align-items-center ml-auto'>
											<div className='d-flex align-items-center mr-2'>
												<img src={refresh} className='mr-2' />
												Refresh
											</div>
											<div
												className={`${style.iconBtn} iconBtn-h40 d-flex justify-content-center align-items-center mr-2`}
											>
												<img src={heart} />
											</div>
											<UncontrolledDropdown className='mr-2'>
												<DropdownToggle className={`${style.iconBtn} iconBtn-h40`}>
													<img src={shareIcon} alt='shareIcon' />
												</DropdownToggle>
												<DropdownMenu right className={style.dropdownMenu}>
													<DropdownItem className='mb-2'>
														<i class='fab fa-twitter-square mr-2'></i> Twitter
													</DropdownItem>
													<DropdownItem className='mb-2'>
														<i class='fab fa-telegram mr-2'></i> Telegram
													</DropdownItem>
													<DropdownItem className='mb-2'>
														<i class='fab fa-facebook mr-2'></i> Facebook
													</DropdownItem>
													<DropdownItem className='mb-2'>
														<i class='fas fa-envelope mr-2'></i> Email
													</DropdownItem>
													<DropdownItem className='mb-2'>
														<i class='fas fa-link mr-2'></i> Copy Link
													</DropdownItem>
												</DropdownMenu>
											</UncontrolledDropdown>

											<UncontrolledDropdown>
												<DropdownToggle className={`${style.iconBtn} iconBtn-h40`}>
													<img src={ellipsisV} alt='ellipsisV' />
												</DropdownToggle>
												<DropdownMenu right className={style.dropdownMenu}>
													<DropdownItem>
														<img src={pennant} alt='pennant' className='mr-2' />{' '}
														{t("Report")}
													</DropdownItem>
												</DropdownMenu>
											</UncontrolledDropdown>
										</div>
									</div>
									<div className='row w-100'>
										<div className='col-lg-6'>
											<div className='d-flex align-items-center'>
												<img
													src={nftData ? nftData?.collectionImage : userImg}
													height='44'
													className='mr-3'
												/>
												<div className='d-flex flex-column'>
													<span className='cardLabel'>Collection</span>
													<span className='cardValue d-flex align-items-center'>
														{nftData
															? nftData?.collectionName
															: 'Collection name'}{' '}
													</span>
												</div>
											</div>
										</div>

										<div className='col-lg-6'>
											<div className='d-flex align-items-center'>
												<img
													src='https://e7.pngegg.com/pngimages/442/477/png-clipart-computer-icons-user-profile-avatar-profile-heroes-profile-thumbnail.png'
													height='44'
													className='mr-3'
												/>
												<div className='d-flex flex-column'>
													<span className='cardLabel cursor' onClick={() => setOwnedByModal(true)}
														onKeyDown={(e) => {
															if (e.key === 'Enter' || e.key === ' ') {
																setOwnedByModal(true)
															}
														}}
														role="button" tabIndex={0}>{t("Owner")}</span>
													<span className='cardValue d-flex align-items-center'>
														{t("Unnamed")}
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className='nftCard detCard'>
									<Nav tabs className='darkTabs mb-3'>
										<NavItem>
											<NavLink
												className={classnames({ active: activeTab === '1' })}
												onClick={() => {
													toggle('1')
												}}
											>
												{t("Details")}
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({ active: activeTab === '2' })}
												onClick={() => {
													toggle('2')
												}}
											>
												{t("Offers")}
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({ active: activeTab === '3' })}
												onClick={() => {
													toggle('3')
												}}
											>
												{t("Traits")}
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({ active: activeTab === '4' })}
												onClick={() => {
													toggle('4')
												}}
											>
												{t("Listings")}
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({ active: activeTab === '5' })}
												onClick={() => {
													toggle('5')
												}}
											>
												{t("Price History")}
											</NavLink>
										</NavItem>
									</Nav>
									<TabContent activeTab={activeTab} className='w-100 scroller'>
										<TabPane tabId='1'>
											<div className='row'>
												<div className='col-sm-6'>
													<span className='tableLabel'>{t("Contract")}</span>
												</div>
												<div className='col-sm-6 text-sm-right'>
													<span className='tableValue yellowTxt'>
														{nftData
															? nftData.contractAddress
															: '0399kdjs...93i92'}
													</span>
												</div>
												<div className='col-sm-6'>
													<span className='tableLabel'>{t("Token ID")}</span>
												</div>
												<div className='col-sm-6 text-sm-right'>
													<span className='tableValue yellowTxt'>
														{nftData ? nftData.nftId : ''}
													</span>
												</div>
												<div className='col-sm-6'>
													<span className='tableLabel'>{t("Token Standard")}</span>
												</div>
												<div className='col-sm-6 text-sm-right'>
													<span className='tableValue'>{nftData ? nftData.contractStandard : 'ERC-1155'}</span>
												</div>
												<div className='col-sm-6'>
													<span className='tableLabel'>{t("Chain")}</span>
												</div>
												<div className='col-sm-6 text-sm-right'>
													<span className='tableValue'>
														{nftData ? nftData.chain : 'Ethereum'}
													</span>
												</div>
												<div className='col-sm-6'>
													<span className='tableLabel'>{t("Last Updated")}</span>
												</div>
												<div className='col-sm-6 text-sm-right'>
													<span className='tableValue'>
														{moment(nftData && nftData?.updatedAt).fromNow()}
													</span>
												</div>
												<div className='col-sm-6'>
													<span className='tableLabel'>{t("Creator Earnings")}</span>
												</div>
												<div className='col-sm-6 text-sm-right'>
													<span className='tableValue'>5%</span>
												</div>
											</div>
										</TabPane>
										<TabPane tabId='2'>
											<div className='table-responsive'>
												<table className='table table-borderless table-hover mb-0'>
													<thead className='trd_head'>
														<tr>
															<th>{t("Price")}</th>
															<th>{t("USD Price")}</th>
															<th>{t("Quantity")}</th>
															<th>{t("Floor Difference")}</th>
															<th>{t("Expiration")}</th>
															<th>{t("From")}</th>
														</tr>
													</thead>
													<tbody className='trdtable_body'>
														<tr>
															<td>34.31 ETH</td>
															<td>$53,941.95</td>
															<td>1</td>
															<td>7% below</td>
															<td>18 h ago</td>
															<td className='yellowTxt'>Phillip Kenter</td>
														</tr>
														<tr>
															<td>34.31 ETH</td>
															<td>$53,941.95</td>
															<td>1</td>
															<td>7% below</td>
															<td>18 h ago</td>
															<td className='yellowTxt'>Makenna George</td>
														</tr>
														<tr>
															<td>34.31 ETH</td>
															<td>$53,941.95</td>
															<td>1</td>
															<td>7% below</td>
															<td>18 h ago</td>
															<td className='yellowTxt'>Tiana Lubin</td>
														</tr>
														<tr>
															<td>34.31 ETH</td>
															<td>$53,941.95</td>
															<td>1</td>
															<td>7% below</td>
															<td>18 h ago</td>
															<td className='yellowTxt'>Allison Baptista</td>
														</tr>
														<tr>
															<td>34.31 ETH</td>
															<td>$53,941.95</td>
															<td>1</td>
															<td>7% below</td>
															<td>18 h ago</td>
															<td className='yellowTxt'>Mira Calzoni</td>
														</tr>
														<tr>
															<td>34.31 ETH</td>
															<td>$53,941.95</td>
															<td>1</td>
															<td>7% below</td>
															<td>18 h ago</td>
															<td className='yellowTxt'>Ryan Dorwart</td>
														</tr>
														<tr>
															<td>34.31 ETH</td>
															<td>$53,941.95</td>
															<td>1</td>
															<td>7% below</td>
															<td>18 h ago</td>
															<td className='yellowTxt'>Jaxson Franci</td>
														</tr>
														<tr>
															<td>34.31 ETH</td>
															<td>$53,941.95</td>
															<td>1</td>
															<td>7% below</td>
															<td>18 h ago</td>
															<td className='yellowTxt'>Zain Calzoni</td>
														</tr>
														<tr>
															<td>34.31 ETH</td>
															<td>$53,941.95</td>
															<td>1</td>
															<td>7% below</td>
															<td>18 h ago</td>
															<td className='yellowTxt'>Cheyenne Herwitz</td>
														</tr>
													</tbody>
												</table>
											</div>
										</TabPane>
										<TabPane tabId='3'>
											<div className='row'>
												<div className='col-sm-6 col-lg-4'>
													<div className='traitCnt'>
														<span className='cardLabel'>{t("Backgrounds")}</span>
														<div className='cardValue'>
															<span className='yellowTxt mr-2'>
																New Punk Blue
															</span>{' '}
															12%
														</div>
													</div>
												</div>
												<div className='col-sm-6 col-lg-4'>
													<div className='traitCnt'>
														<span className='cardLabel'>Clothes</span>
														<div className='cardValue'>
															<span className='yellowTxt mr-2'>Hip Hop</span> 1%
														</div>
													</div>
												</div>
												<div className='col-sm-6 col-lg-4'>
													<div className='traitCnt'>
														<span className='cardLabel'>Eyes</span>
														<div className='cardValue'>
															<span className='yellowTxt mr-2'>Coins</span> 5%
														</div>
													</div>
												</div>
												<div className='col-sm-6 col-lg-4'>
													<div className='traitCnt'>
														<span className='cardLabel'>Fur</span>
														<div className='cardValue'>
															<span className='yellowTxt mr-2'>Red</span> 5%
														</div>
													</div>
												</div>
												<div className='col-sm-6 col-lg-4'>
													<div className='traitCnt'>
														<span className='cardLabel'>Hat</span>
														<div className='cardValue'>
															<span className='yellowTxt mr-2'>Fez</span> 4%
														</div>
													</div>
												</div>
												<div className='col-sm-6 col-lg-4'>
													<div className='traitCnt'>
														<span className='cardLabel'>Mouth</span>
														<div className='cardValue'>
															<span className='yellowTxt mr-2'>
																Bored Cigarette
															</span>{' '}
															7%
														</div>
													</div>
												</div>
												<div className='col-sm-6 col-lg-4'>
													<div className='traitCnt'>
														<span className='cardLabel'>Special</span>
														<div className='cardValue'>
															<span className='yellowTxt mr-2'>Fire</span> 1%
														</div>
													</div>
												</div>
											</div>
										</TabPane>
										<TabPane tabId='4'>
											<div className='table-responsive'>
												<table className='table table-borderless table-hover mb-0'>
													<thead className='trd_head'>
														<tr>
															<th>Price</th>
															<th>USD Price</th>
															<th>Quantity</th>
															<th>From</th>
															<th></th>
														</tr>
													</thead>
													<tbody className='trdtable_body'>
														<tr>
															<td>34.31 ETH</td>
															<td>$53,941.95</td>
															<td>1</td>
															<td className='yellowTxt'>Phillip Kenter</td>
															<td className='text-right'>
																<button className='btn gradientBtn ml-auto'>
																	Buy Now
																</button>
															</td>
														</tr>
														<tr>
															<td>34.31 ETH</td>
															<td>$53,941.95</td>
															<td>1</td>
															<td className='yellowTxt'>Makenna George</td>
															<td className='text-right'>
																<button className='btn gradientBtn ml-auto'>
																	Buy Now
																</button>
															</td>
														</tr>
														<tr>
															<td>34.31 ETH</td>
															<td>$53,941.95</td>
															<td>1</td>
															<td className='yellowTxt'>Tiana Lubin</td>
															<td className='text-right'>
																<button className='btn gradientBtn ml-auto'>
																	Buy Now
																</button>
															</td>
														</tr>
														<tr>
															<td>34.31 ETH</td>
															<td>$53,941.95</td>
															<td>1</td>
															<td className='yellowTxt'>Allison Baptista</td>
															<td className='text-right'>
																<button className='btn gradientBtn ml-auto'>
																	Buy Now
																</button>
															</td>
														</tr>
														<tr>
															<td>34.31 ETH</td>
															<td>$53,941.95</td>
															<td>1</td>
															<td className='yellowTxt'>Mira Calzoni</td>
															<td className='text-right'>
																<button className='btn gradientBtn ml-auto'>
																	Buy Now
																</button>
															</td>
														</tr>
														<tr>
															<td>34.31 ETH</td>
															<td>$53,941.95</td>
															<td>1</td>
															<td className='yellowTxt'>Ryan Dorwart</td>
															<td className='text-right'>
																<button className='btn gradientBtn ml-auto'>
																	Buy Now
																</button>
															</td>
														</tr>
														<tr>
															<td>34.31 ETH</td>
															<td>$53,941.95</td>
															<td>1</td>
															<td className='yellowTxt'>Jaxson Franci</td>
															<td className='text-right'>
																<button className='btn gradientBtn ml-auto'>
																	Buy Now
																</button>
															</td>
														</tr>
														<tr>
															<td>34.31 ETH</td>
															<td>$53,941.95</td>
															<td>1</td>
															<td className='yellowTxt'>Zain Calzoni</td>
															<td className='text-right'>
																<button className='btn gradientBtn ml-auto'>
																	Buy Now
																</button>
															</td>
														</tr>
														<tr>
															<td>34.31 ETH</td>
															<td>$53,941.95</td>
															<td>1</td>
															<td className='yellowTxt'>Cheyenne Herwitz</td>
															<td className='text-right'>
																<button className='btn gradientBtn ml-auto'>
																	Buy Now
																</button>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</TabPane>
										<TabPane tabId='5'>
											<img src={graph} width='100%' height='100%' />
										</TabPane>
									</TabContent>
								</div>
							</div>

							<div className='col-12'>
								<div className='nftCard'>
									<h3 className='heading'>{t("Activity")}</h3>
									<Nav tabs>
										<NavItem>
											<NavLink
												className={classnames({ active: activeTab1 === '1' })}
												onClick={() => {
													toggle1('1')
												}}
											>
												All
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({ active: activeTab1 === '2' })}
												onClick={() => {
													toggle1('2')
												}}
											>
												Sales
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({ active: activeTab1 === '3' })}
												onClick={() => {
													toggle1('3')
												}}
											>
												Listings
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({ active: activeTab1 === '4' })}
												onClick={() => {
													toggle1('4')
												}}
											>
												Offers
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({ active: activeTab1 === '5' })}
												onClick={() => {
													toggle1('5')
												}}
											>
												Transfers
											</NavLink>
										</NavItem>
									</Nav>
									<TabContent activeTab={activeTab1} className='w-100'>
										<TabPane tabId='1'>
											<div className='row'>
												<div className='col-12'>
													<div className='table-responsive'>
														<table className='table table-borderless table-hover mb-0'>
															<thead className='trd_head'>
																<tr>
																	<th></th>
																	<th>Price</th>
																	<th style={{ width: "280px" }}>From</th>
																	<th style={{ width: "280px" }}>To</th>
																	<th>
																		<img src={clock} />
																	</th>
																</tr>
															</thead>
															<tbody className='trdtable_body'>
																<tr>
																	<td className='tableTag'>
																		<img src={cart} className='mr-2' /> Sale
																	</td>
																	<td>34.31 ETH</td>
																	<td className='yellowTxt'>Gustava Mango</td>
																	<td className='yellowTxt'>Gretchen Philips</td>
																	<td>3h</td>
																</tr>
																<tr>
																	<td className='tableTag'>
																		<img src={transferIcon} className='mr-2' />{' '}
																		Transfer
																	</td>
																	<td>3,5234 ETH</td>
																	<td className='yellowTxt'>Justin Carder</td>
																	<td className='yellowTxt'>Gustavo Mango</td>
																	<td>3h</td>
																</tr>
																<tr>
																	<td className='tableTag'>
																		<img src={tag} className='mr-2' /> List
																	</td>
																	<td>3,6432 ETH</td>
																	<td className='yellowTxt'>Erin Press</td>
																	<td className='yellowTxt'>Justin Carder</td>
																	<td>3h</td>
																</tr>
																<tr>
																	<td className='tableTag'>
																		<img src={tag} className='mr-2' /> List
																	</td>
																	<td>3,6432 ETH</td>
																	<td className='yellowTxt'>Tatiana Bator</td>
																	<td className='yellowTxt'>Erin Press</td>
																	<td>3h</td>
																</tr>
																<tr>
																	<td className='tableTag'>
																		<svg
																			className='mr-2'
																			width='19'
																			height='8'
																			viewBox='0 0 19 8'
																			fill='none'
																			xmlns='http://www.w3.org/2000/svg'
																		>
																			<path
																				d='M17.7188 1.75C18.2188 2.1875 18.5 2.8125 18.4688 3.4375C18.4688 4.09375 18.1562 4.6875 17.625 5.0625L14.5 7.4375C14 7.8125 13.375 8 12.75 8H1C0.71875 8 0.5 7.78125 0.5 7.5V7C0.5 6.75 0.71875 6.5 1 6.5H12.75C13.0625 6.5 13.3438 6.40625 13.5938 6.21875L16.75 3.875C17.0625 3.625 17.0938 3.1875 16.75 2.90625C16.5312 2.71875 16.125 2.71875 15.875 2.90625L13.9688 4.34375C13.7188 4.53125 13.4375 4.625 13.125 4.625H8.90625C8.6875 4.625 8.5 4.4375 8.5 4.21875V4.1875C8.5 3.96875 8.6875 3.78125 8.90625 3.78125H10.7812C11.7188 3.78125 11.6875 2.5 10.8125 2.5H6.3125C5.75 2.5 5.21875 2.6875 4.78125 3.03125L3.5 4H1C0.71875 4 0.5 3.78125 0.5 3.5V3C0.5 2.75 0.71875 2.5 1 2.5H2.96875L3.875 1.84375C4.5625 1.3125 5.4375 1 6.3125 1H10.8125C12.0312 1 12.9688 1.9375 12.9688 3.125H13.0938L14.9688 1.71875C15.5625 1.25 16.75 0.9375 17.7188 1.75Z'
																				fill='white'
																			/>
																		</svg>
																		Offer
																	</td>
																	<td>3,6456 ETH</td>
																	<td className='yellowTxt'>Chance Vaccaro</td>
																	<td className='yellowTxt'>Tatiana Bator</td>
																	<td>3h</td>
																</tr>
																<tr>
																	<td className='tableTag'>
																		<svg
																			className='mr-2'
																			width='19'
																			height='8'
																			viewBox='0 0 19 8'
																			fill='none'
																			xmlns='http://www.w3.org/2000/svg'
																		>
																			<path
																				d='M17.7188 1.75C18.2188 2.1875 18.5 2.8125 18.4688 3.4375C18.4688 4.09375 18.1562 4.6875 17.625 5.0625L14.5 7.4375C14 7.8125 13.375 8 12.75 8H1C0.71875 8 0.5 7.78125 0.5 7.5V7C0.5 6.75 0.71875 6.5 1 6.5H12.75C13.0625 6.5 13.3438 6.40625 13.5938 6.21875L16.75 3.875C17.0625 3.625 17.0938 3.1875 16.75 2.90625C16.5312 2.71875 16.125 2.71875 15.875 2.90625L13.9688 4.34375C13.7188 4.53125 13.4375 4.625 13.125 4.625H8.90625C8.6875 4.625 8.5 4.4375 8.5 4.21875V4.1875C8.5 3.96875 8.6875 3.78125 8.90625 3.78125H10.7812C11.7188 3.78125 11.6875 2.5 10.8125 2.5H6.3125C5.75 2.5 5.21875 2.6875 4.78125 3.03125L3.5 4H1C0.71875 4 0.5 3.78125 0.5 3.5V3C0.5 2.75 0.71875 2.5 1 2.5H2.96875L3.875 1.84375C4.5625 1.3125 5.4375 1 6.3125 1H10.8125C12.0312 1 12.9688 1.9375 12.9688 3.125H13.0938L14.9688 1.71875C15.5625 1.25 16.75 0.9375 17.7188 1.75Z'
																				fill='white'
																			/>
																		</svg>
																		Offer
																		<span className='text-danger ml-2'>
																			Expired
																		</span>
																	</td>
																	<td>3,7585 ETH</td>
																	<td className='yellowTxt'>
																		Carter Rhiel Madsen
																	</td>
																	<td className='yellowTxt'>Chance Vaccaro</td>
																	<td>3h</td>
																</tr>
																<tr>
																	<td className='tableTag'>
																		<img src={transferIcon} className='mr-2' />{' '}
																		Transfer
																	</td>
																	<td>3,8464 ETH</td>
																	<td className='yellowTxt'>Madelyn George</td>
																	<td className='yellowTxt'>
																		Carter Rhiel Madsen
																	</td>
																	<td>3h</td>
																</tr>
																<tr>
																	<td className='tableTag'>
																		<img src={tag} className='mr-2' /> List
																	</td>
																	<td>3,2562 ETH</td>
																	<td className='yellowTxt'>Livia Saris</td>
																	<td className='yellowTxt'>Madelyn George</td>
																	<td>3h</td>
																</tr>
																<tr>
																	<td className='tableTag'>
																		<svg
																			className='mr-2'
																			width='19'
																			height='8'
																			viewBox='0 0 19 8'
																			fill='none'
																			xmlns='http://www.w3.org/2000/svg'
																		>
																			<path
																				d='M17.7188 1.75C18.2188 2.1875 18.5 2.8125 18.4688 3.4375C18.4688 4.09375 18.1562 4.6875 17.625 5.0625L14.5 7.4375C14 7.8125 13.375 8 12.75 8H1C0.71875 8 0.5 7.78125 0.5 7.5V7C0.5 6.75 0.71875 6.5 1 6.5H12.75C13.0625 6.5 13.3438 6.40625 13.5938 6.21875L16.75 3.875C17.0625 3.625 17.0938 3.1875 16.75 2.90625C16.5312 2.71875 16.125 2.71875 15.875 2.90625L13.9688 4.34375C13.7188 4.53125 13.4375 4.625 13.125 4.625H8.90625C8.6875 4.625 8.5 4.4375 8.5 4.21875V4.1875C8.5 3.96875 8.6875 3.78125 8.90625 3.78125H10.7812C11.7188 3.78125 11.6875 2.5 10.8125 2.5H6.3125C5.75 2.5 5.21875 2.6875 4.78125 3.03125L3.5 4H1C0.71875 4 0.5 3.78125 0.5 3.5V3C0.5 2.75 0.71875 2.5 1 2.5H2.96875L3.875 1.84375C4.5625 1.3125 5.4375 1 6.3125 1H10.8125C12.0312 1 12.9688 1.9375 12.9688 3.125H13.0938L14.9688 1.71875C15.5625 1.25 16.75 0.9375 17.7188 1.75Z'
																				fill='white'
																			/>
																		</svg>
																		Offer
																		<span className='text-danger ml-2'>
																			Expired
																		</span>
																	</td>
																	<td>3,6742 ETH</td>
																	<td className='yellowTxt'>Omar Aminoff</td>
																	<td className='yellowTxt'>Livia Saris</td>
																	<td>3h</td>
																</tr>
																<tr>
																	<td className='tableTag'>
																		<img src={transferIcon} className='mr-2' />{' '}
																		Transfer
																	</td>
																	<td>3,9864 ETH</td>
																	<td className='yellowTxt'>Livia Saris</td>
																	<td className='yellowTxt'>Omar Aminoff</td>
																	<td>3h</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</TabPane>
										<TabPane tabId='2'>
											<div className='row'>
												<div className='col-12'>
													<div className='table-responsive'>
														<table className='table table-borderless table-hover mb-0'>
															<thead className='trd_head'>
																<tr>
																	<th></th>
																	<th>Price</th>
																	<th>From</th>
																	<th>To</th>
																	<th>
																		<img src={clock} />
																	</th>
																</tr>
															</thead>
															<tbody className='trdtable_body'>
																<tr>
																	<td className='tableTag'>
																		<img src={cart} className='mr-2' /> Sale
																	</td>
																	<td>34.31 ETH</td>
																	<td className='yellowTxt'>Gustava Mango</td>
																	<td className='yellowTxt'>Gretchen Philips</td>
																	<td>3h</td>
																</tr>
																<tr>
																	<td className='tableTag'>
																		<img src={cart} className='mr-2' /> Sale
																	</td>
																	<td>34.31 ETH</td>
																	<td className='yellowTxt'>Gustava Mango</td>
																	<td className='yellowTxt'>Gretchen Philips</td>
																	<td>3h</td>
																</tr>
																<tr>
																	<td className='tableTag'>
																		<img src={cart} className='mr-2' /> Sale
																	</td>
																	<td>34.31 ETH</td>
																	<td className='yellowTxt'>Gustava Mango</td>
																	<td className='yellowTxt'>Gretchen Philips</td>
																	<td>3h</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</TabPane>
										<TabPane tabId='3'>
											<div className='row'>
												<div className='col-12'>
													<div className='table-responsive'>
														<table className='table table-borderless table-hover mb-0'>
															<thead className='trd_head'>
																<tr>
																	<th></th>
																	<th>Price</th>
																	<th>From</th>
																	<th>To</th>
																	<th>
																		<img src={clock} />
																	</th>
																</tr>
															</thead>
															<tbody className='trdtable_body'>
																<tr>
																	<td className='tableTag'>
																		<img src={tag} className='mr-2' /> List
																	</td>
																	<td>3,6432 ETH</td>
																	<td className='yellowTxt'>Erin Press</td>
																	<td className='yellowTxt'>Justin Carder</td>
																	<td>3h</td>
																</tr>
																<tr>
																	<td className='tableTag'>
																		<img src={tag} className='mr-2' /> List
																	</td>
																	<td>3,6432 ETH</td>
																	<td className='yellowTxt'>Tatiana Bator</td>
																	<td className='yellowTxt'>Erin Press</td>
																	<td>3h</td>
																</tr>
																<tr>
																	<td className='tableTag'>
																		<img src={tag} className='mr-2' /> List
																	</td>
																	<td>3,2562 ETH</td>
																	<td className='yellowTxt'>Livia Saris</td>
																	<td className='yellowTxt'>Madelyn George</td>
																	<td>3h</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</TabPane>
										<TabPane tabId='4'>
											<div className='row'>
												<div className='col-12'>
													<div className='table-responsive'>
														<table className='table table-borderless table-hover mb-0'>
															<thead className='trd_head'>
																<tr>
																	<th></th>
																	<th>Price</th>
																	<th>From</th>
																	<th>To</th>
																	<th>
																		<img src={clock} />
																	</th>
																</tr>
															</thead>
															<tbody className='trdtable_body'>
																<tr>
																	<td className='tableTag'>
																		<svg
																			className='mr-2'
																			width='19'
																			height='8'
																			viewBox='0 0 19 8'
																			fill='none'
																			xmlns='http://www.w3.org/2000/svg'
																		>
																			<path
																				d='M17.7188 1.75C18.2188 2.1875 18.5 2.8125 18.4688 3.4375C18.4688 4.09375 18.1562 4.6875 17.625 5.0625L14.5 7.4375C14 7.8125 13.375 8 12.75 8H1C0.71875 8 0.5 7.78125 0.5 7.5V7C0.5 6.75 0.71875 6.5 1 6.5H12.75C13.0625 6.5 13.3438 6.40625 13.5938 6.21875L16.75 3.875C17.0625 3.625 17.0938 3.1875 16.75 2.90625C16.5312 2.71875 16.125 2.71875 15.875 2.90625L13.9688 4.34375C13.7188 4.53125 13.4375 4.625 13.125 4.625H8.90625C8.6875 4.625 8.5 4.4375 8.5 4.21875V4.1875C8.5 3.96875 8.6875 3.78125 8.90625 3.78125H10.7812C11.7188 3.78125 11.6875 2.5 10.8125 2.5H6.3125C5.75 2.5 5.21875 2.6875 4.78125 3.03125L3.5 4H1C0.71875 4 0.5 3.78125 0.5 3.5V3C0.5 2.75 0.71875 2.5 1 2.5H2.96875L3.875 1.84375C4.5625 1.3125 5.4375 1 6.3125 1H10.8125C12.0312 1 12.9688 1.9375 12.9688 3.125H13.0938L14.9688 1.71875C15.5625 1.25 16.75 0.9375 17.7188 1.75Z'
																				fill='white'
																			/>
																		</svg>
																		Offer
																	</td>
																	<td>3,6456 ETH</td>
																	<td className='yellowTxt'>Chance Vaccaro</td>
																	<td className='yellowTxt'>Tatiana Bator</td>
																	<td>3h</td>
																</tr>
																<tr>
																	<td className='tableTag'>
																		<svg
																			className='mr-2'
																			width='19'
																			height='8'
																			viewBox='0 0 19 8'
																			fill='none'
																			xmlns='http://www.w3.org/2000/svg'
																		>
																			<path
																				d='M17.7188 1.75C18.2188 2.1875 18.5 2.8125 18.4688 3.4375C18.4688 4.09375 18.1562 4.6875 17.625 5.0625L14.5 7.4375C14 7.8125 13.375 8 12.75 8H1C0.71875 8 0.5 7.78125 0.5 7.5V7C0.5 6.75 0.71875 6.5 1 6.5H12.75C13.0625 6.5 13.3438 6.40625 13.5938 6.21875L16.75 3.875C17.0625 3.625 17.0938 3.1875 16.75 2.90625C16.5312 2.71875 16.125 2.71875 15.875 2.90625L13.9688 4.34375C13.7188 4.53125 13.4375 4.625 13.125 4.625H8.90625C8.6875 4.625 8.5 4.4375 8.5 4.21875V4.1875C8.5 3.96875 8.6875 3.78125 8.90625 3.78125H10.7812C11.7188 3.78125 11.6875 2.5 10.8125 2.5H6.3125C5.75 2.5 5.21875 2.6875 4.78125 3.03125L3.5 4H1C0.71875 4 0.5 3.78125 0.5 3.5V3C0.5 2.75 0.71875 2.5 1 2.5H2.96875L3.875 1.84375C4.5625 1.3125 5.4375 1 6.3125 1H10.8125C12.0312 1 12.9688 1.9375 12.9688 3.125H13.0938L14.9688 1.71875C15.5625 1.25 16.75 0.9375 17.7188 1.75Z'
																				fill='white'
																			/>
																		</svg>
																		Offer
																		<span className='text-danger ml-2'>
																			Expired
																		</span>
																	</td>
																	<td>3,7585 ETH</td>
																	<td className='yellowTxt'>
																		Carter Rhiel Madsen
																	</td>
																	<td className='yellowTxt'>Chance Vaccaro</td>
																	<td>3h</td>
																</tr>
																<tr>
																	<td className='tableTag'>
																		<svg
																			className='mr-2'
																			width='19'
																			height='8'
																			viewBox='0 0 19 8'
																			fill='none'
																			xmlns='http://www.w3.org/2000/svg'
																		>
																			<path
																				d='M17.7188 1.75C18.2188 2.1875 18.5 2.8125 18.4688 3.4375C18.4688 4.09375 18.1562 4.6875 17.625 5.0625L14.5 7.4375C14 7.8125 13.375 8 12.75 8H1C0.71875 8 0.5 7.78125 0.5 7.5V7C0.5 6.75 0.71875 6.5 1 6.5H12.75C13.0625 6.5 13.3438 6.40625 13.5938 6.21875L16.75 3.875C17.0625 3.625 17.0938 3.1875 16.75 2.90625C16.5312 2.71875 16.125 2.71875 15.875 2.90625L13.9688 4.34375C13.7188 4.53125 13.4375 4.625 13.125 4.625H8.90625C8.6875 4.625 8.5 4.4375 8.5 4.21875V4.1875C8.5 3.96875 8.6875 3.78125 8.90625 3.78125H10.7812C11.7188 3.78125 11.6875 2.5 10.8125 2.5H6.3125C5.75 2.5 5.21875 2.6875 4.78125 3.03125L3.5 4H1C0.71875 4 0.5 3.78125 0.5 3.5V3C0.5 2.75 0.71875 2.5 1 2.5H2.96875L3.875 1.84375C4.5625 1.3125 5.4375 1 6.3125 1H10.8125C12.0312 1 12.9688 1.9375 12.9688 3.125H13.0938L14.9688 1.71875C15.5625 1.25 16.75 0.9375 17.7188 1.75Z'
																				fill='white'
																			/>
																		</svg>
																		Offer
																		<span className='text-danger ml-2'>
																			Expired
																		</span>
																	</td>
																	<td>3,6742 ETH</td>
																	<td className='yellowTxt'>Omar Aminoff</td>
																	<td className='yellowTxt'>Livia Saris</td>
																	<td>3h</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</TabPane>
										<TabPane tabId='5'>
											<div className='row'>
												<div className='col-12'>
													<div className='table-responsive'>
														<table className='table table-borderless table-hover mb-0'>
															<thead className='trd_head'>
																<tr>
																	<th></th>
																	<th>Price</th>
																	<th>From</th>
																	<th>To</th>
																	<th>
																		<img src={clock} />
																	</th>
																</tr>
															</thead>
															<tbody className='trdtable_body'>
																<tr>
																	<td className='tableTag'>
																		<img src={transferIcon} className='mr-2' />{' '}
																		Transfer
																	</td>
																	<td>3,5234 ETH</td>
																	<td className='yellowTxt'>Justin Carder</td>
																	<td className='yellowTxt'>Gustavo Mango</td>
																	<td>3h</td>
																</tr>
																<tr>
																	<td className='tableTag'>
																		<img src={transferIcon} className='mr-2' />{' '}
																		Transfer
																	</td>
																	<td>3,8464 ETH</td>
																	<td className='yellowTxt'>Madelyn George</td>
																	<td className='yellowTxt'>
																		Carter Rhiel Madsen
																	</td>
																	<td>3h</td>
																</tr>
																<tr>
																	<td className='tableTag'>
																		<img src={transferIcon} className='mr-2' />{' '}
																		Transfer
																	</td>
																	<td>3,9864 ETH</td>
																	<td className='yellowTxt'>Livia Saris</td>
																	<td className='yellowTxt'>Omar Aminoff</td>
																	<td>3h</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</TabPane>
									</TabContent>
								</div>
							</div>

							<div className='col-12 mt-3'>
								<div className='d-flex align-items-center mb-3'>
									<h3 className='gradientHeading'>More from this collection.</h3>
									<button className='btn darkBtn ml-auto'>View Collection</button>
								</div>
							</div>
							<div className='col-12 mt-3'>
								<div className='row'>
									<div className='col-sm-6 col-lg-3 col-xl-2'>
										<div className='gridItemCard'>
											<div className='gItemImg'>
												<img
													src={Itemimg1}
													alt='Itemimg1'
													className='img-fluid w-100 itemImage'
												/>
												<div className='hoverImgCnt'>
													<div className='d-flex justify-content-between '>
														<div>
															<img
																src={ETHIcon}
																alt='ETHIcon'
																className='cointypeImg'
															/>
														</div>
														<div className='alarmbadge'>
															<i className='fas fa-alarm-clock'></i> 6 Days left
														</div>
													</div>
													<div className='d-flex justify-content-between align-items-center'>
														<button
															className='buybowbtn rounded-pill'
															type='button'
														>
															Buy Now
														</button>
														<button className='cartBtnCircle'>
															<img src={shoppingcart} alt='shoppingcart' />
														</button>
													</div>
												</div>
											</div>
											<div className='botcntCard'>
												<div className='bottomSmImg'>
													<img src={ItemimgSm1} height={73} alt='ItemimgSm1' />
												</div>
												<div className='botcntTxt'>
													<div className='itemName'>Azuki #2341</div>
													<div className='d-flex justify-content-between'>
														<div className='itemPricetxt'>Price</div>
														<div className='itemPriceval'>1.4 ETH</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className='col-sm-6 col-lg-3 col-xl-2'>
										<div className='gridItemCard'>
											<div className='gItemImg'>
												<img
													src={Itemimg2}
													alt='Itemimg1'
													className='img-fluid w-100 itemImage'
												/>
												<div className='hoverImgCnt'>
													<div className='d-flex justify-content-between '>
														<div>
															<img
																src={ETHIcon}
																alt='ETHIcon'
																className='cointypeImg'
															/>
														</div>
														<div className='alarmbadge'>
															<i className='fas fa-alarm-clock'></i> 6 Days left
														</div>
													</div>
													<div className='d-flex justify-content-between align-items-center'>
														<button
															className='buybowbtn rounded-pill'
															type='button'
														>
															Buy Now
														</button>
														<button className='cartBtnCircle'>
															<img src={shoppingcart} alt='shoppingcart' />
														</button>
													</div>
												</div>
											</div>
											<div className='botcntCard'>
												<div className='bottomSmImg'>
													<img src={ItemimgSm2} height={73} alt='ItemimgSm1' />
												</div>
												<div className='botcntTxt'>
													<div className='itemName'>Azuki #2341</div>
													<div className='d-flex justify-content-between'>
														<div className='itemPricetxt'>Price</div>
														<div className='itemPriceval'>1.4 ETH</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className='col-sm-6 col-lg-3 col-xl-2'>
										<div className='gridItemCard'>
											<div className='gItemImg'>
												<img
													src={Itemimg3}
													alt='Itemimg1'
													className='img-fluid w-100 itemImage'
												/>
												<div className='hoverImgCnt'>
													<div className='d-flex justify-content-between '>
														<div>
															<img
																src={ETHIcon}
																alt='ETHIcon'
																className='cointypeImg'
															/>
														</div>
														<div className='alarmbadge'>
															<i className='fas fa-alarm-clock'></i> 6 Days left
														</div>
													</div>
													<div className='d-flex justify-content-between align-items-center'>
														<button
															className='buybowbtn rounded-pill'
															type='button'
														>
															Buy Now
														</button>
														<button className='cartBtnCircle'>
															<img src={shoppingcart} alt='shoppingcart' />
														</button>
													</div>
												</div>
											</div>
											<div className='botcntCard'>
												<div className='bottomSmImg'>
													<img src={ItemimgSm3} height={73} alt='ItemimgSm1' />
												</div>
												<div className='botcntTxt'>
													<div className='itemName'>Azuki #2341</div>
													<div className='d-flex justify-content-between'>
														<div className='itemPricetxt'>Price</div>
														<div className='itemPriceval'>1.4 ETH</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className='col-sm-6 col-lg-3 col-xl-2'>
										<div className='gridItemCard'>
											<div className='gItemImg'>
												<img
													src={Itemimg4}
													alt='Itemimg1'
													className='img-fluid w-100 itemImage'
												/>
												<div className='hoverImgCnt'>
													<div className='d-flex justify-content-between '>
														<div>
															<img
																src={ETHIcon}
																alt='ETHIcon'
																className='cointypeImg'
															/>
														</div>
														<div className='alarmbadge'>
															<i className='fas fa-alarm-clock'></i> 6 Days left
														</div>
													</div>
													<div className='d-flex justify-content-between align-items-center'>
														<button
															className='buybowbtn rounded-pill'
															type='button'
														>
															Buy Now
														</button>
														<button className='cartBtnCircle'>
															<img src={shoppingcart} alt='shoppingcart' />
														</button>
													</div>
												</div>
											</div>
											<div className='botcntCard'>
												<div className='bottomSmImg'>
													<img src={ItemimgSm4} height={73} alt='ItemimgSm1' />
												</div>
												<div className='botcntTxt'>
													<div className='itemName'>Azuki #2341</div>
													<div className='d-flex justify-content-between'>
														<div className='itemPricetxt'>Price</div>
														<div className='itemPriceval'>1.4 ETH</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className='col-sm-6 col-lg-3 col-xl-2'>
										<div className='gridItemCard'>
											<div className='gItemImg'>
												<img
													src={Itemimg5}
													alt='Itemimg1'
													className='img-fluid w-100 itemImage'
												/>
												<div className='hoverImgCnt'>
													<div className='d-flex justify-content-between '>
														<div>
															<img
																src={ETHIcon}
																alt='ETHIcon'
																className='cointypeImg'
															/>
														</div>
														<div className='alarmbadge'>
															<i className='fas fa-alarm-clock'></i> 6 Days left
														</div>
													</div>
													<div className='d-flex justify-content-between align-items-center'>
														<button
															className='buybowbtn rounded-pill'
															type='button'
														>
															Buy Now
														</button>
														<button className='cartBtnCircle'>
															<img src={shoppingcart} alt='shoppingcart' />
														</button>
													</div>
												</div>
											</div>
											<div className='botcntCard'>
												<div className='bottomSmImg'>
													<img src={ItemimgSm5} height={73} alt='ItemimgSm1' />
												</div>
												<div className='botcntTxt'>
													<div className='itemName'>Azuki #2341</div>
													<div className='d-flex justify-content-between'>
														<div className='itemPricetxt'>Price</div>
														<div className='itemPriceval'>1.4 ETH</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className='col-sm-6 col-lg-3 col-xl-2'>
										<div className='gridItemCard'>
											<div className='gItemImg'>
												<img
													src={Itemimg6}
													alt='Itemimg1'
													className='img-fluid w-100 itemImage'
												/>
												<div className='hoverImgCnt'>
													<div className='d-flex justify-content-between '>
														<div>
															<img
																src={ETHIcon}
																alt='ETHIcon'
																className='cointypeImg'
															/>
														</div>
														<div className='alarmbadge'>
															<i className='fas fa-alarm-clock'></i> 6 Days left
														</div>
													</div>
													<div className='d-flex justify-content-between align-items-center'>
														<button
															className='buybowbtn rounded-pill'
															type='button'
														>
															Buy Now
														</button>
														<button className='cartBtnCircle'>
															<img src={shoppingcart} alt='shoppingcart' />
														</button>
													</div>
												</div>
											</div>
											<div className='botcntCard'>
												<div className='bottomSmImg'>
													<img src={ItemimgSm6} height={73} alt='ItemimgSm1' />
												</div>
												<div className='botcntTxt'>
													<div className='itemName'>Azuki #2341</div>
													<div className='d-flex justify-content-between'>
														<div className='itemPricetxt'>Price</div>
														<div className='itemPriceval'>1.4 ETH</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<Modal
					isOpen={modal}
					toggle={toggle2}
					centered='true'
					className='curMdl lgMdl'
					backdropClassName='selCurBp'
					keyboard={false}
				>
					{!nftData?.asset?.startsWith(process.env.S3_URL) && !nftData?.asset?.startsWith('ipfs') && nftData?.asset !== null ? <img src={nftData?.asset ? nftData?.asset : nftBanner1} /> : <img src={defaultImg} />}
				</Modal>

				<MakeOfferModal
					modalOpen={makeOfferModal}
					closeModal={() => setMakeOfferModal(false)}
				/>
				<BuyNFTModal
					modalOpen={buyNFTModal}
					closeModal={() => setBuyNFTModal(false)}
				/>
				<OwnedByModal modalOpen={ownedByModal} closeModal={() => setOwnedByModal(false)} />
			</div>
		</>
	)
}
export default NftDetails
