import { useQuery } from '@tanstack/react-query'
import { useMutation } from 'wagmi'
import { useQueryClient } from '@tanstack/react-query'
import DataService from '../../../lib/DataService'

const { getRequest, postRequest } = DataService()
export const getSearchName = ({ name }) => {
	return getRequest(`home/search?search=${name}`)
}


export const useGetSearchName = ({ search }) => {
	const shouldFetchData = search.trim() !== '' && search !== '%20';

	return useQuery({
		queryKey: shouldFetchData ? ['nft_list', search] : null,
		queryFn: () => shouldFetchData ? getSearchName({ name: search }) : null,
	});
};

const getBlock = data => {
	return postRequest('admin/nft/update_report', { ...data })
}

export const useBlock = () => {

	return useMutation(getBlock, {
		onMutate: () => { },
		onError: error => { },
		onSuccess: getReport => {
			if (getReport.data.status === false) {

			}

			if (getReport.data.status === true) {
				useQueryClient.invalidateQueries(['block'])

			}
		}
	})
}