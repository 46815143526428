import { useQuery } from '@tanstack/react-query'

import DataService from '../../../lib/DataService'

const { getRequest } = DataService()
export async function fetchListings(
	collectionAddress,
	nftId,
	pageParam,
	address
) {
	

	const response = await getRequest(`asset/listings?collection=${collectionAddress}&id=${nftId}&page=${pageParam}&address=${address}`)

	return response.data
}

export function useFetchListings(
	collectionAddress,
	nftId,
	pageParam
) {
	return useQuery({
		queryKey: ['listings', collectionAddress, nftId, pageParam],
		queryFn: () => fetchListings(collectionAddress, nftId, pageParam),
		enabled: !!(collectionAddress && nftId)
	})
}
