import { useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import { CloseIcon } from '../buy-now/CloseIcon'
import { useCheckoutModalActions, useCheckoutModalState } from './store'

import '../modal.scss'

import yellowtick20 from '../../../assets/images/collection/yellow-tick_20px.svg'
import { handleImageError } from '../../../hooks/useHandleImg'
import defaultImage from '../../../assets/images/homeExplorer/placeholder.png'

function Nft({ nft }) {
	const [videoError, setVideoError] = useState()
	const isVideo = nft.details.asset.endsWith('.mp4')

	const handleVideoError = () => {
		setVideoError(true);
	};

	return (
		<div className='cartItem'>
			<div className='itemImg'>
				{!isVideo ? (
					typeof nft?.details?.asset == "string" && nft?.details?.asset !== undefined && !nft?.details?.asset?.startsWith(process.env.S3_URL) &&
						!nft?.details?.asset?.startsWith("ipfs") && nft?.details?.asset !== null ?
						<img
							className='object-cover'
							alt='asset'
							src={nft?.details?.asset ? nft?.details?.asset : defaultImage}
							onError={handleImageError}
						/> : <img
							className='object-cover'
							alt='asset'
							src={defaultImage}
						/>
				) : (
					videoError ? <img
						className='object-cover'
						alt='asset'
						src={defaultImage}
					/> :

						<video loop autoPlay muted playsInline preload='metadata' controlslist='nodownload' id='video-asset' style={{
							objectFit: 'unset',
							height: '84px',
							width: '84px',
							borderTopLeftRadius: '16px',
							borderBottomLeftRadius: '16px'
						}}>
							<source src={nft.details.asset ? nft.details.asset : defaultImage} type="video/mp4" onError={handleVideoError} />
						</video>

				)}
			</div>
			<div className='flex-grow-1 p-3'>
				<div className='d-flex justify-content-between mb-2'>
					<div className='itemName'>
						{nft.collection.name}{' '}
						{nft.collection.isVerified && (
							<img
								src={yellowtick20}
								alt='yellowtick20'
								className='ml-1'
							/>
						)}
					</div>
				</div>
				<div className='d-flex justify-content-between mb-n1'>
					<div className='itemId'>{nft.details.name}</div>
					<span className='itemBal'>{`${nft.details.price
						? parseFloat(nft.details.price.toFixed(8))
						: parseFloat(nft.bestOffer.price.toFixed(8))
						} ${nft.details.price
							? nft.details.currency
							: nft.bestOffer.currency
						}`}</span>
				</div>
			</div>
		</div>
	)
}

export function CheckoutModal() {
	const { t } = useTranslation()
	const { toggleCheckoutModal, closeCheckoutModal } =
		useCheckoutModalActions()
	const { isOpen, asset } = useCheckoutModalState()

	if (!asset) {
		return null
	}

	const formatNames = (data) => {
		if (data.length === 0) return ' '

		let formattedNames = '';

		for (let i = 0; i < data.length; i++) {
			formattedNames += data[i].details.name;

			if (i === data.length - 2) {
				formattedNames += ' and ';
			} else if (i < data.length - 1) {
				formattedNames += ', ';
			}
		}

		return formattedNames;
	};

	const NFTList = ({ names }) => {
		if (!names) {
			return null
		}

		const generateNFTsString = (names) => {
			if (names?.length !== 0) {
				if (names?.length === 1) {
					return `${names[0].details.name} !`;
				} else {
					return `${formatNames(names)} !`;
				}
			}
		};

		return <div>{generateNFTsString(names)}</div>;
	};

	return (
		<Modal
			isOpen={isOpen}
			centered='true'
			className='mymodal'
			backdropClassName='selCurBp'
			keyboard={false}
		>
			<ModalHeader
				close={<CloseIcon onClose={toggleCheckoutModal} customclass="closefix" />}
			><svg
				className='mb12px'
				width='40'
				height='40'
				viewBox='0 0 40 40'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
					<rect width='40' height='40' rx='12' fill='#00B976' />
					<path
						d='M25.5938 14.625C25.75 14.4688 26 14.4688 26.125 14.625L27.0312 15.5C27.1562 15.6562 27.1562 15.9062 27.0312 16.0312L17.6562 25.4062C17.5 25.5625 17.2812 25.5625 17.125 25.4062L12.9375 21.25C12.8125 21.0938 12.8125 20.8438 12.9375 20.7188L13.8438 19.8125C13.9688 19.6875 14.2188 19.6875 14.375 19.8125L17.375 22.8438L25.5938 14.625Z'
						fill='white'
					/>
				</svg>

				<h5 className='modal-title'>
					{t('Checkout success!')}
				</h5>
			</ModalHeader>
			<ModalBody className='mt-n4'>
				<div className='text-center'>

					<div className='subcheckTxt mb-3'>
						{t('You successfully purchased')}{' '}
						<span className='fw-600 dark-text'><NFTList names={asset} /></span>
					</div>
				</div>

				<div>
					{asset.map((nft) => (
						<Nft key={nft.id} nft={nft} />
					))}
				</div>

				<div className='mt-4' onClick={closeCheckoutModal} onKeyDown={(e) => {
					if (e.key === 'Enter' || e.key === ' ') {
						closeCheckoutModal();
					}
				}}
					role="button"
					tabIndex={0}>
					<button className='btn btn-block checkoutBtn'>
						{t('Okay')}
					</button>
				</div>
			</ModalBody>
		</Modal>
	)
}
