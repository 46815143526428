import { create } from 'zustand'

const useAcceptOfferModalStore = create((set) => ({
	isOpen: false,
	asset: null,
	actions: {
		toggleAcceptOfferModal: () =>
			set((state) => ({
				...state,
				isOpen: !state.isOpen
			})),
		setAsset: (asset) =>
			set((state) => ({
				...state,
				asset
			}))
	}
}))

export const useAcceptOfferModalState = () =>
	useAcceptOfferModalStore(({ isOpen, asset }) => ({
		isOpen,
		asset
	}))

export const useAcceptOfferModalActions = () =>
	useAcceptOfferModalStore((state) => state.actions)
