import React, { useContext, useEffect } from 'react';
import './helpcenter.scss';

import icon1 from '../../assets/images/helpcenter/stars.svg';
import icon2 from '../../assets/images/helpcenter/wallet.svg';
import icon3 from '../../assets/images/helpcenter/tag.svg';
import icon4 from '../../assets/images/helpcenter/magic.svg';
import icon5 from '../../assets/images/helpcenter/send-back.svg';
import icon6 from '../../assets/images/helpcenter/user.svg';
import icon7 from '../../assets/images/helpcenter/life-ring.svg';
import icon8 from '../../assets/images/helpcenter/shield-alt.svg';
import icon9 from '../../assets/images/helpcenter/code.svg';
import { useNavigate } from 'react-router-dom';
import { useOutletContext } from 'react-router-dom'
import noItemfoundImage from '../../assets/images/collection/noItemfoundImage.svg';
import cnddsLight from '../../assets/images/cnddsLight.svg'
import { ThemeContext } from '../context/context';
import { useTranslation } from 'react-i18next';

const HelpCenterMainPage = (props) => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [])

    const searchResult = (data) => {
        setHelpsearch("")
        navigate("/searchResult/" + data._id)
    }

    const titleImgIcon = [
        { "icon": icon1, "title": "Getting Started" },
        { "icon": icon2, "title": "Buying" },
        { "icon": icon3, "title": "Selling" },
        { "icon": icon4, "title": "Create an NFT" },
        { "icon": icon5, "title": "Drop a Collection" },
        { "icon": icon6, "title": "User Content" },
        { "icon": icon7, "title": "FaQ" },
        { "icon": icon8, "title": "User Safety" },
        { "icon": icon9, "title": "Developers" },
    ]


    const { helpsearch, setHelpsearch } = useContext(ThemeContext)

    const [getData, getCount] = useOutletContext()

    const navigate = useNavigate()
    const categoryactive = (data) => {
        setHelpsearch("")
        navigate("/categoryactive/" + data.name)
    }

    const { t } = useTranslation()


    const litstedTileLoop = getData && getData.map((item) => {
        return (

            helpsearch ?
                <>

                    {item.subHeading.map((question) => {
                        return (


                            <div className='col-lg-9 mx-auto'>
                                <div className="quesListItem" onClick={() => searchResult(question)} onKeyDown={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        searchResult(question)
                                    }
                                }}
                                    role="button" tabIndex={0} style={{ cursor: "pointer" }}>
                                    <div>{question.question}</div>
                                    <div>
                                        <i className="fas fa-chevron-right"></i>
                                    </div>
                                </div>
                            </div>)
                    })}
                </> :

                <div className='col-lg-4 col-md-4 col-sm-6' onClick={() => categoryactive(item)} onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        categoryactive(item)
                    }
                }}
                    role="button" tabIndex={0}>
                    <div className='tilebox'>
                        <div className='iconwizard'>
                            <img src={item.image} className='img-fluid' />
                        </div>
                        <div className='tileName'>
                            {item.name}
                        </div>
                    </div>
                </div>


        );
    });


    const closesearch = (e) => {
        setHelpsearch('')
        e.preventDefault()


    }


    return (
        <>
            <div className="container py-5">
                <div className="d-flex align-items-center justify-content-between">
                    {helpsearch ?
                        <div className='d-flex align-items-center fiterSelectlabel mb-4'>
                            <div className='tagLabel m-1'>
                                <span>{helpsearch}</span>
                                <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={(e) => closesearch(e)} onKeyDown={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        closesearch(e)
                                    }
                                }}
                                    role="button" tabIndex={0}></i>
                            </div>
                        </div>
                        :
                        ""
                    }
                    <div className="fs-14 fw-400 dark-text-secondary">
                        {helpsearch ?
                            <>
                                {getCount} {getCount ? getCount == 1 ? "result" : "results" : "results"}
                            </>
                            :
                            ""
                        }
                    </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='tileBoxmain'>

                            {getData && getData.length > 0 ?
                                <div className='row'>
                                    {litstedTileLoop}
                                </div>
                                :
                                <div className='row'>
                                    <div className="col-md-12">
                                        <div className='noItemFound'>
                                            <div className='text-center'>
                                                <img src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : noItemfoundImage} alt="noItemfoundImage" className='img-fluid' />
                                            </div>
                                            <div className='nofounttxt mb-4'>
                                                {helpsearch ? 'No items were found. Try to broaden your search.' : t('Nothing to display')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default HelpCenterMainPage;