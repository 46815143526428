

import React, { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom'
import { useAccount } from 'wagmi'
import { useParams } from 'react-router-dom';
import DataService from '../lib/DataService';
const PrivateRoutes = () => {

    const { address } = useAccount()
    const { id } = useParams()
    const [status, setStatus] = useState(true)
    const { postRequest } = DataService()

    useEffect(() => {
        isAuthenticated()
    }, [address])

    const [loading, setLoading] = useState(false);

    const isAuthenticated = async () => {
        setLoading(true)
        const checkReport = await postRequest(
            'home/check_user_reported',
            { address: id ? id : address }
        )
        if (checkReport.status == true) {
            setStatus(true)
            setLoading(false)
        } else if (checkReport.status == '404') {
            setStatus('404')
            setLoading(false)
        } else {
            setStatus(false)
            setLoading(false)
        }
    }

    return (
        <>
            {status && status == true ? <Outlet /> : status == '404' ? <Navigate to='/404' /> : <Navigate to="/" />}
        </>
    )
}

export default PrivateRoutes
