import React, { useState, useEffect } from 'react';

import './cmspages.scss';
import loader from '../../assets/images/Loader.png'
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';
import DataService from '../../lib/DataService';
const TermsPage = (props) => {

    const [loading, setLoading] = useState(false);
    const { t } = useTranslation()
	const { getRequest } = DataService()

    useEffect(() => {
        fetchterms()
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [])

    const [terms, setTerms] = useState("")

    const fetchterms = async () => {
        setLoading(true)
        const termsdata = await getRequest(`home/cms?title=Terms`)
        if (termsdata.status == true) {
            setLoading(false)
            setTerms(termsdata.data)
        }
        else {
            setLoading(false)
            setTerms("")
        }
    }

    useEffect(() => {

        document.title = 'Terms of Service | Wor NFT'
    }, [])

    return (
        <>
            <div className={`text-center ${loading ? 'spinner' : 'd-none'}`}>
                <img src={loader} alt='' className='spinAnimate' />
            </div>
            <div className="container">
                <div className='row justify-content-center'>
                    <div className="col-lg-9">
                        <div className='d-flex justify-content-between mb-4 flex-wrap align-items-center'>
                            <div className='cmsTit'>{t("Terms of Service")}</div>
                            <div className='cmsdateTxt'>{t("Last modification")}: {new Date(
                                terms ? terms && terms.updatedAt : ""
                            ).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</div>
                        </div>
                        {ReactHtmlParser(terms ? terms && terms.pagecontent : "")}
                    </div>
                </div>
            </div>
        </>
    )
}

export default TermsPage;