import React, { useEffect, useState } from 'react';
import {Modal} from 'reactstrap';
import '../../../src/views/Settings/Settings.scss';
import { useToast } from '../../utils/toast/customSuccess'
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DataService from '../../lib/DataService';
export const Unsubscribe = () => {

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const { t } = useTranslation()
    const { postRequest } = DataService()
    const toast = useToast()

    const navigate = useNavigate()

    const params = useParams();

    const data1 = {
        "_id": params.id,
    }

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        let data = await postRequest(`home/unSubscribe`, data1);
        if (data?.status == true) {
            toast.success(data.message)
            toggle()
        }
        else {
            toast.error(data.message)
            navigate("/")
        }
    }


    const myprofile = () => {
        navigate("/settingsnotification")
    }



    return (
        <>
            <Modal isOpen={modal} toggle={toggle} centered="true" className="curMdl createScsMdl" backdropClassName="selCurBp" backdrop='static' keyboard={false}>
                <div className="createContent">
                    <div className="w-100">
                        <h3 className="walletHeading my-2">{t("Your email was unsubscribed!.")}</h3>
                        <br />
                        <button type="button" className="btn btn-block gradientBtn" onClick={myprofile}>{t("View Notification")}</button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Unsubscribe;