import React, { useEffect, useState } from 'react';
import profileProfPic from '../../assets/images/profile/profileProfPic.png';
import yellowTick24px from '../../assets/images/collection/yellow-tick-24px.svg';
import shareIcon from '../../assets/images/collection/shareIcon.svg';
import ellipsisV from '../../assets/images/collection/ellipsis-v.svg';
import pennant from '../../assets/images/collection/pennant.svg';
import transferIcon from '../../assets/images/collection/transferIcon.svg';
import listIcon from '../../assets/images/collection/listIcon.svg';
import offerIcon from '../Profile/assets/bestOfferIcon.svg';
import selectIcon from '../Profile/assets/selectIcon.svg';
import hideIcon from '../Profile/assets/hideIcon.svg';
import copyIcon from '../Profile/assets/copyIcon.svg';
import settingsIcon from '../Profile/assets/settingsIcon.svg';
import copyClickIcon from '../../assets/images/copyIcon.svg';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledDropdown } from 'reactstrap';
import classnames from 'classnames';
import MyCollectionData from './MyCollectionData';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const MyCollection = () => {

  const { t } = useTranslation()
  const navigate = useNavigate()

  

  useEffect(() => {
    document.title = 'Create Collection | Wor NFT'
  }, [])


  return (
    <>
        
        
          <div className="">
            <MyCollectionData />
          </div>
    </>
  );
};

export default MyCollection;