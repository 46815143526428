import { useParams } from 'react-router-dom'
import { useEffect } from 'react'

import CollectionHeader from './CollectionHeader'
import Tabs from './Tabs'
import { useScollToTop } from '../../hooks/useScrollToTop'
import { useFetchCollectionDetails } from './apis/fetchCollectionDetails'
import { AssetNotFound } from '../nft/components/AssetNotFound'
import { Spinner } from '../../components/loader/spinner/Spinner'
import { useToggleClassName } from '../../hooks/useToggleClassName'

export default function Collection() {
	const params = useParams()
	const { data, isLoading } = useFetchCollectionDetails(
		params.collectionAddress
	)
	useScollToTop()
	useToggleClassName()

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}, [])

	if (isLoading) {
		return (
			<section class='TraSec'>
				<div class='container' style={{ height: '85vh ' }}>
					<div
						className='row align-items-center justify-content-center'
						style={{ height: '85vh ' }}
					>
						<Spinner />
					</div>
				</div>
			</section>
		)
	}

		if (data)
			document.title = `${data?.data?.collectionName} - Collection | Wor NFT`



	if (!data.status) {
		return <AssetNotFound />
	}

	return (
		<>
			<CollectionHeader collectionAddress={params.collectionAddress} />
			<Tabs
				collectionAddress={params.collectionAddress}
				collectionDetails={data}
			/>
		</>
	)
}
