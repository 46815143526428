import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Filters } from './components/Filters'
import { ItemsViewTabs } from './components/ItemsViewTabs'
import { ItemsViewTabsContent } from './components/ItemsViewTabsContent'
import { useFetchCollectionTraits } from './apis/fetchCollectionTraits'
import { parseQueryStringToObject } from '../../utils/parseQueryStringToObject'
import { convertObjectToQueryString } from '../../utils/convertObjectToQueryString'
import { flattenFilters } from '../../utils/flattenFilters'

import noItemfoundImage from '../../assets/images/collection/noItemfoundImage.svg'
import cnddsLight from '../../assets/images/cnddsLight.svg'
import { useTranslation } from 'react-i18next'

export function NoItemsFound({ displayText, onClick = () => {} }) {
	const { t } = useTranslation()

	return (
		<div className='noItemFound'>
			<div className='text-center'>
				<img
					src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : noItemfoundImage}
					alt='noItemfoundImage'
					className='img-fluid'
				/>
			</div>
			<div className='nofounttxt mb-4'>
				{displayText
					? displayText
					: t('No items were found. Try to broaden your search.')}
			</div>
			{!displayText && (
				<div>
					<button className='applyBtn' onClick={onClick}>
						{t("Back to all items")}
					</button>
				</div>
			)}
		</div>
	)
}

export function Items({ collectionAddress, collectionDetails }) {
	const [searchParams, setSearchParams] = useSearchParams()

	const [activeTab, setActiveTab] = useState('grid')
	const [showFilters, setShowFilters] = useState(true)
	const [filters, setFilters] = useState(() =>
		parseQueryStringToObject(searchParams)
	)

	const { data: traitsData } = useFetchCollectionTraits(collectionAddress)

	useEffect(() => {
		const filters = parseQueryStringToObject(searchParams)
		setFilters(filters)
	}, [setFilters, searchParams])

	const handleFiltersChange = (filters) => {
		setFilters(filters)
		const flattenedFilters = flattenFilters(filters)
		const queryString = convertObjectToQueryString(flattenedFilters)
		setSearchParams(queryString)
	}

	return (
		<Filters
			itemsViewTabsContent={
				<ItemsViewTabsContent
					activeTab={activeTab}
					showFilters={showFilters}
					collectionDetails={collectionDetails}
					filters={filters}
					handleFiltersChange={handleFiltersChange}
				/>
			}
			showFilters={showFilters}
			setShowFilters={setShowFilters}
			traits={traitsData}
			filters={filters}
			handleFiltersChange={handleFiltersChange}
			collectionDetails={collectionDetails}
		>
			<ItemsViewTabs
				activeTab={activeTab}
				setActiveTab={setActiveTab}
			></ItemsViewTabs>
		</Filters>
	)
}
