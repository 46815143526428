import { useAccount, usePublicClient } from 'wagmi'
import { parseUnits } from 'viem'

import { useMarketplaceContract } from './useContract'
import { axios } from '../lib/axios'
import { getOrder } from '../utils/getOrder'
import { useBuyNowModalState } from '../components/modals/buy-now/store'
import { useBuy } from '../features/nft/apis/buy'
import DataService from '../lib/DataService'
export const useBuyNow = (marketplaceContractAddress, chainId) => {
	const marketplaceContract = useMarketplaceContract(
		marketplaceContractAddress,
		chainId
	)
	const publicClient = usePublicClient({
		chainId
	})
	const { address: account } = useAccount()
	const { mutateAsync: buy } = useBuy()
	const { asset } = useBuyNowModalState()
	const { getRequest, postRequest } = DataService()

	const getSellArgs = async asset => {
		const royaltyFee = asset.details.royalty
		const royaltyReceipt = asset.details.creator
		const isStore = !asset.details.isMinted
		const uri = asset.details.uri

		const order = getOrder({
			asset,
			assetType: asset.details.contractStandard,
			orderType: 'sell'
		})

		try {
			const {
				data: { v, r, s }
			} = await postRequest('asset/buyer-fee-signature', {
				orderTuple: order,
				royalty: royaltyFee * 10,
				royaltyAddress: royaltyReceipt,
				network: asset.details.chain
			})

			const buyerFeeSig = [v, r, s]
			const args = [
				[order],
				[buyerFeeSig],
				[royaltyFee * 10],
				[royaltyReceipt],
				[isStore],
				[uri]
			]

			return args
		} catch (error) {
			throw new Error(error)
		}
	}

	const buyNow = async asset => {
		const value = parseFloat(
			parseUnits(asset.details.price.toString(), asset.details.decimals)
		).toString()

		try {
			const sellArgs = await getSellArgs(asset)

			const hash = await marketplaceContract.write.sell(sellArgs, {
				account,
				...(asset.details.isCoin ? { value } : {})
			})

			const transactionReceipt = await publicClient.waitForTransactionReceipt({
				hash
			})

			if (transactionReceipt.status === 'success') {
				const res = await buy(asset)
				if (res.status) {
					return transactionReceipt
				}
			}

			throw new Error('Something went wrong. Try again later')
		} catch (error) {
			throw new Error(error)
		}
	}

	return { getSellArgs, buyNow }
}
