import { useMemo } from 'react'
import { erc20ABI, usePublicClient, useWalletClient } from 'wagmi'

import { getContract } from '../utils/getContract'
import nftAbi from '../config/abi/nft.json'
import marketplaceAbi from '../config/abi/marketplace.json'
import { chainId } from '../config/constants/chains'
export function useContract(address, abi, chainid = chainId.ETHEREUM) {
	const { data: walletClient } = useWalletClient({
		chainid
	})
	const publicClient = usePublicClient({
		chainid
	})

	return useMemo(() => {
		if (!address || !abi) return null

		try {
			return getContract({
				abi,
				address,
				signer: walletClient ?? undefined,
				publicClient
			})
		} catch (error) {
			return null
		}
	}, [address, abi, walletClient, publicClient])
}

export function useTokenContract(tokenAddress, chainid) {
	return useContract(tokenAddress, erc20ABI, chainid)
}

export function useNftContract(address, chainid) {
	return useContract(address, nftAbi, chainid)
}

export function useMarketplaceContract(address, chainid) {
	return useContract(address, marketplaceAbi, chainid)
}
