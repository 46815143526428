import { create } from 'zustand'

const useBidPlacedSuccessfullyModalStore = create((set) => ({
	isOpen: false,
	asset: null,
	actions: {
		toggleBidPlacedSuccessfullyModal: () =>
			set((state) => ({
				...state,
				isOpen: !state.isOpen
			})),
		setAsset: (asset) =>
			set((state) => ({
				...state,
				asset
			}))
	}
}))

export const useBidPlacedSuccessfullyModalState = () =>
	useBidPlacedSuccessfullyModalStore(({ isOpen, asset }) => ({
		isOpen,
		asset
	}))

export const useBidPlacedSuccessfullyModalActions = () =>
	useBidPlacedSuccessfullyModalStore((state) => state.actions)
