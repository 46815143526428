import React, { useState, useEffect, useLayoutEffect, useRef, useContext } from 'react';

import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import filterIcon from '../../../assets/images/collection/filterIcon.svg';
import arrowLeftIcon from '../../../assets/images/collection/arrowLeftIcon.svg';

import FilterProfileOfferMade from './filterProfileOfferMade';
import ProfileOfferMade from './profileOfferMade';
import { ThemeContext } from '../../context/context';
import { useTranslation } from 'react-i18next';

const ProfileOfferMadeMain = () => {

  const [isFilterView, setfilterView] = useState(true)
  const [width, setWidth] = useState(window.innerWidth);
  const { t } = useTranslation()

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  useEffect(() => {
    if (width < 992) {
      setfilterView(false);
    } else {
      setfilterView(true);

    }
  }, [width]);

  const filterShow = () => {
    setfilterView(!isFilterView)
  }


  const ref = useRef(null)
  const [divWidth, setDivWidth] = useState('')
  const [divWidth1, setDivWidth1] = useState([window.innerWidth])

  const handleResize = () => {
    setDivWidth(ref.current.offsetWidth)
    setDivWidth1([window.innerWidth])
  }
  useEffect(() => {
    if (ref.current) window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [ref])

  useEffect(() => {
    const handleWindowResize = () => {
      setDivWidth1(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useLayoutEffect(() => {
    setDivWidth(ref.current.offsetWidth)
    setDivWidth1([window.innerWidth])
  }, [])

  const { offermadesearch, setOffermadeSearch } = useContext(ThemeContext)

  const handleOnKeyDown = (e) => {
    if (e.key == 'Enter' || e.key == '') {
      setOffermadeSearch(e.target.value)
    }
  }


  return (
    <>
      <div className='filterbtnshow mb-2' style={{ 'marginLeft': (((divWidth1[0] - divWidth) + 30) / 2) * -1 + 17 }}>
        <button className='filtershowhideBtn' onClick={filterShow} >
          {!isFilterView ? <>
            <img src={arrowLeftIcon} alt='arrowLeftIcon' />
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ 'position': 'absolute', 'right': '7px', 'marginTop': '2px' }}>
              <circle cx="5" cy="5" r="4" fill="url(#paint0_linear_462_183603)" stroke="#1D2025" stroke-width="2" />
              <defs>
                <linearGradient id="paint0_linear_462_183603" x1="1.54737" y1="1.46316" x2="8.41053" y2="8.78947" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FFA900" />
                  <stop offset="1" stop-color="#FFEDAE" />
                  <stop offset="1" stop-color="#FFF6A4" />
                </linearGradient>
              </defs>
            </svg>
          </> : <img src={filterIcon} alt='filterIcon' />}
        </button>
      </div>
      <div className="mb-5" ref={ref}>
        <div className="row">
          <div className="col-lg-12">
            <div className="itemWizard">
              <div className={`filterSection ${isFilterView ? 'show' : 'hide'}`} >
                <FilterProfileOfferMade />
              </div>
              <div className={`viewItemscard ${isFilterView ? 'collapseWidth' : 'fullWidth'}`}>
                <div className="form-inline">
                  <div className="form-group formInputs flex-grow-1">
                    <InputGroup className='mb-2'>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText className='px-2'>
                          <svg
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.875 14.6562C16.0312 14.8125 16.0312 15.0625 15.875 15.1875L15.1562 15.9062C15.0312 16.0625 14.7812 16.0625 14.625 15.9062L10.8438 12.125C10.7812 12.0312 10.75 11.9375 10.75 11.8438V11.4375C9.59375 12.4062 8.125 13 6.5 13C2.90625 13 0 10.0938 0 6.5C0 2.9375 2.90625 0 6.5 0C10.0625 0 13 2.9375 13 6.5C13 8.125 12.375 9.625 11.4062 10.75H11.8125C11.9062 10.75 12 10.8125 12.0938 10.875L15.875 14.6562ZM6.5 11.5C9.25 11.5 11.5 9.28125 11.5 6.5C11.5 3.75 9.25 1.5 6.5 1.5C3.71875 1.5 1.5 3.75 1.5 6.5C1.5 9.28125 3.71875 11.5 6.5 11.5Z"
                              fill="white"
                            />
                          </svg>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder={t("Search Items")} name="searchInput" onKeyDown={handleOnKeyDown} />
                    </InputGroup>
                  </div>
                </div>
                <div>
                  
                  <ProfileOfferMade />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileOfferMadeMain;