import { useQuery } from '@tanstack/react-query'
import DataService from '../../../lib/DataService'

const { getRequest, postRequest } = DataService()
async function fetchBids(orderId) {
	
	return getRequest(`asset/bids?id=${orderId}`)

}

export function useFetchBids(orderId) {
	return useQuery({
		queryKey: ['bids', orderId],
		queryFn: () => fetchBids(orderId),
		enabled: !!orderId
	})
}
