import { useContext } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { ThemeContext } from "../../../../views/context/context";

export const ExploreLoader = () => {
    const { themeClr } = useContext(ThemeContext)

    return (
        <div className="gridrow g-col-5">


            <SkeletonTheme
                baseColor="#202020"
                highlightColor="#444"
                width={'100%'}
            >


                <div className="grid-col">
                    <div className={themeClr ? 'gridItemExplorerCardWht ' : 'gridItemExplorerCard '}>
                        <div className="gItemImg">
                            <Skeleton variant="rounded" width={'100%'} height={168} className="d-block" containerClassName="skeltonWidth" />
                            <div className='botcntCard'>
                                <div className='botcntTxt'>
                                    <div className='itemsmName'>
                                        <Skeleton variant="rounded" sx={{ fontSize: '1rem' }} />
                                        <Skeleton variant="rounded" sx={{ fontSize: '1rem' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-col">
                    <div className={themeClr ? 'gridItemExplorerCardWht ' : 'gridItemExplorerCard '}>
                        <div className="gItemImg">
                            <Skeleton variant="rounded" width={'100%'} height={168} className="d-block" containerClassName="skeltonWidth" />
                            <div className='botcntCard'>
                                <div className='botcntTxt'>
                                    <div className='itemsmName'>
                                        <Skeleton variant="rounded" sx={{ fontSize: '1rem' }} />
                                        <Skeleton variant="rounded" sx={{ fontSize: '1rem' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-col">
                    <div className={themeClr ? 'gridItemExplorerCardWht ' : 'gridItemExplorerCard '}>
                        <div className="gItemImg">
                            <Skeleton variant="rounded" width={'100%'} height={168} className="d-block" containerClassName="skeltonWidth" />
                            <div className='botcntCard'>
                                <div className='botcntTxt'>
                                    <div className='itemsmName'>
                                        <Skeleton variant="rounded" sx={{ fontSize: '1rem' }} />
                                        <Skeleton variant="rounded" sx={{ fontSize: '1rem' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-col">
                    <div className={themeClr ? 'gridItemExplorerCardWht ' : 'gridItemExplorerCard '}>
                        <div className="gItemImg">
                            <Skeleton variant="rounded" width={'100%'} height={168} className="d-block" containerClassName="skeltonWidth" />
                            <div className='botcntCard'>
                                <div className='botcntTxt'>
                                    <div className='itemsmName'>
                                        <Skeleton variant="rounded" sx={{ fontSize: '1rem' }} />
                                        <Skeleton variant="rounded" sx={{ fontSize: '1rem' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-col">
                    <div className={themeClr ? 'gridItemExplorerCardWht ' : 'gridItemExplorerCard '}>
                        <div className="gItemImg">
                            <Skeleton variant="rounded" width={'100%'} height={168} className="d-block" containerClassName="skeltonWidth" />
                            <div className='botcntCard'>
                                <div className='botcntTxt'>
                                    <div className='itemsmName'>
                                        <Skeleton variant="rounded" sx={{ fontSize: '1rem' }} />
                                        <Skeleton variant="rounded" sx={{ fontSize: '1rem' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </SkeletonTheme>
        </div>

    )
}