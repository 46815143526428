import React, { createContext, useState } from "react";

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("");
  const [blockchain, setBlockChain] = useState("")
  const [themeClr, setThemeClr] = useState(false)

  //Profile Created
  const [createfilter, setCreateFilter] = useState("Recently Received")
  const [createsortFieldfilter, setCreatesortFieldFilter] = useState("_id")
  const [createsortOrderfilter, setCreatesortOrderFilter] = useState("desc")
  const [createdsearch, setCreatedSearch] = useState("")
  const [createstatus, setCreateStatus] = useState([''])
  const [createSortStatusField, setCreateSortStatusField] = useState("")
  const [createSortStatus, setCreateSortStatus] = useState("")
  const [checkcreateChain, setcreateCheckChain] = useState([])
  const [checkcreatecollection, setCheckcreateCollection] = useState([])
  const [checkcreatetype, setCheckcreateType] = useState("")


  //Profile Activity Listing

  const [activelistingcollectionsearch, setActivelistingCollectionSearch] = useState("")
  const [chooselistingchain, setListingChain] = useState("")
  const [minAmount, setMinAmount] = useState("")
  const [maxAmount, setMaxAmount] = useState("")
  const [singlecollectionList, setSingleCollectionList] = useState([])
  const [status, setStatus] = useState(false)
  const [collection, setCollection] = useState()

  //Profile Activity 

  const [activitystatus, setActivityStatus] = useState(["all"])
  const [checkactivityChain, setactivityCheckChain] = useState([])
  const [checkactivitycollection, setCheckactivityCollection] = useState([])

  //Profile Collected

  const [collectedsearch, setCollectedSearch] = useState('')
  const [collectedfilter, setCollectedFilter] = useState('Recently Received')
  const [collectedstatus, setcollectedStatus] = useState([''])
  const [checkcollectedChain, setcollectedCheckChain] = useState([])
  const [singlecollectedcollectionList, setSingleCollectedCollectionList] = useState([])

  const [chooselistingcollectedchain, setListingCollectedChain] = useState('')
  const [collectedminAmount, setCollectedMinAmount] = useState('')
  const [collectedmaxAmount, setCollectedMaxAmount] = useState('')
  const [Collectedstatus, setCollectedStatus] = useState(false)

  const [createdfiltersortby, setCreatedFilterSortby] = useState('_id')
  const [createdfiltersortorder, setCreatedFilterSortorder] = useState('desc')
  const [contractAddresscollected, setContarctAddressCollected] = useState([])
  const [checkcollectedCurrency, setcollectedCheckCurrency] = useState([])

  const [filtercollection, setFiltercollection] = useState('')

  //Explorer

  const [exploreTab, setExploreTab] = useState("")
  const [exploreChain, setExploreChain] = useState([])
  const [explorecollection, setExploreCollection] = useState([])
  const [exploreSearch, setExploreSearch] = useState("")

  const [activeTab, setActiveTab] = useState('1');
  const [exploreStatus, setExploreStatus] = useState([''])
  const [exploreSortStatusField, setExploreSortStatusField] = useState("")


  const [chooseexplorecollectedchain, setexploreCollectedChain] = useState('')
  const [exploreminAmount, setExploreMinAmount] = useState('')
  const [exploremaxAmount, setExploreMaxAmount] = useState('')
  const [explorepriestatus, setExploredPriceStatus] = useState(false)
  const [createdcollectionsearch, setCollectionSearch] = useState("")


  //WatchList

  const [activeTab4, setActiveTab4] = useState('1');
  const [watchlistTab, setWatchlistTab] = useState("")

  //Favourites

  const [activeTab5, setActiveTab5] = useState('1');
  const [favouriteTab, setFavouriteTab] = useState("")

  //OfferReceived

  const [activeTab6, setActiveTab6] = useState('1');
  const [offerreceivedTab, setOfferReceivedTab] = useState("")

  const [offerCollection, setOfferCollection] = useState([])
  const [offersearchCollection, setOfferSearchCollection] = useState('')

  //offerMade
  const [offermadestatus, setOfferMadeStatus] = useState([""])
  const [offermadetype, setOfferMadeType] = useState([""])
  const [offermadecollection, setOfferMadeCollection] = useState("")
  const [offermadesearch, setOffermadeSearch] = useState("")

  //hidden
  const [hiddensearch, setHiddensearch] = useState('')
  const [hiddenfilter, setHiddenFilter] = useState('Recently Received')
  const [hiddenstatus, sethiddenStatus] = useState([''])
  const [checkhiddencollection, setCheckhiddenCollection] = useState([])
  const [checkhiddenChain, setCheckhiddenChain] = useState([])
  const [singlehiddencollectionList, setSingleCollectedHiddenList] = useState([])

  const [hiddenfiltersortby, setHiddenFilterSortby] = useState('_id')
  const [hiddenfiltersortorder, setHiddenFilterSortorder] = useState('desc')
  const [chooselistinghiddenchain, setListingHiddenChain] = useState('')
  const [hiddenminAmount, setHiddenMinAmount] = useState('')
  const [hiddenmaxAmount, setHiddenMaxAmount] = useState('')
  const [Hiddenstatus, setHiddenStatus] = useState(false)
  const [hidecollection, setHideCOllection] = useState('')


  const [contractAddresshidden, setContarctAddressHidden] = useState([])


  //help center

  const [helpsearch, setHelpsearch] = useState("")
  const [socialmedia, setSocialMedia] = useState("")

  //Analytic Fikter

  const [Analyticsfilter, setAnalyticsFilter] = useState("")


  const [repstatus, setRepstatus] = useState(true)
  const resetState = () => {
    setTheme("");
    setBlockChain("");
    setThemeClr(false);

    // Profile Created
    setCreateFilter("Recently Received");
    setCreatesortFieldFilter("_id");
    setCreatesortOrderFilter("desc");
    setCreatedSearch("");
    setCreateStatus(['']);
    setCreateSortStatusField("");
    setCreateSortStatus("");
    setcreateCheckChain([]);
    setCheckcreateCollection([]);
    setCheckcreateType("");

    // Profile Activity Listing
    setActivelistingCollectionSearch("");
    setListingChain("");
    setMinAmount("");
    setMaxAmount("");
    setSingleCollectionList([]);
    setStatus(false);
    setCollection();

    // Profile Activity
    setActivityStatus(["all"]);
    setactivityCheckChain([]);
    setCheckactivityCollection([]);

    // Profile Collected
    setCollectedSearch('');
    setCollectedFilter('Recently Received');
    setcollectedStatus(['']);
    setcollectedCheckChain([]);
    setSingleCollectedCollectionList([]);
    setListingCollectedChain('');
    setCollectedMinAmount('');
    setCollectedMaxAmount('');
    setCollectedStatus(false);
    setCreatedFilterSortby('_id');
    setCreatedFilterSortorder('desc');
    setContarctAddressCollected([]);
    setcollectedCheckCurrency([]);
    setFiltercollection('')

    // Explorer
    setExploreTab("");
    setExploreChain([]);
    setExploreCollection([]);
    setExploreSearch("");
    setActiveTab('1');
    setExploreStatus(['']);
    setExploreSortStatusField("");
    setexploreCollectedChain("")
    setExploreMinAmount("")
    setExploreMaxAmount("")
    setExploredPriceStatus(false)
    setCollectionSearch('')

    // WatchList
    setActiveTab4('1');
    setWatchlistTab("");

    // Favourites
    setActiveTab5('1');
    setFavouriteTab("");

    // OfferReceived
    setActiveTab6('1');
    setOfferReceivedTab("");
    setOfferCollection([]);
    setOfferSearchCollection('');

    // OfferMade
    setOfferMadeStatus([""]);
    setOfferMadeType([""]);
    setOfferMadeCollection("");
    setOffermadeSearch("");


    // Help Center
    setHelpsearch("");
    setSocialMedia("");

    // Analytic Filter
    setAnalyticsFilter("");
    setRepstatus(true);

    //Hidden 
    setHiddensearch('')
    setHiddenFilter('Recently Received')
    setHideCOllection('')
    sethiddenStatus([''])
    setCheckhiddenCollection([])
    setCheckhiddenChain([])
    setSingleCollectedHiddenList([])
    setHiddenFilterSortby('_id')
    setHiddenFilterSortorder('desc')
    setListingHiddenChain('')
    setHiddenMinAmount('')
    setHiddenMaxAmount('')
    setHiddenStatus(false)
  };

  return (
    <ThemeContext.Provider value={{
      theme, setTheme, blockchain, setBlockChain, createfilter, setCreateFilter, createsortFieldfilter, setCreatesortFieldFilter, createsortOrderfilter, setCreatesortOrderFilter, createdsearch, setCreatedSearch, createstatus, setCreateStatus, createSortStatusField, activeTab, setActiveTab, activeTab5, setActiveTab5, favouriteTab, setFavouriteTab, themeClr, setThemeClr, socialmedia, setSocialMedia, exploreStatus, setExploreStatus, exploreSortStatusField, setExploreSortStatusField,
      setCreateSortStatusField, createSortStatus, setCreateSortStatus, checkcreateChain, setcreateCheckChain, checkcreatecollection, setCheckcreateCollection, activelistingcollectionsearch, setActivelistingCollectionSearch, chooselistingchain, setListingChain, minAmount, setMinAmount, maxAmount, setMaxAmount, singlecollectionList, setSingleCollectionList, status, setStatus, activitystatus, setActivityStatus, checkactivityChain, setactivityCheckChain, checkactivitycollection, setCheckactivityCollection, exploreTab, setExploreTab, exploreChain, setExploreChain, explorecollection, setExploreCollection, collectedsearch, setCollectedSearch, collectedfilter, setCollectedFilter, collectedstatus, setcollectedStatus, checkcollectedChain, setcollectedCheckChain, singlecollectedcollectionList, setSingleCollectedCollectionList, chooselistingcollectedchain, setListingCollectedChain, collectedminAmount, setCollectedMinAmount, collectedmaxAmount, setCollectedMaxAmount, Collectedstatus, setCollectedStatus, exploreSearch, setExploreSearch, activeTab4, setActiveTab4, watchlistTab, setWatchlistTab, checkcreatetype, setCheckcreateType, createdfiltersortby, setCreatedFilterSortby, createdfiltersortorder, setCreatedFilterSortorder, activeTab6, setActiveTab6, offerreceivedTab, setOfferReceivedTab, offerCollection, setOfferCollection, offersearchCollection, setOfferSearchCollection, offermadestatus, setOfferMadeStatus, offermadetype, setOfferMadeType, offermadecollection, setOfferMadeCollection, offermadesearch, setOffermadeSearch, helpsearch, setHelpsearch, Analyticsfilter, setAnalyticsFilter, repstatus, setRepstatus, contractAddresscollected, setContarctAddressCollected, collection, setCollection, hiddensearch, setHiddensearch, hiddenfilter, setHiddenFilter, hiddenstatus, sethiddenStatus, checkhiddenChain, setCheckhiddenChain, singlehiddencollectionList, setSingleCollectedHiddenList
      , chooselistinghiddenchain, setListingHiddenChain, hiddenminAmount, setHiddenMinAmount, hiddenmaxAmount, setHiddenMaxAmount, Hiddenstatus, setHiddenStatus, contractAddresshidden, setContarctAddressHidden, checkhiddencollection, setCheckhiddenCollection, hiddenfiltersortby, setHiddenFilterSortby, hiddenfiltersortorder, setHiddenFilterSortorder
      , checkcollectedCurrency, setcollectedCheckCurrency, filtercollection, setFiltercollection, hidecollection, setHideCOllection,chooseexplorecollectedchain, setexploreCollectedChain,exploreminAmount, setExploreMinAmount,exploremaxAmount, setExploreMaxAmount, explorepriestatus, setExploredPriceStatus,createdcollectionsearch, setCollectionSearch,resetState
    }}>
      {children}
    </ThemeContext.Provider>
  );
}; 