import { useQuery } from '@tanstack/react-query'
import DataService from '../../../lib/DataService'

const { getRequest } = DataService()
function fetchCollectionDetails(collectionAddress) {
	
	return getRequest(`collection/details?address=${collectionAddress}`)

}

export function useFetchCollectionDetails(collectionAddress) {
	return useQuery({
		queryKey: ['collection', collectionAddress],
		queryFn: () => fetchCollectionDetails(collectionAddress),
		enabled: !!collectionAddress
	})
}
