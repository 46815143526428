import { TabContent, TabPane } from 'reactstrap'

import CollectionNfts from './CollectionNfts'

export function ItemsViewTabsContent({
	activeTab,
	showFilters,
	collectionDetails,
	filters,
	handleFiltersChange
}) {
	return (
		<TabContent activeTab={activeTab}>
			<TabPane tabId='grid'>
				<CollectionNfts
					view={activeTab}
					showFilters={showFilters}
					collectionDetails={collectionDetails}
					filters={filters}
					handleFiltersChange={handleFiltersChange}
				/>
			</TabPane>
			<TabPane tabId='list'>
				<CollectionNfts
					view={activeTab}
					showFilters={showFilters}
					collectionDetails={collectionDetails}
					filters={filters}
					handleFiltersChange={handleFiltersChange}
				/>
			</TabPane>
		</TabContent>
	)
}
