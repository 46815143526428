import ETHIcon from '../assets/images/collection/ETHIcon.png'
import BNBIcon from '../assets/images/bnbIconSm.png'
import MaticIcon from '../assets/images/maticIconSm.png'
import { chainId } from '../config/constants/chains'

export const chainIconMap = {
	ethereum: ETHIcon,
	binance: BNBIcon,
	polygon: MaticIcon
}

export const chainIdMap = {
	ethereum: chainId.ETHEREUM,
	binance: chainId.BINANCE,
	polygon: chainId.POLYGON
}

export const chainMap = {
	[chainId.ETHEREUM]: 'ethereum',
	[chainId.BINANCE]: 'binance',
	[chainId.POLYGON]: 'polygon'
}

export const nativeCoinMap = {
	ethereum: 'ETH',
	binance: 'BNB',
	polygon: 'MATIC'
}


export const nativeCoinMap1 = {
	ETH: 'ETH',
	BNB: 'BNB',
	MATIC: 'MATIC',
	WOR: "WOR",
	BUSD: "BUSD",
	USDT: "USDT",
	WETH: "WETH",
	WBNB: "WBNB",
	WMATIC: "WMATIC"
}

export const nativecoin = {
	ethereum: 'WETH',
	binance: 'WBNB',
	polygon: 'WMATIC'
}