import { AuctionStation } from './AuctionStation'
import { BuyNowStation } from './BuyNowStation'
import { ListStation } from './ListStation'

export function AssetTradeStation({ asset }) {
	let content

	if (!asset.details.isMarketplace) {
		content = <ListStation asset={asset} />
	} else if (asset.details.saleType === 'auction') {
		content = <AuctionStation asset={asset} />
	} else {
		content = <BuyNowStation asset={asset} />
	}

	return <div className='nftCard'>{content}</div>
}
