import structuredClone from '@ungap/structured-clone'

export const INITIAL_FILTER_STATE = {
	search: {
		status: null,
		owner: null,
		paymentAssets: null,
		priceFilter: null,
		stringTraits: null,
		sortAscending: 'true',
		sortBy: null,
		query: null
	}
}

export const parseQueryStringToObject = (queryString) => {
	const params = new URLSearchParams(queryString)
	const result = structuredClone(INITIAL_FILTER_STATE)

	for (const [key, value] of params.entries()) {
		const keys = key.split(/\]\[|\[|\]/).filter(Boolean)

		let currentObject = result
		keys.forEach((keyPart, index) => {
			if (index === keys.length - 1) {
				currentObject[keyPart] = value
			} else {
				currentObject[keyPart] = currentObject[keyPart] || {}
				currentObject = currentObject[keyPart]
			}
		})
	}

	return result
}
