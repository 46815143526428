import React, { useEffect, useState } from 'react';
import { Modal, UncontrolledPopover, PopoverBody } from 'reactstrap';
import IconModal from '../../assets/images/IconModal.png';
import copyIcon from '../../assets/images/copyIcon.svg';
import Pencil from '../../assets/images/Pencil.svg';
import twitter from '../../assets/images/twitterNew.svg';
import instagram from '../../assets/images/instagram.svg';
import globe from '../../assets/images/globe.svg';
import infoIcon from '../../assets/images/infoIcon.svg';
import checkIcon from '../../assets/images/toast/checkIcon.svg';
import yellowTick20px from '../../assets/images/collection/yellow-tick_20px.svg';
import '../MyCollection/myCollection.scss';
import '../../assets/scss/mycollecion_mint.scss'
import './Settings.scss';
import {
  useAccount,
} from 'wagmi'
import copy from "copy-to-clipboard";
import { useToast } from '../../utils/toast/customSuccess'
import reject from './decline.png'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import loader from '../../assets/images/Loader.png'
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input';
import DataService from '../../lib/DataService'
import 'react-phone-number-input/style.css';
export const SettingsProfile = (props) => {
  const { postformdataRequest, getRequest, postRequest } = DataService()
  const { t } = useTranslation()

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);

  const CheckTick = () => {
    return (
      <>
        <svg className="ml-auto" width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.1953 0.46875C10.3125 0.351562 10.5 0.351562 10.5938 0.46875L11.2734 1.125C11.3672 1.24219 11.3672 1.42969 11.2734 1.52344L4.24219 8.55469C4.125 8.67188 3.96094 8.67188 3.84375 8.55469L0.703125 5.4375C0.609375 5.32031 0.609375 5.13281 0.703125 5.03906L1.38281 4.35938C1.47656 4.26562 1.66406 4.26562 1.78125 4.35938L4.03125 6.63281L10.1953 0.46875Z" fill="#FFA900" />
        </svg>
      </>
    )
  }

  const [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#ffffff");
  const toast = useToast()

  const { address, isConnected } = useAccount()

  const [copyText, setCopyText] = useState(address ? address : "");

  const copyToClipboard = () => {
    copy(copyText);
    toast.success(t("Address Copied"));
  }

  const [profileimage, setProfileIamge] = useState()
  const [profilecoverimage, setProfileCoverIamge] = useState()
  const [profileimgtype, setProfileImgtype] = useState("")
  const [coverimgtype, setCoverImgtype] = useState("")
  const [username, setUsername] = useState("")
  const [bio, setBio] = useState("")
  const [email, setEmail] = useState("")
  const [weburl, setWebUrl] = useState("")
  const [emailverify, setEmailVerify] = useState("")
  const [Logoverify, setLogoVerify] = useState("")
  const [twitterverify, setTwitterVerify] = useState("")
  const [nameVerify, setnameVerify] = useState("")
  const [wholeVerify, setWholeVerify] = useState("")
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState('US');
  const [countryMaxLength, setCountryMaxLength] = useState();
  const [checkPhone, setCheckPhone] = useState(false)

  useEffect(() => {
    const validLengths = parsePhoneNumber('+' + selectedCountry)?.lengths || [15];
    const maxLength = Math.max(...validLengths);
    setCountryMaxLength(maxLength);
    setCheckPhone(true)
  }, [selectedCountry]);

  const handleChange = (value) => {
    if (value !== null && typeof value === "string") {
      setPhoneNumber(value);
      setIsValid(!isValidPhoneNumber(value));
      if (!isValidPhoneNumber(value)) {
        setIsValid(false)
        setCheckPhone(false)
      }
    } else if (value == undefined) {
      setCheckPhone(true)
      setIsValid(false)
    } else {
      setPhoneNumber('')
      setIsValid(true)
    }
  };

  const handleCountryChange = (newCountry) => {
    setSelectedCountry(newCountry);
    setIsValid(false)
  };


  const handleKeyDown = (event) => {
    if (event.key === 'Backspace') {
      if (phoneNumber === "" && phoneNumber == undefined) {
        event.preventDefault();
        setIsValid(false)
        setPhoneNumber('');
      }
    }
  };

  useEffect(() => {
    GetProfile()

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [address])

  const [getprofile, setGetProfile] = useState("")

  const GetProfile = async () => {
    setLoading(true)
    const update = await postRequest("user/getSingleProfile", { "address": address ? address : "" })
    if (update.status == true) {
      setGetProfile(update.data)
      setProfileIamge(update.data.logo)
      props.setProfilePic(update.data.logo)
      props.setUsername(update.data.name)
      setProfileCoverIamge(update.data.cover)
      setCoverImgtype(update.data.coverImageType)
      setProfileImgtype(update.data.logoImageType)
      setUsername(update.data.name)
      setBio(update.data.bio)
      setEmail(update.data.email)
      setWebUrl(update.data.website)
      setEmailVerify(update.data.emailVerify)
      setLogoVerify(update.data.logoVerify)
      setTwitterVerify(update.data.twitterVerify)
      setnameVerify(update.data.nameVerify)
      setWholeVerify(update.data.verification)
      setTwitter(update.data.twitter)
      setInstagram(update.data.instagram)
      setPhoneNumber(update.data.phoneNumber)

      // data()
      setLoading(false)
    }
    else {
      setLoading(false)
      setGetProfile("")
      setProfileIamge("")
      setProfileCoverIamge("")
      setCoverImgtype("")
      setProfileImgtype("")
      setUsername("")
      setBio("")
      setEmail("")
      setWebUrl("")
      setTwitter("")
      setInstagram("")
      setEmailVerify("")
      setLogoVerify("")
      setTwitterVerify("")
      setnameVerify("")
      setWholeVerify("")
      setPhoneNumber("")
    }
  }


  let imageFile;
  let imageFile1;

  const imageupload = (e) => {
    imageFile = e.target.files[0];
    let size = parseFloat(e.target.files[0]?.size);
    let maxSizeKB = 2000;
    let maxSize = 100 * 1024 * 1024;
    if (imageFile != undefined) {
      if (size < maxSize) {
        if (!imageFile.name.match(/\.(webp|jpg|jpeg|png|gif)$/)) {
          document.getElementById('uploadimage').innerHTML = 'Please select valid image for WEBP,PNG,GIF,JPEG'
        }
        else if (imageFile == "" || imageFile == undefined) {
          setProfileIamge('')
          document.getElementById('uploadimage').innerHTML = 'Please choose profile image'
        }
        else {
          const reader = new FileReader()
          reader.onload = e => {
            const img = new Image()
            img.onload = () => {
              const width = img.naturalWidth;
              const height = img.naturalHeight;
              if (width >= 1400 && height >= 360) {
                setProfileIamge(imageFile);
                setProfileImgtype(imageFile.type)
                document.getElementById('uploadimage').innerHTML = ''
              }
              else {
                setProfileIamge(undefined);
                setProfileImgtype('')
                document.getElementById('uploadimage').innerHTML =
                  'Use image size height 360 pixels, width 1400 pixels.';
              }
            }
            img.onerror = () => {
              document.getElementById('uploadimage').innerHTML =
                'Unsupported image file'
              setProfileIamge(undefined)
              setProfileImgtype('')
            }
            img.src = e.target.result
          }
          reader.readAsDataURL(imageFile)
        }
      }
      else {
        document.getElementById('uploadimage').innerHTML = 'Maximum file size is 100mb'
      }
    }
    else {

    }
  }

  const imageupload1 = (e) => {
    imageFile1 = e.target.files[0];
    let size = parseFloat(e.target.files[0]?.size);
    let maxSizeKB = 2000;
    let maxSize = 100 * 1024 * 1024;
    if (imageFile1 != undefined) {
      if (size < maxSize) {
        if (!imageFile1.name.match(/\.(webp|png|jpg|jpeg|gif|mp4)$/)) {
          document.getElementById('uploadcoverimage').innerHTML = 'Please select valid image for WEBP,GIF,PNG,MP4'
        }
        else if (imageFile1 == "" || imageFile1 == undefined) {
          setProfileCoverIamge('')
          document.getElementById('uploadcoverimage').innerHTML = 'Please choose cover image'
        }
        else {
          if (imageFile1.type === 'video/mp4') {
            setProfileCoverIamge(imageFile1);
            setCoverImgtype(imageFile1.type)
          }
          else {
            const reader = new FileReader()
            reader.onload = e => {
              const img = new Image()
              img.onload = () => {
                const width = img.naturalWidth;
                const height = img.naturalHeight;
                if (width >= 1400 && height >= 360) {
                  setProfileCoverIamge(imageFile1);
                  setCoverImgtype(imageFile1.type)
                  document.getElementById('uploadcoverimage').innerHTML = ''
                }
                else {
                  setProfileCoverIamge(undefined);
                  setCoverImgtype('')
                  document.getElementById('uploadcoverimage').innerHTML =
                    'Use image size height 360 pixels, width 1400 pixels.';
                }
              }
              img.onerror = () => {
                document.getElementById('uploadcoverimage').innerHTML =
                  'Unsupported image file'
                setProfileCoverIamge(undefined)
                setCoverImgtype('')
              }
              img.src = e.target.result
            }
            reader.readAsDataURL(imageFile1)
          }
        }
      }
      else {
        document.getElementById('uploadcoverimage').innerHTML = 'Maximum file size is 100mb'
      }
    }
    else {

    }
  }


  const setUsername1 = (e) => {

    if (e.target.value == "" || e.target.value == undefined) {
      setUsername("")
      document.getElementById('username1').innerHTML = 'Please enter user name'
    }
    else if (e.target.value.length < 3) {
      setUsername(e.target.value)
      document.getElementById('username1').innerHTML = 'User Name atleast 3 characters'
    }
    else {
      setUsername(e.target.value)
      document.getElementById('username1').innerHTML = ''
    }
  }
  let regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

  const setEmail1 = (e) => {
    setEmail(e.target.value)
  }

  const setWebUrl1 = (e) => {
    setWebUrl(e.target.value)
  }

  const [Instagram, setInstagram] = useState("")
  const [Twitter, setTwitter] = useState("")

  let twireg = /^(?:https?:\/\/)?(?:www\.)?(twitter|x)\.com\/(#!\/)?[a-zA-Z0-9_]+$/


  const setTwitter1 = (e) => {
    setTwitter(e.target.value)
  }

  const navigate = useNavigate()


  const updateprofile = async () => {

    setLoading(true)
    if (profileimage == "undefined" && profilecoverimage == "undefined" && username == "" && weburl == "") {
      document.getElementById('uploadimage').innerHTML = 'Please choose profile image'
      document.getElementById('uploadcoverimage').innerHTML = 'Please choose cover image'
      document.getElementById('username1').innerHTML = 'Please enter user name'
      document.getElementById('weburl1').innerHTML = 'Please enter url'
      setLoading(false)
    }
    else if (profileimage == "undefined" || profileimage == "") {
      document.getElementById('uploadimage').innerHTML = 'Please choose profile image'
      setLoading(false)
    }
    else if (profilecoverimage == "undefined" || profilecoverimage == "") {
      document.getElementById('uploadcoverimage').innerHTML = 'Please choose cover image'
      setLoading(false)
    }
    else if (username == "" || username == "undefined") {
      document.getElementById('username1').innerHTML = 'Please enter user name'
      setLoading(false)
    }
    else if (username < 3) {
      document.getElementById('username1').innerHTML = 'User Name atleast 3 characters'
      setLoading(false)
    }
    else {
      const formData = new FormData()
      formData.append('address', address.toLowerCase())
      formData.append('name', username)
      formData.append('logo', profileimage)
      formData.append('cover', profilecoverimage)
      formData.append('bio', bio)
      formData.append('email', email)
      formData.append('twitter', Twitter)
      formData.append('instagram', Instagram)
      formData.append('website', weburl)
      formData.append('emailVerify', emailverify)
      formData.append('coverImageType', coverimgtype)
      formData.append('logoImageType', profileimgtype)
      formData.append('phoneNumber', phoneNumber)


      const update = await postformdataRequest("user/update-profile", formData)
      if (update.status == true) {
        toast.success(update.message)
        props.setProfilePic(update.data.logo)
        props.setUsername(update.data.name)
        setLoading(false)
        setTimeout(() => {
          navigate("/profile")
        })
      }
      else {
        toast.error(update.message)
        setLoading(false)
      }
    }

  }

  const Emailverify = async () => {
    setLoading(true)
    const emailVerify = await postRequest("user/get_email_url", { "email": email, "address": address })
    if (emailVerify.status == true) {
      toast.success(emailVerify.message)
      setLoading(false)
      const formData = new FormData()
      formData.append('address', address.toLowerCase())
      formData.append('name', username)
      formData.append('logo', profileimage)
      formData.append('cover', profilecoverimage)
      formData.append('bio', bio)
      formData.append('email', email)
      formData.append('twitter', Twitter)
      formData.append('instagram', Instagram)
      formData.append('website', weburl)
      formData.append('emailVerify', emailverify)
      formData.append('coverImageType', coverimgtype)
      formData.append('logoImageType', profileimgtype)
      formData.append('phoneNumber', phoneNumber)
      const update = await postformdataRequest("user/update-profile", formData)
      GetProfile()
    }
    else {
      toast.error(emailVerify.message)
      setLoading(false)
    }
  }

  const Verify = async () => {
    setLoading(true)

    const setVerifyStatus = (profileValue) => {
      switch (profileValue) {
        case "":
        case "Rejected":
          return "Pending";
        case "Verified":
          return "Verified";
        case "Pending":
          return "Pending";
        default:
          return "";
      }
    };

    const twitterVerifyValue = (Twitter === "" || Twitter === undefined) ? "" : setVerifyStatus(getprofile.twitterVerify);
    const nameVerifyValue = setVerifyStatus(getprofile.nameVerify);
    const logoVerifyValue = setVerifyStatus(getprofile.logoVerify);
    const wholeVerifyValue = setVerifyStatus(getprofile.verification);

    const formData = new FormData()
    formData.append('address', address.toLowerCase())
    formData.append('name', username)
    formData.append('logo', profileimage)
    formData.append('verification', wholeVerifyValue)
    formData.append('twitterVerify', twitterVerifyValue)
    formData.append('nameVerify', nameVerifyValue)
    formData.append('logoVerify', logoVerifyValue)
    formData.append('twitter', Twitter)
    const GetVerified = await postformdataRequest("user/getVerified", formData)
    if (GetVerified.status == true) {
      toast.success(GetVerified.message)
      setLoading(false)
      GetProfile()
    }
    else {
      toast.error(GetVerified.message)
      setLoading(false)
    }
  };



  const isVerified = (status) => status === 'Verified';
  const isRejected = (status) => status === 'Rejected';

  const handlekeydown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      Verify()
    }
  }

  const renderGetVerifiedButton = () => {
    if (getprofile.logoVerify === "" && getprofile.nameVerify === "" && username.length >= 3) {
      return (
        <div className="getVerified ml-auto mt-2" style={{ cursor: "pointer" }} onClick={Verify} onKeyDown={handlekeydown}
          role="button" tabIndex={0}>
          <p>{wholeVerify === "" ? t("Get Verified") : isRejected(wholeVerify) ? t("Get Verified") : t(wholeVerify)}</p>
        </div>
      );
    }

    const renderRejectedButton = (verifyType) => {
      return (
        <div className="getVerified ml-auto mt-2" style={{ cursor: "pointer" }} onClick={Verify} onKeyDown={handlekeydown}
          role="button" tabIndex={0}>
          <p>{verifyType === "" ? t("Get Verified") : isRejected(verifyType) ? t("Get Verified") : t(verifyType)}</p>
        </div>
      );
    };

    const renderPendingButton = () => {
      return (
        <div style={{ justifyContent: "end", display: "flex" }}>
          <p className="dot"></p>
          <span style={{ color: "var(--dark-primary-1, #ffa900)", fontSize: "15px" }}>
            {wholeVerify === "" ? t("Get Verified") : isRejected(wholeVerify) ? t("Get Verified") : t(wholeVerify)}
          </span>
        </div>
      );
    };

    switch (true) {
      case isVerified(getprofile.logoVerify) && isVerified(getprofile.twitterVerify) && isVerified(getprofile.nameVerify) && isVerified(getprofile.emailVerify):
        return (
          <p style={{ justifyContent: "end", display: "flex" }}>
            {t(wholeVerify)} <img src={yellowTick20px} />
          </p>
        );

      case getprofile.logoVerify === "Rejected":
        return renderRejectedButton(Logoverify);

      case getprofile.twitterVerify === "Rejected":
        return renderRejectedButton(twitterverify);

      case getprofile.nameVerify === "Rejected":
        return renderRejectedButton(nameVerify);

      case getprofile.emailVerify === "Rejected":
        return renderRejectedButton(emailverify);

      case getprofile.logoVerify === "Pending":
      case getprofile.twitterVerify === "Pending":
      case getprofile.nameVerify === "Pending":
      case getprofile.emailVerify === "Pending":
        return renderPendingButton();

      default:
        return null;
    }
  };



  return (
    <>
      <div className={`text - center ${loading ? 'spinner' : 'd-none'}`}>
        <img src={loader} alt='' className='spinAnimate' />
      </div>
      <div className="d-flex align-items-center mb-3">
        <h3 className="settingsHeading mb-0">{t("Profile")}</h3>
        {getprofile && getprofile.verification == "Verified" ?
          <button className="btn gradientBtn ml-auto" disabled>{t("Save")}</button>
          :
          username && profileimage && profilecoverimage && isValidPhoneNumber(phoneNumber)
            ?
            <button className="btn gradientBtn ml-auto" onClick={updateprofile}>{t("Save")}</button>
            :
            <button className="btn gradientBtn ml-auto" disabled>{t("Save")}</button>
        }
      </div>
      <div className="createCollectionCard mb-3">
        <form className="w-100">
          <div className="walletCnt d-flex align-items-center flex-wrap mb-3">
            <h3 >{address ? address.slice(0, 5) + "..." + address.slice(38, 42) : ""}</h3>
            <span className="walletLabel successLabel ml-auto">{address ? t("Wallet Connected") : t("Connect Wallet")}</span>
            <a href="#"><img src={copyIcon} className="ml-2" onClick={copyToClipboard} onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                copyToClipboard()
              }
            }}
              role="button" tabIndex={0} /></a>
          </div>
          <div className='form-row'>
            <div className="col-12 mb-3 d-flex align-items-start flex-wrap">
              <div className="imgContainer mr-4 mbVCh">
                <span className="formLabel">{t("Profile Image")} <span className='text-danger'>*</span></span>
                <div className="d-flex align-items-center flex-wrap">
                  <div className="imgCnt">
                    {typeof profileimage == "string" && profileimage !== undefined ?
                      profileimage !== "undefined" ?
                        <img src={profileimage} className="logoImg" />
                        :
                        ""
                      :
                      profileimage ?
                        <img src={URL.createObjectURL(profileimage)} className="logoImg" />
                        :
                        ""
                    }

                    {getprofile.logoVerify == "Verified" ?
                      ""
                      :
                      profileimage !== "undefined" ?
                        <div className="justify-content-center align-items-center editIcon">
                          <input type="file" id="chooseBtn" accept=".png, .gif, .webp, .jpg, .jpeg" onChange={(e) => imageupload(e)} />
                          <label for="chooseBtn"><img src={Pencil} /></label>
                        </div>
                        :
                        <div className="d-flex flex-column" >
                          <p className='text-center'>{t("PNG, GIF, JPG, JPEG, WEBP")}.</p>
                          <p className="fs-12 greyTxt text-center">{t("Max 100mb")}.</p>
                          <div className="chooseFileBtn mt-2 ml-4">
                            <input type="file" id="chooseBtn" accept=".png, .gif, .webp, .jpg, .jpeg" onChange={(e) => imageupload(e)} />
                            <label for="chooseBtn">{t("Choose File")}</label>
                          </div>
                        </div>

                    }
                  </div>
                </div>
                <label className="text-danger errLabel ml-auto"><span id='uploadimage' style={{ color: "red" }}></span></label>
              </div>
              <div className="coverContainer mbVCh">
                <span className="formLabel">{t("Cover Image")} <span className='text-danger'>*</span></span>
                <div className="coverCnt d-flex flex-column">

                  {typeof profilecoverimage == "string" && profilecoverimage !== undefined ?

                    coverimgtype.startsWith('video') ?
                      <video

                        className='vitag'
                        src={profilecoverimage}
                        alt="Uploaded"
                        muted
                        autoPlay

                      />
                      :
                      profilecoverimage !== 'undefined' ?
                        <img className="coverImg" src={profilecoverimage} />
                        :
                        ""

                    :
                    typeof profilecoverimage == "object" ?
                      coverimgtype.startsWith('video') ?
                        <video

                          className='vitag'
                          src={URL.createObjectURL(profilecoverimage)}
                          alt="Uploaded"
                          muted
                          autoPlay
                        />
                        :
                        profilecoverimage ?
                          < img className="coverImg" src={URL.createObjectURL(profilecoverimage)} />
                          :
                          ""
                      :
                      ""
                  }

                  {getprofile && getprofile.verification == "Verified" ?
                    ""
                    :
                    profilecoverimage !== "undefined" ?
                      <div className="justify-content-center align-items-center editIcon">
                        <input type="file" id="chooseBtn1" accept='.png, .gif, .webp, .jpg, .jpeg, .mp4' onChange={(e) => imageupload1(e)} />
                        <label for="chooseBtn1"><img src={Pencil} /></label>
                      </div>
                      :
                      <div className="d-flex flex-column align-items-center">
                        <span className='imgTxt'>{t("PNG, GIF, JPG, JPEG, WEBP, MP4")}.</span>
                        <span className="fs-12 greyTxt">{t("Max 100mb. At least 1400*360 pixels")}.</span>
                        <div className="chooseFileBtn mt-2">
                          <input type="file" id="chooseBtn1" accept='.png, .gif, .jpg, .jpeg, .webp, .mp4' onChange={(e) => imageupload1(e)} />
                          <label for="chooseBtn1">{t("Choose File")}</label>
                        </div>
                      </div>
                  }
                </div>
                <label className="text-danger errLabel ml-auto "><span id='uploadcoverimage' style={{ color: "red" }} className='ml-5'></span></label>
              </div>
            </div>
            <div className="col-12 mb-3">
              <div className="d-flex align-items-center flex-wrap">
                <span className="formLabel">{t("User Name")} <span style={{ color: "red" }}>*</span></span>
                <label className="text-danger errLabel ml-auto"> <div id='username1' style={{ color: "red" }}></div></label>
              </div>
              {getprofile && getprofile.nameVerify == "Verified" ?
                <input type="text" placeholder={t("Enter Name")} className="form-control" name="username" defaultValue={username} disabled />
                :
                <input type="text" placeholder={t("Enter Name")} className="form-control" name="username" onKeyUp={(e) => setUsername1(e)} defaultValue={username} />
              }

            </div>

            <div className="col-12 mb-3">
              <span className="formLabel">{t("Bio")}</span>
              {getprofile && getprofile.verification == "Verified" ?
                <textarea className="form-control" placeholder={t("Type Bio")} name="bio" defaultValue={bio} disabled></textarea>

                :
                <textarea className="form-control" placeholder={t("Type Bio")} name="bio" onKeyUp={(e) => setBio(e.target.value)} defaultValue={bio}></textarea>
              }
            </div>

            <div className="col-12 mb-3">
              <div className="d-flex align-items-center flex-wrap">
                <span className="formLabel">{t("Email Address")}</span>
                <label className="text-danger errLabel ml-auto">
                  {getprofile && getprofile.emailVerify == "Verified" ?
                    ""
                    :
                    <>
                      <img src={infoIcon} /> <span className='text-danger'>
                        {t("Email address is not updated, then you will not be able to receive any notification")}</span>
                    </>
                  }
                </label>
              </div>
              <div className="input-group verified">
                {getprofile && getprofile.emailVerify == "Verified" ?
                  <input type="text" placeholder={t("Enter Email")} className="form-control nd-formcontr" name="email" defaultValue={email} disabled />
                  :
                  <input type="text" placeholder={t("Enter Email")} className="form-control nd-formcontr" name="email" onChange={(e) => setEmail1(e)} defaultValue={email} />
                }
                <div className="input-group-append">
                  {email && regex.test(email) ?
                    getprofile && getprofile.emailVerify == "" && getprofile.isResend == false ?
                      <div className="input-group-text errLabel" style={{ cursor: "pointer" }} onClick={Emailverify} onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          Emailverify()
                        }
                      }}
                        role="button" tabIndex={0}>{t("Verify")}<i className="fas fa-chevron-right ml-2"></i></div>
                      :
                      getprofile.isResend == true ?
                        <div className="input-group-text errLabel" style={{ cursor: "pointer" }} onClick={Emailverify} onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            Emailverify()
                          }
                        }}
                          role="button" tabIndex={0}>{t("Resend")}<i className="fas fa-chevron-right ml-2"></i></div>
                        :
                        <div className="input-group-text"><img src={checkIcon} /></div>
                    :
                    ""
                  }
                </div>
              </div>
            </div>
            <div className="col-12 mb-3">
              <div className="d-flex align-items-center flex-wrap">
                <span className="formLabel">{t("Mobile Number")} <span className='text-danger'>*</span></span>
                {isValid && isValidPhoneNumber(phoneNumber) ? '' : isValid || !isValidPhoneNumber(phoneNumber) ? <label className="text-danger errLabel ml-auto"> <div id='phonenumber' style={{ color: "red" }}>{phoneNumber !== "" ? checkPhone ? 'Mobile Number is required' : 'Invalid Mobile Number' : ''}</div></label> : ''}
              </div>
              <div className='form-group mobilenumberInput'>
                <PhoneInput
                  international
                  withCountryCallingCode
                  defaultCountry={selectedCountry}
                  value={phoneNumber}
                  onChange={handleChange}
                  countryCallingCodeEditable={false}
                  onCountryChange={handleCountryChange}
                  error={!isValid}
                  limitMaxLength={true}
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>
          </div>

          <hr className="w-100" />
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center flex-wrap">
                <span className="formLabel">{t("Social Media")}</span>
              </div>
              <div className="d-flex urlFieldCnt flex-wrap align-items-center mb-2">
                <div className="d-flex w-100 mb-3">
                  <div className="socilaMediaIconCnt mr-2"><img src={twitter} /></div>
                  {getprofile && getprofile.twitterVerify == "Verified" ?
                    <input type="text" placeholder={t("Enter Twitter Url")} className="form-control" name="twitter" defaultValue={Twitter} disabled />
                    :
                    <input type="text" placeholder={t("Enter Twitter Url")} className="form-control" name="twitter" onKeyUp={(e) => setTwitter1(e)} defaultValue={Twitter} />
                  }
                </div>
                <label className="text-danger errLabel ml-auto"> <span id='twit' style={{ color: "red" }}></span></label>

              </div>
              <div className="d-flex urlFieldCnt flex-wrap align-items-center mb-2">
                <div className="d-flex w-100 mb-3">
                  <div className="socilaMediaIconCnt mr-2"><img src={instagram} /></div>
                  <div className=" align-items-center flex-wrap">
                  </div>
                  {getprofile && getprofile.verification == "Verified" ?
                    <input type="text" placeholder={t("Enter Instagram Url")} className="form-control" name="instagram" defaultValue={Instagram} disabled />
                    :
                    <input type="text" placeholder={t("Enter Instagram Url")} className="form-control" name="instagram" onKeyUp={(e) => setInstagram(e.target.value)} defaultValue={Instagram} />
                  }
                </div>


              </div>
              <hr />
            </div>
          </div>



          <div className="row">
            <div className="col-12 mb-3">
              <div className="d-flex align-items-center flex-wrap">
                <span className="formLabel">{t("Website")}
                </span>
                <label className="text-danger errLabel ml-auto">
                </label>
              </div>
              <div className="d-flex urlFieldCnt">
                <div className="socilaMediaIconCnt mr-2"><img src={globe} /></div>
                {getprofile && getprofile.verification == "Verified" ?
                  <input type="text" className="form-control" placeholder={t("Enter URL")} name="weburl" defaultValue={weburl} disabled />
                  :
                  <input type="text" className="form-control" placeholder={t("Enter URL")} name="weburl" onKeyUp={(e) => setWebUrl1(e)} defaultValue={weburl} />
                }
              </div>
              <hr />
            </div>
            <div className="col-12 mb-3">
              <div className="d-flex align-items-center flex-wrap">
                <span className="formLabel mb-0">{t("Verification")}</span>

                <div className="dark-primary ml-auto">
                  {t("Requirements")} < img src={infoIcon} id="popoverLegacy" />
                </div>


                <UncontrolledPopover trigger="legacy" placement="bottom" target="popoverLegacy" className="verifyPopOver">
                  <PopoverBody>

                    <div>
                      {getprofile.logoVerify == "Verified" ?
                        <label className="custCheck">{t("Profile Image")}.
                          <input type="checkbox" checked />
                          <span className="checkmark"></span>
                        </label>
                        :
                        getprofile.logoVerify == "Rejected" ?
                          <>
                            <img src={reject} style={{ height: "28px", width: "28px", borderRadius: "50%", marginLeft: "-4px" }} />
                            <label>{t("Profile Image")}.</label>
                          </>
                          :
                          <div>

                            <label className="custCheck">{t("Profile Image")}.
                              <input type="checkbox" disabled />
                              <span className="checkmark1"></span>
                            </label>
                          </div>
                      }


                    </div>
                    <div>
                      {getprofile.nameVerify == "Verified" ?
                        <label className="custCheck">{t("User Name")}.
                          <input type="checkbox" checked />
                          <span className="checkmark"></span>
                        </label>
                        :
                        getprofile.nameVerify == "Rejected" ?
                          <>
                            <img src={reject} style={{ height: "28px", width: "28px", borderRadius: "50%", marginLeft: "-4px" }} />
                            <label>{t("User Name")}.</label>
                          </>
                          :
                          <div>
                            <label className="custCheck">{t("User Name")}.
                              <input type="checkbox" disabled />
                              <span className="checkmark1"></span>
                            </label>
                          </div>
                      }
                    </div>
                    <div>
                      {getprofile.emailVerify == "Verified" ?
                        <label className="custCheck">{t("Verified email")}.
                          <input type="checkbox" checked />
                          <span className="checkmark"></span>
                        </label>
                        :
                        getprofile.emailVerify == "Rejected" ?
                          <>
                            <img src={reject} style={{ height: "28px", width: "28px", borderRadius: "50%", marginLeft: "-4px" }} />
                            <label>{t("Verified email")}.</label>
                          </>
                          :
                          <div>

                            <label className="custCheck">{t("Verified email")}.
                              <input type="checkbox" disabled />
                              <span className="checkmark1"></span>
                            </label>
                          </div>
                      }
                    </div>
                    <div>
                      {getprofile.twitterVerify == "Verified" ?
                        <label className="custCheck">{t("Connected Twitter account")}.
                          <input type="checkbox" checked />
                          <span className="checkmark"></span>
                        </label>
                        :
                        getprofile.twitterVerify == "Rejected" ?
                          <>
                            <img src={reject} style={{ height: "28px", width: "28px", borderRadius: "50%", marginLeft: "-4px" }} />
                            <label>{t("Connected Twitter account")}.</label>
                          </>
                          :
                          <div>
                            <label className="custCheck">{t("Connected Twitter account")}.
                              <input type="checkbox" disabled />
                              <span className="checkmark1"></span>
                            </label>
                          </div>
                      }
                    </div>
                  </PopoverBody>
                </UncontrolledPopover>
              </div>

              {renderGetVerifiedButton()}

            </div>

          </div>
        </form >
      </div >


      < Modal isOpen={modal1} toggle={toggle1} centered="true" className="curMdl createScsMdl" backdropClassName="selCurBp" keyboard={false}>
        <div className="createContent">
          <button className="btn closeBtn" onClick={toggle}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z" fill="#595F6A" />
            </svg>
          </button>

          <div className="w-100">
            <div className="d-flex justify-content-center">
              <img src={IconModal} />
            </div>
            <h3 className="walletHeading my-2">{t("Are you sure?")}</h3>
            <h3 className="walletSubHeading mb-3 ">{t("You are about to leave this page. All unsaved changes will be lost. Are you sure?")}</h3>
            <button type="button" className="btn btn-block gradientBtn mb-2" >{t("Continue Editing")}</button>
            <button type="button" className="btn btn-block darkBtn">{t("Discard Changes")}</button>
          </div>
        </div>
      </Modal >

    </>
  );
};

export default SettingsProfile;