import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import profileImg from '../../../assets/images/header/userProfileImg.png'
import { formatAddress } from '../../../utils/formatAddress';
import { useTranslation } from 'react-i18next';

const OwnedByModal = ({ modalOpen, closeModal, ownerList }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const closeBtn = <button className="close" onClick={closeModal}>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.3359 10.5L16.5547 14.7188C16.7891 14.9531 16.7891 15.3828 16.5547 15.6172L15.5781 16.5938C15.3438 16.8281 14.9141 16.8281 14.6797 16.5938L10.5 12.375L6.28125 16.5938C6.04688 16.8281 5.61719 16.8281 5.38281 16.5938L4.40625 15.6172C4.17188 15.3828 4.17188 14.9531 4.40625 14.7188L8.625 10.5L4.40625 6.32031C4.17188 6.08594 4.17188 5.65625 4.40625 5.42188L5.38281 4.44531C5.61719 4.21094 6.04688 4.21094 6.28125 4.44531L10.5 8.66406L14.6797 4.44531C14.9141 4.21094 15.3438 4.21094 15.5781 4.44531L16.5547 5.42188C16.7891 5.65625 16.7891 6.08594 16.5547 6.32031L12.3359 10.5Z" fill="#6E737E" />
        </svg>
    </button>;

    const ownerData = ownerList.map((data) => {
        return (
            <div className='ownedbyUserList'>
                <div className='d-flex hover' onClick={() => navigate(`/profile/${data && data?.address}`)} onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        navigate(`/profile/${data && data?.address}`);
                    }
                }}
                    role="button"
                    tabIndex={0}>
                    <div>
                        {!data?.profile?.startsWith(process.env.S3_URL) && !data?.profile?.startsWith('ipfs') && data?.profile !== null && data?.profile !== "undefined" && data?.profile !== "" && data?.profile !== undefined ?
                            <img src={data && data.profile
                                ? data.profile
                                : profileImg} alt='usrImg' className='imguser mr-3' /> :
                            <img src={profileImg} alt='usrImg' className='imguser mr-3' />}
                    </div>
                    <div>
                        <div className='fs-14 fw-600'>{data && data.name
                            ? data.name
                            : 'Unnamed'}</div>
                        <div className='fs-12 fw-400 dark-text-secondary'>{formatAddress(data.address)}</div>
                    </div>
                </div>
                <div className='fs-12 fw-400 dark-text-secondary'>{data.amount} {t("Item")}</div>
            </div>
        )
    })

    return (
        <Modal isOpen={modalOpen} centered="true" className="mymodal" backdropClassName="selCurBp" keyboard={false}
        >
            <ModalHeader onClick={closeModal} close={closeBtn}>
                <h5 className='modal-title'>{t("Owned by")}</h5></ModalHeader>
            <ModalBody>
                <div className='ownedbyuser'>
                    {ownerData}
                </div>
            </ModalBody>
        </Modal>
    )
}

export default OwnedByModal;