import { useAccount, usePublicClient } from 'wagmi'
import { createWalletClient, custom, parseUnits } from 'viem'

import { axios } from '../lib/axios'
import { getOrder } from '../utils/getOrder'
import marketplaceAbi from '../config/abi/marketplace.json'
import contracts from '../config/constants/contracts'
import { getContract } from '../utils/getContract'
import { useCheckoutItems } from '../features/nft/apis/checkoutItems'
import DataService from '../lib/DataService'
export const useCheckoutCartItems = () => {
	const publicClient = usePublicClient()
	const { address: account } = useAccount()
	const { mutateAsync: checkoutItemsFromCart } = useCheckoutItems()
	const { getRequest, postRequest } = DataService()

	const getOrders = (assets) => {
		return assets.map((asset) => {
			return {
				order: getOrder({
					asset,
					assetType: asset.details.contractStandard,
					orderType: 'sell'
				}),
				royalty: asset.details.royalty * 10,
				royaltyAddress: asset.details.creator,
				network: asset.details.chain
			}
		})
	}

	const getBuyerFeeSignature = async ({
		order,
		royalty,
		royaltyAddress,
		network
	}) => {
		const res = await postRequest('asset/buyer-fee-signature', {
			orderTuple: order,
			royalty,
			royaltyAddress,
			network
		})
		if (res.status) {
			const { v, r, s } = res.data
			return [v, r, s]
		} else {
			throw new Error(res.message)
		}
	}

	const getBuyerFeeSigs = async (orders) => {
		try {
			const res = await Promise.allSettled(
				orders.map((order) => getBuyerFeeSignature(order))
			)
			return res.map((r) => r.value)
		} catch (error) {
			throw new Error(error)
		}
	}

	const checkoutCartItems = async (assets, chain, value) => {
		const parsedValue = parseFloat(
			parseUnits(value.toString(), 18)
		).toString()

		const ordersArray = getOrders(assets)

		const address = contracts.marketplace[chain.id]

		try {
			const client = createWalletClient({
				transport: custom(window.ethereum),
				chain
			})

			const contract = getContract({
				abi: marketplaceAbi,
				signer: client,
				address,
				publicClient
			})

			const orders = ordersArray.map(({ order }) => order)
			const buyerFeeSigs = await getBuyerFeeSigs(ordersArray)
			const royaltyFee = assets.map((asset) => (asset.details.royalty * 10))
			const royaltyReceipts = assets.map((asset) => asset.details.creator)
			const iStores = assets.map((asset) => !asset.details.isMinted)
			const uris = assets.map((asset) => asset.details.uri)

			const hash = await contract.write.sell(
				[
					orders,
					buyerFeeSigs,
					royaltyFee,
					royaltyReceipts,
					iStores,
					uris
				],
				{
					account,
					value: value !== 0 ? parsedValue : 0
				}
			)

			const transactionReceipt =
				await publicClient.waitForTransactionReceipt({
					hash
				})

			if (transactionReceipt.status === 'success') {
				const res = await checkoutItemsFromCart(assets)
				if (res.status) {
					return transactionReceipt
				}
			}

			throw new Error('Something went wrong. Try again later')
		} catch (error) {
			throw new Error(error)
		}
	}

	return { checkoutCartItems }
}
