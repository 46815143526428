import React, {
	useState,
	useRef,
	useCallback
} from 'react'

import { useInfiniteQuery } from '@tanstack/react-query'

import { collectionOffer } from '../apis/collectionOffer'
import { useParams } from 'react-router'
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi'
import { useSearchParams } from 'react-router-dom'
import { useCollectionActions, useCollectionState } from '../store'
import { useTranslation } from 'react-i18next'
import { useConnectWalletModalActions } from '../../../components/modals/connect-wallet/store'
import { chainId } from '../../../config/constants/chains'
import { useMakeCollectionOfferModalActions } from '../../../components/modals/collection-offer/store'
import cndds from '../../../assets/images/cndds.png'
import cnddsLight from '../../../assets/images/cnddsLight.svg'
import { chainIdMap, chainMap } from '../../../utils/getFilterData'
import { useOkxSwitchNetwork } from '../../../hooks/useOkxSwitchNetwork'
import { formatAmount } from '../../../utils/formatAmount'

function TraitChip({ filter, filters, onClearChip }) {
	const handleClearChip = () => {
		const updatedFilters = {
			...filters,
			search: {
				...filters.search,
				stringTraits: null
			}
		}
		onClearChip(updatedFilters)
	}
	const { t } = useTranslation()

	const handleKeyDown = (event) => {
		if (event.key === 'Enter' || event.key === ' ') {
			handleClearChip();
		}
	};

	return (
		<>
			{filter?.value?.name !== 'No Trait' ? (
				<div className='fiterSelectlabel mb-4'>
					<div className='tagLabel'>
						<span className='background'>
							{filter?.value?.name}:{' '}
						</span>
						<span>{filter?.value?.values?.[0]}</span>
						<i
							className='fas fa-times ml-2 hover'
							onClick={handleClearChip}
							onKeyDown={handleKeyDown}
							role="button"
							tabIndex={0}
						></i>
					</div>
				</div>
			) : null}
		</>
	)
}

const OffersList = ({ filters, handleFiltersChange, collectionDetails }) => {
	const { t } = useTranslation()
	const params = useParams()
	const { address: account } = useAccount()
	const { chain } = useNetwork()
	const { switchNetworkAsync } = useSwitchNetwork()
	const { switchNetwork } = useOkxSwitchNetwork()
	const walletType = localStorage.getItem('walletType')
	const [searchParams, setSearchParams] = useSearchParams()


	const { toggleMakeCollectionOfferModal, setCollection } =
		useMakeCollectionOfferModalActions()
	const { toggleConnectWalletModal, setConnectWalletModalState } =
		useConnectWalletModalActions()

	const isCreator =
		account &&
		account.toLowerCase() ===
		collectionDetails?.data?.creator?.address?.toLowerCase()

	const { noOfItems } = useCollectionState()
	const { setNoOfItems } = useCollectionActions()

	const [hoveredRow, setHoveredRow] = useState(null)

	const handleRowHover = (index) => {
		setHoveredRow(index)
	}

	const handleMakeOffer = async (asset) => {
		setCollection({
			...collectionDetails?.data,
			collectionOffer: {
				...asset
			}
		})

		setConnectWalletModalState({
			chainId: chainIdMap[collectionDetails?.data.analytics.chain]
		})
		if (!account) {
			toggleConnectWalletModal()
			setConnectWalletModalState({
				callback: toggleMakeCollectionOfferModal
			})
			return
		}

		try {
			if (walletType === 'okx') {
				if (
					collectionDetails?.data.analytics.chain !== chainMap[parseInt(window.okxwallet.chainId)]
				) {
					await switchNetwork(chainIdMap[collectionDetails?.data.analytics.chain])
				}
			} else {
				if (
					collectionDetails?.data.analytics.chain === 'binance' &&
					chain.id !== chainId.BINANCE
				) {
					await switchNetworkAsync(chainId.BINANCE)
				} else if (
					collectionDetails?.data.analytics.chain === 'ethereum' &&
					chain.id !== chainId.ETHEREUM
				) {
					await switchNetworkAsync(chainId.ETHEREUM)
				} else if (
					collectionDetails?.data.analytics.chain === 'polygon' &&
					chain.id !== chainId.AMOY
				) {
					await switchNetworkAsync(chainId.AMOY)
				}
			}
		} catch (error) {
		}

		toggleMakeCollectionOfferModal()
	}

	const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
		useInfiniteQuery({
			queryKey: [
				'collection_offer',
				params.collectionAddress,
				searchParams.toString(),
				filters
			],
			queryFn: ({ pageParam = 1 }) =>
				collectionOffer(
					params.collectionAddress,
					account,
					filters,
					pageParam,
					(noOfItems) => {
						setNoOfItems(noOfItems)
					}
				),
			getNextPageParam: (lastPage, allPages) => {
				return lastPage?.length ? allPages?.length + 1 : undefined
			},
			enabled: !!params.collectionAddress
		})

	const intersectionObserverRef = useRef()
	const lastNftRef = useCallback(
		(nft) => {

			if (isFetchingNextPage) {
				return
			}

			if (intersectionObserverRef.current) {
				intersectionObserverRef.current.disconnect()
			}

			intersectionObserverRef.current = new IntersectionObserver(
				(nfts) => {
					if (
						nfts[0].isIntersecting &&
						hasNextPage &&
						!isFetchingNextPage
					) {
						fetchNextPage()
					}
				}
			)

			if (nft) {
				intersectionObserverRef.current.observe(nft)
			}
		},
		[isFetchingNextPage, hasNextPage, fetchNextPage]
	)

	const traitChips = Object.entries(filters?.search.stringTraits ?? {}).map(
		([key, value]) => {
			return (
				<TraitChip
					key={key}
					filters={filters}
					filter={{ key, value }}
					onClearChip={handleFiltersChange}
				/>
			)
		}
	)

	const offerList = data?.pages?.map((page, index) => {
		return page?.map((offer, i) => {
			if (page?.length === i + 1) {
				return (
					<tr
						key={i}
						ref={lastNftRef ? lastNftRef : null}
						onMouseEnter={() => handleRowHover(i)}
						onMouseLeave={() => handleRowHover(null)}
						onClick={isCreator ? null : () => handleMakeOffer(offer)}
						style={{ cursor: 'pointer' }}
					>
						<td>
							<span className='tdValue'>
								{!isCreator && hoveredRow === i
									? `Make Offer at ${formatAmount(parseFloat(offer.price))} ${offer.currency}  >`
									: `${formatAmount(parseFloat(offer.price))} ${offer.currency}`}
							</span>
							<span
								className='progressBarCnt'
								style={{ width: offer.percentage + '%' }}
							></span>
						</td>
						<td>
							<span className='tdValue'>
								{formatAmount(parseFloat(offer.volume?.toFixed(8)))}{' '}
								{offer.currency}
							</span>
						</td>
						<td>
							<span className='tdValue'>{offer.quantity}</span>
						</td>
						<td>
							<span className='tdValue'>{offer.bidders}</span>
						</td>
						<td>
							<span className='tdValue'>{offer.yourOffers}</span>
						</td>
					</tr>
				)
			}
			return (
				<tr
					key={i}
					onMouseEnter={() => handleRowHover(i)}
					onMouseLeave={() => handleRowHover(null)}
					onClick={isCreator ? null : () => handleMakeOffer(offer)}
					style={{ cursor: 'pointer' }}
				>
					<td>
						<span className='tdValue'>
							{!isCreator && hoveredRow === i
								? `Make Offer at ${formatAmount(parseFloat(offer.price))} ${offer.currency}  >`
								: `${formatAmount(parseFloat(offer.price))} ${offer.currency}`}
						</span>
						<span
							className='progressBarCnt'
							style={{ width: offer.percentage + '%' }}
						></span>
					</td>
					<td>
						<span className='tdValue'>
							{formatAmount(parseFloat(offer.volume?.toFixed(8)))}{' '}
							{offer.currency}
						</span>
					</td>
					<td>
						<span className='tdValue'>{offer.quantity}</span>
					</td>
					<td>
						<span className='tdValue'>{offer.bidders}</span>
					</td>
					<td>
						<span className='tdValue'>{offer.yourOffers}</span>
					</td>
				</tr>
			)
		})
	})

	const hasNoData =
		offerList &&
		searchParams.toString().length > 0 &&
		data?.pages?.[0].length === 0
	const hasNoItems =
		offerList && searchParams.toString().length === 0 && noOfItems === 0
	const noData = offerList?.[0].length === 0

	return (
		<>
			{traitChips}
			<div class='table-responsive'>
				{!noData ?
					<table class='table table-borderless progressTable mb-0'>
						<thead class='trd_head'>
							<tr>
								<th>{t("Offer Price")}</th>
								<th>{t("Total Volume")} </th>
								<th>{t("Total Offers")}</th>
								<th>{t("Bidders")}</th>
								<th>{t("Your Offers")}</th>
							</tr>
						</thead>
						<tbody class='trdtable_body'>{offerList}</tbody>
					</table>
					: (
						<div className='noItemFound'>
							<div className='text-center'>
								<img
									src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : cndds}
									alt='noItemfoundImage'
									className='img-fluid'
								/>
							</div>
							<div className='nofounttxt mb-4'>
								{t('Nothing to display.')}
							</div>
						</div>
					)}

			</div>
		</>
	)
}

export default OffersList
