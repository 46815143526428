import { Link, useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { NftCard } from '../../collection/components/NftCard'
import { axios } from '../../../lib/axios'
import { BACKEND_URL } from '../../../config/constants'
import { useTranslation } from 'react-i18next'
import DataService from '../../../lib/DataService'

const { getRequest } = DataService()
export function MoreFromTheCollection() {
	const params = useParams()
	const navigate = useNavigate()
	const { t } = useTranslation()

	const { data, isLoading } = useQuery({
		queryKey: ['nfts'],
		queryFn: async () => {
			
			return getRequest(`collection/assets?address=${params.collectionAddress}`)

		},
		select: (res) => {
			if (res.status) {
				return res.data
			}
		}
	})

	if (isLoading) {
		return null
	}


	const nfts = data?.filter((n) =>
		n.details.nftId == params.nftId
			? n.details.supplyId != params.supplyId
			: true
	)
		.slice(0, 6)

	return (
		<>
			<div className='col-12 mt-3'>
				<div className='d-flex align-items-center justify-content-between flex-nowrap'>
					<h3 className='gradientHeading pr-3'>
						{t("More from this collection")}.
					</h3>
					<Link
						className='btn darkBtn ml-auto mb-4'
						to={`/collection/${params.collectionAddress}`}
					>
						{t("View Collection")}
					</Link>
				</div>
			</div>
			<div className='col-12'>
				<div className='row'>
					{nfts &&
						nfts.map((nft) => {
							return (
								<NftCard
									item={nft}
									onClick={() =>
										navigate(
											`/assets/${params.chain}/${params.collectionAddress}/${nft.details.nftId}`
										)
									}
									classNames='col-sm-6 col-lg-3 col-xl-2 hover mb-4'
								/>
							)
						})}
				</div>
			</div>
		</>
	)
}
