import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import noItemfoundImage from '../../assets/images/collection/noItemfoundImage.svg';
import cnddsLight from '../../assets/images/cnddsLight.svg'
import './Settings.scss';
import loader from '../../assets/images/Loader.png'
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';
import DataService from '../../lib/DataService';

export const SettingsAccountsupport = () => {

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [loading, setLoading] = useState(false);


  const { getRequest } = DataService()

  useEffect(() => {
    fetchAccountsupport()
  }, [])

  const [support, setSupport] = useState("")
  const fetchAccountsupport = async () => {
    setLoading(true)
    const accountsupport = await getRequest("user/accounts_support")
    if (accountsupport.status == true) {
      setLoading(false)
      setSupport(accountsupport.data)
    }
    else {
      setLoading(false)
    }
  }

  const { t } = useTranslation()

  return (
    <>
      <div className={`text-center ${loading ? 'spinner' : 'd-none'}`}>
        <img src={loader} alt='' className='spinAnimate' />
      </div>
      <div className="d-flex align-items-center mb-3">
        <h3 className="settingsHeading mb-0">{t("Account Support")}</h3>
      </div>
      <div className="createCollectionCard mb-3">
        <form className="w-100">
          <div className="form-row">
            <div className="col-12">
              <Accordion allowZeroExpanded>
                {support && support.length > 0 ?
                  support && support.filter(item => item.status == true).map((data, index) => (
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                        {ReactHtmlParser(data.question)}
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                        {ReactHtmlParser(data.answer)}
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))

                  :

                  <div className='noItemFound'>
                    <div className='text-center'>
                      <img src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : noItemfoundImage} alt="noItemfoundImage" className='img-fluid' />
                    </div>
                    <div className='nofounttxt mb-4'>
                      {t("Nothing to display.")}
                    </div>
                  </div>

                }

              </Accordion>
            </div>
          </div>
        </form>
      </div>

    </>
  );
};

export default SettingsAccountsupport;