import React, { useState, useEffect } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { useNavigate, useParams } from 'react-router-dom'
import classnames from 'classnames'
import { useAccount } from 'wagmi'

import FixedPrice from './components/FixedPrice'
import Auction from './components/Auction'
import { DutchAuction } from './components/DutchAuction'
import { useFetchListAssetDetails } from './apis/fetchListAssetDetails'
import { Spinner } from '../../components/loader/spinner/Spinner'
import { AssetNotFound } from '../../features/nft/components/AssetNotFound'
import { useScollToTop } from '../../hooks/useScrollToTop'
import { formatAmount } from '../../utils/formatAmount'
import { useToggleClassName } from '../../hooks/useToggleClassName'

import aiPfpClub from '../../assets/images/itemIcons/aiPfpClub.png'
import walletLoaderCnt from '../../assets/images/walletLoader.png'
import walletLightLoaderCnt from '../../assets/images/wallet-light-loader.png'
import walletLoader from '../../assets/images/loaderCircle.png'
import tick from '../../assets/images/collection/yellow-tick_20px.svg'
import defaultImage from '../../assets/images/homeExplorer/placeholder.png'
import getownerabi from '../AbiArray/MarketplaceBnbabi.json'
import contracts from '../../config/constants/contracts'
import './listforsale.scss'
import { handleImageError } from '../../hooks/useHandleImg'
import { getProvider } from '../../utils/getProvider'
import Web3 from 'web3'
import { useTranslation } from 'react-i18next'
import { chainId } from '../../config/constants/chains'

export const Listforsale = () => {
	const { address, isConnected } = useAccount()
	const [videoError, setVideoError] = useState(false)
	useScollToTop()

	const params = useParams()
	const { t } = useTranslation()

	const { data, isLoading, isFetching } = useFetchListAssetDetails(
		params.collectionAddress,
		params.nftId,
		params.chain,
		address
	)

	const [modal, setModal] = useState(false)
	const toggle = () => setModal(!modal)

	const [modal1, setModal1] = useState(false)
	const toggle1 = () => setModal1(!modal1)

	const [activeTab, setActiveTab] = useState('1')
	const toggle2 = tab => {
		if (activeTab !== tab) setActiveTab(tab)
	}

	const [owner, setOwner] = useState('')

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})

		const fetchData = async () => {
			try {
				const provider = getProvider();
				const web3 = new Web3(provider);
				const getOwner = new web3.eth.Contract(getownerabi, contracts.marketplace[chainId.ETHEREUM]);
				const owner = await getOwner.methods.owner().call();
				setOwner(owner)
			} catch (error) {
				console.error('Error fetching owner:', error);
			}
		};

		fetchData();
	}, [owner])

	const navigate = useNavigate()
	useToggleClassName()

	const [fetchPrice, setFetchPrice] = useState([])

	const fetchPriceDetails = data => {
		setFetchPrice(data)
	}

	if (isLoading) {
		return (
			<section class='TraSec'>
				<div class='container' style={{ height: '85vh ' }}>
					<div
						className='row align-items-center justify-content-center'
						style={{ height: '85vh ' }}
					>
						<Spinner />
					</div>
				</div>
			</section>
		)
	}

	if (!data.status) {
		return <AssetNotFound />
	}

	const isVideo = data?.data?.details?.asset?.includes('.mp4')
	const handleVideoError = () => {
		setVideoError(true)
	}



	return (
		<>
			<div className='row'>
				<div className='col-lg-2 mb-3'>
					<button className='backIcon' onClick={() => navigate(-1)}>
						<i className='fas fa-angle-left'></i>
					</button>
				</div>
				<div className='col-lg-3 mb-3'>
					<h3 className='collectionSecHeading text-left'>{t("List For Sale")} </h3>
				</div>
				<div className='col-lg-6'>
					<div className='createCollectionCard mb-3'>
						<div className='borderedCnt mb-3'>
							{isConnected ? (
								<div className='valueRow d-flex align-items-center flex-wrap'>
									<h3>
										{address
											? address.slice(0, 5) + '...' + address.slice(38, 42)
											: ''}
									</h3>
								</div>
							) : (
								<div className='valueRow d-flex align-items-center flex-wrap'>
									<h3></h3>
									<span
										className='walletLabel successLabel ml-auto'
										style={{ color: 'red' }}
									>
										Please Connect Wallet
									</span>
								</div>
							)}
						</div>
						<hr className='w-100' />
						<div className='lfsItemCnt mb-3'>
							{!isVideo ? (
								!data?.data?.details?.asset?.startsWith(process.env.S3_URL) &&
									!data?.data?.details?.asset?.startsWith('ipfs') &&
									data?.data?.details?.asset !== null ? (
									<img
										src={
											data?.data?.details?.asset
												? data?.data?.details?.asset
												: defaultImage
										}
										className='itemImg'
										onError={handleImageError}
									/>
								) : (
									<img src={defaultImage} className='itemImg' />
								)
							) : videoError ? (
								<img src={defaultImage} className='itemImg' />
							) : (
								
								<video loop autoplay='' playsInline preload='metadata' controlslist='nodownload' id='video-asset' className='imgVideoCenter-60px'>
									<source src={data?.data?.details?.asset ? data?.data?.details?.asset : defaultImage} type="video/mp4" onError={handleVideoError} />
								</video>

							)}
							<div className='itemValueCnt d-flex align-items-center'>
								<div className='d-flex align-items-start flex-column'>
									<span className='mb-1'>
										{data?.data?.collection?.name}
										{data?.data?.collection?.isVerified ? (
											<img src={tick} className='ml-1' />
										) : null}
									</span>
									<h3 className='mb-1'>{data?.data?.details?.name}</h3>
								</div>
								<div className='d-flex flex-column ml-auto'>
									<span className='mb-1'>
										{!fetchPrice?.price
											? ''
											: `($${formatAmount(fetchPrice?.singleNftUsdtPrice)})`}
									</span>
									<h3 className='mb-1'>
										{!fetchPrice?.price
											? '--'
											: parseFloat(formatAmount(fetchPrice?.price))}{' '}
										{fetchPrice?.token?.currencySymbol}
									</h3>
								</div>
							</div>
						</div>
						<hr className='w-100' />
						<div className='row mb-3'>
							<div className='col-12'>
								<span className='formLabel mr-2'>{t("Choose a sale type")}</span>
								<Nav tabs className='mt-3'>
									<NavItem>
										<NavLink
											className={classnames({
												active: activeTab === '1'
											})}
											onClick={() => {
												toggle2('1')
											}}
										>
											<span>{t("Fixed Price")}</span>
										</NavLink>
									</NavItem>
									{address.toLowerCase() == owner?.toLowerCase() && (data?.data?.details?.creator)?.toLowerCase() == owner?.toLowerCase() && data?.data?.details?.mintType == "lazyMint" ?
										""
										:
										<NavItem>
											<NavLink
												className={classnames({
													active: activeTab === '2'
												})}
												onClick={() => {
													toggle2('2')
												}}
											>
												<span>{t("Auction")}</span>
											</NavLink>
										</NavItem>
									}
									<NavItem>
										<NavLink
											className={classnames({
												active: activeTab === '3'
											})}
											onClick={() => {
												toggle2('3')
											}}
										>
											<span>{t("Dutch Auction")}</span>
										</NavLink>
									</NavItem>
								</Nav>
								<hr />
							</div>

							<div className='col-12'>
								<TabContent activeTab={activeTab}>
									<TabPane tabId='1'>
										<FixedPrice
											data={data?.data}
											onData={fetchPriceDetails}
											activeTab={activeTab}
										/>
									</TabPane>
									<TabPane tabId='2'>
										<Auction
											data={data?.data}
											onData={fetchPriceDetails}
											activeTab={activeTab}
										/>
									</TabPane>
									<TabPane tabId='3'>
										<DutchAuction
											data={data?.data}
											onData={fetchPriceDetails}
											activeTab={activeTab}
										/>
									</TabPane>
								</TabContent>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Modal
				isOpen={modal}
				toggle={toggle}
				centered='true'
				className='curMdl createScsMdl'
				backdropClassName='selCurBp'
				keyboard={false}
			>
				<ModalHeader>
					<button className='btn closeBtn' onClick={toggle}>
						<svg
							width='12'
							height='12'
							viewBox='0 0 12 12'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z'
								fill='#595F6A'
							/>
						</svg>
					</button>
					<h5 className='modal-title'>{t("Loading")}</h5>
				</ModalHeader>
				<ModalBody>
					<div className='createContent'>
						<div className='d-flex justify-content-center'>
							<img src={aiPfpClub} />
						</div>
						<h3 className='walletHeading my-3'>{t("Item Minted Successfully")}</h3>
						<button type='button' className='btn btn-block gradientBtn'>
							{t("View Collection")}
						</button>
					</div>
					<div>

						<h3 className='walletSubHeading'>
							{t("To continue send transaction with your wallet")}.
						</h3>
						<div className='d-flex justify-content-center align-items-center loaderCont'>
							{localStorage.getItem('light_theme') == 'true' ? (
								<img src={walletLightLoaderCnt} />
							) : (
								<img src={walletLoaderCnt} />
							)}
							<div className='loaderAnimCnt'>
								<img src={walletLoader} className='loaderAnimation' />
							</div>
						</div>
					</div>
				</ModalBody>
			</Modal>

			<Modal
				isOpen={modal1}
				toggle={toggle1}
				centered='true'
				className='curMdl createScsMdl'
				backdropClassName='selCurBp'
			>
				<div className='createContent'>
					<button className='btn closeBtn' onClick={toggle1}>
						<svg
							width='12'
							height='12'
							viewBox='0 0 12 12'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z'
								fill='#595F6A'
							/>
						</svg>
					</button>
					<div className='d-flex justify-content-center'>
						<img src={aiPfpClub} />
					</div>
					<h3 className='walletHeading my-3'>Add Trait</h3>
					<form>
						<div className='row'>
							<div className='col-12 mb-3'>
								<div className='d-flex align-items-center flex-wrap'>
									<span className='formLabel mb-2'>Type</span>
								</div>
								<input
									type='text'
									placeholder='Enter Type'
									className='form-control'
								/>
							</div>
							<div className='col-12 mb-3'>
								<div className='d-flex align-items-center flex-wrap'>
									<span className='formLabel mb-2'>Name</span>
								</div>
								<input
									type='text'
									placeholder='Enter Name'
									className='form-control'
								/>
							</div>
						</div>
					</form>
					<button type='button' className='btn btn-block gradientBtn'>
						Add Trait
					</button>
				</div>
			</Modal>
		</>
	)
}

export default Listforsale
