import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { Link, useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi'
import { useTranslation } from 'react-i18next'
import { io } from 'socket.io-client'

import { fetchListings } from '../apis/fetchListings'
import { useEditListingModalActions } from '../../../components/modals/edit-listing-modal/store'
import { chainId } from '../../../config/constants/chains'
import { useConnectWalletModalActions } from '../../../components/modals/connect-wallet/store'
import { useBuyNowModalActions } from '../../../components/modals/buy-now/store'
import { useCheckoutModalActions } from '../../../components/modals/checkout/store'
import { axios } from '../../../lib/axios'
import { useToast } from '../../../utils/toast/customSuccess'
import { usePurchaseLoadingModalActions } from '../../../components/modals/purchase-loading/store'
import { useCancelAuction } from '../../../hooks/useCancelAuction'
import contracts from '../../../config/constants/contracts'
import { useSomethingWentWrongModalActions } from '../../../components/modals/error/store'
import { useCancelListing } from '../apis/cancelListing'

import cndds from '../../../assets/images/cndds.png'
import minus from '../../../assets/images/minus.svg'
import cnddsLight from '../../../assets/images/cnddsLight.svg'
import { chainIdMap, chainMap } from '../../../utils/getFilterData'
import { useOkxSwitchNetwork } from '../../../hooks/useOkxSwitchNetwork'



const Listing = forwardRef(function Listing({ listing, asset }, ref) {
	const { t } = useTranslation()
	const [socket, setSocket] = useState()
	const [currentPrice, setCurrentPrice] = useState()
	const [cart, setCart] = useState(true)


	const { address: account, isConnected } = useAccount()
	const { switchNetworkAsync } = useSwitchNetwork()
	const { switchNetwork } = useOkxSwitchNetwork()
	const walletType = localStorage.getItem('walletType')
	const { chain } = useNetwork()
	const queryClient = useQueryClient()
	const toast = useToast()

	const chainid = chainIdMap[asset.details.chain]
	const marketplaceContractAddress = asset
		? contracts.marketplace[chainid]
		: null

	const { cancelAuction } = useCancelAuction(marketplaceContractAddress, chainid)
	const { mutateAsync: cancelListing } = useCancelListing()

	const { toggleEditListingModal, setAsset: setEditListingAssetState } =
		useEditListingModalActions()
	const { toggleBuyNowModal, setAsset: setBuyNowModalAssetState } =
		useBuyNowModalActions()
	const { setAsset: setCheckoutModalAssetState } = useCheckoutModalActions()
	const { togglePurchaseLoadingModal, setHeading } =
		usePurchaseLoadingModalActions()
	const { toggleSomethingWentWrongModal } =
		useSomethingWentWrongModalActions()
	const { toggleConnectWalletModal, setConnectWalletModalState } =
		useConnectWalletModalActions()

	useEffect(() => {
		if (!listing) return
		if (listing.details.saleType !== 'dutch-auction') return

		const s = io(process.env.REACT_APP_BACKEND_SOCKET_URL)
		setSocket(s)

		return () => {
			s.disconnect()
		}
	}, [listing])

	useEffect(() => {
		if (socket && listing) {
			socket.emit('get-order-id', listing.details.orderId)
		}
	}, [socket, listing])

	useEffect(() => {
		if (socket && listing) {
			socket.on('dutch-price', (price) => {
				setCurrentPrice(price)
				const updatedAsset = {
					...asset,
					details: {
						...listing.details,
						seller: listing.details.fromAddress,
						priceInUsd: listing.details.usdPrice,
						royalty: asset.details.royalty,
						creator: asset.details.creator,
						isMinted: asset.details.isMinted,
						uri: asset.details.uri,
						contractStandard: asset.details.contractStandard,
						nftId: asset.details.nftId,
						contractAddress: asset.details.contractAddress,
						price
					}
				}
				setBuyNowModalAssetState(updatedAsset)
			})
		}
	}, [socket, listing])

	const edit = async (listing) => {
		setEditListingAssetState({
			...listing,
			collection: {
				name: asset.collection.name,
				isVerified: asset.collection.isVerified,
				address: asset.collection.address,
				profile: asset.collection.profile
			},
			details: {
				...listing.details,
				floorPrice: asset.details.floorPrice
			}
		})

		setConnectWalletModalState({
			chainId: chainIdMap[listing.details.chain]
		})
		if (!account) {
			toggleConnectWalletModal()
			setConnectWalletModalState({
				callback: toggleEditListingModal
			})
			return
		}

		try {
			if (walletType === 'okx') {
				if (
					listing.details.chain !== chainMap[parseInt(window.okxwallet.chainId)]
				) {
					await switchNetwork(chainIdMap[listing.details.chain])
				}
			} else {
				if (listing.details.chain === 'binance' && chain.id !== chainId.BINANCE) {
					await switchNetworkAsync(chainId.BINANCE)
				} else if (
					listing.details.chain === 'ethereum' &&
					chain.id !== chainId.ETHEREUM
				) {
					await switchNetworkAsync(chainId.ETHEREUM)
				} else if (
					listing.details.chain === 'polygon' &&
					chain.id !== chainId.AMOY
				) {
					await switchNetworkAsync(chainId.AMOY)
				}
			}
			

			toggleEditListingModal()
		} catch (error) { }
	}

	const buy = async (listing) => {
		const updatedAsset = {
			...asset,
			details: {
				...listing.details,
				seller: listing.details.fromAddress,
				price: currentPrice ? currentPrice : listing.details.price,
				priceInUsd: listing.details.usdPrice,
				royalty: asset.details.royalty,
				creator: asset.details.creator,
				isMinted: asset.details.isMinted,
				uri: asset.details.uri,
				contractStandard: asset.details.contractStandard,
				nftId: asset.details.nftId,
				contractAddress: asset.details.contractAddress
			}
		}
		if (listing.details.saleType === 'dutch-auction') {
			setBuyNowModalAssetState(updatedAsset)
		} else {
			setBuyNowModalAssetState({
				...updatedAsset,
				details: {
					...updatedAsset.details,
					price: listing.details.price
				}
			})
		}
		setCheckoutModalAssetState([
			{
				...asset,
				details: {
					...listing.details,
					price: currentPrice ? currentPrice : listing.details.price
				}
			}
		])
		setConnectWalletModalState({
			chainId: chainIdMap[listing.details.chain]
		})

		if (!account) {
			toggleConnectWalletModal()
			setConnectWalletModalState({
				callback: toggleBuyNowModal
			})
			return
		}

		try {
			if (walletType === 'okx') {
				if (
					listing.details.chain !== chainMap[parseInt(window.okxwallet.chainId)]
				) {
					await switchNetwork(chainIdMap[listing.details.chain])
				}
			} else {
				if (listing.details.chain === 'binance' && chain.id !== chainId.BINANCE) {
					await switchNetworkAsync(chainId.BINANCE)
				} else if (
					listing.details.chain === 'ethereum' &&
					chain.id !== chainId.ETHEREUM
				) {
					await switchNetworkAsync(chainId.ETHEREUM)
				} else if (
					listing.details.chain === 'polygon' &&
					chain.id !== chainId.AMOY
				) {
					await switchNetworkAsync(chainId.AMOY)
				}
			}

			toggleBuyNowModal()
		} catch (error) {
		}
	}

	const toggleCart = (asset) => (account) => {
		axios
			.post('asset/cart-mutation', {
				orderId: asset.details.orderId,
				userAddress: account,
				status: !asset?.details?.isCart
			})
			.then((res) => {
				if (res.data) {
					if (res?.data?.isCart) {
						toast.success('Added to cart.')
					} else {
						toast.error('Removed from cart.')
					}
					queryClient.invalidateQueries(['listings'])
					queryClient.invalidateQueries(['nfts'])
					queryClient.invalidateQueries(['asset'])
					queryClient.invalidateQueries(['cart'])
					setTimeout(() => {
						setCart(true)
					}, 5000);
				}
			})
			.catch((error) => { setCart(true) })
	}

	const handleToggleCart = () => {
		setCart(false)
		if (!account) {
			toggleConnectWalletModal()
			setConnectWalletModalState({
				callback: toggleCart(listing)
			})
			return
		} else {
			toggleCart(listing)(account)
		}
	}

	const handleCancelListing = async (listing) => {
		setHeading('Cancel Listing')
		togglePurchaseLoadingModal()
		try {
			if (listing.details.saleType === 'auction') {
				if (asset.details.chain !== chainMap[chain.id]) {
					await switchNetworkAsync(chainIdMap[asset.details.chain])
				}

				await cancelAuction({
					owner: listing.details.fromAddress,
					nftAddress: asset.details.contractAddress,
					tokenId: asset.details.nftId,
					orderId: listing.details.orderId
				})
				toast.success('Listing canceled')
			} else {
				await cancelListing(listing.details.orderId)
				toast.success('Listing canceled')
			}
		} catch (error) {
			toggleSomethingWentWrongModal()
		} finally {
			togglePurchaseLoadingModal()
		}
	}

	const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true'

	return (
		<tr ref={ref ? ref : null}>
			<td>{`${parseFloat(
				currentPrice
					? currentPrice.toFixed(8)
					: listing.details.price.toFixed(8)
			)} ${listing.details.currency}`}</td>
			<td>${parseFloat(listing.details.usdPrice.toFixed(4))}</td>
			<td>{listing.details.quantity}</td>
			<td className='yellowTxt'>
				<Link
					to={`/profile/${listing.details.fromAddress}`}
					style={{ textDecoration: 'none', color: 'inherit' }}
				>
					{listing.details.from ? listing.details.from : '-'}
				</Link>
			</td>
			<td className='text-right'>
				{!!account &&
					isLoggedIn &&
					listing?.details?.address !== account.toLowerCase()
					? listing?.details?.saleType === 'fixed' &&
					(!listing?.details?.isCart ? (
						cart ? <button
							className='btn gradientBtn d-inline-flex'
							onClick={handleToggleCart}
						>
							<svg
								width='19'
								height='16'
								viewBox='0 0 19 16'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M17.2188 2C17.7188 2 18.0625 2.46875 17.9688 2.9375L16.5625 9.4375C16.4688 9.78125 16.1875 10 15.8125 10H6.0625L6.34375 11.5H15.1562C15.6562 11.5 16 11.9688 15.9062 12.4375L15.875 12.5625C16.25 12.9375 16.5 13.4375 16.5 14C16.5 15.125 15.5938 16 14.5 16C13.375 16 12.5 15.125 12.5 14C12.5 13.6562 12.5938 13.3125 12.75 13H8.21875C8.375 13.3125 8.5 13.6562 8.5 14C8.5 15.125 7.59375 16 6.5 16C5.375 16 4.5 15.125 4.5 14C4.5 13.4688 4.6875 13 5.03125 12.625L2.875 1.5H0.375C0.15625 1.5 0 1.34375 0 1.125V0.375C0 0.1875 0.15625 0 0.375 0H3.5C3.84375 0 4.15625 0.28125 4.21875 0.625L4.5 2H17.2188ZM6.5 14.75C6.90625 14.75 7.25 14.4375 7.25 14C7.25 13.5938 6.90625 13.25 6.5 13.25C6.0625 13.25 5.75 13.5938 5.75 14C5.75 14.4375 6.0625 14.75 6.5 14.75ZM14.5 14.75C14.9062 14.75 15.25 14.4375 15.25 14C15.25 13.5938 14.9062 13.25 14.5 13.25C14.0625 13.25 13.75 13.5938 13.75 14C13.75 14.4375 14.0625 14.75 14.5 14.75ZM15.2188 8.5L16.3125 3.5H4.78125L5.75 8.5H15.2188Z'
									fill='#0A0A0B'
								/>
							</svg>
						</button> : ''
					) : (
						cart ? <button
							className='btn gradientBtn d-inline-flex'
							onClick={handleToggleCart}
						>
							<img src={minus} alt='shoppingcart' />
						</button> : ''
					))
					: ''}
			</td>
			<td className='text-right'>
				{!!account &&
					listing.details.address === account.toLowerCase() ? (
					listing.details.saleType === 'fixed' ? (
						<button
							type='button'
							className='btnAccept'
							onClick={() => edit(listing)}
							style={{ width: '100px' }}
						>
							{t('Edit Listing')}
						</button>
					) : (
						<button
							type='button'
							className='btnAccept'
							onClick={() => handleCancelListing(listing)}
							style={{ width: '100px' }}
						>
							{t('Cancel Listing')}
						</button>
					)
				) : listing.details.saleType === 'fixed' ||
					listing.details.saleType === 'dutch-auction' ? (
					<button
						type='button'
						className='btnAccept'
						onClick={() => buy(listing)}
						style={{ width: '100px' }}
					>
						{t(
							listing.details.saleType === 'fixed'
								? t('Buy Now')
								: t('Bid To Buy')
						)}
					</button>
				) : null}
			</td>
		</tr>
	)
})

export function AssetListings({ asset }) {
	const { t } = useTranslation()
	const params = useParams()
	const { address: account } = useAccount()
	const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
		useInfiniteQuery({
			queryKey: ['listings', params.collectionAddress, params.nftId],
			queryFn: ({ pageParam = 1 }) =>
				fetchListings(
					params.collectionAddress,
					params.nftId,
					pageParam,
					account
				),
			getNextPageParam: (lastPage, allPages) => {
				return lastPage.length ? allPages.length + 1 : undefined
			},
			enabled: !!(params.collectionAddress && params.nftId)
		})
	const intersectionObserver = useRef()
	const lastListingRef = useCallback(
		(listing) => {
			if (isFetchingNextPage) {
				return
			}

			if (intersectionObserver.current) {
				intersectionObserver.current.disconnect()
			}

			intersectionObserver.current = new IntersectionObserver(
				(listings) => {
					if (listings[0].isIntersecting && hasNextPage) {
						fetchNextPage()
					}
				}
			)

			if (listing) {
				intersectionObserver.current.observe(listing)
			}
		},
		[isFetchingNextPage, hasNextPage, fetchNextPage]
	)

	const listings = data?.pages.map((page) => {
		return page.map((listing, i) => {
			if (page.length === i + 1) {
				return (
					<Listing
						key={listing._id}
						ref={lastListingRef}
						listing={listing}
						asset={asset}
					/>
				)
			}
			return <Listing key={listing._id} listing={listing} asset={asset} />
		})
	})
	const hasNoListings = listings?.[0].length === 0

	if (hasNoListings) {
		return (
			<div className='h464pxCenter'>
				<div class='noResf text-center'>
					<img class='img-fluid' src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : cndds} alt='' />
					<h1>{t('No Data')}</h1>
				</div>
			</div>
		)
	}

	return (
		<div className='table-responsive scroller'>
			<table className='table table-borderless table-hover mb-0 hmecollection'>
				<thead className='trd_head'>
					{!hasNoListings && <tr>
						<th>{t('Price')}</th>
						<th>{t('USD Price')}</th>
						<th>{t('Quantity')}</th>
						<th>{t('From')}</th>
						<th></th>
						<th></th>
					</tr>}
				</thead>
				<tbody className='trdtable_body'>{listings}</tbody>
			</table>
		</div>
	)
}
