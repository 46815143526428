import Axios from 'axios'

import { BACKEND_URL } from '../config/constants'

export const axios = Axios.create({
	baseURL: BACKEND_URL
})

axios.interceptors.request.use(
	(config) => {
		const accessToken = localStorage.getItem('access-token')
		if (accessToken) {
			config.headers['authorization'] = 'Bearer ' + accessToken
		}

		return config
	},
	(error) => {
		Promise.reject(error)
	}
)

axios.interceptors.response.use(
	(response) => {
		return response.data
	},
	(error) => {
		return Promise.reject(error)
	}
)
