import { useQuery } from '@tanstack/react-query'

import DataService from '../../../lib/DataService'

const { getRequest } = DataService()

export async function fetchCollectionOwnerList(collectionAddress, pageParam) {
	
	const response = await getRequest(`collection/owners_list?contractAddress=${collectionAddress}&page=${pageParam}`)
	return response.data
}

export function useFetchCollectionOwnerList(collectionAddress) {
	return useQuery({
		queryKey: ['nfts', collectionAddress],
		queryFn: () => fetchCollectionOwnerList(collectionAddress),
		enabled: !!collectionAddress
	})
}

export const fetchDistributionList = ({ contractAddress }) => {
	return getRequest(`collection/owners_distribution?contractAddress=${contractAddress}&page=1`)

}

export const useFetchDistributionList = ({ contractAddress }) => {
	return useQuery({
		queryKey: ['distribution_list', contractAddress],
		queryFn: () => fetchDistributionList({ contractAddress }),
		enabled: !!contractAddress

	})

}