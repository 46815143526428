import { useQuery } from '@tanstack/react-query'

import DataService from '../../../lib/DataService'

const { getRequest } = DataService()

export function fetchCollectionTraits(collectionAddress) {
	
	return getRequest(`collection/traits?address=${collectionAddress}`)

}

export function useFetchCollectionTraits(collectionAddress) {
	return useQuery({
		queryKey: ['collection-traits', collectionAddress],
		queryFn: () => fetchCollectionTraits(collectionAddress),
		enabled: !!collectionAddress
	})
}
