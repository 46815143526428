import Skeleton from 'react-loading-skeleton'

export function CollectionBannerPlaceholder() {
	return <Skeleton height={318} containerClassName='banner-skeleton' />
}

export function CollectionDetailsPlaceholder() {
	return (
		<div className='col-lg-6 order-lg-2 text-center'>
			<div className='details-skeleton'>
				<div className='imgtag'>
					<Skeleton />
				</div>
				<div className='text-center'>
					<div className='userName'>
						<Skeleton />
					</div>
					<div className='userName usernameby'>
						<span className='fw-400'>
							<Skeleton />
						</span>{' '}
					</div>
					<div className='userDescpara'>
						<Skeleton />
					</div>
					<div>
						<button className='readmorebtn'>
							<Skeleton />
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export function CollectionStatBoxPlaceholder() {
	return (
		<div className='row justify-content-center stat-skeleton'>
			<div className='col-lg-11'>
				<Skeleton />
			</div>
		</div>
	)
}
