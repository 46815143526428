import React, { useEffect, useState } from 'react';

import '../Profile/listviewItem.scss';
import style from '../Profile/profile.module.scss';

import tblUserImg1 from '../../assets/images/collection/userImage.png';
import tblUserImg2 from '../../assets/images/itemIcons/deGods.png';
import tblUserImg3 from '../../assets/images/profile/profileProfPic.png';
import noItemfoundImage from '../../assets/images/collection/noItemfoundImage.svg';
import cnddsLight from '../../assets/images/cnddsLight.svg'
import defaultImg from '../../assets/images/header/userProfileImg.png'
import yellowTick from '../../assets/images/collection/yellow-tick_20px.svg';
import settingsIcon from '../../assets/images/mycollection/settingsIcon.svg';
import ethIconSm from '../../assets/images/ethIconSm.png';
import bnbIconSm from '../../assets/images/bnbIconSm.png';
import maticIconSm from '../../assets/images/maticIconSm.png';
import { axios } from '../../lib/axios'
import { useToast } from '../../utils/toast/customSuccess'
import { useNavigate } from 'react-router-dom';
import ScaleLoader from "react-spinners/ScaleLoader";
import PaginationComponent from 'react-reactstrap-pagination'
import {
    useAccount,
    useConnect,
    useNetwork,
    useSwitchNetwork,
    useDisconnect
} from 'wagmi'
import { useTranslation } from 'react-i18next';
import { formatAmount } from '../../utils/formatAmount';
import loader from '../../assets/images/Loader.png'
import ResponsivePagination from 'react-responsive-pagination';
import { nativeCoinMap, chainIconMap } from '../../utils/getFilterData';
import DataService from '../../lib/DataService';
const landPerRow = 6

const ListViewItem = () => {

    const { t } = useTranslation()
    const { address, isConnected } = useAccount()
    const { postRequest } = DataService()

    const toast = useToast()
    const [next, setNext] = useState(landPerRow)
    const [getdata, setGetdata] = useState("")
    const [page, setPage] = useState(1)
    const [count1, setCount] = useState('')

    const [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#ffffff");

    useEffect(() => {
        getcollection()
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [address, page])

    function handlePageChange(page) {
        setPage(page);
    }

    const getcollection = async () => {
        setLoading(true)
        const collection = await postRequest("user/my-collections", { "ownerAddress": address, "page": page, "limit": 10 })
        if (collection.status == true) {
            setGetdata(collection.data)
            setCount(Math.ceil(collection.count / 15))
            setLoading(false)
        }
        else {
            setGetdata('')
            setCount('')
            setLoading(false)
        }
    }

    const navigate = useNavigate()
    const editcollection = (data) => {
        navigate("/collection/" + data.slug)
    }

    const createcollection = () => {
        navigate("/choosecollection")
    }

   
    return (
        <>
            <div className="row">
                <div className="col-12">

                    <div className="col-12 d-flex align-items-center">
                        <h3 className="collectionSecHeading">{t("My Collections")}</h3>
                        {getdata?.length > 0 ?
                            <button className='btn gradientBtn ml-auto' onClick={createcollection}>{t("Create Collection")}</button>
                            :
                            ""
                        }
                    </div>


                    <div className={loading ? "spinner" : "d-none"}>
                        <img src={loader} alt='' className='spinAnimate' />
                    </div>
                    {getdata?.length > 0 ?

                        <div className='table-responsive'>
                            <table className='table table-borderless listViewTable collapseTable'>
                                <tbody>
                                    {getdata && getdata.map((item, index) =>
                                    (
                                        <>
                                            <tr onClick={() => editcollection(item)} style={{ cursor: "pointer" }}>
                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='tblImg'>{!item?.logoImage?.startsWith(process.env.S3_URL) && !item?.logoImage?.startsWith('ipfs') ? <img src={item?.logoImage !== '' ? item?.logoImage : defaultImg} alt={`item.imgsrc`} /> : <img src={defaultImg} alt={`item.imgsrc`} />}</div>
                                                        <div>
                                                            <div className='fw-600'>{item.collectionName ? item.collectionName : "-"} <img src={item.verification == "Verified" ? yellowTick : ""} height="18" className="ml-1" /> <img src={chainIconMap[item.chain]} height="18" /></div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <div className='smTxt dark-text-secondary'>{t("Volume")}</div>
                                                        {item.volume == "0" ?
                                                            <div className='fw-600' style={{ opacity: "0.5" }}>--</div>
                                                            :
                                                            <div className='fw-600'>{(formatAmount(item.volume, 8))} {nativeCoinMap[item.chain]}</div>
                                                        }
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <div className='smTxt dark-text-secondary'>{t("Floor Price")}</div>
                                                        {item.floorPrice == "0" ?
                                                            <div className='fw-600' style={{ opacity: "0.5" }}>--</div>
                                                            :
                                                            <div className='fw-600'>{(formatAmount(item?.floorPrice, 8))} {item.floorCurrency}</div>
                                                        }
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <div className='smTxt dark-text-secondary'>{t("Sales")}</div>
                                                        {item.soldItems == 0 ?
                                                            <div className='fw-600' style={{ opacity: "0.5" }}>--</div>
                                                            :
                                                            <div className='fw-600'>{item.soldItems}</div>
                                                        }
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <div className='smTxt dark-text-secondary'>{t("Owner")}</div>
                                                        {item.owners == "0" ?
                                                            <div className='fw-600' style={{ opacity: "0.5" }}>--</div>
                                                            :
                                                            <div className='fw-600'>{item.owners}</div>
                                                        }
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <div className='smTxt dark-text-secondary'>{t("Items")}</div>
                                                        {item.totalItems == "0" ?
                                                            <div className='fw-600' style={{ opacity: "0.5" }}>--</div>
                                                            :
                                                            <div className='fw-600'>{item.totalItems}</div>
                                                        }
                                                    </div>
                                                </td>
                                                <td className='text-right' onClick={(e) => e.stopPropagation(navigate("/editcollection/" + item._id))}>
                                                    <button className={style.iconBtn}><img src={settingsIcon} /></button>
                                                </td>
                                            </tr>
                                        </>
                                    ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        :
                        <div className='noItemFound'>
                            {loading == false ?
                                <>
                                    <div className='text-center'>
                                        <img src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : noItemfoundImage} alt="noItemfoundImage" className='img-fluid' />
                                    </div>
                                    <div className='nofounttxt mb-4'>
                                        {t("Create your first collection")}!
                                    </div>
                                    <div>
                                        <button className='applyBtn1' onClick={createcollection}>{t("Create Collection")}</button>
                                    </div>
                                </>
                                :
                                ""
                            }
                        </div>
                    }


                    <div className='justify-content-center row'>
                        <div className='col-lg-4 col-md-6 col-sm-8'>
                            <div class="bri_pagenation2">
                                {getdata && getdata.length > 0 ? (
                                    <div className=''>
                                        <nav aria-label="Page ">
                                            <ResponsivePagination
                                                total={count1}
                                                current={page}
                                                onPageChange={page => handlePageChange(page)}
                                                previousLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.125 5.75L5.71875 1.125C5.84375 0.96875 6.09375 0.96875 6.25 1.125L6.84375 1.75C7 1.875 7 2.125 6.84375 2.28125L3.15625 6L6.84375 9.75C7 9.90625 7 10.125 6.84375 10.2812L6.25 10.9062C6.09375 11.0625 5.84375 11.0625 5.71875 10.9062L1.125 6.28125C0.96875 6.125 0.96875 5.90625 1.125 5.75Z" fill="#F7F7F7"></path></svg>}
                                                nextLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.84375 6.28125L2.28125 10.9062C2.125 11.0625 1.875 11.0625 1.75 10.9062L1.125 10.2812C0.96875 10.125 0.96875 9.90625 1.125 9.75L4.8125 6L1.125 2.28125C0.96875 2.125 0.96875 1.875 1.125 1.75L1.75 1.125C1.875 0.96875 2.125 0.96875 2.28125 1.125L6.84375 5.75C7 5.90625 7 6.125 6.84375 6.28125Z" fill="#F7F7F7"></path></svg>}
                                                previousClassName="page-link-1"
                                                nextClassName="page-link-1"
                                            />
                                        </nav>

                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ListViewItem;