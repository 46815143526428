import React, { useState, useEffect, useLayoutEffect, useRef, useContext } from 'react';

import {  DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import CollectionAnalytics from '../../features/collection/components/CollectionAnalytics';
import { ThemeContext } from '../context/context';

const AnalyticsCollectionMain = () => {

  const { t } = useTranslation()

  const [isFilterView, setfilterView] = useState(true)

  const filterShow = () => {
    setfilterView(!isFilterView)
  }

  const CheckTick = () => {
    return (
      <>
        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.1953 0.46875C10.3125 0.351562 10.5 0.351562 10.5938 0.46875L11.2734 1.125C11.3672 1.24219 11.3672 1.42969 11.2734 1.52344L4.24219 8.55469C4.125 8.67188 3.96094 8.67188 3.84375 8.55469L0.703125 5.4375C0.609375 5.32031 0.609375 5.13281 0.703125 5.03906L1.38281 4.35938C1.47656 4.26562 1.66406 4.26562 1.78125 4.35938L4.03125 6.63281L10.1953 0.46875Z" fill="#FFA900" />
        </svg>
      </>
    )
  }

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);



  const ref = useRef(null)
  const [divWidth, setDivWidth] = useState('')
  const [divWidth1, setDivWidth1] = useState([window.innerWidth])

  const handleResize = () => {
    setDivWidth(ref.current.offsetWidth)
    setDivWidth1([window.innerWidth])
  }
  useEffect(() => {
    if (ref.current) window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [ref])

  useEffect(() => {
    const handleWindowResize = () => {
      setDivWidth1(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useLayoutEffect(() => {
    setDivWidth(ref.current.offsetWidth)
    setDivWidth1([window.innerWidth])
  }, [])


  const analyticsfilter = [

    {
      name: t("All Time"),
      value: "All Time",
      sortField: "",
    },
    {
      name: t("Last Hour"),
      value: "Last Hour",
      sortField: "1",
    },
    {
      name: t("Last 6 Hours"),
      value: "Last 6 Hours",
      sortField: "6",
    },
    {
      name: t("Last 24 Hours"),
      value: "Last 24 Hours",
      sortField: "24",
    },
    {
      name: t("Last 7 Days"),
      value: "Last 7 Days",
      sortField: "7",
    },
    {
      name: t("Last 30 Days"),
      value: "Last 30 Days",
      sortField: "30",
    },
    {
      name: t("Last 90 Days"),
      value: "Last 90 Days",
      sortField: "90",
      sortOrder: "desc"
    },
  ]


  const { Analyticsfilter, setAnalyticsFilter } = useContext(ThemeContext)
  const [data, setData] = useState("")

  const chooseFilterdata = (data) => {
    setData(data.name)
    setAnalyticsFilter(data.sortField)
  }

  return (
    <>
      <div className="mb-5" ref={ref}>
        <div className="row">
          <div className="col-lg-12">
            <div className="itemWizard">
              <div className={`viewItemscard ${'fullWidth'}`}>
                <div className="form-inline">
                  <div className="form-group formInputs mr-3 flex-grow-1">
                  </div>
                  <div className="form-group formInputs mb-2">

                    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className='customdropdown'>
                      <DropdownToggle>
                        <span>{t(data == "" ? "All Time" : data)}</span> <i className={`fas ${dropdownOpen ? 'fa-angle-up' : 'fa-angle-down'}`}></i>
                      </DropdownToggle>
                      <DropdownMenu right>
                        {analyticsfilter && analyticsfilter.map((data, index) => (
                          <DropdownItem onClick={() => chooseFilterdata(data)}><div>{data.name}</div><div>{Analyticsfilter == data.sortField ? <CheckTick /> : ""}</div></DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
                <div>
                  <CollectionAnalytics />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AnalyticsCollectionMain;