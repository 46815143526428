import { useCallback, useState } from 'react'
import { useAccount, useSwitchNetwork as useSwitchNetworkWallet } from 'wagmi'

import { useToast } from '../utils/toast/customSuccess'
import { PUBLIC_NODES } from '../config/nodes'
import { chainId } from '../config/constants/chains'

const chainNames = {
	[chainId.BINANCE]: 'Binance Testnet',
	[chainId.ETHEREUM]: 'Sepholia Testnet',
	[chainId.POLYGON]: 'Amoy Testnet',
	[chainId.AMOY]: 'Amoy Testnet'

}

export function useSwitchNetwork() {
	const [loading, setLoading] = useState(false)

	const {
		switchNetworkAsync: _switchNetworkAsync,
		isLoading: _isLoading,
		...switchNetworkArgs
	} = useSwitchNetworkWallet({
		throwForSwitchChainNotSupported: true
	})
	const { isConnected } = useAccount()
	const toast = useToast()

	const isLoading = _isLoading || loading
	const wallet = JSON.parse(localStorage.getItem('wagmi_v1.1.wallet'))

	const switchNetworkAsync = useCallback(
		async (chainId) => {
			if (isConnected && typeof _switchNetworkAsync === 'function') {
				if (wallet === 'okx') {
					const okxwallet = window.okxwallet

					if (!okxwallet) return

					try {
						await okxwallet.request({
							method: 'wallet_switchEthereumChain',
							params: [{ chainId: `0x${chainId.toString(16)}` }]
						})
					} catch (error) {
						if (error.code === 4902) {
							return await okxwallet.request({
								method: 'wallet_addEthereumChain',
								params: [
									{
										chainId: `0x${chainId.toString(16)}`,
										rpcUrls: PUBLIC_NODES[chainId],
										chainName: chainNames[chainId]
									}
								]
							})
						} else {
							toast.error(
								'Error connecting, please retry and confirm in wallet!'
							)
							throw new Error(
								'Error connecting, please retry and confirm in wallet!'
							)
						}
					}
				}

				return _switchNetworkAsync(chainId)
					.then((c) => c)
					.catch((e) => {
						
					})
					.finally(() => setLoading(false))
			}

			return new Promise(() => { })
		},
		[isConnected, _switchNetworkAsync, isLoading, setLoading, toast]
	)

	return {
		...switchNetworkArgs,
		switchNetworkAsync,
		isLoading
	}
}
