import { forwardRef, useState } from "react";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { handleImageError } from "../../../hooks/useHandleImg";
import { useConnectWalletModalActions } from "../../../components/modals/connect-wallet/store";
import { useCheckoutModalActions } from "../../../components/modals/checkout/store";
import { useBuyNowModalActions } from "../../../components/modals/buy-now/store";
import { chainId } from "../../../config/constants/chains";
import { timeUntil } from "../../../utils/formatRoundTime";

import ETHIcon from "../../../assets/images/collection/ETHIcon.png";
import BNBIcon from "../../../assets/images/bnbIconSm.png";
import MaticIcon from "../../../assets/images/maticIconSm.png";
import shoppingcart from "../../../assets/images/collection/shopping-cart.svg";
import minus from "../../../assets/images/minus.svg";
import { axios } from "../../../lib/axios";
import { useToast } from "../../../utils/toast/customSuccess";
import defaultImage from "../../../assets/images/homeExplorer/placeholder.png";
import { formatCompactNumber } from "../../../utils/formatCompactNumber";
import { UncontrolledTooltip } from "reactstrap";
import { chainIconMap, chainIdMap, chainMap } from "../../../utils/getFilterData";
import { useOkxSwitchNetwork } from "../../../hooks/useOkxSwitchNetwork";
import DataService from "../../../lib/DataService";


export const NftCard = forwardRef(function NftCard(
  { item, onClick, classNames },
  ref
) {
  const { t } = useTranslation();
  const [videoError, setVideoError] = useState(false)
  const [cart, setCart] = useState(true)
  const { toggleConnectWalletModal, setConnectWalletModalState } =
    useConnectWalletModalActions();
  const { setAsset: setCheckoutModalAssetState } = useCheckoutModalActions();
  const { address: account, isConnected } = useAccount();
  const { switchNetworkAsync } = useSwitchNetwork();
  const { switchNetwork } = useOkxSwitchNetwork()
  const walletType = localStorage.getItem('walletType')

  const { postRequest } = DataService()

  const { chain } = useNetwork();
  const { toggleBuyNowModal, setAsset: setBuyNowModalAssetState } =
    useBuyNowModalActions();
  const isVideo = item.details.asset.endsWith(".mp4");
  const timeUntilsExpiry =
    item.details.isMarketplace && timeUntil(item.details.saleExpiry);
  const toast = useToast();
  const queryClient = useQueryClient();

  const isMarketplace = item.details.isMarketplace;
  const isOnFixed = item.details.saleType === "fixed";
  const isOwner = account?.toLowerCase() === item?.details?.seller;
  const isCart = item?.details?.isCart;
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
  const now = Math.floor(new Date().getTime() / 1000);
  const isAuctionExpired = now > item.details.saleExpiry;

  const handleBuyNow = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setBuyNowModalAssetState(item);
    setCheckoutModalAssetState([item]);
    setConnectWalletModalState({ chainId: chainIdMap[item.details.chain] });

    if (!account) {
      toggleConnectWalletModal();
      setConnectWalletModalState({
        callback: toggleBuyNowModal,
      });
      return;
    }

    try {
      if (walletType === 'okx') {
        if (
          item.details.chain !== chainMap[parseInt(window.okxwallet.chainId)]
        ) {
          await switchNetwork(chainIdMap[item.details.chain])
        }
      } else {
        if (item.details.chain === 'binance' && chain.id !== chainId.BINANCE) {
          await switchNetworkAsync(chainId.BINANCE)
        } else if (
          item.details.chain === 'ethereum' &&
          chain.id !== chainId.ETHEREUM
        ) {
          await switchNetworkAsync(chainId.ETHEREUM)
        } else if (
          item.details.chain === 'polygon' &&
          chain.id !== chainId.AMOY
        ) {
          await switchNetworkAsync(chainId.AMOY)
        }
      }
    

      toggleBuyNowModal();
    } catch (error) { }
  };

  const toggleCart = (asset) => (account) => {
    postRequest("asset/cart-mutation", {
      orderId: asset.details.orderId,
      userAddress: account,
      status: !asset?.details?.isCart,
    })
      .then((res) => {
        if (res?.data) {
          toast.success(
            `Item ${res?.data?.isCart ? "added to" : "removed from"} the cart`
          );
          queryClient.invalidateQueries(["nfts"]);
          queryClient.invalidateQueries(["cart"]);
          queryClient.refetchQueries(['asset'])
          queryClient.invalidateQueries(['listings'])
          setTimeout(() => {
            setCart(true)
          }, 5000);
        }
      })
      .catch((error) => { setCart(true) });
  };

  const handleToggleCart = (e) => {
    setCart(false)
    e.preventDefault();
    e.stopPropagation();
    if (!account) {
      toggleConnectWalletModal();
      setConnectWalletModalState({
        callback: toggleCart(item),
      });
      return;
    } else {
      toggleCart(item)(account);
    }
  };

  const handleVideoError = () => {
    setVideoError(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClick();
    }
  };


  return (
    <div
      className={classNames ? classNames : "grid-col hover"}
      ref={ref ? ref : null}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      style={{
        objectFit: "cover",
        width: "100%",
      }}
      role="button"
    >
      <Link
        to={`/assets/${item.details.chain}/${item.details.contractAddress}/${item.details.nftId}`}
        style={{ textDecoration: "none" }}
      >
        <div className="gridItemCard">
          <div className="gItemImg">
            {!isVideo ? (
              !item?.details?.asset?.startsWith(process.env.S3_URL) &&
                !item?.details?.asset?.startsWith("ipfs") && item?.details?.asset !== null ? (
                <img
                  src={
                    item?.details?.asset ? item?.details?.asset : defaultImage
                  }
                  alt={item?.details?.name}
                  className="img-fluid w-100 itemImage"
                  onError={handleImageError}
                />
              ) : (
                <img
                  src={defaultImage}
                  alt={item?.details?.name}
                  className="img-fluid w-100 itemImage"
                />
              )
            ) : (
              videoError ? <img
                src={defaultImage}
                alt={item?.details?.name}
                className="img-fluid w-100 itemImage"
              /> :

                <video
                  controls
                  loop
                  playsInline
                  preload='metadata'
                  controlslist='nodownload'
                  id='video-asset'
                  style={{
                    objectFit: "cover",
                    height: "168px",
                    width: "100%",
                    borderRadius: "12px 12px 0 0",
                  }}
                  className="img-fluid w-100 itemImage"
                >
                  <source
                    src={item?.details?.asset || defaultImage}
                    type="video/mp4"
                    onError={handleVideoError}
                  />
                </video>

            )}
            {item?.details?.contractStandard == "ERC1155" ?
              <div className="idTag">#{formatCompactNumber(item?.details?.supply)}</div>
              :
              ""
            }
            {isMarketplace && (
              <div className="hoverImgCnt">
                <div className="d-flex justify-content-between ">
                  <div>
                    <img
                      src={chainIconMap[item.details.chain]}
                      alt="chain"
                      className="cointypeImg"
                      id='detail-chain'
                    />
                  </div>
                  {!isAuctionExpired && (
                    <div className="alarmbadge">
                      <i className="fas fa-alarm-clock"></i>{" "}
                      {`${timeUntilsExpiry} left`}
                    </div>
                  )}

                  <UncontrolledTooltip
                    placement='top'
                    target='detail-chain'
                    className='tooltipCnt red-tooltip'
                    style={{ padding: '12px 16px', borderRadius: '12px', fontFamily: 'Poppins', textTransform: 'capitalize' }}
                  >
                    <img
                      src={chainIconMap[item.details.chain]}
                      alt="chain"
                      className="cointypeImg"
                    /> Chain : {item.details.chain}
                  </UncontrolledTooltip>
                </div>
                {!!account && isLoggedIn && !isOwner && isOnFixed && (
                  <div className="d-flex justify-content-between align-items-center">
                    <button
                      className="buybowbtn rounded-pill"
                      type="button"
                      style={{ flexGrow: "1" }}
                      onClick={handleBuyNow}
                    >
                      {t("Buy Now")}
                    </button>
                    {isConnected && isOnFixed || cart ? (
                      !isCart ? (
                        cart ? <button
                          className="cartBtnCircle ml-1"
                          onClick={handleToggleCart}
                        >
                          <img src={shoppingcart} alt="shoppingcart" />
                        </button> : <></>
                      ) : (
                        cart ? <button
                          className="cartBtnCircle ml-1"
                          onClick={handleToggleCart}
                        >
                          <img src={minus} alt="shoppingcart" />
                        </button> : <></>
                      )
                    ) : ''}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="botcntCard">
            <div className="bottomSmImg">
              {!item?.details?.asset?.startsWith(process.env.S3_URL) &&
                !item?.details?.asset?.startsWith("ipfs") && item?.details?.asset !== null ? (
                <img
                  src={
                    item?.details?.asset ? item?.details?.asset : defaultImage
                  }
                  height={73}
                  alt={item?.details?.name}
                  onError={handleImageError}
                />
              ) : (
                <img src={defaultImage} height={73} alt={item?.details?.name} />
              )}
            </div>
            <div className="botcntTxt">
              <div className="itemName">{item?.details?.name !== "" ? item?.details?.name : `#${item?.details?.nftId}`}</div>
              {isMarketplace && (
                <div className="d-flex justify-content-between">
                  <div className="itemPricetxt">{t("Price")}</div>
                  <div className="itemPriceval">{`${parseFloat(
                    item?.details?.price?.toFixed(8)
                  )} ${item.details.currency}`}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
});
