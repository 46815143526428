import React, { useState, useEffect } from 'react';

import './cmspages.scss';
import loader from '../../assets/images/Loader.png'
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';
import DataService from '../../lib/DataService';
const PrivacyPage = (props) => {

    const [loading, setLoading] = useState(false);
	const { t } = useTranslation()
	const { getRequest } = DataService()

    useEffect(() => {
        fetchprivacy()
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
    }, [])

    const [privacy, setPrivacy] = useState("")

    const fetchprivacy = async () => {
        setLoading(true)
        const privactdata = await getRequest(`home/cms?title=Privacy`)
        if (privactdata.status == true) {
            setLoading(false)
            setPrivacy(privactdata.data)
        }
        else {
            setLoading(false)
            setPrivacy("")
        }
    }
    
    useEffect(() => {
       
        document.title = 'Privacy Policy | Wor NFT'
  }, [])

    return (
        <>

            <div className={`text-center ${loading ? 'spinner' : 'd-none'}`}>
                <img src={loader} alt='' className='spinAnimate' />
            </div>
            <div className="container">
                <div className='row justify-content-center'>
                    <div className="col-lg-9">
                        <div className='d-flex justify-content-between mb-4 flex-wrap align-items-center'>
                            <div className='cmsTit'>{t("Privacy Policy")}</div>
                            <div className='cmsdateTxt'>{t("Last modification")}: {new Date(
                                privacy ? privacy && privacy.updatedAt : ""
                            ). toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</div>
                        </div>
                        {ReactHtmlParser(privacy ? privacy && privacy.pagecontent : "")}
                    </div>
                </div>
            </div>
        </>
    )
}

export default PrivacyPage;