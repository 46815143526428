import { create } from 'zustand'

const useMakeOfferModalStore = create(set => ({
	isOpen: false,
	asset: null,
	actions: {
		toggleMakeOfferModal: () =>
			set(state => ({
				...state,
				isOpen: !state.isOpen
			})),
		setAsset: asset =>
			set(state => ({
				...state,
				asset
			}))
	}
}))

export const useMakeOfferModalState = () =>
	useMakeOfferModalStore(({ isOpen, asset }) => ({
		isOpen,
		asset
	}))

export const useMakeOfferModalActions = () =>
	useMakeOfferModalStore(state => state.actions)
