import { useState, useEffect, useContext, useRef, useMemo } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useAccount } from 'wagmi'
import {
	DropdownMenu,
	DropdownToggle,
	InputGroup,
	ButtonDropdown,
	DropdownItem
} from 'reactstrap'
import { watchAccount } from '@wagmi/core'
import { useTranslation } from 'react-i18next'

import { ThemeContext } from '../../views/context/context'
import { useDebounce } from '../../utils/useDebounce'
import { useGetSearchName } from './api'
import useAuth from '../../hooks/useAuth'
import { useConnectWalletModalActions } from '../modals/connect-wallet/store'
import { useGetBnbBalance, useGetEthBalance } from '../../hooks/useBalance'
import { useCartModalActions } from '../modals/cart/store'
import { useFetchCartItems } from '../modals/cart/fetchCartItems'

import logo from './assets/logo.svg'
import logoLight from './assets/logoLightHeader.svg'
import searchIcon from './assets/search.svg'
import wallet from './assets/wallet.svg'
import uk from './assets/uk.svg'
import chinese from './assets/chinese.svg'
import sun from './assets/sun.svg'
import moon from './assets/moon.svg'
import mintIcon from './assets/mintIcon.svg'
import ethIcon from '../../assets/images/header/ethCoin.png'
import ethIcon1 from '../../assets/images/header/ethCoin.svg'
import polygon from '../../assets/images/header/Polygon.svg'
import ethIconLight from './assets/ethIcon_light.svg'
import bnbIcon from '../../assets/images/header/bnbCoin.png'
import bnbIcon1 from '../../assets/images/header/bnbCoin.svg'
import bnbIconLight from './assets/bnbIcon_light.svg'
import shopcard from '../../assets/images/header/shoppingCart.png'
import profileImg from '../../assets/images/header/userProfileImg.png'
import profileDDImg1 from '../../assets/images/header/profileDDImg1.svg'
import profileDDImg2 from '../../assets/images/header/profileDDImg2.svg'
import profileDDImg3 from '../../assets/images/header/profileDDImg3.svg'
import profileDDImg4 from '../../assets/images/header/profileDDImg4.svg'
import profileDDImg5 from '../../assets/images/header/profileDDImg5.svg'
import yellowTick from '../../assets/images/collection/yellow-tick_20px.svg'
import DataService from '../../lib/DataService'
import style from './hdr.module.scss'
import { handleImageError } from '../../hooks/useHandleImg'
import DefaultImage from '../../assets/images/homeExplorer/placeholder.png'
import { nativeCoinMap } from '../../utils/getFilterData'
import { useToast } from '../../utils/toast/customSuccess'
const Header = props => {
	const [search, setSearch] = useState('')
	const [mbMenu, setMbMenu] = useState(false)
	const [menuClose, setMenuClose] = useState(false)
	const [address, setAddress] = useState()
	const [language, setLanguage] = useState(
		localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en'
	)
	const {
		setExploreTab,
		setActiveTab,
		setActiveTab4,
		setWatchlistTab,
		themeClr,
		setThemeClr,
		resetState
	} = useContext(ThemeContext)

	const toast = useToast()

	const [isLoggedIn, setIsLoggedIn] = useState(
		localStorage.getItem('isLoggedIn')
	)
	const { postRequest } = DataService()

	const { t, i18n } = useTranslation()
	const concernedElementRef = useRef(null)
	const { isConnected, connector, address: account } = useAccount()
	sessionStorage.setItem('WorAddress', address ? address : '')
	const debouncedSearchText = useDebounce(search, 500)
	const encoded = encodeURIComponent(debouncedSearchText)
	const fetchSearchMutation = useGetSearchName({
		search: encoded
	})

	const { balance: bnbBalance, refresh } = useGetBnbBalance()
	const { balance: ethBalance } = useGetEthBalance()

	const [isOpen, setIsOpen] = useState(false)
	const [isOpen1, setIsOpen1] = useState(false)
	const [isOpen2, setIsOpen2] = useState(false)
	const [isOpen3, setIsOpen3] = useState(false)
	const [isOpen4, setIsOpen4] = useState(false)

	const [getprofile, setGetProfile] = useState('')
	const [width, setWidth] = useState(window.innerWidth)

	const sideMenuToggle = () => {
		if (width < 1200) {
			setMbMenu(false)
		}
	}

	useEffect(() => {
		if (props?.profilePic && props?.username) {
			let temp = {}
			temp = getprofile
			temp.logo = props?.profilePic
			temp.name = props?.username
			setGetProfile(temp)
		}
		GetProfile()
	}, [props.profilePic, props.username])

	const handleLanguage = language => {
		i18n.changeLanguage(language)
		localStorage.setItem('lang', language)
		setLanguage(language)
	}

	const handleSearch = data => {
		setMenuClose(true)
		setSearch(data)
	}

	const handleClose = () => {
		setSearch('')
		setMenuClose(false)
	}

	const menuToggle = () => {
		setMbMenu(!mbMenu)
	}
	const [scroll, setScroll] = useState(false)

	const themeChangeClick = () => {
		setThemeClr(!themeClr)
		localStorage.setItem('light_theme', !themeClr)
		document.body.classList.toggle('light-mode', !themeClr)

	}

	const [modalCart, setModalCart] = useState(false)

	useEffect(() => {
		if (localStorage.getItem('light_theme') == 'true') {
			setThemeClr(true)
			document.body.classList.toggle('light-mode', true)
		} else {
			setThemeClr(false)
			document.body.classList.toggle('light-mode', false)
		}
	}, [themeClr, account])

	const [loading, setLoading] = useState(false)

	const navigate = useNavigate()

	const { logout } = useAuth()
	const { toggleConnectWalletModal } = useConnectWalletModalActions()
	const id = useParams()

	useEffect(() => {
		window.addEventListener('scroll', () => {
			setScroll(window.scrollY > 50)
		})
		GetProfile()
	}, [address, account, width])

	useEffect(() => {
		const unwatch = watchAccount(async account => {
			setAddress(account?.address)
			if (account.address === address || !account.address) return
			try {
				const res = await postRequest('user/connect-wallet', {
					address: account.address,
					type: account.connector.id
				})
				if (res.status) {
					localStorage.setItem('access-token', res.data.authToken)
					sessionStorage.setItem('WorAddress', account.address)
					const update = await postRequest('user/getSingleProfile', {
						address: id.id ? id.id : account.address
					})
					if (update.status == true) {
						setGetProfile(update.data)
					} else {
						setGetProfile('')
					}
				}
			} catch (error) { }
		})

		return () => {
			unwatch()
		}
	}, [address, account])

	useEffect(() => {

		const intervalId = setInterval(async () => {
			if (isLoggedIn == 'true') {
				const res = await postRequest('user/importCollectionsUser', {
					address: account
				})
				if (res.status) {
					const response = await postRequest('user/importNfts', {
						address: account
					})
					if (response.status) {
						await postRequest('user/deleteNfts', {
							address: account
						})
					}
				}
			}
		}, 10 * 60 * 1000)
		return () => {
			clearInterval(intervalId)
		}
	}, [address, account, isLoggedIn])

	useEffect(() => {
		if (connector) {
			connector.on('disconnect', logout)
		}

		return () => {
			connector?.off('disconnect', logout)
		}
	}, [connector])

	const GetProfile = async () => {
		setLoading(true)
		const update = await postRequest('user/getSingleProfile', {
			address: id.id ? id.id : account ? account : ''
		})
		if (update.status == true) {
			setGetProfile(update.data)
		} else {
			setGetProfile('')
		}
	}


	const navigateTab = (data) => {
		setMbMenu(false)
		setExploreTab(data)
	}
	const navigateTab1 = (data) => {
		setMbMenu(false)
		setWatchlistTab(data)
	}

	const handleProfile = (data, e) => {
		e.preventDefault()
		navigate(`/profile/${data}`)
		setMbMenu(false)
		setSearch(' ')
		setMenuClose(false)
	}
	const handleCollection = (data, e) => {
		e.preventDefault()
		navigate(`/collection/${data}`)
		setSearch(' ')
		setMenuClose(false)
		setSearch(' ')
		setMbMenu(false)
	}
	const handleNft = (data, e) => {
		e.preventDefault()
		setMenuClose(false)
		setSearch(' ')
		navigate(`/assets/${data.chain}/${data.contractAddress}/${data.nftId}`)
		setMbMenu(false)
	}
	useEffect(() => {
		const handleClickOutside = e => {
			if (
				concernedElementRef.current &&
				!concernedElementRef.current.contains(e.target)
			) {
				setMenuClose(false)
				setSearch(' ')
			}
		}

		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	const { toggleCartModal } = useCartModalActions()
	const memoizedAccount = useMemo(() => account, [account])
	const { data, isLoading, error } = useFetchCartItems(memoizedAccount)
	const itemsCountInCart = data?.data?.length

	useEffect(() => {
		const handleStorage = e => {
			setIsLoggedIn(localStorage.getItem('isLoggedIn'))
		}
		window.addEventListener('storage', handleStorage)

		return () => {
			window.removeEventListener('storage', handleStorage)
		}
	}, [])

	const [videoError, setVideoError] = useState(false)

	const handleVideoError = () => {
		setVideoError(true)
	}

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			resetState();
		}
	};

	const handleKeyDown1 = (event) => {
		if (event.key === 'Enter') {
			setSearch('');
			handleClose();
		}
	};

	const handleKeyDown2 = (event) => {
		if (event.key === 'Enter') {
			menuToggle();
		}
	};

	return (
		<>
			<header className={`${scroll ? 'headerWhite' : ''} ${style.hdr}`}>
				<div className='container-fluid'>
					<div className={`row align-items-center ${style.hdrR}`}>
						<div className='col-auto'>
							<Link to='/' className={style.logo} >
								<img
									className={`${themeClr ? 'd-none' : ''}`}
									onClick={() => resetState()}
									src={logo}
									alt='logo'
									width={67}
									height={32}
									onKeyDown={handleKeyDown} role="button" tabIndex={0}
								/>
								<img
									className={`${themeClr ? '' : 'd-none'}`}
									onClick={() => resetState()}
									src={logoLight}
									alt='logo'
									width={67}
									height={32}
									onKeyDown={handleKeyDown} role="button" tabIndex={0}
								/>
							</Link>
						</div>
						<div className='col'>
							<div className={`${style.hdrRt} ${mbMenu ? style.show : ''}`}>
								<div className='row align-items-center'>
									<div className={`col-lg ${style.srhCol}`}>
										<InputGroup className={style.hdrSrh}>
											<button className='btn' type='button'>
												<img src={searchIcon} alt='search' />
											</button>
											<input
												name='searchName'
												className='form-control'
												type='text'
												value={search === ' ' ? search.trim() : search}
												placeholder={t(
													'Search items, collections and accounts...'
												)}
												onChange={e => handleSearch(e.target.value)}
												autoComplete='off'
											/>
											<span
												className={`${menuClose ? 'clsIcn' : 'd-none'}`}
												onClick={() => {
													setSearch('')
													handleClose()
												}}
												onKeyDown={handleKeyDown1}
												role="button" tabIndex={0}
											>
												<i
													class='fa fa-times'
													style={{
														cursor: 'pointer'
													}}
													aria-hidden='true'
												></i>
											</span>
										</InputGroup>

										{menuClose === true ? (
											<div
												className={`scroller ${style.filterSubmenu}`}
												ref={concernedElementRef}
											>
												<span className={style.heading}>
													{t('Collections')}
												</span>
												{fetchSearchMutation &&
													fetchSearchMutation?.data?.data?.collections?.length >
													0 ? (
													fetchSearchMutation?.data?.data?.collections?.map(
														item => (
															<div className={style.filterRow}>
																{item.chain == 'polygon' ? <img
																	src={item.logoImage ? item.logoImage : polygon}
																	className={style.logo}
																	onError={handleImageError}
																/> : item.chain == 'binance' ? <img
																	src={item.logoImage ? item.logoImage : bnbIcon1}
																	className={style.logo}
																	onError={handleImageError}
																/> : <img
																	src={item.logoImage ? item.logoImage : ethIcon1}
																	className={style.logo}
																	onError={handleImageError}
																/>}
																<Link
																	to='#'
																	onClick={e => handleCollection(item.slug, e)}
																	className={style.srch}
																>
																	<div className='d-flex flex-column'>
																		<h3>
																			{item.collectionName}{' '}
																			{item.isVerified === true && (
																				<img
																					src={yellowTick}
																					className='ml-1'
																				/>
																			)}
																		</h3>
																		<p>
																			{item.totalNfts} {t('Items')}
																		</p>
																	</div>
																</Link>
																<p className='ml-auto'>
																	{parseFloat(item.volume.toFixed(8))}{' '}
																	{nativeCoinMap[item.chain]}
																</p>
															</div>
														)
													)
												) : (
													<div className='text-center'>
														{t("No collections found")}
													</div>
												)}

												<hr className='w-100' />

												<span className={style.heading}>{t("Accounts")}</span>

												{fetchSearchMutation &&
													fetchSearchMutation?.data?.data?.users?.length > 0 ? (
													fetchSearchMutation?.data?.data?.users?.map(item => (
														<div className={style.filterRow}>
															<img
																src={item.logo}
																className={style.logo}
																onError={handleImageError}
															/>
															<Link
																to='#'
																onClick={e => handleProfile(item.address, e)}
																className={style.srch}
															>
																<div className='d-flex flex-column'>
																	<h3>
																		{item.name}
																		{item.isVerified === true && (
																			<img src={yellowTick} className='ml-1' />
																		)}
																	</h3>
																</div>
															</Link>
														</div>
													))
												) : (
													<div className='text-center'>{t("No accounts found")}</div>
												)}

												<hr className='w-100' />
												<span className={style.heading}>{t('Items')}</span>

												{fetchSearchMutation &&
													fetchSearchMutation?.data?.data?.nfts?.length > 0 ? (
													fetchSearchMutation?.data?.data?.nfts?.map(item => (
														<div className={style.filterRow}>


															{item?.imageFormat &&
																!item?.asset?.startsWith(process.env.S3_URL) &&
																!item?.asset?.startsWith('ipfs') &&
																item?.imageFormat !== '' &&
																item?.asset !== null ? (
																item?.imageFormat &&
																	item?.imageFormat.startsWith('image') ? (
																	<img
																		src={
																			item?.asset !== ''
																				? item?.asset
																				: DefaultImage
																		}
																		className='itemIcon mr-3'
																		style={{
																			height: '40px',
																			aspectRatio: '1',
																			borderRadius: '12px'
																		}}
																		onError={handleImageError}
																	/>
																) : videoError ? (
																	<img
																		src={DefaultImage}
																		className='itemIcon mr-3'
																		style={{
																			height: '40px',
																			aspectRatio: '1',
																			borderRadius: '12px'
																		}}
																	/>
																) : (

																	<video
																		loop
																		autoPlay
																		muted
																		playsInline
																		controlslist='nodownload'
																		className='itemIcon mr-3'
																		style={{
																			height: '40px',
																			aspectRatio: '1',
																			borderRadius: '12px',
																			background: '#0a0a0b'
																		}}
																	>
																		<source
																			src={item?.asset}
																			type='video/mp4'
																			onError={handleVideoError}
																		/>
																	</video>
																)
															) : (
																<img
																	src={DefaultImage}
																	className='itemIcon mr-3'
																	style={{
																		height: '40px',
																		aspectRatio: '1',
																		borderRadius: '12px'
																	}}
																/>
															)}

															<Link
																to='#'
																onClick={e => handleNft(item, e)}
																className={style.srch}
															>
																<div className='d-flex flex-column'>
																	<h3>{item.nftName}</h3>
																	<p>{item.collectionName}</p>
																</div>
															</Link>
															<p className='ml-auto'>{t("Royalty")} {item.royalty}</p>
														</div>
													))
												) : (
													<div className='text-center'>{t("No items found")}</div>
												)}
											</div>
										) : null}
									</div>
									<div className='col-xl-auto mr-auto pl-xl-5'>
										<div className={style.hdrMenu}>
											<ButtonDropdown
												className='dropdown'
												isOpen={isOpen}
												toggle={() => {
													setIsOpen(!isOpen)
												}}
											>
												<DropdownToggle
													className='dropdown-toggle'
													data-menu={t('Stats')}
												>
													{t('Stats')}
												</DropdownToggle>
												<DropdownMenu right className='dropdown-menu'>
													<div className={style.menuLs}>
														<DropdownItem
															tag={Link}
															to='/homecollection'
															onClick={sideMenuToggle}
														>
															{t('Ranking')}
														</DropdownItem>

														<DropdownItem
															tag={Link}
															to='/activity'
															onClick={sideMenuToggle}
														>
															{t('Activity')}
														</DropdownItem>
													</div>
												</DropdownMenu>
											</ButtonDropdown>

											<ButtonDropdown
												className='dropdown'
												isOpen={isOpen1}
												toggle={() => {
													setIsOpen1(!isOpen1)
												}}
											>
												<DropdownToggle
													className='dropdown-toggle'
													data-menu={t('Explore')}
												>
													{t('Explore')}
												</DropdownToggle>
												<DropdownMenu right className='dropdown-menu'>
													<div className={style.menuLs}>
														<DropdownItem
															tag={Link}
															to='/explorer'
															onClick={() => {
																resetState()
																navigateTab('all')
																setActiveTab('1')
																sideMenuToggle()
															}}
														>
															{t("All NFT's")}
														</DropdownItem>

														<DropdownItem
															tag={Link}
															to='/explorer'
															onClick={() => {
																resetState()
																navigateTab('Art')
																setActiveTab('2')
																sideMenuToggle()
															}}
														>
															{t('Art')}
														</DropdownItem>
														<DropdownItem
															tag={Link}
															to='/explorer'
															onClick={() => {
																resetState()
																navigateTab('Collectibles')
																setActiveTab('3')
																sideMenuToggle()
															}}
														>
															{t('Collectibles')}
														</DropdownItem>
														<DropdownItem
															tag={Link}
															to='/explorer'
															onClick={() => {
																resetState()
																navigateTab('Music')
																setActiveTab('4')
																sideMenuToggle()
															}}
														>
															{t('Music')}
														</DropdownItem>
														<DropdownItem
															tag={Link}
															to='/explorer'
															onClick={() => {
																resetState()
																navigateTab('Photography')
																setActiveTab('5')
																sideMenuToggle()
															}}
														>
															{t('Photography')}
														</DropdownItem>
														<DropdownItem
															tag='a'
															href='/explorer'
															onClick={() => {
																resetState()
																navigateTab('Sports')
																setActiveTab('6')
																sideMenuToggle()
															}}
														>
															{t('Sports')}
														</DropdownItem>
													</div>
												</DropdownMenu>
											</ButtonDropdown>

											{isLoggedIn !== null &&
												isLoggedIn === 'true' &&
												account ? (
												<ButtonDropdown
													className='dropdown'
													isOpen={isOpen2}
													toggle={() => {
														setIsOpen2(!isOpen2)
													}}
												>
													<DropdownToggle
														className='dropdown-toggle'
														data-menu='Bridge'
													>
														{t("Bridge")}
													</DropdownToggle>
													<DropdownMenu right className='dropdown-menu'>
														<div className={style.menuLs}>
															<DropdownItem
																tag={Link}
																to='/bridge'
																onClick={sideMenuToggle}
															>
																{t("Bridge")}
															</DropdownItem>

															<DropdownItem
																tag={Link}
																to='/bridgehistory'
																onClick={sideMenuToggle}
															>
																{t("History")}
															</DropdownItem>
														</div>
													</DropdownMenu>
												</ButtonDropdown>
											) : (
												''
											)}
										</div>
									</div>
									<div className='col-lg-auto'>
										<div className={style.hdrRtInr}>
											{isLoggedIn !== null &&
												isLoggedIn === 'true' &&
												account ? (
												<Link
													to='/chooseNftChain'
													className={style.hdrMnt}
													onClick={sideMenuToggle}
												>
													<img
														src={mintIcon}
														alt='mintIcon'
														width={20}
														height={20}
													/>
													<span>{t('Mint')}</span>
												</Link>
											) : null}

											{isLoggedIn === null ||
												isLoggedIn === 'false' ||
												isConnected === false ? (
												<button
													className={`btn ${style.btnCntWal}`}
													type='button'
													onClick={() => {
														toggleConnectWalletModal()
														sideMenuToggle()
														setMbMenu(false)
													}}
												>
													<img
														src={wallet}
														alt='wallet'
														width={20}
														height={20}
													/>
													<span>{t('Connect Wallet')}</span>
												</button>
											) : (
												<div className='d-inline-flex align-items-center flex-wrap'>
													<div
														className={`d-inline-block ${style.balanceShow}`}
													>
														<div className='dflex'>
															<span>
																{ethBalance.toFixed(4)}
																<img
																	className={`pl-2 ${themeClr ? 'd-none' : ''}`}
																	src={ethIcon}
																	alt='ethIcon'
																/>
																<img
																	className={`pl-2 ${themeClr ? '' : 'd-none'}`}
																	src={ethIconLight}
																	alt='ethIcon'
																/>
															</span>
															<span className='verticalborder'></span>
															<span>
																{bnbBalance.toFixed(4)}
																<img
																	className={`pl-2 ${themeClr ? 'd-none' : ''}`}
																	src={bnbIcon}
																	alt='bnbIcon'
																/>
																<img
																	className={`pl-2 ${themeClr ? '' : 'd-none'}`}
																	src={bnbIconLight}
																	alt='bnbIcon'
																/>
															</span>
														</div>
													</div>

													<ButtonDropdown
														className={`d-inline-block mx-3 dropdown ${style.lngDd}`}
														isOpen={isOpen3}
														toggle={() => {
															setIsOpen3(!isOpen3)
														}}
													>
														<DropdownToggle className={style.profileImg}>
															<img
																className='imgbrd'
																src={
																	getprofile && getprofile?.logo !== 'undefined'
																		? getprofile?.logo
																		: profileImg
																}
																alt='profileImg'
																width={40}
																height={40}
															/>
														</DropdownToggle>
														<DropdownMenu
															right
														>
															<div className={style.ddHead}>
																<img
																	src={
																		getprofile &&
																			getprofile.logo !== 'undefined'
																			? getprofile.logo
																			: profileImg
																	}
																	alt='profileImg'
																	className='mr-3'
																	width={40}
																	height={40}
																/>
																<div className='d-flex flex-column'>
																	<h3>
																		{getprofile.name
																			? getprofile.name
																			: getprofile?.address?.slice(0, 10) +
																			'...'}
																		{getprofile.logoVerify == 'Verified' ? (
																			<img
																				src={yellowTick}
																				alt='yellowTick'
																				className='ml-1'
																			/>
																		) : (
																			''
																		)}
																	</h3>
																	<p className='text-success'>
																		{t('Wallet Connected')}
																	</p>
																</div>
															</div>
															<div className={style.lngMenuLs}>
																<DropdownItem
																	className='btn'
																	onClick={() => resetState()}
																>
																	<Link to='/profile' onClick={() => {
																		sideMenuToggle()
																		setMbMenu(false)

																	}}>
																		<img
																			src={profileDDImg1}
																			alt='profileDDImg1'
																		/>
																		<span>{t('Profile')}</span>
																	</Link>
																</DropdownItem>
																<DropdownItem className='btn'>
																	<Link
																		to='/homecollection'
																		onClick={() => {
																			navigateTab1('Watchlist')
																			setActiveTab4('3')
																			sideMenuToggle()
																		}}
																	>
																		<img
																			src={profileDDImg2}
																			alt='profileDDImg2'
																		/>
																		<span>{t('Watchlist')}</span>
																	</Link>
																</DropdownItem>
																<DropdownItem className='btn'>
																	<Link
																		to='/mycollection'
																		onClick={sideMenuToggle}
																	>
																		<img
																			src={profileDDImg3}
																			alt='profileDDImg3'
																		/>
																		<span>{t('My Collections')}</span>
																	</Link>
																</DropdownItem>
																<DropdownItem className='btn'>
																	<Link
																		to='/settingsprofile'
																		onClick={sideMenuToggle}
																	>
																		<img
																			src={profileDDImg4}
																			alt='profileDDImg4'
																		/>
																		<span>{t('Settings')}</span>
																	</Link>
																</DropdownItem>
																<DropdownItem className='btn'>
																	<Link
																		to='#'
																		onClick={() => {
																			logout()
																			sideMenuToggle()
																		}}
																	>
																		<img
																			src={profileDDImg5}
																			alt='profileDDImg5'
																		/>
																		<span>{t('Log Out')}</span>
																	</Link>
																</DropdownItem>
															</div>
														</DropdownMenu>
													</ButtonDropdown>

													<button
														className={`btn ml-2 ${style.shcardnoti}`}
														type='button'
														onClick={toggleCartModal}
													>
														<img
															src={shopcard}
															alt='shopcard'
															width={19}
															height={16}
														/>
														<span className='badgecount'>
															{itemsCountInCart}
														</span>
													</button>
												</div>
											)}

											<ButtonDropdown
												className={`dropdown ${style.lngDd}`}
												isOpen={isOpen4}
												toggle={() => {
													setIsOpen4(!isOpen4)
												}}
											>
												<DropdownToggle caret>
													<img
														src={
															language == 'en' || language == 'English'
																? uk
																: chinese
														}
														alt='united kingdom'
														width={16}
														height={16}
													/>
												</DropdownToggle>
												<DropdownMenu right className='dropdown-menu'>
													<div className={style.lngMenuLs}>
														<DropdownItem className='btn'>
															<Link
																to='#'
																onClick={() => {
																	handleLanguage('en')
																	sideMenuToggle()
																}}
															>
																<img
																	className={style.flagIcon}
																	src={uk}
																	alt='united kingdom'
																	width={16}
																	height={16}
																/>
																<span>English</span>
															</Link>
														</DropdownItem>
														<DropdownItem className='btn'>
															<Link
																to='#'
																onClick={() => {
																	handleLanguage('zh')
																	sideMenuToggle()
																}}
															>
																<img
																	className={style.flagIcon}
																	src={chinese}
																	alt='Chinese (Simplified)'
																	width={16}
																	height={16}
																/>
																<span>Chinese (Simplified)</span>
															</Link>
														</DropdownItem>
													</div>
												</DropdownMenu>
											</ButtonDropdown>

											<button
												className={`btn ${style.thTg}`}
												type='button'
												onClick={() => {
													themeChangeClick()
												}}
											>
												{!themeClr ? (
													<img src={sun} alt='sun' width={20} height={20} />
												) : (
													<img src={moon} alt='moon' width={20} height={20} />
												)}
											</button>
										</div>
									</div>
								</div>
							</div>

							<div className={`d-xl-none ${style.hdrRtTg}`}>
								<div
									className={`${style.smClose} ${mbMenu ? style.active : ''}`}
									onClick={menuToggle} onKeyDown={handleKeyDown2}
									role="button" tabIndex={0}
								>
									<svg id={style.closeicon} viewBox='0 0 800 600'>
										<path
											d='M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200'
											id={style.top}
										></path>
										<path d='M300,320 L460,320' id={style.middle}></path>
										<path
											d='M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190'
											id={style.bottom}
											transform='translate(480, 320) scale(1, -1) translate(-480, -318) '
										></path>
									</svg>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>
		</>
	)
}
export default Header
