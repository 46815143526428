import { create } from 'zustand'

const useCollectionOfferSuccessModalStore = create((set) => ({
	isOpen: false,
	collection: null,
	actions: {
		toggleCollectionOfferSuccessModal: () =>
			set((state) => ({
				...state,
				isOpen: !state.isOpen
			})),
		setCollection: (collection) =>
			set((state) => ({
				...state,
				collection
			}))
	}
}))

export const useCollectionOfferSuccessModalState = () =>
	useCollectionOfferSuccessModalStore(({ isOpen, collection }) => ({
		isOpen,
		collection
	}))

export const useCollectionOfferSuccessModalActions = () =>
	useCollectionOfferSuccessModalStore((state) => state.actions)
