import { useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useAccount, useNetwork, useSwitchNetwork, usePublicClient } from 'wagmi'

import { CloseIcon } from '../makeOffer/CloseIcon'
import { useAcceptOfferModalActions, useAcceptOfferModalState } from './store'
import { usePurchaseLoadingModalActions } from '../purchase-loading/store'
import { chainId } from '../../../config/constants/chains'
import { getOrder } from '../../../utils/getOrder'
import { useApproveOffer } from '../../../features/nft/apis/fetchAssetDetails'
import { useSomethingWentWrongModalActions } from '../error/store'
import contracts from '../../../config/constants/contracts'
import { timeUntil } from '../../../utils/formatRoundTime'
import { useCheckoutModalActions } from '../checkout/store'
import { formatAddress } from '../../../utils/formatAddress'
import { handleImageError } from '../../../hooks/useHandleImg'
import { useMarketplaceContract } from '../../../hooks/useContract'
import { useApprovalForAll } from '../../../hooks/useApprovalForAll'
import greenCheck from '../../../assets/images/greenCheck.svg'
import yellowtick20 from '../../../assets/images/yellow-tick_20px.svg'
import defaultImage from '../../../assets/images/homeExplorer/placeholder.png'
import { chainIdMap, chainMap } from '../../../utils/getFilterData'
import { useOkxSwitchNetwork } from '../../../hooks/useOkxSwitchNetwork'
import { useTranslation } from 'react-i18next'
import DataService from '../../../lib/DataService'
export function AcceptOfferModal() {
	const [videoError, setVideoError] = useState(false)

	const { t } = useTranslation()

	const { toggleAcceptOfferModal } = useAcceptOfferModalActions()
	const { isOpen, asset } = useAcceptOfferModalState()
	const {
		togglePurchaseLoadingModal,
		closePurchaseLoadingModal,
		setHeading
	} = usePurchaseLoadingModalActions()
	const { openSomethingWentWrongModal } = useSomethingWentWrongModalActions()
	const { setAsset, toggleCheckoutModal } = useCheckoutModalActions()

	const [modal3, setModal3] = useState(false)
	const toggle3 = () => setModal3(!modal3)

	const { switchNetworkAsync } = useSwitchNetwork()
	const { switchNetwork } = useOkxSwitchNetwork()
	const walletType = localStorage.getItem('walletType')
	const { getRequest, postRequest } = DataService()

	const { chain } = useNetwork()
	const { address: account } = useAccount()
	const { mutateAsync: approveOffer } = useApproveOffer(account)

	const closeBtn = (
		<button className='close' onClick={() => onclose()}>
			<svg
				width='20'
				height='20'
				viewBox='0 0 20 20'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M12.3359 10.5L16.5547 14.7188C16.7891 14.9531 16.7891 15.3828 16.5547 15.6172L15.5781 16.5938C15.3438 16.8281 14.9141 16.8281 14.6797 16.5938L10.5 12.375L6.28125 16.5938C6.04688 16.8281 5.61719 16.8281 5.38281 16.5938L4.40625 15.6172C4.17188 15.3828 4.17188 14.9531 4.40625 14.7188L8.625 10.5L4.40625 6.32031C4.17188 6.08594 4.17188 5.65625 4.40625 5.42188L5.38281 4.44531C5.61719 4.21094 6.04688 4.21094 6.28125 4.44531L10.5 8.66406L14.6797 4.44531C14.9141 4.21094 15.3438 4.21094 15.5781 4.44531L16.5547 5.42188C16.7891 5.65625 16.7891 6.08594 16.5547 6.32031L12.3359 10.5Z'
					fill='#6E737E'
				/>
			</svg>
		</button>
	)

	const onclose = () => {
		setModal3(false)
	}

	const chainid = chainIdMap[asset?.details.chain]
	const publicClient = usePublicClient({
		chainid
	})

	const marketplaceContractAddress = asset
		? contracts.marketplace[chainIdMap[asset.details.chain]]
		: null
	const marketplaceContract = useMarketplaceContract(
		marketplaceContractAddress,
		chainid
	)
	const { approveForAll } = useApprovalForAll(
		asset?.details.contractAddress,
		chainid
	)

	if (!asset) {
		return null
	}

	const timeLeft = timeUntil(asset.bestOffer.expiry)
	const isVideo = asset.details.asset.endsWith('.mp4')
	const worFee = asset.details.platformFee / 100
	const creatorEarning = Number(asset.details.royalty) / 100
	const totalEarningswithoutcreators =
		asset.bestOffer.price * (1 - (worFee + creatorEarning))
	const totalEarningscreators =
		asset.bestOffer.price * (1 - (worFee))
	const currency =
		asset.allowedCurrencies.find(
			(currency) => currency.currencySymbol === asset.bestOffer.currency
		) ??
		asset.allowedCurrencies.find(
			(currency) => currency.currencyType === 'coin'
		)
	const totalEarningsInUsd = totalEarningswithoutcreators * currency.usdPrice
	const totalEarningsInUsd1 = totalEarningscreators * currency.usdPrice


	const handleAcceptOffer = async () => {
		setHeading('Accept Offer')
		togglePurchaseLoadingModal()

		const royaltyFee = asset.details.royalty
		const royaltyReceipt = asset.details.creator
		const isStore = !asset.details.isMinted
		const uri = asset.details.uri ? asset.details.uri : process.env.REACT_APP_PUBLIC_URL
		const order = getOrder({
			asset: {
				...asset,
				offeror: asset.bestOffer.address,
				details: {
					...asset.details,
					price: asset.bestOffer.price,
					decimals: asset.bestOffer.decimals,
					currencyAddress:
						contracts[asset.bestOffer.currency.toLowerCase()] ??
						contracts.zero
				}
			},
			assetType: asset.details.contractStandard,
			orderType: 'buy',
			expiry: asset.bestOffer.expiry,
			copies: 1
		})
		const sig = asset.bestOffer.signTuple

		try {
			if (walletType === 'okx') {
				if (
					asset.details.chain !== chainMap[parseInt(window.okxwallet.chainId)]
				) {
					await switchNetwork(chainIdMap[asset.details.chain])
				}
			} else {
				if (asset.details.chain === 'binance' && chain.id !== chainId.BINANCE) {
					await switchNetworkAsync(chainId.BINANCE)
				} else if (
					asset.details.chain === 'ethereum' &&
					chain.id !== chainId.ETHEREUM
				) {
					await switchNetworkAsync(chainId.ETHEREUM)
				} else if (
					asset.details.chain === 'polygon' &&
					chain.id !== chainId.AMOY
				) {
					await switchNetworkAsync(chainId.AMOY)
				}
			}

			await approveForAll(marketplaceContractAddress)

			const res = await postRequest('asset/buyer-fee-signature', {
				orderTuple: order,
				royalty: Number(royaltyFee) * 10,
				royaltyAddress: royaltyReceipt,
				network: asset.details.chain
			})

			if (!res.status) throw new Error(res.message)

			const buyerFeeSig = [res.data.v, res.data.r, res.data.s]

			const hash = await marketplaceContract.write.buy(
				[
					[order],
					sig,
					[buyerFeeSig],
					[Number(royaltyFee) * 10],
					[royaltyReceipt],
					[isStore],
					[uri],
					asset.bestOffer.totalQuantity
				],
				{ account }
			)

			const transactionReceipt =
				await publicClient.waitForTransactionReceipt({
					hash
				})

			if (transactionReceipt?.status !== 'success') {
				throw new Error('Something went wrong.')
			}

			const params = {
				orderId: asset.bestOffer.offerId,
				address: account,
				contractAddress: asset.details.contractAddress,
				nftId: asset.details.nftId,
				transactionHash: transactionReceipt.transactionHash
			}

			const approveRes = await approveOffer(params)

			if (approveRes.status) {
				setAsset([asset])
				closePurchaseLoadingModal()
				setModal3(true)
			} else {
				throw new Error(approveRes.message)
			}
		} catch (error) {
			openSomethingWentWrongModal()
		} finally {
			toggleAcceptOfferModal()
			closePurchaseLoadingModal()
		}
	}
	const handleVideoError = () => {
		setVideoError(true)
	}


	const Submit = () => {
		setModal3(false)
	}


	return (
		<>
			<Modal
				isOpen={isOpen}
				toggle={toggleAcceptOfferModal}
				centered='true'
				className='mymodal'
				backdropClassName='selCurBp'
				keyboard={false}
			>
				<ModalHeader
					toggle={toggleAcceptOfferModal}
					close={<CloseIcon onClose={toggleAcceptOfferModal} />}
				>
					<h5 className='modal-title'>{t('Accept Offer')}</h5>
				</ModalHeader>
				<ModalBody>
					<div>
						<div className='cartItem'>
							<div className='itemImg'>
								{!isVideo ? (
									typeof asset?.details?.asset == 'string' &&
										asset?.details?.asset !== undefined &&
										!asset?.details?.asset?.startsWith(
											process.env.S3_URL
										) &&
										!asset?.details?.asset?.startsWith('ipfs') &&
										asset?.details?.asset !== null ? (
										<img
											className='object-cover'
											alt='asset'
											src={
												asset?.details?.asset
													? asset?.details?.asset
													: defaultImage
											}
											onError={handleImageError}
										/>
									) : (
										<img
											className='object-cover'
											alt='asset'
											src={defaultImage}
										/>
									)
								) : videoError ? (
									<img
										className='object-cover'
										alt='asset'
										src={defaultImage}
									/>
								) : (


									<video loop autoPlay muted playsInline preload='metadata' controlslist='nodownload' id='video-asset' style={{
										objectFit: 'contain',
										height: '84px',
										width: '84px'
									}}>
										<source src={asset.details.asset
											? asset.details.asset
											: defaultImage} type="video/mp4" onError={handleVideoError} />
									</video>

								)}
							</div>
							<div className='flex-grow-1 d-flex justify-content-between p-3'>
								<div className='d-flex flex-column mb-2'>
									<div className='mb-2'>
										<div className='itemName'>
											{asset.collection.name}{' '}
											{asset.collection.isVerified && (
												<img
													src={yellowtick20}
													alt='verified'
													className='ml-1'
												/>
											)}
										</div>
									</div>

									<div className='itemId'>
										{asset.details.name}
									</div>
								</div>
								<div className='d-flex flex-column mb-n1 text-right'>
									<span className='itemBal mb-2'>
										$
										{parseFloat(
											asset.bestOffer.priceInUsd.toFixed(2)
										)}
									</span>
									<div className='itemId'>{`${parseFloat(
										asset.bestOffer.price.toFixed(8)
									)} ${asset.bestOffer.currency}`}</div>
								</div>
							</div>
						</div>
					</div>
					<div className='borderRoundedCard'>
						<div class='d-flex justify-content-between mb-2'>
							<div className='fs-14 fw-400 dark-text-secondary'>
								{t("Floor Diference")}
							</div>
							<div className='fs-16 fw-400 dark-text'>
								{asset?.bestOffer?.floorDifference?.floordifference ?
									parseFloat(
										asset?.bestOffer?.floorDifference?.floordifference?.toFixed(
											2
										)
									)
									:
									"0"
								}
								%{' '}
								{asset.bestOffer.floorDifference.up
									? 'above'
									: 'below'}
							</div>
						</div>
						<div class='d-flex justify-content-between mb-2'>
							<div className='fs-14 fw-400 dark-text-secondary'>
								{t("From")}
							</div>
							<div className='fs-16 fw-400 yellowTxt'>
								{formatAddress(asset.bestOffer.address, 5)}
							</div>
						</div>
						<div class='d-flex justify-content-between mb-2'>
							<div className='fs-14 fw-400 dark-text-secondary'>
								{t("Expiration")}
							</div>
							<div className='fs-16 fw-400 dark-text'>
								in {timeLeft}
							</div>
						</div>
						<div class='d-flex justify-content-between mb-2'>
							<div className='fs-14 fw-400 dark-text-secondary'>
								{t("WARRIOR Fee")}
							</div>
							<div className='fs-16 fw-400 dark-text'>
								{asset.details.platformFee}%
							</div>
						</div>
						{account?.toLowerCase() == asset?.details?.creator?.toLowerCase() ?
							""
							:
							<div class='d-flex justify-content-between mb-2'>
								<div className='fs-14 fw-400 dark-text-secondary'>
									{t("Creator Earnings")}
								</div>
								<div className='fs-16 fw-400 dark-text'>
									{asset.details.royalty}%
								</div>
							</div>
						}
						{account?.toLowerCase() == asset?.details?.creator?.toLowerCase() ?

							<div class='d-flex justify-content-between mb-2'>
								<div className='fs-14 fw-700 dark-text-secondary'>
									{t("Total Earnings")}
								</div>
								<div className='fs-16 fw-700 dark-text'>
									<span className='dark-text-grey fs-16 fw-400'>
										($
										{parseFloat(totalEarningsInUsd1.toFixed(2))})
									</span>{' '}
									{`${parseFloat(totalEarningscreators.toFixed(8))} ${asset.bestOffer.currency
										}`}
								</div>
							</div>
							:
							<div class='d-flex justify-content-between mb-2'>
								<div className='fs-14 fw-700 dark-text-secondary'>
									{t("Total Earnings")}
								</div>
								<div className='fs-16 fw-700 dark-text'>
									<span className='dark-text-grey fs-16 fw-400'>
										($
										{parseFloat(totalEarningsInUsd.toFixed(2))})
									</span>{' '}
									{`${parseFloat(totalEarningswithoutcreators.toFixed(8))} ${asset.bestOffer.currency
										}`}
								</div>
							</div>
						}
					</div>
					<div className='mt-4'>
						<button
							className='btn btn-block checkoutBtn'
							onClick={handleAcceptOffer}
						>
							{t("Accept")}{' '}
						</button>
					</div>
				</ModalBody>
			</Modal>


			<Modal isOpen={modal3} toggle={toggle3} centered="true" className="mymodal" backdropClassName="selCurBp">
				<ModalHeader onClick={toggle3} close={closeBtn} backdrop="static" keyboard={false}>
					<div className="d-flex flex-column align-items-center">
						<img src={greenCheck} className="mb-3" alt="greenCheck" />
						{asset?.bestOffer?.from ? asset?.bestOffer?.from : asset?.bestOffer.address?.slice(0, 10) + '...'} {t("received the NFT!")}
					</div>
				</ModalHeader>
				<ModalBody className="pt-0">
					<div>
						<div className='cartItem'>
							<div className='itemImg'>
								{!isVideo ? (
									typeof asset?.details?.asset == 'string' &&
										asset?.details?.asset !== undefined &&
										!asset?.details?.asset?.startsWith(
											process.env.S3_URL
										) &&
										!asset?.details?.asset?.startsWith('ipfs') &&
										asset?.details?.asset !== null ? (
										<img
											className='object-cover'
											alt='asset'
											src={
												asset?.details?.asset
													? asset?.details?.asset
													: defaultImage
											}
											onError={handleImageError}
										/>
									) : (
										<img
											className='object-cover'
											alt='asset'
											src={defaultImage}
										/>
									)
								) : videoError ? (
									<img
										className='object-cover'
										alt='asset'
										src={defaultImage}
									/>
								) : (


									<video loop autoPlay muted playsInline preload='metadata' controlslist='nodownload' id='video-asset' style={{
										objectFit: 'contain',
										height: '84px',
										width: '84px'
									}}>
										<source src={asset.details.asset
											? asset.details.asset
											: defaultImage} type="video/mp4" onError={handleVideoError} />
									</video>

								)}
							</div>
							<div className='flex-grow-1 d-flex justify-content-between p-3'>
								<div className='d-flex flex-column mb-2'>
									<div className="mb-2">
										<div className='itemName'>{asset.collection.name}{' '}
											{asset.collection.isVerified && (
												<img
													src={yellowtick20}
													alt='verified'
													className='ml-1'
												/>
											)}</div>
										<span className='fs-14 fw-400 dark-text-secondary'>
										</span>
									</div>

									<div className='itemId'>{asset.details.name} {asset.details?.nftId}</div>
								</div>
								<div className='d-flex flex-column mb-n1 text-right justify-content-center'>
									<div className='itemId'>{`${parseFloat(
										asset.bestOffer.price.toFixed(8)
									)} ${asset.bestOffer.currency}`}</div>
								</div>
							</div>
						</div>
					</div>
					<div className='mt-4'>
						<button className='btn btn-block checkoutBtn' onClick={Submit}>{t("Okay")}</button>
					</div>
				</ModalBody>
			</Modal>

		</>

	)
}
