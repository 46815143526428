import { create } from 'zustand'

const useCartModalStore = create((set) => ({
	isOpen: false,
	actions: {
		toggleCartModal: () =>
			set((state) => ({
				...state,
				isOpen: !state.isOpen
			}))
	}
}))

export const useCartModalState = () =>
	useCartModalStore(({ isOpen }) => ({
		isOpen
	}))

export const useCartModalActions = () =>
	useCartModalStore((state) => state.actions)
