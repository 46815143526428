import { useMutation } from 'wagmi'
import { useQueryClient } from '@tanstack/react-query'

import DataService from '../../../lib/DataService'

const { postRequest } = DataService()

function bid(data) {
	return postRequest('asset/bid', { ...data })
}

export function useBid() {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: bid,
		onSuccess: () => {
			queryClient.invalidateQueries(['bids'])
		}
	})
}
