import React, { useContext, useEffect, useState } from 'react';
import './helpcenter.scss';

import noItemfoundImage from '../../assets/images/collection/noItemfoundImage.svg';
import cnddsLight from '../../assets/images/cnddsLight.svg'
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import loader from '../../assets/images/Loader.png'
import { ThemeContext } from '../context/context';
import { useDebounce } from '../../utils/useDebounce';
import ResponsivePagination from 'react-responsive-pagination';
import { useTranslation } from 'react-i18next';
import DataService from '../../lib/DataService';
const landPerRow = 6

const HelpCenterCategoryActive = () => {
  const questionList = ["Question 1", "Question 2", "Question 3", "Question 4", "Question 5", "Question 6", "Question 7", "Question 8", "Question 9", "Question 10"];

  const { getRequest, postRequest } = DataService()

  const { id } = useParams()

  const { helpsearch, setHelpsearch } = useContext(ThemeContext)
  const debouncedSearchText = useDebounce(helpsearch, 500)
  const [totalactivitycount, setTotalActivity] = useState("")



  const [currentPage, setCurrentPage] = useState(1);

  function handlePageChange(page) {
    setCurrentPage(page);
  }

  useEffect(() => {
    Getquestion()
  }, [debouncedSearchText, currentPage])

  const [data, setData] = useState("")
  const [loading, setLoading] = useState(false)
  const [next, setNext] = useState(landPerRow)
  const [page, setPage] = useState(1)
  const [count, setCount] = useState('')



  const Getquestion = async () => {
    setLoading(true)
    const update = await getRequest(`home/get_single_question?filter=${helpsearch}&categoryName=${id}&page=${page}&limit=15`)
    if (update.status == true) {
      setLoading(false)
      setData(update.data)
      setCount(update.count)
      setTotalActivity(Math.ceil(update.count / 15))
    }
    else {
      setLoading(false)
      setCount("0")
      setData("")
    }
  }
  const navigate = useNavigate()
  const back = (e) => {
    setHelpsearch('')
    e.preventDefault()
    navigate("/helpcenter")
  }


  const searchResult = (data) => {
    navigate("/searchResult/" + data._id)
    setHelpsearch("")
  }

  const closesearch = async () => {
    setHelpsearch("")
  }

  const helpCenter = async () => {
    setHelpsearch("")
    navigate('/helpcenter')
  }

  const { t } = useTranslation()
  const currentUrl = useLocation()
  let categoryNameSplit = currentUrl.pathname.split('/')
  categoryNameSplit = decodeURIComponent(categoryNameSplit[2])

  return (
    <>
      <div className={`text-center ${loading ? 'spinner' : 'd-none'}`}>
        <img src={loader} alt='' className='spinAnimate' />
      </div>
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-12">
            <div style={{ position: 'absolute', top: '-10px' }}>
              <button className='backbtn' onClick={() => helpCenter()}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.78125 9.75L11.4375 6.125C11.5938 5.96875 11.8438 5.96875 11.9688 6.125L12.2188 6.34375C12.3438 6.5 12.3438 6.71875 12.2188 6.875L9 10L12.1875 13.1562C12.3438 13.3125 12.3438 13.5312 12.1875 13.6875L11.9688 13.9062C11.8438 14.0625 11.5938 14.0625 11.4375 13.9062L7.78125 10.2812C7.625 10.125 7.625 9.90625 7.78125 9.75Z" fill="white" />
                </svg>
              </button>
            </div>
            <div className="text-center hpCateActive mb-5 mt-5 mt-sm-0">
              <span onClick={() => helpCenter()} onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  helpCenter()
                }
              }}
                role="button" tabIndex={0} style={{ cursor: "pointer" }}>Help Center</span>
              <span className="verticalline"></span>
              <span className="">{categoryNameSplit}</span>
              {helpsearch ? <><span className="verticalline"></span>
                <span className="active">Search Results</span></> : ""}
            </div>
            <div className="d-flex align-items-center justify-content-between">
              {helpsearch ?
                <div className='d-flex align-items-center fiterSelectlabel mb-4'>
                  <div className='tagLabel m-1'>
                    <span>{helpsearch}</span>
                    <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={closesearch} onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        closesearch()
                      }
                    }}
                      role="button" tabIndex={0}></i>
                  </div>
                </div>
                :
                ""
              }
              <div className="fs-14 fw-400 dark-text-secondary">
                {helpsearch ?
                  <>
                    {count} {count ? count == 1 ? "result" : "results" : ""}
                  </>
                  :
                  ""
                }
              </div>
            </div>
            <div>
              <div className='mt-5'>
                <div className="row">
                  <div className="col-lg-9 mx-auto">

                    {data && data.length > 0 ?
                      data && data.map((question) => (
                        <>
                          <div className="quesListItem" onClick={() => searchResult(question)} onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              searchResult(question)
                            }
                          }}
                            role="button" tabIndex={0} style={{ cursor: "pointer" }}>
                            <div>{question.question}</div>
                            <div>
                              <i className="fas fa-chevron-right"></i>
                            </div>
                          </div>
                        </>
                      ))
                      :
                      <div className="col-md-12">
                        <div className='noItemFound'>
                          <div className='text-center'>
                            <img src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : noItemfoundImage} alt="noItemfoundImage" className='img-fluid' />
                          </div>
                          <div className='nofounttxt mb-4'>
                            {helpsearch ? 'No items were found. Try to broaden your search.' : t('Nothing to display')}
                          </div>
                        </div>
                      </div>

                    }

                  </div>
                </div>
              </div>

              {data && data.length > 0 ?


                < div className='row justify-content-center'>
                  <div className='col-lg-4 col-md-6 col-sm-8'>
                    <div class="bri_pagenation2">
                      <nav aria-label="Page ">
                        <ResponsivePagination
                          total={totalactivitycount}
                          current={currentPage}
                          onPageChange={page => handlePageChange(page)}
                          previousLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.125 5.75L5.71875 1.125C5.84375 0.96875 6.09375 0.96875 6.25 1.125L6.84375 1.75C7 1.875 7 2.125 6.84375 2.28125L3.15625 6L6.84375 9.75C7 9.90625 7 10.125 6.84375 10.2812L6.25 10.9062C6.09375 11.0625 5.84375 11.0625 5.71875 10.9062L1.125 6.28125C0.96875 6.125 0.96875 5.90625 1.125 5.75Z" fill="#F7F7F7"></path></svg>}
                          nextLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.84375 6.28125L2.28125 10.9062C2.125 11.0625 1.875 11.0625 1.75 10.9062L1.125 10.2812C0.96875 10.125 0.96875 9.90625 1.125 9.75L4.8125 6L1.125 2.28125C0.96875 2.125 0.96875 1.875 1.125 1.75L1.75 1.125C1.875 0.96875 2.125 0.96875 2.28125 1.125L6.84375 5.75C7 5.90625 7 6.125 6.84375 6.28125Z" fill="#F7F7F7"></path></svg>}
                          previousClassName="page-link-1"
                          nextClassName="page-link-1"
                        />
                      </nav>
                    </div>
                  </div>
                </div>

                :
                ""
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HelpCenterCategoryActive;