import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Dropdown,
	Modal,
	ModalHeader,
	ModalBody
} from 'reactstrap'
import { useAccount, useConnect, useNetwork, useSwitchNetwork } from 'wagmi'
import Web3 from 'web3'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { NumericFormat } from 'react-number-format'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { useFixedPriceSell } from '../apis/fetchListAssetDetails'
import { useToast } from '../../../utils/toast/customSuccess'
import ApprovalABI from '../../AbiArray/Approvalcollection/Bnbapproval.json'
import { chainId } from '../../../config/constants/chains'
import contracts from '../../../config/constants/contracts'
import { formatAmount } from '../../../utils/formatAmount'
import { useContractProvider } from '../../../utils/useContractProvider'
import { useOkxSwitchNetwork } from '../../../hooks/useOkxSwitchNetwork'
import { handleImageError } from '../../../hooks/useHandleImg'

import defaultImage from '../../../assets/images/homeExplorer/placeholder.png'
import walletLoaderCnt from '../../../assets/images/walletLoader.png'
import walletLightLoaderCnt from '../../../assets/images/wallet-light-loader.png'
import walletLoader from '../../../assets/images/loaderCircle.png'
import IconModal from '../../../assets/images/IconModal.png'
import angleDown from '../../../assets/images/angleDown.svg'
import { getProvider } from '../../../utils/getProvider'
import { chainIdMap, chainMap } from '../../../utils/getFilterData'
import { parseUnits } from 'viem'
import { CloseIcon } from '../../../components/modals/makeOffer/CloseIcon'
const intervals = [
	{ label: '1 hour', duration: 60, format: '1H' },
	{ label: '6 hours', duration: 360, format: '6H' },
	{ label: '1 day', duration: 1440, format: '1D' },
	{ label: '3 days', duration: 4320, format: '3D' },
	{ label: '7 days', duration: 10080, format: '7D' },
	{ label: '1 month', duration: 43200, format: '1M' }
]

const decrementDuration = [
	{ label: '1 Minute', duration: 1 },
	{ label: '15 Minutes', duration: 15 },
	{ label: '1 Hour', duration: 60 }
]

export const DutchAuction = ({ data, onData, activeTab }) => {
	const { t } = useTranslation()
	const [videoError, setVideoError] = useState(false)
	const { address, isConnected } = useAccount()
	const { connectAsync, connectors } = useConnect()
	const { pro } = useContractProvider()

	const { switchNetworkAsync } = useSwitchNetwork()
	const { switchNetwork } = useOkxSwitchNetwork()

	const { chain: network } = useNetwork()
	const chainid = network?.id

	const navigate = useNavigate()
	const fixedPriceSellMutation = useFixedPriceSell()
	const toast = useToast()

	const [modal, setModal] = useState(false)
	const toggle = () => setModal(!modal)

	const schema = yup.object().shape({
		price: yup
			.number()
			.typeError('Please enter a starting price')
			.required('Required')
			.max(100000000000000, 'The amount cannot exceed 100,000,000,000,000')
			.test('not-zero', 'The value should be at least one', value => value !== 0)
			.test('lessThanEndingPrice', 'Starting price must be greater than ending price', function (value, context) {
				return context.parent.endPrice ? value > context.parent.endPrice && value !== context.parent.endPrice : true;
			}),
		endPrice: yup
			.number()
			.typeError('Please enter a ending price')
			.required('Required')
			.max(100000000000000, 'The amount cannot exceed 100,000,000,000,000')
			.test('not-zero', 'The value should be at least one', value => value !== 0)
			.test('greaterThanStartingPrice', 'Ending price must be less than starting price', function (value, context) {
				return context.parent.price ? value < context.parent.price && value !== context.parent.price : true;
			}),
	});

	const {
		control,
		register,
		handleSubmit,
		setValue,
		getValues,
		setError,
		watch,
		reset,
		formState: { errors, isValid, isDirty }
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(schema)
	})

	const marketplaceContractAddress = data
		? contracts.marketplace[chainIdMap[data?.details?.chain]]
		: null

	const provider = getProvider()

	const web3 = new Web3(provider)
	const tetherContract = new web3.eth.Contract(
		ApprovalABI,
		data?.details?.contractAddress
	)

	const walletType = localStorage.getItem('walletType')

	const startPrice = watch('price')
	const endPrice = watch('endPrice')

	useEffect(() => {
		if (endPrice >= startPrice) {
			setError('endPrice', 'Ending price must be less than starting price')
			setError('price', 'Starting price must be greater than ending price')
		}
		else {
			setError('endPrice', '')
			setError('price', '')
		}

	}, [startPrice, endPrice])

	useEffect(() => {
		if (activeTab) {
			setValue('price', '')
			setValue('endPrice', '')

		}
	}, [activeTab])

	const CheckTick = () => {
		return (
			<>
				<svg
					class='ml-auto'
					width='12'
					height='9'
					viewBox='0 0 12 9'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M10.1953 0.46875C10.3125 0.351562 10.5 0.351562 10.5938 0.46875L11.2734 1.125C11.3672 1.24219 11.3672 1.42969 11.2734 1.52344L4.24219 8.55469C4.125 8.67188 3.96094 8.67188 3.84375 8.55469L0.703125 5.4375C0.609375 5.32031 0.609375 5.13281 0.703125 5.03906L1.38281 4.35938C1.47656 4.26562 1.66406 4.26562 1.78125 4.35938L4.03125 6.63281L10.1953 0.46875Z'
						fill='#FFA900'
					/>
				</svg>
			</>
		)
	}

	const [selectedInterval, setSelectedInterval] = useState(intervals[4])
	const [selectedDuration, setSelectedDuration] = useState(decrementDuration[0])
	const [dropdownOpen, setDropdownOpen] = useState(false)
	const [dropdownOpen1, setDropdownOpen1] = useState(false)
	const [loading, setLoading] = useState(false)
	const [isError, setIsError] = useState(false)

	const [date, setDate] = useState('')
	const [time, setTime] = useState('')

	const toggleDropdown = () => setDropdownOpen(!dropdownOpen)
	const toggleDropdown1 = () => setDropdownOpen1(!dropdownOpen1)

	const handleIntervalChange = interval => {
		setSelectedInterval(interval)
	}

	const handleDuartionChange = decrementDuration => {
		setSelectedDuration(decrementDuration)
	}

	const filteredIntervalOptions = intervals.filter(
		option => option.label !== selectedDuration?.label
	)

	const filteredDurationOptions = decrementDuration.filter(
		option => option.label !== selectedInterval?.label
	)

	const intervalItems = filteredIntervalOptions.map(interval => (
		<DropdownItem
			tag='a'
			key={t(interval.label)}
			onClick={() => handleIntervalChange(interval)}
		>
			<div className='d-flex align-items-center'>
				{t(interval.label)}{' '}
				{selectedInterval.label === interval.label && <CheckTick />}
			</div>
		</DropdownItem>
	))

	useEffect(() => {
		const currentDate = new Date()
		const nextDate = new Date(
			currentDate.getTime() + selectedInterval.duration * 60000
		)

		const formatDate = format(nextDate, 'dd-MM-yyyy hh:mm a')
		const dateTimeArray = formatDate.split(' ')
		setDate(dateTimeArray[0])
		setTime(dateTimeArray[1] + ' ' + dateTimeArray[2])
	}, [selectedInterval])

	useEffect(() => {
		setValue('currency', data && data?.allowedCurrencies?.[0])
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}, [data?.allowedCurrencies])

	const getCurrency = watch('currency')
	const getPrice = watch('price')

	const plat = data?.details?.platformFee / 100
	const royal = data?.details?.royalty / 100

	const finalAmount = getPrice * (1 - (plat + royal))
	const usdtPrice = finalAmount * getCurrency?.usdPrice
	const singleNftUsdtPrice = getPrice * getCurrency?.usdPrice

	useEffect(() => {
		const obj = {
			token: getCurrency,
			price: getPrice,
			totalSellAmount: finalAmount,
			singleNftUsdtPrice: singleNftUsdtPrice
		}

		onData(obj)
	}, [getCurrency, getPrice])

	const onSubmit = async fetch => {
		const sellParams = {
			nftId: data?.details?.nftId,
			contractAddress: data?.details?.contractAddress,
			saleType: 'dutch-auction',
			currency: getCurrency?.currencySymbol,
			price: fetch.price,
			endPrice: fetch.endPrice,
			duration: selectedInterval?.format,
			decrementDuration: selectedDuration?.duration,
			seller: address,
			contractStandard: data?.details?.contractStandard,
			buyingAmount: parseUnits(
				getPrice?.toString(),
				getCurrency?.currencyDecimal
			)?.toString(),
			royaltyFee: data?.details?.royalty,
			adminFee: data?.details?.platformFee
		}
		setLoading(true)
		setIsError(false)
		toggle()
		try {
			if (walletType === 'okx') {
				if (
					data?.details?.chain !== chainMap[parseInt(window.okxwallet.chainId)]
				) {
					await switchNetwork(chainIdMap[data?.details?.chain])
				}
			} else {
				if (data?.details?.chain === 'binance' && chainid !== chainId.BINANCE) {
					await switchNetworkAsync(chainId.BINANCE)
				} else if (
					data?.details?.chain === 'ethereum' &&
					chainid !== chainId.ETHEREUM
				) {
					await switchNetworkAsync(chainId.ETHEREUM)
				} else if (data?.details?.chain === 'polygon' && chainid !== chainId.AMOY) {
					await switchNetworkAsync(chainId.AMOY)
				}
			}
		} catch (error) {
			setLoading(false)
			setIsError(true)
			return
		}

		const approvalResponse = await tetherContract.methods
			.isApprovedForAll(address, marketplaceContractAddress)
			.call()

		if (approvalResponse) {
			setLoading(true)
			toggle()
			try {
				const res = await fixedPriceSellMutation.mutateAsync(sellParams)

				if (res?.status) {
					toast.success(res?.message)
				} else {
					setIsError(true)
					toast.error(res?.message)
				}
			} catch (err) {
				setModal(false)
				setIsError(true)
			}
		} else {
			try {
				await tetherContract.methods
					.setApprovalForAll(marketplaceContractAddress, true)
					.send({ from: address.toString() })
					.on('transactionHash', async hash => { })
					.on('receipt', async approveresult => { })

				setLoading(true)
				toggle()
				const res = await fixedPriceSellMutation.mutateAsync(sellParams)

				if (res?.status) {
					toast.success(res?.message)
					setIsError(false)
				} else {
					setIsError(true)
					toast.error(res?.message)
				}
			} catch (err) {
				setModal(false)
				setIsError(true)
			}
		}
	}

	useEffect(() => {
		if (fixedPriceSellMutation.isSuccess) {
			setLoading(false)
		}
	}, [fixedPriceSellMutation.isSuccess])

	const isVideo = data?.details?.asset?.includes('.mp4')

	const handleVideoError = () => {
		setVideoError(true)
	}
	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='form-row'>
					<div className='col-12 mb-3'>
						<div className='d-flex align-items-center flex-wrap'>
							<span className='formLabel'>{t('Starting Price')}</span>
							{errors.price && (
								<label className='text-danger errLabel ml-auto'>
									{t(errors.price.message)}
								</label>
							)}
						</div>
						<div className='d-flex urlFieldCnt traitsCnt mb-2'>
							<div class='input-group mb-2'>
								<Controller
									name={t('price')}
									control={control}
									defaultValue=''
									render={({ field }) => (
										<NumericFormat
											{...field}
											placeholder={t('Amount')}
											className='form-control'
											decimalScale={6}
											allowNegative={false}
											onValueChange={values => {
												const { value } = values
												field.onChange(value)
											}}
										/>
									)}
								/>
								<div class='input-group-append'>
									<div class='input-group-text'>

										<Controller
											name='currency'
											control={control}
											defaultValue={data?.allowedCurrencies[0]}
											render={({ field: { onChange, value } }) => (
												<div>
													{value && value.currencySymbol === 'MATIC' ? (
														<input
															type='text'
															value={value.currencySymbol}
															readOnly
															onChange={event => {
																onChange({ currencySymbol: event.target.value })
															}}
															style={{ height: 20, width: 100 }}
														/>
													) : (
														<select
															value={JSON.stringify(value)}
															onChange={event => {
																onChange(JSON.parse(event.target.value))
																event.preventDefault()
															}}
														>
															{data &&
																data?.allowedCurrencies?.map(
																	(currency, index) => (
																		<option
																			key={index}
																			value={JSON.stringify(currency)}
																		>
																			{currency.currencySymbol}
																		</option>
																	)
																)}
														</select>
													)}
												</div>
											)}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='col-12 mb-3'>
						<div className='d-flex align-items-center flex-wrap'>
							<span className='formLabel'>{t('Ending Price')}</span>
							{errors.endPrice && (
								<label className='text-danger errLabel ml-auto'>
									{t(errors.endPrice.message)}
								</label>
							)}
						</div>
						<div className='d-flex urlFieldCnt traitsCnt mb-2'>
							<div class='input-group mb-2'>
								<Controller
									name='endPrice'
									control={control}
									defaultValue=''
									render={({ field }) => (
										<NumericFormat
											{...field}
											placeholder={t('Amount')}
											className='form-control'
											decimalScale={6}
											allowNegative={false}
											onValueChange={values => {
												const { value } = values
												field.onChange(value)
											}}
										/>
									)}
								/>
								<div class='input-group-append'>
									<div class='input-group-text'>

										<Controller
											name='currency'
											control={control}
											defaultValue={data?.allowedCurrencies[0]}
											render={({ field: { onChange, value } }) => (
												<div>
													{value && value.currencySymbol === 'MATIC' ? (
														<input
															type='text'
															value={value.currencySymbol}
															readOnly
															onChange={event => {
																onChange({ currencySymbol: event.target.value })
															}}
															style={{ height: 20, width: 100 }}
														/>
													) : (
														<select
															value={JSON.stringify(value)}
															onChange={event => {
																onChange(JSON.parse(event.target.value))
																event.preventDefault()
															}}
														>
															{data &&
																data?.allowedCurrencies?.map(
																	(currency, index) => (
																		<option
																			key={index}
																			value={JSON.stringify(currency)}
																		>
																			{currency.currencySymbol}
																		</option>
																	)
																)}
														</select>
													)}
												</div>
											)}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<hr className='w-100' />

					<div className='col-lg-6 mb-3'>
						<div className='d-flex align-items-center flex-wrap'>
							<span className='formLabel'>{t("Duration")}</span>
						</div>
						<Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
							<DropdownToggle caret className='d-flex align-items-center w-100'>
								{t(selectedInterval.label)}
								<img src={angleDown} className='ml-auto' />
							</DropdownToggle>
							<DropdownMenu>{intervalItems}</DropdownMenu>
						</Dropdown>
					</div>

					<div className='col-lg-6 mb-3'>
						<div className='d-flex align-items-center flex-wrap'>
							<span className='formLabel'>{t("Ends At")}</span>
						</div>
						<div className='d-flex urlFieldCnt traitsCnt'>
							<div class='input-group'>
								<input type='text' class='form-control' value={date} />
								<div class='input-group-append'>
									<div class='input-group-text'>{time}</div>
								</div>
							</div>
						</div>
					</div>

					<div className='col-lg-12 mb-3'>
						<div className='d-flex align-items-center flex-wrap'>
							<span className='formLabel'>{t("Decrement Duration")}</span>
						</div>
						<Dropdown isOpen={dropdownOpen1} toggle={toggleDropdown1}>
							<DropdownToggle caret className='d-flex align-items-center w-100'>
								{selectedDuration.label}
								<img src={angleDown} className='ml-auto' />
							</DropdownToggle>
							<DropdownMenu>
								{filteredDurationOptions.map(decrementDuration => (
									<DropdownItem
										tag='a'
										key={decrementDuration.label}
										onClick={() => handleDuartionChange(decrementDuration)}
									>
										<div className='d-flex align-items-center'>
											{decrementDuration.label}{' '}
											{selectedDuration.label === decrementDuration.label && (
												<CheckTick />
											)}
										</div>
									</DropdownItem>
								))}
							</DropdownMenu>
						</Dropdown>
					</div>
					<hr className='w-100' />

					<div className='col-12 mt-3'>
						<div className='borderedCnt mb-3'>
							<div className='valueRow d-flex align-items-center flex-wrap mb-2'>
								<h3>{t('Listing Price')}</h3>
								<span className='valueLabel ml-auto'>
									{getPrice === '' ? '--' : parseFloat(formatAmount(getPrice))}{' '}
									{getCurrency?.currencySymbol}
								</span>
							</div>
							<div className='valueRow d-flex align-items-center flex-wrap mb-2'>
								<h3>{t("WARRIOR Fee")}</h3>
								<span className='valueLabel ml-auto'>
									{data?.details?.platformFee}%
								</span>
							</div>
							<div className='valueRow d-flex align-items-center flex-wrap mb-2'>
								<h3>{t('Creator Earnings')}</h3>
								<span className='valueLabel ml-auto'>
									{data?.details?.royalty === '' || undefined
										? '-'
										: `${data?.details?.royalty}%`}
								</span>
							</div>
							<div className='valueRow d-flex align-items-center flex-wrap mb-2'>
								<h3>{t("Potential Earnings")}</h3>
								<span className='valueLabel ml-auto'>
									<span>
										{finalAmount ? `($${formatAmount(usdtPrice)})` : null}
									</span>
									{finalAmount ? parseFloat(formatAmount(finalAmount)) : '--'}{' '}
									{getCurrency?.currencySymbol}
								</span>
							</div>
						</div>
					</div>
					<hr className='w-100' />

					<div className='col-12 d-flex align-items-center mb-3'>
						<button
							type='button'
							className='btn btn-block blackBtn mx-2 my-2'
							onClick={() => navigate(-1)}
						>
							{t('Cancel')}
						</button>
						<button
							type='submit'
							className='btn btn-block gradientBtn mx-2 my-2'
							disabled={
								!isDirty || !isValid || fixedPriceSellMutation.isLoading
							}
						>
							{fixedPriceSellMutation.isLoading
								? 'Loading ...'
								: t('List for Sale')}
						</button>
					</div>
				</div>
			</form>
			<Modal
				isOpen={modal}
				toggle={toggle}
				centered='true'
				className='curMdl createScsMdl mymodal'
				backdropClassName='selCurBp'
				backdrop='static'
				keyboard={false}
			>
				<ModalHeader
					close={<CloseIcon onClose={toggle} customclass="closefix" />}
				>
					{loading ? <h3 className='modal-title'>{t("Loading")}</h3> : isError ? <div className='d-flex justify-content-center'>
						<img src={IconModal} />
					</div> : <div className='d-flex justify-content-center'>
						{!isVideo ? (
							!data?.details?.asset?.startsWith(process.env.S3_URL) &&
								!data?.details?.asset?.startsWith('ipfs') &&
								data?.details?.asset !== null ? (
								<img
									src={
										data?.details?.asset ? data?.details?.asset : defaultImage
									}
									className='imgVideoCenter-60px'
									onError={handleImageError}
								/>
							) : (
								<img src={defaultImage} className='imgVideoCenter-60px' />
							)
						) : videoError ? (
							<img src={defaultImage} className='imgVideoCenter-60px' />
						) : (


							<video
								loop
								autoplay=''
								playsInline
								preload='metadata'
								controlslist='nodownload'
								id='video-asset'
								className='imgVideoCenter-60px'
							>
								<source
									src={
										data?.details?.asset ? data?.details?.asset : defaultImage
									}
									type='video/mp4'
									onError={handleVideoError}
								/>
							</video>
						)}
					</div>}
				</ModalHeader>
				<ModalBody className='mt-n4'>
					{isError ? (
						<div className='createContent'>
							<h3 className='walletHeading my-3'>{t("Something went wrong")}</h3>
							<button
								type='button'
								className='btn btn-block darkBtn'
								onClick={toggle}
							>
								{t('Okay')}
							</button>
						</div>
					) : loading ? (
						<div>
							<h3 className='walletSubHeading'>
								{t("To continue send transaction with your wallet")}.
							</h3>
							<div className='d-flex justify-content-center align-items-center loaderCont'>
								{localStorage.getItem('light_theme') == 'true' ? (
									<img src={walletLightLoaderCnt} />
								) : (
									<img src={walletLoaderCnt} />
								)}
								<div className='loaderAnimCnt'>
									<img src={walletLoader} className='loaderAnimation' />
								</div>
							</div>
						</div>
					) : (
						<div className='createContent'>

							<h3 className='walletHeading my-3'>{t("Item has been listed!")}</h3>
							<p className='text-center dark-text'>
								<span>{data?.details?.name}</span> {t("From")}{' '}
								<span>{data?.collection?.name}</span> {t("collection has been listed for sale")}.
							</p>
							<button
								type='button'
								className='btn btn-block gradientBtn'
								onClick={() => navigate('/profile', { state: '2' })}
							>
								{t("View Listing")}
							</button>
						</div>
					)}

				</ModalBody>
			</Modal>
		</>
	)
}

export default DutchAuction
