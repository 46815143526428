import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap'

import { CheckIcon } from '../../../components/CheckIcon'
import { ThemeContext } from '../../../views/context/context'

const sortableFilters = [
    { label: 'Price_Low_High' },
    { label: 'Price_High_Low' },
    { label: 'Recently Listed' },
    { label: 'Recently Created' },
    { label: 'Recently Sold' },
    { label: 'Most Viewed' },
    { label: 'Most Favorited' },
    { label: 'Ending Soon' },
    { label: 'Highest Bid' },
    { label: 'Highest Last Sale' },
    { label: 'Recently Received' },
    { label: 'Oldest' }
]

const filterValueMap = {
    ['Recently Listed']: 'LISTING_DATE',
    ['Recently Created']: 'CREATED_DATE',
    ['Recently Sold']: 'LAST_SALE_DATE',
    ['Most Viewed']: 'VIEWER_COUNT',
    ['Most Favorited']: 'FAVORITE_COUNT',
    ['Ending Soon']: 'EXPIRATION_DATE',
    ['Highest Bid']: 'BEST_BID',
    ['Highest Last Sale']: 'LAST_SALE_PRICE',
    ['Recently Received']: 'LAST_TRANSFER_DATE',
}

const filterKeyMap = {
    LISTING_DATE: 'Recently Listed',
    CREATED_DATE: 'Recently Created',
    LAST_SALE_DATE: 'Recently Sold',
    VIEWER_COUNT: 'Most Viewed',
    FAVORITE_COUNT: 'Most Favorited',
    EXPIRATION_DATE: 'Ending Soon',
    BEST_BID: 'Highest Bid',
    LAST_SALE_PRICE: 'Highest Last Sale',
    LAST_TRANSFER_DATE: 'Recently Received',
}

export function FiltersTimeDropdown({ filters, handleFiltersChange }) {
    const { t } = useTranslation()
    const analyticsfilter = [
        {
            name: t("All Time"),
            value: "All Time",
            sortField: "0",
        },
        {
            name: t("Last Hour"),
            value: "Last Hour",
            sortField: "1",
        },
        {
            name: t("Last 6 Hours"),
            value: "Last 6 Hours",
            sortField: "6",
        },
        {
            name: t("Last 24 Hours"),
            value: "Last 24 Hours",
            sortField: "24",
        },
        {
            name: t("Last 7 Days"),
            value: "Last 7 Days",
            sortField: "7",
        },
        {
            name: t("Last 30 Days"),
            value: "Last 30 Days",
            sortField: "30",
        },
        {
            name: t("Last 90 Days"),
            value: "Last 90 Days",
            sortField: "90",
            sortOrder: "desc"
        },
    ]

    const CheckTick = () => {
        return (
            <>
                <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.1953 0.46875C10.3125 0.351562 10.5 0.351562 10.5938 0.46875L11.2734 1.125C11.3672 1.24219 11.3672 1.42969 11.2734 1.52344L4.24219 8.55469C4.125 8.67188 3.96094 8.67188 3.84375 8.55469L0.703125 5.4375C0.609375 5.32031 0.609375 5.13281 0.703125 5.03906L1.38281 4.35938C1.47656 4.26562 1.66406 4.26562 1.78125 4.35938L4.03125 6.63281L10.1953 0.46875Z" fill="#FFA900" />
                </svg>
            </>
        )
    }

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(prevState => !prevState);


    const [Analyticsfilter, setAnalyticsFilter] = useState('0')

    const [data, setData] = useState("")

    const chooseFilterdata = (data) => {
        setData(data.name)
        setAnalyticsFilter(data.sortField)
        let updatedFilters = { ...filters }
        updatedFilters.search.sortBy = data.sortField

        handleFiltersChange(updatedFilters)
    }

    return (
        <div className='form-group formInputs mr-3 mb-2'>
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className='customdropdown'>
                <DropdownToggle>
                    <span>{t(data == "" ? "All Time" : data)}</span> <i className={`fas ${dropdownOpen ? 'fa-angle-up' : 'fa-angle-down'}`}></i>
                </DropdownToggle>
                <DropdownMenu>
                    {analyticsfilter && analyticsfilter.map((data, index) => (
                        <DropdownItem onClick={() => chooseFilterdata(data)} style={{ minWidth: '140px' }}>
                            <div>{data.name}</div>
                            <div>{Analyticsfilter == data.sortField ? <CheckTick /> : ""}</div>
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </div>
    )
}
