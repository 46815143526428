import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useAccount } from 'wagmi'

import { AssetCard } from './components/AssetCard'
import { AssetHeader } from './components/AssetHeader'
import { AssetDetails } from './components/AssetDetails'
import { useFetchAssetDetails } from './apis/fetchAssetDetails'
import { Spinner } from '../../components/loader/spinner/Spinner'
import { AssetTraits } from './components/AssetTraits'
import { AssetTradeStation } from './components/AssetTradeStation'
import { AssetNotFound } from './components/AssetNotFound'
import { OfferList } from './components/OfferList'
import { useScollToTop } from '../../hooks/useScrollToTop'
import { AssetListings } from './components/AssetListings'
import { AssetActivity } from './components/AssetActivity'
import { MoreFromTheCollection } from './components/MoreFromTheCollection'
import PriceHistory from './components/PriceHistory'
import { useToggleClassName } from '../../hooks/useToggleClassName'

export default function Nft() {
	const { t } = useTranslation()

	const [activeTab, setActiveTab] = useState('1')

	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab)
	}

	useScollToTop()

	const { address: account } = useAccount()
	const params = useParams()
	const { data, isLoading, isError, isFetching } = useFetchAssetDetails(
		params.collectionAddress,
		params.nftId,
		params.chain,
		account
	)
	const hasRendered = useRef(null)
	useToggleClassName()

	useEffect(() => {
		if (!hasRendered.current) {
			hasRendered.current = true
		}
	}, [])

	if (isLoading || (isFetching && !hasRendered.current)) {
		return (
			<section class='TraSec'>
				<div class='container' style={{ height: '85vh ' }}>
					<div
						className='row align-items-center justify-content-center'
						style={{ height: '85vh ' }}
					>
						<Spinner />
					</div>
				</div>
			</section>
		)
	}

	if (isError || (!!data && !data.status)) {
		return <AssetNotFound />
	}

	const asset = data.data

	if (data)
		document.title = `${data?.data?.details?.name ? data?.data?.details?.name : data?.data?.collection?.name} - NFT | Wor NFT`

	return (
		<section class='TraSec'>
			<div class='container'>
				<div className='row'>
					<div className='col-lg-6 col-xl-5'>
						<AssetCard assetDetails={asset.details} />
						<AssetTradeStation asset={asset} />
					</div>

					<div className='col-lg-6 col-xl-7'>
						<AssetHeader asset={asset} />
						<div className='nftCard detCard'>
							<Nav tabs className='darkTabs mb-3'>
								<NavItem>
									<NavLink
										className={classnames({
											active: activeTab === '1'
										})}
										onClick={() => {
											toggle('1')
										}}
									>
										{t('Details')}
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({
											active: activeTab === '2'
										})}
										onClick={() => {
											toggle('2')
										}}
									>
										{t('Offers')}
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({
											active: activeTab === '3'
										})}
										onClick={() => {
											toggle('3')
										}}
									>
										{t('Traits')}
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({
											active: activeTab === '4'
										})}
										onClick={() => {
											toggle('4')
										}}
									>
										{t('Listings')}
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({
											active: activeTab === '5'
										})}
										onClick={() => {
											toggle('5')
										}}
									>
										{t('Price History')}
									</NavLink>
								</NavItem>
							</Nav>
							<TabContent
								activeTab={activeTab}
								className='w-100 scroller'
							>
								<TabPane tabId='1'>
									<AssetDetails asset={asset} />
								</TabPane>
								<TabPane tabId='2'>
									<OfferList asset={asset} />
								</TabPane>
								<TabPane tabId='3'>
									<AssetTraits asset={asset} />
								</TabPane>
								<TabPane tabId='4'>
									<AssetListings asset={asset} />
								</TabPane>
								<TabPane tabId='5'>
									<PriceHistory />
								</TabPane>
							</TabContent>
						</div>
					</div>
					<AssetActivity asset={asset} />
					<MoreFromTheCollection />
				</div>
			</div>
		</section>
	)
}
