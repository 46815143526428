import { useEffect, useLayoutEffect, useRef, useState } from 'react'

import { FiltersDropdown } from './FiltersDropdown'
import { FiltersSidebar } from './FiltersSidebar'
import { SearchItems } from './SearchItems'

import arrowLeftIcon from '../../../assets/images/collection/arrowLeftIcon.svg'
import filterIcon from '../../../assets/images/collection/filterIcon.svg'
import { FiltersTimeDropdown } from './FiltersTimeDropdown'

export function Filters({
	itemsViewTabsContent,
	showFilters,
	setShowFilters,
	traits,
	filters,
	handleFiltersChange,
	tab,
	collectionDetails,
	children
}) {
	const [containerOffsetWidth, setcontainerOffsetWidth] = useState('')
	const [WindowInnerWidth, setWindowInnerWidth] = useState([
		window.innerWidth
	])
	const [width, setWidth] = useState(window.innerWidth);

	const containerRef = useRef(null)

	const filterBtnMargin =
		((WindowInnerWidth[0] - containerOffsetWidth + 30) / 2) * -1 + 17


	useEffect(() => {
		function handleResize() {
			setWidth(window.innerWidth);
		}
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [width]);

	useEffect(() => {
		if (width < 992) {
			setShowFilters(false);
		} else {
			setShowFilters(true);

		}
	}, [width]);

	useLayoutEffect(() => {
		setcontainerOffsetWidth(containerRef.current.offsetWidth)
		setWindowInnerWidth([window.innerWidth])
	}, [])

	useEffect(() => {
		function handleResize() {
			setcontainerOffsetWidth(containerRef.current.offsetWidth)
			setWindowInnerWidth([window.innerWidth])
		}

		if (containerRef.current)
			window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	useEffect(() => {
		function handleResize() {
			setWindowInnerWidth(window.innerWidth)
		}

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<>
			<div
				className='filterbtnshow mb-2 mb-xl-0'
				style={{
					marginLeft: filterBtnMargin
				}}
			>
				{showFilters ? (
					<button
						className='filtershowhideBtn'
						onClick={() => setShowFilters(false)}
					>
						<img src={filterIcon} alt='filterIcon' />
					</button>
				) : (
					<button
						className='filtershowhideBtn'
						onClick={() => setShowFilters(true)}
					>
						<>
							<img src={arrowLeftIcon} alt='arrowLeftIcon' />
							<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ 'position': 'absolute', 'right': '7px', 'marginTop': '2px' }}>
								<circle cx="5" cy="5" r="4" fill="url(#paint0_linear_462_183603)" stroke="#1D2025" stroke-width="2" />
								<defs>
									<linearGradient id="paint0_linear_462_183603" x1="1.54737" y1="1.46316" x2="8.41053" y2="8.78947" gradientUnits="userSpaceOnUse">
										<stop stop-color="#FFA900" />
										<stop offset="1" stop-color="#FFEDAE" />
										<stop offset="1" stop-color="#FFF6A4" />
									</linearGradient>
								</defs>
							</svg>
						</>

					</button>
				)}
			</div>
			<div className='mb-5' ref={containerRef}>
				<div className='row'>
					<div className='col-lg-12'>
						<div className='itemWizard'>
							<div
								className={`filterSection ${showFilters ? 'show' : 'hide'
									}`}
							>
								<FiltersSidebar
									traits={traits}
									filters={filters}
									handleFiltersChange={handleFiltersChange}
									tab={tab}
									collectionDetails={collectionDetails}
								/>
							</div>
							<div
								className={`viewItemscard ${showFilters ? 'collapseWidth' : 'fullWidth'
									}`}
							>
								{tab !== '2' ?
									<div className='form-inline'>
										<SearchItems
											filters={filters}
											handleFiltersChange={
												handleFiltersChange
											}
										/>
										{tab === '3' ?
											<FiltersTimeDropdown
												filters={filters}
												handleFiltersChange={
													handleFiltersChange
												}
											/> :
											<FiltersDropdown
												filters={filters}
												handleFiltersChange={
													handleFiltersChange
												}
											/>
										}
										{children}
									</div>
									: null}
								{itemsViewTabsContent}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
