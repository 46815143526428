import React, { useState, useEffect, useContext } from 'react'

import './Bridge.scss'
import 'react-toastify/dist/ReactToastify.css'
import Web3 from 'web3'
import bnbabi from '../AbiArray/bnbabi.json'
import ethabi from '../AbiArray/ethabi.json'
import polygonabi from '../AbiArray/polygonabi.json'
import { ThemeContext } from '../context/context'
import contracts from '../../config/constants/contracts'
import { formatAmount } from '../../utils/formatAmount'
import { useToast } from '../../utils/toast/customSuccess'
import loader from '../../assets/images/Loader.png'
import { getProvider } from '../../utils/getProvider'
import {
	useAccount,
	useConnect,
	useNetwork,
	useSwitchNetwork,
} from 'wagmi'
import ResponsivePagination from 'react-responsive-pagination';


import { chainId } from '../../config/constants/chains'

import { useContractProvider } from '../../utils/useContractProvider'
import { useOkxSwitchNetwork } from '../../hooks/useOkxSwitchNetwork'
import { useToggleClassName } from '../../hooks/useToggleClassName'
import {
	useGetBnbBalance,
	useGetEthBalance,
	useGetMaticBalance
} from '../../hooks/useBalance'
import { chainIdMap, chainMap } from '../../utils/getFilterData'
import { useTranslation } from 'react-i18next'
import noItemfoundImage from '../../assets/images/collection/noItemfoundImage.svg';
import cnddsLight from '../../assets/images/cnddsLight.svg'
import DataService from '../../lib/DataService'
const url = {
	BNB: 'https://testnet.bscscan.com/tx/',
	ETH: 'https://sepolia.etherscan.io/tx/',
	MATIC: 'https://www.oklink.com/amoy/tx',
	WBNB: "https://testnet.bscscan.com/tx/",
	WETH: "https://sepolia.etherscan.io/tx/",
	WMATIC: "https://www.oklink.com/amoy/tx/",
	WOR: "https://testnet.bscscan.com/tx/",
	BUSD: "https://testnet.bscscan.com/tx/",
	USDT: "https://sepolia.etherscan.io/tx/"
}

const landPerRow = 6

const BridgeHistroy = () => {
	const toast = useToast()
	useToggleClassName()
	const [next, setNext] = useState(landPerRow)
	const [page, setPage] = useState(1)
	const { getRequest, postRequest } = DataService()

	useEffect(() => {
		document.title = ' Bridge History | Wor NFT'
	}, [])

	const [loading, setLoading] = useState(false)
	let [color, setColor] = useState('#ffffff')

	const { theme, setTheme } = useContext(ThemeContext)

	const { address, isConnected } = useAccount()
	const { connectAsync, connectors } = useConnect()
	const { t } = useTranslation()


	const { switchNetworkAsync } = useSwitchNetwork()
	const { switchNetwork } = useOkxSwitchNetwork()

	const { chain: network } = useNetwork()
	const chainid = network?.id

	const { balance: bnbBalance, refresh } = useGetBnbBalance()
	const { balance: ethBalance, refresheth } = useGetEthBalance()
	const { balance: maticBalance } = useGetMaticBalance()

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
		fetchhistory()
	}, [page, address])

	const [history, setHistory] = useState('')
	const [count1, setCount] = useState('')
	var count = 0

	function handlePageChange(page) {
		setPage(page);
	}

	const fetchhistory = async () => {
		setLoading(true)
		const userHistory = await getRequest(`bridge/history?address=${address}&page=${page}&limit=15`
		)
		if (userHistory.status == true) {
			setHistory(userHistory.data)
			setCount(Math.ceil(userHistory.count / 15))
			setLoading(false)
		} else {
			setLoading(false)
			setHistory('')
			setCount('')
		}
	}

	
	const { pro } = useContractProvider()
	const walletType = localStorage.getItem('walletType')
	const provider = getProvider()

	const web3 = new Web3(provider)
	const bnbcontractMarket = new web3.eth.Contract(
		bnbabi,
		contracts.bridge.Bnb_contract
	)
	const ethcontractMarket = new web3.eth.Contract(
		ethabi,
		contracts.bridge.Eth_contract
	)
	const polygoncontractMarket = new web3.eth.Contract(
		polygonabi,
		contracts.bridge.Polygon_contract
	)

	var nonce
	var domain
	var claim
	var value
	var contract;

	const ReclaimUser = async (data) => {
		setLoading(true)
		try {
			if (data.toCurrency == "ETH") {
				contract = ethcontractMarket
			} else if (data.toCurrency == "MATIC") {
				contract = polygoncontractMarket
			} else if (data.toCurrency == "BNB") {
				contract = bnbcontractMarket
			}
			else if (data.toCurrency == "USDT") {
				contract = ethcontractMarket
			}
			else if (data.toCurrency == "BUSD") {
				contract = bnbcontractMarket
			}
			else if (data.toCurrency == "WOR") {
				contract = bnbcontractMarket
			}
			const balancecheck = await postRequest("admin/nft/check_claim_balance", {
				"network": data.network, "amount": Number(data.toAmount),
				"toCurrency": data.toCurrency
			})
			if (balancecheck.status == true) {
				const buyprice2 = web3.utils.toWei(
					data.toAmount.toString(),
					'ether'
				)
				const buyprice3 = data.toAmount.toString() * 1000000

				const getvrs = await postRequest(
					'admin/nft/get_vrs',
					{
						userAddress: address,
						currencySymbol: data.toCurrency,
						amount: data.toAmount.toString()
					}
				)
				if (getvrs.status == true) {
					{

						try {
							if (walletType === 'okx') {
								if (data.network !== chainMap[parseInt(window.okxwallet.chainId)]) {
									await switchNetwork(chainIdMap[data.network])
								}
							} else {
								if (data.toCurrency == "BUSD" && chainid !== chainId.BINANCE) {
									await switchNetworkAsync(chainId.BINANCE)
								}
								else if (data.toCurrency == "BNB" && chainid !== chainId.BINANCE) {
									await switchNetworkAsync(chainId.BINANCE)
								}
								else if (data.toCurrency == "WOR" && chainid !== chainId.BINANCE) {
									await switchNetworkAsync(chainId.BINANCE)
								}
								else if (data.toCurrency == "ETH" && chainid !== chainId.ETHEREUM) {
									await switchNetworkAsync(chainId.ETHEREUM)
								}
								else if (data.toCurrency == "USDT" && chainid !== chainId.ETHEREUM) {
									await switchNetworkAsync(chainId.ETHEREUM)
								}
								else if (data.toCurrency == "MATIC" && chainid !== chainId.AMOY) {
									await switchNetworkAsync(chainId.AMOY)
								}
							}
						}
						catch (error) {
							toast.error('User rejected the switch network')
							setLoading(false)
							return
						}
						try {
							(claim = await contract.methods.claim(data.toCurrency == "USDT" ? buyprice3 : buyprice2, String(getvrs.data.blockNumber), getvrs.data.v, getvrs.data.r, getvrs.data.s, data.toCurrency == "USDT" ? contracts.usdt : data.toCurrency == "BUSD" ? contracts.busd : data.toCurrency == "WOR" ? contracts.wor : "0x0000000000000000000000000000000000000000"
							).send({ from: address }).on('transactionHash', async (hash) => {
								const getTransactionReceipt = async (hash) => {
									const result =
										await web3.eth.getTransactionReceipt(
											hash
										)
									if (result === null) {
										getTransactionReceipt(hash)
									} else {

										let data1 = {
											id: data._id,
											adminFee: (formatAmount((getvrs.adminFee * +data.toAmount) / 100)).toString(),
											txnHash: result.transactionHash,
											toAmount: (Number(data.toAmount) - ((getvrs.adminFee * +data.toAmount) / 100)).toString(),
											status: 'completed'
										}
										const fetch = await postRequest(

											'bridge/re-claim',
											data1
										)
										if (fetch.status == true) {
											toast.success(
												'Transaction completed successfully'
											)
											fetchhistory()
											setLoading(false)
											refresh()
											refresheth()
										}
									}
								}
								getTransactionReceipt(hash)
							})
								.on('receipt', async (approveresult) => {
								}))
						}
						catch (err) {
							toast.error("Transaction Rejected")
							setLoading(false)
						}
					}
				} else {
					toast.error(getvrs.data)
					setLoading(false)
				}
			}
			else {
				toast.error(balancecheck.message)
				setLoading(false)
			}
		} catch (error) {
		}
	}

	return (
		<>
			<div className={loading ? 'spinner' : 'd-none'}>
				<img src={loader} alt='' className='spinAnimate' />
			</div>


			<section class='BrigeHises  inrPgMt'>
				<div class='container-fluid'>
					<div class='brhsd text-center'>
						<h1>{t("Bridge History")}</h1>
					</div>
					{history && history.length > 0 ? (
						<div class='britab'>
							<div class='table-responsive'>
								<table class='table table-borderless mb-0'>
									<thead class='bri_head'>
										<tr>
											<th>{t("S.no")} </th>
											<th>{t("Date & Time")} </th>
											<th>{t("From Amount")}</th>
											<th>{t("To Amount")}</th>
											<th>{t("Admin Fee")}</th>
											<th>{t("From Address")}</th>
											<th>{t("To Address")}</th>
											<th>{t("Transaction Hash")} </th>
											<th>{t("Status")}</th>
											<th>{("Action")}</th>
										</tr>
									</thead>

									<tbody class='buy_table_body'>
										{history &&
											history.map((val, key) => {
												return (
													<tr key={key}>
														<td>
															{page == 1
																? key + 1
																: (page - 1) * 15 +
																(key + 1)}
														</td>
														<td>
															{new Date(
																val.createdAt
															).toLocaleString()}
														</td>
														<td>
															{val.fromAmount}{' '}
															{val.fromCurrency}
														</td>
														<td>
															{val.type == 'claim' ? (
																<>
																	<span>
																		{parseFloat(formatAmount(val.toAmount))}
																	</span>
																	<span>
																		{' '}
																		{
																			val.toCurrency
																		}
																	</span>
																</>
															) : (
																'-'
															)}
														</td>
														<td>
															{val.adminFee == 0 ?
																"-"
																:
																parseFloat(formatAmount(val.adminFee))
															}</td>

														<td>
															{val.fromAddress
																? val.fromAddress.slice(
																	0,
																	5
																) +
																'..' +
																val.fromAddress.slice(
																	38,
																	42
																)
																: ''}
														</td>
														<td>
															{val.toAddress
																? val.toAddress.slice(
																	0,
																	5
																) +
																'..' +
																val.toAddress.slice(
																	38,
																	42
																)
																: ''}
														</td>
														<td>
															{val.txnHash ? (

																<a
																	rel='noreferrer'
																	href={
																		url[val.toCurrency] + val.txnHash
																	}
																	target='_blank'
																>
																	{' '}
																	{val.txnHash !==
																		''
																		? val.txnHash.slice(
																			0,
																			4
																		) +
																		'...' +
																		val.txnHash.slice(
																			15,
																			18
																		)
																		: '-'}{' '}
																</a>
															) : (
																'-'
															)}
														</td>
														<td class='yelcl'>
															{t(val.status)}{' '}
														</td>
														<td>
															{val.status ==
																'cancelled' ? (
																<button
																	class='btn'
																	onClick={() =>
																		ReclaimUser(
																			val
																		)
																	}
																>
																	{t("Claim")}
																</button>
															) : t('claimed')}
														</td>
													</tr>
												)
											})}

									</tbody>
								</table>
							</div>
						</div>
					) : (
						<div className='noItemFound'>
							<div className='text-center'>
								<img src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : noItemfoundImage} alt="noItemfoundImage" className='img-fluid' />
							</div>
							<div className='nofounttxt mb-4'>
								{t('Nothing to display.')}
							</div>
						</div>
					)}
					<div className='row justify-content-center'>
						<div className='col-lg-4 col-md-6 col-sm-8'>
							<div class="bri_pagenation2">
								<div class=''>
									<nav aria-label="Page ">
										<ResponsivePagination
											total={count1}
											current={page}
											onPageChange={page => handlePageChange(page)}
											previousLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.125 5.75L5.71875 1.125C5.84375 0.96875 6.09375 0.96875 6.25 1.125L6.84375 1.75C7 1.875 7 2.125 6.84375 2.28125L3.15625 6L6.84375 9.75C7 9.90625 7 10.125 6.84375 10.2812L6.25 10.9062C6.09375 11.0625 5.84375 11.0625 5.71875 10.9062L1.125 6.28125C0.96875 6.125 0.96875 5.90625 1.125 5.75Z" fill="#F7F7F7"></path></svg>}
											nextLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.84375 6.28125L2.28125 10.9062C2.125 11.0625 1.875 11.0625 1.75 10.9062L1.125 10.2812C0.96875 10.125 0.96875 9.90625 1.125 9.75L4.8125 6L1.125 2.28125C0.96875 2.125 0.96875 1.875 1.125 1.75L1.75 1.125C1.875 0.96875 2.125 0.96875 2.28125 1.125L6.84375 5.75C7 5.90625 7 6.125 6.84375 6.28125Z" fill="#F7F7F7"></path></svg>}
											previousClassName="page-link-1"
											nextClassName="page-link-1"
										/>
									</nav>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
export default BridgeHistroy
