import React, { useEffect, useState, useContext } from 'react'
import style from './profile.module.scss'
import profileProfPic from '../../assets/images/profile/profileProfPic.png'
import yellowTick24px from '../../assets/images/collection/yellow-tick-24px.svg'
import shareIcon from '../../assets/images/collection/shareIcon.svg'
import ellipsisV from '../../assets/images/collection/ellipsis-v.svg'
import pennant from '../../assets/images/collection/pennant.svg'
import settingsIcon from './assets/settingsIcon.svg'
import copyClickIcon from '../../assets/images/copyIcon.svg'
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
	UncontrolledDropdown,
	Modal,
	ModalHeader,
	ModalBody,
	Dropdown
} from 'reactstrap'
import classnames from 'classnames'
import ProfileCollection from './Collected/profileItem'
import ActiveListing from './activeListing/profileActiveMain'

import ProfileCreate from './Created/profileCreateMain'
import ProfileFavorite from './Favorites/profileFavoriteMain'
import ProfileActivity from './Activity/profileActivityMain'
import OfferReceived from './offerReceived/profileOfferReceivedMain'
import OfferMade from './offerMade/profileOfferMadeMain'
import { useToast } from '../../utils/toast/customSuccess'
import copy from 'copy-to-clipboard'
import { useOutletContext } from 'react-router-dom'
import {
	EmailShareButton,
	TelegramShareButton,
	FacebookShareButton,
	TwitterShareButton
} from 'react-share'

import { useAccount } from 'wagmi'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import loader from '../../assets/images/Loader.png'
import { ThemeContext } from '../context/context'
import HiddenItems from './Hidden/HiddenItems'
import DataService from '../../lib/DataService'

export const ProfilePage = props => {
	const { postformdataRequest, getRequest, postRequest } = DataService()
	const [dropdownMore, setdropdownMore] = useState(false)
	const MoreToggle = () => setdropdownMore(prevState => !prevState)

	const {
		activeTab5,
		setActiveTab5,
		setFavouriteTab,
		setOfferReceivedTab,
		resetState
	} = useContext(ThemeContext)


	const profileData = {
		1: "Collected",
		2: "Active Listings",
		3: "Created",
		5: "Favorites",
		6: "Activity",
		7: "Offers Received",
		8: "Offers Made",
		9: "Hidden"

	}

	const { t } = useTranslation()
	const [loading, setLoading] = useState(false)

	const toast = useToast()

	const { address } = useAccount()

	const { id } = useParams()

	const location = useLocation()

	const data = location.state

	const schema = yup.object().shape({
		reason: yup
			.string()
			.required('Required')
			.min(3, 'Reason should be atleast 3 character')
	})

	const { register, control, handleSubmit, formState, reset } = useForm({
		mode: 'onChange',
		resolver: yupResolver(schema)
	})

	const { isValid, errors } = formState

	useEffect(() => {

		if (data === null || '') {
			setActiveTab5(activeTab5)
		} else {
			setActiveTab5(data)
		}
	}, [data?.tab])

	const toggle = tab => {
		resetState()
		if (activeTab5 !== tab) setActiveTab5(tab)
	}

	const [modal, setModal] = useState(false)
	const toggle1 = () => setModal(!modal)

	useEffect(() => {
		if (activeTab5)
			document.title = `${profileData[activeTab5]} - Profile | Wor NFT`
	}, [activeTab5])

	const closeBtn = (
		<button className='close'>
			<svg
				width='20'
				height='20'
				viewBox='0 0 20 20'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M12.3359 10.5L16.5547 14.7188C16.7891 14.9531 16.7891 15.3828 16.5547 15.6172L15.5781 16.5938C15.3438 16.8281 14.9141 16.8281 14.6797 16.5938L10.5 12.375L6.28125 16.5938C6.04688 16.8281 5.61719 16.8281 5.38281 16.5938L4.40625 15.6172C4.17188 15.3828 4.17188 14.9531 4.40625 14.7188L8.625 10.5L4.40625 6.32031C4.17188 6.08594 4.17188 5.65625 4.40625 5.42188L5.38281 4.44531C5.61719 4.21094 6.04688 4.21094 6.28125 4.44531L10.5 8.66406L14.6797 4.44531C14.9141 4.21094 15.3438 4.21094 15.5781 4.44531L16.5547 5.42188C16.7891 5.65625 16.7891 6.08594 16.5547 6.32031L12.3359 10.5Z'
					fill='#6E737E'
				/>
			</svg>
		</button>
	)

	const [showFullDescription, setFullDescription] = useState(false)


	const [copyText, setCopyText] = useState(id ? id : address)

	useEffect(() => {
		GetProfile()
	}, [id, address, showFullDescription, copyText])

	const copyToClipboard = () => {
		copy(id ? id : address)
		toast.success(t('Address Copied'))
	}

	const [copylink, setCopyLink] = useState()

	const copyToLink = () => {
		copy(`${process.env.REACT_APP_PUBLIC_URL}/profile/${id ? id : address}`)
		toast.copy('Link copied')
	}

	const getData = useOutletContext()

	const [getprofile, setGetProfile] = useState('')
	const navigate = useNavigate()
	const settings = () => {
		navigate('/settingsprofile')
	}

	const onSubmit = async data => {
		setLoading(true)
		let detail = {
			address: address,
			profileAddress: id ? id : address,
			reason: data.reason,
			type: 'profile'
		}
		const res = await postRequest('user/report_data', detail)
		if (res.status == true) {
			setLoading(false)
			setModal(false)
			toast.success(res.message, {
				showOnlyTheLastOne: true,
				clickToClose: true,
				timeout: 2000
			})
		} else {
			setLoading(false)
			setModal(false)
			reset()
			toast.error(res.message, {
				showOnlyTheLastOne: true,
				clickToClose: true,
				timeout: 2000
			})
		}
	}

	const [status, setStatus] = useState(false)

	const GetProfile = async () => {
		setLoading(true)
		const update = await postRequest('user/getSingleProfile', {
			address: id ? id : address
		})
		if (update.status == true) {
			setLoading(false)
			setGetProfile(update.data)
			setStatus(true)
		} else {
			setLoading(false)
			setGetProfile('')
			setStatus(true)
		}
	}

	let size
	let maxSizeKB
	let maxSize

	const imageupload1 = async e => {
		let imageFile1 = e.target.files[0]
		size = parseFloat(e.target.files[0]?.size)
		maxSizeKB = 2000
		maxSize = 100 * 1024 * 1024
		if (imageFile1 != undefined) {
			if (size < maxSize) {
				if (!imageFile1.name.match(/\.(webp|jpg|jpeg|png|gif)$/)) {
					toast.error('Please select valid image for WEBP,PNG,GIF,JPEG')
				} else if (imageFile1 == '' || imageFile1 == undefined) {
					toast.error('Please choose logo image')
				} else {

					const reader = new FileReader()
					reader.onload = e => {
						const img = new Image()
						img.onload = async () => {
							const width = img.naturalWidth;
							const height = img.naturalHeight;
							if (width >= 1400 && height >= 360) {
								setLoading(true)
								const formData = new FormData()
								formData.append('address', address.toLowerCase())
								formData.append('name', getprofile.name)
								formData.append('logo', imageFile1)
								formData.append('cover', getprofile.cover)
								formData.append('bio', getprofile.bio)
								formData.append('email', getprofile.email)
								formData.append('twitter', getprofile.twitter)
								formData.append('instagram', getprofile.instagram)
								formData.append('website', getprofile.website)
								formData.append('verification', getprofile.verification)
								formData.append('twitterVerify', getprofile.twitterVerify)
								formData.append('nameVerify', getprofile.nameVerify)
								formData.append('logoVerify', getprofile.logoVerify)
								formData.append('emailVerify', getprofile.emailVerify)
								formData.append('coverImageType', getprofile.coverImageType)
								formData.append('logoImageType', imageFile1.type)

								const update = await postformdataRequest('user/update-profile', formData)
								if (update.status == true) {
									toast.success(update.message)
									props.setProfilePic(update.data.logo)
									props.setUsername(update.data.name)
									setLoading(false)
									GetProfile()
								} else {
									toast.error(update.message)
									setLoading(false)
								}
							}
							else {
								toast.error('Use image size height 360 pixels, width 1400 pixels.')
							}
						}
						img.onerror = () => {
							toast.error('Unsupported image file')
							setLoading(false)
						}
						img.src = e.target.result
					}
					reader.readAsDataURL(imageFile1)
				}
			} else {
				toast.error('Maximum file size is 100mb')
			}
		} else {
		}
	}

	const showIcon = getData[0]?.bio?.length > 60

	const showFullDescriptionHandler = () => {
		setFullDescription(!showFullDescription)
	}

	const description = showFullDescription
		? getData[0]?.bio
		: getData[0]?.bio?.length < 60
			? getData[0]?.bio
			: `${getData[0]?.bio?.slice(0, 60)}...`

	return (
		<>
			{status == true ? (
				<>
					<div className={`text-center ${loading ? 'spinner' : 'd-none'}`}>
						<img src={loader} alt='' className='spinAnimate' />
					</div>

					<div className='container-fluid'>
						<div className='row pb-3'>
							<div className='col-lg-6 order-lg-2 text-center ml-auto '>
								<div className={style.userDetails}>
									<div className='imgtag'>
										<div className={style.editCnt}>
											<img
												src={
													getprofile &&
														getprofile.logo !== 'undefined' &&
														getprofile.logo !== '' &&
														getprofile.logo !== null
														? getprofile.logo
														: profileProfPic
												}
												alt='userImg'
											/>

											{getprofile && getprofile.verification == 'Verified' ? (
												''
											) : id === address || id == undefined ? (
												<label
													htmlFor='userImg'
													className={style.editIconCnt}
													style={{ cursor: 'pointer' }}
												>
													<input
														id='userImg'
														type='file'
														accept='.png, .gif, .webp, .jpg, .jpeg'
														className={style.fileInputType}
														onChange={e => imageupload1(e)}
													/>
													<svg
														width='18'
														height='18'
														viewBox='0 0 18 18'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'
													>
														<path
															d='M16.4062 2.78125C17.1875 3.5625 17.1875 4.8125 16.4062 5.59375L5.375 16.625L1.8125 17C1.34375 17.0625 0.9375 16.6562 1 16.1875L1.375 12.625L12.4062 1.59375C13.1875 0.8125 14.4375 0.8125 15.2188 1.59375L16.4062 2.78125ZM13.4375 7.15625L10.8438 4.5625L3.84375 11.5625H4.9375V13.0625H6.4375V14.1562L13.4375 7.15625ZM4.9375 15.6562L5.5625 15.0312V13.9375H4.0625V12.4375H2.96875L2.34375 13.0625L2.125 14.9062L3.09375 15.875L4.9375 15.6562ZM15.6875 4.90625C16.0938 4.5 16.0938 3.875 15.6875 3.46875L14.5312 2.3125C14.125 1.90625 13.5 1.90625 13.0938 2.3125L11.5312 3.875L14.125 6.46875L15.6875 4.90625Z'
															fill='#0A0A0B'
														/>
													</svg>
												</label>
											) : (
												''
											)}
										</div>
									</div>

									<div className='text-center'>
										<div className='userName'>
											{getData[0] ? (
												<>
													{getData[0]?.name}
													{getData[0]?.verification === 'Verified' && (
														<img
															src={yellowTick24px}
															alt='yellowTick24px'
															className='ml-2'
														/>
													)}
												</>
											) : (
												<></>
											)}
										</div>
									</div>
									<div className='text-center d-flex justify-content-center mt-3'>
										<div className='userName d-flex mb-3'>
											<>
												<span>
													{t("Joined")}{' '}
													{new Date(
														getData[0]?.createdAt
													).toLocaleDateString('en-us', {
														year: 'numeric',
														month: 'long'
													})}
												</span>
												<span className='mx-2 verticalLine'>|</span>
											</>

											<span>
												{getData[0].address
													? getData[0].address.slice(0, 5) +
													'...' +
													getData[0].address.slice(38, 42)
													: ''}
												<img
													src={copyClickIcon}
													alt='copyClickIcon'
													className='ml-2 hover'
													onClick={copyToClipboard}
													onKeyDown={(e) => {
														if (e.key === 'Enter' || e.key === ' ') {
															copyToClipboard()
														}
													}}
													role="button" tabIndex={0}
												/>
											</span>
										</div>
									</div>

									<div className='userDescpara'>{description}</div>
									{showIcon && (
										<div onClick={showFullDescriptionHandler} onKeyDown={(e) => {
											if (e.key === 'Enter' || e.key === ' ') {
												showFullDescriptionHandler()
											}
										}}
											role="button" tabIndex={0}>
											<button className='readmorebtn'>
												Read {showFullDescription ? 'Less' : 'More'}{' '}
												<i
													className={`fas ${showFullDescription
														? 'fa-angle-up'
														: 'fa-angle-down'
														} ml-2`}
												></i>
											</button>
										</div>
									)}
								</div>
							</div>

							<div className='col-lg-3 order-lg-3 text-center text-lg-right mb-4 mb-lg-0'>
								<div
									className={`justify-content-center justify-content-lg-end ${style.socialmediaBtnGrid}`}
								>
									<UncontrolledDropdown>
										<DropdownToggle className={style.iconBtn}>
											<img src={shareIcon} alt='shareIcon' />
										</DropdownToggle>
										<DropdownMenu right className={style.dropdownMenu}>
											<DropdownItem className='mb-2'>
												<svg
													width='20'
													height='20'
													viewBox='0 0 20 20'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														d='M11.1527 8.92804L16.2525 3H15.044L10.6159 8.14724L7.07919 3H3L8.34821 10.7835L3 17H4.20855L8.88474 11.5643L12.6198 17H16.699L11.1524 8.92804H11.1527ZM9.49748 10.8521L8.95559 10.077L4.644 3.90978H6.50026L9.97976 8.88696L10.5216 9.66202L15.0446 16.1316H13.1883L9.49748 10.8524V10.8521Z'
														fill='white'
													/>
												</svg>

												<TwitterShareButton
													title={'Check out this account on wornft'}
													url={`${process.env.REACT_APP_PUBLIC_URL}/profile/${address}`}
													via={['WorNft']}
												>
													{' '}
													{t("Twitter")}
												</TwitterShareButton>
											</DropdownItem>
											<DropdownItem className='mb-2'>
												<svg
													width='16'
													height='16'
													viewBox='0 0 16 16'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														d='M8 0.25C12.25 0.25 15.75 3.75 15.75 8C15.75 12.2812 12.25 15.75 8 15.75C3.71875 15.75 0.25 12.2812 0.25 8C0.25 3.75 3.71875 0.25 8 0.25ZM11.5625 5.53125C11.5938 5.4375 11.5938 5.34375 11.5625 5.21875C11.5625 5.15625 11.5 5.0625 11.4688 5.03125C11.375 4.9375 11.2188 4.9375 11.1562 4.9375C10.875 4.9375 10.4062 5.09375 8.25 6C7.5 6.3125 6 6.9375 3.75 7.9375C3.375 8.09375 3.1875 8.21875 3.15625 8.375C3.125 8.625 3.53125 8.71875 4 8.875C4.40625 9 4.9375 9.15625 5.21875 9.15625C5.46875 9.15625 5.75 9.0625 6.0625 8.84375C8.15625 7.40625 9.25 6.6875 9.3125 6.6875C9.375 6.6875 9.4375 6.65625 9.46875 6.6875C9.53125 6.75 9.53125 6.8125 9.5 6.84375C9.46875 7 7.5 8.8125 7.375 8.9375C6.9375 9.375 6.4375 9.65625 7.21875 10.1562C7.875 10.5938 8.25 10.875 8.9375 11.3125C9.375 11.5938 9.71875 11.9375 10.1562 11.9062C10.375 11.875 10.5938 11.6875 10.6875 11.0938C10.9688 9.75 11.4688 6.75 11.5625 5.53125Z'
														fill='white'
													/>
												</svg>{' '}
												<TelegramShareButton
													url={`${process.env.REACT_APP_PUBLIC_URL}/profile/${address}`}
													title={'Check out this account on wornft'}
													className='share-button'
													via={['WorNft']}
												>
													{t("Telegram")}
												</TelegramShareButton>
											</DropdownItem>
											<DropdownItem className='mb-2'>
												<svg
													width='16'
													height='16'
													viewBox='0 0 16 16'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														d='M15.75 8C15.75 11.875 12.9062 15.0938 9.1875 15.6562V10.25H11L11.3438 8H9.1875V6.5625C9.1875 5.9375 9.5 5.34375 10.4688 5.34375H11.4375V3.4375C11.4375 3.4375 10.5625 3.28125 9.6875 3.28125C7.9375 3.28125 6.78125 4.375 6.78125 6.3125V8H4.8125V10.25H6.78125V15.6562C3.0625 15.0938 0.25 11.875 0.25 8C0.25 3.71875 3.71875 0.25 8 0.25C12.2812 0.25 15.75 3.71875 15.75 8Z'
														fill='white'
													/>
												</svg>{' '}
												<FacebookShareButton
													image={
														getprofile && getprofile.logo !== 'undefined'
															? getprofile.logo
															: profileProfPic
													}
													url={`${process.env.REACT_APP_PUBLIC_URL}/profile/${address}`}
													subject={'Check out this account on wornft'}
												>
													{t("Facebook")}
												</FacebookShareButton>
											</DropdownItem>
											<DropdownItem className='mb-2'>
												<svg
													width='20'
													height='20'
													viewBox='0 0 20 20'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														d='M16.5 4C17.3125 4 18 4.6875 18 5.5V14.5C18 15.3438 17.3125 16 16.5 16H3.5C2.65625 16 2 15.3438 2 14.5V5.5C2 4.6875 2.65625 4 3.5 4H16.5ZM3.5 5C3.21875 5 3 5.25 3 5.5V6.8125C3.65625 7.375 4.65625 8.1875 7.6875 10.5938C8.21875 11.0312 9.25 12.0312 10 12C10.7188 12.0312 11.75 11.0312 12.2812 10.5938C15.3125 8.1875 16.3125 7.375 17 6.8125V5.5C17 5.25 16.75 5 16.5 5H3.5ZM16.5 15C16.75 15 17 14.7812 17 14.5V8.09375C16.2812 8.6875 15.1562 9.59375 12.9062 11.375C12.25 11.9062 11.125 13.0312 10 13C8.84375 13.0312 7.71875 11.9062 7.0625 11.375C4.8125 9.59375 3.6875 8.6875 3 8.09375V14.5C3 14.7812 3.21875 15 3.5 15H16.5Z'
														fill='white'
													/>
												</svg>

												<EmailShareButton
													title={'Check out this account on wornft'}
													url={`${process.env.REACT_APP_PUBLIC_URL}/profile/${address}`}
													via={['WorNft']}
													className='share-button'
												>
													{t("Email")}
												</EmailShareButton>
											</DropdownItem>
											<DropdownItem className='mb-2' onClick={copyToLink}>
												<svg
													width='20'
													height='20'
													viewBox='0 0 20 20'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														d='M11.4062 14.3438L8.90625 16.8125C7.34375 18.4062 4.75 18.4062 3.1875 16.8125C1.59375 15.25 1.59375 12.6562 3.1875 11.0938L5.65625 8.59375C5.6875 8.5625 5.71875 8.53125 5.75 8.5C6 8.28125 6.375 8.4375 6.40625 8.75C6.40625 8.90625 6.40625 9.0625 6.4375 9.21875C6.4375 9.3125 6.40625 9.4375 6.3125 9.53125C5.8125 10.0312 3.96875 11.875 3.96875 11.875C2.8125 13.0312 2.8125 14.875 3.96875 16.0312C5.125 17.1875 6.96875 17.1875 8.125 16.0312L10.5938 13.5625L10.625 13.5312C11.75 12.4062 11.75 10.5312 10.5938 9.40625C10.3438 9.15625 10.0625 8.9375 9.75 8.8125C9.59375 8.75 9.5 8.59375 9.5 8.4375C9.53125 8.28125 9.5625 8.125 9.59375 7.96875C9.65625 7.75 9.90625 7.65625 10.0938 7.71875C10.5625 7.9375 11 8.21875 11.4062 8.59375C12.9688 10.1875 12.9688 12.7812 11.4062 14.3438ZM8.5625 11.4375C7 9.84375 7 7.25 8.5625 5.6875L11.0625 3.21875C12.625 1.625 15.2188 1.625 16.7812 3.21875C18.375 4.78125 18.375 7.375 16.7812 8.9375L14.3125 11.4375C14.2812 11.4688 14.25 11.5 14.2188 11.5312C13.9688 11.75 13.5938 11.5938 13.5625 11.2812C13.5625 11.125 13.5625 10.9688 13.5312 10.8125C13.5312 10.7188 13.5625 10.5938 13.6562 10.5C14.1562 10 16 8.15625 16 8.15625C17.1562 7 17.1562 5.15625 16 4C14.8438 2.84375 13 2.84375 11.8438 4L9.375 6.46875L9.34375 6.5C8.21875 7.625 8.21875 9.5 9.375 10.625C9.625 10.875 9.90625 11.0938 10.2188 11.2188C10.375 11.2812 10.4688 11.4375 10.4688 11.5938C10.4375 11.75 10.4062 11.9062 10.375 12.0625C10.3125 12.2812 10.0625 12.375 9.875 12.3125C9.40625 12.0938 8.96875 11.8125 8.5625 11.4375Z'
														fill='white'
													/>
												</svg>{' '}
												{t("Copy Link")}
											</DropdownItem>
										</DropdownMenu>
									</UncontrolledDropdown>
									{getprofile?.address !== address?.toLowerCase() ? (
										<UncontrolledDropdown>
											<DropdownToggle className={style.iconBtn}>
												<img src={ellipsisV} alt='ellipsisV' />
											</DropdownToggle>
											<DropdownMenu right className={style.dropdownMenu}>
												<DropdownItem onClick={toggle1}>
													<img src={pennant} alt='pennant' className='mr-2' />{' '}
													{t('Report')}
												</DropdownItem>
											</DropdownMenu>
										</UncontrolledDropdown>
									) : (
										''
									)}
								</div>
							</div>
						</div>
					</div>
					<div className={`mb-4 ${style.borderbottom}`}></div>
					<div className='container-fluid'>
						<div className='row'>
							<div className='col-lg-10 d-flex flex-wrap'>
								<Nav tabs className={style.mycollectiontabs}>
									<NavItem>
										<NavLink
											className={classnames({ active: activeTab5 === '1' })}
											onClick={() => {
												toggle('1')
											}}
											style={{ cursor: 'pointer' }}
										>
											{t('Collected')}
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={classnames({ active: activeTab5 === '2' })}
											onClick={() => {
												toggle('2')
											}}
											style={{ cursor: 'pointer' }}
										>
											{t('Active Listings')}
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={classnames({ active: activeTab5 === '3' })}
											onClick={() => {
												toggle('3')
											}}
											style={{ cursor: 'pointer' }}
										>
											{t('Created')}
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={classnames({ active: activeTab5 === '5' })}
											onClick={() => {
												toggle('5')
												setFavouriteTab('favorites')
											}}
											style={{ cursor: 'pointer' }}
										>
											{t('Favorited')}
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={classnames({ active: activeTab5 === '6' })}
											onClick={() => {
												toggle('6')
											}}
											style={{ cursor: 'pointer' }}
										>
											{t('Activity')}
										</NavLink>
									</NavItem>
								</Nav>
								<Dropdown
									isOpen={dropdownMore}
									toggle={MoreToggle}
									className={`dropdownpos ${classnames({
										active: activeTab5 === '7' || activeTab5 === '8' || activeTab5 === '9'
									})}`}
								>
									<DropdownToggle
										className={`ml-2 mb-4 ${style.collectOfferBtn}`}
									>
										{t('More')}
										<svg
											className='ml-2'
											width='20'
											height='20'
											viewBox='0 0 20 20'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M9.71875 12.2188L6.09375 8.5625C5.9375 8.40625 5.9375 8.15625 6.09375 8.03125L6.3125 7.78125C6.46875 7.65625 6.6875 7.65625 6.84375 7.78125L10 11L13.125 7.8125C13.2812 7.65625 13.5 7.65625 13.6562 7.8125L13.875 8.03125C14.0312 8.15625 14.0312 8.40625 13.875 8.5625L10.25 12.2188C10.0938 12.375 9.875 12.375 9.71875 12.2188Z'
												fill='#F7F7F7'
											/>
										</svg>
									</DropdownToggle>
									<DropdownMenu right className={style.dropdownMenu}>
										<DropdownItem
											className={classnames({ active: activeTab5 === '7' })}
											onClick={() => {
												toggle('7')
												setOfferReceivedTab('OfferReceived')
											}}
											style={{ cursor: 'pointer' }}
										>
											{t('Offers Received')}
										</DropdownItem>
										<DropdownItem
											className={classnames({ active: activeTab5 === '8' })}
											onClick={() => {
												toggle('8')
											}}
											style={{ cursor: 'pointer' }}
										>
											{t('Offers Made')}
										</DropdownItem>
										{id === address || id == undefined ? (
											<DropdownItem
												className={classnames({ active: activeTab5 === '9' })}
												onClick={() => {
													toggle('9')
												}}
												style={{ cursor: 'pointer' }}
											>
												{t('Hidden')}
											</DropdownItem>
										) : (
											''
										)}
									</DropdownMenu>
								</Dropdown>
							</div>

							{id === address || id == undefined ? (
								<div className='col-lg-2 text-right'>
									<button className={style.iconBtn} onClick={settings}>
										<img src={settingsIcon} alt='settingsIcon' />
									</button>
								</div>
							) : (
								''
							)}
						</div>
						<div className='row'>
							<div className='col-12'>
								<TabContent activeTab={activeTab5}>
									<TabPane tabId='1'>
										{activeTab5 === '1' && <ProfileCollection />}
									</TabPane>
									<TabPane tabId='2'>
										{activeTab5 === '2' && <ActiveListing />}
									</TabPane>
									<TabPane tabId='3'>
										{activeTab5 === '3' && <ProfileCreate />}
									</TabPane>
									<TabPane tabId='5'>
										{activeTab5 === '5' && <ProfileFavorite />}
									</TabPane>
									<TabPane tabId='6'>
										{activeTab5 === '6' && <ProfileActivity />}
									</TabPane>
									<TabPane tabId='7'>
										{activeTab5 === '7' && <OfferReceived />}
									</TabPane>
									<TabPane tabId='8'>
										{activeTab5 === '8' && <OfferMade />}
									</TabPane>
									<TabPane tabId='9'>
										{activeTab5 === '9' && <HiddenItems />}
									</TabPane>
								</TabContent>
							</div>
						</div>
					</div>

					<Modal
						isOpen={modal}
						toggle={toggle1}
						centered='true'
						className='mymodal'
						backdropClassName='selCurBp'
						keyboard={false}
					>
						<ModalHeader onClick={toggle1} close={closeBtn}>
							{t('Report')}
						</ModalHeader>
						<ModalBody className='pt-0'>
							<p className='dark-text-secondary text-center'>
								{t("Please, describe the reason.")}
							</p>
							<form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
								<textarea
									{...register('reason')}
									className={`form-control custextarea ${errors.reason && 'err'}`}
									placeholder={t('Type Here...')}
								// style={{ color: 'white' }}
								></textarea>
								{errors.reason && (
									<label className='text-danger errLabel ml-auto'>
										{errors.reason.message}
									</label>
								)}
								<div className='mt-4'>
									{errors.reason || !isValid ?
										<button
											type='submit'
											className='btn btn-block darkBtn mb-2 text-danger'
											disabled
										>
											{t('Report')}
										</button> :
										<button
											type='submit'
											className='btn btn-block darkBtn mb-2 text-danger'
										>
											{t('Report')}
										</button>}
									<button
										type='button'
										className='btn btn-block darkBtn mb-2'
										onClick={() => {
											setModal(false)
											reset()
										}}
									>
										{t("Cancel")}
									</button>
								</div>
							</form>
						</ModalBody>
					</Modal>
				</>
			) : (
				''
			)}
		</>
	)
}

export default ProfilePage
