import { useEffect } from 'react'

export function useToggleClassName() {
	useEffect(() => {
		const el = document.querySelector('#root-div')
		el.classList.add('withoutBannerHeaderPage')

		return () => {
			el.classList.remove('withoutBannerHeaderPage')
		}
	}, [])
}
