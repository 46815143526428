import React, { useEffect, useState } from 'react';
import { formatPayload } from "./FeedbackFormAPI";
import {
    useAccount,
} from 'wagmi'
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../utils/toast/customSuccess'
import loader from '../../assets/images/Loader.png'
import noItemfoundImage from '../../assets/images/collection/noItemfoundImage.svg'
import cnddsLight from '../../assets/images/cnddsLight.svg'

import './support-ticket.scss'
import { useTranslation } from 'react-i18next';
import DataService from '../../lib/DataService';
const SupportTicket = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { getRequest, postRequest } = DataService()

    const [username, setUsername] = useState("")
    const [emailres, setEmail] = useState("")
    const [feedbackSubject, setFeedbackSubject] = useState("");
    const [feedbackValue, setFeedbackValue] = useState("");
    const [subjectError, setSubjectError] = useState("");
    const [feedbackError, setFeedbackError] = useState("");
    const [EmailError, setEmailError] = useState("");
    const [nameError, setnameError] = useState("");
    const [ticketHistory, settickethistory] = useState("");
    const [supportoption, setSupportOption] = useState("")
    const { address } = useAccount()
    const toast = useToast()
    const [loading, setLoading] = useState(false)


    useEffect(() => {

        document.title = 'Support Ticket | Wor NFT'
    }, [])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        fetchData();
        supportOption()
    }, [address]);

    const fetchData = async () => {

        try {
            const profileResponse = await postRequest("user/getSingleProfile", { address });
            if (profileResponse.data) {
                const { name, email } = profileResponse.data;

                setUsername(name || "");
                setEmail(email || "");
                await getTicketHistory(email);
            } else {
                setUsername("");
                setEmail("");
                settickethistory("");
            }
        } catch (error) {
            console.error("Error fetching profile:", error);
        }
    };

    const getTicketHistory = async (email) => {
        setLoading(true);

        try {
            const historyResponse = await postRequest("user/ticket-history", { email });
            if (historyResponse.data && historyResponse.data.tickets) {
                const ticketIds = historyResponse.data.tickets.map(ticket => ticket.id);
                ticketIds.sort((a, b) => b - a);
                const sortedTickets = ticketIds.map(id => historyResponse.data.tickets.find(ticket => ticket.id === id));
                settickethistory(sortedTickets);
            } else {
                settickethistory([]);
            }
        } catch (error) {
            console.error("Error fetching ticket history:", error);
            settickethistory([]);
        } finally {
            setLoading(false);
        }
    };


    const supportOption = async () => {
        setLoading(true)
        const support = await getRequest("admin/support_option_list")
        if (support.status == true) {
            setSupportOption(support?.data)
            setLoading(false)
        }
        else {
            setSupportOption("")
            setLoading(false)
        }
    }

    const editusername = (e) => {
        const editname = e.target.value;
        setUsername(editname)
        if (editname.length === 0) {
            setnameError("Username is required")
            setUsername('')

        } else {
            setnameError('')
        }
    }


    const editemail = (event) => {
        const email = event.target.value;
        setEmail(email)
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (email.length === 0) {
            setEmailError("Email is required");
        } else if (emailPattern.test(email)) {
            setEmail(email);
            setEmailError("");
        } else {
            setEmailError("Invalid email");
        }
    }


    const handleRadioChange = (name) => {

        setFeedbackSubject(name);
        setSubjectError("")
    };

    const handleFeedbackChange = (event) => {
        setFeedbackValue(event.target.value);
        setFeedbackError("");

    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        setFeedbackError("");
        setnameError("")

        if (username.length === 0) {
            setnameError("Username is required");
            setLoading(false);
            return;
        }
        if (emailres.length === 0) {
            setEmailError("Email is required");
            setLoading(false);
            return;
        }


        if (feedbackValue?.length === 0 && feedbackSubject?.length === 0) {
            setFeedbackError("Feedback is required");
            setSubjectError("Subject is required");
            setLoading(false);
            return;
        }
        if (feedbackValue?.length === 0) {
            setFeedbackError("Feedback is required");
            setLoading(false);
            return;
        }
        if (feedbackSubject?.length === 0) {
            setSubjectError("Subject is required");
            setLoading(false);
            return;
        }

        const payload = formatPayload(
            feedbackSubject,
            emailres,
            username,
            feedbackValue,
        );

        try {

            const response = await postRequest('user/send-feedback', payload);

            if (response.status === true) {
                toast.success(response.message);
                setFeedbackValue('')
                setUsername('')
                setEmail('')
                setFeedbackSubject('')
                fetchData()
                setLoading(false)
            } else if (response.status === false) {
                toast.error(response.message);
                setLoading(false)
            }
            else {
                toast.error(response.error);
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
        }
    };
    const navigateToPage = (ticketId) => {
        navigate(`/conversation/${ticketId}`);
    };
    const getButtonColor = (status) => {
        switch (status) {
            case 'open':
                return 'btn btnOpen';
            case 'pending':
                return 'btnpending';
            case 'solved':
                return 'btnClose';
            case 'closed':
                return 'btnClose';
            case 'new':
                return 'btnnew';
            default:
                return 'black';
        }
    };



    return (
        <>
            <div className={`text-center ${loading ? 'spinner' : 'd-none'}`}>
                <img src={loader} alt='' className='spinAnimate' />
            </div>
            <div className='container-fluid'>

                <div>

                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='scroller' style={{ maxHeight: ticketHistory.length > 4 ? '500px' : 'auto', overflowY: ticketHistory.length > 4 ? 'auto' : 'visible' }}>
                                {ticketHistory?.length > 0 ?
                                    ticketHistory && ticketHistory.map(ticket => (
                                        <div className='filtl mb-3' key={ticket.id} onClick={() => navigateToPage(ticket.id)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter' || e.key === ' ') {
                                                    navigateToPage(ticket.id)
                                                }
                                            }}
                                            role="button"
                                            tabIndex={0}
                                            style={{ cursor: "pointer" }}>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <span className='fs-'>{t("ID")} {ticket.id}</span>
                                                </div>
                                                <div className='col-md-6 text-md-right'>
                                                    <span className='fs-16 fw-400 pr-3 dark-text'>{new Date(
                                                        ticket.created_at
                                                    ).toLocaleString()}</span>
                                                    <button className={`btn ${getButtonColor(ticket.status)}`}>
                                                        <span style={{ textTransform: 'capitalize' }}>{ticket.status}</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <hr className='hrcls' />
                                            <div className='fs-16 fw-500'>{t("Subject")} : <span className='dark-text fs-16 fw-400'>{ticket.subject}</span></div>
                                            <div className='fs-16 fw-500'>{t("Description")}: </div>
                                            <div className='dark-text fs-16 fw-400'>{ticket.description}</div>
                                        </div>
                                    )) :
                                    <div className='row'>
                                        <div className='col-md-12 mt-3'>
                                            <div className='noItemFound'>
                                                <div className='text-center'>
                                                    <img
                                                        src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : noItemfoundImage}
                                                        alt='noItemfoundImage'
                                                        className='img-fluid'
                                                    />
                                                </div>
                                                <div className='nofounttxt mb-4'>
                                                    {t("Nothing to display.")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>



                        </div>

                        <div className='col-lg-6'>
                            <div className='filtl px-lg-5 py-5 mb-4'>
                                <div className='form-group formInputs'>
                                    <label>{t("User Name")}</label>
                                    <input className='form-control' type='text' value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        onKeyUp={editusername} placeholder={t('Enter Your User Name')} />
                                </div>
                                <p style={{ color: "red" }}>{t(nameError)}</p>
                                <div className='form-group formInputs'>
                                    <label>{t("Email Address")}</label>
                                    <input className='form-control' type='text' value={emailres}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder={t('Enter Your Email Address')} onKeyUp={editemail} />
                                </div>
                                <p style={{ color: "red" }}>{t(EmailError)}</p>
                                <div className='form-group formInputs'>
                                    <label>{t("What Can Help Today?")}</label>
                                    <div>
                                        {supportoption && supportoption?.map((data, index) =>
                                            <div key={data.name} className="custom-control custom-radio custom-control-inline">
                                                <input
                                                    type="radio"
                                                    className="custom-control-input"
                                                    id={`customRadio_${index}`}
                                                    name="example"
                                                    value={data.name}
                                                    checked={feedbackSubject === data.name}
                                                    onChange={() => handleRadioChange(data.name)}
                                                />
                                                <label className="custom-control-label" style={{ cursor: "pointer" }} htmlFor={`customRadio_${index}`}>{data.name}</label>
                                            </div>
                                        )}
                                        <p style={{ color: "red" }}>{t(subjectError)}</p>
                                    </div>
                                </div>
                                <div className='form-group formInputs'>
                                    <label>{t("Issue Message")}</label>
                                    <input className='form-control' type='text' value={feedbackValue}
                                        onChange={handleFeedbackChange} placeholder={t('Enter Issue Message')} />
                                    <p style={{ color: "red" }}>{t(feedbackError)}</p>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-4 col-md-6'>
                                        <button type="button" className='btn gradientBtn btn-block' onClick={handleSubmit}>{t("Submit")}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SupportTicket;