import { useAccount, usePublicClient } from 'wagmi'

import { useCancelListing } from '../features/nft/apis/cancelListing'
import { useMarketplaceContract } from './useContract'

export function useCancelAuction(marketplaceContractAddress, chainId) {
	const marketplaceContract = useMarketplaceContract(
		marketplaceContractAddress,
		chainId
	)
	const publicClient = usePublicClient({ chainId })
	const { address: account } = useAccount()
	const { mutateAsync: cancelListing } = useCancelListing()

	const cancelAuction = async ({
		owner,
		nftAddress,
		tokenId,
		orderId,
	}) => {
		try {
			const hash = await marketplaceContract.write.cancelOrder(
				[owner, nftAddress, tokenId],
				{
					account
				}
			)

			const transactionReceipt =
				await publicClient.waitForTransactionReceipt({
					hash
				})

			if (transactionReceipt.status === 'success') {
				const res = await cancelListing(orderId)
				if (res.status) {
					return transactionReceipt
				}
			}

			throw new Error('Something went wrong. Try again later')
		} catch (error) {
			throw new Error(error)
		}
	}

	return { cancelAuction }
}
