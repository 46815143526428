import React, { useState, useEffect } from 'react'
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
	Modal
} from 'reactstrap'
import { useDropzone } from 'react-dropzone'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import Web3 from 'web3'
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi'
import { useTranslation } from 'react-i18next'

import {
	useGetCollection,
	useGetRoyalityFee,
	useCreateNFT,
	useGetName
} from './api'
import WorABIJSON from '../AbiArray/NftWorAbi.json'
import { useDebounce } from '../../utils/useDebounce'
import { useContractProvider } from '../../utils/useContractProvider'
import { useOkxSwitchNetwork } from '../../hooks/useOkxSwitchNetwork'
import { chainId } from '../../config/constants/chains'
import { handleImageError } from '../../hooks/useHandleImg'
import { getProvider } from '../../utils/getProvider'
import aiPfpClub from '../../assets/images/itemIcons/aiPfpClub.png'
import IconModal from '../../assets/images/IconModal.png'
import walletLoaderCnt from '../../assets/images/walletLoader.png'
import walletLightLoaderCnt from '../../assets/images/wallet-light-loader.png'
import walletLoader from '../../assets/images/loaderCircle.png'
import angleDown from '../../assets/images/angleDown.svg'
import Pencil from '../../assets/images/Pencil.svg'
import plus from '../../assets/images/plus.svg'
import defaultImage from '../../assets/images/homeExplorer/placeholder.png'
import { chainIdMap, chainMap } from '../../utils/getFilterData'
import { useToast } from '../../utils/toast/customSuccess'
const validUrl =
	/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm

const SUPPORTED_FORMATS = ['image/webp', 'image/png', 'image/gif', 'video/mp4', 'image/jpg', 'image/jpeg']

const schema = yup.object().shape({
	nftName: yup
		.string()
		.required('Required')
		.max(30, 'Nft name must be at most 30 characters')
		.min(3, 'Nft name should be atleast 3 characters'),

	collectionName: yup.string().required('Required'),
	supply: yup
		.number()
		.typeError('Please enter only number')
		.test('not-zero', 'The value should be atleast one', value => value !== 0)
		.test('no-decimals', 'Decimals are not allowed.', value =>
			Number.isInteger(value)
		)
		.max(100000, 'Maximum supply is 100000')
		.required('Required'),
	asset: yup
		.mixed()
		.required('Upload an image')
		.test(
			'fileFormat',
			'Unsupported format. Upload an image',
			value => value && SUPPORTED_FORMATS.includes(value.type)
		)
})

export const ChooseCollection = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { pro } = useContractProvider()

	const { address, isConnected } = useAccount()

	const { switchNetworkAsync } = useSwitchNetwork()
	const { switchNetwork } = useOkxSwitchNetwork()

	const { chain: network } = useNetwork()
	const chainid = network?.id

	const getCollectionMutation = useGetCollection()
	const getRoyalityFeeMutation = useGetRoyalityFee()

	const createNftMutation = useCreateNFT()
	const fetchNftNameMutation = useGetName()

	const [modal, setModal] = useState(false)
	const toggle = () => {
		reset()
		setModal(!modal)
		setMintLoading(false)
		setNftImg('')
		setSelectCollection("")
	}

	const toast = useToast()

	const [modal1, setModal1] = useState(false)
	const toggle1 = () => setModal1(!modal1)

	useEffect(() => {
		const getCollection = async () => {
			let data = {
				creator: address,
				chain: sessionStorage.getItem('Nftblockchain'),
				contractStandard: "ERC1155"
			}
			let res = await getCollectionMutation.mutateAsync(data)
		}

		getCollection()
	}, [])

	const chain = sessionStorage.getItem('Nftblockchain')
	const defaultSupplyValue = 1;

	const {
		register,
		control,
		handleSubmit,
		watch,
		formState,
		reset,
		setError,
		setValue,
		clearErrors,
		getValues
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(schema)
	})

	const { errors } = formState

	const getNftName = getValues('nftName')
	const getCollectionName = getValues('collectionName')

	const debouncedSearchText = useDebounce(getNftName, 500)
	console.log('debouncedSearchText: ', debouncedSearchText);

	useEffect(() => {
		const getName = async () => {
			if (debouncedSearchText) {
				let res = await fetchNftNameMutation.mutateAsync({
					nftName: debouncedSearchText
				})
			}
		}

		getName()
	}, [debouncedSearchText])

	useEffect(() => {
		if (fetchNftNameMutation.isSuccess) {
			if (fetchNftNameMutation.data.status) {
			} else {
				setError('nftName', {
					type: 'required',
					message: 'NFT Name already exists'
				})
			}
		}
	}, [fetchNftNameMutation.isSuccess])

	const [fileType, setFileType] = useState()

	const [nftImg, setNftImg] = useState(null)

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		accept: 'image/png,image/jpg, image/gif, image/webp, video/mp4,image/jpeg',
		maxFiles: 1,
		onDrop: acceptedFiles => {
			setFileType(acceptedFiles[0].type)
			const selectedImage = acceptedFiles[0]
			const maxSize = 100 * 1024 * 1024
			if (acceptedFiles[0].size > maxSize) {
				setError('asset', {
					type: 'required',
					message: 'File size must be less than 100MB.'
				})
				setNftImg('')
			} else if (
				![
					'image/png',
					'image/jpg',
					'image/webp',
					'image/gif',
					'video/mp4',
					'image/jpeg'
				].includes(acceptedFiles[0].type)
			) {
				setError('asset', {
					type: 'required',
					message: 'Unsupported file type. Use png, jpg, jpeg, webp, gif, mp4 only'
				})
				setNftImg('')
			} else {
				if (acceptedFiles[0].type === 'video/mp4') {
					setNftImg(URL.createObjectURL(selectedImage))
					clearErrors('asset')
					setValue('asset', acceptedFiles[0])
				} else {
					const reader = new FileReader()
					reader.onload = e => {
						const img = new Image()
						img.onload = () => {
							const width = img.naturalWidth;
							const height = img.naturalHeight;
							if (width >= 1400 && height >= 360) {
								setNftImg(URL.createObjectURL(selectedImage))
								clearErrors('asset')
								setValue('asset', acceptedFiles[0])
							}
							else {
								setError('asset', {
									type: 'required',
									message: 'Use image size height 360 pixel, width 1400 pixel.'

								})
								setNftImg('')
							}
						}
						img.onerror = () => {
							setError('asset', {
								type: 'required',
								message: 'Unsupported image file'
							})
							setNftImg('')
						}
						img.src = e.target.result
					}
					reader.readAsDataURL(selectedImage)
				}
			}
		}
	})

	const [input1, setInput1] = useState('')
	const [input2, setInput2] = useState('')
	const [valueList, setValueList] = useState([])

	function handleOpenDialog(cb = () => { }) {
		setModal1(true)
		cb()
	}

	function handleCloseDialog() {
		setModal1(false)
		setInput1('')
		setInput2('')
		setTraitToEdit(null)
	}

	const handleInput1Change = e => {
		setInput1(e.target.value)
	}

	const handleInput2Change = e => {
		setInput2(e.target.value)
	}

	const [traitToEdit, setTraitToEdit] = useState(null)

	const handleTraits = e => {
		e.preventDefault()
		if (traitToEdit) {
			const indexOfTraitToEdit = valueList.findIndex(
				v => JSON.stringify(v) === JSON.stringify(traitToEdit)
			)
			const editedTrait = { [input1]: input2 }
			const valueListCopy = [...valueList]
			valueListCopy.splice(indexOfTraitToEdit, 1, editedTrait)
			setValueList([...valueListCopy])
			setTraitToEdit(null)
			setInput1('')
			setInput2('')
			setModal1(false)
			return
		}

		setValueList([...valueList, { [input1]: input2 }])
		setInput1('')
		setInput2('')
		setTraitToEdit(null)
		setModal1(false)
	}

	const back = () => {
		navigate('/chooseNftChain')
	}

	const walletType = localStorage.getItem('walletType')

	const [selectCollection, setSelectCollection] = useState('')
	const [collectionAddress, setCollectionAddress] = useState('')
	const [collectionError, setCollectionError] = useState('')

	const chooseCollectionData = async data => {
		setSelectCollection(data.collectionName)
		setCollectionAddress(data.contractAddress)
		setValue('collectionName', data.collectionName)
		setValue('chain', sessionStorage.getItem('Nftblockchain'))
		await getRoyalityFeeMutation.mutateAsync({
			contractAddress: data.contractAddress
		})
	}

	const [isLoading, setIsLoading] = useState(false)
	const [mintloading, setMintLoading] = useState(false)
	const [errorMint, setErrorMint] = useState(false)
	const [message, setMessage] = useState("")
	const [getNftid, setGetNftId] = useState('')

	const provider = getProvider()

	const web3 = new Web3(provider)
	const tetherContract = new web3.eth.Contract(WorABIJSON, collectionAddress)
	const [id, setId] = useState('')


	const onSubmit = async data => {
		if (selectCollection === '') {
			setCollectionError('Please select collection')
			return
		}
		toggle()
		setIsLoading(true)
		setErrorMint(false)
		setMessage("")

		try {
			if (walletType === 'okx') {
				if (
					sessionStorage.getItem('Nftblockchain') !==
					chainMap[parseInt(window.okxwallet.chainId)]
				) {
					await switchNetwork(
						chainIdMap[sessionStorage.getItem('Nftblockchain')]
					)
				}
			} else {
				if (
					sessionStorage.getItem('Nftblockchain') === 'binance' &&
					chainid !== chainId.BINANCE
				) {
					await switchNetworkAsync(chainId.BINANCE)
				} else if (
					sessionStorage.getItem('Nftblockchain') === 'ethereum' &&
					chainid !== chainId.ETHEREUM
				) {
					await switchNetworkAsync(chainId.ETHEREUM)
				} else if (
					sessionStorage.getItem('Nftblockchain') === 'polygon' &&
					chainid !== chainId.AMOY
				) {
					await switchNetworkAsync(chainId.AMOY)
				}
			}
		} catch (error) {
			setIsLoading(false)
			setErrorMint(true)
			return
		}

		let TotalSupply;

		const nftAdd = new FormData()
		nftAdd.append('collectionName', selectCollection)
		nftAdd.append('nftName', data.nftName)
		nftAdd.append('description', data.description)
		nftAdd.append('contractAddress', collectionAddress)
		nftAdd.append('supply', data.supply)
		nftAdd.append('url', data.url)
		nftAdd.append('traits', JSON.stringify(valueList))
		nftAdd.append('chain', getRoyalityFeeMutation?.data?.chain)
		nftAdd.append('asset', data.asset)
		nftAdd.append('nftId', getRoyalityFeeMutation?.data?.nftId)
		// nftAdd.append('isMetadata', data.isMetadata)
		nftAdd.append('isUri', 'create')
		nftAdd.append('creatorType', 'user')
		nftAdd.append('creator', address)
		nftAdd.append('imageFormat', fileType)
		nftAdd.append('contractStandard', "ERC1155")

		const res = await createNftMutation.mutateAsync(nftAdd)
		setGetNftId(getRoyalityFeeMutation?.data?.nftId)
		if (res.status) {
			if (true) {
				try {
					await tetherContract.methods
						.mint(
							address.toString(),
							address.toString(),
							getRoyalityFeeMutation?.data?.nftId,
							data.supply.toString(),
							res.uri
						)
						.send({
							from: address.toString()
						})
						.on('transactionHash', async hash => { })
						.on('receipt', async approveresult => {
							const nftUpdate = new FormData()
							nftUpdate.append('collectionName', selectCollection)
							nftUpdate.append('nftName', data.nftName)
							nftUpdate.append('description', data.description)
							nftUpdate.append('contractAddress', collectionAddress)
							nftUpdate.append('supply', data.supply)
							nftUpdate.append('url', data.url)
							nftUpdate.append('traits', JSON.stringify(valueList))
							nftUpdate.append('chain', getRoyalityFeeMutation?.data?.chain)
							nftUpdate.append('asset', data.asset)
							nftUpdate.append('nftId', getRoyalityFeeMutation?.data?.nftId)
							nftUpdate.append('isUri', 'update')
							nftUpdate.append('uri', res.uri)
							nftUpdate.append('creatorType', 'user')
							nftUpdate.append('creator', address)
							nftUpdate.append('imageFormat', fileType)
							nftUpdate.append('contractStandard', 'ERC1155')

							if (approveresult.status === true) {
								const uripass = await createNftMutation.mutateAsync(nftUpdate)

								if (uripass.status) {
									setIsLoading(false)
									setMintLoading(true)
									setNftImg(uripass.data.asset)
									setId(uripass.data.nftId)
								} else {
									setIsLoading(false)
									setMintLoading(false)
									setErrorMint(true)
								}
							}
						})
				} catch (err) {
					console.log('err: ', err);
					const errorMessage = err.message
					const splitError = errorMessage.split('{');
					const errorString = splitError[0].trim();
					setMessage(errorString)
					setIsLoading(false)
					setMintLoading(false)
					setErrorMint(true)
				}
			}
		} else {
			setIsLoading(false)
			setMintLoading(false)
			setErrorMint(true)
			return
		}
	}

	const viewNftDetail = () => {
		navigate(
			`/assets/${chain}/${collectionAddress}/${Number(id)}`
		)
	}

	return (
		<>
			<div className='row'>
				<div className='col-lg-2 mb-3 text-lg-right pr-lg-5' onClick={back} onKeyDown={(e) => {
					if (e.key === 'Enter' || e.key === ' ') {
						back()
					}
				}}
					role="button" tabIndex={0}>
					<button className='backIcon ml-0'>
						<i className='fas fa-angle-left'></i>
					</button>
				</div>
				<div className='col-lg-3 mb-3'>
					<h3 className='collectionSecHeading text-left'>{t("Create Item")}</h3>
					<h3 className='collectionSecSubHeading text-left pr-lg-5'>
						{t("Once your NFT minted, you will no longer able to edit it")}.
					</h3>
				</div>
				<div className='col-lg-6'>
					<div className='createCollectionCard mb-3'>
						<form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
							{isConnected ? (
								<div className='walletCnt mb-3'>
									<h3>
										{address
											? address.slice(0, 5) + '...' + address.slice(38, 42)
											: ''}
									</h3>
									<span className='walletLabel successLabel ml-auto'>
										{t('Wallet Connected')}
									</span>
								</div>
							) : (
								<div className='walletCnt mb-3'>
									<h3></h3>
									<span
										className='walletLabel successLabel ml-auto'
										style={{ color: 'red' }}
									>
										{t("Please Connect Wallet")}
									</span>
								</div>
							)}

							<hr />
							<div className='form-row'>
								<div className='col-12 mb-3'>
									<div className='d-flex align-items-center flex-wrap'>
										<span className='formLabel mr-2'>{t("Collection")}</span>
										<UncontrolledDropdown
											className={
												getCollectionName !== undefined
													? 'ml-auto collectionDD'
													: `ml-auto collectionDD ${t(errors.collectionName) && 'err'
													}`
											}
										>
											<DropdownToggle
												caret
												className='d-flex align-items-center'
											>
												{selectCollection
													? selectCollection
													: t('Select Collection')}
												<img src={angleDown} className='ml-auto' />
											</DropdownToggle>
											<DropdownMenu>
												<DropdownItem
													tag='a'
													onClick={() => navigate('/choosecollection')}
												>
													<div className='d-flex align-items-center createCollectionrow'>
														<div className='addIconCnt mr-2'>
															<img src={plus} className='plusIcon' />
														</div>
														{t("Create Collection")}
													</div>
												</DropdownItem>
												{getCollectionMutation?.data?.data?.map(collection => (
													<DropdownItem
														tag='a'
														onClick={() => chooseCollectionData(collection)}
														{...register('collectionName')}
														value={collection.collectionName}
													>
														<div className='d-flex align-items-center'>
															{!collection?.logoImage?.startsWith(
																process.env.S3_URL
															) &&
																!collection?.logoImage?.startsWith('ipfs') &&
																collection?.logoImage !== null ? (
																<img
																	src={
																		collection?.logoImage
																			? collection?.logoImage
																			: defaultImage
																	}
																	className='mr-2'
																	style={{
																		height: '40px',
																		aspectRatio: '1',
																		borderRadius: '12px'
																	}}
																	onError={handleImageError}
																/>
															) : (
																<img
																	src={defaultImage}
																	className='mr-2'
																	style={{
																		height: '40px',
																		aspectRatio: '1',
																		borderRadius: '12px'
																	}}
																/>
															)}
															<div className='d-flex flex-column'>
																<h3>{collection.collectionName} </h3>
															</div>
														</div>
													</DropdownItem>
												))}
											</DropdownMenu>
										</UncontrolledDropdown>
									</div>
									{collectionError && (
										<label className='text-danger errLabel ml-auto'>
											{t(collectionError)}
										</label>
									)}
									<hr />
								</div>
								<div className='col-12 mb-4'>
									<div className='d-flex align-items-center flex-wrap'>
										<span className='formLabel'>{t("Upload File")}</span>
										{errors.asset && (
											<label className='text-danger errLabel ml-auto'>
												{t(errors.asset.message)}
											</label>
										)}
									</div>
									<div
										className={
											errors.asset
												? 'err coverCnt d-flex flex-column'
												: 'coverCnt d-flex flex-column'
										}
									>
										<Controller
											name='asset'
											control={control}
											render={({ field: { onChange } }) => (
												<>
													{nftImg ? (
														<>
															{fileType?.startsWith('image') ? (
																<>
																	<img className='coverImg' src={nftImg} />
																	<div
																		className='justify-content-center align-items-center editIcon'
																		{...getRootProps()}
																		onClick={e => e.stopPropagation()}
																		onKeyDown={(e) => {
																			if (e.key === 'Enter' || e.key === ' ') {
																				e.stopPropagation()
																			}
																		}}
																		role="button" tabIndex={0}
																	>
																		<div className='editUplCnt'>
																			<label for='editUplCnt'>
																				<img src={Pencil} />
																			</label>
																			<input
																				{...getInputProps()}
																				id='editUplCnt'
																				accept='.png, .gif, .webp, .jpg, .jpeg, .mp4'
																			/>
																		</div>
																	</div>
																</>
															) : fileType?.startsWith('video') ? (
																<>
																	<video
																		src={nftImg}
																		alt='Uploaded'
																		controls='false'
																		muted
																		autoPlay
																		className='coverImg img-fluid'
																	/>
																	<div
																		className='justify-content-center align-items-center editIcon'
																		{...getRootProps()}
																		onClick={e => e.stopPropagation()}
																		onKeyDown={(e) => {
																			if (e.key === 'Enter' || e.key === ' ') {
																				e.stopPropagation()
																			}
																		}}
																		role="button" tabIndex={0}
																	>
																		<div className='editUplCnt'>
																			<label for='editUplCnt'>
																				<img src={Pencil} />
																			</label>
																			<input
																				{...getInputProps()}
																				id='editUplCnt'
																				accept='.png, .gif, .webp,.jpg,.jpeg,.mp4'
																			/>
																		</div>
																	</div>
																</>
															) : (
																<div
																	className='d-flex flex-column align-items-center'
																	{...getRootProps()}
																	onClick={e => e.stopPropagation()}
																	onKeyDown={(e) => {
																		if (e.key === 'Enter' || e.key === ' ') {
																			e.stopPropagation()
																		}
																	}}
																	role="button" tabIndex={0}
																>
																	{isDragActive ? (
																		<p>Drop the image here ...</p>
																	) : (
																		<>
																			<span>{t("PNG, GIF, WEBP, JPG, JPEG or MP4")}.</span>
																			<span className='fs-12 greyTxt'>
																				{t("Max 100mb.At least 1400*360 pixels")}.
																			</span>
																		</>
																	)}
																	<div className='chooseFileBtn mt-2'>
																		<input
																			{...getInputProps()}
																			id='chooseBtn'
																			accept='.png, .gif, .webp,.jpg,.jpeg,.mp4'
																		/>
																		<label for='chooseBtn'>{t("Choose File")}</label>
																	</div>
																</div>
															)}
														</>
													) : (
														<div
															className='d-flex flex-column align-items-center'
															{...getRootProps()}
															onClick={e => e.stopPropagation()}
															onKeyDown={(e) => {
																if (e.key === 'Enter' || e.key === ' ') {
																	e.stopPropagation()
																}
															}}
															role="button" tabIndex={0}
														>
															{isDragActive ? (
																<p>Drop the image here ...</p>
															) : (
																<>
																	<span>{t("PNG, GIF, WEBP, JPG, JPEG or MP4")}.</span>
																	<span className='fs-12 greyTxt'>
																		{t("Max 100mb. At least 1400*360 pixels")}.
																	</span>
																</>
															)}
															<div className='chooseFileBtn mt-2'>
																<input
																	{...getInputProps()}
																	id='chooseBtn'
																	accept='.png, .gif, .webp,.jpg,.jpeg,.mp4'
																/>
																<label for='chooseBtn'>{t("Choose File")}</label>
															</div>
														</div>
													)}
												</>
											)}
										/>
									</div>
								</div>

								<div className='col-12 mb-3'>
									<div className='d-flex align-items-center flex-wrap'>
										<span className='formLabel'>{t("Name")}</span>
										{errors.nftName && (
											<label className='text-danger errLabel ml-auto'>
												{t(errors.nftName.message)}
											</label>
										)}
									</div>

									<input
										{...register('nftName')}
										type='text'
										placeholder={t('Name your NFT')}
										className={`form-control ${errors.nftName && 'err'}`}
									/>
								</div>
								<div className='col-12 mb-3'>
									<div className='d-flex align-items-center flex-wrap'>
										<span className='formLabel'>{t("Supply")}</span>
										{errors.supply && (
											<label className='text-danger errLabel ml-auto'>
												{errors.supply.message}
											</label>
										)}
									</div>
									<input
										{...register('supply')}
										type='text'
										placeholder={t('Supply')}
										className={`form-control ${errors.supply && 'err'}`}
									/>
								</div>

								<div className='col-12 mb-3'>
									<span className='formLabel'>{t("Description (Optional)")}</span>
									{errors.description && (
										<label className='text-danger errLabel ml-auto'>
											{t(errors.description.message)}
										</label>
									)}
									<textarea
										{...register('description')}
										className={`form-control ${errors.description && 'err'}`}
										placeholder={t('Enter Description')}
									/>
									<hr />
								</div>

								<div className='col-12 mb-3'>
									<div className='d-flex align-items-center flex-wrap'>
										<span className='formLabel'>{t("External Link (Optional)")}</span>

									</div>
									<input
										{...register('url')}
										type='text'
										placeholder={t('Enter Link Here')}
										className={'form-control'}
									/>

									<hr />
								</div>
								<div className='col-12 mb-3'>
									<div className='d-flex align-items-center flex-wrap'>
										<span className='formLabel'>{t('Traits')}</span>
									</div>
									{valueList.length > 0 &&
										valueList.map(value => {
											const key = Object.keys(value)[0]
											return (
												<div className='d-flex urlFieldCnt traitsCnt mb-2'>
													<div class='input-group mb-2'>
														<input
															type='text'
															class='form-control'
															value={key}
														/>
														<div class='input-group-append'>
															<div class='input-group-text'>{value[key]}</div>
														</div>
													</div>
													<div
														className='socilaMediaIconCnt ml-2'
														onClick={() => {
															setInput1(key)
															setInput2(value[key])
															handleOpenDialog(() => {
																setTraitToEdit({
																	[key]: value[key]
																})
															})
														}}

														onKeyDown={e => {
															if (e.key === 'Enter' || e.key === ' ') {
																e.stopPropagation();
																setInput1(key)
																setInput2(value[key])
																handleOpenDialog(() => {
																	setTraitToEdit({
																		[key]: value[key]
																	})
																})
															}
														}}
														role='button'
														tabIndex={0}
													>
														<img src={Pencil} />
													</div>
													<div
														className='socilaMediaIconCnt ml-2'
														onClick={() => {
															setValueList(
																valueList.filter(
																	v =>
																		JSON.stringify(v) !== JSON.stringify(value)
																)
															)
														}}
														role='button'
														tabIndex={0}
														onKeyDown={e => {
															if (e.key === 'Enter' || e.key === ' ') {
																e.stopPropagation();
																setValueList(
																	valueList.filter(
																		v =>
																			JSON.stringify(v) !== JSON.stringify(value)
																	)
																)
															}
														}}
													>
														-
													</div>
												</div>
											)
										})}
									<div
										className='d-flex align-items-center cursor'
										onClick={toggle1}
										onKeyDown={(e) => {
											if (e.key === 'Enter' || e.key === ' ') {
												toggle1()
											}
										}}
										role="button" tabIndex={0}
									>
										<div className='addIconCnt hover mr-2'>
											<img src={plus} />
										</div>
										{t("Add Trait")}
									</div>
									<hr />
								</div>

								<div className='col-12 mb-3'>
									<button
										type='submit'
										className='btn btn-block gradientBtn'
										disabled={!getRoyalityFeeMutation.isSuccess}
									>
										{t("Create Item")}
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>

			<Modal
				isOpen={modal}
				toggle={toggle}
				centered='true'
				className='curMdl createScsMdl'
				backdropClassName='selCurBp'
				backdrop='static'
				keyboard={false}
			>
				{isLoading ? (
					<div>
						<h3 className='walletHeading my-3'>{t("Loading")}</h3>
						<h3 className='walletSubHeading'>
							{t("To continue send transaction with your wallet")}.
						</h3>
						<div className='d-flex justify-content-center align-items-center loaderCont'>
							{localStorage.getItem('light_theme') == 'true' ? (
								<img src={walletLightLoaderCnt} />
							) : (
								<img src={walletLoaderCnt} />
							)}
							<div className='loaderAnimCnt'>
								<img src={walletLoader} className='loaderAnimation' />
							</div>
						</div>
					</div>
				) : null}
				{mintloading ? (
					<div className='createContent'>
						<button className='btn closeBtn' onClick={toggle}>
							<svg
								width='12'
								height='12'
								viewBox='0 0 12 12'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z'
									fill='#595F6A'
								/>
							</svg>
						</button>
						<div className='d-flex justify-content-center'>
							{fileType?.startsWith('image') ? (
								<img src={nftImg ? nftImg : aiPfpClub} width='70px' style={{ borderRadius: "10px" }} />
							) : null}
						</div>
						<h3 className='walletHeading my-3'>{t("Item Minted Successfully")}</h3>
						<button
							type='button'
							className='btn btn-block gradientBtn'
							onClick={() => viewNftDetail()}
						>
							{t("View Item")}
						</button>
					</div>
				) : null}

				{errorMint ? (
					<div className='createContent'>
						<button className='btn closeBtn' onClick={toggle}>
							<svg
								width='12'
								height='12'
								viewBox='0 0 12 12'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z'
									fill='#595F6A'
								/>
							</svg>
						</button>
						<div className='d-flex justify-content-center'>
							<img src={IconModal} />
						</div>
						<h3 className='walletHeading my-3'> {message == "insufficient funds" ? t("Insufficient Funds") : t("Something went wrong")}</h3>
						<button
							type='button'
							className='btn btn-block darkBtn'
							onClick={toggle}
						>
							{t('Okay')}
						</button>
					</div>
				) : null}
			</Modal>

			<Modal
				isOpen={modal1}
				toggle={handleOpenDialog}
				centered='true'
				className='curMdl createScsMdl'
				backdropClassName='selCurBp'
				backdrop='static'
				keyboard={false}
			>
				<div className='createContent'>
					<button className='btn closeBtn' onClick={handleCloseDialog}>
						<svg
							width='12'
							height='12'
							viewBox='0 0 12 12'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M7.46875 6L10.8438 9.375C11.0312 9.5625 11.0312 9.90625 10.8438 10.0938L10.0625 10.875C9.875 11.0625 9.53125 11.0625 9.34375 10.875L6 7.5L2.625 10.875C2.4375 11.0625 2.09375 11.0625 1.90625 10.875L1.125 10.0938C0.9375 9.90625 0.9375 9.5625 1.125 9.375L4.5 6L1.125 2.65625C0.9375 2.46875 0.9375 2.125 1.125 1.9375L1.90625 1.15625C2.09375 0.96875 2.4375 0.96875 2.625 1.15625L6 4.53125L9.34375 1.15625C9.53125 0.96875 9.875 0.96875 10.0625 1.15625L10.8438 1.9375C11.0312 2.125 11.0312 2.46875 10.8438 2.65625L7.46875 6Z'
								fill='#595F6A'
							/>
						</svg>
					</button>
					<div className='d-flex justify-content-center'>
						{fileType?.startsWith('image') ? (
							<img src={nftImg ? nftImg : aiPfpClub} width='50px' />
						) : null}
					</div>
					<h3 className='walletHeading my-3'>{t("Add Trait")}</h3>
					<form>
						<div className='row'>
							<div className='col-12 mb-3'>
								<div className='d-flex align-items-center flex-wrap'>
									<span className='formLabel mb-2'>{t('Type')}</span>
								</div>
								<input
									type='text'
									placeholder={t('Enter Type')}
									className='form-control'
									value={input1}
									onChange={handleInput1Change}
								/>
							</div>
							<div className='col-12 mb-3'>
								<div className='d-flex align-items-center flex-wrap'>
									<span className='formLabel mb-2'>{t('Name')}</span>
								</div>
								<input
									type='text'
									placeholder={t('Enter Name')}
									className='form-control'
									value={input2}
									onChange={handleInput2Change}
								/>
							</div>
						</div>
					</form>
					<button
						type='button'
						className='btn btn-block gradientBtn'
						onClick={handleTraits}
						disabled={!input1 || !input2}
					>
						{t("Add Trait")}
					</button>
				</div>
			</Modal>
		</>
	)
}

export default ChooseCollection
