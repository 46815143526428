import { useMutation, useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { queryClient } from '../../../lib/react-query'
import DataService from '../../../lib/DataService'
const { getRequest, postRequest } = DataService()

export const fetchNotable = () => {
	return getRequest('home/get_Notable_Collections')
}

export const UsefetchNotable = () => {
	return useQuery({
		queryKey: ['notable_list'],
		queryFn: fetchNotable
	})
}

export const fetchViewCount = data => {
	return postRequest('home/update_view_count', data)
}

export const GetFetchViewCount = () => {
	const navigate = useNavigate()

	return useMutation(fetchViewCount, {
		onMutate: () => {},
		onError: error => {},
		onSuccess: ViewCountData => {
			if (ViewCountData?.data?.status === true) {
				navigate('/')
				queryClient.refetchQueries(['notable_list'])
			}
		}
	})
}

export const fetchFeatured = () => {
	return getRequest('home/get_Feature_Collections')
}

export const UsefetchFeatured = () => {
	return useQuery({
		queryKey: ['featured_list'],
		queryFn: fetchFeatured
	})
}
