import { useEffect, useState } from 'react'
import {
	configureChains,
	erc20ABI,
	useAccount,
	useNetwork,
	usePublicClient,
	useWalletClient
} from 'wagmi'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'
import { createPublicClient, formatUnits, http } from 'viem'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'

import { useCartModalActions, useCartModalState } from './store'
import { useFetchCartItems } from './fetchCartItems'
import { CheckIcon } from '../../../components/CheckIcon'
import { formatAddress } from '../../../utils/formatAddress'
import { copyText } from '../../../utils/copyText'
import { usePurchaseLoadingModalActions } from '../purchase-loading/store'
import { useCheckoutModalActions } from '../checkout/store'
import { useSomethingWentWrongModalActions } from '../error/store'
import { useCheckoutCartItems } from '../../../hooks/useCheckoutCartItems'
import { Spinner } from '../../loader/spinner/Spinner'
import { chainId } from '../../../config/constants/chains'
import { useApproveToken } from '../../../hooks/useApproveToken'
import { useSwitchNetwork } from '../../../hooks/useSwitchNetwork'
import { useToast } from '../../../utils/toast/customSuccess'
import { CloseIcon } from '../buy-now/CloseIcon'
import {
	useGetBnbBalance,
	useGetEthBalance,
	useGetMaticBalance
} from '../../../hooks/useBalance'
import { getContract } from '../../../utils/getContract'
import { CHAINS } from '../../../lib/wagmi'
import { PUBLIC_NODES } from '../../../config/nodes'
import { handleImageError } from '../../../hooks/useHandleImg'

import removeIcon from '../../../assets/images/collection/trash-alt.svg'
import yellowtick20 from '../../../assets/images/collection/yellow-tick_20px.svg'
import copyIcon from '../../../assets/images/copyIcon.svg'
import defaultImage from '../../../assets/images/homeExplorer/placeholder.png'

import '../modal.scss'
import { chainIdMap, chainMap, nativeCoinMap } from '../../../utils/getFilterData'
import DataService from '../../../lib/DataService'




const mostNodesConfig = Object.values(PUBLIC_NODES).reduce((prev, cur) => {
	return cur.length > prev ? cur.length : prev
}, 0)

const { chains } = configureChains(
	CHAINS,
	Array.from({ length: mostNodesConfig })
		.map((_, i) => i)
		.map((i) => {
			return jsonRpcProvider({
				rpc: (chain) => {
					return PUBLIC_NODES[chain.id]?.[i]
						? {
							http: PUBLIC_NODES[chain.id][i]
						}
						: null
				}
			})
		})
)

function getChain(chainId) {
	return chains.find((c) => c.id === chainId)
}

const chainNameToChainConfig = {
	binance: getChain(chainId.BINANCE),
	ethereum: getChain(chainId.ETHEREUM),
	polygon: getChain(chainId.AMOY)
}

function Nft({ nft, onRemoveClick }) {
	const isVideo = nft.details.asset.endsWith('.mp4')

	return (
		<div className='cartItem'>
			<div className='itemImg'>
				{!isVideo ? (
					typeof nft?.details?.asset == 'string' &&
						nft?.details?.asset !== undefined &&
						!nft?.details?.asset?.startsWith(process.env.S3_URL) &&
						!nft?.details?.asset?.startsWith('ipfs') &&
						nft?.details?.asset !== null ? (
						<img
							className='object-cover'
							alt={nft.details.name}
							src={
								nft?.details?.asset
									? nft?.details?.asset
									: defaultImage
							}
							onError={handleImageError}
						/>
					) : (
						<img
							className='object-cover'
							alt={nft.details.name}
							src={defaultImage}
						/>
					)
				) : (

					<video loop autoPlay muted playsInline preload='metadata' controlslist='nodownload' id='video-asset' style={{
						objectFit: 'contain',
						height: '84px',
						width: '84px'
					}}>
						<source src={nft.details.asset ? nft.details.asset : defaultImage} type="video/mp4" onError={handleImageError} />
					</video>

				)}
			</div>
			<div className='flex-grow-1 p-3'>
				<div className='d-flex justify-content-between mb-2'>
					<div className='itemName'>
						{nft.collection.name}{' '}
						{nft.collection.isVerified && (
							<img
								src={yellowtick20}
								alt='yellowtick20'
								className='ml-1'
							/>
						)}
					</div>
					<span className='' onClick={() => onRemoveClick(nft)} onKeyDown={(e) => {
						if (e.key === 'Enter' || e.key === ' ') {
							onRemoveClick(nft);
						}
					}}
						role="button"
						tabIndex={0}>
						<button className='removebtn'>
							<img src={removeIcon} alt='' />
						</button>
					</span>
				</div>
				<div className='d-flex justify-content-between mb-n1'>
					<div className='itemId'>{nft.details.name}</div>
					{nft.details.status === 'Active' ? (
						<span className='itemBal'>{`${parseFloat(
							nft?.details?.price?.toFixed(8)
						)} ${nft.details.currency}`}</span>
					) : (
						<span className='itemBal' style={{ color: 'red' }}>
							{nft.details.status}
						</span>
					)}
				</div>
			</div>
		</div>
	)
}

export function CartModal() {
	const [copied, setCopied] = useState(false)
	const [currenciesInCart, setCurrenciesInCart] = useState()
	const [balance, setBalance] = useState()
	const [isRemoveFromCartModalOpen, setIsRemovedFromCartModalOpen] =
		useState(false)
	const { t } = useTranslation()
	const { isOpen } = useCartModalState()
	const { address: account } = useAccount()
	const { toggleCartModal } = useCartModalActions()
	const { togglePurchaseLoadingModal } = usePurchaseLoadingModalActions()
	const { toggleCheckoutModal, setAsset } = useCheckoutModalActions()
	const { toggleSomethingWentWrongModal } =
		useSomethingWentWrongModalActions()
	const { switchNetworkAsync } = useSwitchNetwork()
	const { chain } = useNetwork()
	const [itemToRemove, setItemToRemove] = useState(null)
	const { checkoutCartItems } = useCheckoutCartItems()
	const { approveTokens } = useApproveToken()
	const publicClient = usePublicClient()
	const { data: walletClient } = useWalletClient()

	const { balance: bnbBalance } = useGetBnbBalance()
	const { balance: ethBalance } = useGetEthBalance()
	const { balance: maticBalance } = useGetMaticBalance()
	const nativeCurrencyBalanceMap = {
		ETH: ethBalance,
		BNB: bnbBalance,
		MATIC: maticBalance
	}

	const { data, isLoading } = useFetchCartItems(account, isOpen)
	const nfts = data?.data
	const hasNoItems = data?.data?.length === 0
	const nftsBasedOnChain = nfts?.reduce((acc, cur) => {
		if (!acc.hasOwnProperty(cur.details.chain)) {
			acc[cur.details.chain] = nfts.filter(
				(nft) => nft.details.chain === cur.details.chain
			)
		}
		return acc
	}, {})

	const chains = nftsBasedOnChain ? Object.keys(nftsBasedOnChain) : []

	const currencies =
		chains && chains.length > 0
			? Object.values(nftsBasedOnChain)[0].reduce((acc, cur, i, arr) => {
				if (!acc.hasOwnProperty(cur.details.currency)) {
					acc[cur.details.currency] = arr.filter(
						(nft) =>
							nft.details.currency === cur.details.currency
					)
				}
				return acc
			}, {})
			: {}

	const nativeCoin = nativeCoinMap[chains[0]]
	const totalNativeCoinAmount =
		currencies[nativeCoin] &&
		currencies[nativeCoin].reduce((acc, cur) => {
			acc += cur.details.price
			return acc
		}, 0)

	const tokens =
		chains && chains.length > 0
			? Object.values(nftsBasedOnChain)[0].reduce((acc, cur, i, arr) => {
				if (
					['MATIC', 'BNB', 'ETH'].includes(cur.details.currency)
				) {
					return acc
				}
				if (!acc.hasOwnProperty(cur.details.currency)) {
					acc[cur.details.currency] = arr.filter(
						(nft) =>
							nft.details.currency === cur.details.currency
					)
				}
				return acc
			}, {})
			: {}

	useEffect(() => {
		if (!data || !isOpen) return

		const currencies = data?.data?.reduce((acc, cur, i, arr) => {
			if (!acc.hasOwnProperty(cur.details.currency)) {
				acc[cur.details.currency] = {
					totalPrice: arr
						.filter(
							(n) => n.details.currency === cur.details.currency
						)
						.reduce((a, c) => {
							a += c.details.price
							return a
						}, 0),
					address: cur.details.currencyAddress,
					decimals: cur.details.decimals,
					isCoin: cur.details.isCoin
				}
			}

			return acc
		}, {})
		setCurrenciesInCart(currencies)

		const nftsBasedOnChain = nfts?.reduce((acc, cur) => {
			if (!acc.hasOwnProperty(cur.details.chain)) {
				acc[cur.details.chain] = nfts.filter(
					(nft) => nft.details.chain === cur.details.chain
				)
			}
			return acc
		}, {})

		const chains = nftsBasedOnChain ? Object.keys(nftsBasedOnChain) : []
		if (chains.length !== 1) return

		let balance = {}
		Object.entries(currencies).forEach(async ([key, value]) => {
			if (!balance.hasOwnProperty(key)) {
				if (value.isCoin) {
					balance = {
						...balance,
						[key]: {
							balance: nativeCurrencyBalanceMap[nativeCoin]
						}
					}
					setBalance(balance)
				} else {
					try {
						const [chain] = chains
						const client = createPublicClient({
							chain: chainNameToChainConfig[chain],
							transport: http()
						})

						const contract = getContract({
							abi: erc20ABI,
							address: value.address,
							publicClient: client
						})
						const rawBalance = await contract.read.balanceOf([
							account
						])

						balance = {
							...balance,
							[key]: {
								balance: parseFloat(
									formatUnits(rawBalance, value.decimals)
								)
							}
						}
						setBalance(balance)
					} catch (error) {
					}
				}
			}
		})
	}, [data, isOpen])

	const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true'

	const isBalanceSufficient =
		!!account &&
		isLoggedIn &&
		chains.length === 1 &&
		Object.entries(currenciesInCart ?? {}).length > 0 &&
		Object.entries(balance ?? {}).filter(([key, value]) => {
			if (!currenciesInCart[key]) return
			return value.balance < currenciesInCart[key].totalPrice
		}).length === 0

	const cannotCheckout = nfts?.some(
		(nft) =>
			nft.details.status === 'Inactive' ||
			nft.details.status === 'Sold' ||
			nft.details.status === 'Expired' ||
			nft.details.status === 'Canceled'
	)

	if (!account) {
		return
	}

	const getAmountsToApprove = (nfts) => {
		const tokens = nfts.reduce((acc, cur, i, arr) => {
			if (['MATIC', 'BNB', 'ETH'].includes(cur.details.currency)) {
				return acc
			}
			if (!acc.hasOwnProperty(cur.details.currency)) {
				acc[cur.details.currency] = arr.filter(
					(nft) => nft.details.currency === cur.details.currency
				)
			}
			return acc
		}, {})

		const amounts = Object.entries(tokens).map(([key, value]) => {
			const amount = value.reduce((acc, cur) => {
				acc += cur.details.price
				return acc
			}, 0)
			return {
				amount,
				address: value[0].details.currencyAddress
			}
		})

		return amounts
	}

	const getValue = (nfts) => {
		const currency = nfts.reduce((acc, cur, i, arr) => {
			if (!['MATIC', 'BNB', 'ETH'].includes(cur.details.currency)) {
				return acc
			}
			if (!acc.hasOwnProperty(cur.details.currency)) {
				acc[cur.details.currency] = arr.filter(
					(nft) => nft.details.currency === cur.details.currency
				)
			}
			return acc
		}, {})

		const value =
			Object.values(currency).length !== 0
				? Object.values(currency)[0].reduce((acc, cur) => {
					acc += cur.details.price
					return acc
				}, 0)
				: 0

		return value
	}

	const handleCheckout = async () => {
		setAsset(Object.values(nftsBasedOnChain)[0])
		togglePurchaseLoadingModal()

		try {
			let currentChain = chain
			if (chains[0] !== chainMap[chain.id]) {
				const c = await switchNetworkAsync(chainIdMap[chains[0]])
				currentChain = c
			}

			const amountsToApprove = getAmountsToApprove(
				Object.values(nftsBasedOnChain)[0]
			)

			if (amountsToApprove.length !== 0) {
				await approveTokens(amountsToApprove, currentChain)
			}
			const value = getValue(Object.values(nftsBasedOnChain)[0])
			await checkoutCartItems(nfts, currentChain, value)
			toggleCheckoutModal()
			toggleCartModal()
		} catch (error) {
			toggleSomethingWentWrongModal()
		} finally {
			togglePurchaseLoadingModal()
		}
	}

	const toggleRemoveItemFromCartModal = () => {
		setIsRemovedFromCartModalOpen(!isRemoveFromCartModalOpen)
	}

	const onRemoveClick = (item) => {
		setItemToRemove(item)
		toggleRemoveItemFromCartModal()
	}

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			setCopied(true)
			copyText(account, () => {
				setTimeout(() => {
					setCopied(false)
				}, 600)
			});
		}
	};

	return (
		<>
			<Modal
				isOpen={isOpen}
				centered='true'
				className='mymodal'
				backdropClassName='selCurBp'
				keyboard={false}
			>
				<ModalHeader close={<CloseIcon onClose={toggleCartModal} />}>
					<h5 className='modal-title'>{t('Your cart')}</h5>
				</ModalHeader>
				<hr className='hrCls my-0' />
				<ModalBody>
					{isLoading ? (
						<div
							className='d-flex justify-content-center align-items-center'
							style={{ height: '500px' }}
						>
							<Spinner />
						</div>
					) : !hasNoItems ? (
						<>
							<div className='d-flex justify-content-between mb-3'>
								<span className='fs-16 fw-600 dark-text'>
									{nfts?.length} {t('Items')}
								</span>
							</div>
							<div className='scroller pr-2'
								style={{
									maxHeight: '250px',
									minHeight: '86px',
									overflowY: 'auto'
								}}
							>
								{nfts?.map((nft) => {
									return (
										<Nft
											key={nft.id}
											nft={nft}
											onRemoveClick={(item) =>
												onRemoveClick(item)
											}
										/>
									)
								})}
							</div>
							<hr className='hrCls' />
							<div className='d-flex mb-3'>
								<div>
									<span className='fs-16 fw-600'>
										{t('Wallet')}
									</span>{' '}
									<span className='greenLabel'>
										{t('Connected')}
									</span>
								</div>
							</div>
							<div className='walletCnt d-flex align-items-center flex-wrap mb-3'>
								<h3>{formatAddress(account, 8)}</h3>
								{copied ? (
									<CheckIcon className='ml-auto' />
								) : (
									<img
										src={copyIcon}
										alt='copy-icon'
										className='ml-auto hover'
										onClick={() => {
											setCopied(true)
											copyText(account, () => {
												setTimeout(() => {
													setCopied(false)
												}, 600)
											})
										}}
										onKeyDown={handleKeyDown} role="button" tabIndex={0}
									/>
								)}
							</div>
							<hr className='hrCls' />
							{chains.length === 1 && (
								<div className='borderRoundedCard'>
									<div className='d-flex justify-content-between'>
										<div className='fs-14 fw-400 dark-text-secondary'>
											{t('Total')}
										</div>
										<div className='fs-16 fw-400 dark-text-grey'>
											{cannotCheckout ? (
												'--'
											) : (
												<span className='fw-700 dark-text'>{`${data?.totalPrice} ${data?.currencySymbol}`}</span>
											)}
										</div>
									</div>
								</div>
							)}

							<div className='noteTxt pt-3'>
								<span className='pr-2'>
									<svg
										width='21'
										height='20'
										viewBox='0 0 21 20'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M10.5 2.25C14.75 2.25 18.25 5.75 18.25 10C18.25 14.2812 14.75 17.75 10.5 17.75C6.21875 17.75 2.75 14.2812 2.75 10C2.75 5.75 6.21875 2.25 10.5 2.25ZM10.5 16.25C13.9375 16.25 16.75 13.4688 16.75 10C16.75 6.5625 13.9375 3.75 10.5 3.75C7.03125 3.75 4.25 6.5625 4.25 10C4.25 13.4688 7.03125 16.25 10.5 16.25ZM10.5 5.6875C11.2188 5.6875 11.8125 6.28125 11.8125 7C11.8125 7.75 11.2188 8.3125 10.5 8.3125C9.75 8.3125 9.1875 7.75 9.1875 7C9.1875 6.28125 9.75 5.6875 10.5 5.6875ZM12.25 13.625C12.25 13.8438 12.0625 14 11.875 14H9.125C8.90625 14 8.75 13.8438 8.75 13.625V12.875C8.75 12.6875 8.90625 12.5 9.125 12.5H9.5V10.5H9.125C8.90625 10.5 8.75 10.3438 8.75 10.125V9.375C8.75 9.1875 8.90625 9 9.125 9H11.125C11.3125 9 11.5 9.1875 11.5 9.375V12.5H11.875C12.0625 12.5 12.25 12.6875 12.25 12.875V13.625Z'
											fill='#6E737E'
										/>
									</svg>
								</span>
								<span >
									{t(
										'Items in your cart are not guaranteed at purchase.'
									)}
								</span>
							</div>

							{chains.length !== 1 && (
								<div className='noteTxt mt-3'>
									<span className='pr-2'>
										<svg
											width='21'
											height='20'
											viewBox='0 0 21 20'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M10.5 2.25C14.75 2.25 18.25 5.75 18.25 10C18.25 14.2812 14.75 17.75 10.5 17.75C6.21875 17.75 2.75 14.2812 2.75 10C2.75 5.75 6.21875 2.25 10.5 2.25ZM10.5 16.25C13.9375 16.25 16.75 13.4688 16.75 10C16.75 6.5625 13.9375 3.75 10.5 3.75C7.03125 3.75 4.25 6.5625 4.25 10C4.25 13.4688 7.03125 16.25 10.5 16.25ZM10.5 5.6875C11.2188 5.6875 11.8125 6.28125 11.8125 7C11.8125 7.75 11.2188 8.3125 10.5 8.3125C9.75 8.3125 9.1875 7.75 9.1875 7C9.1875 6.28125 9.75 5.6875 10.5 5.6875ZM12.25 13.625C12.25 13.8438 12.0625 14 11.875 14H9.125C8.90625 14 8.75 13.8438 8.75 13.625V12.875C8.75 12.6875 8.90625 12.5 9.125 12.5H9.5V10.5H9.125C8.90625 10.5 8.75 10.3438 8.75 10.125V9.375C8.75 9.1875 8.90625 9 9.125 9H11.125C11.3125 9 11.5 9.1875 11.5 9.375V12.5H11.875C12.0625 12.5 12.25 12.6875 12.25 12.875V13.625Z'
												fill='#6E737E'
											/>
										</svg>
									</span>
									<span>
										{t(
											'There are multiple Networks has been added. Choose your preferred network of NFTs alone'
										)}
									</span>
								</div>
							)}

							{currenciesInCart &&
								balance &&
								Object.entries(balance).map(([key, value]) => {
									if (!currenciesInCart[key]) return null

									if (
										value.balance <
										currenciesInCart[key].totalPrice
									) {
										return (
											<div className='mt-2'>
												<p className='text-danger cartFont fs-14 text-center'>
													{t("Insufficient")} {t(key)} {t("balance!")}
												</p>
											</div>
										)
									} else {
										return null
									}
								})
							}

							{
								<button
									className='btn btn-block checkoutBtn mt-4'
									onClick={handleCheckout}
									disabled={
										chains.length !== 1 ||
										!isBalanceSufficient ||
										cannotCheckout
									}
								>
									{t('Checkout')}
								</button>
							}
						</>
					) : (
						<div
							style={{
								height: '200px',
								display: 'flex',
								flexDirection: 'column'
							}}
						>
							<div
								style={{
									flexGrow: '1',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center'
								}}
							>
								<p style={{ color: '#C5C5C5', fontFamily: 'Poppins' }}>{t("No items added yet")}.</p>
							</div>
							<div className='noteTxt'>
								<span className='pr-2'>
									<svg
										width='21'
										height='20'
										viewBox='0 0 21 20'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M10.5 2.25C14.75 2.25 18.25 5.75 18.25 10C18.25 14.2812 14.75 17.75 10.5 17.75C6.21875 17.75 2.75 14.2812 2.75 10C2.75 5.75 6.21875 2.25 10.5 2.25ZM10.5 16.25C13.9375 16.25 16.75 13.4688 16.75 10C16.75 6.5625 13.9375 3.75 10.5 3.75C7.03125 3.75 4.25 6.5625 4.25 10C4.25 13.4688 7.03125 16.25 10.5 16.25ZM10.5 5.6875C11.2188 5.6875 11.8125 6.28125 11.8125 7C11.8125 7.75 11.2188 8.3125 10.5 8.3125C9.75 8.3125 9.1875 7.75 9.1875 7C9.1875 6.28125 9.75 5.6875 10.5 5.6875ZM12.25 13.625C12.25 13.8438 12.0625 14 11.875 14H9.125C8.90625 14 8.75 13.8438 8.75 13.625V12.875C8.75 12.6875 8.90625 12.5 9.125 12.5H9.5V10.5H9.125C8.90625 10.5 8.75 10.3438 8.75 10.125V9.375C8.75 9.1875 8.90625 9 9.125 9H11.125C11.3125 9 11.5 9.1875 11.5 9.375V12.5H11.875C12.0625 12.5 12.25 12.6875 12.25 12.875V13.625Z'
											fill='#6E737E'
										/>
									</svg>
								</span>
								<span>
									{t(
										'Items in your cart are not guaranteed at purchase.'
									)}
								</span>
							</div>

							<button
								className='btn btn-block checkoutBtn mt-4'
								disabled
							>
								{t('Continue')}
							</button>
						</div>
					)}
				</ModalBody>
			</Modal>
			<RemoveItemFromCartModal
				isOpen={isRemoveFromCartModalOpen}
				onClose={toggleRemoveItemFromCartModal}
				asset={itemToRemove}
			/>
		</>
	)
}

function RemoveItemFromCartModal({ isOpen, onClose, asset }) {
	const [videoError, setVideoError] = useState(false)
	const [remove, setRemove] = useState(true)
	const { postRequest } = DataService()

	const { t } = useTranslation()
	const queryClient = useQueryClient()
	const toast = useToast()
	const { address: account } = useAccount()
	const { toggleSomethingWentWrongModal } =
		useSomethingWentWrongModalActions()

	if (!asset || !account) {
		return null
	}

	const isVideo = asset.details.asset.endsWith('.mp4')

	const addToFavorites = async () => {
		try {
			setRemove(false)
			onClose()
			const res = await postRequest('asset/favorite-mutation', {
				nftId: asset.details.nftId,
				contractAddress: asset.details.contractAddress,
				status: true,
				address: account
			})
			if (res?.data) {
				setRemove(true)
				queryClient.invalidateQueries(['asset'])
				return res
			}
		} catch (error) {
			setRemove(true)
			throw new Error(error)
		}
	}

	const removeFromCart = async () => {
		try {
			setRemove(false)
			onClose()
			const res = await postRequest('asset/cart-mutation', {
				orderId: asset.details.orderId,
				userAddress: account,
				status: false
			})
			if (res?.data) {
				toast.success('Item removed from the cart')
				setRemove(true)
				queryClient.invalidateQueries(['cart'])
				queryClient.invalidateQueries(['asset'])
				queryClient.invalidateQueries(['nfts'])
				queryClient.invalidateQueries(['listings'])
				return res
			}
		} catch (error) {
			setRemove(true)
			throw new Error(error)
		}
	}

	const handleAddToFavoritesAndRemove = async () => {
		try {
			await addToFavorites()
			await removeFromCart()
			queryClient.invalidateQueries(["nfts"]);
			queryClient.invalidateQueries(["cart"]);
			queryClient.invalidateQueries(['asset'])
			queryClient.invalidateQueries(['listings'])
		} catch (error) {
			toggleSomethingWentWrongModal()
		}
	}

	const handleVideoError = () => {
		setVideoError(true)
	}

	return (
		<Modal
			isOpen={isOpen}
			centered='true'
			className='mymodal'
			backdropClassName='selCurBp'
			keyboard={false}
		>
			<ModalHeader close={<CloseIcon onClose={onClose} />}>
				<h5 className='modal-title'>{t('Remove from cart')}</h5>
			</ModalHeader>
			<ModalBody>
				<div className='text-center'>
					<div className='subcheckTxt mb-3'>
						{t(
							'Are you sure you want to remove the item from your cart'
						)}{' '}
					</div>
				</div>

				<div>
					<div className='cartItem'>
						<div className='itemImg'>
							{!isVideo ? (
								<img
									className='object-cover'
									alt='asset'
									src={
										asset.details.asset
											? asset.details.asset
											: defaultImage
									}
									onError={handleImageError}
								/>
							) : videoError ? (
								<img
									className='object-cover'
									alt='asset'
									src={defaultImage}
								/>
							) : (
								
								<video loop autoPlay muted playsInline preload='metadata' controlslist='nodownload' id='video-asset' style={{
									objectFit: 'contain',
									height: '84px',
									width: '84px'
								}}>
									<source src={asset.details.asset ? asset.details.asset : defaultImage} type="video/mp4" onError={handleVideoError} />
								</video>

							)}
						</div>
						<div className='flex-grow-1 p-3'>
							<div className='d-flex justify-content-between mb-2'>
								<div className='itemName'>
									{asset.collection.name}{' '}
									{asset.collection.isVerified && (
										<img
											src={yellowtick20}
											alt='yellowtick20'
											className='ml-1'
										/>
									)}
								</div>
							</div>
							<div className='d-flex justify-content-between mb-n1'>
								<div className='itemId'>
									{asset.details.name}
								</div>
								<span className='itemBal'>{`${parseFloat(
									asset?.details?.price?.toFixed(8)
								)} ${asset.details.currency}`}</span>
							</div>
						</div>
					</div>
				</div>

				<div className='mt-4'>
					{remove ?
						<>
							<button
								className='btn btn-block checkoutBtn'
								onClick={handleAddToFavoritesAndRemove}
							>
								{t('Add To Favorites and Remove')}
							</button>
							<button
								className='btn btn-block checkoutBtn'
								onClick={removeFromCart}
							>
								{t('Remove')}
							</button>
						</> :
						<>
							<button
								className='btn btn-block checkoutBtn'
								disabled
							>
								{t('Add To Favorites and Remove')}
							</button>
							<button
								className='btn btn-block checkoutBtn'
								disabled
							>
								{t('Remove')}
							</button>
						</>}
				</div>
			</ModalBody>
		</Modal>
	)
}
