import React, { useState, useCallback, useRef } from 'react'
import {
	UncontrolledTooltip
} from 'reactstrap'

import yellowTick from '../../assets/images/collection/yellow-tick_20px.svg'
import copyIcon from '../../assets/images/copyIcon.svg'
import eth from '../../assets/images/ethIconSm.png'
import bnb from '../../assets/images/bnbIconSm.png'
import './Settings.scss'
import matic from '../../assets/images/maticIconSm.png'
import defaultImage from '../../assets/images/homeExplorer/placeholder.png'
import { useAccount } from 'wagmi'
import copy from 'copy-to-clipboard'
import { useToast } from '../../utils/toast/customSuccess'
import { useTranslation } from 'react-i18next'
import { GetFetchEarnings } from './api'
import { handleImageError } from '../../hooks/useHandleImg'


export const SettingsEarnings = () => {
	const chainImageMap = {
		binance: bnb,
		polygon: matic,
		ethereum: eth
	}

	const { t } = useTranslation()

	const toast = useToast()

	const { address } = useAccount()

	const [copyText, setCopyText] = useState(address ? address : '')
	const [videoError, setVideoError] = useState(false)
	const handleVideoError = () => {
		setVideoError(true)
	}
	const copyToClipboard = () => {
		copy(copyText)
		toast.success(t('Address Copied'))
	}

	const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
		GetFetchEarnings({
			address: address
		})

	const intersectionObserverRef = useRef()
	const lastNftRef = useCallback(
		nft => {
			if (isFetchingNextPage) {
				return
			}

			if (intersectionObserverRef.current) {
				intersectionObserverRef.current.disconnect()
			}

			intersectionObserverRef.current = new IntersectionObserver(nfts => {
				if (nfts[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
					fetchNextPage()
				}
			})

			if (nft) {
				intersectionObserverRef.current.observe(nft)
			}
		},
		[isFetchingNextPage, hasNextPage, fetchNextPage]
	)

	const listViewrow = data?.pages?.map(page => {
		return page?.data?.map((item, i) => {
			if (page?.data?.length === i + 1) {
				return (
					<tr ref={lastNftRef}>
						<td>
							<img src={chainImageMap[item.chain]} alt='coimImg' />
						</td>
						<td>
							<div className='d-flex align-items-center'>
								<div className='tblImg'>
									{item.imageFormat && item.imageFormat.startsWith('image') ? (
										!item?.asset?.startsWith(process.env.S3_URL) &&
											!item?.asset?.startsWith('ipfs') &&
											item?.asset !== null ? (
											<img
												src={item?.asset ? item.asset : defaultImage}
												className='itemIcon mr-3'
												style={{
													height: '60px',
													aspectRatio: '1',
													borderRadius: '12px'
												}}
												onError={handleImageError}
											/>
										) : (
											<img
												src={defaultImage}
												className='itemIcon mr-3'
												style={{
													height: '60px',
													aspectRatio: '1',
													borderRadius: '12px'
												}}
											/>
										)
									) : videoError ? (
										<img
											src={defaultImage}
											className='itemIcon mr-3'
											style={{
												height: '60px',
												aspectRatio: '1',
												borderRadius: '12px'
											}}
										/>
									) : (


										<video loop autoPlay muted playsInline style={{
											height: '60px',
											aspectRatio: '1',
											borderRadius: '12px'
										}} className='itemIcon mr-3'>
											<source src={item.asset ? item.asset : defaultImage} type="video/mp4" onError={handleVideoError} />
										</video>
									)}
								</div>
								<div>
									<div className='fw-600'>{item.nftName}</div>
									<div className='smTxt dark-text-secondary'>
										{item.collectionName}
										{item.verification === 'Verified' && (
											<img src={yellowTick} height='18' className='ml-1' />
										)}
									</div>
								</div>
							</div>
						</td>
						<td className='text-center'>
							<span className='hover' id={`previousPrice_${item._id}`} data-toggle='tooltip'>
								${parseFloat(item.previousPriceInUsd?.toFixed(8))}
							</span>
							<UncontrolledTooltip
								placement='top'
								target={`previousPrice_${item._id}`}
							>
								{parseFloat(item.previousPrice?.toFixed(8))} {item.previousSaleCurrency ? item.previousSaleCurrency : item.currency}
							</UncontrolledTooltip>
						</td>
						<td className='text-center'>
							<span className='hover' id={`currentPrice_${item._id}`} data-toggle='tooltip'>
								${parseFloat(item.currentPriceInUsd?.toFixed(8))}
							</span>
							<UncontrolledTooltip
								placement='top'
								target={`currentPrice_${item._id}`}
							>
								{parseFloat(item.currentPrice?.toFixed(8))} {item.currency}
							</UncontrolledTooltip>
						</td>
						<td className=''>${parseFloat(item.profitInUsd?.toFixed(8))}</td>
					</tr>
				)
			}
			return (
				<tr key={item._id}>
					<td>
						<img src={chainImageMap[item.chain]} alt='coimImg' />
					</td>
					<td>
						<div className='d-flex align-items-center'>
							<div className='tblImg'>
								{item.imageFormat && item.imageFormat.startsWith('image') ? (
									!item?.asset?.startsWith(process.env.S3_URL) &&
										!item?.asset?.startsWith('ipfs') &&
										item?.asset !== null ? (
										<img
											src={item?.asset ? item.asset : defaultImage}
											className='itemIcon mr-3'
											style={{
												height: '60px',
												aspectRatio: '1',
												borderRadius: '12px'
											}}
											onError={handleImageError}
										/>
									) : (
										<img
											src={defaultImage}
											className='itemIcon mr-3'
											style={{
												height: '60px',
												aspectRatio: '1',
												borderRadius: '12px'
											}}
										/>
									)
								) : videoError ? (
									<img
										src={defaultImage}
										className='itemIcon mr-3'
										style={{
											height: '60px',
											aspectRatio: '1',
											borderRadius: '12px'
										}}
									/>
								) : (

									<video loop autoPlay muted playsInline style={{
										height: '60px',
										aspectRatio: '1',
										borderRadius: '12px'
									}} className='itemIcon mr-3'>
										<source src={item.asset ? item.asset : defaultImage} type="video/mp4" onError={handleVideoError} />
									</video>
								)}
							</div>
							<div>
								<div className='fw-600'>{item.nftName}</div>
								<div className='smTxt dark-text-secondary'>
									{item.collectionName}
									{item.verification === 'Verified' && (
										<img src={yellowTick} height='18' className='ml-1' />
									)}
								</div>
							</div>
						</div>
					</td>
					<td className='text-center'>
						<span className='hover' id={`previousPrice_${item._id}`} data-toggle='tooltip'>
							${parseFloat(item.previousPriceInUsd?.toFixed(8))}
						</span>
						<UncontrolledTooltip
							placement='top'
							target={`previousPrice_${item._id}`}
						>
							{parseFloat(item.previousPrice?.toFixed(8))} {item.previousSaleCurrency ? item.previousSaleCurrency : item.currency}
						</UncontrolledTooltip>
					</td>
					<td className='text-center'>
						<span className='hover' id={`currentPrice_${item._id}`} data-toggle='tooltip'>
							${parseFloat(item.currentPriceInUsd?.toFixed(8))}
						</span>
						<UncontrolledTooltip
							placement='top'
							target={`currentPrice_${item._id}`}
						>
							{parseFloat(item.currentPrice?.toFixed(8))} {item.currency}
						</UncontrolledTooltip>
					</td>
					<td className=''>${parseFloat(item.profitInUsd?.toFixed(8))}</td>
				</tr>
			)
		})
	})

	return (
		<>
			<div className='d-flex align-items-center mb-3'>
				<h3 className='settingsHeading mb-0'>{t("Earnings")}</h3>
			</div>
			<div className='createCollectionCard mb-3'>
				<form className='w-100'>
					<div className='row form-row'>
						<div className='col-12 mb-3'>
							<div className='walletCnt mb-3'>
								<h3>
									{address
										? address.slice(0, 5) + '...' + address.slice(38, 42)
										: ''}
								</h3>
								<span className='walletLabel successLabel ml-auto'>
									{address ? t('Wallet Connected') : t('Connect Wallet')}
								</span>
								<img
									src={copyIcon}
									alt='copyIcon'
									className='ml-2'
									onClick={copyToClipboard}
									onKeyDown={(e) => {
										if (e.key === 'Enter' || e.key === ' ') {
											copyToClipboard()
										}
									}}
									role="button" tabIndex={0}
									style={{ cursor: 'pointer' }}
								/>
							</div>
						</div>
						<div className='col-12'>
							<div className='borderInputBox mb-4'>
								<div className='d-flex justify-content-between'>
									<div className='fs-16 fw-400 dark-text'>{t("Total Earnings")}</div>
									<div className='fs-16 fw-600 dark-text'>
										{data?.pages[0]?.totalProfit
											? '$' +
											parseFloat(data?.pages[0]?.totalProfit?.toFixed(8))
											: '-'}
									</div>
								</div>
							</div>
							<div className='table-responsive scroller'>
								<table className='table table-borderless listViewTable '>
									{data?.pages[0]?.data?.length != 0 && <thead>
										<tr>
											<th></th>
											<th>{t("Item")} </th>
											<th>{t("Obtained")}</th>
											<th>{t("Sold")}</th>
											<th>{t("Profit")}</th>
										</tr>
									</thead>}
									<tbody>{listViewrow}</tbody>
								</table>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	)
}

export default SettingsEarnings
