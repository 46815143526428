import { useCallback, useContext } from 'react'
import {
	ConnectorNotFoundError,
	SwitchChainNotSupportedError,
	useConnect,
	useDisconnect
} from 'wagmi'
import { useNavigate } from 'react-router-dom'
import { ThemeContext } from '../views/context/context'
import { useToast } from '../utils/toast/customSuccess'
import { useTranslation } from 'react-i18next'


export class WalletConnectorNotFoundError extends Error {}

export class WalletSwitchChainError extends Error {}

export default function useAuth() {
	const { connectAsync, connectors } = useConnect()
	const { disconnectAsync } = useDisconnect()
	const { t, i18n } = useTranslation()

	const toast = useToast()
	const navigate = useNavigate()
	const login = useCallback(
		async ({ connectorId, chainId }) => {
			const connector = connectors.find(c => c.id === connectorId)

			try {
				const connected = await connectAsync({
					connector,
					chainId
				})
				return connected
			} catch (error) {
				if (error instanceof ConnectorNotFoundError) {
					throw new WalletConnectorNotFoundError()
				}
				if (error instanceof SwitchChainNotSupportedError) {
					throw new WalletSwitchChainError(
						'Unable to switch network. Please try it on your wallet'
					)
				}
				if (error.message.includes('User rejected the request')) {
					throw new Error('You rejected the request in your wallet.')
				}
				throw new Error(error)
			}
		},
		[connectors, connectAsync]
	)
	const { resetState } = useContext(ThemeContext)

	const logout = useCallback(async () => {
		try {
			toast.success(t('You have been logged out.'))
			await disconnectAsync()
			localStorage.removeItem('access-token')
			localStorage.removeItem('walletType')
			sessionStorage.removeItem('WorAddress')
			sessionStorage.clear()
			localStorage.clear()
			localStorage.setItem('isLoggedIn', 'false')
			dispatchEvent(new Event('storage'))
			navigate('/')
			resetState()
		} catch (error) {}
	}, [disconnectAsync])

	return { login, logout }
}
