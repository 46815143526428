import React, { useContext, useEffect, useState } from 'react'
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown
} from 'reactstrap'

import listIcon from '../../assets/images/collection/listIcon.svg'
import hideIcon from './assets/hideIcon.svg'
import copyIcon from './assets/copyIcon.svg'
import ETHIcon from '../../assets/images/collection/ETHIcon.png'
import BNBIcon from '../../assets/images/collection/bnbCoin.png'
import MATICIcon from '../../assets/images/collection/maticIconSm.png'
import checkTickIcon from '../../assets/images/toast/checkIcon.svg'
import removeIcon from '../../assets/images/toast/checkIcon.svg'
import yellowTick20px from '../../assets/images/collection/yellow-tick_20px.svg'
import moreIcon from './assets/moreIcon.svg'
import noItemfoundImage from '../../assets/images/collection/noItemfoundImage.svg'
import cnddsLight from '../../assets/images/cnddsLight.svg'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from '../context/context'
import { useToast } from '../../utils/toast/customSuccess'
import { useAccount } from 'wagmi'
import { useNavigate, useParams } from 'react-router-dom'
import ResponsivePagination from 'react-responsive-pagination'
import defaultImage from '../../assets/images/homeExplorer/placeholder.png'
import { handleImageError } from '../../hooks/useHandleImg'
import copy from 'copy-to-clipboard'
import { ExploreLoader } from '../../features/collection/components/loader/ExploreLoader'
import { nativeCoinMap } from '../../utils/getFilterData'
import DataService from '../../lib/DataService'


const nativeCoinImg = {
	ethereum: ETHIcon,
	binance: BNBIcon,
	polygon: MATICIcon
}
const landPerRow = 6

const GridViewItem = props => {
	const { t } = useTranslation()

	const Statuss = {
		"list": t("Listed"),
		"auction": t("On Auction"),
		"make-offer": t("Has Offers"),
		"new": t("New")
	};

	const { address } = useAccount()

	const gridview = props.filterview ? 'g-col-5' : 'g-col-6'

	const [loading, setLoading] = useState(false)

	const { getRequest, postRequest } = DataService()

	const {
		collectedsearch, setCollectedSearch, collectedfilter, setCollectedFilter, collectedstatus, setcollectedStatus, checkcollectedChain, setcollectedCheckChain, singlecollectedcollectionList, chooselistingcollectedchain, setListingCollectedChain, collectedminAmount, setCollectedMinAmount, collectedmaxAmount, setCollectedMaxAmount, hiddenstatus, setHiddenStatus, Collectedstatus, setCollectedStatus, createdfiltersortby, setCreatedFilterSortby, createdfiltersortorder, setCreatedFilterSortorder, contractAddresscollected, setContarctAddressCollected, checkcollectedCurrency, setcollectedCheckCurrency,
		filtercollection, setFiltercollection
	} = useContext(ThemeContext)

	const { id } = useParams()
	const [next, setNext] = useState(landPerRow)
	const [page, setPage] = useState(1)

	const toast = useToast()

	useEffect(() => {
		GetCollected()
	}, [
		page, address, Collectedstatus, collectedsearch, collectedfilter, collectedstatus, checkcollectedChain, singlecollectedcollectionList, contractAddresscollected, Collectedstatus == true ? chooselistingcollectedchain : '', Collectedstatus == true ? collectedminAmount : '', Collectedstatus == true ? collectedmaxAmount : '', Collectedstatus == true ? hiddenstatus : '', createdfiltersortby, createdfiltersortorder, checkcollectedCurrency, id
	])
	const [getcollected, setGetcollected] = useState('')
	const [totalactivitycount, setTotalActivity] = useState('')
	const [videoError, setVideoError] = useState(false)

	const [count, setCount] = useState('')

	function handlePageChange(page) {
		setPage(page)
	}

	const [exploreLoading, setExploreLoading] = useState(false)

	const GetCollected = async () => {
		setLoading(true)
		setExploreLoading(true)
		const fetch = await getRequest(
			`user/collected-nfts?address=${id ? id : address}&page=${collectedsearch !== '' ? 1 : contractAddresscollected > 0 ? 1 : checkcollectedChain > 0 ? 1 : checkcollectedCurrency > 0 ? 1 : Collectedstatus == true ? 1 : createdfiltersortorder !== 'desc' ? 1 : createdfiltersortby !== '_id' ? 1 : collectedstatus > 0 ? 1 : page}&limit=15&sortField=${createdfiltersortby}&sortOrder=${createdfiltersortorder}&filter=${collectedsearch}&collectionName=${contractAddresscollected && contractAddresscollected.map(item => { return item.collectionName })}&contractAddress=${contractAddresscollected && contractAddresscollected.map(item => { return item.contractAddress })}&chain=${checkcollectedChain}&currencyName=${checkcollectedCurrency}&sortStatusField=${collectedstatus}&currency=${Collectedstatus == true ? chooselistingcollectedchain : ''}&minPrice=${Collectedstatus == true ? collectedminAmount : ''}&maxPrice=${Collectedstatus == true ? collectedmaxAmount : ''}`
		)
		if (fetch.status == true) {
			setGetcollected(fetch.data)
			setTotalActivity(Math.ceil(fetch.count / 15))
			setCount(fetch.count)
			setLoading(false)
			setExploreLoading(false)
			setPage(
				collectedsearch !== '' ? 1 : contractAddresscollected > 0 ? 1 : checkcollectedChain > 0 ? 1 : checkcollectedCurrency > 0 ? 1 : Collectedstatus == true ? 1 : createdfiltersortorder !== 'desc' ? 1 : createdfiltersortby !== '_id' ? 1 : collectedstatus > 0 ? 1 : page
			)
		} else {
			setGetcollected('')
			setTotalActivity('')
			setCount('')
			setLoading(false)
			setExploreLoading(false)
			setPage(1)
		}
	}

	const navigate = useNavigate()

	const handleVideoError = () => {
		setVideoError(true);
	};

	const hideNFT = async (id1, contractAddress) => {
		let Hide = true
		const HidedNFT = await postRequest(`user/hide-nft?nftId=${id1}&isHide=${Hide}&owner=${address}&contractAddress=${contractAddress}`)
		if (HidedNFT.status === true) {
			GetCollected()
			toast.hide('1item successfully hidden.')
			const getcollection = await getRequest(`user/owned-collections?address=${id ? id : address}`)
			if (getcollection.status == true) {
				setFiltercollection(getcollection.data)
			}
		}
	}


	const copyLink = (item) => {
		copy(`${process.env.REACT_APP_PUBLIC_URL}/assets/${item.chain}/${item.contractAddress}/${item.nftId}`)
		toast.copy('Link copied')
	}

	const ItemCard =
		getcollected &&
		getcollected.map(item => {
			return (
				item?.isHide ? <></> : <>
					<div
						className='grid-col'
						style={{
							width: '100%',
							height: '100%',
							objectFit: 'cover'
						}}
						onClick={() =>
							navigate(
								`/assets/${item.chain}/${item.contractAddress}/${item.nftId}`
							)
						}
						onKeyDown={(e) => {
							if (e.key === 'Enter' || e.key === ' ') {
								navigate(`/assets/${item.chain}/${item.contractAddress}/${item.nftId}`)
							}
						}}
						role="button" tabIndex={0}
					>
						<div className={`gridItemCard ${item.itemadded ? 'active' : ''}`}>
							<div className='gItemImg'>
								{typeof item?.asset == 'string' &&
									item?.asset !== undefined &&
									!item?.asset?.startsWith(process.env.S3_URL) &&
									!item?.asset?.startsWith('ipfs') && item?.asset !== null ? (
									item?.asset?.endsWith('mp4') ? (
										videoError ? <img
											src={defaultImage}
											className='img-fluid w-100 itemImage'
										/> :

											<video autoPlay muted playsInline className='img-fluid w-100 itemImage' style={{
												width: '100px',
												height: '150px',
												objectFit: 'cover'
											}}>
												<source src={item?.asset ? item.asset : defaultImage} type="video/mp4" onError={handleVideoError} />
											</video>

									) : item?.asset !== 'undefined' ? (
										<img
											src={item?.asset ? item?.asset : defaultImage}
											className='img-fluid w-100 itemImage'
											onError={handleImageError}
										/>
									) : (
										<img
											src={defaultImage}
											className='img-fluid w-100 itemImage'
										/>
									)
								) : (
									<img
										src={defaultImage}
										className='img-fluid w-100 itemImage'
									/>
								)}
								{item.contractStandard == "ERC1155" ?
									<div className='idTag'>#{item.supply}</div>
									:
									""
								}
								<div className='hoverImgCnt'>
									<div className='d-flex justify-content-between align-items-center'>
										<div className='d-flex align-items-center'>
											<img
												src={
													nativeCoinImg[item.chain]
												}
												alt='ETHIcon'
												className='cointypeImg'
											/>
										</div>
									</div>
									<div className='d-flex justify-content-between align-items-center'>
										{Number(item.supply) - Number(item.nftListedCount) <= 0 ? (
											''
										) : !!address && address.toLowerCase() === item.owner ? (
											<button
												className='buybowbtn rounded-pill'
												type='button'
												onClick={e => {
													e.stopPropagation()
													navigate(
														`/listforsale/${item.chain}/${item.contractAddress}/${item.nftId}`
													)
												}}
											>
												{t('List For Sale')}
											</button>
										) : null}
										{!!address && address.toLowerCase() === item.owner ? (
											<button className='cartBtnCircle' onClick={e => {
												e.stopPropagation()
											}} style={{ marginInlineStart: "auto" }}>
												<UncontrolledDropdown>
													<DropdownToggle className="plainToggle">
														<img src={moreIcon} alt="shoppingcart" />
													</DropdownToggle>
													<DropdownMenu right className="dropdownMenu">
														{/* <DropdownItem>
															<img src={selectIcon} alt="selectIcon" className='mr-2' /> Select
														</DropdownItem> */}
														{Number(item.supply) - Number(item.nftListedCount) <= 0 ? (
															''
														) : !!address && address.toLowerCase() === item.owner ? (
															<DropdownItem onClick={e => {
																e.stopPropagation()
																navigate(
																	`/listforsale/${item.chain}/${item.contractAddress}/${item.nftId}`
																)
															}} >
																<img src={listIcon} alt="listIcon" className='mr-2' /> {t("List For Sale")}
															</DropdownItem>
														) : null}


														<DropdownItem onClick={() => hideNFT(item?._id, item?.contractAddress)}>
															<img src={hideIcon} alt="hideIcon" className='mr-2' /> {t("Hide")}
														</DropdownItem>
														<DropdownItem onClick={() => copyLink(item)}>
															<img src={copyIcon} alt="copyIcon" className='mr-2' /> {t("Copy Link")}
														</DropdownItem>
													</DropdownMenu>
												</UncontrolledDropdown>
											</button>) : null}
									</div>
								</div>
							</div>
							<div className='botcntCard'>
								<div className='bottomSmImg'>
									<img src={item.imgsmsrc} height={73} alt='ItemimgSm1' />
								</div>
								<div className='botcntTxt'>
									<div className='itemLabel'>
										{item.collectionName}{' '}
										<img
											src={
												item.verification == 'Verified' ? yellowTick20px : ''
											}
											height='12'
										/>
									</div>
									<div className='itemName'>
										{item.nftName !== ''
											? item.nftName
											: item.nftId
												? `#${item.nftId}`
												: '---'}
									</div>
									<div class='d-flex justify-content-between'>
										<div class='itemPricetxt'>{t("Highest Bid")}</div>
										<div class='itemPriceval'>
											{item.highestBid ? item.highestBid : '--'}{' '}
											{item.highestBid
												? nativeCoinMap[
												item.chain
												]
												: ''}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)
		})

	const closecollectedsearch = () => {
		setCollectedSearch('')
	}
	const closecollectedfilter = () => {
		setCollectedFilter('Recently Received')
		setCreatedFilterSortby('_id')
		setCreatedFilterSortorder('desc')
	}
	const closecollectedstatus = data => {
		let a = collectedstatus.includes(data)
		if (a == true) {
			const index = collectedstatus.indexOf(data)
			if (index > -1) {
				collectedstatus.splice(index, 1)
				const nerArr = [...collectedstatus]
				setcollectedStatus(nerArr)
				if (nerArr.length == 0) {
					setcollectedStatus([''])
				}
			}
		}
	}

	const litstedTagLoop3 =
		collectedstatus &&
		collectedstatus.map(item => {
			return (
				<>
					{item == '' ? (
						''
					) : (
						<>
							<div className='tagLabel'>
								<span className='' style={{ textTransform: 'capitalize' }}>
									{Statuss[item]}
								</span>
								<i
									className='fas fa-times ml-2'
									style={{ cursor: 'pointer' }}
									onClick={() => closecollectedstatus(item)}
									onKeyDown={(e) => {
										if (e.key === 'Enter' || e.key === ' ') {
											closecollectedstatus(item)
										}
									}}
									role="button" tabIndex={0}
								></i>
							</div>
						</>
					)}
				</>
			)
		})


	const litstedTagLoop2 =
		checkcollectedChain &&
		checkcollectedChain.map(item => {
			return (
				<>
					{item == '' ? (
						''
					) : (
						<>
							<div className='tagLabel'>
								<span style={{ textTransform: "capitalize" }}>{item}</span>
								<i
									className='fas fa-times ml-2'
									style={{ cursor: 'pointer' }}
									onClick={() => closecollectedchain(item)}
									onKeyDown={(e) => {
										if (e.key === 'Enter' || e.key === ' ') {
											closecollectedchain(item)
										}
									}}
									role="button" tabIndex={0}
								></i>
							</div>
						</>
					)}
				</>
			)
		})

	const litstedTagLoop4 =
		checkcollectedCurrency &&
		checkcollectedCurrency.map(item => {
			return (
				<>
					{item == '' ? (
						''
					) : (
						<>
							<div className='tagLabel'>
								<span style={{ textTransform: "capitalize" }}>{item}</span>
								<i
									className='fas fa-times ml-2'
									style={{ cursor: 'pointer' }}
									onClick={() => closecollectedcurrency(item)}
									onKeyDown={(e) => {
										if (e.key === 'Enter' || e.key === ' ') {
											closecollectedcurrency(item)
										}
									}}
									role="button" tabIndex={0}
								></i>
							</div>
						</>
					)}
				</>
			)
		})

	const closecollectedchain = item => {
		let a = checkcollectedChain.includes(item)

		if (a == true) {
			const index = checkcollectedChain.indexOf(item)
			if (index > -1) {
				checkcollectedChain.splice(index, 1)
				const nerArr = [...checkcollectedChain]
				setcollectedCheckChain(nerArr)
			}
		}
	}

	const closecollectedcurrency = item => {
		let a = checkcollectedCurrency.includes(item)

		if (a == true) {
			const index = checkcollectedCurrency.indexOf(item)
			if (index > -1) {
				checkcollectedCurrency.splice(index, 1)
				const nerArr = [...checkcollectedCurrency]
				setcollectedCheckCurrency(nerArr)
			}
		}
	}

	const litstedCollection =
		contractAddresscollected &&
		contractAddresscollected.map(item => {
			return (
				<>
					{item == '' ? (
						''
					) : (
						<>
							<div className='tagLabel'>
								<span style={{ textTransform: "capitalize" }}>{item.collectionName}</span>
								<i
									className='fas fa-times ml-2'
									style={{ cursor: 'pointer' }}
									onClick={() => closeCollection(item)}
									onKeyDown={(e) => {
										if (e.key === 'Enter' || e.key === ' ') {
											closeCollection(item)
										}
									}}
									role="button" tabIndex={0}
								></i>
							</div>
						</>
					)}
				</>
			)
		})

	const closeCollection = data => {
		let a = contractAddresscollected.some(
			item => item.contractAddress === data.contractAddress
		)
		if (a == true) {
			const updatedList = contractAddresscollected.filter(
				item => item.contractAddress !== data.contractAddress
			)
			setContarctAddressCollected(updatedList)
		}
	}

	const closesearch1 = () => {
		setCollectedMinAmount('')
		setCollectedMaxAmount('')
		setCollectedStatus(false)
	}

	const clearAll = () => {
		setCollectedSearch('')
		setcollectedStatus([''])
		setcollectedCheckChain([])
		setContarctAddressCollected([])
		setCollectedMinAmount('')
		setCollectedMaxAmount('')
		setHiddenStatus()
		setCollectedStatus(false)
		setCollectedFilter('Recently Received')
		setCreatedFilterSortby('_id')
		setCreatedFilterSortorder('desc')
		setcollectedCheckCurrency([])
	}

	const Back = () => {
		setCollectedSearch('')
		setcollectedStatus([''])
		setcollectedCheckChain([])
		setContarctAddressCollected([])
		setCollectedMinAmount('')
		setCollectedMaxAmount('')
		setHiddenStatus()
		setCollectedStatus(false)
		setCollectedFilter('Recently Received')
		setCreatedFilterSortby('_id')
		setCreatedFilterSortorder('desc')
		setcollectedCheckCurrency([])
	}

	const handlekeydown = (e) => {
		if (e.key === 'Enter' || e.key === ' ') {
			closesearch1()
		}
	}
	return (
		<>

			<div className='py-3'>
				<div className='row mb-4'>
					<div className='col-lg-6'>
						<span class='cmntxt'>
							{count ? count : ''} {count ? t('Items') : ''}
						</span>
					</div>
				</div>
				<div className='fiterSelectlabel mb-4 mt-4'>
					{collectedsearch ? (
						<div className='tagLabel'>
							<span style={{ textTransform: "capitalize" }}>{collectedsearch}</span>
							<i
								className='fas fa-times ml-2'
								style={{ cursor: 'pointer' }}
								onClick={closecollectedsearch}
								onKeyDown={(e) => {
									if (e.key === 'Enter' || e.key === ' ') {
										closecollectedsearch()
									}
								}}
								role="button" tabIndex={0}
							></i>
						</div>
					) : (
						''
					)}

					{collectedstatus.includes('') ? '' : litstedTagLoop3}
					{litstedTagLoop2}
					{litstedTagLoop4}
					{litstedCollection}

					{Collectedstatus == true ? (
						typeof chooselistingcollectedchain !== 'undefined' &&
							collectedminAmount &&
							collectedmaxAmount ? (
							<div className='tagLabel'>
								<span>
									{chooselistingcollectedchain} {collectedminAmount}-
									{collectedmaxAmount}
								</span>
								<i
									className='fas fa-times ml-2'
									style={{ cursor: 'pointer' }}
									onClick={closesearch1}
									onKeyDown={handlekeydown}
									role="button" tabIndex={0}

								></i>
							</div>
						) : typeof chooselistingcollectedchain !== 'undefined' &&
							collectedminAmount ? (
							<div className='tagLabel'>
								<span>
									{chooselistingcollectedchain} {collectedminAmount}
								</span>
								<i
									className='fas fa-times ml-2'
									style={{ cursor: 'pointer' }}
									onClick={closesearch1}
									onKeyDown={handlekeydown}
									role="button" tabIndex={0}

								></i>
							</div>
						) : typeof chooselistingcollectedchain !== 'undefined' &&
							collectedmaxAmount ? (
							<div className='tagLabel'>
								<span>
									{chooselistingcollectedchain} {collectedmaxAmount}
								</span>
								<i
									className='fas fa-times ml-2'
									style={{ cursor: 'pointer' }}
									onClick={closesearch1}
									onKeyDown={handlekeydown}
									role="button" tabIndex={0}

								></i>
							</div>
						) : (
							''
						)
					) : (
						''
					)}

					{
						Number(collectedstatus.filter(item => item.trim() !== "").length) + Number(checkcollectedChain?.length) + Number(contractAddresscollected?.length) + Number(collectedsearch.trim() === "" ? 0 : collectedsearch.trim().replace(/\s+/g, "").split(/\s+/).length) + Number(checkcollectedCurrency.length) > 1
							?
							(<button className='clearAllBtn' onClick={clearAll}>
								{t('Clear All')}
							</button>
							) : (
								''
							)
					}



				</div>

				{exploreLoading == true ? (
					<ExploreLoader />

				) :
					getcollected && getcollected.length > 0 ? (
						<div className={`gridrow ${gridview}`}>{ItemCard}</div>
					) : (
						<div className='noItemFound'>
							{loading == false ? (
								<>
									<div className='text-center'>
										<img
											src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : noItemfoundImage}
											alt='noItemfoundImage'
											className='img-fluid'
										/>
									</div>

									{collectedsearch ||
										(collectedfilter == 'Recently Received'
											? ''
											: collectedfilter) ||
										!collectedstatus.includes('') ||
										(Collectedstatus == true
											? (typeof chooselistingcollectedchain !== 'undefined' &&
												collectedminAmount) ||
											collectedmaxAmount
											: '') ||
										checkcollectedChain?.length > 0 ||
										checkcollectedCurrency?.length > 0 ||
										contractAddresscollected?.length > 0 ? (
										<>
											<div className='nofounttxt mb-4'>
												{t('No items were found. Try to broaden your search.')}
											</div>
											<div>
												<button className='applyBtn' onClick={Back}>
													{t("Back to all items")}
												</button>
											</div>
										</>
									) : (
										<div className='nofounttxt mb-4'>
											{t('Nothing to display.')}
										</div>
									)}
								</>
							) : (
								''
							)}
						</div>
					)
				}

				<div className='row justify-content-center'>
					<div className='col-lg-4 col-md-6 col-sm-8'>
						<div class='bri_pagenation2'>
							{getcollected && getcollected.length > 0 ? (
								<div class=''>
									<nav aria-label='Page '>
										<ResponsivePagination
											total={totalactivitycount}
											current={page}
											onPageChange={page => handlePageChange(page)}
											previousLabel={
												<svg
													width='7'
													height='12'
													viewBox='0 0 7 12'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														d='M1.125 5.75L5.71875 1.125C5.84375 0.96875 6.09375 0.96875 6.25 1.125L6.84375 1.75C7 1.875 7 2.125 6.84375 2.28125L3.15625 6L6.84375 9.75C7 9.90625 7 10.125 6.84375 10.2812L6.25 10.9062C6.09375 11.0625 5.84375 11.0625 5.71875 10.9062L1.125 6.28125C0.96875 6.125 0.96875 5.90625 1.125 5.75Z'
														fill='#F7F7F7'
													></path>
												</svg>
											}
											nextLabel={
												<svg
													width='7'
													height='12'
													viewBox='0 0 7 12'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														d='M6.84375 6.28125L2.28125 10.9062C2.125 11.0625 1.875 11.0625 1.75 10.9062L1.125 10.2812C0.96875 10.125 0.96875 9.90625 1.125 9.75L4.8125 6L1.125 2.28125C0.96875 2.125 0.96875 1.875 1.125 1.75L1.75 1.125C1.875 0.96875 2.125 0.96875 2.28125 1.125L6.84375 5.75C7 5.90625 7 6.125 6.84375 6.28125Z'
														fill='#F7F7F7'
													></path>
												</svg>
											}
											previousClassName='page-link-1'
											nextClassName='page-link-1'
										/>
									</nav>
								</div>
							) : (
								''
							)}
						</div>
					</div>
				</div>

				<useToast
					toastImgIcon={checkTickIcon}
					toastmassage={t('Added to Cart')}
				/>
				<useToast
					toastImgIcon={removeIcon}
					toastmassage={t('Removed from Cart')}
				/>
			</div>
		</>
	)
}

export default GridViewItem
