import React, { useContext, useEffect, useState } from 'react'
import {
	Modal,
	ModalHeader,
	ModalBody
} from 'reactstrap'
import './profileActive.scss'
import yellowTick from '../../../assets/images/collection/yellow-tick_20px.svg'
import noItemfoundImage from '../../../assets/images/collection/noItemfoundImage.svg'
import cnddsLight from '../../../assets/images/cnddsLight.svg'
import { ThemeContext } from '../../context/context'
import walletLoaderCnt from '../../../assets/images/walletLoader.png'
import walletLightLoaderCnt from '../../../assets/images/wallet-light-loader.png'
import walletLoader from '../../../assets/images/loaderCircle.png'

import { useToast } from '../../../utils/toast/customSuccess'

import {
	useAccount,
	useConnect,
	useNetwork,
	useSwitchNetwork,

} from 'wagmi'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'

import { useTranslation } from 'react-i18next'
import auction from '../../AbiArray/MarketplaceBnbabi.json'
import contracts from '../../../config/constants/contracts'
import Web3 from 'web3'
import loader1 from '../../../assets/images/Loader.png'
import ResponsivePagination from 'react-responsive-pagination'
import { getProvider } from '../../../utils/getProvider'
import { chainId } from '../../../config/constants/chains'

import { useContractProvider } from '../../../utils/useContractProvider'
import { useOkxSwitchNetwork } from '../../../hooks/useOkxSwitchNetwork'
import defaultImage from '../../../assets/images/homeExplorer/placeholder.png'
import { handleImageError } from '../../../hooks/useHandleImg'
import { CloseIcon } from '../../../components/modals/makeOffer/CloseIcon'
import { useBuyNowModalActions } from '../../../components/modals/buy-now/store'
import { useConnectWalletModalActions } from '../../../components/modals/connect-wallet/store'
import { useCheckoutModalActions } from '../../../components/modals/checkout/store'
import { BuyNowModal } from '../../../components/modals/buy-now/BuyNowModal'
import { chainIdMap, chainMap } from '../../../utils/getFilterData'

import DataService from '../../../lib/DataService'



const landPerRow = 6

const ActivityCollection = () => {
	const { t } = useTranslation()

	const [modal, setModal] = useState(false)
	const toggle = () => setModal(!modal)

	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()

	const { address } = useAccount()

	const { id } = useParams()
	const { postRequest } = DataService()

	const { switchNetworkAsync } = useSwitchNetwork()
	const { switchNetwork } = useOkxSwitchNetwork()
	const { chain } = useNetwork()
	const chainid = chain?.id
	const { toggleConnectWalletModal, setConnectWalletModalState } =
		useConnectWalletModalActions()
	const { setAsset: setCheckoutModalAssetState } = useCheckoutModalActions()

	const { toggleBuyNowModal, setAsset: setBuyNowModalAssetState } =
		useBuyNowModalActions()

	const toast = useToast()
	const {
		activelistingcollectionsearch,
		setActivelistingCollectionSearch,
		chooselistingchain,
		minAmount,
		setMinAmount,
		maxAmount,
		setMaxAmount,
		singlecollectionList,
		setSingleCollectionList,
		status,
		setStatus,
		setCollection
	} = useContext(ThemeContext)

	const [next, setNext] = useState(landPerRow)
	const [page, setPage] = useState(1)

	useEffect(() => {
		GetActivityNFT()
	}, [
		address,
		page,
		status,
		activelistingcollectionsearch,
		status == true
			? chooselistingchain == 'ETH'
				? 'ethereum'
				: chooselistingchain == 'BNB'
					? 'binance'
					: chooselistingchain == 'MATIC'
						? 'polygon'
						: ''
			: '',
		status == true ? minAmount : '',
		,
		status == true ? maxAmount : '',
		,
		singlecollectionList,
		id
	])

	const [getactivitydnft, setGetActivityNft] = useState('')
	const [totalactivitynftcount, setTotalActivityNftCount] = useState('')
	const [totalnftcount, setTotalNftCount] = useState('')

	function handlePageChange(page) {
		setPage(page)
	}

	const GetActivityNFT = async () => {
		setLoading(true)
		let data = {
			limit: 15,
			page:
				activelistingcollectionsearch !== ''
					? 1
					: status == true
						? 1
						: singlecollectionList > 0
							? 1
							: page,
			address: id ? id : address,
			filter: activelistingcollectionsearch,
			minPrice: status == true ? minAmount : '',
			maxPrice: status == true ? maxAmount : '',
			collection: singlecollectionList,
			chain:
				status == true
					? chooselistingchain == 'ETH'
						? 'ethereum'
						: chooselistingchain == 'BNB'
							? 'binance'
							: chooselistingchain == 'MATIC'
								? 'polygon'
								: ''
					: ''
		}
		const fetch = await postRequest(`user/get_activeListing`, data)
		if (fetch.status == true) {
			let cnVerify = fetch.data

			const getcollection = await postRequest('user/getListedCollection', {
				address: id ? id : address
			})
			if (getcollection.status == true) {
				let getcollectionverify = getcollection.data
				for (let i = 0; i < cnVerify.length; i++) {
					for (let j = 0; j < getcollectionverify.length; j++) {
						if (
							getcollectionverify[j].collectionName ===
							cnVerify[i].collectionName
						) {
							let data = getcollectionverify[j].verification
							cnVerify[i]['verification'] = data
						}
					}
				}
				setGetActivityNft(cnVerify)
			}
			setTotalActivityNftCount(fetch.count)
			setTotalNftCount(Math.ceil(fetch.count / 15))
			setLoading(false)
			setPage(
				activelistingcollectionsearch !== ''
					? 1
					: status == true
						? 1
						: singlecollectionList > 0
							? 1
							: page
			)
			setCollection(getcollection.data)
		} else {
			setGetActivityNft('')
			setTotalActivityNftCount('')
			setTotalNftCount('')
			setLoading(false)
			setPage(1)
			setCollection()
		}
	}

	const litstedCollection =
		singlecollectionList &&
		singlecollectionList.map(item => {
			return (
				<>
					{item == '' ? (
						''
					) : (
						<>
							<div className='tagLabel'>
								<span>{item}</span>
								<i
									className='fas fa-times ml-2'
									style={{ cursor: 'pointer' }}
									onClick={() => closeCollection(item)}
									onKeyDown={(e) => {
										if (e.key === 'Enter' || e.key === ' ') {
											closeCollection(item)
										}
									}}
									role="button" tabIndex={0}
								></i>
							</div>
						</>
					)}
				</>
			)
		})

	const [singlecancel, setSingleCancel] = useState('')
	const cancel = data => {
		setSingleCancel(data)
		toggle()
	}

	const handleBuyNow = async item => {
		setBuyNowModalAssetState(item)
		setCheckoutModalAssetState([item])
		setConnectWalletModalState({ chainId: chainIdMap[item.details.chain] })

		if (!address) {
			toggleConnectWalletModal()
			setConnectWalletModalState({
				callback: toggleBuyNowModal
			})
			return
		}

		try {
			if (item.details.chain !== chainMap[chain.id]) {
				await switchNetworkAsync(chainIdMap[item.details.chain])
			}

			toggleBuyNowModal()
		} catch (error) { }
	}

	const closesearch = () => {
		setActivelistingCollectionSearch('')
	}

	const closesearch1 = () => {
		setMinAmount('')
		setMaxAmount('')
		setStatus(false)
	}
	const closeCollection = item => {
		let a = singlecollectionList.includes(item)
		if (a == true) {
			const index = singlecollectionList.indexOf(item)
			if (index > -1) {
				singlecollectionList.splice(index, 1)
				const nerArr = [...singlecollectionList]
				setSingleCollectionList(nerArr)
			}
		}
	}

	const clearAll = () => {
		setActivelistingCollectionSearch('')
		setMinAmount('')
		setMaxAmount('')
		setSingleCollectionList([])
		setStatus(false)
	}

	const Back = () => {
		setActivelistingCollectionSearch('')
		setMinAmount('')
		setMaxAmount('')
		setSingleCollectionList([])
		setStatus(false)
	}

	const [loader, setloader] = useState(false)
	const [videoError, setVideoError] = useState(false)
	const getback = () => {
		setModal(!modal)
	}
	const handleVideoError = () => {
		setVideoError(true)
	}
	const { pro } = useContractProvider()
	const walletType = localStorage.getItem('walletType')
	const provider = getProvider()

	const web3 = new Web3(provider)
	const bnbAuction = new web3.eth.Contract(
		auction,
		contracts.marketplace[chainId.BINANCE]
	)
	const ethAuction = new web3.eth.Contract(
		auction,
		contracts.marketplace[chainId.ETHEREUM]
	)
	const polygonAuction = new web3.eth.Contract(
		auction,
		contracts.marketplace[chainId.POLYGON]
	)

	let contract
	const cancelListing = async () => {
		setloader(true)

		if (address) {
			if (singlecancel.details.saleType == 'auction') {
				if (singlecancel.details.chain === 'ethereum') {
					contract = ethAuction
				} else if (singlecancel.details.chain === 'binance') {
					contract = bnbAuction
				} else if (singlecancel.details.chain === 'polygon') {
					contract = polygonAuction
				}

				try {
					if (walletType === 'okx') {
						if (
							singlecancel.details.chain !==
							chainMap[parseInt(window.okxwallet.chainId)]
						) {
							await switchNetwork(chainIdMap[singlecancel.chain])
						}
					} else {
						if (singlecancel.details.chain === 'binance' && chainid !== chainId.BINANCE) {
							await switchNetworkAsync(chainId.BINANCE)
						} else if (
							singlecancel.details.chain === 'ethereum' &&
							chainid !== chainId.ETHEREUM
						) {
							await switchNetworkAsync(chainId.ETHEREUM)
						} else if (
							singlecancel.details.chain === 'polygon' &&
							chainid !== chainId.AMOY
						) {
							await switchNetworkAsync(chainId.AMOY)
						}
					}
				} catch (error) {
					toast.error('User rejected the switch network')
					setloader(false)
					setModal(!modal)
					return
				}
				try {
					let cancellisting = await contract.methods
						.cancelOrder(
							id ? id : address.toString(),
							singlecancel.details.contractAddress,
							singlecancel.details.nftId
						)
						.send({ from: address.toString() })
						.on('transactionHash', async hash => { })
						.on('receipt', async approveresult => {
							let data = {
								_id: singlecancel._id,
								address: id ? id : address,
								nftId: singlecancel.details.nftId,
								type: singlecancel.details.saleType,
								contractAddress: singlecancel.details.contractAddress
							}

							const fetch = await postRequest(`user/cancel_sell`, data)
							if (fetch.status == true) {
								setTimeout(() => {
									setModal(!modal)
									toast.cancel('Listing canceled')
									GetActivityNFT()
									setloader(false)
								}, 1000)
							} else {
								setModal(!modal)
								setloader(false)
								toast.error(fetch.message)
							}
						})
				} catch (err) {
					setModal(!modal)
					setloader(false)
					toast.error('Transaction Rejected')
				}
			} else {
				let data = {
					_id: singlecancel._id,
					address: id ? id : address,
					assetId: singlecancel.details.assetId,
					type: singlecancel.details.saleType,
					contractAddress: singlecancel.details.contractAddress,
					nftId: singlecancel.details.nftId
				}

				const fetch = await postRequest(`user/cancel_sell`, data)
				if (fetch.status == true) {
					setTimeout(() => {
						setModal(!modal)
						toast.cancel('Listing canceled')
						GetActivityNFT()
						setloader(false)
					}, 1000)
				} else {
					setModal(!modal)
					setloader(false)
					toast.error(fetch.message)
				}
			}
		} else {
			setloader(false)
			toast.error('Please connect wallet')
		}
	}

	const listViewrow =
		getactivitydnft &&
		getactivitydnft.map((item, index) => {
			return (
				<>
					<tr
						onClick={e =>
							e.stopPropagation(
								navigate(
									`/assets/${item.details.chain}/${item.details.contractAddress}/${item.details.nftId}`
								)
							)
						}
						style={{ cursor: 'pointer' }}
					>
						<td style={{ textTransform: 'capitalize' }}>
							{t(item.details.saleType)}
						</td>
						<td>
							<div className='d-flex align-items-center'>
								<div className='tblImg'>
									{typeof item?.details?.asset == 'string' &&
										item?.details?.asset !== undefined &&
										!item?.details?.asset?.startsWith(process.env.S3_URL) &&
										!item?.details?.asset?.startsWith('ipfs') &&
										item?.details?.asset !== null ? (
										item?.details?.imageFormat?.startsWith('video') ? (
											videoError ? (
												<img src={defaultImage} alt={`item.details.imgsrc`} />
											) : (
												<video
													src={
														item?.details?.asset
													}
													alt='Uploaded'
													muted
													autoPlay

													onError={handleVideoError}
												/>
											)
										) : item?.details?.asset !== 'undefined' ? (
											<img
												src={
													item?.details?.asset
														? item?.details?.asset
														: defaultImage
												}
												alt={`item.details.imgsrc`}
												onError={handleImageError}
											/>
										) : (
											<img src={defaultImage} alt={`item.details.imgsrc`} />
										)
									) : (
										<img src={defaultImage} alt={`item.details.imgsrc`} />
									)}
								</div>
								<div>
									<div className='fw-600 ml-2'>
										{item?.details?.nftName !== ''
											? item?.details?.nftName
											: item?.details?.nftId
												? `#${item.details.nftId}`
												: '---'}
									</div>
									<div className='smTxt dark-text-secondary ml-2'>
										{item.details.collectionName}{' '}
										<img
											src={
												item.details.verification == 'Verified'
													? yellowTick
													: ''
											}
											height='18'
											className='ml-1'
										/>
									</div>
								</div>
							</div>
						</td>
						<td className='text-right'>
							<div>
								{parseFloat(item?.details?.price?.toFixed(8))}{' '}
								{item.details.currency}
							</div>
							<div className='fs-12 dark-text-secondary'>
								$
								{parseFloat(
									(item.details.price * item.details.priceInUsd)?.toFixed(8)
								)}
							</div>
						</td>
						<td className='pl-5'>
							{item.details.floorDifference.floordifference !== null
								? parseFloat(item.details.floorDifference?.floordifference?.toFixed(2))
								: '0'}
							% {item.details?.floorDifferenceup == true ? t('Above') : t('below')}
						</td>
						<td className='text-right'>
							{item.details.isExpired == true ? (
								<span style={{ color: 'red' }}>{t("Expired")}</span>
							) : item.details.saleExpiry == 0 ? (
								'-'
							) : (
								moment(item.details.saleExpiry).fromNow()
							)}
						</td>

						{!!address && address.toLowerCase() === item.details.address ? (
							<td className='text-right'>
								<span
									className='cancelTag'
									onClick={e => {
										e.stopPropagation();
										cancel(item);
									}}
									onKeyDown={(e) => {
										if (e.key === 'Enter' || e.key === ' ') {
											e.stopPropagation();
											cancel(item);
										}
									}}
									role="button" tabIndex={0}
								>
									{t('Cancel')}
								</span>
							</td>
						) : item.details.saleType !== 'auction' ? (
							<td className='text-right'>
								<button
									type='button'
									className='btn gradientBtn1'
									onClick={e => e.stopPropagation(handleBuyNow(item))}
								>
									{t('Buy Now')}
								</button>
							</td>
						) : (
							''
						)}
					</tr>
				</>
			)
		})

	const handleKeydown = (e) => {
		if (e.key === 'Enter' || e.key === ' ') {
			closesearch1()
		}
	}


	return (
		<>
			<div className={loading ? 'spinner' : 'd-none'}>
				<img src={loader1} alt='' className='spinAnimate' />
			</div>

			<div className='row mb-4'>
				<div className='col-lg-6'>
					<span class='cmntxt'>
						{totalactivitynftcount > 0 ? totalactivitynftcount : ''}{' '}
						{totalactivitynftcount > 0 ? t('Items') : ''}
					</span>
				</div>
			</div>

			<div className='fiterSelectlabel mb-4'>
				{activelistingcollectionsearch ? (
					<div className='tagLabel'>
						<span>{activelistingcollectionsearch}</span>
						<i
							className='fas fa-times ml-2'
							style={{ cursor: 'pointer' }}
							onClick={closesearch}
							onKeyDown={(e) => {
								if (e.key === 'Enter' || e.key === ' ') {
									closesearch()
								}
							}}
							role="button" tabIndex={0}
						></i>
					</div>
				) : (
					''
				)}

				{litstedCollection}
				{status == true ? (
					typeof chooselistingchain !== 'undefined' &&
						minAmount &&
						maxAmount ? (
						<div className='tagLabel'>
							<span>
								{chooselistingchain} {minAmount}-{maxAmount}
							</span>
							<i
								className='fas fa-times ml-2'
								style={{ cursor: 'pointer' }}
								onClick={closesearch1}
								onKeyDown={handleKeydown}
							></i>
						</div>
					) : typeof chooselistingchain !== 'undefined' && minAmount ? (
						<div className='tagLabel'>
							<span>
								{chooselistingchain} {minAmount}
							</span>
							<i
								className='fas fa-times ml-2'
								style={{ cursor: 'pointer' }}
								onClick={closesearch1}
								onKeyDown={handleKeydown}

							></i>
						</div>
					) : typeof chooselistingchain !== 'undefined' && maxAmount ? (
						<div className='tagLabel'>
							<span>
								{chooselistingchain} {maxAmount}
							</span>
							<i
								className='fas fa-times ml-2'
								style={{ cursor: 'pointer' }}
								onClick={closesearch1}
								onKeyDown={handleKeydown}
							></i>
						</div>
					) : (
						''
					)
				) : (
					''
				)}

				{Number(
					activelistingcollectionsearch.trim() === ''
						? 0
						: activelistingcollectionsearch
							.trim()
							.replace(/\s+/g, '')
							.split(/\s+/).length
				) +
					Number(singlecollectionList.length) >
					1 ? (
					<button className='clearAllBtn' onClick={clearAll}>
						{t('Clear All')}
					</button>
				) : (
					''
				)}
			</div>
			{getactivitydnft && getactivitydnft.length > 0 ?
				<div className='table-responsive'>
					<table className='table table-borderless activityTable'>

						<thead>
							<tr>
								<th>{t('Type')}</th>
								<th>{t('Items')}</th>
								<th className='text-right'>{t('Price')}</th>
								<th className='pl-5'>{t('Floor Difference')}</th>
								<th className='text-right'>{t('Expiration')}</th>
								<th className='text-right'>{t('Action')}</th>
							</tr>
						</thead>

						<tbody>
							{listViewrow}

						</tbody>
					</table>
				</div>
				: (
					<div className='noItemFound justify-content-center'>
						{loading == false ? (
							<>
								<div className='text-center'>
									<img
										src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : noItemfoundImage}
										alt='noItemfoundImage'
										className='img-fluid'
									/>
								</div>

								{activelistingcollectionsearch ||
									(status == true
										? (typeof chooselistingchain !== 'undefined' &&
											minAmount) ||
										maxAmount
										: '') ||
									singlecollectionList.length > 0 ? (
									<>
										<div className='nofounttxt mb-4'>
											{t(
												'No items were found. Try to broaden your search.'
											)}
										</div>
										<div>
											<button className='applyBtn' onClick={Back}>
												{t("Back to all items")}
											</button>
										</div>
									</>
								) : (
									<div className='nofounttxt mb-4'>
										{t('Nothing to display.')}
									</div>
								)}
							</>
						) : (
							''
						)}
					</div>
				)}

			<div className='row justify-content-center'>
				<div className='col-lg-4 col-md-6 col-sm-8'>
					<div class='bri_pagenation2'>
						{getactivitydnft && getactivitydnft.length > 0 ? (
							<div className=''>
								<nav aria-label='Page '>
									<ResponsivePagination
										total={totalnftcount}
										current={page}
										onPageChange={page => handlePageChange(page)}
										previousLabel={
											<svg
												width='7'
												height='12'
												viewBox='0 0 7 12'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													d='M1.125 5.75L5.71875 1.125C5.84375 0.96875 6.09375 0.96875 6.25 1.125L6.84375 1.75C7 1.875 7 2.125 6.84375 2.28125L3.15625 6L6.84375 9.75C7 9.90625 7 10.125 6.84375 10.2812L6.25 10.9062C6.09375 11.0625 5.84375 11.0625 5.71875 10.9062L1.125 6.28125C0.96875 6.125 0.96875 5.90625 1.125 5.75Z'
													fill='#F7F7F7'
												></path>
											</svg>
										}
										nextLabel={
											<svg
												width='7'
												height='12'
												viewBox='0 0 7 12'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													d='M6.84375 6.28125L2.28125 10.9062C2.125 11.0625 1.875 11.0625 1.75 10.9062L1.125 10.2812C0.96875 10.125 0.96875 9.90625 1.125 9.75L4.8125 6L1.125 2.28125C0.96875 2.125 0.96875 1.875 1.125 1.75L1.75 1.125C1.875 0.96875 2.125 0.96875 2.28125 1.125L6.84375 5.75C7 5.90625 7 6.125 6.84375 6.28125Z'
													fill='#F7F7F7'
												></path>
											</svg>
										}
										previousClassName='page-link-1'
										nextClassName='page-link-1'
									/>
								</nav>
							</div>
						) : (
							''
						)}
					</div>
				</div>
			</div>

			<Modal
				isOpen={modal}
				toggle={toggle}
				centered='true'
				className='mymodal'
				backdropClassName='selCurBp'
				backdrop='static'
				keyboard={false}
			>

				<ModalHeader
					close={loader == true ? null : <CloseIcon onClose={getback} customclass="closefix" />}
				>{loader == false ? (<><div className='d-flex justify-content-center'>
					<svg
						className='mb12px'
						width='40'
						height='40'
						viewBox='0 0 40 40'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<rect width='40' height='40' rx='12' fill='#FE2626' />
						<path
							d='M21.0312 20L24.9062 23.875C25 23.9688 25 24.125 24.9062 24.25L24.2188 24.9375C24.0938 25.0312 23.9375 25.0312 23.8438 24.9375L23.1875 24.2812L20 21.0625L16.125 24.9375C16.0312 25.0312 15.875 25.0312 15.75 24.9375L15.0625 24.25C14.9688 24.125 14.9688 23.9688 15.0625 23.875L18.9375 20L15.0625 16.1562C14.9688 16.0625 14.9688 15.9062 15.0625 15.7812L15.75 15.0938C15.875 15 16.0312 15 16.125 15.0938L20 18.9688L23.8438 15.0938C23.9375 15 24.0938 15 24.2188 15.0938L24.9062 15.7812C25 15.9062 25 16.0625 24.9062 16.1562L24.25 16.8125L21.0312 20Z'
							fill='white'
						/>
					</svg>
				</div>
					<h3 className='modal-title'>
						{t("Are you sure you want to cancel your listing?")}
					</h3></>) : ""}</ModalHeader>
				{loader == true ? (
					<>
						<h5 style={{ display: 'flex', justifyContent: 'center' }}>
							{t("Cancel Listing")}
						</h5>
						<div className='fs-16 fw-400 text-center dark-text-secondary mb12px'>
							{t("To continue send transaction with your wallet")}.
						</div>
						<div className='d-flex justify-content-center align-items-center loaderCont '>
							{localStorage.getItem('light_theme') == 'true' ? (
								<img src={walletLightLoaderCnt} />
							) : (
								<img src={walletLoaderCnt} />
							)}
							<div className='loaderAnimCnt'>
								<img src={walletLoader} className='loaderAnimation' />
							</div>
						</div>
					</>
				) : (<ModalBody>
					<div className='mt-n4'>
						<div className='createContent'>
							<div className='w-100'>
								<h3 className='subcheckTxt mb-3'>
									{t("This will unpublish this sale from WARRIOR")}.
								</h3>
								<button
									type='button'
									className='btn btn-block cancelBtn mb-2'
									onClick={cancelListing}
								>
									{t("Cancel Listing")}
								</button>
								<button
									type='button'
									className='btn btn-block getBackBtn'
									onClick={getback}
								>
									{t("Get Back")}
								</button>
							</div>
						</div>

					</div>
				</ModalBody>)}
			</Modal>

			<BuyNowModal GetActivityNFT={GetActivityNFT} />
		</>
	)
}

export default ActivityCollection
