import React, { useContext, useState, useEffect } from 'react';
import './home-explorer.scss';
import NftDefaultImage from '../../assets/images/homeExplorer/placeholder.png'
import shoppingcart from '../../assets/images/collection/shopping-cart.svg';
import primeTick from '../../assets/images/collection/yellow-tick_20px.svg';

import bnbIconSm from '../../assets/images/bnbIconSm.png';
import ethIconSm from '../../assets/images/ethIconSm.png';
import maticIconSm from '../../assets/images/maticIconSm.png';
import yellowTick from '../../assets/images/collection/yellow-tick_20px.svg';
import { Button, Collapse, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { ThemeContext } from '../context/context';

import { useTranslation } from 'react-i18next';
import { useDebounce } from '../../utils/useDebounce';
import noItemfoundImage from '../../assets/images/collection/noItemfoundImage.svg'
import cnddsLight from '../../assets/images/cnddsLight.svg'
import { useNavigate } from 'react-router-dom';

import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi';
import minus from '../../assets/images/minus.svg'
import { useConnectWalletModalActions } from '../../components/modals/connect-wallet/store';
import { useCheckoutModalActions } from '../../components/modals/checkout/store';
import { useBuyNowModalActions } from '../../components/modals/buy-now/store';
import { chainId } from '../../config/constants/chains';
import { useQueryClient } from '@tanstack/react-query'
import { useToast } from '../../utils/toast/customSuccess';
import ResponsivePagination from 'react-responsive-pagination';
import { ExploreLoader } from '../../features/collection/components/loader/ExploreLoader';
import { chainIdMap, chainMap } from '../../utils/getFilterData';
import { useOkxSwitchNetwork } from '../../hooks/useOkxSwitchNetwork';
import { nativeCoinMap } from '../../utils/getFilterData';
import { NumericFormat } from 'react-number-format';
import moment from 'moment';
import DataService from '../../lib/DataService';

const AllNFTPage = (props) => {
    const gridview = 'g-col-5';

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { address: account, isConnected } = useAccount()
    const { toggleConnectWalletModal, setConnectWalletModalState } =
        useConnectWalletModalActions()
    const { setAsset: setCheckoutModalAssetState } = useCheckoutModalActions()
    const { switchNetworkAsync } = useSwitchNetwork()
    const { switchNetwork } = useOkxSwitchNetwork()
    const walletType = localStorage.getItem('walletType')
    const { toggleBuyNowModal, setAsset: setBuyNowModalAssetState } =
        useBuyNowModalActions()
    const queryClient = useQueryClient()
    const { chain } = useNetwork()
    const toast = useToast()

    const landPerRow = 6
    const { getRequest, postRequest } = DataService()

    const [next, setNext] = useState(landPerRow)
    const [cart, setCart] = useState(true)


    const [getExplorenft, setGetExploreNft] = useState('')

    const [page, setPage] = useState(1)
    const [count1, setCount] = useState('')

    const [currentPage, setCurrentPage] = useState(1);

    function handlePageChange(page) {
        setCurrentPage(page);
    }

    const { exploreChain, setExploreChain, exploreCollection, exploreStatus, setExploreStatus, exploreSortStatusField, setExploreSortStatusField,
        setExploreCollection, exploreTab, setExploreTab, exploreSearch, setExploreSearch, chooseexplorecollectedchain, setexploreCollectedChain, exploreminAmount, setExploreMinAmount, exploremaxAmount, setExploreMaxAmount, explorepriestatus, setExploredPriceStatus } = useContext(ThemeContext)




    const Status = [
        {
            id: 1,
            name: t("All"),
            sortStatusField: "",

        },
        {
            id: 2,
            name: t("Listed"),
            sortStatusField: "list",
        },
        {
            id: 3,
            name: t("On Auction"),
            sortStatusField: "auction",
        },
        {
            id: 4,
            name: t("Sale"),
            sortStatusField: "sale",
        },
        {
            id: 5,
            name: t("Has Offers"),
            sortStatusField: "make-offer",
        }

    ]



    const Chains = [
        {
            id: 1,
            name: "BNB",
            value: "binance",
        },
        {
            id: 2,
            name: "ETH",
            value: "ethereum",
        },
        {
            id: 3,
            name: "MATIC",
            value: "polygon",
        }
    ]


    const Chaincurrency = [
        {
            id: 1,
            name: "BNB",
            value: "binance",
        },
        {
            id: 2,
            name: "ETH",
            value: "ethereum",
        },
        {
            id: 3,
            name: "MATIC",
            value: "polygon",
        },
        {
            id: 4,
            name: "WOR",
            value: "wor",
        },
        {
            id: 5,
            name: "BUSD",
            value: "busd",
        },
        {
            id: 6,
            name: "USDT",
            value: "usdt",
        },
    ]

    const [loading, setLoading] = useState(false)
    const [exploreLoading, setExploreLoading] = useState(false)
    const [updateTime, setUpdateTime] = useState()

    const [collectionsearch, setCollectionSearch] = useState("")
    const [expCollection, setExpCollection] = useState([])
    const [videoError, setVideoError] = useState(false)
    const [totalnftcount, setTotalNftCount] = useState(0)
    const debouncedSearchText = useDebounce(exploreSearch, 500)

    const [isOpenCollapse1, setIsOpenCollapse1] = useState(false);
    const toggleCollapse1 = () => {
        setIsOpenCollapse1(!isOpenCollapse1);
    }
    const [isOpenCollapse2, setIsOpenCollapse2] = useState(false);
    const toggleCollapse2 = () => {
        setIsOpenCollapse2(!isOpenCollapse2);
    }
    const [isOpenCollapse3, setIsOpenCollapse3] = useState(false);
    const toggleCollapse3 = () => {
        setIsOpenCollapse3(!isOpenCollapse3);
    }
    const [isOpenCollapse4, setIsOpenCollapse4] = useState(false);
    const toggleCollapse4 = () => {
        setIsOpenCollapse4(!isOpenCollapse4);
    }
    const [isOpenCollapse5, setIsOpenCollapse5] = useState(false);
    const toggleCollapse5 = () => {
        setIsOpenCollapse5(!isOpenCollapse5);
    }

    const FilterCraetedStatus = (data) => {
        setExploreStatus(data.sortStatusField)
        if (data.sortStatusField == "") {
            setExploreStatus([""])
        }
        else {
            let a = exploreStatus.includes(data.sortStatusField)
            if (a == true) {
                const index = exploreStatus.indexOf(data.sortStatusField);
                if (index > -1) {
                    exploreStatus.splice(index, 1);
                    const nerArr = [...exploreStatus]
                    setExploreStatus(nerArr);
                    if (nerArr.length == 0) {
                        setExploreStatus([""]);
                    }
                }

            } else if (a == false) {
                const index = exploreStatus.indexOf("");
                if (index > -1) {
                    exploreStatus.splice(index, 1);
                }
                setExploreStatus(
                    [...exploreStatus, data.sortStatusField]
                );

            }
        }

    }

    const handleCheckboxChange = (item) => {
        let a = exploreChain.includes(item.value)

        if (a == true) {

            const index = exploreChain.indexOf(item.value);
            if (index > -1) {
                exploreChain.splice(index, 1);
                const nerArr = [...exploreChain]
                setExploreChain(nerArr);
            }

        } else if (a == false) {
            setExploreChain(
                [...exploreChain, item.value]
            );
        }
    }

    const [collDetail, setCollDetail] = useState([])

    const handleCheckboxCollection = (item) => {

        let a = collDetail?.includes(item.collectionName)
        if (a === true) {
            const index = collDetail.indexOf(item.collectionName);
            if (index > -1) {
                collDetail.splice(index, 1);
                const nerArr = [...collDetail]
                setCollDetail(nerArr);
            }
        }
        else {
            setCollDetail([...collDetail, item?.collectionName])
        }
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
        GetExplore()
    }, [currentPage, exploreChain, exploreCollection, exploreTab, debouncedSearchText, collDetail, exploreStatus, exploreSortStatusField, explorepriestatus == true ? chooseexplorecollectedchain : '', explorepriestatus == true ? exploreminAmount : '', explorepriestatus == true ? exploremaxAmount : ''])

    useEffect(() => {
        GetFilterCollection()

    }, [])

    const GetExplore = async () => {
        setExploreLoading(true)
        const fetch = await getRequest(
            `home/get_all_NFTs?userAddress=${account}&chain=${exploreChain}&collectionName=${collDetail}&category=${exploreTab}&status=${exploreStatus}&page=${currentPage}&limit=100&currency=${explorepriestatus == true ? chooseexplorecollectedchain : ''}&minPrice=${explorepriestatus == true ? exploreminAmount : ''}&maxPrice=${explorepriestatus == true ? exploremaxAmount : ''}`
        )
        if (fetch.status == true) {
            let cnVerify = fetch.data;
            setCount(Math.ceil(fetch.count / 100))
            const getcollection = await getRequest("home/get_all_collections?filter=")
            if (getcollection.status == true) {
                let getcollectionverify = getcollection.data;
                for (let i = 0; i < cnVerify.length; i++) {
                    for (let j = 0; j < getcollectionverify.length; j++) {
                        if (getcollectionverify[j].collectionName === cnVerify[i].collectionName) {
                            let data = getcollectionverify[j].verification;
                            cnVerify[i]['verification'] = data;

                        }
                    }
                }
                setGetExploreNft(cnVerify)
            }

            setTotalNftCount(fetch.count)
            setExploreLoading(false)
        }
        else {
            setGetExploreNft()
            setTotalNftCount()
            setExploreLoading(false)

        }
    }
    const closesearch = () => {
        setExploreSearch("")

    }

    const closestatus = (data) => {
        setExploreSortStatusField("")
        let a = exploreStatus.includes(data)
        if (a == true) {
            const index = exploreStatus.indexOf(data);
            if (index > -1) {
                exploreStatus.splice(index, 1);
                const nerArr = [...exploreStatus]
                setExploreStatus(nerArr);
                if (nerArr.length == 0) {
                    setExploreStatus([""]);
                }
            }

        }
    }




    const clearAll = () => {
        setExploreSearch("")
        setExploreChain([])
        setExploreCollection([])
        GetExplore()
        setExploreStatus([''])
        setExploreSortStatusField("")
        setExploreMinAmount('')
        setExploreMaxAmount('')
        setExploredPriceStatus(false)
        setCollDetail([])
    }

    const closechain = (item) => {
        let a = exploreChain.includes(item)

        if (a == true) {

            const index = exploreChain.indexOf(item);
            if (index > -1) {
                exploreChain.splice(index, 1);
                const nerArr = [...exploreChain]
                setExploreChain(nerArr);
            }
        }
    }

    const closecollection = (item) => {
        let a = collDetail.includes(item)

        if (a == true) {

            const index = collDetail.indexOf(item);
            if (index > -1) {
                collDetail.splice(index, 1);
                const nerArr = [...collDetail]
                setCollDetail(nerArr);
            }

        }
    }

    const GetFilterCollection = async () => {
        const getcollection = await getRequest("home/get_all_collections?filter=")
        if (getcollection.status == true) {
            setExpCollection(getcollection.data)
        }
    }
    const searchItems = async (e) => {
        if (e.key == 'Enter' || e.key == "") {
            setCollectionSearch(e?.target?.value)
            const searchcollection = await getRequest(`home/get_all_collections?filter=${e?.target?.value}`)
            if (searchcollection.status == true) {
                setExpCollection(searchcollection.data)
            }
            else {
                setExpCollection("")
            }
        }
    }

    const handleNft = (data, e) => {
        e.preventDefault()
        navigate(`/assets/${data.details.chain}/${data.details.contractAddress}/${data.details.nftId}`)

    }

    const litstedTagLoop3 = exploreStatus && exploreStatus.map((item) => {
        return (
            <>
                {item == "" ?
                    ""
                    :
                    <>
                        <div className='tagLabel m-1'>
                            <span style={{ textTransform: "capitalize" }}>{item == "make-offer" ? t("Offers") : t(item)}</span>
                            <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={() => closestatus(item)} onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    closestatus(item)
                                }
                            }}
                                role="button" tabIndex={0}></i>
                        </div>
                    </>
                }
            </>
        );
    });

    const litstedTagLoop1 = collDetail && collDetail.map((item) => {
        return (
            <>
                {item == "" ?
                    ""
                    :
                    <>
                        <div className='tagLabel m-1'>
                            <span style={{ textTransform: "capitalize" }}>{item}</span>
                            <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={() => closecollection(item)} onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    closecollection(item)
                                }
                            }}
                                role="button" tabIndex={0}></i>
                        </div>
                    </>
                }
            </>
        );
    });


    const litstedTagLoop2 = exploreChain && exploreChain.map((item) => {
        return (
            <>
                {item == "" ?
                    ""
                    :
                    <>
                        <div className='tagLabel m-1'>
                            <span style={{ textTransform: "capitalize" }}>{item}</span>
                            <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={() => closechain(item)} onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    closechain(item)
                                }
                            }}
                                role="button" tabIndex={0}></i>
                        </div>
                    </>
                }
            </>
        );
    });

    const ItemCard = getExplorenft && getExplorenft.map((item) => {
        const isVideo = item?.details?.asset?.endsWith('.mp4')
        const isMarketplace = item?.details?.isMarketplace
        const isOnFixed = item?.details?.saleType === 'fixed'
        const isOwner = account?.toLowerCase() === item?.details.owner
        const isCart = item?.details?.isCart
        const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true'

        const handleBuyNow = async (e) => {
            e.stopPropagation()
            setBuyNowModalAssetState(item)
            setCheckoutModalAssetState([item])
            setConnectWalletModalState({ chainId: chainIdMap[item.details.chain] })

            if (!account) {
                toggleConnectWalletModal()
                setConnectWalletModalState({
                    callback: toggleBuyNowModal
                })
                return
            }

            try {
                if (walletType === 'okx') {
                    if (
                        item.details.chain !== chainMap[parseInt(window.okxwallet.chainId)]
                    ) {
                        await switchNetwork(chainIdMap[item.details.chain])
                    }
                } else {
                    if (item.details.chain === 'binance' && chain.id !== chainId.BINANCE) {
                        await switchNetworkAsync(chainId.BINANCE)
                    } else if (
                        item.details.chain === 'ethereum' &&
                        chain.id !== chainId.ETHEREUM
                    ) {
                        await switchNetworkAsync(chainId.ETHEREUM)
                    } else if (
                        item.details.chain === 'polygon' &&
                        chain.id !== chainId.AMOY
                    ) {
                        await switchNetworkAsync(chainId.AMOY)
                    }
                }


                toggleBuyNowModal()
            } catch (error) {

            }
        }

        const toggleCart = (asset) => (account) => {
            postRequest('asset/cart-mutation', {
                orderId: asset.details.orderId,
                userAddress: account,
                status: !asset.details.isCart
            })
                .then((res) => {
                    if (res.data) {
                        toast.success(
                            `Item ${res.data.isCart ? 'added to' : 'removed from'
                            } the cart`
                        )

                        queryClient.refetchQueries(['nfts'])
                        queryClient.invalidateQueries(['cart'])
                        queryClient.invalidateQueries(['listings'])
                        queryClient.invalidateQueries(['asset'])
                        setTimeout(() => {
                            setCart(true)
                        }, 5000);

                    }
                })
                .catch((error) => {

                })
        }

        const handleToggleCart = (e) => {
            setCart(false)
            e.stopPropagation()
            if (!account) {
                toggleConnectWalletModal()
                setConnectWalletModalState({
                    callback: toggleCart(item)
                })
                return
            } else {
                toggleCart(item)(account)
            }
        }

        const handleImageError = (event) => {
            event.target.src = NftDefaultImage;
        };

        const handleVideoError = (event) => {
            setVideoError(true)
        };



        return (
            <>
                <div className='grid-col'>

                    <div className={`gridItemExplorerCard ${item.itemadded ? 'active' : ''}`} style={{ cursor: "pointer" }}
                        onClick={(e) => handleNft(item, e)} onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                                handleNft(item, e)
                            }
                        }}
                        role="button" tabIndex={0}>
                        <div className='gItemImg'>

                            {!isVideo ? (
                                !item?.details?.asset?.startsWith(process.env.S3_URL) && !item?.details?.asset?.startsWith('ipfs') && item?.details?.asset !== null ?
                                    <img
                                        src={item?.details?.asset !== "" ? item?.details?.asset : NftDefaultImage}
                                        className='img-fluid w-100 itemImage'
                                        alt={item?.details?.name}
                                        onError={handleImageError}
                                    /> :
                                    <img
                                        src={NftDefaultImage}
                                        className='img-fluid w-100 itemImage'
                                        alt={item?.details?.name}
                                    />
                            ) : (
                                videoError ? <img
                                    src={NftDefaultImage}
                                    className='img-fluid w-100 itemImage'
                                    alt={item?.details?.name}
                                /> :

                                    <video loop playsInline preload='metadata' controlslist='nodownload' className='img-fluid w-100 itemImage'>
                                        <source src={item?.details?.asset} onError={handleVideoError} />
                                    </video>
                            )}
                            <div className='hoverImgCnt'>
                                <div className='d-flex justify-content-between '>
                                    <div>
                                        <img src={item.details.chain == "binance" ? bnbIconSm : item.details.chain == "ethereum" ? ethIconSm : item.details.chain == "polygon" ? maticIconSm : ""} alt="ETHIcon" className='cointypeImg mr-2' />
                                        {item.details.contractStandard == "ERC1155" ?
                                            <span className='hashnum'>#{item.details.supply}</span>
                                            :
                                            ""
                                        }
                                    </div>
                                </div>
                                {!!account &&
                                    isLoggedIn &&
                                    !isOwner &&
                                    isOnFixed && (
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <button
                                                className='buybowbtn rounded-pill'
                                                type='button'
                                                style={{ flexGrow: '1' }}
                                                onClick={handleBuyNow}
                                            >
                                                {t('Buy Now')}
                                            </button>
                                            {isConnected && isOnFixed || cart ? (
                                                !isCart ? (
                                                    cart ? <button
                                                        className='cartBtnCircle ml-1'
                                                        onClick={handleToggleCart}
                                                    >
                                                        <img
                                                            src={shoppingcart}
                                                            alt='shoppingcart'
                                                        />
                                                    </button> : ""
                                                ) : (
                                                    cart ? <button
                                                        className='cartBtnCircle ml-1'
                                                        onClick={handleToggleCart}
                                                    >
                                                        <img
                                                            src={minus}
                                                            alt='shoppingcart'
                                                        />
                                                    </button> : ""
                                                )
                                            ) : ""}
                                        </div>
                                    )}
                            </div>
                        </div>
                        <div className='botcntCard'>
                            <div className='bottomSmImg'>
                                {!isVideo ? (
                                    !item?.details?.asset?.startsWith(process.env.S3_URL) && !item?.details?.asset?.startsWith('ipfs') && item?.details?.asset !== null ?
                                        <img src={item?.details?.asset !== "" ? item?.details?.asset : NftDefaultImage} height={73} alt={item?.details?.name} onError={handleImageError} /> :
                                        <img src={NftDefaultImage} height={73} alt={item?.details?.name} />) : (
                                    videoError ? <img src={NftDefaultImage} height={73} alt={item?.details?.name} /> :

                                        // <video
                                        //     src={item?.details?.asset}
                                        //     height={73} onError={handleVideoError}
                                        // />

                                        <video playsInline loop preload='metadata' controlslist='nodownload' style={{ height: "73px" }}>
                                            <source src={item?.details?.asset} type="video/mp4" onError={handleVideoError} />
                                        </video>
                                )}
                            </div>
                            <div className='botcntTxt'>
                                <div className='itemsmName'>{item?.collection[0]?.name} <img src={item?.collection[0]?.isVerified ? primeTick : ""} alt='' /></div>
                                <div className='itemName'>{item?.details?.name !== "" ? item.details.name : `#${item.details.nftId}`}</div>
                                {/* <div className='itemName'>{item.details.name} #{item.details.supply}</div> */}
                                <div className='d-flex justify-content-between'>
                                    <div className="itemPricetxt">{t('Highest Bid')}</div>
                                    <div className="itemPriceval">{item?.details?.highestBidAmount !== null ? item?.details?.highestBidAmount !== null : "--"} {item?.details?.highestBidAmount !== null ? nativeCoinMap[item.details.chain] : ""}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    })

    const closesearch1 = () => {
        setExploreMinAmount('')
        setExploreMaxAmount('')
        setExploredPriceStatus(false)
    }

    const Apply = () => {

        if (Number(exploremaxAmount) > 0) {
            if (Number(exploreminAmount) > Number(exploremaxAmount)) {
                toast.error("Minimum must be less than maximum amount")
                setExploreMinAmount("")
                setExploreMaxAmount("")
            }
            else {
                setExploredPriceStatus(true)
            }
        }
        else {
            setExploredPriceStatus(true)
        }
    }

    const refetchTime = async () => {
        try {
            const response = await getRequest('/home/get_update_time')
            if (response.status == true) {
                // setUpdateTime(response.data.updatedAt)
                setUpdateTime(moment(response.data.updatedAt).fromNow())
            }
        } catch (err) {
        }
    }

    useEffect(() => {
        refetchTime()
    }, [])

    const handlekeydown = (e) => {
        if (e.key === 'Enter' || e.key === ' ') {
            closesearch1()
        }
    }

    return (
        <>

            <div className='row'>
                <div className='col-lg-9'>

                    <div className="mb-4">

                        <div className='d-flex justify-content-between mb-4'>
                            <div className='fs-14 fw-400 dark-text-secondary'>{totalnftcount > 0 ? totalnftcount : ""}{" "}
                                {totalnftcount > 0 ? t("Items") : ""}</div>
                            <div className='fs-14 fw-400 dark-text-secondary'>
                                {t("Updated")} {updateTime}
                                <button className='refreshIcon pl-2' onClick={() => refetchTime()}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.4531 0.96875C14.6172 0.96875 14.7812 1.13281 14.7812 1.29688V5.67188C14.7812 5.86328 14.6172 6 14.4531 6H10.0781C9.88672 6 9.75 5.86328 9.75 5.67188V5.39844C9.75 5.23438 9.88672 5.07031 10.0781 5.07031H13.1953C12.2109 3.21094 10.2422 1.89844 8 1.89844C4.85547 1.89844 2.3125 4.38672 2.14844 7.44922C2.12109 7.64062 1.98438 7.75 1.82031 7.75H1.54688C1.35547 7.75 1.19141 7.61328 1.21875 7.42188C1.38281 3.83984 4.36328 0.96875 8 0.96875C10.4883 0.96875 12.6484 2.33594 13.8516 4.33203V1.29688C13.8516 1.13281 13.9883 0.96875 14.1797 0.96875H14.4531ZM14.4258 7.75C14.6172 7.75 14.7812 7.91406 14.7539 8.10547C14.5898 11.6875 11.6094 14.5312 8 14.5312C5.48438 14.5312 3.32422 13.1914 2.14844 11.1953V14.2031C2.14844 14.3945 1.98438 14.5312 1.82031 14.5312H1.54688C1.35547 14.5312 1.21875 14.3945 1.21875 14.2031V9.82812C1.21875 9.66406 1.35547 9.5 1.54688 9.5H5.92188C6.08594 9.5 6.25 9.66406 6.25 9.82812V10.1016C6.25 10.293 6.08594 10.4297 5.92188 10.4297H2.77734C3.76172 12.3438 5.73047 13.6016 8 13.6016C11.1172 13.6016 13.6602 11.168 13.8242 8.07812C13.8516 7.88672 13.9883 7.75 14.1523 7.75H14.4258Z" fill="#C5C5C5" />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div className='d-flex align-items-center fiterSelectlabel mb-4'>
                            {exploreSearch ?

                                <div className='tagLabel m-1'>
                                    <span>{exploreSearch}</span>
                                    <i className="fas fa-times ml-2" style={{ cursor: "pointer" }} onClick={closesearch} onKeyDown={(e) => {
                                        if (e.key === 'Enter' || e.key === ' ') {
                                            closesearch()
                                        }
                                    }}
                                        role="button" tabIndex={0}></i>
                                </div>
                                :
                                ""}

                            {exploreStatus.includes("All") ? "" : litstedTagLoop3}{litstedTagLoop1}{litstedTagLoop2}


                            {explorepriestatus == true ? (
                                typeof chooseexplorecollectedchain !== 'undefined' &&
                                    exploreminAmount &&
                                    exploremaxAmount ? (
                                    <div className='tagLabel m-1'>
                                        <span clas>
                                            {chooseexplorecollectedchain} {exploreminAmount} -
                                            {exploremaxAmount}
                                        </span>
                                        <i
                                            className='fas fa-times ml-2'
                                            style={{ cursor: 'pointer' }}
                                            onClick={closesearch1}
                                            onKeyDown={handlekeydown}
                                            role="button" tabIndex={0}
                                        ></i>
                                    </div>

                                ) : typeof chooseexplorecollectedchain !== 'undefined' &&
                                    exploreminAmount ? (
                                    <div className='tagLabel m-1'>
                                        <span>
                                            {chooseexplorecollectedchain} {exploreminAmount}
                                        </span>
                                        <i
                                            className='fas fa-times ml-2'
                                            style={{ cursor: 'pointer' }}
                                            onClick={closesearch1}
                                            onKeyDown={handlekeydown}
                                            role="button" tabIndex={0}
                                        ></i>
                                    </div>

                                ) : typeof chooseexplorecollectedchain !== 'undefined' &&
                                    exploremaxAmount ? (
                                    <div className='tagLabel m-1'>
                                        <span>
                                            {chooseexplorecollectedchain} {exploremaxAmount}
                                        </span>
                                        <i
                                            className='fas fa-times ml-2'
                                            style={{ cursor: 'pointer' }}
                                            onClick={closesearch1}
                                            onKeyDown={handlekeydown}
                                            role="button" tabIndex={0}
                                        ></i>
                                    </div>
                                ) : (
                                    ''
                                )
                            ) : (
                                ''
                            )}

                            {Number(exploreChain?.length) + Number(collDetail?.length) + Number(exploreStatus.filter(item => item.trim() !== "")?.length) > 1 ?
                                <button className='clearAllBtn' onClick={clearAll}>{t("Clear All")}</button>
                                :
                                ""
                            }

                        </div>

                        {exploreLoading == true ? (
                            <ExploreLoader />

                        ) : (
                            <>
                                {getExplorenft && getExplorenft.length > 0 ?
                                    <>

                                        <div className={`gridrow ${gridview}`}>
                                            {ItemCard}
                                        </div>


                                        < div className='row justify-content-center'>
                                            <div className='col-lg-4 col-md-6 col-sm-8'>
                                                <div class="bri_pagenation2">
                                                    <nav aria-label="Page ">
                                                        <ResponsivePagination
                                                            total={count1}
                                                            current={currentPage}
                                                            onPageChange={page => handlePageChange(page)}
                                                            previousLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.125 5.75L5.71875 1.125C5.84375 0.96875 6.09375 0.96875 6.25 1.125L6.84375 1.75C7 1.875 7 2.125 6.84375 2.28125L3.15625 6L6.84375 9.75C7 9.90625 7 10.125 6.84375 10.2812L6.25 10.9062C6.09375 11.0625 5.84375 11.0625 5.71875 10.9062L1.125 6.28125C0.96875 6.125 0.96875 5.90625 1.125 5.75Z" fill="#F7F7F7"></path></svg>}
                                                            nextLabel={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.84375 6.28125L2.28125 10.9062C2.125 11.0625 1.875 11.0625 1.75 10.9062L1.125 10.2812C0.96875 10.125 0.96875 9.90625 1.125 9.75L4.8125 6L1.125 2.28125C0.96875 2.125 0.96875 1.875 1.125 1.75L1.75 1.125C1.875 0.96875 2.125 0.96875 2.28125 1.125L6.84375 5.75C7 5.90625 7 6.125 6.84375 6.28125Z" fill="#F7F7F7"></path></svg>}
                                                            previousClassName="page-link-1"
                                                            nextClassName="page-link-1"
                                                        />
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>

                                    </>
                                    :

                                    <div className='noItemFound'>
                                        {loading == false ?
                                            <>
                                                <div className='text-center'>
                                                    <img src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : noItemfoundImage} alt="noItemfoundImage" className='img-fluid' />
                                                </div>
                                                <div className='nofounttxt mb-4'>
                                                    {t("Nothing to display.")}
                                                </div>
                                            </>
                                            :
                                            ""
                                        }
                                    </div>
                                }
                            </>
                        )}


                    </div>

                </div>
                <div className='col-lg-3'>
                    <div className='filtercard'>
                        <div>
                            <Button className='filterTit' onClick={toggleCollapse1}>
                                <span>{t("Status")} </span>
                                <i className={`fas ${isOpenCollapse1 ? 'fa-angle-up' : 'fa-angle-down'}`} ></i>
                            </Button>
                            <Collapse isOpen={isOpenCollapse1}>
                                <div className='collapseCnt'>
                                    {Status && Status.map((data, index) => (
                                        <button className={exploreStatus.length > 0 ? exploreStatus.includes(data.sortStatusField) ? 'filterStatusbtn active' : 'filterStatusbtn' : 'filterStatusbtn'} onClick={() => FilterCraetedStatus(data)}>{data.name}</button>
                                    ))}
                                </div>
                            </Collapse>
                        </div>

                        <div>
                            <Button className='filterTit' onClick={toggleCollapse4}>
                                <span>{t("Price")} </span>
                                <i className={`fas ${isOpenCollapse4 ? 'fa-angle-up' : 'fa-angle-down'}`} ></i>
                            </Button>
                            <Collapse isOpen={isOpenCollapse4}>
                                <div className='collapseCnt'>
                                    <div className='row align-items-center'>
                                        <div className='col-12' style={{ minWidth: '90px' }}>
                                            <div className='form-group formInputs'>
                                                <Input type="select" onChange={(e) => setexploreCollectedChain(e.target.value)}>
                                                    <option selected value="">{t("Select")}</option>
                                                    {Chaincurrency && Chaincurrency.map((data, index) => (
                                                        <>
                                                            <option value={data.name} key={index}>{data.name}</option>
                                                        </>
                                                    ))}
                                                </Input>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='form-group formInputs'>
                                                <NumericFormat className='form-control' placeholder={t('Min')} name="collectedminAmount" onChange={(e) => setExploreMinAmount(e.target.value)} allowNegative={false} customInput={Input} value={exploreminAmount} />

                                            </div>
                                        </div>
                                        <div className='col-auto text-center px-0 mb-3'>{t("to")}</div>
                                        <div className='col'>
                                            <div className='form-group formInputs'>
                                                <NumericFormat className='form-control' placeholder={t('Max')} name="collectedmaxAmount" onChange={(e) => setExploreMaxAmount(e.target.value)} value={exploremaxAmount} allowNegative={false} customInput={Input} />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {chooseexplorecollectedchain && exploremaxAmount || exploreminAmount ?
                                            <button className='applybtn' onClick={Apply}>{t("Apply")}</button>
                                            :
                                            <button className='applybtn' disabled>{t("Apply")}</button>
                                        }
                                    </div>
                                </div>
                            </Collapse>
                        </div>

                        <div>
                            <Button className='filterTit' onClick={toggleCollapse2}>
                                <span>{t("Chains")} </span>
                                <i className={`fas ${isOpenCollapse2 ? 'fa-angle-up' : 'fa-angle-down'}`} ></i>
                            </Button>
                            <Collapse isOpen={isOpenCollapse2}>
                                <div className='collapseCnt'>
                                    <div className='filterInnercard'>


                                        {Chains && Chains.map((data, index) => (
                                            <div class="d-flex align-items-center mb-2" key={data.id} >
                                                <label class="cuschk mr-2 mb-0" For={`checkbx1${index + 1}`}>
                                                    <input type="checkbox" id={`checkbx1${index + 1}`} name="chains" checked={exploreChain.length > 0 ? exploreChain.includes(data.value) : false} onChange={() => handleCheckboxChange(data)} />
                                                    <span class="checkmark"></span>
                                                </label>
                                                <img src={data.value == "binance" ? bnbIconSm : data.value == "ethereum" ? ethIconSm : data.value == "polygon" ? maticIconSm : ""} className="mr-1" width="20px" />
                                                <h3 className='mb-0 filterLabel'>{data.value}</h3>
                                                <h3 className='mb-0 filterLabel dark-text-secondary ml-auto'>{data.name}</h3>
                                            </div>
                                        ))}



                                    </div>
                                </div>
                            </Collapse>
                        </div>
                        <div>
                            <Button className='filterTit' onClick={toggleCollapse5}>
                                <span>{t("Collections")}</span>
                                <i className={`fas ${isOpenCollapse5 ? 'fa-angle-up' : 'fa-angle-down'}`} ></i>
                            </Button>
                            <Collapse isOpen={isOpenCollapse5}>
                                <div className='collapseCnt'>
                                    <div className="form-group formInputs">
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText className='px-2'>
                                                    <svg
                                                        width="17"
                                                        height="17"
                                                        viewBox="0 0 17 17"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M15.875 14.6562C16.0312 14.8125 16.0312 15.0625 15.875 15.1875L15.1562 15.9062C15.0312 16.0625 14.7812 16.0625 14.625 15.9062L10.8438 12.125C10.7812 12.0312 10.75 11.9375 10.75 11.8438V11.4375C9.59375 12.4062 8.125 13 6.5 13C2.90625 13 0 10.0938 0 6.5C0 2.9375 2.90625 0 6.5 0C10.0625 0 13 2.9375 13 6.5C13 8.125 12.375 9.625 11.4062 10.75H11.8125C11.9062 10.75 12 10.8125 12.0938 10.875L15.875 14.6562ZM6.5 11.5C9.25 11.5 11.5 9.28125 11.5 6.5C11.5 3.75 9.25 1.5 6.5 1.5C3.71875 1.5 1.5 3.75 1.5 6.5C1.5 9.28125 3.71875 11.5 6.5 11.5Z"
                                                            fill="white"
                                                        />
                                                    </svg>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder={t("Search collection")}
                                                onKeyDown={searchItems}
                                            />
                                        </InputGroup>
                                    </div>
                                    <div className='filterInnercard'>
                                        <div class="scroller htcheckbox">

                                            {expCollection.length > 0 ?
                                                expCollection.map((data, index) =>
                                                (
                                                    <div class="d-flex align-items-center mb-2" key={data.id}>
                                                        <label class="cuschk mr-2 mb-0" For={`checkbox${index + 1}`}>
                                                            <input type="checkbox" id={`checkbox${index + 1}`} name="collectionchoose"
                                                                checked={collDetail?.length > 0 ? collDetail?.includes(data.collectionName) : false}

                                                                onChange={() => handleCheckboxCollection(data)} />
                                                            <span class="checkmark"></span>
                                                        </label>
                                                        <h3 className='mb-0 filterLabel'>{data.collectionName}</h3>
                                                        <img src={data.verification == "Verified" ? yellowTick : ""} className="ml-1" />
                                                        <h3 className='mb-0 filterLabel dark-text-secondary ml-auto'>{data.totalItems}</h3>
                                                    </div>
                                                ))
                                                :
                                                "No Data Found"
                                            }


                                        </div>
                                    </div>



                                </div>
                            </Collapse>
                        </div>
                    </div>

                </div>

            </div >
        </>
    )
}

export default AllNFTPage;