import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	Tooltip,
	ResponsiveContainer
} from 'recharts'
import moment from 'moment'
import { Spinner } from 'reactstrap'

import { useCollectionVolumeChart } from '../../apis/collectionVolumeChart'
import { formatAmount } from '../../../../utils/formatAmount'

import greenUpArrow from '../../../../assets/images/collection/greenUpArrow.svg'
import redDownArrow from '../../../../assets/images/collection/redDownArrow.svg'
import cndds from '../../../../assets/images/cndds.png'
import cnddsLight from '../../../../assets/images/cnddsLight.svg'

import { useTranslation } from 'react-i18next'

const CustomTooltip = ({ active, payload, label }) => {
	if (active && payload && payload.length) {
		return (
			<div className='custombar-tooltip' >
				<div className='tooltip-content' >
					<h3 className='label-bold'>{`${payload[0].value
						}`}</h3>
					<h3 className='label-bold'>{`${payload[0].payload.currency}`}</h3>

					<p className='label'>
						Sales:{`${payload[0].payload.sales}`}
					</p>
					<p className='label'>{`${payload[0].payload.time}`}</p>
				</div>
			</div>
		)
	}
	return null
}

const VolumeChart = () => {
	const params = useParams()
	const [chartData, setChartData] = useState([])

	const { data, isLoading, isSuccess } = useCollectionVolumeChart(
		params.collectionAddress
	)

	const { t } = useTranslation()


	useEffect(() => {
		const processedData =
			data?.status === false
				? []
				: data?.data.map((item) => ({
					time: moment.utc(item?.createdAt).format('MMM D'),
					dateTime: moment
						.utc(item?.createdAt)
						.format('MMM D, LT'),
					volume: item?.volume,
					currency: item?.chain,
					nftImage: item?.asset,
					sales: item?.count
				}))
		setChartData(processedData)
	}, [isSuccess, data])

	if (isLoading) {
		return (
			<div className='col-12 my-auto'>
				<div class='noResf text-center' style={{ height: '200px' }}>
					<Spinner />
				</div>
			</div>
		)
	}

	if (data?.status === false) {
		return (
			<div className='col-12 my-auto'>
				<div class='noResf text-center'>
					<img class='img-fluid' src={localStorage.getItem('light_theme') == 'true' ? cnddsLight : cndds} alt='' />
					<h1>{t("Nothing to display.")}</h1>
				</div>
			</div>
		)
	}

	return (
		<>
			<div className='graphvalue mb-2 d-flex align-items-center'>
				{(formatAmount(data.currentVolume, 8))} {data.chain}{' '}
				<span className={data.isUp ? 'greenTxt pl-2' : 'redTxt pl-2'}>
					<img
						src={data.isUp ? greenUpArrow : redDownArrow}
						alt='greenUpArrow'
					/>{' '}
					{data.volDifference}%
				</span>
			</div>
			<ResponsiveContainer
				width='100%'
				height={250}
			>
				<BarChart data={chartData}>
					<XAxis dataKey='time' />
					<YAxis />

					<Tooltip
						content={<CustomTooltip />}
						cursor={{ fill: 'transparent' }}
					/>
					<Bar dataKey='volume' fill='#dcac38' barSize={20} />
				</BarChart>
			</ResponsiveContainer>
		</>
	)
}

export default VolumeChart
