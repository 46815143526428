import { forwardRef, useState } from "react";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import { Link } from "react-router-dom";

import { useConnectWalletModalActions } from "../../../components/modals/connect-wallet/store";
import { useCheckoutModalActions } from "../../../components/modals/checkout/store";
import { useBuyNowModalActions } from "../../../components/modals/buy-now/store";
import { chainId } from "../../../config/constants/chains";
import { hoursToGo } from "../../../utils/formatRoundTime";
import { formatAddress } from "../../../utils/formatAddress";
import { handleImageError } from "../../../hooks/useHandleImg";
import ETHIcon from "../../../assets/images/collection/ETHIcon.png";
import BNBIcon from "../../../assets/images/bnbIconSm.png";
import MaticIcon from "../../../assets/images/maticIconSm.png";
import defaultImage from "../../../assets/images/homeExplorer/placeholder.png";
import { chainIdMap, chainMap } from "../../../utils/getFilterData";
import { useOkxSwitchNetwork } from "../../../hooks/useOkxSwitchNetwork";
import { useTranslation } from "react-i18next";

export const NftList = forwardRef(function NftList({ item, onClick }, ref) {
  const [videoError, setVideoError] = useState(false)
  const { toggleConnectWalletModal, setConnectWalletModalState } =
    useConnectWalletModalActions();
  const { setAsset: setCheckoutModalAssetState } = useCheckoutModalActions();
  const { address: account } = useAccount();
  const { switchNetworkAsync } = useSwitchNetwork();
  const { switchNetwork } = useOkxSwitchNetwork()
  const walletType = localStorage.getItem('walletType')
  const { chain } = useNetwork();
  const { toggleBuyNowModal, setAsset: setBuyNowModalAssetState } =
    useBuyNowModalActions();
  const isVideo = item.details.asset.endsWith(".mp4");
  const timeSinceCreated = hoursToGo(new Date(item.details.createdAt).getTime());

  const handleBuyNow = async (e) => {
    e.stopPropagation();
    setBuyNowModalAssetState(item);
    setCheckoutModalAssetState([item]);
    setConnectWalletModalState({ chainId: chainIdMap[item.details.chain] });

    if (!account) {
      toggleConnectWalletModal();
      setConnectWalletModalState({
        callback: toggleBuyNowModal,
      });
      return;
    }

    try {
      if (walletType === 'okx') {
        if (
          item.details.chain !== chainMap[parseInt(window.okxwallet.chainId)]
        ) {
          await switchNetwork(chainIdMap[item.details.chain])
        }
      } else {
        if (item.details.chain === 'binance' && chain.id !== chainId.BINANCE) {
          await switchNetworkAsync(chainId.BINANCE)
        } else if (
          item.details.chain === 'ethereum' &&
          chain.id !== chainId.ETHEREUM
        ) {
          await switchNetworkAsync(chainId.ETHEREUM)
        } else if (
          item.details.chain === 'polygon' &&
          chain.id !== chainId.AMOY
        ) {
          await switchNetworkAsync(chainId.AMOY)
        }
      }
      
      toggleBuyNowModal();
    } catch (error) { }
  };

  const { t } = useTranslation()


  const isOwner =
    account && account.toLowerCase() === item.owner.address.toLowerCase();
  const isOnFixed =
    item.details.isMarketplace && item.details.saleType === "fixed";
  const isOnAuction =
    item.details.isMarketplace && item.details.saleType === "auction";
  const isOnDutchAuction =
    item.details.isMarketplace && item.details.saleType === "dutch-auction";

  const handleVideoError = () => {
    setVideoError(true);
  };

  return (
    <tr ref={ref ? ref : null} onClick={onClick} className="hover">
      <td>
        <div className="d-flex align-items-center">
          <div className="tblImg">
            {!isVideo ? (
              !item?.details?.asset?.startsWith(process.env.S3_URL) &&
                !item?.details?.asset?.startsWith("ipfs") && item?.details?.asset !== null ? (
                <img
                  src={
                    item?.details?.asset ? item?.details?.asset : defaultImage
                  }
                  alt="Itemimg1"
                  className="img-fluid itemImage"
                  onError={handleImageError}
                />
              ) : (
                <img
                  src={defaultImage}
                  alt="Itemimg1"
                  className="img-fluid itemImage"
                />
              )
            ) : (
              videoError ? <img
                src={defaultImage}
                alt="Itemimg1"
                className="img-fluid itemImage"
              /> :
                

                <video loop playsInline preload='metadata' controlslist='nodownload' id='video-asset' style={{
                  objectFit: "contain",
                  height: "60px",
                  width: "60px",
                }}>
                  <source src={item?.details?.asset ? item?.details?.asset : defaultImage} type="video/mp4" onError={handleVideoError} />
                </video>

            )}
          </div>
          <div className="fw-600">{item?.details?.name !== "" ? item?.details?.name : `#${item?.details?.nftId}`}</div>
        </div>
      </td>
      <td className="text-right" style={{ opacity: isOnAuction ? "1" : "0.5" }}>
        {isOnAuction ? item.details.highestBidAmount : "--"}
      </td>
      <td className="text-right">{item.details.isMarketplace ? "--" : "-"}</td>
      <td className="text-right">
        <Link
          to={`/profile/${item.owner.address}`}
          style={{ textDecoration: "none" }}
        >
          <span className="dark-primary">
            {item.owner.name === ""
              ? formatAddress(item.owner.address, 4)
              : item.owner.name}
          </span>
        </Link>
      </td>
      <td className="text-right">
        <span className="dark-text-secondary">{timeSinceCreated}</span>
      </td>
      {item.details.isMarketplace && isOnFixed ? (
        <td className="text-right">
          <button className="btnBuynow" onClick={handleBuyNow}>
            {t("Buy Now")}
          </button>
        </td>
      ) : (
        <td></td>
      )}
    </tr>
  );
});
