import { create } from 'zustand'

const useBuyNowModalStore = create((set) => ({
	isOpen: false,
	asset: null,
	actions: {
		toggleBuyNowModal: () =>
			set((state) => ({
				...state,
				isOpen: !state.isOpen
			})),
		setAsset: (asset) =>
			set((state) => ({
				...state,
				asset
			}))
	}
}))

export const useBuyNowModalState = () =>
	useBuyNowModalStore(({ isOpen, asset }) => ({
		isOpen,
		asset
	}))

export const useBuyNowModalActions = () =>
	useBuyNowModalStore((state) => state.actions)
